<template>
  <b-card
    ref="bCarMarketingRoi"
    class="b_card_content dashboard__card"
    :class="isDarkSkin ? 'dashboard__card--dark' : ''"
  >
    <b-card-text>
      <b-row>
        <b-col cols="9">
          <div class="d-flex mb-1">
            <b-avatar rounded="sm" size="4em" class="b_avatar_color">
              <div class="ico_content">
                <b-img
                  :src="icoMarketingCard"
                  style="width: 85%; height: 85%"
                />
              </div>
            </b-avatar>

            <div class="ml-1 title_content">
              <span :style="titleCard()">Marketing</span>
              <span :style="titleCard()">ROI</span>
            </div>
          </div>
        </b-col>
        <b-col
          cols="3"
          class="d-flex justify-content-end"
          style="font-size: 20px; font-weight: 600"
        >
          <b-badge :class="['px-1 content_roi', roiColor()]">
            {{ roi }}%
          </b-badge>
        </b-col>

        <b-col>
          <div>
            <div class="content_piramide">
              <div
                v-b-tooltip.hover.bottom="''"
                :class="['piramide_1', isDarkSkin ? 'piramide_1--dark' : '']"
              >
                <span>
                  <span :style="isDarkSkin ? 'color: black' : 'color: white'"
                    >Investment ( $ {{ investment }} )</span
                  ><br />
                  <div
                    class="cursor-pointer"
                    :class="
                      isDarkSkin
                        ? 'span-hover-marketing-roi-white'
                        : 'span-hover-marketing-roi-black'
                    "
                    @click="openRepliesModal"
                  >
                    <div class="d-inline" style="font-weight: 600">
                      Replies ( {{ replies }} )
                    </div>
                    <div class="d-inline">
                      <feather-icon icon="EyeIcon" size="20" />
                    </div>
                  </div>
                </span>
              </div>
              <div
                v-b-tooltip.hover.top="''"
                :class="['piramide_2', isDarkSkin ? 'piramide_2--dark' : '']"
              >
                <span>
                  <div
                    class="cursor-pointer"
                    :class="
                      isDarkSkin
                        ? 'span-hover-marketing-roi-white'
                        : 'span-hover-marketing-roi-black'
                    "
                    @click="openLeadsModal"
                  >
                    <div class="d-inline" style="font-weight: 600">
                      Leads ( {{ leads }} )
                    </div>
                    <div class="d-inline">
                      <feather-icon icon="EyeIcon" size="20" />
                    </div>
                  </div>
                  <br />
                  <div
                    class="cursor-pointer"
                    :class="
                      isDarkSkin
                        ? 'span-hover-marketing-roi-white'
                        : 'span-hover-marketing-roi-black'
                    "
                    @click="openNumbersModal"
                  >
                    <div class="d-inline" style="font-weight: 600">
                      Numbers ( {{ numbers }} )
                    </div>
                    <div class="d-inline">
                      <feather-icon icon="EyeIcon" size="20" />
                    </div>
                  </div>
                </span>
              </div>
              <div
                :class="['piramide_3', isDarkSkin ? 'piramide_3--dark' : '']"
              >
                <span class="content_span mt-1">
                  <div
                    class="cursor-pointer"
                    :class="
                      isDarkSkin
                        ? 'span-hover-marketing-roi-green-dark'
                        : 'span-hover-marketing-roi-green-light'
                    "
                    @click="openSalesModal"
                  >
                    <div class="d-inline">Sales ( {{ sales }} )</div>
                    <div class="d-inline">
                      <feather-icon icon="EyeIcon" size="20" />
                    </div>
                  </div>
                  I.P {{ "$" + initial_payments }} <br />
                  <span>Appointments ({{ appointments }})</span>
                </span>
              </div>
            </div>
          </div>

          <div
            class="loading_roi"
            :class="[
              statusService ? '' : 'd-none',
              isDarkSkin ? 'loading_roi--dark' : '',
            ]"
          >
            <b-spinner variant="primary" label="Text Centered" />
          </div>
        </b-col>
      </b-row>
    </b-card-text>
    <detailed-replies-modal
      v-if="repliesModalIsOpen"
      :send-year="selectYear"
      :send-month="selectMonth"
      @close="closeRepliesModal"
    />
    <detailed-numbers-modal
      v-if="numbersModalIsOpen"
      :send-year="selectYear"
      :send-month="selectMonth"
      @close="closeNumbersModal"
    />
    <detailed-leads-modal
      v-if="leadsModalIsOpen"
      :send-year="selectYear"
      :send-month="selectMonth"
      @close="closeLeadsModal"
    />
    <detailed-sales-modal
      v-if="salesModalIsOpen"
      :send-year="selectYear"
      :send-month="selectMonth"
      @close="closeSalesModal"
    />
  </b-card>
</template>
<script>
import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service";
import DetailedRepliesModal from "@/views/ceo/dashboard/modals/roi/DetailedReplies.vue";
import DetailedNumbersModal from "@/views/ceo/dashboard/modals/roi/DetailedNumbers.vue";
import DetailedLeadsModal from "@/views/ceo/dashboard/modals/roi/DetailedLeads.vue";
import DetailedSalesModal from "@/views/ceo/dashboard/modals/roi/DetailedSales.vue";

export default {
  components: {
    DetailedRepliesModal,
    DetailedNumbersModal,
    DetailedLeadsModal,
    DetailedSalesModal,
  },
  props: {
    selectYear: {
      type: Number,
      default: null,
      required: false,
    },
    selectMonth: {
      type: Number,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      icoMarketingCard: require("@/assets/images/icons/dashboard-ceo/ico_marketing_card.png"),
      leadsModalIsOpen: false,
      sizeScreen: 0,
      investment: 0,
      replies: 0,
      leads: 0,
      numbers: 0,
      numbersModalIsOpen: false,
      appointments: 0,
      sales: 0,
      roi: 0, // usar en porcentaje
      initial_payments: 0, // usar en hover Apps
      repliesModalIsOpen: false,
      statusService: false,
      salesModalIsOpen: false,
    };
  },
  watch: {
    selectYear(value) {
      this.getDataMarketing();
    },
    selectMonth(value) {
      this.getDataMarketing();
    },
  },
  mounted() {
    this.getDataMarketing();

    // responsive font
    setTimeout(() => {
      window.addEventListener("resize", this.checkScreenSize);
      this.checkScreenSize();
    }, 500);
  },
  methods: {
    checkScreenSize() {
      setTimeout(() => {
        const componentMarketing = this.$refs.bCarMarketingRoi;
        this.sizeScreen =
          componentMarketing && componentMarketing.clientWidth > 0
            ? componentMarketing.clientWidth
            : 0;
      }, 500);
      // console.log(this.sizeScreen);
    },
    titleCard() {
      let size = "18px";
      if (this.sizeScreen >= 407 && this.sizeScreen < 468) {
        size = "18px";
      } else if (this.sizeScreen >= 274 && this.sizeScreen < 407) {
        size = "13px";
      } else if (this.sizeScreen < 274) {
        size = "16px";
      }
      return `display:block;font-size:${size};font-weight:600;width:100%`;
    },
    async getDataMarketing() {
      try {
        this.statusService = true;
        const params = {
          year: this.selectYear,
          month: this.selectMonth,
        };
        const { data } = await DashboardServive.getMarketingRoiIndex(params);
        // console.log(data);
        this.investment = this.formatterMoney(data.investment);
        this.replies = data.replies;
        this.leads = data.leads;
        this.numbers = data.numbers;
        this.appointments = data.appointments;
        this.sales = data.sales;
        // eslint-disable-next-line radix
        this.roi = parseInt(data.roi);
        this.initial_payments = this.formatterMoney(data.initial_payments);
        this.statusService = false;
      } catch (error) {
        this.statusService = false;
        this.showErrorSwal(error);
      }
    },
    formatterMoney(var_num) {
      return Number(var_num).toLocaleString("en-US", {
        style: "decimal",
        maximumFractionDigits: 10,
        minimumFractionDigits: 2,
      });
    },
    roiColor() {
      // eslint-disable-next-line radix
      const roi = parseInt(this.roi);
      if (roi > 70) {
        return "bg-success";
      }
      if (roi > 40 && roi <= 70) {
        return "bg-warning";
      }
      return "bg-danger";
    },
    openRepliesModal() {
      this.repliesModalIsOpen = true;
    },
    closeRepliesModal() {
      this.repliesModalIsOpen = false;
    },
    openNumbersModal() {
      this.numbersModalIsOpen = true;
    },
    closeNumbersModal() {
      this.numbersModalIsOpen = false;
    },
    openLeadsModal() {
      this.leadsModalIsOpen = true;
    },
    closeLeadsModal() {
      this.leadsModalIsOpen = false;
    },
    openSalesModal() {
      this.salesModalIsOpen = true;
    },
    closeSalesModal() {
      this.salesModalIsOpen = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.bg_dark_card {
  background: rgba(17, 17, 19, 1);
  box-shadow: 0px 0px 15px 0px #ffffff26 !important;
}
.bg_white_card {
  background: #fff;
  box-shadow: 0px 0px 15px 0px #00000026 !important;
}

.b_card_content {
  border-radius: 18px;
  height: 523px;

  .b_avatar_color {
    background: rgba(167, 197, 255, 1) !important;
  }

  .title_content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .ico_content {
    width: 80%;
    height: 83%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@mixin piramide($height, $width, $background, $clippath) {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: $height;
  width: $width;
  background: $background;
  clip-path: $clippath;
}

.content_piramide {
  margin-top: 20px;
  height: 400px;

  .piramide_1 {
    @include piramide(
      125px,
      100%,
      rgba(36, 83, 186, 1),
      polygon(5% 0%, 95% 0%, 84% 100%, 16% 100%)
    );
    // polygon(3% 0%, 96% 0%, 78% 100%, 21% 100%)

    //piramide_1--dark
    &--dark {
      span {
        color: rgba(17, 17, 19, 1);
      }
    }

    span {
      text-align: center;
      font-size: 16px;
      span {
        font-weight: bold;
      }
    }
  }

  .piramide_2 {
    margin-top: 20px;
    @include piramide(
      102px,
      100%,
      rgba(63, 122, 250, 1),
      polygon(17% 0%, 83% 0%, 75% 95%, 25% 95%)
    );
    // polygon(23% 0%, 76% 0%, 62% 95%, 38% 95%)
    //piramide_2--dark
    &--dark {
      span {
        color: rgba(17, 17, 19, 1);
      }
    }

    span {
      text-align: center;
      font-size: 16px;
      span {
        font-weight: bold;
      }
    }
  }

  .piramide_3 {
    @include piramide(
      115px,
      100%,
      rgba(210, 255, 194, 1),
      polygon(25% 0%, 75% 0%, 69% 80%, 31% 80%)
    );
    // polygon(40% 0%, 60% 0%, 50% 80%, 50% 78%)
    margin-top: 15px;
    align-items: flex-start;

    .content_span {
      text-align: center;
      font-size: 13px;
      font-weight: bold;
      color: rgba(0, 198, 80, 1);
      margin-top: 5px;
    }

    //piramide_3--dark
    &--dark {
      background-color: rgba(194, 215, 255, 1);
      .content_span {
        color: rgba(17, 17, 19, 1);
      }
    }
  }
}

.content_roi {
  height: 36px;
  max-width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  // color:rgba(255, 142, 0, 1) !important;
  background: rgba(253, 222, 183, 1);
  font-weight: bold;
}

.loading_roi {
  position: absolute;
  top: 0px;
  height: 102%;
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(253, 253, 253);

  &--dark {
    background: rgba(17, 17, 19, 1);
  }
}
.span-hover-marketing-roi-white:hover {
  color: white !important;
}
.span-hover-marketing-roi-black:hover {
  color: black !important;
}
.span-hover-marketing-roi-white {
  color: black !important;
}
.span-hover-marketing-roi-black {
  color: white !important;
}
.span-hover-marketing-roi-green-light {
  color: #00c650 !important;
}
.span-hover-marketing-roi-green-dark {
  color: black !important;
}
.span-hover-marketing-roi-green-light:hover {
  color: black !important;
}
.span-hover-marketing-roi-green-dark:hover {
  color: #00c650 !important;
}
</style>
