<template>
  <div>
    <b-modal
      ref="modal-tracking-migration-status"
      size="md"
      hide-footer
      scrollable
      @hidden="$emit('hidden')"
    >
      <template #modal-title>
        <span class="text-uppercase font-weight-bolder">
          Tracking Meta Ad Migration Status
        </span>
      </template>
      <template #default>
        <b-table
          id="tracking-migration-status-table"
          ref="tracking-migration-status-table"
          slot="table"
          class="position-relative table-new-customization"
          empty-text="No matching records found"
          sticky-header="70vh"
          primary-key="id"
          responsive="md"
          show-empty
          no-border-collapse
          no-provider-filtering
          :busy.sync="isBusy"
          :fields="fields"
          :items="myProvider"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(type)="data">
            <span>{{ getTypeName(data.item.type) }}</span>
          </template>
          <template #cell(date)="data">
            <span>{{ data.item.date | myGlobal }}</span>
          </template>
          <template #cell(status)="data">
            <b-badge :variant="getVariant(data.item.status).variant">
              {{ getVariant(data.item.status).name }}
            </b-badge>
          </template>
          <template #cell(created_at)="data">
            <span>{{ data.item.created_at | myGlobalDay }}</span>
          </template>
          <template #cell(updated_at)="data">
            <span>{{ data.item.updated_at | myGlobalDay }}</span>
          </template>
        </b-table>
      </template>
    </b-modal>
  </div>
</template>
<script>
import MetaService from "@/views/creative/views/meta/service/meta.service.js";
export default {
  name: "TrackingMigrationStatusModal",
  data() {
    return {
      isBusy: false,
      fields: [
        {
          key: "type",
          label: "Type of Migration",
          sortable: false,
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "date",
          label: "Migrated Date",
          sortable: false,
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "status",
          label: "Status",
          sortable: false,
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "created_at",
          label: "Created At",
          sortable: false,
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "updated_at",
          label: "Updated At",
          sortable: false,
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.toggleModal("modal-tracking-migration-status");
  },
  methods: {
    async myProvider() {
      try {
        this.addPreloader();
        const { data } = await MetaService.getTrackingMigrateStatus();
        return data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    getVariant(status) {
      const statusMap = {
        IN_PROCESS: "light-primary",
        COMPLETED: "light-success",
        FAILED: "light-danger",
      };
      const variant = statusMap[status];
      const name = status.replace(/_/g, " ");
      return { variant, name };
    },
    getTypeName(type) {
      return type.replace(/_/g, " ");
    },
  },
};
</script>
  <style lang="scss">
</style>