<template>
  <div class="chat-sentiment-grid">
    <filter-slot
      class="filter-slot-new-customization"
      :filter="[]"
      :total-rows="filterSlot.totalRows"
      :paginate="filterSlot.paginate"
      :start-page="filterSlot.startPage"
      :to-page="filterSlot.toPage"
      :filter-principal="filterSlot.filterPrincipal"
      :top-paginate="false"
      :input-width="60"
      showMinimal
      @reload="$refs['chat-sentiment-grid'].refresh()"
    >
      <template #buttons-filter-end>
        <div class="d-flex">
          <div class="d-flex gap-5">
            <div
              class="th d-flex justify-content-center cursor-pointer"
              style="
                width: 40px;
                height: 40px;
                border-radius: 50%;
                position: relative;
              "
              v-for="(row, index) in listSentiment"
              :key="index"
              :class="row.class"
              v-b-tooltip.hover
              :title="row.label"
              @click="selectSentiment = row.label"
            >
              <div v-if="getSelectSentiment == row.label" class="link arrow">
                <feather-icon
                  icon="ChevronDownIcon"
                  size="18"
                  :class="row.colorText"
                ></feather-icon>
              </div>
              <img
                v-if="row.label == 'Positive'"
                src="@/assets/images/icons/happyFace.svg"
                style="max-width: 40px"
                alt=""
              />
              <img
                v-if="row.label == 'Neutral'"
                src="@/assets/images/icons/NeutralFace.svg"
                style="max-width: 40px"
                alt=""
              />
              <img
                v-if="row.label == 'Negative'"
                src="@/assets/images/icons/NegativeFace.svg"
                style="max-width: 40px"
                alt=""
              />
              <img
                v-if="row.label == 'Mixed'"
                src="@/assets/images/icons/MixedFace.svg"
                style="max-width: 40px"
                alt=""
              />
            </div>
          </div>
          <div style="width: 300px" class="d-flex">
            <div
              class="align-self-center absolute-linker"
              :class="{ dark: isDarkSkin }"
              style="width: 260px"
            >
              <div
                class="d-flex justify-content-between align-items-center h-full px-1"
              >
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="20"
                  class="cursor-pointer"
                  @click="prev"
                />
                <span class="font-size:15px">
                  {{ months[dataMonth - 1] }} {{ dataYear }}
                </span>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="20"
                  class="cursor-pointer"
                  @click="next"
                />
              </div>
            </div>
          </div>
        </div>
      </template>

      <b-table
        id="chat-sentiment-grid"
        ref="chat-sentiment-grid"
        slot="table"
        class="position-relative table-new-customization"
        empty-text="No matching records found"
        sticky-header="60vh"
        primary-key="id"
        responsive="md"
        show-empty
        no-border-collapse
        no-provider-filtering
        :fields="Fields"
        :items="myProvider"
        :per-page="filterSlot.paginate.perPage"
        :current-page="filterSlot.paginate.currentPage"
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(sender)="data">
          <div>
            {{ data.item.sender.name }}
          </div>
        </template>
        <template #cell(sentiment)="data">
          <b-badge
            :variant="getBadgeVariant(data.item.sentiment.sentiment)"
            class="text-uppercase"
          >
            {{ data.item.sentiment.sentiment }}
          </b-badge>
        </template>
        <template #cell(score)="data">
          <div class="d-flex align-items-center flex-column">
            <small class="text-success">
              Positive: {{ data.item.sentiment.sentimentScore.positive }}%
            </small>
            <small class="text-danger">
              Negative: {{ data.item.sentiment.sentimentScore.negative }}%
            </small>
            <small class="text-primary">
              Neutral: {{ data.item.sentiment.sentimentScore.neutral }}%
            </small>
            <small class="text-info">
              Mixed: {{ data.item.sentiment.sentimentScore.mixed }}%
            </small>
          </div>
        </template>
        <template #cell(messageCount)="data">
          <b-badge
            style="cursor: pointer"
            variant="light-secondary"
            @click="openModalMessages(data)"
          >
            {{ data.item.messageCount }}
          </b-badge>
        </template>
        <template #cell(lastMessageDate)="data">
          {{ data.item.lastMessageDate | myGlobalDay }}
        </template>
        <template #cell(summary)="data">
          <div class="d-flex align-items-center justify-content-between">
            <feather-icon
              icon="EyeIcon"
              size="12"
              class="cursor-pointer text-primary"
              @click="modalSummary(data)"
              v-b-tooltip.hover
              title="View Summary"
            />

            <feather-icon
              v-if="
                data.item.sentiment &&
                data.item.sentiment.sentimentHistory.length > 1
              "
              icon="ListIcon"
              size="12"
              class="cursor-pointer text-primary"
              @click="modalAnalysisHistory(data)"
              v-b-tooltip.hover
              title="View Analysis History"
            />
          </div>
        </template>
      </b-table>
    </filter-slot>

    <ModalMessages
      v-if="showModalMessages"
      @close="showModalMessages = false"
    />

    <ModalSummary
      v-if="showModalSummary"
      @close="showModalSummary = false"
      :summary="currentSummary"
      :senderName="senderName"
    />
    <ModalAnalysisVersions
      v-if="showAnalysisHistory"
      @close="showAnalysisHistory = false"
      :analysisHistory="analysisHistory"
    />
  </div>
</template>
<script>
import MessengerMetaService from "@/views/commons/Messenger/components/messenger/services/messenger.meta.service.js";
import Fields from "@/views/commons/Messenger/sentiment-analysis/data/chat-sentiment-grid-fields.data.js";
import Filters from "@/views/commons/Messenger/sentiment-analysis/data/chat-sentiment-grid-filters.data.js";
import ModalMessages from "@/views/commons/Messenger/sentiment-analysis/components/modals/ModalMessages.vue";
import ModalSummary from "@/views/commons/Messenger/sentiment-analysis/components/modals/ModalSummary.vue";
import ModalAnalysisVersions from "@/views/commons/Messenger/sentiment-analysis/components/modals/ModalAnalysisVersions.vue";

import { mapActions } from "vuex";
export default {
  name: "ChatSentimentGrid",
  components: {
    ModalMessages,
    ModalSummary,
    ModalAnalysisVersions,
  },
  props: {
    sendSentiment: {
      type: String,
      required: false,
      default: "Positive",
    },
    pageId: {
      type: String,
      required: true,
      default: null,
    },
    year: {
      type: Number,
      required: false,
      default: null,
    },
    month: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isBusy: false,
      Fields,
      filterSlot: {
        Filters,
        paginate: {
          currentPage: 1,
          perPage: 50,
        },
        startPage: 0,
        toPage: 0,
        totalRows: 0,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Search by lead name...",
          model: "",
        },
      },
      selectSentiment: "Positive",
      showModalSummary: false,
      currentSummary: "",
      senderName: "",
      idChat: null,
      showModalMessages: false,
      listSentiment: [
        {
          key: "negative",
          label: "Negative",
          sortable: false,
          visible: true,
          class: "bg-danger text-white text-center th-sentiment ",
          route: "/assets/images/icons/NegativeFace.svg",
          colorText: "text-danger",
        },
        {
          key: "positive",
          label: "Positive",
          sortable: false,
          visible: true,
          class: "bg-success text-white text-center th-sentiment ",
          route: "@/assets/images/icons/happyFace.svg",
          colorText: "text-success",
        },
        {
          key: "neutral",
          label: "Neutral",
          sortable: false,
          visible: true,
          class: "bg-primary text-white text-center th-sentiment ",
          route: "@/assets/images/icons/NeutralFace.svg",
          colorText: "text-primary",
        },
        {
          key: "mixed",
          label: "Mixed",
          sortable: false,
          visible: true,
          class: "bg-info text-white text-center th-sentiment",
          route: "@/assets/images/icons/MixedFace.svg",
          colorText: "text-info",
        },
      ],
      dataYear: null,
      dataMonth: null,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      analysisHistory: [],
      showAnalysisHistory: false,
    };
  },
  computed: {
    getSelectSentiment() {
      return this.selectSentiment;
    },
  },
  created() {
    this.selectSentiment = this.sendSentiment;
    this.dataYear = this.year;
    this.dataMonth = this.month;
  },
  methods: {
    ...mapActions({
      A_SET_CURRENT_MESSENGER_CONVERSATION_SIDEBAR:
        "MessengerMetaStore/A_SET_CURRENT_MESSENGER_CONVERSATION_SIDEBAR",
    }),

    async myProvider() {
      try {
        const params = {
          npage: this.filterSlot.paginate.currentPage,
          perpage: this.filterSlot.paginate.perPage * 1,
          pageId: this.pageId,
          sentimentType: this.selectSentiment,
          searchText: this.filterSlot.filterPrincipal.model,
          year: this.dataYear,
          month: this.dataMonth,
        };

        const { data } = await MessengerMetaService.getChatSentimentAnalysis(
          params
        );
        this.filterSlot.totalRows = data.total;
        this.filterSlot.startPage = data.from ?? 0;
        this.filterSlot.toPage = data.to ?? 0;
        this.filterSlot.paginate.currentPage = data.currentPage;
        this.filterSlot.paginate.perPage = data.perPage;
        // data.data.forEach((element) => {
        //   element.summary = element.summary.replaceAll("**", "<b>");
        //   element.summary = element.summary.replaceAll("**", "<b/>");
        //   element.summary = element.summary.replaceAll(/\n/g, "<br>");
        //   element.summary = element.summary.replaceAll(/\\n/g, "<br>");
        // });
        return data.data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    getBadgeVariant(sentiment) {
      const badgeVariants = {
        Positive: "light-success",
        Negative: "light-danger",
        Neutral: "light-primary",
        Mixed: "light-info",
      };
      const badgeVariant = badgeVariants[sentiment] ?? "light-secondary";
      return badgeVariant;
    },
    refresh() {
      this.$refs["chat-sentiment-grid"].refresh();
    },
    modalSummary(data) {
      this.currentSummary = data.item.summary;
      this.senderName = data.item.sender.name;
      this.showModalSummary = true;
      this.idChat = data.item._id;
    },
    modalAnalysisHistory(data) {
      this.analysisHistory = data.item.sentiment.sentimentHistory;
      this.showAnalysisHistory = true;
    },
    openModalMessages(data) {
      data.item.chatId = data.item.id;
      this.A_SET_CURRENT_MESSENGER_CONVERSATION_SIDEBAR(data.item);
      this.showModalMessages = true;
      this.idChat = data.item._id;
    },
    async next() {
      if (this.dataMonth < 12) {
        this.dataMonth++;
      } else {
        this.dataMonth = 1;
        this.dataYear++;
      }
      this.refresh();
    },
    async prev() {
      if (this.dataMonth > 1) {
        this.dataMonth--;
      } else {
        this.dataMonth = 12;
        this.dataYear--;
      }
      this.refresh();
    },
  },
  watch: {
    selectSentiment() {
      this.refresh();
    },
  },
};
</script>
<style lang="scss">
.chat-sentiment-grid {
  #chat-sentiment-grid {
    .programs_image {
      background-color: #e9e9e9;
      padding: 0.2rem;
      border-radius: 10px;
      width: 2.5rem;
      height: 2.5rem;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .link {
    cursor: pointer;
    height: 20px;
    width: 40px;
    position: absolute;
    animation: move_down 1.5s ease-in-out infinite;
  }

  @keyframes move_down {
    0% {
      transform: translate(0, -26px);
    }
    50% {
      transform: translate(0, -20px);
    }
    100% {
      transform: translate(0, -26px);
    }
  }
}
</style>