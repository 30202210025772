<template>
  <div>
    <svg
      :width="width"
      :height="height"
      :viewBox="'0 0 ' + width + ' ' + height"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4 6.35388C11.7613 6.35388 6.35388 11.7613 6.35388 18.4C6.35388 25.0388 11.7613 30.4462 18.4 30.4462C25.0388 30.4462 30.4462 25.0388 30.4462 18.4C30.4462 11.7613 25.0388 6.35388 18.4 6.35388ZM18.4 27.7693C13.2425 27.7693 9.0308 23.5754 9.0308 18.4C9.0308 13.2246 13.2425 9.0308 18.4 9.0308C23.5576 9.0308 27.7693 13.2246 27.7693 18.4C27.7693 23.5754 23.5576 27.7693 18.4 27.7693Z"
        :fill="color"
        :stroke="stroke"
      />
      <path
        d="M18.4001 16.1692H20.1847C20.9164 16.1692 21.5231 15.5624 21.5231 14.8307C21.5231 14.099 20.9164 13.4923 20.1847 13.4923H19.7385V13.0461C19.7385 12.3144 19.1317 11.7076 18.4001 11.7076C17.6684 11.7076 17.0616 12.3144 17.0616 13.0461V13.8135C16.0087 14.3132 15.277 15.3839 15.277 16.6153C15.277 18.3286 16.6868 19.7384 18.4001 19.7384C18.6499 19.7384 18.8462 19.9347 18.8462 20.1846C18.8462 20.4344 18.6499 20.6307 18.4001 20.6307H16.6154C15.8837 20.6307 15.277 21.2375 15.277 21.9692C15.277 22.7009 15.8837 23.3076 16.6154 23.3076H17.0616V23.7538C17.0616 24.4855 17.6684 25.0923 18.4001 25.0923C19.1317 25.0923 19.7385 24.4855 19.7385 23.7538V22.9864C20.7914 22.4867 21.5231 21.4159 21.5231 20.1846C21.5231 18.4713 20.1133 17.0615 18.4001 17.0615C18.1502 17.0615 17.9539 16.8652 17.9539 16.6153C17.9539 16.3655 18.1502 16.1692 18.4001 16.1692Z"
        :fill="color"
        :stroke="stroke"
      />
      <path
        d="M18.4 3.67692C21.4874 3.67692 24.4855 4.65846 26.9483 6.42523C26.3951 6.58585 25.9846 7.08554 25.9846 7.69231C25.9846 8.424 26.5914 9.03077 27.3231 9.03077H30.8923C31.624 9.03077 32.2308 8.424 32.2308 7.69231V4.12308C32.2308 3.39138 31.624 2.78462 30.8923 2.78462C30.1606 2.78462 29.5538 3.39138 29.5538 4.12308V5.06892C26.4486 2.46338 22.5046 1 18.4 1C8.79877 1 1 8.79877 1 18.4C1 19.1317 1.60677 19.7385 2.33846 19.7385C3.07015 19.7385 3.67692 19.1317 3.67692 18.4C3.67692 10.28 10.28 3.67692 18.4 3.67692ZM34.4615 17.0615C33.7298 17.0615 33.1231 17.6683 33.1231 18.4C33.1231 26.52 26.52 33.1231 18.4 33.1231C15.3126 33.1231 12.3145 32.1415 9.85169 30.3748C10.4049 30.2141 10.8154 29.7145 10.8154 29.1077C10.8154 28.376 10.2086 27.7692 9.47692 27.7692H5.90769C5.176 27.7692 4.56923 28.376 4.56923 29.1077V32.6769C4.56923 33.4086 5.176 34.0154 5.90769 34.0154C6.63938 34.0154 7.24615 33.4086 7.24615 32.6769V31.7311C10.3514 34.3366 14.2954 35.8 18.4 35.8C28.0012 35.8 35.8 28.0012 35.8 18.4C35.8 17.6683 35.1932 17.0615 34.4615 17.0615Z"
        :fill="color"
        :stroke="stroke"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      required: false,
      default: 37,
    },
    height: {
      type: Number,
      required: false,
      default: 37,
    },
    color: {
      type: String,
      required: false,
      default: "white",
    },
    stroke: {
      type: String,
      required: false,
      default: "#FF8E00",
    },
  },
};
</script>

