<template>
  <div>
    <b-modal
      id="modal-appointments-grid"
      ref="modal-appointments-grid"
      header-bg-variant="transparent border-bottom border-bottom-2"
      size="caro"
      header-class="p-0"
      title="Sales Detail"
      modal-class="modal-primary"
      scrollable
      @hidden="$emit('hidden')"
      hide-footer
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <span>
            Appointments -
            {{ checkToday }}</span
          >
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="$emit('hidden')"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>
      <template #default>
        <filter-slot
          :id="`appointments-details-filters`"
          :filter="[]"
          :total-rows="filterSlot.totalRows"
          :paginate="filterSlot.paginate"
          :start-page="filterSlot.startPage"
          :to-page="filterSlot.toPage"
          :filter-principal="filterSlot.filterPrincipal"
          :top-paginate="false"
          @reload="$refs['appointments-details-grid'].refresh()"
        >
          <b-table
            ref="appointments-details-grid"
            slot="table"
            class="blue-scrollbar table-appointments-customized px-1"
            primary-key="id"
            empty-text="No matching records found"
            responsive="md"
            sticky-header="50vh"
            show-empty
            no-provider-filtering
            :busy.sync="isBusy"
            :fields="Fields"
            :items="myProvider"
            :per-page="filterSlot.paginate.perPage"
            :current-page="filterSlot.paginate.currentPage"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(lead_name)="data">
              <span v-if="data.item.lead_name">
                <router-link
                  :class="textLink"
                  :to="`/crm/leads/${data.item.lead_id}`"
                  target="_blank"
                >
                  {{ data.item.lead_name }}
                </router-link>
              </span>
              <span v-else>
                <router-link
                  :class="textLink"
                  :to="`/crm/leads/${data.item.lead_id}`"
                  target="_blank"
                >
                  {{ data.item.nickname }}
                </router-link>
              </span>
            </template>
            <template #cell(seller_name)="data">
              <div class="d-flex justify-content-between mt-1">
                <div style="font-size: 13px">
                  <b-avatar
                    :src="data.item.user_image"
                    size="1.5rem"
                    variant="light-primary"
                    class="mr-1"
                  />
                  {{ data.item.seller_name }}
                </div>
              </div>
            </template>
            <template #cell(programs)="data">
              <div
                v-if="data.item.programs"
                class="d-flex justify-content-center align-items-center flex-wrap modal-appointments-grid"
                style="gap: 4px"
              >
                <div
                  v-for="(item, index) in data.item.programs"
                  :key="index"
                  class="programs_image"
                >
                  <img
                    :src="getProgramImageRoute(item.program_id)"
                    v-b-tooltip.right="item.program_name"
                    alt="img_services"
                  />
                </div>
              </div>
              <div
                v-else
                class="d-flex justify-content-center align-items-center"
              >
                -
              </div>
            </template>
            <template #cell(call_answered)="data">
              <b-badge
                :variant="
                  data.item.call_answered ? 'light-success' : 'light-danger'
                "
                class="text-capitalize"
              >
                {{ data.item.call_answered ? "YES" : "NO" }}
              </b-badge>
            </template>
            <template #cell(sale_made)="data">
              <div>
                <b-badge
                  :id="`sale_made-${data.item.id}`"
                  :variant="
                    data.item.sale_made ? 'light-success' : 'light-danger'
                  "
                  class="text-capitalize"
                >
                  {{ data.item.sale_made ? "YES" : "NO" }}
                </b-badge>

                <b-tooltip
                  v-if="data.item.is_attended && !data.item.sale_made"
                  :target="`sale_made-${data.item.id}`"
                  triggers="hover"
                  placement="top"
                >
                  <span>Pending Sale</span>
                </b-tooltip>

                <b-badge
                  v-if="
                    parseInt(data.item.total_sales) > 0 && data.item.sale_made
                  "
                  variant="light-primary"
                  v-b-tooltip.hover.top="`Total Sales`"
                  class="ml-1"
                >
                  {{ data.item.total_sales }}
                </b-badge>
              </div>
            </template>
            <template #cell(total_reschedule)="data">
              <b-badge
                variant="light-primary"
                class="text-capitalize cursor-pointer"
                v-b-tooltip.hover.top="`Reschedule Tracking`"
                @click="
                  openModalRescheduleTracking(
                    data.item.event_id,
                    data.item.lead_name
                  )
                "
              >
                {{ data.item.total_reschedule }}
              </b-badge>
            </template>
            <template #cell(appointment_date)="data">
              <div
                class="d-flex align-items-center justify-content-center flex-column"
              >
                <div>{{ data.item.date | formatDate }}</div>
                <div>
                  {{ data.item.from | formatHour }} -
                  {{ data.item.to | formatHour }}
                </div>
              </div>
            </template>
            <template #cell(creator_name)="data">
              <div
                class="d-flex align-items-center justify-content-center flex-column"
              >
                <div>{{ data.item.creator_name }}</div>
                <div>{{ data.item.created_at | myGlobalDay }}</div>
              </div>
            </template>
          </b-table>
        </filter-slot>
      </template>
    </b-modal>
    <modal-event-reschedule-tracking
      :event="event"
      :lead-name="leadName"
      v-if="showModalEventRescheduleTracking"
      @hidden="showModalEventRescheduleTracking = false"
    />
  </div>
</template>
<script>
import DashboardService from "@/views/crm/views/dashboard/service/dashboard.service";
import Fields from "@/views/crm/views/dashboard/components/appointments/data/fields-appointments-grid.data.js";
import moment from "moment";
import ModalEventRescheduleTracking from "@/views/crm/views/dashboard/components/appointments/modal/ModalEventRescheduleTracking.vue";
import { mapActions } from "vuex";
export default {
  name: "ModalAppointmentsGrid",
  components: {
    ModalEventRescheduleTracking,
  },
  filters: {
    formatDate(value) {
      if (!value) return "-";
      return moment(value).format("MM/DD/YYYY");
    },
    formatHour(value) {
      if (!value) return "-";
      return moment(value, "HH:mm:ss").format("hh:mm A");
    },
  },
  props: {
    programId: {
      type: Number,
      required: false,
      default: null,
    },
    sellerId: {
      type: Number,
      required: false,
      default: null,
    },
    date: {
      type: String,
      required: false,
      default: null,
    },
    isToday: {
      type: Boolean,
      required: false,
      default: true,
    },
    answerOption: {
      type: Number,
      required: false,
      default: null,
    },
    saleOption: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isBusy: false,
      Fields,
      filterSlot: {
        filters: [],
        paginate: {
          currentPage: 1,
          perPage: 50,
        },
        startPage: 0,
        toPage: 0,
        totalRows: 0,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Lead name...",
          model: "",
        },
      },
      defaultProgramOptions: [
        {
          id: null,
          value: "All programs",
          name: "All programs",
          initial: "AP",
        },
      ],
      defaultSellerOptions: { id: null, user_name: "All sellers" },
      triggerProvider: false,
      showModalEventRescheduleTracking: false,
      event: {},
      leadName: "",
    };
  },
  computed: {
    checkToday() {
      if (this.isToday) {
        return moment(this.date).format("MMMM DD, YYYY");
      }
      return moment(this.date).format("MMMM YYYY");
    },
  },
  async created() {},
  mounted() {
    this.toggleModal("modal-appointments-grid");
  },
  methods: {
    ...mapActions({
      A_GET_EVENT: "CrmEventStore/A_GET_EVENT",
    }),

    async myProvider() {
      try {
        const params = {
          program_id: this.programId,
          seller_id: this.sellerId,
          call_answered: this.answerOption,
          sale_made: this.saleOption,
          search_txt: this.filterSlot.filterPrincipal.model,
          date: this.date,
          is_today: this.isToday,
          npage: this.filterSlot.paginate.currentPage,
          per_page: this.filterSlot.paginate.perPage,
        };
        const { data } =
          await DashboardService.getAppointmentsDetailsDashboardData(params);
        this.filterSlot.totalRows = data.total;
        this.filterSlot.startPage = data.from ?? 0;
        this.filterSlot.toPage = data.to ?? 0;
        this.filterSlot.paginate.currentPage = data.current_page;
        this.filterSlot.paginate.perPage = data.per_page;
        return data.data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async openModalRescheduleTracking(id, lead_name) {
      try {
        const { data } = await this.A_GET_EVENT({ id });
        this.event = data[0];
        this.leadName = lead_name;
        this.showModalEventRescheduleTracking = true;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    addSearchIconInFilter() {
      const iconElement = document.createElement("i");
      iconElement.setAttribute("data-v-04a348xasdg48", "");
      iconElement.classList.add("search-icon");
      const div = document.createElement("div");
      div.setAttribute("data-v-04a348xasdg48", "");
      div.appendChild(iconElement);
      setTimeout(() => {
        const inputFilterParent = document.querySelector(
          "#inner-duo-filter-container"
        );
        const { firstChild } = inputFilterParent;
        inputFilterParent.insertBefore(iconElement, firstChild);
      }, 10);
    },
  },
};
</script>
<style lang="scss">
#modal-appointments-grid {
  .modal-appointments-grid {
    gap: 0.5rem;
    .programs_image {
      background-color: #e9e9e9;
      padding: 0.2rem;
      border-radius: 10px;
      width: 2.5rem;
      height: 2.5rem;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .table-appointments-customized {
    table {
      border-collapse: separate !important;
      border-spacing: 0px 8px !important;

      tr td {
        border: none !important;
        background: #fafafa !important;
        padding-top: 15px !important;
        padding-bottom: 15px !important;
      }

      tr td:first-child {
        border-radius: 10px 0 0 10px !important;
      }

      tr td:last-child {
        border-radius: 0 10px 10px 0 !important;
        background: #fafafa !important;
      }
    }
  }

  #appointments-details-filters {
    #container-filters-selector {
      margin: 20px 10px 15px 10px !important;
      padding: 20px 30px !important;
      border-radius: 10px !important;
      background-color: #fafafa !important;
    }
    #filter-slot-seach-input {
      border-radius: 10px !important;
      padding-top: 25px !important;
      padding-bottom: 25px !important;
      padding-left: 45px !important;
    }
    #filter-slot-search-icon {
      display: none;
    }
    #inner-duo-filter-container {
      width: 60% !important;
      button {
        border-radius: 10px !important;
        div {
          padding: 3px !important;
          svg {
            width: 20px !important;
            height: 20px !important;
          }
        }
      }
      .search-icon {
        position: absolute;
        width: 22px;
        height: 22px;
        background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="50px" stroke="%23ffffff" height="50px"><path d="M 21 3 C 11.621094 3 4 10.621094 4 20 C 4 29.378906 11.621094 37 21 37 C 24.710938 37 28.140625 35.804688 30.9375 33.78125 L 44.09375 46.90625 L 46.90625 44.09375 L 33.90625 31.0625 C 36.460938 28.085938 38 24.222656 38 20 C 38 10.621094 30.378906 3 21 3 Z M 21 5 C 29.296875 5 36 11.703125 36 20 C 36 28.296875 29.296875 35 21 35 C 12.703125 35 6 28.296875 6 20 C 6 11.703125 12.703125 5 21 5 Z"/></svg>')
          no-repeat;
        background-size: contain;
        left: 30px;
        z-index: 999;
      }
    }
    .page-item {
      button {
        border-radius: 5px !important;
        margin-left: 5px !important;
        margin-right: 5px !important;
      }
      span {
        border-radius: 5px !important;
        margin-left: 5px !important;
        margin-right: 5px !important;
      }
    }
    #filters-selector {
      display: flex;
      flex-direction: row-reverse !important;
      justify-content: space-between;
      #filters-selector__pages {
        justify-content: flex-end !important;
      }
    }
  }
}

.dark-layout {
  #modal-appointments-grid {
    .table-appointments-customized {
      table {
        tr td {
          background: #1b1b1e !important;
        }

        tr td:last-child {
          background: #1b1b1e !important;
        }
      }
    }

    #appointments-details-filters {
      #container-filters-selector {
        background-color: #1b1b1e !important;
      }
    }
  }
}

.header-modal {
  position: relative;
  padding: 20px 0 20px 15px;
  background: rgba(22, 84, 204, 1);
  width: 100%;
  font-size: 22px;
  display: flex;
  justify-content: space-betweeen;

  span {
    font-size: 1.6rem;
    font-weight: bold;
    margin-left: 8px;
    color: #ffffff;
  }

  .container-icons {
    background-color: #ffffff;
    padding: 2.7px 6.37px;
    border-radius: 0.357rem;
    width: 31px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    transition: all 0.23s ease 0.1s;

    &--dark {
      background-color: #17171a;
    }

    &:hover {
      transform: translate(-7px, 5px);
      cursor: pointer;
    }
  }
}
</style>