<template>
  <div>
    <b-modal
      ref="modalName"
      title-class="h3 text-white"
      :title="`CONFIRM COLLECT`"
      size="lg"
      @hidden="closeModal()"
      centered
      no-close-on-backdrop
      modal-class="custom-modal-amg"
    >
      <validation-observer ref="fileForm">
        <b-row>
          <b-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              name="check_number"
              rules="required"
            >
              <b-form-group label="Billing Date">
                <b-form-datepicker
                  id="date"
                  v-model="form.billing_date"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  :max="new Date()"
                  :class="errors[0] ? 'is-invalid' : ''"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              name="check_number"
              rules="required"
            >
              <b-form-group label="Check number">
                <b-form-input
                  id="date"
                  v-model="form.check_number"
                  :state="errors.length > 0 ? false : null"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <b-button variant="custom-amg" @click="confirm()"> Confirm </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import DashboardService from "@/views/ceo/service-dashboard/dashboard.service";
import { mapGetters, mapActions } from "vuex";
import PreviewPdf from "@/views/debt-solution/views/court-info/components/PreviewPdf.vue";
import ImageViewer from "@/views/commons/components/paragon-soft/components/ImageViewer.vue";

export default {
  props: {
    checkData: {
      type: Object,
      required: true,
    },
  },
  components: {
    PreviewPdf,
    ImageViewer,
  },
  data() {
    return {
      form: {
        check_number: null,
        billing_date: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.toggleModal("modalName");
  },
  methods: {
    ...mapActions({
      refreshExpensesCounter: "ExpensesRequest/refreshExpensesCounter",
    }),
    async confirm() {
      const result = await this.$refs.fileForm.validate();
      if (!result) return;
      const confirm = await this.showConfirmSwal(
        "Are you sure the e-check was collected?"
      );
      if (!confirm.isConfirmed) return false;

      try {
        this.addPreloader();
        const params = {
          check_number: this.form.check_number,
          billing_date: this.form.billing_date,
          check_id: this.checkData.check_id,
          user_id: this.currentUser.user_id,
          department_expense_id: this.checkData.department_expense_id,
          type: this.checkData.type,
          is_collected: true,
        };
        const data = await DashboardService.collectEcheck(params);
        if (data.success) {
          this.showToast(
            "success",
            "top-right",
            "Success",
            "CheckIcon",
            "Successfully process"
          );
          this.refreshExpensesCounter(true);
          this.$emit("reload");
          this.$emit("close");
        } else {
          this.showToast(
            "danger",
            "top-right",
            "Oop!",
            "AlertOctagonIcon",
            "Failed process"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>