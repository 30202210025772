<template>
  <b-modal
    v-model="ownControl"
    :title="titleModal"
    title-tag="h3"
    hide-footer
    size="lg"
    header-bg-variant="transparent"
    modal-class="custom-modal-amg"
    header-class="p-0"
    no-close-on-backdrop
    @hidden="closeModal"
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bold">{{ titleModal }}</h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="closeModal"
          />
        </div>
      </div>
    </template>
    <ValidationObserver ref="form">
      <b-row class="font-bureau-style">
        <b-col>
          <div class="form-group">
            <label>Bank</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <v-select
                v-model="bank_id"
                :reduce="(el) => el.id"
                :options="banks"
                :clearable="false"
                :style="
                  errors[0] ? 'border: 1px solid red; border-radius: 5px;' : ''
                "
                label="name"
              />
            </ValidationProvider>
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <div class="form-group">
            <label>Card Holder Name</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-input
                class="border-hover-p"
                id="card_holder"
                name="card_holder"
                v-model="form.cardholdername"
                type="text"
                placeholder="Card Holder Name"
                :class="errors[0] ? 'is-invalid' : ''"
                :disabled="modalView"
              />
            </ValidationProvider>
          </div>
        </b-col>

        <b-col cols="12" md="6" v-if="modalView">
          <div class="form-group">
            <label>Card Number</label>
            <b-input-group>
              <template #append>
                <!-- <b-button
                  v-if="!showCard"
                  variant="primary"
                  class="p-0 m-0"
                  style="width: 50px"
                  @click="eyeCard"
                >
                  <feather-icon icon="EyeOffIcon" class="pointer" />
                </b-button> -->
                <b-button
                  variant="primary"
                  class="p-0 m-0"
                  style="width: 50px"
                  @click="eyeCard"
                >
                  <feather-icon icon="EyeIcon" class="pointer" />
                </b-button>
              </template>
              <b-form-input v-model="cardNumber" />
            </b-input-group>
          </div>
        </b-col>
        <b-col cols="12" md="6" v-else>
          <div class="form-group">
            <label>Card Number</label>
            <b-row>
              <b-col cols="3">
                <ValidationProvider
                  rules="required|length:4|is_numeric"
                  v-slot="{ errors }"
                >
                  <b-form-input
                    class="border-hover-p"
                    ref="input-1"
                    @input="activeFocus(1, 4)"
                    v-model="cardnumber1"
                    type="text"
                    :class="errors[0] ? 'is-invalid' : ''"
                  />
                </ValidationProvider>
              </b-col>
              <b-col cols="3">
                <ValidationProvider
                  rules="required|length:4|is_numeric"
                  v-slot="{ errors }"
                >
                  <b-form-input
                    class="border-hover-p"
                    ref="input-2"
                    @input="activeFocus(2, 4)"
                    v-model="cardnumber2"
                    type="text"
                    :class="errors[0] ? 'is-invalid' : ''"
                  />
                </ValidationProvider>
              </b-col>
              <b-col cols="3">
                <ValidationProvider
                  rules="required|length:4|is_numeric"
                  v-slot="{ errors }"
                >
                  <b-form-input
                    class="border-hover-p"
                    ref="input-3"
                    @input="activeFocus(3, 4)"
                    v-model="cardnumber3"
                    type="text"
                    :class="errors[0] ? 'is-invalid' : ''"
                  />
                </ValidationProvider>
              </b-col>
              <b-col cols="3">
                <ValidationProvider
                  rules="required|min:3|max:4|is_numeric"
                  v-slot="{ errors }"
                >
                  <b-form-input
                    class="border-hover-p"
                    ref="input-4"
                    @input="activeFocus(4, 4)"
                    v-model="cardnumber4"
                    type="text"
                    :class="errors[0] ? 'is-invalid' : ''"
                  />
                </ValidationProvider>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col cols="4" md="2">
          <div class="form-group">
            <label>MM</label>
            <ValidationProvider
              rules="required|length:2|is_numeric"
              v-slot="{ errors }"
            >
              <b-form-input
                class="border-hover-p"
                type="text"
                id="card-expi-month"
                maxlength="2"
                ref="input-5"
                @input="activeFocus(5, 2)"
                v-model="form.card_expi_month"
                :class="errors[0] ? 'is-invalid' : ''"
                :disabled="modalView"
              />
            </ValidationProvider>
          </div>
        </b-col>
        <b-col cols="4" md="2">
          <div class="form-group">
            <label>YY</label>
            <ValidationProvider
              rules="required|length:2|is_numeric"
              v-slot="{ errors }"
            >
              <b-form-input
                class="border-hover-p"
                type="text"
                id="card-expi-year"
                maxlength="2"
                ref="input-6"
                @input="activeFocus(6, 2)"
                v-model="form.card_expi_year"
                :class="errors[0] ? 'is-invalid' : ''"
                :disabled="modalView"
              />
            </ValidationProvider>
          </div>
        </b-col>
        <b-col cols="4" md="2">
          <div class="form-group">
            <label>CVV</label>
            <ValidationProvider
              rules="required|min:3|max:4|is_numeric"
              v-slot="{ errors }"
            >
              <b-form-input
                class="border-hover-p"
                v-model="form.cardsecuritycode"
                ref="input-7"
                id="card-cvv"
                max="4"
                type="text"
                maxlength="4"
                :class="errors[0] ? 'is-invalid' : ''"
                :disabled="modalView"
              />
            </ValidationProvider>
          </div>
        </b-col>
        <b-col cols="4" md="6">
          <div class="form-group">
            <label>Assigned Departments</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <v-select
                v-model="module_id"
                multiple
                :reduce="(el) => el.id"
                :options="modules"
                :clearable="false"
                :style="
                  errors[0] ? 'border: 1px solid red; border-radius: 5px;' : ''
                "
                label="name"
              />
            </ValidationProvider>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="!modalView">
        <b-col md="12" class="mt-2 text-right">
          <b-button
            class="btn-update-sn rounded font-bureau-style text-white"
            :class="isDarkSkin ? 'custom-button-dark' : 'custom-button-light'"
            variant="primary"
            @click="createCard"
          >
            <i class="fas fa-save"></i>
            {{ dataCard == null ? "Create" : "Update" }} credit card
          </b-button>
        </b-col>
      </b-row>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

// Components
import VueGoogleAutocomplete from "vue-google-autocomplete";
import PaymentSolutionsServices from "@/views/commons/components/credit-cards-expenses/services/payment-solutions.services.js";
import DashboardService from "@/views/ceo/service-dashboard/dashboard.service";
import vSelect from "vue-select";

// Services
import { amgApi } from "@/service/axios";
import crmGlobal from "@/views/crm/services/global";

export default {
  components: { VueGoogleAutocomplete, vSelect },
  props: {
    lead: {
      type: Number,
      default: null,
    },
    dataCard: {
      default: null,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      ownControl: false,
      modalView: false,
      showCard: false,
      showCode: false,
      address_create_card_modal: "",
      states: [],
      cards: [],
      moreInfo: "1",
      form: {
        idlead: this.lead,
        card_expi_month: "",
        card_expi_year: "",
        cardnumber: "",
        cardsecuritycode: "",
        city: "",
        state: "",
        country: "United Stated",
        zipcode: "",
        address: "",
        cardholdername: "",
        street: "",
        user: null,
      },
      cardnumber1: "",
      cardnumber2: "",
      cardnumber3: "",
      cardnumber4: "",
      modules: [],
      module_id: null,
      banks: [],
      bank_id: null
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
    }),

    cardCVC() {
      return this.form.cardsecuritycode.length == 3
        ? this.showCode
          ? this.form.cardsecuritycode
          : "XX" + this.form.cardsecuritycode.substr(2)
        : this.showCode
        ? this.form.cardsecuritycode
        : "xxx" + this.form.cardsecuritycode.substr(3);
    },

    cardNumber() {
      if (this.showCard) {
        return this.form.cardnumber;
      } else {
        return "XXXX-XXXX-XXXX-" + this.cardnumber4;
      }
    },
    titleModal() {
      return this.dataCard == null
        ? "Create Credit Card"
        : `Update Credit Card: ${this.dataCard.cardholdername}`;
    },
  },
  methods: {
    async getModules() {
      try {
        this.addPreloader();
        const { data } = await PaymentSolutionsServices.getModules();
        this.modules = data.filter((item) => item.id !== 6);
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async getBanks() {
      try {
        this.addPreloader();
        const data = await DashboardService.getBankCards();
        this.banks = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async getEeuuStates() {
      try {
        const response = await crmGlobal.getStatesEeuu({});

        if (response.status == 200) {
          response.data.map((item) =>
            this.states.push({ value: item.slug, label: item.state })
          );
        }
      } catch (error) {
        throw error;
      }
    },
    activeFocus(index, max) {
      let inputValue = this.$refs?.[`input-${index}`];
      if (inputValue.value.length === max - 1) {
        const nextElement = this.$refs?.[`input-${index + 1}`];
        if (nextElement) nextElement.focus();
      }
    },
    getAddressData(mainAddress) {
      let address = mainAddress.street_number
        ? mainAddress.street_number + " " + mainAddress.route
        : mainAddress.route;

      this.direccion = mainAddress;
      this.address_create_card_modal =
        this.direccion.street_number + " " + this.direccion.route;
      var x = document.getElementById("address_create_card_modal");
      x.value = this.address_create_card_modal;

      this.form.street = address;
      this.form.address = this.address_create_card_modal;
      this.form.city = mainAddress.locality;
      this.form.state = mainAddress.administrative_area_level_1;
      this.form.zipcode = mainAddress.postal_code;
    },
    async createCard() {
      const action = this.dataCard == null ? "create" : "update";
      const validate = await this.$refs.form.validate();
      if (!validate) return;

      const message =
        this.dataCard == null
          ? `Are you sure you want to create this credit card?`
          : `Are you sure you want to update the credit card: ${this.dataCard.cardholdername}?`;
      // const confirm = await this.showConfirmSwal(message);
      // if (!confirm.isConfirmed) return;

      this.form.cardnumber =
        this.cardnumber1 +
        "-" +
        this.cardnumber2 +
        "-" +
        this.cardnumber3 +
        "-" +
        this.cardnumber4;

      const params = {
        id: this.dataCard == null ? null : this.dataCard.id,
        idlead: this.lead,
        card_expi_month: this.form.card_expi_month,
        card_expi_year: this.form.card_expi_year,
        cardnumber: this.form.cardnumber,
        cardsecuritycode: this.form.cardsecuritycode,
        city: this.form.city,
        state: this.form.state,
        country: this.form.country,
        zipcode: this.form.zipcode,
        address: this.form.address,
        cardholdername: this.form.cardholdername,
        street: this.form.street,
        user: this.currentUser.user_id,
        moduleId: this.module_id,
        card_bank_id: this.bank_id
      };

      try {
        this.addPreloader();
        let service =
          this.dataCard == null ? "saveCreditCards" : "UpdateCreditCard";
        const { data } = await PaymentSolutionsServices[service](params);
        if (data.success) {
          this.showToast(
            "success",
            "top-right",
            "Success",
            "CheckIcon",
            "Credit Card created successfully"
          );
          this.$emit("refresh");
          this.closeModal();
        } else {
          this.showToast(
            "danger",
            "top-right",
            "Error",
            "XIcon",
            "Something went wrong!"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    closeModal() {
      this.$emit("close");
    },

    async eyeCard() {
      try {
        if (this.form.cardnumber == "" || this.form.cardnumber == null) {
          const { data } = await crmGlobal.getOriginalTarget({
            id: this.dataCard.id,
          });
          this.form.cardnumber = data[0];
        }
      } catch (error) {
        console.log(error);
        this.form.cardnumber = "";
      } finally {
        this.showCard = !this.showCard;
      }
    },

    eyeCode() {
      this.showCode = !this.showCode;
    },
  },
  async created() {
    await this.getModules();
    await this.getBanks();
    if (this.dataCard != null) {
      this.form.cardholdername = this.dataCard.cardholdername;
      this.form.card_expi_month = this.dataCard.card_expi_month;
      this.form.card_expi_year = this.dataCard.card_expi_year;
      this.form.cardsecuritycode = this.dataCard.cardsecuritycode;
      this.cardnumber1 = this.dataCard.cardnumber.split("-")[0];
      this.cardnumber2 = this.dataCard.cardnumber.split("-")[1];
      this.cardnumber3 = this.dataCard.cardnumber.split("-")[2];
      this.cardnumber4 = this.dataCard.cardnumber.split("-")[3];
      this.module_id = JSON.parse(this.dataCard.module_ids);
      this.bank_id = this.dataCard.card_bank_id
    }
    // else {
    //   await this.getEeuuStates();
    // }

    // if (this.type === "amg-cards") {
    //   this.moreInfo = 0;
    // }
    this.ownControl = true;
  },
};
</script>

<style scoped>
* {
  --primary-color: #3f7afa;
}
.font-bureau-style {
  color: #706989;
}

label {
  font-weight: 300 !important;
}
.border-hover-p:hover,
.border-hover-p:active {
  border: 2px solid #7000ff !important;
  filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.15));
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
.custom-button-dark {
  background-color: var(--primary-color) !important;
  color: black !important;
}
.custom-button-light {
  background-color: var(--primary-color) !important;
  color: white !important;
}
</style>
