<template>
  <b-card no-body class="mb-0">
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refUserListTable"
      class="position-relative"
      :items="users"
      responsive
      :current-page="currentPage"
      :per-page="perPage"
      :fields="fields"
      :filter="searchQuery"
      primary-key="id"
      :sort-by.sync="sortBy"
      sticky-header="50vh"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      @filtered="onFiltered"
    >
      <template #cell(status)="data">
        <status-account :account="data.item" />
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import vSelect from "vue-select"
import GlobalService from "@/views/services/global.service"
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue"
export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  components: {
    vSelect,
    StatusAccount,
  },
  mounted() {
    this.fetchUsers()
  },
  data() {
    return {
      isSortDirDesc: false,
      sortBy: "",
      perPage: 50,
      perPageOptions: [10, 25, 50, 100],
      currentPage: 1,
      totalUsers: 0,
      totalRows: 0,
      users: [],
      fields: [
        {
          key: "client_name",
          label: "Client",
          sortable: true,
        },
        {
          key: "account",
          label: "Account",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },
      ],
      searchQuery: "",
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters["auth/currentUser"]
    },
  },
  methods: {
    async fetchUsers() {
      let params = {
        idnotifica: this.notification.id_historys,
        session: this.currentUser.user_id,
      }
      const users = await GlobalService.getAccountNotifications(params)
      users.forEach((user) => {
        user.validate_sp = 1
      })
      this.totalRows = users.length
      this.users = users
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style></style>
