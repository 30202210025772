import { amgApi } from "@/service/axios";

class NotesService {

    async getAll(payload) {
        try {
            const data = await amgApi.post("/ring-central/call-recording/without-note/index", payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on getAll:", error);
            throw error;
        }
    }

    async update(payload) {
        try {
            const data = await amgApi.post("/ring-central/call-recording/without-note/update", payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on update:", error);
            throw error;
        }
    }

    async generateAutomaticNote(payload) {
        try {
            const data = await amgApi.post("/ring-central/call-recording/without-note/generate-automatic-note", payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on generateAutomaticNote:", error);
            throw error;
        }
    }

    async getUnnotedCallCounters(payload) {
        try {
            const data = await amgApi.post("/ring-central/call-recording/without-note/get-unnoted-call-counters", payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on getUnnotedCallCounters:", error);
            throw error;
        }
    }

    async getContactsByModule(payload) {
        try {
            const data = await amgApi.post("/ring-central/call-recording/without-note/get-contacts-by-module", payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on getDsClients:", error);
            throw error;
        }
    }
    async getAutomaticNote(payload) {
        try {
            const data = await amgApi.post("/ring-central/call-recording/without-note/get-automatic-note", payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on getAutomaticNote:", error);
            throw error;
        }
    }
}

export default new NotesService();
