var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-1"},[_c('b-table',{attrs:{"items":_vm.missingMonthlyPayments,"fields":_vm.visibleFields},scopedSlots:_vm._u([{key:"cell(pay_5)",fn:function(ref){
var item = ref.item;
return [(item.pay_5)?_c('div',[_c('span',{staticClass:"font-weight-bolder text-primary cursor-pointer",on:{"click":function($event){return _vm.ModalClientAutomatic(item, 5, 1)}}},[_vm._v(" "+_vm._s(item.pay_5)+" ")]),_vm._v(" | "),_c('span',{staticClass:"font-weight-bolder text-warning cursor-pointer",on:{"click":function($event){return _vm.ModalClientAutomatic(item, 5, 0)}}},[_vm._v(" "+_vm._s(item.wo_5)+" ")])]):_c('span',[_vm._v(" - ")])]}},{key:"cell(pay_10)",fn:function(ref){
var item = ref.item;
return [(item.pay_10)?_c('div',[_c('span',{staticClass:"font-weight-bolder text-primary cursor-pointer",on:{"click":function($event){return _vm.ModalClientAutomatic(item, 10, 1)}}},[_vm._v(" "+_vm._s(item.pay_10)+" ")]),_vm._v(" | "),_c('span',{staticClass:"font-weight-bolder text-warning cursor-pointer",on:{"click":function($event){return _vm.ModalClientAutomatic(item, 10, 0)}}},[_vm._v(" "+_vm._s(item.wo_10)+" ")])]):_c('span',[_vm._v(" - ")])]}},{key:"cell(pay_15)",fn:function(ref){
var item = ref.item;
return [(item.pay_15)?_c('div',[_c('span',{staticClass:"font-weight-bolder text-primary cursor-pointer",on:{"click":function($event){return _vm.ModalClientAutomatic(item, 15, 1)}}},[_vm._v(" "+_vm._s(item.pay_15)+" ")]),_vm._v(" | "),_c('span',{staticClass:"font-weight-bolder text-warning cursor-pointer",on:{"click":function($event){return _vm.ModalClientAutomatic(item, 15, 0)}}},[_vm._v(" "+_vm._s(item.wo_15)+" ")])]):_c('span',[_vm._v(" - ")])]}},{key:"cell(pay_20)",fn:function(ref){
var item = ref.item;
return [(item.pay_20)?_c('div',[_c('span',{staticClass:"font-weight-bolder text-primary cursor-pointer",on:{"click":function($event){return _vm.ModalClientAutomatic(item, 20, 1)}}},[_vm._v(" "+_vm._s(item.pay_20)+" ")]),_vm._v(" | "),_c('span',{staticClass:"font-weight-bolder text-warning cursor-pointer",on:{"click":function($event){return _vm.ModalClientAutomatic(item, 20, 0)}}},[_vm._v(" "+_vm._s(item.wo_20)+" ")])]):_c('span',[_vm._v(" - ")])]}},{key:"cell(pay_25)",fn:function(ref){
var item = ref.item;
return [(item.pay_25)?_c('div',[_c('span',{staticClass:"font-weight-bolder text-primary cursor-pointer",on:{"click":function($event){return _vm.ModalClientAutomatic(item, 25, 1)}}},[_vm._v(" "+_vm._s(item.pay_25)+" ")]),_vm._v(" | "),_c('span',{staticClass:"font-weight-bolder text-warning cursor-pointer",on:{"click":function($event){return _vm.ModalClientAutomatic(item, 25, 0)}}},[_vm._v(" "+_vm._s(item.wo_25)+" ")])]):_c('span',[_vm._v(" - ")])]}},{key:"cell(pay_30)",fn:function(ref){
var item = ref.item;
return [(item.pay_30)?_c('div',[_c('span',{staticClass:"font-weight-bolder text-primary cursor-pointer",on:{"click":function($event){return _vm.ModalClientAutomatic(item, 30, 1)}}},[_vm._v(" "+_vm._s(item.pay_30)+" ")]),_vm._v(" | "),_c('span',{staticClass:"font-weight-bolder text-warning cursor-pointer",on:{"click":function($event){return _vm.ModalClientAutomatic(item, 30, 0)}}},[_vm._v(" "+_vm._s(item.wo_30)+" ")])]):_c('span',[_vm._v(" - ")])]}}])}),(_vm.showModalClientsAuto)?_c('clients-auto-modal',{attrs:{"send-year":_vm.dataYear,"send-month":_vm.dataMonth,"send-programs-id":_vm.idProgram,"send-programs":_vm.arrPrograms,"day-payment":_vm.dayPayment,"is-paid":_vm.isPaid},on:{"close":function($event){_vm.showModalClientsAuto = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }