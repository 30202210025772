<template>
  <div class="">
    <filter-slot
      :top-paginate="false"
      :filter-principal="filterPrincipal"
      :filter="filters"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="refreshTable"
      @onChangeCurrentPage="onChangeCurrentPage"
      class="filter-slot-new-customization"
    >
      <template #buttons-filter-end>
        <div style="min-width: 280px" class="ml-1 d-flex col-2 gap-5">
          <div v-for="(item, i) in listSentiments" :key="i" cols="1">
            <b-button
              class="text-center h-full d-flex flex-column align-items-center justify-content-center"
              @click="selectSentiment = item.value"
              :style="{
                background:
                  selectSentiment == item.value
                    ? getColorSentiment(selectSentiment) + ' !important'
                    : isDarkSkin
                    ? '#0E1932 !important'
                    : '#D8D8D8 !important',
                border: 0,
                'font-weight': '900',
                color:
                  selectSentiment == item.value
                    ? isDarkSkin
                      ? '#1A1A1A !important'
                      : '#FFFFFF !important'
                    : item.color + ' !important',
                position: 'relative',
                width: '120px',
              }"
            >
              <span>{{ item.label }}</span>
              <div
                v-if="getTotalAndPercentage(item.value)[0] > 0"
                class="counter"
              >
                <span class="text-white align-self-center">{{
                  getTotalAndPercentage(item.value)[0]
                }}</span>
              </div>
              <span
                v-if="getTotalAndPercentage(item.value)[0] > 0"
                :style="{
                  color: isDarkSkin
                    ? '#FFFFFF !important'
                    : ' #1A1A1A !important',
                }"
                >({{ getTotalAndPercentage(item.value)[1] }}) %</span
              >
            </b-button>
          </div>
        </div>
      </template>
      <template #table>
        <b-table
          slot="table"
          ref="refCallImpressionGrid"
          class="position-relative table-new-customization"
          :items="myProvider"
          :fields="fields"
          primary-key="id"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(client_name)="data">
            <div class="">
              <router-link
                class
                :to="{
                  name: dashboardRouteName(data.item.module_id),
                  params: {
                    idClient: data.item.client_account_id,
                  },
                }"
                target="_blank"
                >{{ data.item.client_name }}
              </router-link>
            </div>
            <status-account :account="data.item" />
            <span>{{ data.item.account }}</span>
          </template>  

          <template #cell(created_at)="data">
            <span>{{ data.item.created_at | myGlobalDay }}</span>
          </template>

          <template #cell(service)="data">
            <div class="d-flex justify-content-center">
              <img
                :src="getProgramImageRoute(data.item.program_id)"
                v-b-tooltip.right="data.item.service"
                style="
                  background: rgb(250, 250, 250);
                  border-radius: 5px;
                  padding: 3px;
                "
                height="50"
                width="50"
                alt="img_services"
              />
            </div>
          </template>

          <template #cell(result)="data">
            <div class="w-100 d-flex justify-content-around">
              <div
                class="d-flex"
                :style="{
                  background:
                    getColorSentiment(data.item.sentiment_id) + ' !important',
                  padding: '6px',
                  borderRadius: '15px',
                }"
              >
                <div
                  :style="
                    isDarkSkin
                      ? 'color :#1A1A1A !important'
                      : 'color: #FFFFFF !important'
                  "
                >
                  <span style="font-weight: 900">{{
                    toCamelCase(data.item.result)
                  }}</span>
                </div>
                <div
                  :style="
                    isDarkSkin
                      ? 'color :#1A1A1A !important'
                      : 'color: #FFFFFF !important'
                  "
                >
                  <feather-icon
                    :id="`details_sentiment_v` + data.index"
                    v-if="data.item.result"
                    icon="InfoIcon"
                    size="15"
                    class="cursor-pointer"
                    style="margin-left: 5px; margin-bottom: 2px"
                  />
                </div>
                <div>
                  <b-tooltip
                    :target="`details_sentiment_v` + data.index"
                    triggers="hover"
                    placement="top"
                  >
                    <div class="w-100 d-flex justify-content-start">
                      <div class="d-flex flex-column">
                        <span class="text-primary text-weight-bolder"
                          >· Neutral : {{ data.item.sentiment_neutral }} %
                        </span>
                        <span class="text-success text-weight-bolder"
                          >· Positive : {{ data.item.sentiment_positive }} %
                        </span>
                        <span class="text-danger text-weight-bolder"
                          >· Negative : {{ data.item.sentiment_negative }} %
                        </span>
                        <span class="text-info text-weight-bolder"
                          >· Mixed : {{ data.item.sentiment_mixed }} %
                        </span>
                      </div>
                    </div>
                  </b-tooltip>
                </div>
              </div>
            </div>
          </template>

          <template #cell(details)="row">
            <feather-icon
              :icon="row.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'"
              class="cursor-pointer"
              size="23"
              @click="row.toggleDetails"
            ></feather-icon>
          </template>
          <template #row-details="row">
            <b-card>
              <b-row
                class="text-center text-primary pb-1"
                style="font-weight: 900"
              >
                <b-col cols="12">
                  <span>CALL</span>
                  <hr />
                </b-col>
                <b-col cols="12">
                  <div class="d-flex justify-content-center">
                    <div>
                      <new-wave-surfer-player
                        class="audio-recording"
                        :url="row.item.record_url"
                        :options="audioOptions"
                        :wave-surfer-id="`wave-surfer-${row.index}`"
                        :elapsed-time-width="95"
                        :file_name="row.item.original_id"
                        @loading="row.item.isLoading = true"
                        @ready="row.item.isLoading = false"
                        custom-class="no-overflow"
                        :download="false"
                        :show-duration="true"
                      ></new-wave-surfer-player>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row
                class="text-center text-primary pb-1"
                style="font-weight: 900"
              >
                <b-col cols="12">
                  <span>SUMMARY</span>
                  <hr />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" style="max-height: 200px; overflow: auto">
                  <p class="text-justify px-2">{{ row.item.content }}</p>
                </b-col>
              </b-row>
            </b-card>
          </template>
        </b-table>
      </template>
    </filter-slot>
  </div>
</template>
  
<script>
import Fields from "@/views/commons/call-impression/data/callImpressionGrid.fields.js";
import { mapActions, mapGetters } from "vuex";
import CallImpressionService from "@/views/commons/call-impression/services/callImpression.service.js";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import Filters from "@/views/commons/call-impression/data/callImpressionGrid.filters.js";
import NewWaveSurferPlayer from "@/views/management/views/approval-process/settlement-approval/components/NewWaveSurferPlayer.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";

export default {
  components: {
    StatusAccount,
    NewWaveSurferPlayer,
  },
  data() {
    return {
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      totalRows: 0,
      startPage: null,
      endPage: null,
      toPage: null,
      fields: Fields,
      isBusy: false,
      filters: Filters,
      sortBy: "created_at",
      sortDesc: true,
      audioOptions: {
        waveColor: "#99989A",
        progressColor: "#0077E6",
        barWidth: 3,
        barRadius: 3,
        cursorWidth: 0,
        height: 20,
        barGap: 2,
        backend: "MediaElement",
        minPxPerSec: 0,
        normalizeToZero: true,
        waveOutlineWidth: 0,
        barMinHeight: 2,
      },
      listSentiments: [
        { value: 2, label: "POSITIVE", color: "#00D25B" },
        { value: 3, label: "NEGATIVE", color: "#F10000" },
        { value: 1, label: "NEUTRAL", color: "#0090E7" },
        { value: 4, label: "MIXED", color: "#6E3CCD" },
      ],
      selectSentiment: 2,
      total_neutral: 0,
      total_positive: 0,
      total_negative: 0,
      total_mixed: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },

    programId() {
      return (
        this.$route.matched[0].meta.program ??
        this.$route.matched[1].meta.program_id
      );
    },
    isModuleGeneral() {
      return this.moduleId ? [2, 16, 18].includes(this.moduleId) : true; // CRM - MANAGEMENT - QUALITY
    },
  },
  async created() {
    this.filters.find((filter) => filter.key == "service").visible =
      this.isModuleGeneral;
  },
  methods: {
    async myProvider(ctx) {
      try {
        const params = {
          search_text: this.filterPrincipal.model,
          page: this.paginate.currentPage,
          per_page: this.paginate.perPage,
          module_id: this.isModuleGeneral
            ? this.filters.find((filter) => filter.key === "service").model
            : this.moduleId,
          type_sentiment: this.selectSentiment, //this.filters.find((filter) => filter.key === "sentiment").model,
          from: this.filters.find((filter) => filter.key === "from").model,
          to: this.filters.find((filter) => filter.key === "to").model,
        };

        const { data } = await CallImpressionService.getCallImpressions(params);
        this.startPage = data.data.from;
        this.paginate.currentPage = data.data.current_page;
        this.paginate.perPage = data.data.per_page;
        this.totalRows = data.data.total;
        this.toPage = data.data.to;
        this.total_neutral = data.total_neutral;
        this.total_positive = data.total_positive;
        this.total_negative = data.total_negative;
        this.total_mixed = data.total_mixed;
        return data.data.data || [];
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    refreshTable() {
      this.$refs.refCallImpressionGrid.refresh();
    },
    onChangeCurrentPage(e) {
      this.paginate.currentPage = e;
      this.refreshTable();
    },
    variantResult(result) {
      switch (result) {
        case "POSITIVE":
          return "light-success";
        case "NEGATIVE":
          return "light-danger";
        case "NEUTRAL":
          return "light-primary";
        default:
          return "light-info";
      }
    },
    async generateSentimentNote(note) {
      try {
        this.addPreloader();
        const data = await ClientsOptionsServices.generateSentimentNote({
          note_id: note.id,
          text: note.change_original ? note.original_text : note.content,
        });

        if (data.status === 200) {
          this.$toast.success("generate sentiment successfully");
        }
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
        await this.getAllNotes();
      }
    },
    getColorSentiment(sentiment) {
      switch (sentiment) {
        case 1:
          return "#0090E7";
        case 2:
          return "#00D25B";
        case 3:
          return "#F10000";
        case 4:
          return "#6E3CCD";
      }
    },
    toCamelCase(text) {
      text = text
        .toLowerCase()
        .replace(/^[a-z]/, (match) => match.toUpperCase());
      return text.replace(/[-_]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ""));
    },
    getTotalAndPercentage(sentiment) {
      const total =
        this.total_neutral +
        this.total_positive +
        this.total_negative +
        this.total_mixed;
      switch (sentiment) {
        case 1:
          return [
            this.total_neutral,
            ((this.total_neutral / total) * 100).toFixed(2),
          ];
        case 2:
          return [
            this.total_positive,
            ((this.total_positive / total) * 100).toFixed(2),
          ];
        case 3:
          return [
            this.total_negative,
            ((this.total_negative / total) * 100).toFixed(2),
          ];
        case 4:
          return [
            this.total_mixed,
            ((this.total_mixed / total) * 100).toFixed(2),
          ];
      }
    },
    dashboardRouteName(module_id) {
      const routers = {
        2: "dashboard-crm",
        3: "bussiness-dashboard-clients",
        4: "administration-dashboard",
        5: "debt-solution-dashboard",
        6: "credit-experts-dashboard",
        7: "boost-credit-dashboard",
        8: "tax-research-client-dashboard",
        10: "claim-department-dashboard",
        11: "specialists-client-dashboard",
        12: "paragon-dashboard",
        14: "bookeeping-dashboard",
        16: "management-dashboard",
        18: "quality-client-dashboard",
        20: "connection-clients-account",
        21: "ce-customer-service-dashboard",
        22: "ce-customer-service-dashboard",
        23: "financial-clients-account",
        25: "specialist-digital-dashboard",
        29: "specialists-client-dashboard-fa",
        16: "management-client-dashboard",
      };
      return routers[module_id] || null;
    },
  },

  watch: {
    selectSentiment(value) {
      this.refreshTable();
    },
  },
};
</script>
  
<style scoped>
button {
  box-shadow: none !important;
}

.counter {
  position: absolute;
  background: red;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  top: -5px;
  right: -5px;
}
</style>
  