<template>
  <div>
    <b-modal
      :title="titleModal"
      v-model="showModal"
      :hide-footer="page != 2"
      size="lg"
      @hidden="close()"
      modal-class="custom-modal-amg"
    >
      <div class="ml-1 my-1">
        <div
          class="absolute-linker d-flex justify-content-end align-items-center"
          :class="{ dark: isDarkSkin }"
        >
          <div class="d-flex align-items-center mr-2">
            <p class="m-0">Filter by:</p>
            <p
              :class="useMonthFilter ? '' : 'text-warning'"
              class="my-0"
              style="font-weight: bold; margin-left: 5px"
            >
              Month
            </p>
            <b-form-checkbox
              switch
              size="md"
              v-model="useMonthFilter"
              style="padding: 5px"
              @input="changeFilter()"
            ></b-form-checkbox>
            <p
              :class="useMonthFilter ? 'text-warning' : ''"
              class="my-0"
              style="font-weight: bold"
            >
              Year
            </p>
          </div>
          <div v-if="useMonthFilter">
            <v-select
              :options="generateYears"
              v-model="dataYear"
              :class="{ 'dark-select': isDarkSkin }"
              class="mr-1"
              style="min-width: 100px"
              @input="changeYear()"
            >
            </v-select>
          </div>
          <div v-else class="d-flex align-items-center h-full px-1">
            <feather-icon
              icon="ChevronLeftIcon"
              size="20"
              class="cursor-pointer"
              @click="prev()"
            />
            <span class="font-size:15px">
              {{ months[dataMonth - 1] }} {{ dataYear }}
            </span>
            <feather-icon
              icon="ChevronRightIcon"
              size="20"
              class="cursor-pointer"
              :class="{ 'disabled-next': disableNext }"
              @click="next()"
            />
          </div>
        </div>
      </div>
      <b-tabs
        active-tab-class="p-0 "
        pills
        nav-class="mb-0 mt-1"
        active-nav-item-class="bg-primary box-shadow-primary border-primary primary"
      >
        <b-tab
          title="Departments"
          @click="changeTab('dept')"
          lazy
          :title-link-class="[bgTabsNavs, 'sub-tab px-3']"
        >
          <b-table
            :items="expenses"
            :fields="visibleFields"
            responsive
            class="position-relative table-new-customization"
            sticky-header="70vh"
          >
            <template #cell(module)="{ item }">
              <div class="d-flex justify-content-center align-items-center">
                <img
                  v-b-tooltip.hover.left="item.name"
                  :src="`/assets/${item.icons}`"
                  alt="Logo"
                  height="30"
                  width="30"
                />
              </div>
            </template>
            <template #cell(total_clients)="{ item }">
              <div
                :class="item.total_clients > 0 ? 'cursor-pointer' : ''"
                @click="
                  item.total_clients > 0 &&
                    openExpensesDetailModal(item, 'CLIENT')
                "
                :style="
                  item.total_clients > 0
                    ? 'color: #0090e7; text-decoration: underline; text-underline-offset: 3px;'
                    : ''
                "
              >
                {{ item.total_clients | formatMoney }}
              </div>
            </template>
            <template #cell(total_departments)="{ item }">
              <div
                :class="item.total_departments > 0 ? 'cursor-pointer' : ''"
                @click="
                  item.total_departments > 0 &&
                    openExpensesDetailModal(item, 'AMG')
                "
                :style="
                  item.total_departments > 0
                    ? 'color: #0090e7; text-decoration: underline; text-underline-offset: 3px;'
                    : ''
                "
              >
                {{ item.total_departments | formatMoney }}
              </div>
            </template>
            <template #cell(total)="{ item }">
              <div>
                {{ item.subtotal | formatMoney }}
              </div>
            </template>
            <template #bottom-row>
              <b-th>
                <b-badge
                  variant="primary"
                  class="w-100 text-center"
                  style="
                    font-size: 14px;
                    font-weight: 600;
                    padding-bottom: 7px;
                    padding-top: 7px;
                  "
                >
                  TOTAL
                </b-badge>
              </b-th>
              <b-th>
                <b-badge
                  variant="success"
                  class="w-100 text-center text-black"
                  style="
                    font-size: 14px;
                    font-weight: 600;
                    padding-bottom: 7px;
                    padding-top: 7px;
                  "
                >
                  {{ totalExpenseClients | formatMoney }}
                </b-badge>
              </b-th>
              <b-th>
                <b-badge
                  variant="success"
                  class="w-100 text-center text-black"
                  style="
                    font-size: 14px;
                    font-weight: 600;
                    padding-bottom: 7px;
                    padding-top: 7px;
                  "
                >
                  {{ totalExpenseDepartments | formatMoney }}
                </b-badge>
              </b-th>
              <b-th>
                <b-badge
                  variant="success"
                  class="w-100 text-center text-black"
                  style="
                    font-size: 14px;
                    font-weight: 600;
                    padding-bottom: 7px;
                    padding-top: 7px;
                  "
                >
                  {{ totalExpense | formatMoney }}
                </b-badge>
              </b-th>
            </template>
          </b-table>
        </b-tab>
        <b-tab
          title="Credit Cards"
          @click="changeTab('card')"
          lazy
          :title-link-class="[bgTabsNavs, 'sub-tab px-3']"
        >
          <b-table
            :items="this.page == 1 ? expensesByBank : expensesByCard"
            :fields="visibleFields"
            responsive
            class="position-relative table-new-customization"
            sticky-header="70vh"
          >
            <template #cell(bank)="{ item }">
              <div class="text-warning">
                {{ item.name }}
              </div>
            </template>
            <template #cell(cardholdername)="{ item }">
              <div class="row">
                <div class="col-4 p-0 justify-content-end d-flex">
                  <type-card :type_card="item.type_card" />
                </div>
                <div
                  class="col-8 p-0 d-flex justify-content-center align-items-center"
                >
                  <strong>XXXX-{{ item.cardnumber.slice(-4) }}</strong>
                </div>
              </div>
            </template>
            <template #cell(total_clients)="{ item }">
              <div
                :class="
                  item.total_clients > 0 && page == 2 ? 'cursor-pointer' : ''
                "
                @click="
                  item.total_clients > 0 &&
                    page == 2 &&
                    openExpensesDetailModal(item, 'CLIENT')
                "
                :style="
                  item.total_clients > 0 && page == 2
                    ? 'color: #0090e7; text-decoration: underline; text-underline-offset: 3px;'
                    : ''
                "
              >
                {{ item.total_clients | formatMoney }}
              </div>
            </template>
            <template #cell(total_departments)="{ item }">
              <div
                :class="
                  item.total_departments > 0 && page == 2
                    ? 'cursor-pointer'
                    : ''
                "
                @click="
                  item.total_departments > 0 &&
                    page == 2 &&
                    openExpensesDetailModal(item, 'AMG')
                "
                :style="
                  item.total_departments > 0 && page == 2
                    ? 'color: #0090e7; text-decoration: underline; text-underline-offset: 3px;'
                    : ''
                "
              >
                {{ item.total_departments | formatMoney }}
              </div>
            </template>
            <template #cell(total)="{ item }">
              <div
                @click="
                  item.subtotal > 0 &&
                    page == 1 &&
                    openExpensesDetailModal(item, 'AMG')
                "
                :style="
                  item.subtotal > 0 && page == 1
                    ? 'color: #0090e7; text-decoration: underline; text-underline-offset: 3px;'
                    : ''
                "
                :class="item.subtotal > 0 && page == 1 ? 'cursor-pointer' : ''"
              >
                {{ item.subtotal | formatMoney }}
              </div>
            </template>
            <template #bottom-row>
              <b-th>
                <b-badge
                  variant="primary"
                  class="w-100 text-center"
                  style="
                    font-size: 14px;
                    font-weight: 600;
                    padding-bottom: 7px;
                    padding-top: 7px;
                  "
                >
                  TOTAL
                </b-badge>
              </b-th>
              <b-th>
                <b-badge
                  variant="success"
                  class="w-100 text-center text-black"
                  style="
                    font-size: 14px;
                    font-weight: 600;
                    padding-bottom: 7px;
                    padding-top: 7px;
                  "
                >
                  {{ totalExpenseClients | formatMoney }}
                </b-badge>
              </b-th>
              <b-th>
                <b-badge
                  variant="success"
                  class="w-100 text-center text-black"
                  style="
                    font-size: 14px;
                    font-weight: 600;
                    padding-bottom: 7px;
                    padding-top: 7px;
                  "
                >
                  {{ totalExpenseDepartments | formatMoney }}
                </b-badge>
              </b-th>
              <b-th>
                <b-badge
                  variant="success"
                  class="w-100 text-center text-black"
                  style="
                    font-size: 14px;
                    font-weight: 600;
                    padding-bottom: 7px;
                    padding-top: 7px;
                  "
                >
                  {{ totalExpense | formatMoney }}
                </b-badge>
              </b-th>
            </template>
          </b-table>
        </b-tab>
      </b-tabs>
      <template #modal-footer>
        <b-button variant="info" @click="page = 1"> Return back </b-button>
      </template>
    </b-modal>
    <detail-department-expenses
      v-if="showExpensesDetailModal"
      :year="dataYear"
      :month="dataMonth"
      :module_id="itemSelected.id"
      :program_id="itemSelected.program_id"
      :card_expense_id="
        tab == 'card' ? itemSelected.credit_card_expense_id : null
      "
      :status="'APPROVED'"
      :dataRow="{ type: type, module_name: itemSelected.name }"
      @close="showExpensesDetailModal = false"
    />
  </div>
</template>
<script>
import DashboardService from "@/views/ceo/service-dashboard/dashboard.service";
import Fields from "@/views/ceo/dashboard/data/expenses-by-dept.fields.js";
import DetailDepartmentExpenses from "@/views/commons/expenses/components/Modal/DetailDepartmentExpenses.vue";
import TypeCard from "@/views/commons/card/TypeCard.vue";
import moment from "moment";
import { modifyVisibility } from "@/helpers/fields-table";
export default {
  name: "ExpensesByDepartment",
  components: {
    DetailDepartmentExpenses,
    TypeCard,
  },
  data() {
    return {
      showModal: false,
      expenses: [],
      expensesByCard: [],
      expensesByBank: [],
      fields: Fields,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      showExpensesDetailModal: false,
      dataYear: null,
      dataMonth: null,
      type: null,
      cpyMonth: null,
      cpyYear: null,
      useMonthFilter: false,
      tab: "dept",
      page: 1,
    };
  },
  props: {
    sendYear: {
      type: Number,
      required: true,
    },
    sendMonth: {
      type: Number,
      required: true,
    },
  },
  async created() {
    this.initMonthYear();
    await this.getExpensesByDepartment();
    this.showModal = true;
  },
  computed: {
    titleMonthYear() {
      return `${this.months[this.dataMonth - 1]} ${this.dataYear}`;
    },
    visibleFields() {
      modifyVisibility(this.fields, "module", this.tab == "dept");
      modifyVisibility(
        this.fields,
        "cardholdername",
        this.tab == "card" && this.page == 2
      );
      modifyVisibility(
        this.fields,
        "bank",
        this.tab == "card" && this.page == 1
      );
      return this.fields.filter((field) => field.visible);
    },
    disableNext() {
      const now = moment();
      const sameYear = now.year() === this.dataYear;
      const sameMonth = now.month() + 1 === this.dataMonth;
      return sameYear && sameMonth;
    },
    totalExpenseClients() {
      let expenses = this.getExpenses;
      return expenses.reduce(
        (acum, item) => acum + parseFloat(item.total_clients),
        0
      );
    },
    totalExpenseDepartments() {
      let expenses = this.getExpenses;
      return expenses.reduce(
        (acum, item) => acum + parseFloat(item.total_departments),
        0
      );
    },
    totalExpense() {
      let expenses = this.getExpenses;
      return expenses.reduce(
        (acum, item) => acum + parseFloat(item.subtotal),
        0
      );
    },
    getExpenses() {
      return this.tab === "dept"
        ? this.expenses
        : this.tab === "card" && this.page === 1
        ? this.expensesByBank
        : this.tab === "card" && this.page === 2
        ? this.expensesByCard
        : [];
    },
    generateYears() {
      const years = [];
      const currentYear = new Date().getFullYear();
      for (let i = currentYear; i >= 2023; i--) {
        years.push(i);
      }
      return years;
    },
    titleModal() {
      if (this.tab == "card" && this.page == 2)
        return "Expenses Department | Bank: " + this.itemSelected.name;
      return "Expenses Department";
    },
  },
  methods: {
    changeYear() {
      this.refresh();
    },
    changeTab(tab) {
      this.page = 1;
      this.tab = tab;
      this.refresh();
    },
    async getExpensesByBank() {
      try {
        this.addPreloader();
        const params = {
          month: this.dataMonth,
          year: this.dataYear,
        };
        const { data } = await DashboardService.getExpensesByBank(params);
        this.expensesByBank = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async getExpensesByDepartment() {
      try {
        this.addPreloader();
        const params = {
          month: this.dataMonth,
          year: this.dataYear,
        };
        const { data } = await DashboardService.getExpensesByDepartment(params);
        this.expenses = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async getExpensesByCreditCard() {
      try {
        this.addPreloader();
        const params = {
          year: this.dataYear,
          month: this.dataMonth,
          card_bank_id: this.itemSelected.card_bank_id,
        };
        const { data } = await DashboardService.getExpensesByCreditCard(params);
        this.expensesByCard = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    changeFilter() {
      if (this.useMonthFilter) {
        this.cpyYear = this.dataYear;
        this.cpyMonth = this.dataMonth;
        this.dataMonth = null;
        this.refresh();
      } else {
        this.dataMonth = this.cpyMonth;
        this.dataYear = this.cpyYear;
        this.refresh();
      }
    },
    async prev() {
      if (this.dataMonth == null) this.dataMonth = this.cpyMonth;
      if (this.dataMonth > 1) {
        this.dataMonth--;
      } else {
        this.dataMonth = 12;
        this.dataYear--;
      }
      await this.refresh();
    },
    async next() {
      if (this.dataMonth == null) this.dataMonth = this.cpyMonth;
      if (this.disableNext) return;
      if (this.dataMonth < 12) {
        this.dataMonth++;
      } else {
        this.dataMonth = 1;
        this.dataYear++;
      }
      await this.refresh();
    },
    initMonthYear() {
      this.dataMonth = this.sendMonth;
      this.dataYear = this.sendYear;
    },
    async openExpensesDetailModal(item, type) {
      this.itemSelected = item;
      if (this.tab == "card" && this.page == 1) {
        await this.getExpensesByCreditCard();
        this.page = 2;
      } else {
        this.type = type;
        this.showExpensesDetailModal = true;
      }
    },
    async refresh() {
      this.tab == "dept"
        ? this.getExpensesByDepartment()
        : this.getExpensesByBank();
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.dark-select {
  background-color: #333;
  color: #fff;
}
.dark .absolute-linker {
  background-color: #444;
}
.disabled-next {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
