/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
import { amgApi } from "@/service/axios";

class DashboardService {
  // User Create
  async userCreator(params) {
    const data = await amgApi.post("/commons/sellerall/2", params);
    return data.data;
  }

  async getDashboardData(params) {
    const data = await amgApi.post("/crm/get-data-dashboard", params);
    return data;
  }

  async getCrmDashboardMonthlySales(params) {
    try {
      const { data } = await amgApi.post("/crm/dashboard/sales/show", params);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async getCrmDashboardYearlySales(params) {
    try {
      const { data } = await amgApi.post("crm/dashboard/sales/index", params);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async getPrograms() {
    try {
      const data = await amgApi.get("/commons/programs");
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async getAppointmentsDashboardData(params) {
    try {
      const data = await amgApi.post(
        "/crm/dashboard/appointments/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getLeadsDashboardData(params) {
    try {
      const { data } = await amgApi.post("crm/dashboard/leads/index", params);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async getSellers(modul, body) {
    try {
      const { data } = await amgApi.post(`commons/sellerall/${modul}`, body);
      return data;
    } catch (error) {
      console.log(
        "Something went wrong on getSellers in Dashboard CRM:",
        error
      );
      throw error;
    }
  }

  async getAppointmentsDetailsDashboardData(params) {
    try {
      const data = await amgApi.post(
        "/crm/dashboard/appointments/show",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getLeadsDashboardDataDetails(params) {
    try {
      const { data } = await amgApi.post("crm/dashboard/leads/details/index", params);
      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async getDetailedCallsByLead(params) {
    try {
      const { data } = await amgApi.post('/crm/dashboard/sales/show', params);
      console.log(data);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async getOriginModules(params) {
    try {
      const { data } = await amgApi.post("crm/dashboard/leads/modules/index", params);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async getDataDashboardLeads(params) {
    try {
      const { data } = await amgApi.post("crm/dashboard/leads/graphics/index", params);
      return data;
    } catch (error) {
      console.error(error);
    }
  }
}
export default new DashboardService();
