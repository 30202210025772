<template>
  <div>
    <b-modal
      v-model="onControl"
      header-bg-variant="transparent border-bottom border-bottom-2"
      size="xmd"
      header-class="p-0"
      :title="`Calls Log - ${clientName}`"
      modal-class="modal-primary"
      hide-footer
      scrollable
      @hidden="close()"
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <span>{{ `Calls Log - ${clientName}` }}</span>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="$emit('close')"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>
      <div style="padding: 0 10px">
        <b-nav card-header pills class="mb-0">
          <b-nav-item
            exact
            :active="conversation"
            exact-active-class="active"
            :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
            @click="changeConversation(true)"
            ><span>CONVERSATION</span>
            <div class="ml-1">
              <feather-icon
                v-if="counterConversation > 0"
                :badge="counterConversation"
                badge-classes="badge-important"
                class="text-body"
                size="19"
              />
            </div>
          </b-nav-item>
          <b-nav-item
            exact
            exact-active-class="active"
            :active="!conversation"
            :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
            @click="changeConversation(false)"
            ><span>NO CONVERSATION</span>
            <div class="ml-1">
              <feather-icon
                v-if="counterNotConversation > 0"
                :badge="counterNotConversation"
                badge-classes="badge-important"
                class="text-body"
                size="19"
              />
            </div>
          </b-nav-item>
        </b-nav>
      </div>

      <b-card no-body class="mb-0">
        <filter-slot
          class="filter-slot-new-customization"
          :filter="[]"
          :filter-principal="filterPrincipal"
          :total-rows="totalRows"
          :paginate="paginate"
          :start-page="startPage"
          :to-page="toPage"
          @reload="$refs['refCallsDetailedAutoModal'].refresh()"
        >
          <template #table>
            <b-table
              ref="refCallsDetailedAutoModal"
              no-border-collapse
              class="position-relative table-new-customization"
              :fields="fields"
              show-empty
              no-provider-filtering
              sticky-header="50vh"
              primary-key="id"
              responsive="sm"
              :items="myProvider"
              :current-page="paginate.currentPage"
              :per-page="paginate.perPage"
              :busy.sync="isBusy"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </template>
              <template #empty>
                <div class="text-center text-primary my-2">
                  <strong>No existing data.</strong>
                </div>
              </template>
              <template #cell(from)="data">
                <div class="d-flex">
                  <b-avatar class="mr-1" variant="light-success">
                    <feather-icon icon="PhoneOutgoingIcon" size="16" />
                  </b-avatar>
                  <div>
                    <div>
                      {{ data.item.from_name }}
                    </div>
                    <b-badge variant="light-success">{{
                      data.item.from_number
                    }}</b-badge>
                  </div>
                </div>
              </template>

              <template #cell(to)="data">
                <div class="d-flex">
                  <b-avatar
                    v-b-tooltip.hover
                    class="mr-1"
                    :variant="getCallStatus(data.item.status)[0]"
                    :title="data.item.status"
                  >
                    <feather-icon
                      :icon="getCallStatus(data.item.status)[1]"
                      size="16"
                    />
                  </b-avatar>
                  <div>
                    <div>
                      {{ data.item.to_name }}
                    </div>
                    <div>
                      <b-badge :variant="getCallStatus(data.item.status)[0]">{{
                        data.item.to_number
                      }}</b-badge>
                    </div>
                  </div>
                </div>
              </template>

              <template #cell(start_time)="data">
                <div>
                  {{ data.item.start_time | myGlobalWithHour }}
                </div>
              </template>

              <template #cell(status)="data">
                <div class="text-left">
                  <div>{{ data.item.status }}</div>
                  <div>
                    {{ data.item.start_time | myGlobalWithHour }}
                  </div>
                </div>
              </template>

              <template #cell(record_url)="data">
                <div
                  v-if="data.item.was_recording"
                  class="w-100 d-flex justify-content-around text-center"
                >
                  <div
                    v-if="data.item.show_item && data.item.record_url"
                    style="position: sticky; top: 0; z-index: 1"
                  >
                    <wave-surfer-player
                      style="height: 1.5rem"
                      :url="data.item.record_url"
                      :options="audioOptions"
                      :wave-surfer-id="`wave-surfer-${data.index}`"
                      :elapsed-time-width="50"
                      :file_name="data.item.original_id"
                      :download="false"
                      @loading="data.item.isLoading = true"
                      @ready="data.item.isLoading = false"
                      @WaveSurferEvents="load"
                    />
                  </div>
                  <feather-icon
                    v-else
                    v-b-tooltip.hover
                    icon="LoaderIcon"
                    class="text-primary cursor-pointer"
                    title="Load record"
                    @click="data.item.show_item = !data.item.show_item"
                  />
                </div>
              </template>
              <template #cell(duration)="data">
                <div class="w-100 d-flex justify-content-around text-center">
                  {{ convertMSeconds(data.item.duration) }}
                </div>
              </template>

              <template #cell(actions)="data">
                <div
                  v-if="data.item.hidden === 1"
                  class="w-100 d-flex justify-content-around text-center text-warning"
                >
                  <feather-icon
                    :id="data.index + 'tooltip'"
                    icon="EyeOffIcon"
                    class="cursor-pointer"
                    @click="hiddenCall(data.item.call_id)"
                  />
                  <b-tooltip
                    :target="data.index + 'tooltip'"
                    triggers="hover"
                    noninteractive
                    variant="info"
                    >Dont show this call</b-tooltip
                  >
                </div>
              </template>
            </b-table>
          </template>
        </filter-slot>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import CallLogService from "@/views/commons/components/ring-central/call-log/service/call-log.service";
import CallsDetailedField from "@/views/ceo/dashboard/data/calls-detailed-fields";
import WaveSurferPlayer from "@/views/management/views/approval-process/settlement-approval/components/WaveSurferPlayer.vue";

export default {
  components: {
    WaveSurferPlayer,
  },
  props: {
    clientAccountId: {
      type: String,
      required: true,
    },
    clientName: {
      type: String,
      default: null,
    },
    fromSettlementDate: {
      type: [String, Date],
      required: true,
    },
    moduleId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      counterConversation: 0,
      counterNotConversation: 0,
      audioOptions: {
        waveColor: "#99989A",
        progressColor: "#0077E6",
        barWidth: 3,
        barRadius: 3,
        cursorWidth: 0,
        height: 20,
        barGap: 3,
        width: 180,
        backend: "MediaElement",
        minPxPerSec: 5,
        normalizeToZero: true,
        waveOutlineWidth: 0,
        barMinHeight: 2,
      },
      conversation: true,
      onControl: false,
      startPage: 0,
      toPage: 0,
      isBusy: false,
      fields: CallsDetailedField,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      totalRows: 1,
      paginate: {
        perPage: 50,
        currentPage: 1,
      },
    };
  },

  computed: {
    fromStmOnlyDate() {
      const date = new Date(this.fromSettlementDate);
      const dateOnly = date.toISOString().split("T")[0];
      return dateOnly;
    },
    fromStmPlusThreeMonths() {
      const date = new Date(this.fromSettlementDate);
      date.setMonth(date.getMonth() + 3);
      const datePlusThreeMonths = date.toISOString().split("T")[0];
      return datePlusThreeMonths;
    },
  },
  watch: {},
  mounted() {
    this.getCallsCounterPerClientAccountId(
      this.clientAccountId,
      this.fromStmOnlyDate,
      this.fromStmPlusThreeMonths
    );
  },
  async created() {
    this.onControl = true;
  },
  methods: {
    close() {
      this.onControl = false;
      this.$emit("close");
    },
    async myProvider() {
      const params = {
        client_account: this.clientAccountId,
        lead_id: null,
        search_txt: this.filterPrincipal.model,
        per_page: this.paginate.perPage,
        page: this.paginate.currentPage,
        date_from: this.fromStmOnlyDate,
        date_to: this.fromStmPlusThreeMonths,
        status: null,
        module_id: this.moduleId,
        is_conversation: this.conversation ? 1 : 0,
      };

      const { data } = await CallLogService.getLeadCallLog(params);
      const items = data.data;
      this.startPage = data.from;
      this.paginate.currentPage = data.current_page;
      this.paginate.perPage = data.per_page;
      this.totalRows = data.total;
      this.toPage = data.to;
      return items || [];
    },
    changeConversation(value) {
      this.conversation = value;
      this.$refs.refCallsDetailedAutoModal.refresh();
    },
    getCallStatus(status) {
      if (status === "Accepted" || status === "Call connected") {
        return ["light-primary", "PhoneIncomingIcon"];
      }

      return ["light-danger", "PhoneMissedIcon"];
    },
    async hiddenCall(id) {
      const result = await this.showQuestionSwal(
        "Do you want to perform this action?",
        "This will hide the call for all users."
      );
      if (result.isConfirmed) {
        try {
          this.addPreloader();
          const params = {
            call_id: id,
          };
          await CallLogService.hiddenCall(params);
          this.$refs.refCallsDetailedAutoModal.refresh();
        } catch (e) {
          this.showErrorSwal(e);
        } finally {
          this.removePreloader();
        }
      }
    },
    async getCallsCounterPerClientAccountId(clientAccountId, dateFrom, dateTo) {
      try {
        const params = {
          client_account_id: clientAccountId,
          date_from: dateFrom,
          date_to: dateTo,
        };
        const data = await CallLogService.getCallsCounterPerClientAccountId(
          params
        );
        const counters = data[0];
        this.counterConversation = counters.count_conversation;
        this.counterNotConversation = counters.count_not_conversation;
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>
      <style lang="scss" scoped>
.text-money {
  font-weight: 500;

  &::before {
    content: "$";
  }
}

.header-modal {
  position: relative;
  padding: 20px 0 20px 15px;
  background: rgba(22, 84, 204, 1);
  width: 100%;
  font-size: 22px;
  display: flex;
  justify-content: space-betweeen;

  span {
    font-size: 1.6rem;
    font-weight: bold;
    margin-left: 8px;
    color: #ffffff;
  }

  .container-icons {
    background-color: #ffffff;
    padding: 2.7px 6.37px;
    border-radius: 0.357rem;
    width: 31px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    transition: all 0.23s ease 0.1s;

    &--dark {
      background-color: #17171a;
    }

    &:hover {
      transform: translate(-7px, 5px);
      cursor: pointer;
    }
  }
}

.charge__programs {
  width: 47px;
  height: 40px;
  background-color: #e9e9e9;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 35px;
    height: 35px;
  }
}
</style>
    <style>
.b-table-bottom-row {
  position: sticky;
  bottom: 0px;
  z-index: 1;
}
</style>
