<template>
  <div>
    <b-modal
      v-model="showModal"
      @hidden="closeModal"
      title="PENDING E-CHECKS"
      hide-footer
      size="xlg"
    >
      <!-- <b-nav card-header pills class="m-0">
        <b-nav-item
          v-for="(tab, index) in tabs"
          :key="index"
          exact
          :link-classes="['px-3', bgTabsNavs]"
          :active="tabSelected === index"
          @click="changeTab(index)"
          >{{ tab }}
        </b-nav-item>
      </b-nav> -->
      <b-table
        class="position-relative table-new-customization"
        :items="items"
        :fields="fields"
        responsive=""
        show-empty
        sticky-header="70vh"
      >
        <template #cell(check_details)="{ item }">
          <b-row>
            <b-col cols="10"><strong>Pay to: </strong>{{ item.vendor }}</b-col>
            <feather-icon
              v-if="isCeo && item.status_check == 'PENDING'"
              @click="openVendorConceptEditorModal(item, 'vendor')"
              icon="EditIcon"
              v-b-tooltip.hover.right.v-warning="'Edit vendor'"
              class="text-warning clickable"
            />
          </b-row>
          <b-row>
            <b-col cols="10"><strong>Memo: </strong>{{ item.concept }}</b-col>
            <feather-icon
              v-if="isCeo && item.status_check == 'PENDING'"
              @click="openVendorConceptEditorModal(item, 'concept')"
              icon="EditIcon"
              v-b-tooltip.hover.right.v-warning="'Edit concept'"
              class="text-warning clickable"
            />
          </b-row>
          <b-row>
            <b-col cols="10"
              ><strong>Date: </strong> {{ item.payment_date | myGlobal }}</b-col
            >
          </b-row>
          <b-row v-if="item.url_file">
            <b-col cols="10"
              ><strong>File: </strong
              ><a :href="item.url_file" target="_blank" :title="item.file">
                <amg-icon
                  class="cursor-pointer text-danger"
                  icon="FilePdfIcon"
                  size="18"
                /> </a
            ></b-col>
            <b-col class="m-0 p-0"></b-col>
          </b-row>
        </template>
        <template #cell(amount)="{ item }">
          {{ item.amount | formatMoney }}
        </template>
        <template #cell(type)="{ item }">
          <b-badge
            class="px-1"
            :class="item.type == 'CLIENT' ? 'clickable' : ''"
            :variant="item.type == 'CLIENT' ? 'primary' : 'info'"
            @click="item.type == 'CLIENT' && openClientExpenses(item)"
          >
            {{ item.type }}
            {{ item.total_clients > 0 ? `(${item.total_clients})` : null }}
          </b-badge>
        </template>
        <template #cell(status_check)="{ item }">
          <span :class="colorText(item)">{{ statusCheck(item) }}</span>
        </template>
        <template #cell(module)="{ item }">
          <div class="d-flex justify-content-center align-items-center">
            <img
              v-b-tooltip.hover.left="item.module_name"
              :src="`/assets/${item.icons}`"
              alt="Logo"
              height="30"
              width="30"
            />
          </div>
        </template>
        <template #cell(check_number)="{ item }">
          <span>{{ item.check_number || "-" }}</span>
        </template>
        <template #cell(requested_by)="{ item }">
            <span class="d-block">{{
              item.requested_by_id == 0 ? "System" : item.requested_by
            }}</span>
            <span class="d-block">{{ item.requested_at | myGlobalDay }} </span>
          </template>
        <template #cell(actions)="{ item }">
          <!-- <tabler-icon
            v-if="item.status_check == 'PENDING'"
            icon="ThumbUpIcon"
            class="text-success clickable"
            size="22"
            v-b-tooltip.hover.top="'Approve'"
            @click="approve(item)"
          />
          <tabler-icon
            v-if="item.status_check == 'PENDING'"
            icon="ThumbDownIcon"
            class="text-danger clickable"
            size="22"
            v-b-tooltip.hover.top="'Reject'"
            @click="openObservationsModal(item, 'rejected')"
          /> -->
          <feather-icon
            v-if="item.status_check == 'PENDING COLLECTION'"
            icon="DollarSignIcon"
            class="text-success clickable"
            size="22"
            v-b-tooltip.hover.top="'Collected'"
            @click="confirmCheck(item)"
          />
          <tabler-icon
            v-if="item.status_check == 'PENDING COLLECTION'"
            icon="CircleXIcon"
            class="text-danger clickable"
            size="22"
            v-b-tooltip.hover.top="'Not collected'"
            @click="openObservationsModal(item, 'not collected')"
          />
        </template>
        <template #cell(tracking)="{ item }">
          <div>
            <feather-icon
              class="text-info cursor-pointer"
              v-b-tooltip.hover.right="'Tracking'"
              @click="openChecksTrackingModal(item)"
              size="16"
              icon="ListIcon"
            />
          </div>
        </template>
      </b-table>
    </b-modal>
    <ObservationsModal
      v-if="showObservationsModal"
      :expenseData="selectedItem"
      @close="showObservationsModal = false"
      @save="annulCheck"
    />
    <ChecksTrackingModal
      v-if="showChecksTrackingModal"
      :dataRow="selectedCheck"
      @close="showChecksTrackingModal = false"
    ></ChecksTrackingModal>
    <ConfirmEcheck
      v-if="showConfirmCheck"
      :checkData="selectedCheck"
      @close="showConfirmCheck = false"
      @reload="refresh"
    />
    <client-expense-request
      v-if="clientExpenseModal.show"
      :expenseDepartment="clientExpenseModal.expenseDepartment"
      @close="clientExpenseModal.show = false"
    />
  </div>
</template>
<script>
import DashboardService from "@/views/ceo/service-dashboard/dashboard.service";
import Fields from "@/views/ceo/dashboard/modals/expenses-request/data/pending-approval-echecks.fields.js";
import ObservationsModal from "@/views/ceo/dashboard/modals/expenses-request/ObservationsModal.vue";
import ChecksTrackingModal from "@/views/commons/expenses/components/Modal/ChecksTrackingModal.vue";
import ConfirmEcheck from "@/views/ceo/dashboard/modals/expenses-request/ConfirmEcheck.vue";
import ClientExpenseRequest from "@/views/ceo/dashboard/modals/expenses-request/ClientExpenseRequest.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    ObservationsModal,
    ChecksTrackingModal,
    ConfirmEcheck,
    ClientExpenseRequest,
  },
  data() {
    return {
      showModal: false,
      items: [],
      fields: Fields,
      showObservationsModal: false,
      showChecksTrackingModal: false,
      selectedCheck: null,
      reason: null,
      showConfirmCheck: false,
      clientExpenseModal: {
        show: false,
        expenseDepartment: null,
      },
      echecksData: 0,
      // tabs: ["NEW", "UNDERVIEW"],
      // tabSelected: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_REFRESH_EXPENSES_COUNTER: "ExpensesRequest/G_REFRESH_EXPENSES_COUNTER",
    }),
  },
  watch: {
    // async tabSelected(newValue) {
    //   await this.getPendingEchecks();
    // },
    G_REFRESH_EXPENSES_COUNTER(newVal) {
      if (newVal) {
        this.getChecksCounter();
        this.refreshExpensesCounter(false);
      }
    },
  },
  async created() {
    await this.getPendingEchecks();
    await this.getChecksCounter();
    this.showModal = true;
  },
  methods: {
    ...mapActions({
      refreshExpensesCounter: "ExpensesRequest/refreshExpensesCounter",
    }),
    async getChecksCounter() {
      try {
        const data = await DashboardService.getChecksCounter();
        this.echecksData = data.new_e_check;
      } catch (error) {
        console.log(error);
      }
    },
    async getPendingEchecks() {
      try {
        this.addPreloader();
        const params = {
          type: "E-CHECK",
          tab: 0,
        };
        const data = await DashboardService.getPendingEchecks(params);
        this.items = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async approve(item) {
      const confirm = await this.showConfirmSwal(
        "Are you sure you want to approve this request?"
      );
      if (!confirm.isConfirmed) return;

      try {
        this.addPreloader();
        const params = {
          id: item.department_expense_id,
          user_id: this.currentUser.user_id,
          check_id: item.check_id,
        };
        const data = await DashboardService.approveEcheck(params);
        if (data.success) {
          this.toastSuccess();
          this.getPendingEchecks();
        } else {
          this.toastDanger();
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    openClientExpenses(item) {
      this.clientExpenseModal.show = true;
      this.clientExpenseModal.expenseDepartment = {
        id: item.department_expense_id,
        type: item.type,
        vendor: item.vendor,
        concept: item.concept,
        method: "E-CHECK",
      };
    },
    statusCheck(item) {
      switch (item.status_check) {
        case "PENDING":
          return "IN CEO";
        case "SEND TO COURIER":
          return "IN COURIER";
        case "SEND TO CORRESPONDENCE":
          return "IN CORRESPONDENCE";
        case "PENDING COLLECTION":
          return "PENDING COLLECT";
        default:
          return item.status_check;
      }
    },
    colorText(item) {
      switch (item.status_check) {
        case "PENDING":
          return "text-warning";
        case "SEND TO COURIER":
          return "text-primary";
        case "SEND TO CORRESPONDENCE":
          return "text-info";
        case "REJECTED":
          return "text-danger";
        case "COLLECTED":
          return "text-success";
        default:
          return "text-warning";
      }
    },
    async annulCheck(observation) {
      let message = `Are you sure you want to annul this request?`;
      const confirm = await this.showConfirmSwal(message);
      if (!confirm.isConfirmed) return;

      try {
        this.addPreloader();
        const params = {
          check_id: this.selectedItem.check_id,
          user_id: this.currentUser.user_id,
          department_expense_id: this.selectedItem.department_expense_id,
          type: this.selectedItem.type,
          reason: this.reason,
          method: this.selectedItem.method,
          observation: observation,
        };
        const data = await DashboardService.annulCheck(params);
        if (data.success) {
          this.toastSuccess();
          await this.refresh();
        } else {
          this.toastDanger();
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
        this.showObservationsModal = false;
      }
    },
    openAddressEditorModal(item) {
      this.selectedAddress = {
        id: item.payable_expense_address_id,
        address: item.street_address,
        city: item.city,
        state: item.state,
        country: item.country,
        postal_code: item.postal_code,
        route: item.route,
        street_number: item.street_number,
        state_slug: item.state_slug,
      };
      this.showAddressEditorModal = true;
    },
    changeTab(index) {
      this.tabSelected = index;
    },
    openChecksTrackingModal(item) {
      this.showChecksTrackingModal = true;
      this.selectedCheck = item;
      this.selectedCheck.method = "E-CHECK";
    },
    openObservationsModal(item, reason) {
      this.reason = reason;
      this.showObservationsModal = true;
      this.selectedItem = item;
      this.selectedItem.method = "E-CHECK";
    },
    confirmCheck(item) {
      this.selectedCheck = item;
      this.showConfirmCheck = true;
    },
    closeModal() {
      this.$emit("close");
    },
    toastSuccess() {
      this.showToast(
        "success",
        "top-right",
        "Success",
        "CheckIcon",
        "Successfully process"
      );
    },
    toastDanger() {
      this.showToast(
        "danger",
        "top-right",
        "Oop!",
        "AlertOctagonIcon",
        "Failed process"
      );
    },
    async refresh() {
      await this.getChecksCounter();
      this.getPendingEchecks();
    },
  },
};
</script>