export default [
  {
    key: "cardholdername",
    label: "Card",
    class: "text-center",
    visible: true,
  },
  {
    key: "transaction_date",
    label: "Transaction Date",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    key: "amount",
    label: "Amount",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    key: "description",
    label: "Description",
    visible: true,
  },
  {
    key: "ticket",
    label: "Ticket",
    visible: true,
  },
];
