<template>
  <div class="body-header-card">
    <div class="card-body-hc">
      <div class="icon-card" :class="bgColorIcon">
        <component :is="getIconComponent(iconName)" />
      </div>
      <div class="content-card">
        <template v-if="showFooter">
          <div class="title">Status:</div>
          <div class="content-negotiate">
            <div class="status-name mr-1">{{ statusName }}</div>
            <div class="amount" :class="amountColor">
              {{ amount | formatCurrency }}
            </div>
          </div>
        </template>
        <template v-else>
          <div class="title">{{ title }}</div>
          <div class="amount" :class="amountColor">
            {{ amount | formatCurrency }}
          </div>
        </template>
      </div>
    </div>
    <div class="footer-card-hc" v-if="showFooter">
      <div class="content-footer">
        <UserIcon />
        <span>N° of clients {{ totalClients }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import RetainerFeeIcon from "@/views/debt-solution/views/dashboard/icons/RetainerFeeIcon.vue";
import PaymentsIcon from "@/views/debt-solution/views/dashboard/icons/PaymentsIcon.vue";
import TotalPendingIcon from "@/views/debt-solution/views/dashboard/icons/TotalPendingIcon.vue";
import SettlementTotalIcon from "@/views/debt-solution/views/dashboard/icons/SettlementTotalIcon.vue";
import SettlementCostIcon from "@/views/debt-solution/views/dashboard/icons/SettlementCostIcon.vue";
import SettlementFeeIcon from "@/views/debt-solution/views/dashboard/icons/SettlementFeeIcon.vue";
import ActiveIcon from "@/views/debt-solution/views/dashboard/icons/ActiveIcon.vue";
import OthersFeeIcon from "@/views/debt-solution/views/dashboard/icons/OthersFeeIcon.vue";
import OthersVoidRefundIcon from "@/views/debt-solution/views/dashboard/icons/OthersVoidRefundIcon.vue";
import OthersChargeBack from "@/views/debt-solution/views/dashboard/icons/OthersChargeBack.vue";
import ToNegotiateOutstandingIcon from "@/views/debt-solution/views/dashboard/icons/ToNegotiateOutstandingIcon.vue";
import ToNegotiateLoyalIcon from "@/views/debt-solution/views/dashboard/icons/ToNegotiateLoyalIcon.vue";
import UserIcon from "@/views/debt-solution/views/dashboard/icons/UserIcon.vue";
export default {
  name: "BodyHeaderCard",
  components: {
    UserIcon,
    RetainerFeeIcon,
    PaymentsIcon,
    TotalPendingIcon,
    SettlementTotalIcon,
    SettlementCostIcon,
    SettlementFeeIcon,
    ActiveIcon,
    OthersFeeIcon,
    OthersVoidRefundIcon,
    OthersChargeBack,
    ToNegotiateOutstandingIcon,
    ToNegotiateLoyalIcon,
  },
  props: {
    iconName: {
      type: String,
      required: false,
      default: "retainer-fee",
    },
    bgColorIcon: {
      type: String,
      required: false,
      default: "bg-primary",
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    amount: {
      type: String | Number,
      required: false,
      default: 0,
    },
    amountColor: {
      type: String,
      required: false,
      default: "",
    },
    showFooter: {
      type: Boolean,
      required: false,
      default: false,
    },
    totalClients: {
      type: Number | String,
      required: false,
      default: 0,
    },
    statusName: {
      type: String,
      required: false,
      default: "",
    },
  },
  methods: {
    getIconComponent(iconName) {
      const iconMap = {
        "retainer-total-fee": "RetainerFeeIcon",
        "retainer-total-payments": "PaymentsIcon",
        "retainer-total-pending": "TotalPendingIcon",
        "settlement-total": "SettlementTotalIcon",
        "settlement-cost": "SettlementCostIcon",
        "settlement-fee": "SettlementFeeIcon",
        "to-negotiate-active": "ActiveIcon",
        "to-negotiate-loyal": "ToNegotiateLoyalIcon",
        "to-negotiate-outstanding": "ToNegotiateOutstandingIcon",
        "settlements-total": "SettlementTotalIcon",
        "settlements-cost": "SettlementCostIcon",
        "settlements-fee": "SettlementFeeIcon",
        "others-fee": "OthersFeeIcon",
        "others-void-refund": "OthersVoidRefundIcon",
        "others-charge-back": "OthersChargeBack",
      };

      return iconMap[iconName] || "RetainerFeeIcon";
    },
  },
};
</script>
<style lang="scss">
.body-header-card {
  font-family: "montserrat";
  background: #ffffff;
  padding: 1rem;
  border-radius: 0.3rem;
  box-shadow: 0px 0px 12px 0px #0000001a;
  cursor: pointer;
  width: 100%;
  height: 100%;
  .card-body-hc {
    display: flex;
    align-items: center;
    gap: 1rem;
    .icon-card {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      border-radius: 0.3rem;
    }
    .content-card {
      width: 100%;
      .title {
        color: #6c6c6c;
        font-size: 1.1rem;
        font-weight: 500;
      }
      .amount,
      .status-name {
        font-size: 1rem;
        font-weight: 600;
        color: #565656;
      }
      .content-negotiate {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .footer-card-hc {
    padding-top: 1rem;
    .content-footer {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      border-top: 1px solid #dddddd;
      padding-top: 1rem;
      color: #898989;
      font-size: 1rem;
      font-weight: 500;
    }
  }

  .bg-primary {
    background: #0090e7 !important;
  }
  .bg-success {
    background: #00dd31 !important;
  }
  .bg-warning {
    background: #ff8e00 !important;
  }
  .bg-danger {
    background: #e70000 !important;
  }
  .bg-primary-light {
    background: rgba(0, 144, 231, 0.3) !important;
  }
  .bg-success-light {
    background: rgba(0, 221, 49, 0.3) !important;
  }
  .bg-warning-light {
    background: rgba(255, 142, 0, 0.3) !important;
  }
  .bg-danger-light {
    background: rgba(231, 0, 0, 0.3) !important;
  }
  .text-primary {
    color: #0090e7 !important;
  }
  .text-success {
    color: #00dd31 !important;
  }
  .text-warning {
    color: #ff8e00 !important;
  }
  .text-danger {
    color: #e70000 !important;
  }
}
.dark-layout {
  .body-header-card {
    background: #111113;
    .card-body-hc {
      .content-card {
        .title {
          color: #ffffff;
        }
        .amount,
        .status-name {
          color: #ffffff;
        }
      }
    }
    .footer-card-hc {
      .content-footer {
        color: #ffffff;
        border-top: 1px solid #3d3d3d;
      }
    }
  }
}
</style>
