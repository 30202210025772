<template>
  <div>
    <DailySettlement @newDate="newDate" :key="key" />
    <DailySettlementByMerchant :key="key + 1" />
  </div>
</template>

<script>
import moment from "moment";
import DailySettlement from "@/views/commons/components/reports/tabs/daily-payment-report/DailySettlement.vue";
import DailySettlementByMerchant from "@/views/commons/components/reports/tabs/daily-payment-report/DailySettlementByMerchant.vue";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      key: 0,
    };
  },
  components: {
    DailySettlement,
    DailySettlementByMerchant,
  },
  methods: {
    ...mapActions({
      A_DAILY_SETTLEMENTS_DATE_RANGE:
        "ManagementReportStore/A_DAILY_SETTLEMENTS_DATE_RANGE",
    }),
    newDate(cur) {
      let currentDate = moment(cur).format("MM/DD/YYYY");
      const range = {
        startDate: currentDate,
        endDate: currentDate,
      };
      this.A_DAILY_SETTLEMENTS_DATE_RANGE(range);
      this.key++;
    },
    setDefaultDatesRange() {
      const yesterday = moment().subtract(1, "days").format("MM/DD/YYYY");
      const range = {
        startDate: yesterday,
        endDate: yesterday,
      };
      this.A_DAILY_SETTLEMENTS_DATE_RANGE(range);
    },
  },
  created() {
    this.setDefaultDatesRange();
  },
};
</script>