<template>
  <b-modal
    ref="daily-settlement-detail-by-merchant"
    title-class="h3 text-white font-weight-bolder"
    @hidden="$emit('hidden')"
    size="xl"
    hide-footer
  >
    <template #modal-title>
      <span class="font-weight-bolder">
        Detail
        {{
          G_CURRENT_DAILY_SETTLEMENT_MERCHANT.merchantName
            ? "of " + G_CURRENT_DAILY_SETTLEMENT_MERCHANT.merchantName
            : ""
        }}
      </span>
    </template>
    <template #default>
      <daily-settlement />
    </template>
  </b-modal>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import DailySettlement from "@/views/commons/components/reports/tabs/daily-payment-report/DailySettlement.vue";
export default {
  name: "DailySettlementDetailByMerchant",
  components: {
    DailySettlement,
  },
  mounted() {
    this.toggleModal("daily-settlement-detail-by-merchant");
  },
  methods: {
    ...mapActions({
      A_GET_DAILY_SETTLEMENT_BY_MERCHANT_DETAIL:
        "ManagementReportStore/A_GET_DAILY_SETTLEMENT_BY_MERCHANT_DETAIL",
      A_GET_DAILY_SETTLEMENT_BY_MERCHANT:
        "ManagementReportStore/A_GET_DAILY_SETTLEMENT_BY_MERCHANT",
    }),
  },
  computed: {
    ...mapGetters({
      G_CURRENT_DAILY_SETTLEMENT_MERCHANT:
        "ManagementReportStore/G_CURRENT_DAILY_SETTLEMENT_MERCHANT",
      G_GET_DAILY_SETTLEMENT_PIE:
        "ManagementReportStore/G_GET_DAILY_SETTLEMENT_PIE",
      G_DAILY_SETTLEMENTS_DATE_RANGE:
        "ManagementReportStore/G_DAILY_SETTLEMENTS_DATE_RANGE",
    }),
  },
  watch: {
    async G_GET_DAILY_SETTLEMENT_PIE() {
      await this.A_GET_DAILY_SETTLEMENT_BY_MERCHANT_DETAIL({
        range: this.G_DAILY_SETTLEMENTS_DATE_RANGE,
        merchant_id: this.G_CURRENT_DAILY_SETTLEMENT_MERCHANT.merchantId,
      });
    },
  },
};
</script>