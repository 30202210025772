<template>
  <div>
    <b-modal
      ref="modal-detail-inversion"
      header-bg-variant="transparent border-bottom border-bottom-2"
      size="md"
      header-class="p-0"
      modal-class="modal-primary"
      hide-footer
      @hidden="$emit('close')"
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <span>Meta Investment ({{ validateTitleMonthYear }})</span>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="$emit('close')"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>
      <div>
        <card-creative-report
          :send-year="sendYear"
          :send-month="sendMonth"
          @newTitleModal="newTitleModal($event)"
        />
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CardCreativeReport from "@/views/ceo/dashboard/components/CardCreativeReport.vue";
export default {
  name: "DetailInversion",
  components: {
    CardCreativeReport,
  },
  props: {
    sendYear: {
      type: Number,
      required: true,
      default: null,
    },
    sendMonth: {
      type: Number,
      required: true,
      default: null,
    },
    sendPrograms: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      titleModal: null,
    };
  },
  computed: {
    ...mapGetters({
      titleMonthYear: "DashboardCeoStore/G_TITLE_MODAL",
    }),
    validateTitleMonthYear() {
      return this.titleModal ? this.titleModal : this.titleMonthYear;
    },
  },
  created() {},
  mounted() {
    this.toggleModal("modal-detail-inversion");
  },
  methods: {
    newTitleModal(value) {
      this.titleModal = value;
    },
  },
};
</script>
<style lang="scss">
</style>