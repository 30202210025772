<template>
  <b-modal
    title-class="h3 text-white"
    v-model="showModal"
    size="md"
    centered
    :title="'Summary - ' + senderName"
    hide-footer
    modal-class="modal-primary"
    body-class="p-0"
    @hidden="emitClose"
  >
    <div v-html="summary" class="p-1"></div>
  </b-modal>
</template>

<script>
export default {
  props: {
    summary: {
      type: String,
      required: true,
    },
    senderName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showModal: true,
    };
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
  },
};
</script>