<template>
  <div class="card-creative-report">
    <div
      style="min-width: 280px"
      class="d-flex justify-content-end align-items-center mb-1 px-1"
    >
      <div
        v-if="selectedType === 2"
        class="absolute-linker pt-2 pb-1"
        :class="{ dark: isDarkSkin }"
      >
        <div
          class="d-flex justify-content-between align-items-center h-full px-1"
          style="font-size: 15px; font-weight: bold"
        >
          <feather-icon
            icon="ChevronLeftIcon"
            size="20"
            class="cursor-pointer"
            @click="prev"
          />
          <span>
            {{ titleMonthYear }}
          </span>
          <feather-icon
            icon="ChevronRightIcon"
            size="20"
            class="cursor-pointer"
            @click="next"
          />
        </div>
      </div>
      <v-select
        v-if="selectedType === 1"
        class="mr-1"
        v-model="dataYear"
        :options="optionsYears"
        :reduce="(option) => option.id"
        :clearable="false"
        label="name"
        style="width: 150px"
        @input="refresh()"
      ></v-select>
      <b-form-radio-group
        v-if="!showDetails"
        id="btn-radios-1"
        class="mr-1"
        v-model="selectedType"
        :options="optionsType"
        button-variant="outline-primary"
        size="md"
        name="radio-btn-outline"
        buttons
        @change="handleTypeSearch()"
      ></b-form-radio-group>
      <b-button
        v-if="showDetails"
        variant="primary"
        class="d-flex justify-content-center align-items-center ml-1"
        size="sm"
        @click="returnYear()"
      >
        <tabler-icon icon="ArrowBackIcon" size="24" />
        <span style="padding-left: 3px">Back</span>
      </b-button>
    </div>

    <b-table
      id="comercial-report-table-ceo"
      ref="comercial-report-table-ceo"
      slot="table"
      class="position-relative table-new-customization"
      empty-text="No matching records found"
      sticky-header="70vh"
      primary-key="id"
      responsive="md"
      show-empty
      no-border-collapse
      no-provider-filtering
      :busy.sync="isBusy"
      :fields="visibleFields"
      :items="myProvider"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(program_id)="data">
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <div v-if="data.item.program_id > 0" class="programs_image">
              <b-img
                :src="getProgramImageRoute(data.item.program_id)"
                v-b-tooltip.right="data.item.program_name"
                alt="img_services"
              />
            </div>
            <b-avatar
              v-else
              size="40"
              square
              icon="file-earmark-image-fill"
              class="rounded"
              variant="light"
              v-b-tooltip.right="data.item.program_name"
            />
          </div>
        </div>
      </template>
      <template #cell(investment)="data">
        <span
          :class="{
            'text-success': data.item.investment > 0,
            'text-secondary': data.item.investment <= 0,
          }"
        >
          {{ data.item.investment | formatCurrency }}
        </span>
      </template>
      <template #cell(details)="data">
        <div
          v-if="selectedType == 2"
          class="d-flex align-items-center justify-content-center"
        >
          <b-badge
            variant="light-primary"
            class="cursor-pointer"
            @click="openModalMeta(data.item.program_id)"
          >
            Meta ADS
            <feather-icon icon="ArrowUpRightIcon" size="20" />
          </b-badge>
          <b-badge
            variant="light-info"
            class="cursor-pointer ml-1"
            @click="openModalReort(data.item.program_id)"
          >
            Commercial Report
            <feather-icon icon="ArrowUpRightIcon" size="20" />
          </b-badge>
        </div>
        <div v-else class="d-flex align-items-center justify-content-center">
          <b-badge
            variant="light-primary"
            class="cursor-pointer"
            @click="showDetailsByMonth(data.item.month)"
          >
            Details
            <feather-icon icon="ArrowUpRightIcon" size="20" />
          </b-badge>
        </div>
      </template>
      <template #bottom-row>
        <b-th>
          <b-badge
            variant="primary"
            class="w-100 text-center"
            style="
              font-size: 14px;
              font-weight: 600;
              padding-bottom: 7px;
              padding-top: 7px;
            "
          >
            TOTAL
          </b-badge>
        </b-th>
        <b-th>
          <b-badge
            variant="success"
            class="w-100 text-center text-black"
            style="
              font-size: 14px;
              font-weight: 600;
              padding-bottom: 7px;
              padding-top: 7px;
            "
          >
            {{ totalInvestment | formatCurrency }}
          </b-badge>
        </b-th>
        <b-th class="fill-bottom-row" />
      </template>
    </b-table>

    <creative-report-modal
      v-if="showCreativeReportModal"
      :send-year="dataYear"
      :send-month="dataMonth"
      :program-id="programId"
      @hidden="showCreativeReportModal = false"
    />
    <meta-ads-modal
      v-if="showMetaAdsModal"
      :send-year="dataYear"
      :send-month="dataMonth"
      :program-id="programId"
      @hidden="showMetaAdsModal = false"
    />
  </div>
</template>
<script>
import ComercialReportService from "@/views/commons/components/reports/tabs/comercial-report/service/comercial-report-v2.service.js";
import CreativeReportModal from "@/views/ceo/dashboard/modals/creative-report/CreativeReportModal.vue";
import MetaAdsModal from "@/views/ceo/dashboard/modals/creative-report/MetaAdsModal.vue";
export default {
  name: "CardCreativeReport",
  components: {
    CreativeReportModal,
    MetaAdsModal,
  },
  props: {
    sendYear: {
      type: Number,
      required: true,
    },
    sendMonth: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      fields: [
        {
          key: "program_id",
          label: "Service",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: false,
          visible: false,
        },
        {
          key: "month_name",
          label: "Month",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: false,
          visible: false,
        },
        {
          key: "investment",
          label: "Inv",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: false,
          visible: true,
        },
        {
          key: "details",
          label: "Details",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: false,
          visible: true,
        },
      ],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      dataYear: null,
      dataMonth: null,
      showCreativeReportModal: false,
      showMetaAdsModal: false,
      totalInvestment: 0,
      selectedType: 2, //1: year, 2: month
      optionsType: [
        { text: "Year", value: 1 },
        { text: "Month", value: 2 },
      ],
      showDetails: false,
      optionsYears: [],
    };
  },
  computed: {
    titleMonthYear() {
      return this.selectedType === 1
        ? `${this.dataYear}`
        : `${this.months[this.dataMonth - 1]} ${this.dataYear}`;
    },
    visibleFields() {
      if (this.selectedType === 1) {
        this.getFieldsByKeys("month_name").visible = true;
        this.getFieldsByKeys("program_id").visible = false;
      } else {
        this.getFieldsByKeys("month_name").visible = false;
        this.getFieldsByKeys("program_id").visible = true;
      }
      return this.fields.filter((field) => field.visible);
    },
  },
  created() {
    this.initMonthYear();
    this.generateYears();
  },
  mounted() {},
  methods: {
    async myProvider() {
      try {
        const params = {
          year: this.dataYear,
          month: this.dataMonth,
          type: this.selectedType,
        };
        const { data } = await ComercialReportService.getCommercialReportCeo(
          params
        );
        this.totalInvestment = data?.[0]?.total_investment || 0;
        return data;
      } catch (e) {
        this.showErrorSwal(e);
        console.log("error ceoooo", e);
      }
    },
    initMonthYear() {
      this.dataMonth = this.sendMonth;
      this.dataYear = this.sendYear;
    },
    async next() {
      if (this.dataMonth < 12) {
        this.dataMonth++;
      } else {
        this.dataMonth = 1;
        this.dataYear++;
      }
      await this.refresh();
    },
    async prev() {
      if (this.dataMonth > 1) {
        this.dataMonth--;
      } else {
        this.dataMonth = 12;
        this.dataYear--;
      }
      await this.refresh();
    },
    async refresh() {
      this.$emit("newTitleModal", this.titleMonthYear);
      await this.$refs["comercial-report-table-ceo"].refresh();
    },
    openModalReort(programId) {
      this.programId = programId;
      this.showCreativeReportModal = true;
    },
    openModalMeta(programId) {
      this.programId = programId;
      this.showMetaAdsModal = true;
    },
    getFieldsByKeys(keys) {
      return this.fields.find((field) => field.key === keys);
    },
    async handleTypeSearch() {
      await this.initMonthYear();
      await this.refresh();
    },
    async showDetailsByMonth(month) {
      this.dataMonth = month;
      this.selectedType = 2;
      this.showDetails = true;
      await this.refresh();
    },
    returnYear() {
      this.selectedType = 1;
      this.showDetails = false;
      this.refresh();
    },
    generateYears() {
      const startYear = 2020;
      const currentYear = new Date().getFullYear();
      this.optionsYears = [];
      for (let year = currentYear; year >= startYear; year--) {
        this.optionsYears.push({ id: year, name: year });
      }
    },
  },
};
</script>
<style lang="scss">
.card-creative-report {
  #comercial-report-table-ceo {
    .programs_image {
      background-color: #e9e9e9;
      padding: 0.2rem;
      border-radius: 10px;
      width: 3.2rem;
      height: 3.2rem;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .rounded {
      border-radius: 0.357rem !important;
    }
    .b-table-bottom-row {
      position: sticky;
      bottom: 0;
      z-index: 1;

      th:nth-child(1) {
        border-radius: 0px 0px 0px 15px !important;
        margin-left: 5px !important;
        background: #0090e7 !important;
      }

      th:nth-child(n + 2):nth-child(-n + 3) {
        font-size: 16px;
        padding-top: 10px;
        padding-bottom: 10px;
        background: #0090e7 !important;
      }

      th:nth-child(3) {
        border-radius: 0px 0px 15px 0px !important;
        margin-left: 10px !important;
        background: #0090e7 !important;
      }
    }
    .fill-bottom-row {
      background-color: #ffffff !important;
    }
  }
}
</style>