<template>
  <b-card
    class="b_card_content dashboard__card chat-sentiment-grid-ceo"
    :class="isDarkSkin ? 'dashboard__card--dark' : ''"
  >
    <b-row>
      <b-col cols="8">
        <div class="d-flex align-items-center">
          <b-avatar rounded="sm" size="4em" class="b_avatar_color">
            <div class="ico_content">
              <b-img
                src="@/assets/images/icons/iconoMessenger.svg"
                style="width: 85%; height: 85%"
              />
            </div>
          </b-avatar>
          <div class="ml-1">
            <h3 class="font-weight-bolder mb-0">Replies Analysis</h3>
          </div>
        </div>
      </b-col>
      <b-col v-if="activeDate" class="d-flex" cols="4">
        <v-select
          v-model="dateYear"
          :reduce="(user) => user.id"
          :options="years"
          :selectable="(option) => option.selectable === true"
          :clearable="false"
          label="name"
          style="width: 100px"
          class="mr-1"
        >
          <template #first>
            <b-form-select-option :value="null" disabled>
              Please select an option
            </b-form-select-option>
          </template>
        </v-select>

        <v-select
          v-model="dateMonth"
          :reduce="(user) => user.id"
          :options="months"
          :clearable="false"
          label="name"
          style="width: 150px"
        >
          <template #first>
            <b-form-select-option :value="null" disabled>
              Please select an option
            </b-form-select-option>
          </template>
        </v-select>
      </b-col>
    </b-row>
    <div class="mt-1 b-table">
      <div class="thead">
        <div class="tr d-flex">
          <div
            class="th d-flex justify-content-center"
            style="flex: 1; height: 40px"
            v-for="(row, index) in Fields"
            :key="index"
            :class="row.class"
          >
            <img
              v-if="row.label == 'Positive'"
              src="@/assets/images/icons/happyFace.svg"
              style="max-width: 30px"
              alt=""
            />
            <img
              v-if="row.label == 'Neutral'"
              src="@/assets/images/icons/NeutralFace.svg"
              style="max-width: 30px"
              alt=""
            />
            <img
              v-if="row.label == 'Negative'"
              src="@/assets/images/icons/NegativeFace.svg"
              style="max-width: 30px"
              alt=""
            />
            <img
              v-if="row.label == 'Mixed'"
              src="@/assets/images/icons/MixedFace.svg"
              style="max-width: 30px"
              alt=""
            />
            <span class="align-self-center p-1">{{ row.label }}</span>
          </div>
        </div>
      </div>
      <div class="tbody">
        <div class="tr d-flex my-1" v-for="(row, index) in items" :key="index">
          <div
            class="mr-1"
            style="width: 20%; border-radius: 10px"
            :style="
              isDarkSkin
                ? 'background-color: #17171A'
                : 'background-color: #fafafa'
            "
          >
            <div class="td-service">
              <div class="d-flex justify-content-center align-items-center">
                <div class="m-1 programs_image">
                  <img
                    :src="getProgramImageRoute(getProgramId(row.pageName))"
                    v-b-tooltip.right="row.pageName"
                    alt="img_services"
                    style="max-width: 40px; max-height: 40px"
                  />
                </div>
                <div class="mr-1 my-1 text-center" style="flex: 1">
                  <span>{{ row.pageName }}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="d-flex"
            style="
              width: 80%;
              border-radius: 10px;
              font-weight: 700;
              font-size: 15px;
            "
            :style="
              isDarkSkin
                ? 'background-color: #17171A'
                : 'background-color: #fafafa'
            "
          >
            <div
              class="d-flex justify-content-center td-sentiment"
              style="flex: 1"
            >
              <div class="align-self-center text-center text-danger">
                <span
                  :class="row.negative != 0 ? 'cursor-pointer' : ''"
                  @click="row.negative != 0 ? openModal(row, 'Negative') : ''"
                  style="text-decoration: underline"
                  >{{ row.negative }} ({{
                    ((row.negative / row.total) * 100).toFixed(2) + " %"
                  }})</span
                >
              </div>
            </div>
            <div
              class="d-flex justify-content-center td-sentiment"
              style="flex: 1"
            >
              <div class="align-self-center text-center text-success">
                <span
                  :class="row.positive != 0 ? 'cursor-pointer' : ''"
                  @click="row.positive != 0 ? openModal(row, 'Positive') : ''"
                  style="text-decoration: underline"
                  >{{ row.positive }} ({{
                    ((row.positive / row.total) * 100).toFixed(2) + " %"
                  }})</span
                >
              </div>
            </div>
            <div
              class="d-flex justify-content-center td-sentiment"
              style="flex: 1"
            >
              <div class="align-self-center text-center text-primary">
                <span
                  :class="row.neutral != 0 ? 'cursor-pointer' : ''"
                  @click="row.neutral != 0 ? openModal(row, 'Neutral') : ''"
                  style="text-decoration: underline"
                  >{{ row.neutral }} ({{
                    ((row.neutral / row.total) * 100).toFixed(2) + " %"
                  }})</span
                >
              </div>
            </div>
            <div
              class="d-flex justify-content-center td-sentiment"
              style="flex: 1"
            >
              <div class="align-self-center text-center text-info">
                <span
                  :class="row.mixed != 0 ? 'cursor-pointer' : ''"
                  @click="row.mixed != 0 ? openModal(row, 'Mixed') : ''"
                  style="text-decoration: underline"
                  >{{ row.mixed }} ({{
                    ((row.mixed / row.total) * 100).toFixed(2) + " %"
                  }})</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <SentimentAnalysisOfMessengerModal
      v-if="showModal"
      :data-object="dataObject"
      :name-sentiment="nameSentiment"
      :year="selectYear ? selectYear : dateYear"
      :month="selectMonth ? selectMonth : dateMonth"
      @close="showModal = false"
    />
  </b-card>
</template>
<script>
import MessengerMetaService from "@/views/commons/Messenger/components/messenger/services/messenger.meta.service.js";
import SentimentAnalysisOfMessengerModal from "@/views/ceo/dashboard/modals/SentimentAnalysisOfMessengerModal.vue";
export default {
  name: "CardSentimentAnalysisOfMessenger",
  components: {
    SentimentAnalysisOfMessengerModal,
  },
  props: {
    selectYear: {
      type: Number,
      default: null,
    },
    selectMonth: {
      type: Number,
      default: null,
    },
    activeDate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isBusy: false,
      Fields: [
        {
          key: "page",
          label: "Service",
          sortable: false,
          visible: true,
          class: "bg-service text-white text-center th-service mr-1",
        },
        {
          key: "negative",
          label: "Negative",
          sortable: false,
          visible: true,
          class: "bg-danger text-white text-center th-sentiment ",
        },
        {
          key: "positive",
          label: "Positive",
          sortable: false,
          visible: true,
          class: "bg-success text-white text-center th-sentiment ",
        },
        {
          key: "neutral",
          label: "Neutral",
          sortable: false,
          visible: true,
          class: "bg-primary text-white text-center th-sentiment ",
        },
        {
          key: "mixed",
          label: "Mixed",
          sortable: false,
          visible: true,
          class: "bg-info text-white text-center th-sentiment",
        },
      ],
      showModal: false,
      items: [],
      dataObject: null,
      nameSentiment: null,
      optionsYears: [],
      today: new Date(),
      dateYear: null,
      dateMonth: null,
      optionsMonth: [
        { id: 1, name: "January" },
        { id: 2, name: "February" },
        { id: 3, name: "March" },
        { id: 4, name: "April" },
        { id: 5, name: "May" },
        { id: 6, name: "June" },
        { id: 7, name: "July" },
        { id: 8, name: "August" },
        { id: 9, name: "September" },
        { id: 10, name: "October" },
        { id: 11, name: "November" },
        { id: 12, name: "December" },
      ],
    };
  },
  computed: {
    months() {
      if (this.dateYear === this.today.getFullYear()) {
        return this.optionsMonth.filter(
          (month) => month.id <= this.today.getMonth() + 1
        );
      }
      return this.optionsMonth;
    },
    years() {
      this.optionsYears.forEach((year) => {
        year.selectable = true;
      });

      const currentMonth = this.today.getMonth() + 1;
      if (this.dateMonth > currentMonth) {
        const lastYearIndex = this.optionsYears.length - 1;
        this.optionsYears[lastYearIndex].selectable = false;
      }

      return this.optionsYears;
    },
  },
  async created() {
    if (this.activeDate) {
      this.currentMonth();
      this.generateYears();
    }
    await this.getSentimentMessage();
  },
  mounted() {},
  methods: {
    async getSentimentMessage() {
      this.isBusy = true;
      try {
        const params = {
          year: this.selectYear ? this.selectYear : this.dateYear,
          month: this.selectMonth ? this.selectMonth : this.dateMonth,
        };

        const { data } = await MessengerMetaService.getSentimentMessage(params);
        this.items = data;
        this.isBusy = false;
      } catch (error) {
        const status = error.response.status;
        if (status === 404) {
          this.items = [];
          return;
        }
        this.showErrorSwal(error);
      }
    },
    getProgramId(page_name) {
      const programs = {
        BUSINESS: 1,
        "BOOST CREDIT": 2,
        "CREDIT EXPERTS": 3,
        "DEBT SOLUTION": 4,
        "TAX RESEARCH": 5,
        "COURT INFO": 6,
        SPECIALIST: 7,
        KEYBOOK: 8,
        PARAGON: 9,
      };
      page_name = page_name.trim().toUpperCase();
      const programId = programs[page_name] ?? null;
      return programId;
    },
    getBadgeVariant(sentiment) {
      const badgeVariants = {
        POSITIVE: "light-success",
        NEGATIVE: "light-danger",
        NEUTRAL: "light-primary",
        MIXED: "light-info",
      };
      sentiment = sentiment.trim().toUpperCase();
      const badgeVariant = badgeVariants[sentiment] ?? "light-secondary";
      return badgeVariant;
    },
    getColorSentiment(sentiment) {
      const colors = {
        POSITIVE: "#00D25B",
        NEGATIVE: "#F10000",
        NEUTRAL: "#0090E7",
        MIXED: "#6E3CCD",
      };
      sentiment = sentiment.trim().toUpperCase();
      const color = colors[sentiment] ?? "#FFFFFF";
      return color;
    },
    refresh() {
      this.$refs["chat-sentiment-grid-ceo"].refresh();
    },
    modalSummary(data) {
      this.showModalSummary = true;
      this.idChat = data.item._id;
    },
    openModal(row, nameSentiment) {
      this.dataObject = row;
      this.nameSentiment = nameSentiment;
      this.showModal = true;
    },
    generateYears() {
      const today = new Date();
      const currentYear = today.getFullYear();
      const beginningYear = 2023;
      this.optionsYears = [];

      for (let i = beginningYear; i <= currentYear; i++) {
        this.optionsYears.push({ id: i, name: i, selectable: true });
      }

      this.dateYear = currentYear;
    },
    currentMonth() {
      const today = new Date();
      const currentMonth = today.getMonth() + 1;
      this.dateMonth = currentMonth;
    },
  },
  watch: {
    async selectYear() {
      await this.getSentimentMessage();
    },
    async selectMonth() {
      await this.getSentimentMessage();
    },
    async dateYear() {
      await this.getSentimentMessage();
    },
    async dateMonth() {
      await this.getSentimentMessage();
    },
  },
};
</script>
<style lang="scss" scoped>
.chat-sentiment-grid-ceo {
  .bg-service {
    background: #3f7afa !important;
  }
  .th-service {
    border-radius: 10px 10px 0px 0px !important;
  }
  .th-sentiment:nth-child(2) {
    border-radius: 10px 0px 0px 0px !important;
  }

  .th-sentiment:last-child {
    border-radius: 0px 10px 0px 0px !important;
  }
  .b_avatar_color {
    background: rgba(167, 197, 255, 1) !important;
  }
}
</style>