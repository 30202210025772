import { amgApi } from "@/service/axios";

class MetaService {
  async geAdsInsights(params) {
    try {
      const data = await amgApi.post("/creative/meta/index", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAdsByAdId(adId) {
    try {
      const data = await amgApi.get(`/creative/meta/show/${adId}`);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAdPreview(params, id) {
    try {
      const data = await amgApi.post(
        `/creative/meta/facebook-ads/show/${id}`,
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async syncWithMeta(params) {
    try {
      const data = await amgApi.post(
        "/creative/meta/facebook-ads-queue/sync-today-meta-ads",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAdInsightsByDay(params) {
    try {
      const data = await amgApi.post(
        "/creative/meta/get-ad-insights-by-day",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getTrackingMigrateStatus() {
    try {
      const data = await amgApi.get(
        "/creative/meta/facebook-ads/get-tracking-migrate-status"
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getMetaAds(params) {
    try {
      const data = await amgApi.post("/creative/meta/ads/index", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getMetaAdById(id) {
    try {
      const data = await amgApi.get(`/creative/meta/ads/show/${id}`);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAdsByIds(params) {
    try {
      const data = await amgApi.post("/creative/meta/ads/get-ads-by-ids", params);
      return data;
    } catch (error) {
      throw error;
    }
  }
}

export default new MetaService();
