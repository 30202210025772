var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"size":"sm","title":"Generate Call Summary","modal-class":"modal-primary","no-close-on-backdrop":""},on:{"hidden":function($event){return _vm.$emit('hidden')}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":cancel}},[_vm._v("Cancel")]),_c('b-button',{staticClass:"btn rounded",attrs:{"variant":"outline-success"},on:{"click":_vm.generateAutomaticNote}},[_vm._v(" Create ")])]}}]),model:{value:(_vm.showGenerateAutomaticNoteForm),callback:function ($$v) {_vm.showGenerateAutomaticNoteForm=$$v},expression:"showGenerateAutomaticNoteForm"}},[_c('ValidationObserver',{ref:"GenerateAutomaticNoteForm"},[_c('b-form',[_c('b-row',[(_vm.call.has_repeat_numbers == false)?_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Choose note is for ...","label-for":"contact_type_id"}},[_c('b-form-radio-group',{staticClass:"mt-1",style:(errors[0] ? 'border: 1px solid red !important;' : ''),attrs:{"options":_vm.contact_type_options,"value-field":"id","text-field":"name","disabled":_vm.disable_contact_types},model:{value:(_vm.contact_type_id),callback:function ($$v) {_vm.contact_type_id=$$v},expression:"contact_type_id"}})],1)]}}],null,false,3329048647)})],1):_vm._e(),(_vm.call.has_repeat_numbers == true)?_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Select lead or clients","label-for":"contacts"}},[_c('v-select',{staticClass:"rounded",class:errors[0] ? 'rounded border border-danger' : '',style:(errors[0] ? 'border: 1px solid red !important;' : ''),attrs:{"id":"possible_contacts","label":"name","options":_vm.possible_contacts,"reduce":function (el) { return el.id; },"placeholder":"Select where the summary will be saved ...","multiple":"","closeOnSelect":false},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var number = ref.number;
return [_c('div',{staticClass:"d-flex justify-content-between w-100"},[_c('span',[_vm._v(_vm._s(name)+" ")]),_c('span',[_vm._v("("+_vm._s(number)+")")])])]}}],null,true),model:{value:(_vm.possible_contact_ids),callback:function ($$v) {_vm.possible_contact_ids=$$v},expression:"possible_contact_ids"}})],1)]}}],null,false,3258913932)})],1):_vm._e(),(_vm.call.has_repeat_numbers == false && _vm.contact_type_id != null)?_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.contact_type_id == 1 ? 'Lead' : 'Client',"label-for":"contacts"}},[_c('vue-autosuggest',{ref:"autocomplete",class:errors[0] ? 'rounded border border-danger' : '',style:(errors[0] ? 'border: 1px solid red !important;' : ''),attrs:{"id":"contact_id","suggestions":_vm.contacts,"get-suggestion-value":_vm.getSuggestionValue,"input-props":{
                  id: 'autosuggest__input',
                  class: ['form-control', errors[0]],
                  disabled: _vm.disable_contacts,
                }},on:{"input":_vm.getContactsByModule,"selected":_vm.selectHandler},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var suggestion = ref.suggestion;
return [_c('span',{staticClass:"my-suggestion-item"},[_vm._v(_vm._s(suggestion.item.name))])]}}],null,true),model:{value:(_vm.contact_name),callback:function ($$v) {_vm.contact_name=$$v},expression:"contact_name"}},[_c('template',{slot:"before-input"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingAutosuggest),expression:"loadingAutosuggest"}],staticClass:"lds-dual-ring"})])],2)],1)]}}],null,false,1864799226)})],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Note Type","label-for":"note_type"}},[_c('v-select',{staticClass:"rounded",class:errors[0] ? 'rounded border border-danger' : '',style:(errors[0] ? 'border: 1px solid red !important;' : ''),attrs:{"id":"note_type","label":"label","options":_vm.note_types,"reduce":function (el) { return el.id; },"placeholder":"Select a type of note ..."},model:{value:(_vm.note_type_id),callback:function ($$v) {_vm.note_type_id=$$v},expression:"note_type_id"}})],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Agent","label-for":"agent"}},[_c('v-select',{staticClass:"rounded",class:errors[0] ? 'rounded border border-danger' : '',style:(errors[0] ? 'border: 1px solid red !important;' : ''),attrs:{"id":"agent","label":"user_name","options":_vm.users,"reduce":function (el) { return el.id; },"placeholder":"Search the user who did the call ..."},model:{value:(_vm.agent_id),callback:function ($$v) {_vm.agent_id=$$v},expression:"agent_id"}})],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }