<template>
  <transition name="bounce">
    <div
      class="card-details"
      :style="isDarkSkin ? 'background-color: #262626 !important' : ''"
      v-if="show"
    >
      <b-table
        slot="table"
        class="blue-scrollbar position-relative table-new-customization"
        ref="refOtherPayments"
        no-provider-filtering
        :items="payments"
        :fields="fields"
        small
        primary-key="id"
        table-class="text-nowrap border-radius-4"
        responsive="sm"
        show-empty
        sticky-header="240px"
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>

        <template #cell(client_name)="{ item }">
          <router-link
            class="text-primary font-weight-bolder"
            v-if="item.client_name"
            target="_blank"
            :to="{
              name: pathDashboard,
              params: { idClient: `${item.client_account_id}` },
            }"
          >
            {{ item.client_name }}
            <div>{{ item.account }}</div>
          </router-link>
          <router-link
            v-else
            class="text-warning font-weight-bolder"
            target="_blank"
            :to="{
              name: 'management-lead-show',
              params: { id: item.lead_id },
            }"
          >
            {{ item.lead_name }} <br />
            ID: {{ item.lead_id }}
          </router-link>
        </template>
        <template #cell(client_type)="{ item }">
          <b-badge :variant="item.lead_name ? 'light-warning' : 'light-success'"
            >{{ item.lead_name ? "CRM" : "CLIENT" }}
          </b-badge>
        </template>

        <template #cell(modality)="{ item }">
          <div><b>NUMBER:</b> {{ item.serial_number }}</div>
          <div><b>DATE:</b> {{ item.serial_date | myGlobalDay }}</div>
          <div><b>HOLDER:</b> {{ item.owner ? item.owner : "-" }}</div>
        </template>

        <template #cell(transaction_id)="{ item }">
          <div><b>METHOD:</b> {{ item.method }}</div>
          <div>
            <b>CONCEPT:</b>
            {{
              item.modality === "Monthly" ? "Monthly Payment" : item.modality
            }}
          </div>
          <div><b>TRANSACTION ID:</b> {{ item.voucher_id }}</div>
        </template>

        <template #cell(amount)="{ item }">
          <span class="text-success">$ {{ item.amount | currency }}</span>
        </template>

        <template #cell(tracking)="{ item }">
          <feather-icon
            class="mr-1 pointer text-primary"
            icon="ListIcon"
            size="18"
            v-b-tooltip
            title="Tracking"
            @click="showTracking(item)"
          />
        </template>
      </b-table>
    </div>
  </transition>
</template>

<script>
import OtherPaymentsService from "@/views/commons/components/other-payments/service/other-payment.service";
export default {
  props: {
    currentTab: Number,
    statusTab: Number,
    depositId: Number,
    moduleId: Number,
    statusModal: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      fields: [
        { key: "client_name", label: "Client Name" },
        {
          key: "client_type",
          label: "Client Type",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "module",
          label: "Module",
          thClass: "text-center",
          tdClass: "text-center",
        },
        { key: "transaction_id", label: "Transaction Detail" },
        { key: "modality", label: "Operation Detail" },
        { key: "amount", label: "Amount" },
      ],
      payments: [],
      isBusy: false,
      show: false,
    };
  },
  computed: {
    pathDashboard() {
      let module_modal = this.statusModal ? 16 : this.moduleId;
      const pathName = {
        3: "bussiness-dashboard-clients",
        4: "administration-dashboard",
        5: "debt-solution-dashboard",
        6: "credit-experts-dashboard",
        7: "boost-credit-dashboard",
        8: "tax-research-client-dashboard",
        10: "claim-department-dashboard",
        11: "specialists-client-dashboard",
        14: "bookeeping-dashboard",
        18: "quality-client-dashboard",
        16: "management-client-dashboard",
      };
      return pathName[module_modal];
    },
  },
  created() {
    this.$nextTick(() => (this.show = true));
    this.getDetails();
  },
  methods: {
    async getDetails() {
      try {
        let module_modal = this.statusModal ? 16 : this.moduleId;
        const params = {
          orderBy: 1,
          order: "desc",
          method: this.currentTab,
          status: this.statusTab,
          module_id: module_modal,
          deposit_id: this.depositId,
        };
        const { data } = await OtherPaymentsService.getOtherPayment(params);
        this.payments = data.data;
        return this.payments || [];
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
.card-details {
  padding: 25px;
  background-color: #d9d9d9 !important;
  overflow-y: auto;
  border-radius: 10px;
  border: 1px solid #0077e6;
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
</style>
