<template>
  <div>
    <b-modal ref="sales-modal" size="sm" @hidden="$emit('hidden')" hide-footer>
      <template #modal-title>
        <span class="text-uppercase font-weight-bolder">
          TOTAL SALES OF: {{ flyerCode }}
        </span>
      </template>
      <template #default>
        <div class="card-sales-cr py-1">
          <div
            v-for="(sale, index) in sales"
            :key="index"
            class="card-sale-item rounded"
            :class="{
              ['border-' + sale.borderColor]: sale.borderColor,
              'cursor-pointer': validateSale(sale),
            }"
            @click="validateSale(sale) && openModalDetail(sale.type)"
          >
            <div class="card-sale-item__header">
              <div class="card-sale-item__header-title">
                {{ sale.title }}
              </div>
              <b-avatar
                size="3em"
                :variant="isDarkSkin ? 'secondary' : 'light'"
              >
                <feather-icon
                  :icon="sale.icon"
                  :class="sale.colorIcon"
                  size="20"
                />
              </b-avatar>
            </div>
            <div
              class="card-sale-item__body"
              :class="`border-top-${sale.borderColor}`"
            >
              <div class="card-sale-item__body-total">
                TOTAL: {{ sale.total }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
    <SalesDetailModal
      v-if="showSalesDetailModal"
      :type="type"
      :flyer-code="flyerCode"
      :flyer-id="flyerId"
      :meta-ad-id="metaAdId"
      :date-from="dateFrom"
      :date-to="dateTo"
      :send-year="sendYear"
      :send-month="sendMonth"
      :send-range="sendRange"
      @hidden="showSalesDetailModal = false"
    />
  </div>
</template>
<script>
import ComercialReportService from "@/views/commons/components/reports/tabs/comercial-report/service/comercial-report-v2.service.js";
import SalesDetailModal from "@/views/commons/components/reports/tabs/comercial-report/modals/sales/SalesDetailModal.vue";
export default {
  name: "SalesModal",
  components: {
    SalesDetailModal,
  },
  props: {
    flyerCode: {
      type: String,
      required: true,
      default: "",
    },

    flyerId: {
      type: Number,
      required: true,
      default: null,
    },
    metaAdId: {
      type: String,
      required: false,
      default: "",
    },
    dateFrom: {
      type: String,
      required: false,
      default: null,
    },
    dateTo: {
      type: String,
      required: false,
      default: null,
    },
    sendYear: {
      type: Number,
      required: false,
      default: null,
    },
    sendMonth: {
      type: Number,
      required: false,
      default: null,
    },
    sendRange: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      sales: [
        {
          title: "Sales",
          icon: "CheckCircleIcon",
          total: 0,
          borderColor: "success",
          colorIcon: "text-success",
          type: 1,
        },
        {
          title: "No Sales",
          icon: "XCircleIcon",
          total: 0,
          borderColor: "danger",
          colorIcon: "text-danger",
          type: 2,
        },
      ],
      showSalesDetailModal: false,
    };
  },
  computed: {},
  created() {
    this.getCountSales();
  },
  mounted() {
    this.toggleModal("sales-modal");
  },
  methods: {
    async getCountSales() {
      try {
        this.addPreloader();
        const params = {
          date_from: this.dateFrom,
          date_to: this.dateTo,
          method: "count_sales",
          year: this.sendYear,
          month: this.sendMonth,
          range: this.sendRange,
          meta_ad_id: this.metaAdId,
        };
        const { data } = await ComercialReportService.showComercialReport(
          params,
          this.flyerId
        );

        this.sales[0].total = data?.[0]?.sales ?? 0;
        this.sales[1].total = data?.[0]?.no_sales ?? 0;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    validateSale(sale) {
      return sale.total > 0;
    },
    openModalDetail(type) {
      this.showSalesDetailModal = true;
      this.type = type;
    },
  },
};
</script>
<style lang="scss">
.card-sales-cr {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .card-sale-item {
    width: 100%;
    .card-sale-item__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.3rem;
      padding: 1.5rem 1rem;
      .card-sale-item__header-title {
        text-transform: uppercase;
      }
    }
    .card-sale-item__body {
      padding: 0.5rem 1rem;
    }
  }
}
</style>
    