<template>
  <div>
    <div v-if="typeData == 'meetings'">
      <div
        class="container-meeting w-100"
        :class="isDarkSkin ? 'container-meeting--dark' : ''"
        v-if="dataList.length > 0"
      >
        <div
          v-for="(item, i) in dataList"
          :key="i"
          class="meetings d-flex justify-content-between"
          :class="isDarkSkin ? 'meetings--dark' : ''"
        >
          <div class="d-flex">
            <div
              class="container_icons"
              :class="isDarkSkin ? 'container_icons--dark' : ''"
            >
              <feather-icon
                icon="CalendarIcon"
                size="21"
                style="color: #0d30ec"
                :style="isDarkSkin ? 'color: #EDF0FF' : ''"
              />
            </div>

            <div
              class="container_fecha"
              :class="isDarkSkin ? 'container_fecha--dark' : ''"
            >

              <small
                :class="
                  ![1, 2].includes(item.status_id)
                    ? 'text-light'
                    : isDarkSkin
                    ? 'text-white'
                    : 'text-secondary'
                "
                class="d-flex font-weight-bolder"
                style="margin-bottom: 5px"
              >
                Created by {{ item.creator_name }}
              </small>
              <strong
                v-b-tooltip.hover="'View in the grid'"
                class="text-primary pointer"
                style="font-size: 14px"
                :style="
                  item.status_id === 6 ? 'color: orange !important;' : null
                "
                @click="redirectMeeting(item)"
              >
                <feather-icon
                  icon="UserIcon"
                  size="15"
                  style="margin-right: 5px"
                />
                {{ item.name_client }}
                <feather-icon icon="ArrowUpRightIcon" size="15" />
              </strong>
              <div class="date" style="font-size: 11px">
                {{ item.meeting_date | myDate }}
                {{ item.meeting_start | myTime }} -
                {{ item.meeting_end | myTime }}
              </div>
            </div>
          </div>
          <div class="mt-1">
            <feather-icon
              v-b-tooltip.hover.v-success
              v-b-tooltip.hover.bottom
              title="ATTENDED"
              icon="UserCheckIcon"
              class="cursor-pointer text-success mr-2 hover-me"
              size="20"
              @click="AttendMeeting(item)"
            />
            <feather-icon
              v-b-tooltip.hover.v-danger
              v-b-tooltip.hover.bottom
              title="NOT ANSWERED"
              icon="UserXIcon"
              class="cursor-pointer text-danger hover-me"
              size="20"
              @click="notAnswerMeeting(item, 6)"
            />
          </div>
        </div>
      </div>
      <div v-else class="meeting-done">
        <div>
          <img
            :src="imgEventDone"
            alt="img_sales"
            width="1800px"
            height="180px"
          />
        </div>
        <div
          class="text-meeting"
          :class="isDarkSkin ? 'text-meeting--dark' : ''"
        >
          No Meetings Found!
        </div>
      </div>
    </div>
    <div v-else>
      <div
        class="container-task"
        :class="isDarkSkin ? 'container-task--dark' : ''"
        v-if="dataList.length > 0"
      >
        <div
          v-for="(item, i) in dataList"
          :key="i"
          class="task"
          :class="isDarkSkin ? 'task--dark' : ''"
        >
          <div
            class="container_icons"
            :class="isDarkSkin ? 'container_icons--dark' : ''"
          >
            <img v-if="!isDarkSkin" :src="imgTask" alt="img_sales" />
            <img v-else :src="imgTaskDark" alt="img_sales_dark" />
          </div>

          <div
            class="container_fecha pb-1"
            :class="isDarkSkin ? 'container_fecha--dark' : ''"
          >
            <strong
              v-b-tooltip.hover.title="'View in the Grid'"
              style="font-size: 14px"
              class="cursor-pointer"
              :class="item.status_id === 1 ? 'text-primary' : 'text-warning'"
              @click="redirect(item)"
            >
              <feather-icon
                icon="UserIcon"
                size="15"
                class="m-0"
                style="margin-right: 3px"
              />
              {{ item.name_client }}
              <feather-icon
                icon="ArrowUpRightIcon"
                size="17"
                class="m-0 hover-me"
                style="margin-left: 3px"
              />
            </strong>
            <div class="fw-bold" style="font-size: 13px">
              {{ item.title }}
            </div>
            <div class="name-task">
              <div v-if="item.show_short_description == 0">
                {{ item.short_description }}
                <b-button
                  variant="link"
                  class="m-0 p-0"
                  v-if="item.description.length > 30"
                  @click="item.show_short_description = 1"
                  >...show more</b-button
                >
              </div>
              <div v-else>
                {{ item.description }}
                <b-button
                  variant="link"
                  class="m-0 p-0"
                  @click="item.show_short_description = 0"
                  >...show less</b-button
                >
              </div>
            </div>
            <div class="date" :class="isDarkSkin ? 'meetings__hour--dark' : ''">
              <div v-if="item.hour_task != null" style="font-size: 12px">
                {{ item.max_date | myDate }} |
                {{ item.hour_task | myTime }}
              </div>
              <div v-else style="font-size: 12px">
                {{ item.max_date | myDate }}
              </div>
              <div>
                <feather-icon
                  v-b-tooltip.hover.v-success
                  v-b-tooltip.hover.bottom
                  title="COMPLETE"
                  icon="CheckCircleIcon"
                  size="18"
                  class="text-success hover-me cursor-pointer"
                  style="margin-right: 8px"
                  @click="updateTask(item, 2)"
                />
                <feather-icon
                  v-b-tooltip.hover.v-primary
                  v-b-tooltip.hover.bottom
                  title="EDIT"
                  icon="Edit2Icon"
                  size="18"
                  class="text-primary hover-me cursor-pointer"
                  style="margin-right: 8px"
                  @click="updateTask(item, 1)"
                />
                <feather-icon
                  v-b-tooltip.hover.v-danger
                  v-b-tooltip.hover.bottom
                  title="DELETE"
                  icon="XCircleIcon"
                  size="18"
                  class="text-danger hover-me cursor-pointer"
                  style="margin-right: 8px"
                  @click="cancelTask(item.id)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="task-done">
        <div>
          <img
            :src="imgTaskDone"
            alt="img_sales"
            width="180px"
            height="180px"
          />
        </div>
        <div class="text-task" :class="isDarkSkin ? 'text-task--dark' : ''">
          All Tasks Done!
        </div>
      </div>
    </div>

    <modal-calendar-task
      v-if="showModalTask"
      @closeModal="showModalTask = false"
    />
    <modal-list-meetings
      v-if="showModalMeetings"
      @closeModal="showModalMeetings = false"
    />

    <modal-task-component
      v-if="showModalTask2"
      :ticket-customer="paramsModalTask"
      :task-id="taskId"
      @closeModal="closeModalTask"
      @successTask="successTask"
    />

    <!--MODAL ATTENDED-->
    <modal-attended
      v-if="showModalAttended"
      :ticket="paramsticketAttended"
      :status="statusMeeting"
      @closeModal="showModalAttended = false"
      @success="successAttended"
    />
  </div>
</template>
<script>
import ModalListMeetings from "@/views/commons/components/customer-tickets/components/modal/meetings/ListMeetings.vue";
import ModalCalendarTask from "@/views/commons/components/customer-tickets/components/modal/tasks/ModalCalendarTask.vue";
import ModalTaskComponent from "@/views/commons/components/customer-tickets/components/modal/tasks/ModalTask.vue";
import ModalAttended from "@/views/commons/components/customer-tickets/components/modal/meetings/ModalAttended.vue";
import CustomerTicketsServices from "@/views/commons/components/customer-tickets/services/customer-tickets.service";
import { mapGetters, mapActions } from "vuex";
import store from "@/store";
import { getModuleText } from "@/utils/convertModule.js";

export default {
  components: {
    ModalListMeetings,
    ModalCalendarTask,
    ModalTaskComponent,
    ModalAttended,
  },
  props: {
    dataList: {
      type: Array,
      default: [],
    },
    typeData: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  data() {
    return {
      imgActiQvity: require("@/assets/images/icons/dashboard-ceo/activity.svg"),
      imgTask: require("@/assets/images/icons/dashboard-ceo/task.svg"),
      imgTaskDark: require("@/assets/images/icons/dashboard-ceo/task-dark.svg"),
      imgTaskDone: require("@/assets/images/icons/dashboard-ceo/task-done.svg"),
      imgEventDone: require("@/assets/images/icons/dashboard-ceo/event-done.svg"),
      showModalMeetings: false,
      showModalTask: false,
      showModalTask2: false,
      showContent: "Meeting",
      showModalAttended: false,
      statusMeeting: null,
      ticketCustomerParams: {},
      hoursReprogram: null,
      meetingAnswered: false,
      showMeetingsTicket: false,
    };
  },
  // created(){
  //   if(this.typeData.length > 0){
  //     this.typeData.forEach(item => {
  //       item.show_short_description = true;
  //     })
  //   }
  // },
  created() {
    this.convertModule();
  },
  methods: {
    ...mapActions({
      A_TASKS_PENDING_CUSTOMER_TICKETS:
        "NotificationStore/A_TASKS_PENDING_CUSTOMER_TICKETS",
    }),
    AttendMeeting({
      ticket_customer_id,
      name_client,
      code_ticket,
      meeting_start,
      meeting_end,
      meeting_date,
      created_by,
      module_id_client,
      phone,
      account,
    }) {
      this.statusMeeting = 4;
      this.paramsticketAttended = {
        id: ticket_customer_id,
        name_client,
        code: code_ticket,
        meeting: {
          date: meeting_date,
          hour_start: meeting_start,
          hour_end: meeting_end,
          created_by,
        },
        module_id: module_id_client,
        number_phone: phone,
        account,
      };
      this.showModalAttended = true;
      this.closeBodyMeeting(); // in case before action was reprogramm meeting
    },
    // notAnswerMeeting({
    //   ticket_customer_id,
    //   name_client,
    //   code_ticket,
    //   meeting_start,
    //   meeting_end,
    //   meeting_date,
    //   created_by,
    //   module_id_client,
    //   phone,
    //   account,
    // }) {
    //   this.statusMeeting = 5;
    //   this.paramsticketAttended = {
    //     id: ticket_customer_id,
    //     name_client,
    //     code: code_ticket,
    //     meeting: {
    //       date: meeting_date,
    //       hour_start: meeting_start,
    //       hour_end: meeting_end,
    //       created_by,
    //     },
    //     module_id: module_id_client,
    //     number_phone: phone,
    //     account,
    //   };
    //   this.showModalAttended = true;
    //   this.closeBodyMeeting(); // in case before action was reprogramm meeting
    // },
    async notAnswerMeeting(item, newStatus) {
      this.showMeetingsTicket = true;
      this.paramsTicketReprogram = {
        ticket_customer_id: item.ticket_customer_id,
        meeting_date: item.meeting_date,
        meeting_start: item.meeting_start,
        meeting_end: item.meeting_end,
        new_status: newStatus,
        created_by: item.created_by,
        code: item.code_ticket,
        name_client: item.name_client,
        meetingCreator: item.hasOwnProperty("old_meeting")
          ? item.old_meeting.created_by
          : item.created_by,
      };
      this.ticketCustomerParams = {
        module_id: item.module_id_client,
      };
      const result = await this.showConfirmSwal(
        "NOT ANSWERED",
        "Are you sure you want to save the meeting without rescheduling?"
      );
      if (result.isConfirmed) {
        this.saveNotAnswerMeeting();
      }
    },
    validHours() {
      if (!this.hoursReprogram || this.hoursReprogram.length === 1) {
        return true;
      }

      let isValid = true;
      this.hoursReprogram.forEach((item, index) => {
        const beforeHourEnd =
          index > 0 ? this.hoursReprogram[index - 1].range_end : null;

        if (beforeHourEnd && beforeHourEnd !== item.range_start) {
          isValid = false;
        }
      });

      if (!isValid) {
        this.showWarningSwal(
          "VERY IMPORTANT",
          "You cannot select more than one range of hours that are not correlative."
        );
      }

      return isValid;
    },
    async saveNotAnswerMeeting() {
      try {
        // let save = false;

        // no haven't hours to reprogramm
        // if (!this.hoursReprogram) {
        //   const { isConfirmed } = await this.showConfirmSwal('NO DATE SELECTED', 'Are you sure you want to save the meeting without rescheduling?');
        //   if (!isConfirmed) return;
        //   save = true;
        // }

        if (!this.validHours()) return;

        // confirm save
        // if (!save) {
        //   const { isConfirmed } = await this.showConfirmSwal();
        //   if (!isConfirmed) return;
        // }

        this.addPreloader();

        let newMeeting = null;
        if (this.hoursReprogram) {
          newMeeting = this.hoursReprogram.map((item) => ({
            meetingDate: item.meeting_date || null,
            meetingStart: item.range_start || null,
            meetingEnd: item.range_end || null,
            status: 2,
          }));
        }
        let isCorrelative = 0;
        if (this.hoursReprogram && this.hoursReprogram.length > 1) {
          isCorrelative = 1;
        }
        const params = {
          ticketCustomerId: this.paramsTicketReprogram.ticket_customer_id,
          createdBy: this.currentUser.user_id,
          descriptionNote: null,
          status: this.paramsTicketReprogram.new_status,
          isCorrelative,
          ticketCode: this.paramsTicketReprogram.code,
          clientName: this.paramsTicketReprogram.name_client,
          meetingCreator: this.paramsTicketReprogram.meetingCreator,
          newMeeting,
        };

        const { status } =
          await CustomerTicketsServices.updateMeetingTicketCustomer(params);
        this.removePreloader();
        if (status === 200) {
          this.showSuccessSwal();
          this.showModalAttended = false;
          this.showMeetingsTicket = false;
          // await this.getMeetingsToAnswer();
          await store.dispatch(
            "NotificationStore/A_MEETINGS_PENDING_CUSTOMER_TICKETS"
          );
          this.meetingAnswered = true;
        }
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
        console.log("error in saveNotAnswerMeeting: ", error);
      }
    },
    closeBodyMeeting() {
      this.showMeetingsTicket = false;
      this.paramsTicketReprogram = {};
      this.hoursReprogram = null;
    },
    async successAttended() {
      this.showModalAttended = false;
      this.showMeetingsTicket = false;
      this.meetingAnswered = true;
      this.$emit("getMeetings");
    },
    async updateTask({ id, code }, status) {
      // change status to completed
      if (status === 2) {
        try {
          const { isConfirmed } = await this.showConfirmSwal();
          if (!isConfirmed) return;

          this.addPreloader();
          const params = {
            taskId: id,
            maxDate: null,
            title: null,
            description: null,
            createdBy: this.currentUser.user_id,
            status,
            allDay: null,
          };

          const { status: statusResult } =
            await CustomerTicketsServices.updateTicketCustomerTask(params);
          this.removePreloader();

          if (statusResult === 200) {
            this.showSuccessSwal();
            this.showContent = null;
            this.$emit("getTask");
            // this.showContent = 'meeting';
            await this.A_TASKS_PENDING_CUSTOMER_TICKETS({
              user_id: this.currentUser.user_id,
            });
          }
        } catch (error) {
          this.removePreloader();
          this.showErrorSwal();
          console.log("Error update task:", error);
        }
      } else {
        // edit information of task
        this.showModalTask2 = true;
        this.paramsModalTask = {
          id: id,
          code,
        };
        this.taskId = id;
      }
    },
    async cancelTask(taskId) {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;

        this.addPreloader();
        const params = {
          taskId,
          deletedBy: this.currentUser.user_id,
        };
        const { status } =
          await CustomerTicketsServices.deletedTicketCustomerTask(params);
        this.removePreloader();
        if (status === 200) {
          this.showSuccessSwal();
          this.$emit("getTask");
          await this.A_TASKS_PENDING_CUSTOMER_TICKETS({
            user_id: this.currentUser.user_id,
          });
        }
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
        console.log("error in cancelTask:", error);
      }
    },
    async successTask() {
      this.showModalTask2 = false;
      this.paramsModalTask = {};
      this.$emit("getTask");
      await this.A_TASKS_PENDING_CUSTOMER_TICKETS({
        user_id: this.currentUser.user_id,
      });
    },
    closeModalTask() {
      this.showModalTask2 = false;
      this.paramsModalTask = {};
    },
    redirect({
      code: code_ticket,
      created_by_ticket,
      module_id,
      id_status,
      type,
    }) {
      const routeParams = {
        code: code_ticket,
        searchByCode: true,
        module: module_id,
      };
      let routeName = "";
      const typeTicket = type == "payments" ? "payment" : type;
      // Establecer el nombre de la ruta según las condiciones
      if (id_status === 5 || id_status === 6) {
        routeName = `${typeTicket}-ticket-${this.moduleText}-in-progress`;
      } else if (id_status === 4) {
        routeName = `${typeTicket}-ticket-${this.moduleText}-rejected`;
      } else if (id_status === 3) {
        routeName = `${typeTicket}-ticket-${this.moduleText}-in-progress`;
      } else if (id_status === 2 || (created_by === 1 && id_status === 1)) {
        routeName = `${typeTicket}-ticket-${this.moduleText}-new`;
      } else if (id_status === 7) {
        routeName = `${typeTicket}-ticket-${this.moduleText}-not-solved`;
      }
      this.$store.dispatch("TicketCustomerStore/A_SET_CODE", code_ticket);

      // Realizar la navegación utilizando 'replace' en lugar de 'push'
      if (routeName) {
        this.$nextTick(() => {
          this.$router
            .replace({
              name: routeName,
              params: routeParams,
            })
            .catch((error) => {
              // Manejar específicamente la excepción NavigationDuplicated
              if (
                error.name === "NavigationDuplicated" &&
                error.message.includes(
                  "Avoided redundant navigation to current location"
                )
              ) {
                if (
                  routeName ===
                  `${typeTicket}-ticket-${this.moduleText}-in-progress`
                ) {
                  this.$root.$emit("redirect-to-module", routeParams);
                }
                // Puedes omitir la excepción ya que se trata de una navegación redundante
              } else {
                // Lanzar otras excepciones
                throw error;
              }
            });
        });
      }
      // Emitir un evento personalizado con la variable
      this.$emit("closeModal");
    },
    redirectMeeting(item) {
      const routeParams = {
        code: item.code_ticket,
        searchByCode: true,
        module: item.module_id,
      };
      let routeName = "";
      const typeTicket = item.type == "payments" ? "payment" : item.type;
      // Establecer el nombre de la ruta según las condiciones
      if (item.id_status === 5 || item.id_status === 6) {
        routeName = `${typeTicket}-ticket-${this.moduleText}-in-progress`;
      } else if (item.id_status === 4) {
        routeName = `${typeTicket}-ticket-${this.moduleText}-rejected`;
      } else if (item.id_status === 3) {
        routeName = `${typeTicket}-ticket-${this.moduleText}-in-progress`;
      } else if (
        item.id_status === 2 ||
        (item.created_by_ticket === 1 && item.id_status === 1)
      ) {
        routeName = `${typeTicket}-ticket-${this.moduleText}-new`;
      } else if (item.id_status === 7) {
        routeName = `${typeTicket}-ticket-${this.moduleText}-not-solved`;
      }
      console.log(routeName);
      this.$store.dispatch("TicketCustomerStore/A_SET_CODE", item.code_ticket);
      // Realizar la navegación utilizando 'replace' en lugar de 'push'
      if (routeName) {
        this.$nextTick(() => {
          this.$router
            .replace({
              name: routeName,
              params: routeParams,
            })
            .catch((error) => {
              // Manejar específicamente la excepción NavigationDuplicated
              if (
                error.name === "NavigationDuplicated" &&
                error.message.includes(
                  "Avoided redundant navigation to current location"
                )
              ) {
                if (
                  routeName ===
                  `${typeTicket}-ticket-${this.moduleText}-in-progress`
                ) {
                  this.$root.$emit("redirect-to-module", routeParams);
                }
                // Puedes omitir la excepción ya que se trata de una navegación redundante
              } else {
                // Lanzar otras excepciones
                throw error;
              }
            });
        });
      }
      // Emitir un evento personalizado con la variable
      this.$emit("closeModal");
    },
    convertModule() {
      this.moduleText = getModuleText(this.moduleId);
    },
  },
};
</script>
<style lang="scss" scoped>
$color-primary: #5f5f5f;
$color-secondary: #ffffff;
$bg-card-white: #ffffff;
$bg-card-dark: #111113;
$box-shadow-white: #00000026;

.dashboard__card {
  margin-top: 5px;
  box-shadow: 0px 0px 15px 0px $box-shadow-white;
  border-radius: 15px;
  margin-bottom: 27px;
  padding: 18px;
  background-color: $bg-card-white;
  height: 100%;

  &--dark {
    background-color: $bg-card-dark;
    box-shadow: 0px 0px 22.935px 0px rgba(255, 249, 249, 0.1);
  }

  .card-report {
    position: relative;

    .report-header {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;

      &__title {
        display: flex;

        &--icons {
          width: 46.28px;
          height: 46.28px;
          background-color: #a7c5ff;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;

          img {
            width: 26.935px;
            height: 27px;
          }
        }

        &--text {
          color: #959595;
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          padding-left: 14px;
          display: flex;
          align-items: center;
          margin-bottom: 0px;
        }
      }

      .btn-activity {
        margin-left: 15px;
        padding: 4px 8px;
        background-color: transparent;
        border: none;
        font-size: 0.9rem;
        color: #a3a3a7;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        display: flex;
        position: relative;

        .container-badge {
          position: absolute;
          top: -3px;
          right: 0;

          .badge-total {
            width: 16px;
            height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #ff3d60;

            span {
              color: #ffffff;
            }
          }
        }
      }
      .active {
        border-bottom: 3px solid #2360d3;
        color: #2360d3;
      }
    }

    .report-body {
      margin-top: 10px;

      .card-tasks {
        margin-top: 3px;

        .head-task {
          display: flex;
          justify-content: end;
          margin-bottom: 10px;

          &__total {
            background-color: #f7f7f7;
            color: #898989;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            padding: 6px 10px;
            border-radius: 8px;

            span {
              margin-left: 12px;
            }
            &:hover {
              background-color: rgb(231, 227, 237);
            }

            &--dark {
              background-color: #1a1a1a;

              &:hover {
                background-color: #0f0c11;
              }
            }
          }
        }

        &--dark {
          background-color: $bg-card-dark;
        }

        .container-task {
          height: 320px;
          background-color: #edf0f7;
          border-radius: 10px;
          padding: 10px;
          overflow-y: auto;

          &--dark {
            background-color: #0d0d0c;
          }
        }
        .task-done {
          height: 326px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;

          .text-task {
            color: #343232;
            font-family: Montserrat;
            font-size: 18px;
            font-weight: bold;
            margin-top: 12px;

            &--dark {
              color: #d4cece;
            }
          }
        }

        .task {
          padding: 10px;
          margin-bottom: 10px;
          background: rgb(255, 255, 255);
          border-left: 10px solid #4e68f1;
          border-radius: 12px;
          display: flex;

          &--dark {
            background-color: #090b11;
            border-left: 10px solid #3f7afa;
          }

          .container_icons {
            background-color: #edf0ff;
            width: 45px;
            height: 45px;
            padding: 5px 8px;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;

            &--dark {
              background-color: #1654cc;
            }
          }

          .container_fecha {
            min-height: 80px;
            display: flex;
            flex-direction: column;
            margin-left: 20px;
            width: 100%;

            .name-task {
              color: #9095ad;
              font-family: Montserrat;
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
            }
            .date {
              margin-top: 3px;
              color: #404040;
              font-family: Montserrat;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              display: flex;
              justify-content: space-between;
              width: 100%;
            }

            &--dark {
              .name-task {
                color: #595b61;
              }

              .date {
                color: #a8a8a8;
              }
            }
          }

          &__name {
            span {
              margin-left: 5px;
            }
          }

          &__date {
            color: rgb(143, 0, 171);
            &--max-date {
              margin-right: 7px;
            }

            &--hour {
              margin-left: 33px;
            }
            &--dark {
              color: rgb(212, 109, 233);
            }
          }
        }
      }

      .card-meetings {
        margin-top: 3px;

        .head-meeting {
          display: flex;
          justify-content: end;
          margin-bottom: 10px;

          &__total {
            background-color: #f7f7f7;
            color: #898989;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            padding: 6px 10px;
            border-radius: 8px;

            span {
              margin-left: 12px;
            }

            &:hover {
              background-color: rgb(231, 227, 237);
            }

            &--dark {
              background-color: #1a1a1a;

              &:hover {
                background-color: #0f0c11;
              }
            }
          }
        }

        &--dark {
          background-color: $bg-card-dark;
        }

        .container-meeting {
          height: 320px;
          background-color: #edf0f7;
          border-radius: 10px;
          padding: 10px;
          overflow-y: auto;

          &--dark {
            background-color: #0d0d0c;
          }

          .meetings {
            padding: 10px;
            margin-bottom: 10px;
            background: rgb(255, 255, 255);
            border-left: 10px solid #4e68f1;
            border-radius: 12px;
            display: flex;

            &--dark {
              background-color: #090b11;
              border-left: 10px solid #3f7afa;
            }

            .container_icons {
              background-color: #edf0ff;
              width: 45px;
              height: 45px;
              padding: 5px 8px;
              border-radius: 10px;
              display: flex;
              justify-content: center;
              align-items: center;

              &--dark {
                background-color: #1654cc;
              }
            }
            .container_fecha {
              height: 50px;
              display: flex;
              flex-direction: column;
              margin-left: 20px;

              .date {
                color: #9095ad;
                font-family: Montserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
              }
              .hour {
                margin-top: 3px;
                color: #404040;
                font-family: Montserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
              }

              &--dark {
                .date {
                  color: #595b61;
                }

                .hour {
                  color: #a8a8a8;
                }
              }
            }
          }
        }

        .meeting-done {
          height: 326px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;

          .text-meeting {
            color: #343232;
            font-family: Montserrat;
            font-size: 18px;
            font-weight: bold;
            margin-top: 12px;

            &--dark {
              color: #d4cece;
            }
          }
        }
      }
    }
  }
}
</style>
