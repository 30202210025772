export default [
  {
    key: "selected",
    label: "",
    sortable: false,
    visible: true,
  },
  {
    key: "client_name",
    label: "Client Name",
    sortable: false,
    visible: true,
  },
  {
    key: "client_type",
    label: "Client Type",
    sortable: false,
    visible: false,
    thClass: "text-center",
    tdClass: "text-center",
  },
  // {
  //   key: "module",
  //   label: "Module",
  //   sortable: false,
  //   visible: true,
  //   thClass: "text-center",
  //   tdClass: "text-center",
  // },
  // {
  //   key: "modality",
  //   label: "Concept",
  //   sortable: false,
  //   visible: true,
  //   thClass: "text-center",
  //   tdClass: "text-center",
  // },
  {
    key: "transaction_id",
    label: "Transaction Detail",
    sortable: false,
    visible: true,
  },
  // {
  //   key: "method",
  //   label: "Method Payment",
  //   sortable: false,
  //   visible: true,
  //   thClass: "text-center",
  //   tdClass: "text-center",
  // },
  {
    key: "serial_number",
    label: "Operation Detail",
    sortable: false,
    visible: true,
  },
  {
    key: "amount",
    label: "Amount",
    sortable: false,
    visible: true,
  },
  {
    key: "received",
    label: "Received",
    sortable: false,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "evidence",
    label: "Evidence",
    sortable: false,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "deposit_number",
    label: "Deposit ID",
    sortable: false,
    visible: false,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "amount_deposit",
    label: "Deposit Amount",
    sortable: false,
    visible: false,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "date_deposit",
    label: "Deposit Date",
    sortable: false,
    visible: false,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "status_confirmation",
    label: "Status",
    sortable: false,
    visible: false,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "tracking",
    label: "Tracking",
    sortable: false,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "created",
    label: "Created By",
    sortable: false,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "actions",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
];
