<template>
  <b-modal
    v-model="showGenerateAutomaticNoteForm"
    @hidden="$emit('hidden')"
    size="sm"
    title="Generate Call Summary"
    modal-class="modal-primary"
    no-close-on-backdrop
  >
    <ValidationObserver ref="GenerateAutomaticNoteForm">
      <b-form>
        <b-row>
          <b-col cols="12" v-if="call.has_repeat_numbers == false">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-group
                label="Choose note is for ..."
                label-for="contact_type_id"
              >
                <b-form-radio-group
                  class="mt-1"
                  v-model="contact_type_id"
                  :options="contact_type_options"
                  value-field="id"
                  text-field="name"
                  :style="errors[0] ? 'border: 1px solid red !important;' : ''"
                  :disabled="disable_contact_types"
                ></b-form-radio-group>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col cols="12" v-if="call.has_repeat_numbers == true">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-group label="Select lead or clients" label-for="contacts">
                <v-select
                  id="possible_contacts"
                  label="name"
                  :options="possible_contacts"
                  :reduce="(el) => el.id"
                  v-model="possible_contact_ids"
                  placeholder="Select where the summary will be saved ..."
                  class="rounded"
                  :style="errors[0] ? 'border: 1px solid red !important;' : ''"
                  :class="errors[0] ? 'rounded border border-danger' : ''"
                  multiple
                  :closeOnSelect="false"
                >
                  <template #option="{ name, number }">
                    <div class="d-flex justify-content-between w-100">
                      <span>{{ name }} </span>
                      <span>({{ number }})</span>
                    </div>
                  </template>
                </v-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col
            cols="12"
            v-if="call.has_repeat_numbers == false && contact_type_id != null"
          >
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-group
                :label="contact_type_id == 1 ? 'Lead' : 'Client'"
                label-for="contacts"
              >
                <vue-autosuggest
                  ref="autocomplete"
                  id="contact_id"
                  v-model="contact_name"
                  :suggestions="contacts"
                  :get-suggestion-value="getSuggestionValue"
                  :class="errors[0] ? 'rounded border border-danger' : ''"
                  :style="errors[0] ? 'border: 1px solid red !important;' : ''"
                  :input-props="{
                    id: 'autosuggest__input',
                    class: ['form-control', errors[0]],
                    disabled: disable_contacts,
                  }"
                  @input="getContactsByModule"
                  @selected="selectHandler"
                >
                  <template slot="before-input">
                    <div
                      class="lds-dual-ring"
                      v-show="loadingAutosuggest"
                    ></div>
                  </template>
                  <template slot-scope="{ suggestion }">
                    <span class="my-suggestion-item">{{
                      suggestion.item.name
                    }}</span>
                  </template>
                </vue-autosuggest>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col cols="12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-group label="Note Type" label-for="note_type">
                <v-select
                  id="note_type"
                  label="label"
                  :options="note_types"
                  :reduce="(el) => el.id"
                  v-model="note_type_id"
                  placeholder="Select a type of note ..."
                  class="rounded"
                  :style="errors[0] ? 'border: 1px solid red !important;' : ''"
                  :class="errors[0] ? 'rounded border border-danger' : ''"
                ></v-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col cols="12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-group label="Agent" label-for="agent">
                <v-select
                  id="agent"
                  label="user_name"
                  :options="users"
                  :reduce="(el) => el.id"
                  v-model="agent_id"
                  placeholder="Search the user who did the call ..."
                  class="rounded"
                  :style="errors[0] ? 'border: 1px solid red !important;' : ''"
                  :class="errors[0] ? 'rounded border border-danger' : ''"
                ></v-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
      </b-form>
    </ValidationObserver>

    <template #modal-footer="{ cancel }">
      <b-button variant="outline-secondary" @click="cancel">Cancel</b-button>
      <b-button
        class="btn rounded"
        variant="outline-success"
        @click="generateAutomaticNote"
      >
        Create
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import CallNotesService from "@/views/commons/components/ring-central/notes/service/notes.service.js";
import ClientDashboardService from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services";
import { VueAutosuggest } from "vue-autosuggest";

export default {
  components: {
    VueAutosuggest,
  },
  props: {
    call: {
      type: Object,
      required: true,
    },
    contact: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      users: [],
      showGenerateAutomaticNoteForm: true,
      agent_id: "",
      contacts: [],
      id: "",
      contact_type_id: null,
      note_types: [
        { id: 1, label: "CONVERSATION" },
        { id: 3, label: "COMMENTARY" },
      ],
      contact_type_options: [
        { id: 1, name: "LEAD" },
        { id: 2, name: "CLIENT" },
      ],
      note_type_id: "",
      contact_id: "",
      disable_contact_types: false,
      disable_contacts: false,
      possible_contacts: [],
      possible_contact_ids: [],
      loadingAutosuggest: false,
      contact_name: "",
    };
  },
  created() {
    this.addNegotiationOption();
  },
  async mounted() {
    if (this.contact != null) {
      this.setFirstContact();
    }

    if (this.call.has_repeat_numbers == true) {
      this.possible_contacts = this.removeDuplicates(
        this.call.leads_or_clients
      );
    }
    await this.getUserModule();
  },
  methods: {
    getSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
    async getContactsByModule(text) {
      this.loadingAutosuggest = true;

      if (text === "" || text === undefined) {
        this.contacts = [];
        this.loadingAutosuggest = false;
        return;
      }
      this.loadingAutosuggest = false;

      try {
        let params = {
          match: text,
          module_id: this.moduleId ?? this.call.module_id,
          contact_type: this.contact_type_id,
        };
        const { data } = await CallNotesService.getContactsByModule(params);
        this.contacts = [
          {
            data: data,
          },
        ];
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    selectHandler(user) {
      if (user === undefined || user === null) {
        return;
      }
      this.contact_id = user.item.id;
    },
    removeDuplicates(arr) {
      const map = new Map();

      arr.forEach((item) => {
        map.set(item.id, item);
      });

      return Array.from(map.values());
    },
    async generateAutomaticNote() {
      try {
        const valid = await this.$refs["GenerateAutomaticNoteForm"].validate();

        if (!valid) {
          return;
        }
        let module = this.moduleId ?? this.call.module_id;

        let params = {
          user_id: this.agent_id,
          lead_id_or_client_account_id: this.contact_id,
          record_url_root: this.call.record_url_root,
          start_time_date: this.call.start_time_date,
          original_id: this.call.original_id,
          start_time: this.call.start_time,
          type: module == 5 ? this.note_type_id : 1,
          module_id: module,
          contacts: this.possible_contact_ids,
        };
        const { data } = await CallNotesService.generateAutomaticNote(params);
        this.showSuccessSwal(data.message);
        this.$emit("updateMainGrid");
        this.$emit("hidden");
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getUserModule() {
      try {
        this.addPreloader();
        let module = this.moduleId ?? this.call.module_id;
        const data = await ClientDashboardService.userModule(module, {
          roles: "[]",
          type: "1",
        });
        this.users = data.data.filter(
          (v, i, a) => a.findIndex((t) => t.id === v.id) === i
        );
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },
    addNegotiationOption() {
      let module = this.moduleId ?? this.call.module_id;
      if (module == 5) {
        this.note_types.push({ id: 10, label: "NEGOTIATION" });
      }
    },
    setFirstContact() {
      this.contact_id = this.contact.id;
      this.contact_name = this.contact.name;

      this.contacts = [
        {
          data: [
            {
              id: this.contact.id,
              name: this.contact.name,
            },
          ],
        },
      ];

      if (!isNaN(this.contact.id)) {
        this.contact_type_id = 1;
      } else {
        this.contact_type_id = 2;
      }
      this.disable_contact_types = true;
      this.disable_contacts = true;
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}
.pagination button {
  flex-grow: 1;
}
.pagination button:hover {
  cursor: pointer;
}
</style>