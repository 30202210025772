<template>
  <svg
    :width="size"
    :height="size"
    :viewBox="'0 0 ' + size + ' ' + size"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.23516 5.58323C7.5768 5.28751 7.85082 4.92175 8.03863 4.51078C8.22644 4.09981 8.32364 3.65325 8.32363 3.2014C8.32363 2.35233 7.98634 1.53805 7.38596 0.937667C6.78559 0.337289 5.9713 0 5.12223 0C4.27317 0 3.45888 0.337289 2.85851 0.937667C2.25813 1.53805 1.92084 2.35233 1.92084 3.2014C1.92083 3.65325 2.01803 4.09981 2.20584 4.51078C2.39365 4.92175 2.66767 5.28751 3.00931 5.58323C2.11301 5.9891 1.35257 6.64452 0.818905 7.47113C0.285241 8.29774 0.000942398 9.26056 0 10.2445C0 10.4143 0.0674578 10.5771 0.187533 10.6972C0.307609 10.8173 0.470467 10.8847 0.640279 10.8847C0.810092 10.8847 0.97295 10.8173 1.09303 10.6972C1.2131 10.5771 1.28056 10.4143 1.28056 10.2445C1.28056 9.22559 1.68531 8.24845 2.40576 7.52799C3.12621 6.80754 4.10336 6.40279 5.12223 6.40279C6.14111 6.40279 7.11826 6.80754 7.83871 7.52799C8.55916 8.24845 8.96391 9.22559 8.96391 10.2445C8.96391 10.4143 9.03137 10.5771 9.15144 10.6972C9.27152 10.8173 9.43438 10.8847 9.60419 10.8847C9.774 10.8847 9.93686 10.8173 10.0569 10.6972C10.177 10.5771 10.2445 10.4143 10.2445 10.2445C10.2435 9.26056 9.95923 8.29774 9.42556 7.47113C8.8919 6.64452 8.13146 5.9891 7.23516 5.58323ZM5.12223 5.12223C4.74233 5.12223 4.37095 5.00958 4.05507 4.79851C3.73919 4.58745 3.493 4.28746 3.34761 3.93647C3.20223 3.58548 3.16419 3.19927 3.2383 2.82666C3.31242 2.45405 3.49536 2.11179 3.764 1.84316C4.03263 1.57452 4.37489 1.39158 4.7475 1.31747C5.1201 1.24335 5.50632 1.28139 5.85731 1.42677C6.20829 1.57216 6.50829 1.81836 6.71935 2.13424C6.93042 2.45012 7.04307 2.82149 7.04307 3.2014C7.04307 3.71083 6.8407 4.19941 6.48047 4.55963C6.12025 4.91986 5.63167 5.12223 5.12223 5.12223Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    size: {
      type: Number,
      required: false,
      default: 11,
    },
    color: {
      type: String,
      required: false,
      default: "#898989",
    },
  },
};
</script>
<style lang="scss">
</style>