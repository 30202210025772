<template>
  <b-modal
    ref="modalName"
    modal-class="custom-modal-amg"
    title-class="h3 text-white"
    title="IMPORTED TRANSACTIONS"
    size="xmd"
    @hidden="closeModal()"
    hide-footer
    header-class="p-0"
    centered
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <div class="d-flex align-items-center">
          <type-card :type_card="historyInfo.type_card" />
          <span
            >Imported transactions of XXXX-{{
              historyInfo.cardnumber.slice(-4)
            }}
            from {{ fromDate | myGlobal }} to {{ toDate | myGlobal }}</span
          >
        </div>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="closeModal()"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>
    <b-table
      :items="items"
      :fields="fields"
      sticky-header="50vh"
      class="position-relative table-new-customization"
    >
      <template #cell(transaction_date)="{ item }">
        <div>
          {{ item.transaction_date | myGlobal }}
        </div>
      </template>
      <template #cell(amount_credit)="{ item }">
        <div>
          <b-badge variant="light-info" style="font-size: 12px">{{
            item.amount_credit
          }}</b-badge>
        </div>
      </template>
      <template #cell(amount_debit)="{ item }">
        <div>
          <b-badge variant="light-success" style="font-size: 12px">{{
            item.amount_debit | formatMoney
          }}</b-badge>
        </div>
      </template>
    </b-table>
  </b-modal>
</template>
<script>
import Fields from "@/views/commons/components/credit-cards-expenses/data/history.imports.details.fields.js";
import DashboardService from "@/views/ceo/service-dashboard/dashboard.service.js";
import TypeCard from "@/views/commons/card/TypeCard.vue";

export default {
  props: {
    historyInfo: {
      required: true,
    },
  },
  components: {
    TypeCard,
  },
  data() {
    return {
      fields: Fields,
      items: [],
    };
  },
  mounted() {
    this.toggleModal("modalName");
  },
  async created() {
    await this.getHistoryDetails();
  },
  computed: {
    fromDate() {
      return this.historyInfo.date_ranges.split(" - ")[0];
    },
    toDate() {
      return this.historyInfo.date_ranges.split(" - ")[1];
    },
  },
  methods: {
    async getHistoryDetails() {
      const params = {
        id_history: this.historyInfo.id,
      };
      try {
        this.addPreloader();
        const { data } = await DashboardService.getHistoryDetails(params);
        this.items = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
