<template>
  <div>
    <b-modal
      ref="transactions-by-card-modal"
      modal-class="custom-modal-amg"
      title-class="h3 text-white"
      size="xmd"
      header-bg-variant="transparent"
      header-class="p-0"
      @hidden="closeModal()"
      centered
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <div class="d-flex align-items-center">
            <type-card :type_card="expense.type_card" />
            <span>{{ expense.cardholdername.toUpperCase() }}</span>
            <span>| XXXX-{{ expense.cardnumber.slice(-4) }}</span>
          </div>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="closeModal()"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>
      <b-row class="client-info">
        <!-- Client -->
        <b-col cols="6" class="p-0">
          <div
            class="mr-1"
            :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
          >
            <p>Vendor | {{ expense.vendor }}</p>
          </div>
        </b-col>
        <b-col cols="6" class="p-0">
          <div
            class="ml-1"
            :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
          >
            <p>Concept | {{ expense.concept }}</p>
          </div>
        </b-col>
      </b-row>
      <b-row class="client-info">
        <!-- Client -->
        <b-col cols="6" class="p-0">
          <div
            class="mr-1"
            :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
          >
            <p>Amount | $ {{ expense.amount | currency }}</p>
          </div>
        </b-col>
        <b-col cols="6" class="p-0">
          <div
            class="ml-1"
            :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
          >
            <p>Expense Date | {{ expenseDate | myGlobal }}</p>
          </div>
        </b-col>
      </b-row>
      <b-table
        ref="transactions-by-card-table"
        id="transactions-by-card-table"
        sticky-header="70vh"
        small
        show-empty
        no-provider-filtering
        :busy.sync="isBusy"
        responsive="sm"
        :items="myProvider"
        :fields="filteredFields"
        :has-provider="true"
        class="position-relative table-new-customization"
        :class="['text-center']"
      >
        <template #empty>
          <feather-icon
            icon="AlertTriangleIcon"
            size="30"
            class="text-warning font-weight-bolder"
          />
          <p class="text-center my-2 font-weight-bolder text-warning">
            No transactions were found
          </p>
        </template>
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(transaction_date)="{ item }">
          <span>{{ item.transaction_date | myGlobal }}</span>
        </template>
        <template #cell(amount)="{ item }">
          <span>$ {{ item.amount_debit | currency }}</span>
        </template>
        <template #cell(action)="{ item }">
          <b-form-radio
            name="select"
            class="vs-checkbox-con"
            @change="selectedTransaction = item"
            plain
          >
          </b-form-radio>
        </template>
      </b-table>
      <template #modal-footer>
        <div class="d-flex justify-content-center">
          <b-button
            @click="$emit('approve', selectedTransaction)"
            variant="success"
            :disabled="!selectedTransaction"
            >SELECT TRANSACTION</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import DashboardService from "@/views/ceo/service-dashboard/dashboard.service";
import TypeCard from "@/views/commons/card/TypeCard.vue";
export default {
  name: "TransactionsByCardModal",
  props: {
    expense: {
      type: Object,
      required: true,
    },
  },
  components: {
    TypeCard,
  },
  data() {
    return {
      selectedTransaction: null,
      expenseDate: null,
      modalExpense: {
        show: false,
        item: null,
      },
      items: [],
      isBusy: false,
      fields: [
        {
          key: "transaction_date",
          label: "Transaction Date",
          visible: true,
        },
        {
          key: "description",
          label: "Description",
          visible: true,
        },
        {
          key: "amount",
          label: "Amount",
          visible: true,
        },
        {
          key: "action",
          label: "Select",
          visible: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    namePath() {
      return this.$route.name;
    },
    filteredFields() {
      return this.fields.filter((field) => field.visible);
    },
    programId() {
      return this.moduleId == 2 ? 0 : this.$route.matched[1].meta.program;
    },
  },
  watch: {},
  async mounted() {
    this.toggleModal("transactions-by-card-modal");
    this.expenseDate = this.expense.expense_date;
  },
  async created() {},
  methods: {
    refreshTable() {
      this.$refs["transactions-by-card-table"].refresh();
    },
    closeModal() {
      this.$emit("close");
    },
    filterColumn(key, bool) {
      let field = this.fields.find((field) => field.key == `key`);
      field.visible = bool;
    },
    async myProvider() {
      try {
        const params = {
          expense_date: this.expense.expense_date,
          amount: this.expense.amount,
          credit_card_expense_id: this.expense.credit_card_expense_id,
        };
        const data = await DashboardService.getTransactionsByCard(params);
        this.items = data;
        return this.items;
      } catch (e) {
        console.log(e);
        this.showErrorSwal(e);
        return [];
      }
    },
    getFilterByKey(key) {
      const filter = this.filter.find((filter) => filter.key === `key`);
      return filter;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";
</style>
