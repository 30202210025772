<template>
  <b-modal
    title-class="h3 text-white"
    v-model="showModal"
    size="xmd"
    centered
    :title="'Analysis History'"
    hide-footer
    modal-class="modal-primary"
    body-class="p-1"
    @hidden="emitClose"
  >
    <b-table
      :items="analysisHistory"
      :fields="fields"
      class="position-relative table-new-customization"
      empty-text="No matching records found"
      sticky-header="60vh"
      primary-key="id"
      responsive="md"
      show-empty
      no-border-collapse
      no-provider-filtering
    >
      <template #cell(sentiment)="data">
        <b-badge :variant="getBadgeVariant(data.value)" class="text-uppercase">
          {{ data.value }}
        </b-badge>
      </template>
      <template #cell(sentimentDate)="data">
        {{ data.value | myGlobalDay }}
      </template>
      <template #cell(sentimentScore)="data">
        <div class="d-flex align-items-center flex-column">
          <small class="text-success">
            Positive: {{ data.value.positive }}%
          </small>
          <small class="text-danger">
            Negative: {{ data.value.negative }}%
          </small>
          <small class="text-primary">
            Neutral: {{ data.value.neutral }}%
          </small>
          <small class="text-info"> Mixed: {{ data.value.mixed }}% </small>
        </div>
      </template>
      <template #cell(summary)="data">
        <change-sms
          v-if="data.value"
          :sms-data="data.value"
          :text-length="250"
          type-show-more="span"
        />
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import ChangeSms from "@/views/commons/components/clients/components/clientsSms/ChangeSms.vue";
export default {
  components: {
    ChangeSms,
  },
  props: {
    analysisHistory: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showModal: true,
      fields: [
        {
          key: "sentiment",
          label: "Sentiment",
          thClass: "text-center sentiment-width",
          tdClass: "text-center sentiment-width",
        },
        {
          key: "sentimentScore",
          label: "Sentiment Score",
          thClass: "text-center sentiment-score-width",
          tdClass: "text-center sentiment-score-width",
        },
        {
          key: "sentimentDate",
          label: "Date",
          thClass: "text-center sentiment-date-width",
          tdClass: "text-center sentiment-date-width",
        },
        {
          key: "summary",
          label: "Summary",
          thClass: "text-center summary-width",
          tdClass: "text-center summary-width",
        },
      ],
    };
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    getBadgeVariant(sentiment) {
      const badgeVariants = {
        Positive: "light-success",
        Negative: "light-danger",
        Neutral: "light-primary",
        Mixed: "light-info",
      };
      const badgeVariant = badgeVariants[sentiment] ?? "light-secondary";
      return badgeVariant;
    },
  },
};
</script>
<style>
.sentiment-width {
  width: 20% !important;
}

.sentiment-score-width {
  width: 30% !important;
}

.sentiment-date-width {
  width: 15% !important;
}

.summary-width {
  width: 35% !important;
}
</style>
