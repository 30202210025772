<template>
  <div>
    <b-modal
      ref="modal-creative-report"
      header-bg-variant="transparent border-bottom border-bottom-2"
      size="xl"
      header-class="p-0"
      modal-class="modal-primary"
      hide-footer
      @hidden="$emit('hidden')"
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <span>Creative Report Details ({{ validateTitleMonthYear }})</span>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="$emit('hidden')"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>
      <comercial-report-grid
        :send-year="sendYear"
        :send-month="sendMonth"
        :send-range="false"
        :program-id="programId"
        @newTitleModal="newTitleModal($event)"
      />
    </b-modal>
  </div>
</template>
<script>
import ComercialReportGrid from "@/views/commons/components/reports/tabs/comercial-report/components/ComercialReportGrid.vue";
import moment from "moment";
export default {
  name: "CreativeReportModal",
  components: {
    ComercialReportGrid,
  },
  props: {
    sendYear: {
      type: Number,
      required: false,
      default: null,
    },
    sendMonth: {
      type: Number,
      required: false,
      default: null,
    },
    programId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      titleModal: null,
    };
  },
  computed: {
    titleMonthYear() {
      return moment()
        .year(this.sendYear)
        .month(this.sendMonth - 1)
        .format("MMMM YYYY");
    },
    validateTitleMonthYear() {
      return this.titleModal ? this.titleModal : this.titleMonthYear;
    },
  },
  created() {},
  mounted() {
    this.toggleModal("modal-creative-report");
  },
  methods: {
    newTitleModal(value) {
      this.titleModal = value;
    },
  },
};
</script>
<style lang="scss" scoped>
.header-modal {
  position: relative;
  padding: 20px 0 20px 15px;
  background: rgba(22, 84, 204, 1);
  width: 100%;
  font-size: 22px;
  display: flex;

  span {
    font-size: 1.6rem;
    font-weight: bold;
    margin-left: 8px;
    color: #ffffff;
  }

  .container-icons {
    background-color: #ffffff;
    padding: 2.7px 6.37px;
    border-radius: 0.357rem;
    width: 31px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    transition: all 0.23s ease 0.1s;

    &--dark {
      background-color: #17171a;
    }

    &:hover {
      transform: translate(-7px, 5px);
      cursor: pointer;
    }
  }
}
</style>