<template>
  <b-modal
    ref="modal-daily-authorize-form"
    size="md"
    @hidden="$emit('hidden')"
    hide-footer
    title="Register Daily Authorize"
    title-class="h3 text-white font-weight-bolder"
    no-close-on-backdrop
    no-close-on-esc
  >
    <validation-observer ref="loginForm" class="px-2" #default="{ invalid }">
      <b-row class="format-title m-0">
        <div class="format-child-items">
          <span>{{ merchant.merchant }}</span>
        </div>
        <div class="format-child-items">
          <span>Date | {{ merchant.date | myGlobal }}</span>
        </div>
        <div class="format-child-items">
          <span>Authorize amount | $ {{ merchant.authorize }}</span>
        </div>
      </b-row>
      <b-row class="m-0 mt-1 justify-content-center">
        <b-col lg="12" xl="12">
          <b-form @submit.prevent="register">
            <b-row
              class="d-flex justify-content-center align-items-center content-form"
            >
              <b-col cols="12" class="font-weight-bolder">
                <span>DEPOSIT</span>
                <hr />
              </b-col>

              <b-col cols="6">
                <b-form-group>
                  <div class="d-flex justify-content-between text-white">
                    <label>AMOUNT</label>
                  </div>

                  <validation-provider
                    #default="{ errors }"
                    name="income_amount"
                    vid="income_amount"
                    rules="required"
                  >
                    <money
                      v-bind="vMoney"
                      v-model="form.real_amount"
                      class="form-control"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group>
                  <div class="d-flex justify-content-between text-white">
                    <label>DATE</label>
                  </div>

                  <validation-provider
                    #default="{ errors }"
                    name="income_date"
                    vid="income_date"
                    rules="required"
                  >
                    <b-form-datepicker
                      v-model="form.income_date"
                      :max="new Date()"
                    ></b-form-datepicker>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="justify-content-end mt-2">
              <div>
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
                  name="btn-submit"
                  @click="register"
                >
                  Register
                </b-button>
              </div>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>
<script>
import moment from "moment";
import VMoney from "v-money";

export default {
  name: "DailyAuthorizeFormModal",
  directives: { money: VMoney },
  components: { VMoney },
  props: {
    merchant: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      vMoney: {
        decimal: ",",
        thousand: ".",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        min: 0.0,
        masked: false,
      },
      form: {
        merchant_id: null,
        real_amount: 0,
        income_date: null,
        authorize_amount: 0,
        date: null,
      },
    };
  },
  mounted() {
    this.toggleModal("modal-daily-authorize-form");
    this.form.real_amount = this.merchant.authorize;
    this.form.date = moment(this.merchant.date).format("YYYY-MM-DD");
    this.form.merchant_id = this.merchant.merchant_id;
    this.form.income_date = this.merchant.show_date;
  },
  methods: {
    async register() {
      const { isConfirmed } = await this.showConfirmSwal();
      if (!isConfirmed) return;
      this.$emit("register", this.form);
    },
  },
};
</script>
<style lang="scss" >
.disabled-date-picker {
  border-color: rgba(118, 118, 118, 0.3) !important;
  color: red !important;
  .text-break {
    color: #6e6b7b !important;
  }
}
</style>

<style lang="scss" scoped>
.format-title {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  background-color: #e5e5e5;
  border-radius: 5px;
  .format-child-items {
    min-width: 270px;
    text-align: center;
    padding: 10px;
    color: rgba(35, 42, 70, 1);
    font-weight: bold;
    border-radius: 5px;
    border: 1px solid rgba(35, 42, 70, 1);
  }
}

.content-form {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgba(155, 155, 155, 1);
}

.dark-layout {
  .format-title {
    border: 1px solid rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.1);
    .format-child-items {
      color: rgba(255, 255, 255, 0.8);
      border: 1px solid rgba(255, 255, 255, 0.3);
    }
  }
}
</style>
