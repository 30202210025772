import { amgApi } from "@/service/axios";
class CallImpressionService {
    async getCallImpressions(params) {
        try {
            const data = await amgApi.post("commons/call-impression/index", params);
            return data;
        } catch (error) {
            console.log("Error getCallImpressions");
        }
    }
}
export default new CallImpressionService();