<template>
  <div>
    <b-modal
      ref="myModal"
      v-model="ownControl"
      modal-class="modal-primary"
      modal
      size="xlg"
      scrollable
      :hide-footer="true"
      header-class="p-0"
      header-bg-variant="transparent border-bottom border-bottom-2"
      @hidden="$emit('close')"
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <div class="ml-1" style="text-transform: uppercase">
            PENDING OFFERS
          </div>

          <b-button
            size="sm"
            :variant="isDarkSkin ? 'dark' : 'light'"
            class="btn_close px-0"
            style="width: 35px"
            @click="close()"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-white' : 'text-black'"
            />
          </b-button>
        </div>
      </template>
      <div>
        <b-row>
          <b-col cols="12">
            <filter-slot
              ref="filterslotModal"
              :filter="[]"
              class="filter-slot-new-customization"
              :no-visible-principal-filter="false"
              :filter-principal="filterPrincipal"
              :total-rows="totalRows"
              :paginate="paginate"
              :start-page="startPage"
              :to-page="toPage"
              :top-paginate="false"
              @reload="$refs.ceDsListTable.refresh()"
            >
              <template #table>
                <b-table
                  ref="ceDsListTable"
                  primary-key="id"
                  class="position-relative table-new-customization"
                  :items="myProvider"
                  :fields="fields"
                  table-class="text-nowrap detail-ds"
                  responsive="sm"
                  show-empty
                  sticky-header="50vh"
                  :busy.sync="isBusy"
                  :per-page="paginate.perPage"
                  :current-page="paginate.currentPage"
                  small
                  :tbody-tr-class="rowClass"
                >
                  <template #table-busy>
                    <div class="text-center text-primary my-2">
                      <b-spinner class="align-middle mr-1" />
                      <strong>Loading ...</strong>
                    </div>
                  </template>

                  <template #cell(full_name_client)="data">
                    <div>
                      <router-link
                        class
                        target="_blank"
                        :to="routers(4, data.item.client_account_id)"
                      >
                        {{ data.item.full_name_client }} <br />
                        {{ data.item.client_account }}
                      </router-link>
                    </div>
                  </template>
                  <template #cell(creditor_name)="data">
                    <span>
                      <strong class="cursor-pointer" :class="[textLink]">{{
                        data.item.creditor
                      }}</strong>
                      <span
                        v-if="data.item.parents != data.item.creditor_name"
                        class="mb-1"
                      >
                        <br />
                        ({{ data.item.parents }})
                      </span>
                      <br />
                      <span>#{{ data.item.creditor_account }}</span>
                    </span>
                  </template>
                  <template #cell(orden_aproved_at)="data">
                    <b-row>
                      <b-col>{{ data.item.order_payment_created_by }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col>{{ data.value | myGlobalWithHour }}</b-col>
                    </b-row>
                  </template>
                  <template #cell(aproved_at)="data">
                    <div>
                      <b-row>
                        <b-col>{{ data.item.finished_by }}</b-col>
                      </b-row>
                      <b-row>
                        <b-col>{{
                          data.item.finished_at | myGlobalWithHour
                        }}</b-col>
                      </b-row>
                    </div>
                  </template>

                  <template #cell(collection)="data">
                    <span>
                      <strong>{{ data.item.collection }}</strong>
                      <br />
                      <span>#{{ data.item.collection_account }}</span>
                    </span>
                  </template>

                  <template #cell(offer_amount)="data">
                    <div>
                      {{ data.item.offer_amount | formatMoney }}
                    </div>
                  </template>

                  <template #cell(settlement_fee)="data">
                    <div>
                      {{ data.item.settlement_fee | formatMoney }}
                    </div>
                  </template>

                  <template #cell(settlement_total_cost)="data">
                    <div>
                      {{ data.item.settlement_total_cost | formatMoney }}
                    </div>
                  </template>

                  <template #cell(balance)="data">
                    <span> $ {{ data.value | currencyZero }}</span>
                  </template>

                  <template #cell(saving)="data">
                    $ <span>{{ data.value | currencyZero }}</span>
                  </template>
                  <template #cell(profit)="data">
                    $ <span>{{ data.value | currencyZero }}</span>
                  </template>
                  <template #cell(payment_date)="data">
                    <span>{{ data.value | myGlobal }}</span>
                  </template>

                  <template #cell(settlement_cost)="data">
                    <div v-if="data.item.settlement_cost">
                      {{ data.item.settlement_cost | formatMoney }}
                    </div>
                  </template>

                  <template #cell(finished_at)="data">
                    <div>
                      {{ data.item.finished_at | myGlobalDay }}
                    </div>
                  </template>

                  <template #cell(send_to)="{ item }">
                    <div v-if="item.send_to">
                      {{ JSON.parse(item.send_to).user_name }} <br />
                      {{ JSON.parse(item.send_to).created_at | myGlobal }}
                    </div>
                  </template>

                  <template #cell(file)="data">
                    <div>
                      <b-link
                        class="ml-25"
                        :href="data.item.pdf"
                        target="_blank"
                      >
                        <img :src="pdfIco" style="width: 20px" />
                      </b-link>
                    </div>
                  </template>

                  <template #cell(created_at)="data">
                    <div>
                      {{ data.item.created_at | myGlobal }}
                    </div>
                  </template>
                  <template #cell(result)="data">
                    <b-badge
                      class="w-100"
                      :variant="colorizeResult(data.value)"
                      pill
                      >{{ data.value }}
                    </b-badge>
                  </template>
                  <template #cell(process)="data">
                    <b-badge
                      class="w-100"
                      :variant="colorizeProcess(data.value)"
                      pill
                      >{{ data.value }}</b-badge
                    >
                  </template>
                  <template #cell(status)="data">
                    <feather-icon
                      v-if="data.item.status === 'PENDING'"
                      v-b-tooltip.hover.left="'PENDING'"
                      icon="ClockIcon"
                      size="20"
                      class="cursor-pointer text-info"
                    />
                    <feather-icon
                      v-if="data.item.status === 'REJECTED'"
                      v-b-tooltip.hover.left="'REJECTED'"
                      icon="XCircleIcon"
                      size="20"
                      class="cursor-pointer text-danger"
                    />
                    <feather-icon
                      v-if="data.item.status === 'APPROVED'"
                      v-b-tooltip.hover.left="'APPROVED'"
                      icon="CheckCircleIcon"
                      size="20"
                      class="cursor-pointer text-success"
                    />
                  </template>
                  <template #cell(show)="data">
                    <feather-icon
                      size="22"
                      icon="EyeIcon"
                      class="text-warning cursor-pointer"
                      @click="openOfferModal(data.item)"
                      v-b-tooltip.hover.left="'SEE OFFER SUMMARY'"
                    />
                  </template>
                </b-table>
              </template>
            </filter-slot>
          </b-col>
        </b-row>
      </div>
      <template #modal-footer>
        <b-row class="d-flex justify-content-end">
          <b-button variant="outline-success">
            SAVE
            <feather-icon icon="SaveIcon" />
          </b-button>
        </b-row>
      </template>
    </b-modal>
    <settlement-offer-modal
      v-if="showSettlementOffer"
      :offer-info="offerModalInfo"
      :eventId="eventId"
      @closing="showSettlementOffer = false"
      @reload="
        reloadTable();
        showSettlementOffer = false;
      "
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service.js";
import offerFields from "@/views/ceo/dashboard/data/fields-ds-offers.data.js";
import paidSettlementFields from "@/views/ceo/dashboard/data/fields-ds-paid-amount.data.js";
import SettlementOfferModal from "@/views/debt-solution/views/settlement-offer/SettlementOfferModal.vue";

export default {
  name: "ModalClientStatus",
  components: {
    SettlementOfferModal,
  },
  props: {
    dataClient: {
      type: Object,
      required: false,
      default: null,
    },
    typeModalName: {
      type: String,
      default: null,
      required: false,
    },
    cardChosen: {
      type: Object,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      ownControl: true,
      // eslint-disable-next-line global-require
      pdfIco: require("@/assets/images/icons/pdf.png"),
      fields: [],
      isBusy: false,

      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by client name...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      startPage: null,
      totalRows: 0,
      toPage: null,
      searchInput: "",
      showSettlementOffer: false,
      eventId: null,
      offerModalInfo: {},
    };
  },
  async created() {
    if (this.cardChosen.sendBack === "offers") {
      let sentToIndex = offerFields.findIndex((p) => p.key === "send_to");
      let resultIndex = offerFields.findIndex((p) => p.key === "result");
      let processIndex = offerFields.findIndex((p) => p.key === "process");
      offerFields[sentToIndex].visible =
        this.dataClient?.state == 0 ? true : false;
      offerFields[resultIndex].visible =
        this.dataClient?.state == 0 ? false : true;
      offerFields[processIndex].visible =
        this.dataClient?.state == 0 ? false : true;
      this.fields = offerFields.filter((p) => p.visible);
    } else {
      this.fields = paidSettlementFields;
    }
  },
  computed: {
    ...mapGetters({
      G_TITLE_MODAL: "DashboardCeoStore/G_TITLE_MODAL",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    modalName() {
      return this.typeModalName == "type_ds"
        ? "DS STATISTICS"
        : "CE STATISTICS";
    },
  },
  mounted() {
    // hiden file
    const fileIndex = this.fields.findIndex((p) => p.key === "file");
    if (this.cardChosen.sendBack !== "paid_amount") {
      this.fields[fileIndex] = null;
    }
  },
  methods: {
    rowClass(item) {
      return item?.state == 0 ? "bg-pendings" : "";
    },
    close() {
      this.$refs["myModal"].hide();
      this.$emit("close");
    },
    routers(program, id) {
      let route = "";
      switch (program) {
        case 1:
          route = `/bussiness/clients/account/${id}`;
          break;
        case 2:
          route = `/boostcredit/clients/account/${id}`;
          break;
        case 3:
          route = `/creditexperts/clients/account/${id}`;
          break;
        case 4:
          route = `/debtsolution/clients/account/${id}`;
          break;
        case 5:
          route = `/taxresearch/clients/account/${id}`;
          break;
        case 7:
          route = `/specialists/clients/account/${id}`;
          break;
        case 8:
          route = `/bookeeping/clients/account/${id}`;
          break;
        case 9:
          route = `/ti/clients/account/${id}`;
          break;
      }
      return route;
    },
    async myProvider() {
      const params = {
        year: this.dataClient.year,
        month: this.dataClient.month,
        card: this.cardChosen.sendBack,
        search_text: this.filterPrincipal.model,
        nPage: this.paginate.currentPage,
        nPerPage: this.paginate.perPage,
        state: this.dataClient?.state,
      };
      const data = await DashboardServive.getDsStatisticsModal(params);
      this.isBusy = false;
      this.startPage = data.from;
      this.paginate.currentPage = data.current_page;
      this.paginate.perpage = data.per_page;
      this.totalRows = data.total;
      this.toPage = data.to;
      return data.data || [];
    },
    colorizeResult(result) {
      switch (result) {
        case "ACCEPT":
          return "light-success";
        case "REJECT":
          return "light-danger";
        default:
          return "";
      }
    },
    colorizeProcess(process) {
      switch (process) {
        case "VERIFICATION":
          return "light-warning";
        case "UNDERVIEW":
          return "light-primary";
        case "REQUEST":
          return "light-secondary";
        default:
          return "";
      }
    },
    tooltipIconProcess(status, purchase_order, method_payment_code) {
      switch (true) {
        case status === 1 && method_payment_code == 1: // PENDING DEBIT CONFIRMATION
          return "Pending Debit Confirmation";

        case status === 1 &&
          !purchase_order &&
          [3, 4].includes(method_payment_code): // PENDING DEBIT CONFIRMATION
          return "Purchase Order";

        case status === 1 && !purchase_order && method_payment_code == 2: // PENDING DEBIT CONFIRMATION
          return "In Correspondence";

        case status === 1 &&
          purchase_order &&
          [3, 4].includes(method_payment_code): // PENDING DEBIT CONFIRMATION
          return "In Correspondence";
        // case 1: // PENDING DEBIT CONFIRMATION
        //   return "Pending Debit Confirmation";

        case status === 2: // PENDING PROCESSING DUE TO INSUFFICIENT FUNDS
          return "Pending Processing Due To Insufficient Funds";
        case status === 3:
          return "Negotiation not completed"; // Negotiation not completed
        case status === 4: // Negotiation completed
          return "Negotiation completed";
        case status === 5:
          return "Pending Completion"; // PENDING COMPLETION
        case status === 6:
          return "Completed"; // COMPLETED
        default:
          break;
      }
    },
    statusIconProcess(
      status,
      is_past_due_payment_date,
      purchase_order,
      method_payment_code
    ) {
      switch (true) {
        case status === 2 && is_past_due_payment_date === 1:
          return "ClockIcon";
        case status === 1 && !purchase_order && method_payment_code == 2: // PENDING DEBIT CONFIRMATION
          return "MailIcon";
        case status === 1 &&
          purchase_order &&
          [3, 4].includes(method_payment_code): // PENDING DEBIT CONFIRMATION
          return "MailIcon";
        case status >= 1 && status <= 4:
          return "CashIcon";
        case status === 5:
          return "CheckIcon";
        case status === 6:
          return "ChecksIcon";
        default:
          break;
      }
    },
    checkStatusColor(
      status,
      is_past_due_payment_date,
      purchase_order,
      method_payment_code
    ) {
      switch (true) {
        case status === 1: // PENDING DEBIT CONFIRMATION
          return "#ffd833";
        case status === 2 && is_past_due_payment_date === 1:
          return "#ff0000";

        case status === 1 &&
          purchase_order &&
          [3, 4].includes(method_payment_code):
          return "#ff0000";

        case status === 2: // PENDING PROCESSING DUE TO INSUFFICIENT FUNDS
          return "#ff6207";
        case status === 3: // DEBIT REJECTED
          return "#ff0000";
        case status === 4: // Negotiation completed
          return "#008000";
        case status === 5: // PENDING COMPLETION
          return "#ffd833";
        case status === 6: // COMPLETED
          return "#54b408";
        default:
          break;
      }
    },
    openOfferModal(item) {
      this.offerModalInfo.mode = "watch";
      this.offerModalInfo.client = item.full_name_client;
      this.offerModalInfo.creditorName = item.creditor_name;
      this.offerModalInfo.creditorAccount = item.creditor_account;
      this.offerModalInfo.creditorId = item.creditor_id;
      this.offerModalInfo.balance = item.balance;
      this.offerModalInfo.percentage = item?.percentage || 0;
      this.offerModalInfo.id = item.id;
      this.offerModalInfo.clientAccountId = item.client_account_id;
      // this.offerModalInfo.creditor_id = item.ds_credit_id;
      this.offerModalInfo.account_name = item.client_account;
      this.offerModalInfo.account = item.client_account;
      this.offerModalInfo.id_analysis = item.id_analysis;
      this.offerModalInfo.ssn = item.ssn;
      this.offerModalInfo.itin = item.itin;
      this.offerModalInfo.cpn = item.cpn;
      this.offerModalInfo.dob = item.dob;
      this.offerModalInfo.address = item.address;
      this.offerModalInfo.openByCeo = true;
      this.eventId = item.event_id;
      this.showSettlementOffer = true;
    },
    reloadTable() {
      console.log("reload");
      this.$refs.ceDsListTable.refresh();
      this.$emit("reloadCeo");
    },
  },
};
</script>
<style lang="scss" scoped>
.header-modal {
  width: 100%;
  background: rgba(22, 84, 204, 1);
  height: 80px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  //border-radius: 10px 10px 0 0;
  //padding: 20px 0 20px 15px;
  display: flex;
  align-items: center;
}

.btn_close {
  position: absolute;
  top: 21px;
  right: 9px;
  transition: all 0.23s ease 0.1s;

  &:hover {
    transform: translate(-7px, 5px);
    cursor: pointer;
  }
}

.content_select {
  display: flex !important;
  flex-wrap: nowrap;
  justify-content: flex-end;

  .label_select {
    height: 50px;
    display: flex !important;
    align-items: center;
  }
}
</style>
<style lang="scss">
.table-new-customization table.detail-ds {
  .bg-pendings {
    td {
      background: rgba(234, 249, 255, 1) !important ;
    }
    td:last-child {
      background: rgba(234, 249, 255, 1) !important ;
    }
  }
  .dark-layout & {
    .bg-pendings {
      td {
        background: rgba(58, 114, 234, 0.2) !important;
      }
      td:last-child {
        background: rgba(58, 114, 234, 0.2) !important;
      }
    }
  }
}
</style>
