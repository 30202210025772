import { render, staticRenderFns } from "./DetailClientsNoPayment.vue?vue&type=template&id=17c8dd90&scoped=true"
import script from "./DetailClientsNoPayment.vue?vue&type=script&lang=js"
export * from "./DetailClientsNoPayment.vue?vue&type=script&lang=js"
import style0 from "./DetailClientsNoPayment.vue?vue&type=style&index=0&id=17c8dd90&prod&lang=css"
import style1 from "./DetailClientsNoPayment.vue?vue&type=style&index=1&id=17c8dd90&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17c8dd90",
  null
  
)

export default component.exports