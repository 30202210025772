<template>
  <div id="detailed-other-component">
    <b-modal
      v-model="onControl"
      header-bg-variant="transparent border-bottom border-bottom-2"
      size="xmd"
      header-class="p-0"
      modal-class="modal-primary"
      hide-footer
      scrollable
      @hidden="close()"
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <span>{{ `Others - ${otherTypeName}` }}</span>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="$emit('close')"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>

        </div>
      </template>
      <b-card
        no-body
      >
        <filter-slot
          :filter="filters"
          class="filter-slot-new-customization"
          :filter-principal="filterPrincipal"
          :total-rows="totalRows"
          :paginate="paginate"
          :start-page="startPage"
          :to-page="toPage"
          :top-paginate="false"
          @reload="$refs['refDetailedOther'].refresh()"
        >
          <template #table>
            <b-table
              :id="( isChargeBackType || isVoidRefundType ) ? 'detailed-other-table-red' : 'detailed-other-table-blue'"
              ref="refDetailedOther"
              no-border-collapse
              class="position-relative table-new-customization"
              :fields="visibleFields"
              show-empty
              no-provider-filtering
              sticky-header="50vh"
              primary-key="id"
              responsive="sm"
              :items="myProvider"
              :current-page="paginate.currentPage"
              :per-page="paginate.perPage"
              :busy.sync="isBusy"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2 ">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </template>
              <template #empty>
                <div class="text-center text-primary my-2">
                  <strong>No existing data.</strong>
                </div>
              </template>
              <template #head(amount)="data">
                <div class="text-center">
                  {{ data.label }}
                </div>
              </template>
              <template #head(charge)="data">
                <div class="text-center">
                  {{ data.label }}
                </div>
              </template>
              <template #head(type)="data">
                <div class="text-center">
                  {{ data.label }}
                </div>
              </template>
              <template #head(created_at)="data">
                <div class="text-center">
                  {{ data.label }}
                </div>
              </template>
              <template #head(advisor_name)="data">
                <div class="text-center">
                  {{ data.label }}
                </div>
              </template>
              <template #head(details)="data">
                <div class="text-center">
                  {{ data.label }}
                </div>
              </template>
              <template #cell(client_name)="data">
                <div>
                  <router-link
                    class
                    :to="{
                      name: 'debt-solution-dashboard',
                      params: {
                        idClient: data.item.client_account_id,
                      },
                    }"
                    target="_blank"
                  >{{ data.item.client_name }}
                  </router-link>
                  <status-account :account="data.item" />
                  <span>{{ data.item.account_id }}</span>
                </div>
              </template>
              <template #cell(amount)="data">
                <div
                  class="text-center"
                  :class="( isChargeBackType || isVoidRefundType ) ? 'text-danger' : ''"
                >
                  {{ ( isChargeBackType || isVoidRefundType ) && totalAmount > 0 ? "-" : "" }}$ {{ data.item.amount | currencyZero }}
                </div>
              </template>
              <template #cell(charge)="data">
                <div class="text-center"> 
                  {{ data.item.charge }}
                </div>
              </template>
              <template #cell(type)="data">
                <div class="text-center">
                  <b-badge
                    :variant="[11,17].includes(data.item.type_id) ? 'light-info' : 'light-primary'"
                    class="p-1"
                  >
                    <span class="text-uppercase">{{ data.item.type }}</span>
                  </b-badge> 
                </div>
              </template>
              <template #cell(advisor_name)="data">
                <div class="text-center">
                  {{ data.item.advisor_name }}
                </div>
              </template>
              <template #cell(created_at)="data">
                <div class="align-middle text-center">
                  {{ data.item.created_at | myGlobalWithHour }}
                </div>
              </template>
              <template #cell(details)="row">
                <div class="text-center">
                  <feather-icon
                    :icon="row.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                    class="cursor-pointer"
                    size="23"
                    @click="row.toggleDetails"
                  ></feather-icon>
                </div>
              </template> 
              <template #bottom-row>
                <b-th>
                  <b-badge
                    :style="( isChargeBackType || isVoidRefundType ) ? 'background-color: #eb5648; color: white;' : 'background-color: #0090e7; color: white;'"
                    class="w-100 text-left"
                    style="font-size: 14px; font-weight: 600"
                  >
                    TOTAL
                  </b-badge>
                </b-th>
                <b-th>
                  <b-badge
                    :style="( isChargeBackType || isVoidRefundType ) ? 'background-color: #eb5648; color: white;' : 'background-color: #0090e7; color: white;'"
                    class="w-100 text-center"
                    style="font-size: 14px; font-weight: 600"
                  >
                    {{ ( isChargeBackType || isVoidRefundType ) && totalAmount > 0 ? "-" : "" }}{{ "$ " }}{{ totalAmount | currency }}
                  </b-badge>
                </b-th>
                <b-th
                  class="fill-bottom-row"
                  colspan="3"
                />
              </template>
              <template #row-details="row">
                <b-card class="m-0">
                  <b-row
                    class="text-center text-primary pb-1"
                    style="font-weight: 900"
                  >
                    <b-col cols="6">
                      <span>TRANSACTION</span>
                      <hr />
                    </b-col>
                    <b-col cols="6">
                      <span>MERCHANT</span>
                      <hr />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="6"
                      class="px-3 d-flex flex-column"
                      style="border-right: 1px solid #0085ff"
                    >
                      <b-row>
                        <b-col cols="12" class="d-flex justify-content-between">
                          <span>TRANSACTION ID :</span>
                          <span class="text-primary font-weight-bolder">{{
                            row.item.transaction_id
                          }}</span>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="12" class="d-flex justify-content-between">
                          <span>TRANSACTION AMOUNT :</span>
                          <span class="text-primary font-weight-bolder"
                            >$ {{ row.item.amount | currencyZero }}</span
                          >
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="12" class="d-flex justify-content-between">
                          <span>TRANSACTION DATE :</span>
                          <span class="text-primary font-weight-bolder">{{
                            row.item.transaction_date | myGlobalWithHour
                          }}</span>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="6" class="px-3 d-flex flex-column">
                      <b-row>
                        <b-col cols="12" class="d-flex justify-content-between">
                          <span>MERCHANT NAME :</span>
                          <span class="text-primary font-weight-bolder">{{
                            row.item.merchant_name
                          }}</span>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="12" class="d-flex justify-content-between">
                          <span>AUTH CODE :</span>
                          <span class="text-primary font-weight-bolder">{{
                            row.item.auth_code
                          }}</span>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="12" class="d-flex justify-content-between">
                          <span>REASON CODE :</span>
                          <span class="text-primary font-weight-bolder"
                            >{{ row.item.reason_code }}
                          </span>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-card>
              </template> 
            </b-table>
          </template>
        </filter-slot>
      </b-card>
    </b-modal>
  </div>
</template>

<script>

import DashboardService from '@/views/debt-solution/views/dashboard/service/dashboard.service';
import StatusAccount from '@/views/commons/components/clients/stylescomponents/StatusAccount.vue';
import Filters from '@/views/debt-solution/views/dashboard/components/others/data/filters.data';

export default {
  components: {
    StatusAccount,
  },
  props: {
    defaultType: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      debtSolutionProgram: 4,
      totalAmount: 0,
      onControl: false,
      startPage: 0,
      toPage: 0,
      isBusy: false,
      fields: [
        {
          key: 'client_name',
          sortable: false,
          label: 'Client Name',
          visible: true,
        }, 
        {
          key: 'amount',
          sortable: false,
          label: 'Total Amount',
          visible: true,
        },
        {
          key: 'charge',
          sortable: false,
          label: 'Charge Name',
          visible: false,
        },
        {
          key: 'type',
          sortable: false,
          label: 'Type',
          visible: false,
        },
        {
          key: 'advisor_name',
          sortable: false,
          label: 'Advisor',
          visible: true,
        },
        {
          key: 'created_at',
          sortable: false,
          label: 'CREATED AT',
          visible: true,
        },
        {
          key: 'details',
          sortable: false,
          label: 'Details',
          visible: false,
        },

      ],
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      totalRows: 1,
      paginate: {
        perPage: 100,
        currentPage: 1,
      },
      statusNav: 2,
      filters: Filters,
    };
  },
  computed: {
    isChargeBackType() {
      return this.defaultType === 2;
    },
    isVoidRefundType() {
      return this.defaultType === 3;
    },
    otherTypeName() {
      switch (this.defaultType) {
        case 1:
          return 'Additional Charges';
        case 2:
          return 'Charge Back';
        case 3:
          return 'Void/Refund';
        default:
          return '';
      }
    },
    visibleFields() {
      this.fields.find((x) => x.key === 'charge').visible = this.defaultType === 1 ;
      this.fields.find((x) => x.key === 'type').visible = this.defaultType === 3 ;
      this.fields.find((x) => x.key === 'details').visible = this.defaultType === 2 ;
      return this.fields.filter((x) => x.visible);
    }
  },
  watch: {},
  async created() {
    this.onControl = true;
  },
  methods: {
    close() {
      this.onControl = false;
      this.$emit('close');
    },
    async myProvider() {
      const params = { 
        type: this.defaultType,
        page: this.paginate.currentPage,
        per_page: this.paginate.perPage,
        name_text: this.filterPrincipal.model,
        from : this.filters.find((x) => x.key === 'dateFrom').model,
        to : this.filters.find((x) => x.key === 'dateTo').model,
      };

      const { data } = await DashboardService.getDebtSolutionDetailedOthers(params);
      const items = data.data;
      this.startPage = data.from;
      this.paginate.currentPage = data.current_page;
      this.paginate.perPage = data.per_page;
      this.totalRows = data.total;
      this.toPage = data.to;
      this.totalAmount = data.data[0]?.total_amount;
      return items || [];
    },
  },
};
</script>
  <style lang="scss" scoped>
  .text-money {
      font-weight: 500;

      &::before {
          content: "$";
      }
  }

  .header-modal {
      position: relative;
      padding: 20px 0 20px 15px;
      background: rgba(22, 84, 204, 1);
      width: 100%;
      font-size: 22px;
      display: flex;
      justify-content: space-betweeen;

      span {
          font-size: 1.6rem;
          font-weight: bold;
          margin-left: 8px;
          color: #FFFFFF;
      }

      .container-icons {
          background-color: #FFFFFF;
          padding: 2.7px 6.37px;
          border-radius: 0.357rem;
          width: 31px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 15px;
          right: 20px;
          transition: all 0.23s ease 0.1s;

          &--dark {
              background-color: #17171a;
          }

          &:hover {
              transform: translate(-7px, 5px);
              cursor: pointer;
          }

      }
  }

  .charge__programs {
  width: 47px;
  height: 40px;
  background-color: #E9E9E9;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

    img {
        width: 35px;
        height: 35px;
    }
  }
  </style>
    <style lang="scss">
       #detailed-other-table-blue .b-table-bottom-row {
          position: sticky;
          bottom: 0px;
          z-index: 1;
      }
      #detailed-other-table-blue .b-table-bottom-row th:nth-child(1) {
        border-radius: 0px 0px 0px 15px !important;
        margin-left: 5px !important;
        background: #0090e7 !important;
      }
      #detailed-other-table-blue .b-table-bottom-row th:nth-child(2) {
        border-radius: 0px 0px 15px 0px !important;
        margin-left: 10px !important;
        background: #0090e7 !important;
      }
      #detailed-other-table-blue .fill-bottom-row {
        background-color: #ffffff !important;
      }
      .dark-layout #detailed-other-table-blue {
        .fill-bottom-row {
          background-color: #17171a !important;
          border-top: none !important;
        }
      }
      /* Red bottom footer version */
      #detailed-other-table-red .b-table-bottom-row {
          position: sticky;
          bottom: 0px;
          z-index: 1;
      }
      #detailed-other-table-red .b-table-bottom-row th:nth-child(1) {
        border-radius: 0px 0px 0px 15px !important;
        margin-left: 5px !important;
        background: #eb5648 !important;
      }
      #detailed-other-table-red .b-table-bottom-row th:nth-child(2) {
        border-radius: 0px 0px 15px 0px !important;
        margin-left: 10px !important;
        background: #eb5648 !important;
      }
      #detailed-other-table-red .fill-bottom-row {
        background-color: #ffffff !important;
      }
      .dark-layout #detailed-other-table-red {
        .fill-bottom-row {
          background-color: #17171a !important;
          border-top: none !important;
        }
      }
</style>
