export default [
    {
        key: 'bank',
        label: 'bank',
        class: 'text-center',
        visible: true
    },
    {
        key: 'cardholdername',
        label: 'Card Holder Name',
        class: 'text-center',
        visible: true
    },
    {
        key: 'cardnumber',
        label: 'Card Number',
        class: 'text-center',
        visible: true
    },
    {
        key: 'expiration_date',
        label: 'expiration date',
        class: 'text-center',
        visible: true
    },
    {
        key: 'cardsecuritycode',
        label: 'cvv',
        class: 'text-center',
        visible: true
    },
    {
        key: 'type_card',
        label: 'Type',
        class: 'text-center',
        visible: true
    },
    {
        key: 'module_name',
        label: 'Departments',
        class: 'text-center',
        visible: true,
        thStyle: { width: "30%" }
    },
    {
        key: 'created_at',
        label: 'Created At',
        class: 'text-center',
        visible: true
    },
    {
      key: 'total',
      label: 'imports',
      class: 'text-center',
      visible: true
    },
    {
        key: 'deleted_at',
        label: 'Deleted At',
        class: 'text-center',
        visible: true
    },
    {
        key: 'actions',
        label: 'Actions',
        class: 'text-center',
        visible: true
    }
]
