<template>
  <div>
    <svg
      :width="width"
      :height="height"
      :viewBox="'0 0 ' + width + ' ' + height"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14" cy="15" r="13.25" :stroke="color" stroke-width="1.5" />
      <circle
        cx="23.5"
        cy="5.5"
        r="4.1"
        :fill="color"
        :stroke="stroke"
        stroke-width="1.2"
      />
      <path
        d="M19.44 10.3333C18.7333 9.62481 17.7965 9.19236 16.7989 9.11411C15.8012 9.03587 14.8085 9.31698 14 9.90667C13.1518 9.27576 12.096 8.98968 11.0453 9.10603C9.99455 9.22238 9.02694 9.73252 8.3373 10.5337C7.64765 11.3349 7.2872 12.3677 7.32851 13.424C7.36983 14.4803 7.80986 15.4818 8.55998 16.2267L12.7 20.3733C13.0467 20.7145 13.5136 20.9057 14 20.9057C14.4864 20.9057 14.9533 20.7145 15.3 20.3733L19.44 16.2267C20.2184 15.4435 20.6553 14.3842 20.6553 13.28C20.6553 12.1758 20.2184 11.1165 19.44 10.3333ZM18.5 15.3067L14.36 19.4467C14.3129 19.4942 14.2568 19.532 14.195 19.5578C14.1332 19.5835 14.0669 19.5968 14 19.5968C13.933 19.5968 13.8667 19.5835 13.805 19.5578C13.7432 19.532 13.6871 19.4942 13.64 19.4467L9.49998 15.2867C8.97715 14.7522 8.68439 14.0343 8.68439 13.2867C8.68439 12.539 8.97715 11.8211 9.49998 11.2867C10.0328 10.7607 10.7513 10.4657 11.5 10.4657C12.2487 10.4657 12.9672 10.7607 13.5 11.2867C13.562 11.3492 13.6357 11.3987 13.7169 11.4326C13.7982 11.4664 13.8853 11.4839 13.9733 11.4839C14.0613 11.4839 14.1485 11.4664 14.2297 11.4326C14.3109 11.3987 14.3847 11.3492 14.4466 11.2867C14.9794 10.7607 15.698 10.4657 16.4466 10.4657C17.1953 10.4657 17.9139 10.7607 18.4466 11.2867C18.9767 11.8141 19.279 12.5281 19.289 13.2758C19.299 14.0235 19.0157 14.7453 18.5 15.2867V15.3067Z"
        :fill="color"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      required: false,
      default: 29,
    },
    height: {
      type: Number,
      required: false,
      default: 29,
    },
    color: {
      type: String,
      required: false,
      default: "#0090E7",
    },
    stroke: {
      type: String,
      required: false,
      default: "#CCE9FA",
    },
  },
};
</script>

