export default [
  {
    key: "check_details",
    label: "Check Details",
    class: "text-left",
    visible: true,
    tdClass: "text-left col-4",
  },
  {
    key: "module",
    label: "Department",
    visible: true,
    thClass: "text-center",
  },
  {
    key: "type",
    label: "Type",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "status_check",
    label: "Status Check",
    visible: false,
  },
  {
    key: "amount",
    label: "Amount",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "check_number",
    label: "Check Number",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "module",
    label: "Department",
    visible: true,
    thClass: "text-center",
  },
  // {
  //     key: "payment_date",
  //     label: "Payment Date",
  //     visible: true,
  //     thClass: "text-center",
  //     tdClass: "text-center",
  // },
  {
    key: "requested_by",
    label: "Requested by",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "actions",
    label: "Actions",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "tracking",
    label: "Tracking",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
];
