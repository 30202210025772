<template>
  <b-modal
    ref="myModal"
    v-model="onControl"
    header-bg-variant="transparent border-bottom border-bottom-2"
    size="xmd"
    header-class="p-0"
    title="Payroll Detail"
    modal-class="modal-primary"
    hide-footer
    scrollable
    @hidden="close"
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <img
          v-if="program.icons"
          v-b-tooltip.hover.top="program.name"
          :src="`/assets/${program.icons}`"
          alt="Logo"
          height="30"
          width="30"
        />
        <span>Payroll Details ({{ titleMonthYear }})</span>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="close"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>

    <filter-slot
      :filter="program.department_id ? [] : filter"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :top-paginate="false"
      :input-width="60"
      @reload="$refs['refTableExpenseDetail'].refresh()"
      class="filter-slot-new-customization"
    >
      <template #buttons-filter-end>
        <div style="min-width: 300px" class="ml-1">
          <div class="absolute-linker" :class="{ dark: isDarkSkin }">
            <div
              class="d-flex justify-content-between align-items-center h-full px-1"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="20"
                class="cursor-pointer"
                @click="prev"
              />
              <span class="font-size:15px">
                {{ months[dataMonth - 1] }} {{ dataYear }}
              </span>
              <feather-icon
                icon="ChevronRightIcon"
                size="20"
                class="cursor-pointer"
                @click="next"
              />
            </div>
          </div>
        </div>
      </template>
      <template #table>
        <b-table
          ref="refTableExpenseDetail"
          no-border-collapse
          class="position-relative table-new-customization"
          :fields="visibleFields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="searchDetailExpense"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #head(employee)="data">
            <div class="text-left">
              {{ data.label }}
            </div>
          </template>
          <template #head(employee_code)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(department)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(rol)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(expense)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>

          <template #cell(employee)="data">
            <div
              class="align-middle text-left text-primary font-weight-bolder"
              @click.prevent="employeeInformation(data.item)"
              style="cursor: pointer"
            >
              {{
                data.item.employee ? capitalize(data.item.employee) : "Unknown"
              }}
            </div>
            <div
              v-if="data.item.alias"
              class="align-middle text-left text-muted"
            >
              ({{ capitalize(data.item.alias) }})
            </div>
          </template>
          <template #cell(employee_code)="data">
            <div class="align-middle text-center">
              {{ data.item.employee_code }}
            </div>
          </template>
          <template #cell(department)="data">
            <div class="align-middle text-center">
              {{ data.item.department }}
            </div>
          </template>
          <template #cell(rol)="data">
            <div class="align-middle text-center">
              {{ data.item.rol }}
            </div>
          </template>
          <template #cell(expense)="data">
            <div class="align-middle text-center text-money">
              {{ data.item.expense | currency }} (S/.
              {{ data.item.expense_soles }})
            </div>
          </template>
          <template #bottom-row>
            <b-th class="fill-bottom-row" :colspan="visibleFields.length - 2" />
            <b-th>
              <b-badge
                variant="primary"
                class="w-100 text-center"
                style="font-size: 14px; font-weight: 600"
              >
                TOTAL
              </b-badge>
            </b-th>
            <b-th>
              <b-badge
                variant="primary"
                class="w-100 text-center"
                style="font-size: 14px; font-weight: 600"
              >
                {{ totalExpense | formatCurrency }} (
                {{ totalExpenseSoles | formatCurrencyPEN }})
              </b-badge>
            </b-th>
          </template>
        </b-table>
      </template>
    </filter-slot>
  </b-modal>
</template>

<script>
import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service";
import DepartmentFilter from "@/views/ceo/dashboard/data/department-filter";

export default {
  props: {
    sendYear: {
      type: Number,
      default: null,
    },
    sendMonth: {
      type: Number,
      default: null,
    },
    sendDepartment: {
      type: Array,
      required: false,
      default: [],
    },
    program: {
      type: Object,
      required: false,
      default: null,
    },
    titleMonthYear: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      onControl: false,
      startPage: 0,
      toPage: 0,
      isBusy: false,
      dataYear: null,
      dataMonth: null,
      titleModal: "",
      sortBy: "client_name",
      sortDesc: true,
      filter: DepartmentFilter,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by employee name...",
        model: "",
      },
      fields: [
        {
          key: "employee",
          sortable: false,
          label: "Employee Name",
          visible: true,
        },
        {
          key: "employee_code",
          sortable: false,
          label: "Code",
          visible: true,
        },
        {
          key: "department",
          sortable: false,
          label: "Department",
          visible: true,
        },
        {
          key: "rol",
          sortable: false,
          label: "Role",
          visible: true,
        },
        {
          key: "expense",
          sortable: false,
          label: "Expense",
          visible: true,
        },
      ],
      payrollDetailItems: [],
      totalRows: 1,
      paginate: {
        perPage: 50,
        currentPage: 1,
      },
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      idEmployee: null,
      totalExpenseSoles: 0,
      totalExpense: 0,
    };
  },
  async created() {
    this.onControl = true;
    this.dataMonth = this.sendMonth;
    this.dataYear = this.sendYear;
    this.filter[0].options = this.sendDepartment;
  },

  computed: {
    visibleFields() {
      if (this.program.department_id) {
        this.fields[2].visible = false;
      } else {
        this.fields[2].visible = true;
      }
      return this.fields.filter((field) => field.visible);
    },
  },

  methods: {
    close() {
      this.$refs["myModal"].hide();
      this.$emit("close");
    },

    capitalize(name) {
      return name
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },

    async searchDetailExpense(ctx) {
      const { data } = await DashboardServive.getDetailsExpenses({
        year: this.dataYear,
        type_expense: "payroll-user",
        department_id: this.program.department_id
          ? this.program.department_id
          : this.filter[0].model,
        month: this.dataMonth,
        npage: ctx.currentPage,
        perpage: ctx.perPage,
        username: this.filterPrincipal.model,
      });

      this.payrollDetailItems = data.data;
      this.startPage = data.from;
      this.paginate.currentPage = data.current_page;
      this.paginate.perPage = data.per_page;
      this.totalRows = data.total;
      this.toPage = data.to;
      this.totalExpense = data.data?.[0]?.sum_total;
      this.totalExpenseSoles = data.data?.[0]?.sum_total_soles;
      return this.payrollDetailItems || [];
    },

    refresh() {
      this.$refs["refTableExpenseDetail"].refresh();
    },

    async next() {
      if (this.dataMonth < 12) {
        this.dataMonth++;
      } else {
        this.dataMonth = 1;
        this.dataYear++;
      }
      this.refresh();
    },

    async prev() {
      if (this.dataMonth > 1) {
        this.dataMonth--;
      } else {
        this.dataMonth = 12;
        this.dataYear--;
      }
      this.refresh();
    },

    async employeeInformation(item) {
      let link = "/management/employees/information/" + item.employee_id;
      window.open(link, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
.text-money {
  font-weight: 500;

  &::before {
    content: "$";
  }
}

.header-modal {
  position: relative;
  padding: 20px 0 20px 15px;
  background: rgba(22, 84, 204, 1);
  width: 100%;
  font-size: 22px;
  display: flex;
  justify-content: space-betweeen;

  span {
    font-size: 1.6rem;
    font-weight: bold;
    margin-left: 8px;
    color: #ffffff;
  }

  .container-icons {
    background-color: #ffffff;
    padding: 2.7px 6.37px;
    border-radius: 0.357rem;
    width: 31px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    transition: all 0.23s ease 0.1s;

    &--dark {
      background-color: #17171a;
    }

    &:hover {
      transform: translate(-7px, 5px);
      cursor: pointer;
    }
  }
}

.b-table-bottom-row th:last-child {
  border-radius: 0px 0px 15px 0px !important;
  margin-left: 5px;
  background: #0090e7 !important;
}

.b-table-bottom-row th:nth-last-child(2) {
  border-radius: 0px 0px 0px 15px !important;
  margin-left: -10px;
  background: #0090e7 !important;
}
.fill-bottom-row {
  background-color: #ffffff !important;
}
.dark-layout {
  .fill-bottom-row {
    background-color: #17171a !important;
    border-top: none !important;
  }
}
</style>
