<template>
  <b-card
    class="dashboard__card p-0"
    :class="isDarkSkin ? 'dashboard__card--dark' : ''"
  >
    <b-row>
      <b-col cols="7">
        <div class="d-flex align-items-center">
          <b-avatar rounded="sm" size="4em" class="b_avatar_color">
            <div class="ico_content">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.2954 14.1684C18.0702 14.1684 16.87 13.9758 15.7325 13.5979C15.1879 13.4063 14.5167 13.581 14.1821 13.9284L11.9271 15.6432C9.3396 14.2526 7.6836 12.5863 6.32137 9.99999L7.97842 7.78316C8.39556 7.36316 8.54506 6.74842 8.36628 6.17263C7.98783 5.02 7.79546 3.81053 7.79546 2.57895C7.79546 1.70842 7.09187 1 6.22728 1H2.56818C1.70359 1 1 1.70842 1 2.57895C1 12.7358 9.20787 21 19.2954 21C20.16 21 20.8636 20.2916 20.8636 19.4211V15.7474C20.8636 14.8768 20.16 14.1684 19.2954 14.1684Z"
                  stroke="#3F7AFA"
                  stroke-width="2.2"
                />
              </svg>
            </div>
          </b-avatar>

          <div class="ml-1">
            <h3 class="font-weight-bolder mb-0">Calls Analysis</h3>
          </div>
        </div>
      </b-col>
      <b-col cols="5">
        <slot name="calendar"></slot>
      </b-col>
    </b-row>

    <div class="mt-1 call-analysis-grid w-100 mt-2 diego-scrollbar">
      <div class="thead">
        <div class="tr">
          <div
            v-for="(column, index) in visibleFields"
            :key="index"
            :class="[
              { 'mr-1 ': column.key === 'program_name' },

              {
                'border-right': column.key === 'negative' && !showUnnoted,
              },
              'th',
              column.class,
            ]"
          >
            <div class="d-flex justify-content-center w-100 align-items-center">
              <PositiveIcon
                v-if="column.key === 'positive'"
                size="2.3rem"
                class="mr-1"
                color="white"
              />
              <NeutralIcon
                v-if="column.key === 'neutral'"
                size="2.3rem"
                class="mr-1"
                color="white"
              />
              <MixedIcon
                v-if="column.key === 'mixed'"
                size="2.3rem"
                class="mr-1"
                color="white"
              />
              <NegativeIcon
                v-if="column.key === 'negative'"
                size="2.3rem"
                class="mr-1"
                color="white"
              />

              <div
                class="label"
                :class="
                  column.key === 'program_name' || column.key === 'unnoted'
                    ? 'mt-05'
                    : ''
                "
              >
                {{ column.label }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tbody">
        <template v-if="showCustomPreloader == false">
          <div
            v-for="(row, rowIndex) in callAnalysis"
            :key="rowIndex"
            class="tr my-1"
          >
            <div
              v-for="(column, colIndex) in visibleFields"
              :key="colIndex"
              class="td d-flex align-items-center"
              :class="[
                {
                  'mr-1 justify-content-start border-services':
                    column.key === 'program_name',
                },
              ]"
              :style="column.style"
            >
              <template v-if="column.key === 'program_name'">
                <img :src="`/assets/${row.icons}`" class="icon" />
                <span
                  class="ml-1"
                  style="
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    max-width: 100px;
                  "
                  >{{ row[column.key] }}</span
                >
              </template>
              <template v-else>
                <div
                  @click="
                    column.key === 'negative'
                      ? openCallImpressionModal(row.module_id, 3)
                      : column.key === 'positive'
                      ? openCallImpressionModal(row.module_id, 2)
                      : column.key === 'neutral'
                      ? openCallImpressionModal(row.module_id, 1)
                      : column.key === 'mixed'
                      ? openCallImpressionModal(row.module_id, 4)
                      : column.key === 'unnoted'
                      ? openUnnotedCallsModal(row.module_id)
                      : ''
                  "
                  class="cell-content cursor-pointer d-flex"
                  :class="column.textClass"
                >
                  <div class="underline-text">
                    <span class="text-td quantity">{{ row[column.key] }}</span>
                    <span class="text-td ml-05 percentage"
                      >({{ row[`${column.key}_percentage`] }}%)</span
                    >
                  </div>
                </div>
              </template>
            </div>
          </div>
        </template>
        <template v-else>
          <div
            v-for="(row, rowIndex) in [1, 2, 3, 4, 5]"
            :key="rowIndex"
            class="tr my-1"
          >
            <div
              v-for="(column, colIndex) in fields"
              :key="colIndex"
              class="td d-flex align-items-center"
            >
              <b-skeleton class="w-100"></b-skeleton>
            </div>
          </div>
        </template>
      </div>
    </div>

    <UnnotedCallsModal
      v-if="showUnnotedCallsModal"
      @hidden="showUnnotedCallsModal = false"
      :module-id="moduleSelected"
      :month="selectMonth"
      :year="selectYear"
      @updateMainGrid="getCallAnalysis()"
    />
    <CallImpressionModal
      v-if="showCallImpressionModal"
      @hidden="showCallImpressionModal = false"
      :module-id="moduleSelected"
      :sentimentSelected="sentimentSelected"
      :selectMonth="selectMonth"
      :selectYear="selectYear"
      @updateMainGrid="getCallAnalysis()"
    />
  </b-card>
</template>

<script>
import UnnotedCallsModal from "@/views/commons/components/ring-central/notes/UnnotedCallsModal.vue";
import CallAnalysisService from "@/views/commons/components/ring-central/notes/service/call-analysis.service.js";
import CallImpressionModal from "@/views/ceo/dashboard/CallImpressionModal.vue";
import PositiveIcon from "@/icons/PositiveIcon.vue";
import NeutralIcon from "@/icons/NeutralIcon.vue";
import MixedIcon from "@/icons/MixedIcon.vue";
import NegativeIcon from "@/icons/NegativeIcon.vue";
export default {
  components: {
    UnnotedCallsModal,
    CallImpressionModal,
    PositiveIcon,
    NeutralIcon,
    MixedIcon,
    NegativeIcon,
  },
  props: {
    value: {
      type: Object,
    },
    selectYear: {
      type: [String, Number],
      default: null,
      required: false,
    },
    selectMonth: {
      type: [String, Number],
      default: null,
      required: false,
    },
    moduleId: {
      type: Number,
      default: null,
      required: false,
    },
    showUnnoted: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      callAnalysis: [],
      showCustomPreloader: true,
      moduleSelected: null,
      sentimentSelected: null,
      showUnnotedCallsModal: false,
      showCallImpressionModal: false,
      fields: [
        {
          key: "program_name",
          label: "Service",
          class: "bg-service",
          textClass: "text-service",
          visible: true,
        },
        {
          key: "positive",
          label: "Positive",
          class: "bg-positive",
          textClass: "text-positive",
          style: "border-radius: 10px 0 0 10px",
          visible: true,
        },
        {
          key: "neutral",
          label: "Neutral",
          class: "bg-neutral",
          textClass: "text-neutral",
          visible: true,
        },
        {
          key: "mixed",
          label: "Mixed",
          class: "bg-mixed",
          textClass: "text-mixed",
          visible: true,
        },
        {
          key: "negative",
          label: "Negative",
          class: "bg-negative",
          textClass: "text-negative",
          visible: true,
        },
        {
          key: "unnoted",
          label: "Unnoted Calls",
          class: "bg-unnoted",
          textClass: "text-unnoted",
          style: "border-radius: 0px 10px 10px 0",
          visible: false,
        },
      ],
    };
  },
  computed: {
    visibleFields() {
      // set visible unnoted calls field if showUnnoted is true
      this.fields = this.fields.map((field) => {
        if (field.key === "unnoted") {
          field.visible = this.showUnnoted;
        }
        return field;
      });
      return this.fields.filter((field) => field.visible);
    },
  },
  watch: {
    selectYear(value) {
      this.getCallAnalysis();
    },
    selectMonth(value) {
      this.getCallAnalysis();
    },
  },
  async created() {
    if (this.moduleId != null) {
      // remove field with key program_name
      this.fields = this.fields.filter((field) => field.key !== "program_name");
    }
    await this.getCallAnalysis();
  },
  methods: {
    async getCallAnalysis() {
      try {
        this.addPreloader();
        this.showCustomPreloader = true;
        let params = {
          month: this.selectMonth,
          year: this.selectYear,
          module_id: this.moduleId,
        };
        const { data } = await CallAnalysisService.getCallAnalysis(params);
        this.callAnalysis = data || [];
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
        this.showCustomPreloader = false;
      }
    },
    openUnnotedCallsModal(moduleId) {
      this.moduleSelected = moduleId;
      this.showUnnotedCallsModal = true;
    },
    openCallImpressionModal(moduleId, sentiment) {
      this.moduleSelected = moduleId;
      this.sentimentSelected = sentiment;
      this.showCallImpressionModal = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.b_card_content {
  border-radius: 18px;
  min-height: 100%;

  &--dark {
    background: rgba(17, 17, 19, 1);
    box-shadow: 0px 0px 15px 0px #ffffff26 !important;
  }
  .b_avatar_color {
    background: rgba(167, 197, 255, 1) !important;
  }
}
.dark-layout {
  .call-analysis-grid {
    .text-unnoted {
      color: #fff !important;
    }
    .td {
      // background: #2f2f31 !important;
      background-color: rgb(23, 23, 26) !important;
    }
  }
}

.call-analysis-grid {
  height: 100%;
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  width: 100%;
  padding-right: 10px;

  .label {
    font-size: 1rem;
    font-weight: 800;
    font-family: "Montserrat", sans-serif !important;
    color: #fff;
    text-align: center;
    text-transform: capitalize;
    display: inline-block;
  }

  .underline-text {
    border-bottom: 1px solid;
  }

  .thead,
  .tbody {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .tr {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .th,
  .td {
    flex: 1;
    padding: 8px;
    min-width: 100px;
  }

  .td {
    background: #fafafa !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bg-service {
    background: #3f7afa !important;
    border-radius: 10px 10px 0 0;
  }

  .text-service {
    color: #3f7afa;
  }

  .bg-negative {
    background: #f10000 !important;
  }

  .text-negative {
    color: #f10000;
  }

  .text-positive {
    color: #00d25b;
  }

  .bg-positive {
    background: #00d25b !important;
    border-radius: 10px 0 0 0;
  }

  .text-neutral {
    color: #0090e7;
  }

  .bg-neutral {
    background: #0090e7 !important;
  }

  .text-mixed {
    color: #6e3ccd;
  }

  .bg-mixed {
    background: #6e3ccd !important;
  }

  .text-unnoted {
    color: #111113;
  }

  .bg-unnoted {
    background: #111113 !important;
    border-radius: 0 10px 0 0;
  }

  .cell-content {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .icon {
    // background: #fafafa;
    // border-radius: 5px;
    // padding: 3px;
    height: 40px;
    width: 40px;
  }

  .quantity,
  .percentage {
    font-size: 1.5rem;
    font-weight: 800;
    font-family: "Montserrat", sans-serif !important;
  }

  .border-services {
    border-radius: 10px;
  }

  .border-right {
    border-radius: 0 10px 0 0;
  }

  .percentage {
    font-size: 0.9rem;
  }

  .mt-05 {
    margin-top: 0.4rem;
  }
  .ml-05 {
    margin-left: 0.4rem;
  }
}
</style>
