<template>
  <b-modal
    ref="AmountEditorModal"
    title="UPDATE AMOUNT"
    size="sm"
    centered
    title-class="h4 text-white font-weight-bolder"
    header-class="class_modal_js"
    @ok="handleOk"
    @hidden="handleHidden"
    ok-title="Update"
  >
    <ValidationObserver ref="form">
      <b-row>
        <b-col>
          <ValidationProvider
            rules="required|validate-amount"
            v-slot="{ errors }"
          >
            <b-form-group label="Payment Amount $">
              <money
                v-bind="vMoney"
                v-model="amount"
                class="form-control input-form"
                :class="[
                  errors.length > 0 || vmoneyValidate ? 'border-danger' : null,
                ]"
              ></money>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service";
import VMoney from "v-money";
export default {
  name: "AmountEditorModal",
  props: {
    aTarget: {
      type: Object,
      required: true,
    },
  },
  directives: { money: VMoney },
  data() {
    return {
      vMoney: {
        decimal: ".",
        thousand: ",",
        prefix: "$ ",
        precision: 2,
        min: 0.0,
      },
      vmoneyValidate: false,
      amount: null,
    };
  },
  methods: {
    async handleOk(ev) {
      ev.preventDefault();
      this.vmoneyValidate = false;
      let isValid = await this.$refs["form"].validate();
      if (!isValid) return;
      if (this.amount == this.aTarget.amount) {
        this.vmoneyValidate = true;
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Amount is the same"
        );
        return;
      }
      const confirm = await this.showConfirmSwal(
        `Are you sure you want to update $${this.aTarget.amount} for $${this.amount}?`
      );
      if (!confirm.isConfirmed) return;
      await this.updateVendorConcept();
    },
    handleHidden() {
      this.$emit("closing");
    },
    async updateVendorConcept() {
      try {
        this.addPreloader();
        await DashboardServive.updateAmount({
          id: this.aTarget.id,
          amount: this.amount,
        });
        this.showToast(
          "success",
          "top-right",
          "Success",
          "CheckIcon",
          "Successfully process"
        );
        this.$emit("refresh");
        this.$emit("closing");
        this.amount = null;
        this.removePreloader();
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
  },
  mounted() {
    this.amount = this.aTarget.amount;
    this.toggleModal("AmountEditorModal");
  },
};
</script>
