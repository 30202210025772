<template>
  <b-tabs
    v-model="activeTab"
    active-tab-class="p-0 "
    pills
    nav-class="mb-0 mt-1"
    active-nav-item-class="bg-primary box-shadow-primary border-primary primary"
  >
    <b-tab
      v-for="(title, index) in ['PENDING', 'CONFIRMED', 'ANNULLED']"
      :key="index"
      :title="title"
      :active="index === 0"
      class="mb-1"
      @click="changeTab(index + 1)"
    >
      <template #title>
        {{ title }}
        <span class="ml-2" v-if="totalesExpenses && index == 0">
          <feather-icon
            icon
            :badge="totalPending"
            badge-classes="badge-danger"
        /></span>
      </template>
      <b-card
        no-body
        class="border-top-primary border-3 border-table-radius px-1"
      >
        <b-tabs
          v-model="activeSubTab"
          active-tab-class="p-0 "
          pills
          nav-class="mb-0 mt-1"
          active-nav-item-class="bg-info box-shadow-info border-info info"
        >
          <b-tab
            v-for="(title, index1) in ['AMG', 'CLIENT']"
            :key="index1"
            :title="title"
            :active="index1 === 0"
            class="mb-1"
            @click="changeSubTab(index1 + 1)"
          >
            <template #title>
              {{ title }}
              <span class="ml-2" v-if="totalesExpenses && tab == 1">
                <feather-icon
                  icon
                  :badge="totalesExpenses[title]"
                  badge-classes="badge-danger"
              /></span>
            </template>
            <b-card
              no-body
              class="border-top-info border-3 border-table-radius px-1"
            >
              <check-expenses-request
                :tab="tab"
                :subtab="subtab"
                :statusTab="statusTab"
                :typeExpense="typeExpense"
                v-if="index1 === activeSubTab && index === activeTab"
              />
            </b-card>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-tab>
  </b-tabs>
</template>
<script>
import CheckExpensesRequest from "@/views/ceo/dashboard/modals/expenses-request/CheckExpensesRequest.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ExpensesRequestDetail",
  components: {
    CheckExpensesRequest,
  },
  data() {
    return {
      tab: 1,
      subtab: 1,
      showModal: true,
      activeTab: 0,
      activeSubTab: 0,
    };
  },
  computed: {
    ...mapGetters({
      totalesExpenses: "ExpensesRequest/G_TOTALES_EXPENSES",
      refreshCounter: "ExpensesRequest/G_REFRESH_EXPENSES_COUNTER",
    }),
    typeExpense() {
      switch (this.activeSubTab) {
        case 0:
          return "AMG";
        case 1:
          return "CLIENT";
        default:
          return "NOT FOUND";
      }
    },
    statusTab() {
      let status;
      switch (this.activeTab) {
        case 0:
          status = 2;
          break;
        case 1:
          status = 1;
          break;
        case 2:
          status = 3;
          break;
        default:
          status = 0;
      }
      return status;
    },
    totalPending() {
      let totalAmg = this.totalesExpenses.AMG || 0;
      let totalClient = this.totalesExpenses.CLIENT || 0;
      return totalAmg + totalClient;
    },
  },
  watch: {
    refreshCounter: {
      async handler(value) {
        await this.getTotales({ status: 2, method: "CHECK" });
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      getTotales: "ExpensesRequest/getTotales",
    }),
    changeTab(title) {
      this.activeSubTab = 0;
      this.tab = title;
    },
    changeSubTab(index) {
      this.subtab = index;
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
