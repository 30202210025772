export default [
    {
      type: 'select',
      margin: true,
      showLabel: true,
      label: 'Program',
      model: null,
      options: [],
      reduce: 'id',
      selectText: 'name',
      cols: 12,
      clearable:true
    },
  ]
  