<template>
  <div :class="typeExpense === 'AMG' ? 'type-amg' : 'type-client'">
    <filter-slot
      :filter="[]"
      class="filter-slot-new-customization"
      :filter-principal="filterPrincipal"
      :total-rows="paginate.total"
      :paginate="paginate"
      :start-page="paginate.from"
      :to-page="paginate.to"
      :top-paginate="false"
      :noVisiblePrincipalFilter="true"
      @reload="$refs['detailsExpenses'].refresh()"
    >
      <template #table>
        <b-table
          id="detailsExpenses"
          ref="detailsExpenses"
          no-border-collapse
          class="position-relative table-new-customization"
          :fields="visibleFields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="getCheckExpensesByStatus"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(vendor)="{ item }">
            <span>{{ item.vendor }}</span>
            <feather-icon
              v-if="tab == 1"
              @click="openVendorConceptEditorModal(item, 'vendor')"
              icon="EditIcon"
              v-b-tooltip.hover.top.v-warning="'Edit vendor'"
              class="text-warning ml-1 clickable"
            />
          </template>
          <template #cell(concept)="{ item }">
            <span>{{ item.concept }}</span>
            <feather-icon
              v-if="tab == 1"
              @click="openVendorConceptEditorModal(item, 'concept')"
              icon="EditIcon"
              v-b-tooltip.hover.top.v-warning="'Edit concept'"
              class="text-warning ml-1 clickable"
            />
          </template>
          <template #cell(module)="{ item }">
            <div class="d-flex justify-content-center align-items-center">
              <img
                v-b-tooltip.hover.left="item.module_name"
                :src="`/assets/${item.icons}`"
                alt="Logo"
                height="30"
                width="30"
              />
            </div>
          </template>
          <template #cell(client)="{ item }">
            <b-badge
              variant="light-primary"
              class="total-client"
              @click="openClientExpenses(item)"
            >
              <span>{{ item.totalClient }}</span>
            </b-badge>
          </template>
          <template #cell(account_number)="{ item }">
            {{ item.account_number }}
          </template>
          <template #cell(cardholdername)="{ item }">
            <template v-if="item.method == 'CREDIT_CARD'">
              <div class="row">
                <div class="col-4 p-0 justify-content-end d-flex">
                  <type-card :type_card="item.type_card" />
                </div>
                <div
                  class="col-8 p-0 d-flex justify-content-center align-items-center"
                >
                  <strong>XXXX-{{ item.cardnumber.slice(-4) }}</strong>
                </div>
              </div>
            </template>
            <template v-else>
              <b-badge variant="primary">{{ item.method }}</b-badge>
            </template>
          </template>
          <template #cell(status_check)="{ item }">
            <span :class="colorText(item)">{{ statusCheck(item) }}</span>
          </template>
          <template #cell(amount)="{ item }">
            {{ item.amount | formatMoney }}
          </template>
          <template #cell(type)="{ item }">
            {{ item.type }}
          </template>
          <template #cell(actions)="{ item }">
            <tabler-icon
              v-if="item.status_check == 'PENDING'"
              icon="ThumbUpIcon"
              class="text-success clickable"
              size="22"
              v-b-tooltip.hover.top="'Approve'"
              @click="uploadCheck(item)"
            />
            <tabler-icon
              v-if="item.status_check == 'PENDING'"
              icon="ThumbDownIcon"
              class="text-danger clickable"
              size="22"
              v-b-tooltip.hover.top="'Reject'"
              @click="openObservationsModal(item, 'rejected')"
            />
            <feather-icon
              v-if="
                item.status_check == 'SEND TO COURIER' &&
                item.available_collect == 1
              "
              icon="DollarSignIcon"
              class="text-success clickable"
              size="22"
              v-b-tooltip.hover.top="'Collected'"
              @click="confirmCheck(item)"
            />
            <tabler-icon
              v-if="
                item.status_check == 'SEND TO COURIER' &&
                item.available_collect == 1
              "
              icon="CircleXIcon"
              class="text-danger clickable ml-1"
              size="22"
              v-b-tooltip.hover.top="'Not collected'"
              @click="openObservationsModal(item, 'not collected')"
            />
          </template>
          <template #cell(check_number)="{ item }">
            <div class="d-flex">
              <span>{{ item.check_number }}</span>
              <feather-icon
                v-if="item.validate_check_number == 1"
                icon="CheckIcon"
                class="text-success cursor-pointer"
                style="margin-left: 5px"
                size="18"
                v-b-tooltip.hover.top="'Validate check number'"
                @click="validateCheckNumber(item)"
              />
            </div>
          </template>
          <template #cell(expense_date)="{ item }">
            {{ item.expense_date | myGlobal }}
          </template>

          <template #cell(updated_at)="{ item }">
            <div>
              <span class="d-block">{{ item.updated_by }}</span>
              <span class="d-block">{{ item.updated_at | myGlobalDay }}</span>
              <feather-icon
                v-if="item.observation"
                icon="InfoIcon"
                v-b-tooltip.hover.top="item.observation"
                variant="info"
                class="text-info"
                style="margin-left: 3px"
              />
            </div>
          </template>
          <template #cell(created_at)="{ item }">
            <div>
              <div>{{ item.created_by }}</div>
              <div>{{ item.created_at | myGlobalDay }}</div>
            </div>
          </template>

          <template #cell(collected_at)="{ item }">
            {{ item.collect_date | myGlobal }}
            <a :href="item.route_pdf" target="_blank">
              <amg-icon
                class="cursor-pointer text-danger"
                icon="FilePdfIcon"
                size="18"
              />
            </a>
          </template>

          <template v-if="tab === 2" #bottom-row>
            <b-th class="text-center py-0">
              <div class="foot-totales" :style="{ color: '#FFF' }">
                <p class="m-0" style="font-weight: 600">SUBTOTAL</p>
                <p class="m-0" style="font-weight: 600">TOTAL</p>
              </div>
            </b-th>
            <b-th> </b-th>
            <b-th v-if="isAmg"> </b-th>
            <b-th v-else colspan="2"></b-th>
            <b-th class="text-center py-0">
              <div class="foot-totales" :style="{ color: '#FFF' }">
                {{ totales.subtotal | formatMoney }}
                <br />
                {{ totales.total | formatMoney }}
              </div>
            </b-th>
            <b-th :colspan="isAmg ? 5 : 3" class="fill-bottom-row"> </b-th>
          </template>
        </b-table>
      </template>
    </filter-slot>

    <TransactionsByCardModal
      v-if="showTransactionsByCardModal"
      :expense="selectedItem"
      @close="showTransactionsByCardModal = false"
      @approve="approveExpense"
    />
    <ObservationsModal
      v-if="showObservationsModal"
      @close="showObservationsModal = false"
      @save="annulCheck"
    />
    <client-expense-request
      v-if="clientExpenseModal.show"
      :expenseDepartment="clientExpenseModal.expenseDepartment"
      @close="clientExpenseModal.show = false"
    />
    <VendorConceptEditorModal
      v-if="showVendorConceptEditorModal"
      :vcTarget="vcTarget"
      @closing="showVendorConceptEditorModal = false"
      @refresh="refresh"
    />
    <ModalCreateCustomerTicket
      v-if="showModalCreateCustomerTicket"
      :customer-ticket="customerTicketSelected"
      :selected-expense="selectedExpense"
      :set-motive="103"
      @hidden="showModalCreateCustomerTicket = false"
      @saved="$refs['detailsExpenses'].refresh()"
    />
    <ModalViewTicketCustomerDetails
      v-if="showModalViewTicketCustomerDetails"
      :customer-ticket="customerTicketSelected"
      @hidden="showModalViewTicketCustomerDetails = false"
    />
    <UploadCheck
      v-if="showUploadCheck"
      :checkData="itemSelected"
      @close="showUploadCheck = false"
      @reload="refresh"
    />
    <ConfirmCheck
      v-if="showConfirmCheck"
      :checkData="itemSelected"
      @close="showConfirmCheck = false"
      @reload="refresh"
    />
  </div>
</template>
<script>
import DashboardService from "@/views/ceo/service-dashboard/dashboard.service";
import Fields from "@/views/ceo/dashboard/modals/expenses-request/data/check-expenses-request.fields.js";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import { mapGetters, mapActions } from "vuex";
import { modifyLabel, modifyVisibility } from "@/helpers/fields-table";
import ObservationsModal from "@/views/ceo/dashboard/modals/expenses-request/ObservationsModal.vue";
import ClientExpenseRequest from "@/views/ceo/dashboard/modals/expenses-request/ClientExpenseRequest.vue";
import TypeCard from "@/views/commons/card/TypeCard.vue";
import TransactionsByCardModal from "@/views/ceo/dashboard/modals/expenses-request/TransactionsByCardModal.vue";
import VendorConceptEditorModal from "@/views/ceo/dashboard/modals/expenses-request/VendorConceptEditorModal.vue";
import ModalCreateCustomerTicket from "@/views/commons/components/customer-tickets/components/modal/ModalCreateCustomerTicket.vue";
import ModalViewTicketCustomerDetails from "@/views/commons/components/customer-tickets/components/modal/ModalViewTicketCustomerDetails.vue";
import UploadCheck from "@/views/ceo/dashboard/modals/expenses-request/UploadCheck.vue";
import ConfirmCheck from "@/views/ceo/dashboard/modals/expenses-request/ConfirmCheck.vue";

export default {
  name: "CheckExpensesRequest",
  components: {
    ObservationsModal,
    StatusAccount,
    ClientExpenseRequest,
    TypeCard,
    TransactionsByCardModal,
    VendorConceptEditorModal,
    ModalCreateCustomerTicket,
    ModalViewTicketCustomerDetails,
    UploadCheck,
    ConfirmCheck,
  },
  props: {
    tab: {
      type: Number,
      required: true,
    },
    subtab: {
      type: Number,
      required: true,
    },
    typeExpense: {
      type: String,
      required: true,
    },
    statusTab: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showModalViewTicketCustomerDetails: false,
      expenseId: null,
      customerId: null,
      customerTicketSelected: {},
      showModalCreateCustomerTicket: false,
      vcTarget: null,
      showVendorConceptEditorModal: false,
      expenses: [],
      fields: Fields,
      showObservationsModal: false,
      showTransactionsByCardModal: false,
      selectedItem: null,
      paginate: {
        perPage: 10,
        currentPage: 1,
        total: 0,
        from: 0,
        to: 0,
      },
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      isBusy: false,
      clientExpenseModal: {
        show: false,
        expenseDepartment: null,
      },
      showUploadCheck: false,
      showConfirmCheck: false,
    };
  },
  async created() {},
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    visibleFields() {
      modifyVisibility(this.fields, "actions", this.tab == 1);
      modifyVisibility(this.fields, "client", this.typeExpense === "CLIENT");
      modifyVisibility(this.fields, "status_check", this.tab != 2);
      modifyVisibility(this.fields, "collected_at", this.tab == 2);

      modifyVisibility(this.fields, "updated_at", this.tab != 1);
      modifyLabel(
        this.fields,
        "updated_at",
        this.tab == 2 ? "CONFIRMED AT" : "REJECTED AT"
      );
      return this.fields.filter((item) => item.visible);
    },
    totales() {
      return {
        total:
          this.expenses.length > 0 ? Number(this.expenses[0].totalAmount) : 0,
        subtotal: this.expenses.reduce((a, b) => a + Number(b.amount), 0),
      };
    },
    isAmg() {
      return this.typeExpense === "AMG";
    },
  },
  methods: {
    ...mapActions({
      refreshExpensesCounter: "ExpensesRequest/refreshExpensesCounter",
    }),
    statusCheck(item) {
      switch (item.status_check) {
        case "SEND TO COURIER":
          return "SENT TO COURIER";
        case "SEND TO CORRESPONDENCE":
          return "SENT TO CORRESPONDENCE";
        default:
          return item.status_check;
      }
    },
    colorText(item) {
      switch (item.status_check) {
        case "PENDING":
          return "text-warning";
        case "SEND TO COURIER":
          return "text-primary";
        case "SEND TO CORRESPONDENCE":
          return "text-info";
        case "REJECTED":
          return "text-danger";
        case "COLLECTED":
          return "text-success";
        default:
          return "text-warning";
      }
    },
    async changeStatusCollect(item, status) {
      const confirm = await this.showConfirmSwal(
        `Are you sure the check ${
          status ? " was collected" : " wasn't collected"
        }?`
      );
      if (!confirm.isConfirmed) return false;

      try {
        this.addPreloader();
        const params = {
          check_id: item.check_id,
          user_id: this.currentUser.user_id,
          is_collected: status,
          department_expense_id: item.department_expense_id,
          type: item.type,
        };
        const data = await DashboardService.changeStatusCollect(params);
        if (data.success) {
          this.toastSuccess();
          await this.refresh();
        } else {
          this.toastDanger();
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async changeStatusCheck(item) {
      let message;
      switch (item.status_check) {
        case "GENERATED":
          message = `Are you sure you want to send the check <strong>${item.check_number}</strong> to correspondence?`;
          break;
        case "SEND TO COURIER":
          message = `Are you sure the check <strong>${item.check_number}</strong> has been collected?`;
          break;
        default:
          message = `Are you sure you want to change status check?: ${item.status_check}`;
          break;
      }
      const confirm = await this.showConfirmSwal(message);
      if (!confirm.isConfirmed) return;

      try {
        const data = await DashboardService.changeStatusCheck({
          check_id: item.check_id,
          user_id: this.currentUser.user_id,
          status_check_id: item.status_check_id,
          department_expense_id: item.department_expense_id,
        });
        if (data.success) {
          this.toastSuccess();
          await this.refresh();
        } else {
          this.toastDanger();
        }
      } catch (error) {}
    },
    openVendorConceptEditorModal(item, type) {
      this.showVendorConceptEditorModal = true;
      this.vcTarget = {
        id: type === "vendor" ? item.vendor_id : item.concept_id,
        type: type,
        description: type === "vendor" ? item.vendor : item.concept,
      };
    },
    async refresh() {
      this.$refs["detailsExpenses"].refresh();
      this.refreshExpensesCounter(true);
    },
    async saveObservations(observation) {
      this.selectedItem.observation = observation;
      await this.changeExpenseStatus(this.selectedItem, "REJECTED");
      this.showObservationsModal = false;
    },
    openObservationsModal(item, reason) {
      this.reason = reason;
      this.showObservationsModal = true;
      this.selectedItem = item;
    },
    async getCheckExpensesByStatus() {
      const params = {
        tab: this.tab,
        type_expense: this.typeExpense,
        page: this.paginate.currentPage,
        per_page: this.paginate.perPage,
        search: this.filterPrincipal.model,
        to_review: this.toReview,
      };
      try {
        const { data } = await DashboardService.getCheckExpensesByStatus(
          params
        );
        this.expenses = data.data;
        this.paginate.from = data.from;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.paginate.total = data.total;
        this.paginate.to = data.to;
        return this.expenses;
      } catch (error) {
        console.log(error);
      }
    },
    async approveCheck(item) {
      const detail = `Module: <b>${item.module_name}</b>, Vendor: <b>${item.vendor}</b>, Amount: <b>$${item.amount}</b>`;
      const confirm = await this.showConfirmSwal(
        `Are you sure you want to approve this expense request?: ${detail}`
      );
      if (!confirm.isConfirmed) return false;

      const params = {
        check_id: item.check_id,
        user_id: this.currentUser.user_id,
      };
      try {
        this.addPreloader();
        const { data } = await DashboardService.approveCheck(params);
        if (data.success) {
          this.toastSuccess();
          await this.refresh();
          this.refreshExpensesCounter(true);
        } else {
          this.toastDanger();
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async validateCheckNumber(item) {
      const confirm = await this.showConfirmSwal(
        `The check number <strong>${item.check_number}</strong> is valid?`
      );
      if (!confirm.isConfirmed) return false;

      try {
        this.addPreloader();
        const params = {
          check_id: item.check_id,
        };
        const data = await DashboardService.validateCheckNumber(params);
        if (data.success) {
          this.toastSuccess();
          await this.refresh();
        } else {
          this.toastDanger();
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    uploadCheck(item) {
      this.itemSelected = item;
      this.showUploadCheck = true;
    },
    confirmCheck(item) {
      this.itemSelected = item;
      this.showConfirmCheck = true;
    },
    openClientExpenses(item) {
      this.clientExpenseModal.show = true;
      this.clientExpenseModal.expenseDepartment = item;
    },
    async annulCheck(observation) {
      let message = `Are you sure you want to annul this check?: ${this.selectedItem.check_number}`;
      let message2 = `Are you sure you want to annul this request?`;
      const confirm = await this.showConfirmSwal(
        this.selectedItem.check_number == '-' ? message2 : message
      );
      if (!confirm.isConfirmed) return false;

      try {
        this.addPreloader();
        const params = {
          check_id: this.selectedItem.check_id,
          user_id: this.currentUser.user_id,
          department_expense_id: this.selectedItem.department_expense_id,
          type: this.selectedItem.type,
          reason: this.reason,
          method: this.selectedItem.method,
          observation: observation,
        };
        const data = await DashboardService.annulCheck(params);
        if (data.success) {
          this.toastSuccess();
          await this.refresh();
        } else {
          this.toastDanger();
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
        this.showObservationsModal = false;
      }
    },
    async changeExpenseStatus(item, status) {
      const detail = `Module: <b>${item.module_name}</b>, Vendor: <b>${item.vendor}</b>, Amount: <b>$${item.amount}</b>`;
      const action = status == "APPROVED" ? "confirm" : "reject";
      const confirm = await this.showConfirmSwal(
        `Are you sure you want to ${action} this expense request?: ${detail}`
      );
      if (!confirm.isConfirmed) return false;

      const params = {
        id: item.id,
        status: status,
        user_id: this.currentUser.user_id,
        type: item.type,
        observation: item.observation,
        method: item.method,
        // expense_transaction_id: item.expense_transaction_id,
      };
      try {
        this.addPreloader();
        const { data } = await DashboardService.changeExpenseStatus(params);
        if (data.success) {
          this.toastSuccess();
          await this.refresh();
        } else {
          this.toastDanger;
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    toastSuccess() {
      this.showToast(
        "success",
        "top-right",
        "Success",
        "CheckIcon",
        "Successfully process"
      );
    },
    toastDanger() {
      this.showToast(
        "danger",
        "top-right",
        "Oop!",
        "AlertOctagonIcon",
        "Failed process"
      );
    },
  },
};
</script>

<style lang="scss">
#detailsExpenses .b-table-bottom-row {
  position: sticky;
  bottom: 0px;
  z-index: 1;
}
#detailsExpenses .total-client {
  font-size: 14px;
  padding: 0.8rem;
  span {
    border-bottom: 2px solid #0090e7;
    padding: 0px 5px;
    font-weight: bold !important;
  }
  :hover {
    cursor: pointer;
    span {
      box-shadow: rgba(255, 255, 255, 0.856) 0px 54px 55px,
        rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px,
        rgba(241, 239, 239, 0.09) 0px -3px 5px;
    }
  }
}
#detailsExpenses .b-table-bottom-row th:nth-child(1) {
  border-radius: 0px 0px 0px 15px !important;
  margin-left: 5px !important;
  background: #0090e7 !important;
}
.type-client
  #detailsExpenses
  .b-table-bottom-row
  th:nth-child(n + 2):nth-child(-n + 4),
.type-amg
  #detailsExpenses
  .b-table-bottom-row
  th:nth-child(n + 2):nth-child(-n + 2) {
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #0090e7 !important;
}
.type-client #detailsExpenses .b-table-bottom-row th:nth-child(4),
.type-amg #detailsExpenses .b-table-bottom-row th:nth-child(4) {
  border-radius: 0px 0px 15px 0px !important;
  margin-left: 10px !important;
  background: #0090e7 !important;
}
.type-amg #detailsExpenses .b-table-bottom-row th:nth-child(3) {
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #0090e7 !important;
}
#detailsExpenses .fill-bottom-row {
  background-color: #ffffff !important;
}
.dark-layout #detailsExpenses {
  .fill-bottom-row {
    background-color: #17171a !important;
    border-top: none !important;
  }
}
.foot-totales p {
  padding: 2px 0px;
}
</style>
