<template>
  <div class="card-negotiate">
    <template v-for="(item, index) in negotiate">
      <div :key="index" class="w-100" @click="$emit('open', item)">
        <body-header-card
          :status-name="item.statusName"
          :amount="item.amount"
          :total-clients="item.totalClients"
          :icon-name="item.iconName"
          :bg-color-icon="item.bgColorIcon"
          :show-footer="true"
        />
      </div>
    </template>
  </div>
</template>
<script>
import BodyHeaderCard from "@/views/debt-solution/views/dashboard/components/BodyHeaderCard.vue";
import DashboardService from "@/views/debt-solution/views/dashboard/service/dashboard.service.js";
export default {
  components: {
    BodyHeaderCard,
  },
  data() {
    return {
      negotiate: [
        {
          statusName: "ACTIVE",
          amount: 0,
          iconName: "to-negotiate-active",
          bgColorIcon: "bg-success-light",
          totalClients: 0,
          status: 1,
        },
        {
          statusName: "LOYAL",
          amount: 0,
          iconName: "to-negotiate-loyal",
          bgColorIcon: "bg-primary-light",
          totalClients: 0,
          status: 5,
        },
        {
          statusName: "HOLD",
          amount: 0,
          iconName: "to-negotiate-outstanding",
          bgColorIcon: "bg-warning-light",
          totalClients: 0,
          status: 2,
        },
      ],
    };
  },
  created() {
    this.getNegotiate();
  },
  methods: {
    async getNegotiate() {
      try {
        this.addPreloader();
        const data = await DashboardService.getDebtSolutionBalance();

        this.negotiate = this.negotiate.map((item) => {
          const element = data.find((el) => el.status_id === item.status);
          if (element) {
            return {
              ...item,
              amount: element.general_balance,
              totalClients: element.general_amount_of_clients,
              statusName: element.status_name.toUpperCase(),
            };
          }
          return item;
        });
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
<style lang="scss">
.card-negotiate {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
</style>