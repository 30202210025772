<template>
  <b-modal
    v-model="showCallImpressionModal"
    @hidden="$emit('hidden')"
    header-bg-variant="transparent border-bottom border-bottom-2"
    header-class="p-0"
    modal-class="modal-primary"
    hide-footer
    size="xl"
  >
    <template #modal-header>
      <div class="header-modal">
        <div class="d-flex align-items-center">
          <img height="35px" width="35px" :src="geModuleImageRoute(moduleId)" />
          <span> Sentiment Analysis of Calls </span>
        </div>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="$emit('hidden')"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>

    <b-card
      class="b_card_content dashboard__card"
      :class="isDarkSkin ? 'dashboard__card--dark' : ''"
    >
      <!-- <b-row>
        <b-col cols="5">
          <div class="d-flex align-items-center">
            <b-avatar rounded="sm" size="4em" class="b_avatar_color">
              <div class="ico_content">
                <b-img :src="imgCalls" style="width: 85%; height: 85%" />
              </div>
            </b-avatar>

            <div class="ml-1">
              <h3 class="font-weight-bolder mb-0">
                Sentiment Analysis of Calls
              </h3>
            </div>
          </div>
        </b-col>
        <b-col
          v-if="this.moduleId ? false : true"
          cols="2"
          offset="5"
          class="align-self-center"
        >
          <b-button
            class="text-center w-100"
            variant="outline-primary"
            @click="openDetailsCallImpression()"
          >
            <span>View All</span>
          </b-button>
        </b-col>
      </b-row> -->
      <b-row class="mt-1">
        <b-col v-for="(item, i) in listSentiments" :key="i" cols="2">
          <b-button
            class="text-center w-100 h-full d-flex flex-column align-items-center justify-content-center"
            @click="handleChange(item.value)"
            :style="{
              background:
                selectSentiment == item.value
                  ? getColorSentiment(selectSentiment) + ' !important'
                  : isDarkSkin
                  ? '#0E1932 !important'
                  : '#D8D8D8 !important',
              border: 0,
              'font-weight': '900',
              color:
                selectSentiment == item.value
                  ? isDarkSkin
                    ? '#1A1A1A !important'
                    : '#FFFFFF !important'
                  : item.color + ' !important',
              position: 'relative',
            }"
          >
            <div class="d-flex justify-content-between align-items-center">
              <PositiveIcon
                v-if="item.label === 'POSITIVE'"
                size="2.3rem"
                class="mr-1"
                color="white"
              />
              <NeutralIcon
                v-if="item.label === 'NEUTRAL'"
                size="2.3rem"
                class="mr-1"
                color="white"
              />
              <MixedIcon
                v-if="item.label === 'MIXED'"
                size="2.3rem"
                class="mr-1"
                color="white"
              />
              <NegativeIcon
                v-if="item.label === 'NEGATIVE'"
                size="2.3rem"
                class="mr-1"
                color="white"
              />
              <div class="d-flex flex-column">
                <span>{{ item.label }}</span>

                <span
                  class="mt-1"
                  v-if="getTotalAndPercentage(item.value)[0] > 0"
                  :style="{
                    color: isDarkSkin
                      ? '#FFFFFF !important'
                      : ' #1A1A1A !important',
                  }"
                  >({{ getTotalAndPercentage(item.value)[1] }}) %</span
                >
              </div>
            </div>

            <div
              v-if="getTotalAndPercentage(item.value)[0] > 0"
              class="counter"
            >
              <span class="text-white align-self-center">{{
                getTotalAndPercentage(item.value)[0]
              }}</span>
            </div>
          </b-button>
        </b-col>
        <b-col cols="2" offset="2" v-if="viewDate">
          <div
            class="d-flex justify-content-between align-items-center h-full px-1"
          >
            <feather-icon
              icon="ChevronLeftIcon"
              size="20"
              class="cursor-pointer"
              @click="prev"
            />
            <span class="font-size:15px">
              {{ months[month - 1] }} {{ year }}
            </span>
            <feather-icon
              icon="ChevronRightIcon"
              size="20"
              class="cursor-pointer"
              @click="next"
            />
          </div>
        </b-col>
      </b-row>
      <hr
        :style="{
          borderTop:
            '5px solid ' + getColorSentiment(selectSentiment) + ' !important',
        }"
      />
      <b-row>
        <b-col cols="12">
          <b-table
            ref="refTableCallImpression"
            no-border-collapse
            class="position-relative table-new-customization wo-min-height"
            :fields="fields"
            sticky-header="55vh"
            show-empty
            no-provider-filtering
            primary-key="id"
            responsive="sm"
            :items="callImpressionData"
            :busy.sync="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>

            <template #cell(service_name)="data">
              <div class="d-flex justify-content-center">
                <img
                  :src="getProgramImageRoute(data.item.program_id)"
                  v-b-tooltip.right="data.item.service"
                  style="
                    background: rgb(250, 250, 250);
                    border-radius: 5px;
                    padding: 3px;
                  "
                  height="40"
                  width="40"
                  alt="img_services"
                />
              </div>
            </template>

            <template #cell(client_name)="data">
              <div
                class="d-flex flex-column"
                v-if="data.item.client_account_id"
              >
                <div>
                  <router-link
                    class
                    :to="{
                      name: dashboardRouteName(data.item.module_id),
                      params: {
                        idClient: data.item.client_account_id,
                      },
                    }"
                    target="_blank"
                    >{{ data.item.client_name }}
                  </router-link>
                </div>
                <status-account :account="data.item" />
                <span>{{ data.item.account }}</span>
              </div>
              <div v-else>
                <router-link
                  class="text-warning font-weight-bolder"
                  target="_blank"
                  :to="{
                    name: dashboardLeadRouteName(data.item.module_id),
                    params: { id: data.item.lead_id },
                  }"
                >
                  {{ data.item.client_name }} <br />
                  ID: {{ data.item.lead_id }}
                </router-link>
              </div>
            </template>

            <!-- <template #cell(phone_client)="data">
              <feather-icon
                icon="PhoneIcon"
                class="text-success"
                v-b-tooltip.top="data.item.phone_client"
                size="15"
              />
            </template>

            <template #cell(service_line)="data">
              <feather-icon
                icon="PhoneIcon"
                class="text-success"
                v-b-tooltip.top="data.item.service_line"
                size="15"
              />
            </template> -->

            <template #cell(date)="data">
              <div class="d-flex flex-column">
                <span>{{ data.item.created_at | myGlobalDay }}</span>
                <span>{{ data.item.agent }}</span>
              </div>
            </template>

            <template #cell(sentiment)="data">
              <div class="w-100 d-flex justify-content-around">
                <div
                  class="d-flex"
                  :style="{
                    background:
                      getColorSentiment(data.item.sentiment_id) + ' !important',
                    padding: '6px',
                    borderRadius: '15px',
                  }"
                >
                  <div
                    :style="
                      isDarkSkin
                        ? 'color :#1A1A1A !important'
                        : 'color: #FFFFFF !important'
                    "
                  >
                    <span
                      style="font-weight: 900; text-transform: capitalize"
                      >{{ data.item.result }}</span
                    >
                  </div>
                  <div
                    :style="
                      isDarkSkin
                        ? 'color :#1A1A1A !important'
                        : 'color: #FFFFFF !important'
                    "
                  >
                    <feather-icon
                      :id="`details_sentiment` + data.index"
                      v-if="data.item.result"
                      icon="InfoIcon"
                      size="15"
                      class="cursor-pointer"
                      style="margin-left: 5px; margin-bottom: 2px"
                    />
                  </div>
                </div>
              </div>
              <div>
                <b-tooltip
                  :target="`details_sentiment` + data.index"
                  triggers="hover"
                  placement="top"
                >
                  <div class="w-100 d-flex justify-content-start">
                    <div class="d-flex flex-column">
                      <span class="text-primary text-weight-bolder"
                        >· Neutral : {{ data.item.sentiment_neutral }} %
                      </span>
                      <span class="text-success text-weight-bolder"
                        >· Positive : {{ data.item.sentiment_positive }} %
                      </span>
                      <span class="text-danger text-weight-bolder"
                        >· Negative : {{ data.item.sentiment_negative }} %
                      </span>
                      <span class="text-info text-weight-bolder"
                        >· Mixed : {{ data.item.sentiment_mixed }} %
                      </span>
                    </div>
                  </div>
                </b-tooltip>
              </div>
            </template>

            <template #cell(details)="row">
              <feather-icon
                :icon="row.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                class="cursor-pointer"
                size="23"
                @click="row.toggleDetails"
              ></feather-icon>
            </template>
            <template #row-details="row">
              <b-card>
                <b-row
                  class="text-center text-primary pb-1"
                  style="font-weight: 900"
                >
                  <b-col cols="12">
                    <span>CALL</span>
                    <hr />
                  </b-col>
                  <b-col cols="12">
                    <div class="d-flex justify-content-center">
                      <div>
                        <new-wave-surfer-player
                          class="audio-recording"
                          :url="row.item.record_url"
                          :options="audioOptions"
                          :wave-surfer-id="`wave-surfer-${row.index}`"
                          :elapsed-time-width="95"
                          :file_name="row.item.original_id"
                          @loading="row.item.isLoading = true"
                          @ready="row.item.isLoading = false"
                          custom-class="no-overflow"
                          :download="false"
                          :show-duration="true"
                        ></new-wave-surfer-player>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row
                  class="text-center text-primary pb-1"
                  style="font-weight: 900"
                >
                  <b-col cols="12">
                    <span>SUMMARY</span>
                    <hr />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" style="max-height: 200px; overflow: auto">
                    <!-- <p class="text-justify px-2">{{  }}</p> -->
                    <div v-html="row.item.content"></div>
                  </b-col>
                </b-row>
              </b-card>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <call-impression-modal
        v-if="modalCallImpression"
        @close="modalCallImpression = false"
      />
    </b-card>
  </b-modal>
</template>

<script>
import ClientStatus from "@/views/ceo/dashboard/ClientStatus.vue";
import CallImpressionService from "@/views/commons/call-impression/services/callImpression.service.js";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import NewWaveSurferPlayer from "@/views/management/views/approval-process/settlement-approval/components/NewWaveSurferPlayer.vue";
import CallImpressionModal from "@/views/ceo/dashboard/modals/CallImpressionModal.vue";
import PositiveIcon from "@/icons/PositiveIcon.vue";
import NeutralIcon from "@/icons/NeutralIcon.vue";
import MixedIcon from "@/icons/MixedIcon.vue";
import NegativeIcon from "@/icons/NegativeIcon.vue";

export default {
  components: {
    ClientStatus,
    StatusAccount,
    NewWaveSurferPlayer,
    CallImpressionModal,
    PositiveIcon,
    NeutralIcon,
    MixedIcon,
    NegativeIcon,
  },

  props: {
    viewDate: {
      type: Boolean,
      default: false,
      required: false,
    },
    selectYear: {
      type: [Number, String],
      default: 0,
    },
    selectMonth: {
      type: Number,
      default: 0,
    },
    moduleId: {
      type: Number,
      required: true,
    },
    sentimentSelected: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {
      showCallImpressionModal: true,
      imgCalls: require("@/assets/images/icons/logo-call-impression.svg"),
      callImpressionData: [],
      isBusy: false,
      audioOptions: {
        waveColor: "#99989A",
        progressColor: "#0077E6",
        barWidth: 3,
        barRadius: 3,
        cursorWidth: 0,
        height: 20,
        barGap: 2,
        backend: "MediaElement",
        minPxPerSec: 0,
        normalizeToZero: true,
        waveOutlineWidth: 0,
        barMinHeight: 2,
      },
      listServices: [
        { value: null, label: "All Services" },
        { value: 3, label: "Business" },
        { value: 7, label: "Boost Credit" },
        { value: 6, label: "Credit Experts" },
        { value: 5, label: "Debt Solution" },
        { value: 8, label: "Tax Research" },
        { value: 10, label: "Court Info" },
        { value: 11, label: "Specialist" },
        { value: 12, label: "Paragon" },
        { value: 14, label: "KeyBook" },
      ],
      listSentiments: [
        { value: 2, label: "POSITIVE", color: "#00D25B" },
        { value: 3, label: "NEGATIVE", color: "#F10000" },
        { value: 1, label: "NEUTRAL", color: "#0090E7" },
        { value: 4, label: "MIXED", color: "#6E3CCD" },
      ],
      total_neutral: 0,
      total_positive: 0,
      total_negative: 0,
      total_mixed: 0,
      fields: [
        {
          key: "service_name",
          label: "SERVICE",
          thClass: "text-center col-1",
          tdClass: "text-center col-1",
        },
        {
          key: "client_name",
          label: "CLIENT",
          thClass: "text-start col-2",
          tdClass: "text-start col-2",
        },
        {
          key: "phone_client",
          label: "PHONE CLIENT",
          thClass: "text-center col-1",
          tdClass: "text-center col-1",
        },
        {
          key: "service_line",
          label: "SERVICE LINE",
          thClass: "text-center col-1",
          tdClass: "text-center col-1",
        },
        {
          key: "date",
          label: "DATE/HOUR",
          thClass: "text-center col-1",
          tdClass: "text-center col-1",
        },
        {
          key: "sentiment",
          label: "SENTIMENT",
          thClass: "text-center col-1",
          tdClass: "text-center col-1",
        },
        {
          key: "details",
          label: "DETAILS",
          thClass: "col-1",
          tdClass: "col-1",
        },
      ],
      modalCallImpression: false,
      firstDayOfMonth: null,
      lastDayOfMonth: null,
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },

  watch: {
    // selectSentiment(value) {
    //   this.getCallImpressions();
    // },
    // selectYear(value) {
    //   this.getCallImpressions();
    // },
    // selectMonth(value) {
    //   this.getCallImpressions();
    // },
  },

  async created() {
    this.selectSentiment = this.sentimentSelected;
    await this.getCallImpressions();
  },
  methods: {
    async getCallImpressions() {
      let firstDay = new Date(this.selectYear, this.selectMonth - 1, 1);
      let lastDay = new Date(this.selectYear, this.selectMonth, 0);

      if (this.viewDate) {
        firstDay = new Date(this.year, this.month - 1, 1);
        lastDay = new Date(this.year, this.month, 0);
      }

      this.firstDayOfMonth = firstDay.toISOString().substring(0, 10);
      this.lastDayOfMonth = lastDay.toISOString().substring(0, 10);

      this.isBusy = true;
      try {
        const params = {
          search_text: null,
          page: 1,
          per_page: 10,
          module_id: this.moduleId,
          type_sentiment: this.selectSentiment,
          from: this.firstDayOfMonth,
          to: this.lastDayOfMonth,
        };

        const { data } = await CallImpressionService.getCallImpressions(params);
        this.callImpressionData = data.data.data;
        this.total_neutral = data.total_neutral;
        this.total_positive = data.total_positive;
        this.total_negative = data.total_negative;
        this.total_mixed = data.total_mixed;

        this.isBusy = false;
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    dashboardRouteName(module_id) {
      const routers = {
        2: "dashboard-crm",
        3: "bussiness-dashboard-clients",
        4: "administration-dashboard",
        5: "debt-solution-dashboard",
        6: "credit-experts-dashboard",
        7: "boost-credit-dashboard",
        8: "tax-research-client-dashboard",
        10: "claim-department-dashboard",
        11: "specialists-client-dashboard",
        12: "paragon-dashboard",
        14: "bookeeping-dashboard",
        16: "management-dashboard",
        18: "quality-client-dashboard",
        20: "connection-clients-account",
        21: "ce-customer-service-dashboard",
        22: "ce-customer-service-dashboard",
        23: "financial-clients-account",
        25: "specialist-digital-dashboard",
        29: "specialists-client-dashboard-fa",
        16: "management-client-dashboard",
      };
      return routers[module_id] || null;
    },
    dashboardLeadRouteName(module_id) {
      const routers = {
        2: "lead-show",
        3: "bussiness-lead-show",
        4: "administration-lead-show",
        5: "debtsolution-lead-show",
        6: "creditexperts-lead-show",
        7: "boostcredit-lead-show",
        8: "tax-research-lead-show",
        10: "claimdepartment-lead-show",
        11: "specialists-leads-show",
        12: "ti-lead-show",
        14: "bookeeping-lead-show",
        20: "connection-lead-show",
        22: "customerservice-lead-show",
        23: "financial-leads-lead-show",
        25: "specialist-dg-lead-show",
        26: "sales-lead-show",
      };
      return routers[module_id] || null;
    },
    variantResult(result) {
      switch (result) {
        case "POSITIVE":
          return "success";
        case "NEGATIVE":
          return "danger";
        case "NEUTRAL":
          return "primary";
        default:
          return "info";
      }
    },
    getColorSentiment(sentiment) {
      switch (sentiment) {
        case 1:
          return "#0090E7";
        case 2:
          return "#00D25B";
        case 3:
          return "#F10000";
        case 4:
          return "#6E3CCD";
      }
    },
    openDetailsCallImpression() {
      this.modalCallImpression = true;
    },
    handleChange(value) {
      this.selectSentiment = value;
      this.getCallImpressions();
    },
    getTotalAndPercentage(sentiment) {
      const total =
        this.total_neutral +
        this.total_positive +
        this.total_negative +
        this.total_mixed;
      switch (sentiment) {
        case 1:
          return [
            this.total_neutral,
            ((this.total_neutral / total) * 100).toFixed(2),
          ];
        case 2:
          return [
            this.total_positive,
            ((this.total_positive / total) * 100).toFixed(2),
          ];
        case 3:
          return [
            this.total_negative,
            ((this.total_negative / total) * 100).toFixed(2),
          ];
        case 4:
          return [
            this.total_mixed,
            ((this.total_mixed / total) * 100).toFixed(2),
          ];
      }
    },
    async next() {
      if (this.month < 12) {
        this.month++;
      } else {
        this.month = 1;
        this.year++;
      }
      await this.getCallImpressions();
    },
    async prev() {
      if (this.month > 1) {
        this.month--;
      } else {
        this.month = 12;
        this.year--;
      }
      await this.getCallImpressions();
    },
  },
};
</script>

<style lang="scss" scoped>
.b_card_content {
  border-radius: 18px;
  min-height: 370px;

  &--dark {
    background: rgba(17, 17, 19, 1);
    box-shadow: 0px 0px 15px 0px #ffffff26 !important;
  }

  .title_content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .b_avatar_color {
    background: rgba(167, 197, 255, 1) !important;
  }

  .select_border_dark {
    color: #1654cc;
    border-color: #1654cc !important;
  }

  button {
    box-shadow: none !important;
  }

  .counter {
    position: absolute;
    background: red;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    top: -5px;
    right: -5px;
  }

  .table-new-customization {
    max-height: 55vh;
    min-height: 55vh;
  }
}
</style>

<style lang="scss">
.wo-min-height {
  min-height: 0 !important;
}
</style>


<style lang="scss" scoped>
.header-modal {
  position: relative;
  padding: 20px 0 20px 15px;
  background: rgba(22, 84, 204, 1);
  width: 100%;
  font-size: 22px;
  display: flex;

  span {
    font-size: 1.6rem;
    font-weight: bold;
    margin-left: 8px;
    color: #ffffff;
  }

  .container-icons {
    background-color: #ffffff;
    padding: 2.7px 6.37px;
    border-radius: 0.357rem;
    width: 31px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    transition: all 0.23s ease 0.1s;

    &--dark {
      background-color: #17171a;
    }

    &:hover {
      transform: translate(-7px, 5px);
      cursor: pointer;
    }
  }
}
</style>