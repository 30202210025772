var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modalName",attrs:{"title-class":"h3 text-white","title":"CONFIRM COLLECT","size":"lg","centered":"","no-close-on-backdrop":"","modal-class":"custom-modal-amg"},on:{"hidden":function($event){return _vm.closeModal()}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"custom-amg"},on:{"click":function($event){return _vm.confirm()}}},[_vm._v(" Confirm ")])]},proxy:true}])},[_c('validation-observer',{ref:"fileForm"},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"check_number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Billing Date"}},[_c('b-form-datepicker',{class:errors[0] ? 'is-invalid' : '',attrs:{"id":"date","date-format-options":{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                },"max":new Date()},model:{value:(_vm.form.billing_date),callback:function ($$v) {_vm.$set(_vm.form, "billing_date", $$v)},expression:"form.billing_date"}})],1)]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"check_number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Check number"}},[_c('b-form-input',{attrs:{"id":"date","state":errors.length > 0 ? false : null},model:{value:(_vm.form.check_number),callback:function ($$v) {_vm.$set(_vm.form, "check_number", $$v)},expression:"form.check_number"}})],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }