<template>
  <div
    class="dashboard__card"
    :class="isDarkSkin ? 'dashboard__card--dark' : ''"
  >
    <div class="card-report">
      <div class="report-header">
        <h2 class="report-header__title">Incomes</h2>
        <div class="report-header__icons">
          <img :src="imgIncomes" alt="img_users" />
        </div>
      </div>

      <div class="line-card" />

      <div class="report-body">
        <div class="report">
          <div
            class="report__value value-currency"
            :class="isDarkSkin ? 'report__value--dark' : ''"
          >
            {{ integerPart(objIncomes.total)
            }}<span v-if="objIncomes.total != 0" class="report__value--decimal"
              >.{{ decimalPart(objIncomes.total) }}</span
            >
          </div>
        </div>
        <hr />
        <div class="detail">
          <div
            v-for="(item, i) in objIncomes.incomes"
            :key="i"
            class="detail-content"
            :class="isDarkSkin ? 'detail-content--dark' : ''"
          >
            <div class="detail-content__text">
              <span class="list-incomes">.</span> <span>{{ item.label }}</span>
            </div>
            <div
              class="detail-content__value pointer"
              :class="isDarkSkin ? 'detail-content__value--dark' : ''"
              @click="showModalDetailIncomes(item.name)"
            >
              <span v-if="item.amount != 0">{{ item.amount | currency }}</span>
              <span v-else>{{ item.amount }} </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <detail-sales
      v-if="showDetailSales"
      :send-year="dataYear"
      :send-month="dataMonth"
      :send-programs="arrPrograms"
      @close="showDetailSales = false"
    />
    <detail-charges
      v-if="showDetailCharges"
      :send-year="dataYear"
      :send-month="dataMonth"
      :send-programs="arrPrograms"
      @close="showDetailCharges = false"
    />
    <detail-client-payments
      v-if="showDetailClientsPayments"
      :send-year="dataYear"
      :send-month="dataMonth"
      :send-programs="arrPrograms"
      @close="showDetailClientsPayments = false"
    />
  </div>
</template>
<script>
import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service";
import DetailSales from "@/views/ceo/dashboard/modals/incomes/DetailSales.vue";
import DetailCharges from "@/views/ceo/dashboard/modals/incomes/DetailCharges.vue";
import DetailClientPayments from "@/views/ceo/dashboard/modals/incomes/DetailClientPayments.vue";

export default {
  components: {
    DetailSales,
    DetailCharges,
    DetailClientPayments,
  },
  props: {
    selectYear: {
      type: Number,
      default: null,
      required: false,
    },
    selectMonth: {
      type: Number,
      default: null,
      required: false,
    },
    listPrograms: {
      type: Array,
      required: false,
      default: [],
    },
  },
  data() {
    return {
      imgIncomes: require("@/assets/images/icons/dashboard-ceo/incomes.svg"),
      showDetailSales: false,
      showDetailCharges: false,
      showDetailClientsPayments: false,
      dataYear: null,
      dataMonth: null,
      objIncomes: {
        total: 0,
        incomes: [
          {
            label: "Sales Amount",
            amount: 0,
            name: "sales",
          },
          {
            label: "Charges",
            amount: 0,
            name: "charges",
          },
          {
            label: "Clients Payments",
            amount: 0,
            name: "clients_payments",
          },
        ],
      },
      arrPrograms: [],
    };
  },
  watch: {
    selectMonth(newVal) {
      this.dataMonth = newVal;
      this.getIncomes();
    },
    selectYear(newVal) {
      this.dataYear = newVal;
      this.getIncomes();
    },
  },
  async created() {
    this.dataMonth = this.selectMonth;
    this.dataYear = this.selectYear;
    await this.getIncomes();
  },
  methods: {
    async getIncomes() {
      try {
        const params = {
          month: this.dataMonth,
          year: this.dataYear,
        };
        const { data } = await DashboardServive.getIncomes(params);
        if (data) {
          this.objIncomes = data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    decimalPart(number) {
      number = parseFloat(number);
      number = number.toFixed(2);
      const parts = number.split(".");

      if (number != 0) {
        const parts = number.split(".");
        if (parts.length > 1) {
          return parts[1];
        }
      } else {
        return "00";
      }
    },
    integerPart(number) {
      number = parseInt(number);
      return number.toLocaleString();
    },

    showModalDetailIncomes(name) {
      this.arrPrograms = this.listPrograms;
      switch (name) {
        case "sales":
          this.showDetailSales = true;
          break;
        case "charges":
          this.showDetailCharges = true;
          break;
        case "clients_payments":
          this.showDetailClientsPayments = true;
          break;

        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$color-primary: #5f5f5f;
$color-secondary: #ffffff;
$bg-card-white: #ffffff;
$bg-card-dark: #111113;
$box-shadow-white: #00000026;

.dashboard__card {
  margin-top: 5px;
  box-shadow: 0px 0px 15px 0px $box-shadow-white;
  border-radius: 15px;
  margin-bottom: 27px;
  padding: 18px;
  background-color: $bg-card-white;
  height: auto !important;

  &--dark {
    background-color: $bg-card-dark;
    box-shadow: 0px 0px 22.935px 0px rgba(255, 249, 249, 0.1);
  }

  .card-report {
    position: relative;

    .report-header {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__title {
        color: #959595;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding-left: 14px;
      }

      &__icons {
        width: 47px;
        height: 47px;
      }
    }

    .line-card {
      width: 4.5px;
      height: 58px;
      background-color: #1654cc;
      position: absolute;
      top: 14px;
      left: -3px;
      border-radius: 8px;
    }

    .report-body {
      padding-left: 14px;

      .report {
        width: 100%;
        display: flex;
        align-items: center;

        &__value {
          color: #606060;
          font-family: Montserrat;
          font-weight: 700;
          font-size: 1.6rem;
          // letter-spacing: 0.2px;

          &--decimal {
            font-size: 1.3rem;
          }

          &--dark {
            color: $color-secondary;
          }
        }

        .value-currency::before {
          content: "$";
        }
      }

      .detail {
        height: 110px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .detail-content {
          display: flex;
          justify-content: space-between;
          color: #606060;
          position: relative;

          &--dark {
            color: #b4b4b4;
          }

          &__text {
            font-family: Montserrat;
            font-size: 14.678px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            .list-incomes {
              margin-right: 7px;
              font-size: 15px;
              font-weight: bold;
              position: absolute;
              top: -4px;
              left: -9px;
            }
          }

          &__value {
            font-family: Montserrat;
            font-size: 14.678px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            &::before {
              content: "$";
            }
            &:hover {
              color: #3db87f;
            }

            &:active {
              color: #038a2c;
            }

            &--dark {
              &:hover {
                color: #b1fccf;
              }

              &:active {
                color: #1d7b44;
              }
            }
          }
        }
      }
    }
  }
}
</style>
