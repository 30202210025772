var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modalName",attrs:{"modal-class":"custom-modal-amg","title-class":"h3 text-white","title":"HISTORY OF TRANSACTION IMPORTS","size":"xmd","hide-footer":"","header-class":"p-0","centered":""},on:{"hidden":function($event){return _vm.closeModal()}},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('div',{staticClass:"header-modal"},[_c('div',[_vm._v("HISTORY OF TRANSACTION IMPORTS")]),_c('div',{staticClass:"container-icons",class:_vm.isDarkSkin ? 'container-icons--dark' : '',on:{"click":function($event){return _vm.closeModal()}}},[_c('feather-icon',{staticClass:"pointer",class:_vm.isDarkSkin ? 'text-light' : 'text-primary',attrs:{"icon":"XIcon","size":"18"}})],1)])]},proxy:true}])},[_c('b-table',{staticClass:"position-relative table-new-customization",attrs:{"items":_vm.items,"fields":_vm.fields,"sticky-header":"50vh","show-empty":""},scopedSlots:_vm._u([{key:"cell(cardinfo)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4 p-0 justify-content-end d-flex"},[_c('type-card',{attrs:{"type_card":item.type_card}})],1),_c('div',{staticClass:"col-8 p-0 d-flex justify-content-center align-items-center"},[_c('strong',[_vm._v("XXXX-"+_vm._s(item.cardnumber.slice(-4)))])])])]}},{key:"cell(date_ranges)",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',[_vm._v("from "),_c('strong',[_vm._v(_vm._s(_vm._f("myGlobal")(item.date_ranges.split(" - ")[0])))])]),_c('br'),_c('span',[_vm._v(" to "),_c('strong',[_vm._v(_vm._s(_vm._f("myGlobal")(item.date_ranges.split(" - ")[1])))])])])]}},{key:"cell(total_transactions)",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('b-badge',{staticClass:"cursor-pointer",staticStyle:{"font-size":"15px"},attrs:{"variant":"primary"},on:{"click":function($event){return _vm.openDetail(item)}}},[_vm._v(_vm._s(item.total_transactions))])],1)]}},{key:"cell(total_amount)",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('b-badge',{staticClass:"cursor-pointer",staticStyle:{"font-size":"15px"},attrs:{"variant":"light-success"},on:{"click":function($event){return _vm.openDetail(item)}}},[_vm._v(_vm._s(_vm._f("formatMoney")(item.total_amount)))])],1)]}},{key:"cell(created_at)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("myGlobalWithHour")(item.created_at))+" ")])]}}])}),(_vm.showDetail)?_c('ImportHistoryDetail',{attrs:{"historyInfo":_vm.logInfo},on:{"close":function($event){_vm.showDetail = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }