export default [
      {
        key: "from",
        visible : true,
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "From",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        },
        cols: 6,
      },
      {
        key: "to",
        visible : true,
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "To",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        },
        cols: 6,
      },
      {
        key: "service",
        visible : false,
        type: "select",
        margin: true,
        showLabel: true,
        label: "Service",
        model: null,
        options: [ 
          { value: null, label: "All" },
          { value: 3, label: "Business" },
          { value: 7, label: "Boost Credit" },
          { value: 6, label: "Credit Experts" },
          { value: 5, label: "Debt Solution" },
          { value: 8, label: "Tax Research" },
          { value: 10, label: "Court Info" },
          { value: 11, label: "Specialist" },
          { value: 12, label: "Paragon" },
          { value: 14, label: "KeyBook" }, 
        ],
        reduce: "value",
        selectText: "label",
        cols: 6,
      }, 
      // {
      //   key: "sentiment",
      //   visible : true,
      //   type: "select",
      //   margin: true,
      //   showLabel: true,
      //   label: "Sentiment",
      //   model: null,
      //   options: [
      //     { value: null, label: "All" },
      //     { value: 1, label: "NEUTRAL" },
      //     { value: 2, label: "POSITIVE" },
      //     { value: 3, label: "NEGATIVE" },
      //     { value: 4, label: "MIXED" },
      //   ],
      //   reduce: "value",
      //   selectText: "label",
      //   cols: 6,
      // }, 
    ];
    
