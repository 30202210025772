<template>
  <div>
    <b-modal
      id="AddEditCredential"
      v-model="onControl"
      size="lg"
      centered
      no-close-on-backdrop
      @hidden="close"
      header-text-variant="primary"
      modal-class="custom-modal-amg"
      :title="`UPLOAD CHECK`"
    >
      <validation-observer ref="fileForm">
        <b-row>
          <b-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              name="check_number"
              rules="required"
            >
              <b-form-group label="Check number">
                <b-form-input v-model="form.check_number" />
                <span v-if="errors[0]" class="text-danger">
                  Check number {{ errors[0] }}</span
                >
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              name="file"
              rules="required"
            >
              <b-form-group label="Check File">
                <b-input-group>
                  <template #append>
                    <div
                      class="d-flex justify-content-center align-items-center"
                      v-if="fileToUpload"
                    >
                      <feather-icon
                        icon="FileIcon"
                        class="cursor-pointer text-primary ml-1"
                        size="20"
                        v-b-tooltip.hover.top="'Preview'"
                        @click="previewFile(fileToUpload)"
                      />
                      <feather-icon
                        icon="TrashIcon"
                        class="cursor-pointer text-danger ml-1"
                        size="20"
                        v-b-tooltip.hover.top="'Remove'"
                        @click="removeFile"
                      />
                    </div>
                  </template>
                  <b-form-file v-model="fileToUpload" @input="fileFill" />
                </b-input-group>
                <span v-if="errors[0]" class="text-danger">
                  File {{ errors[0] }}</span
                >
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <b-button variant="custom-amg" @click="saveOneFile()">
          Send to correspondence
        </b-button>
      </template>

      <image-viewer
        v-if="previewUrl && !isPdf"
        :objectImages="[previewUrl]"
        :route="previewUrl"
        :showBottom="false"
        @hidden="clearPreview"
        style="z-index: 1002 !important"
      />
    </b-modal>

    <preview-pdf
      v-if="previewUrl && isPdf"
      :pdf-url="previewUrl"
      :pdf-name="fileName"
      @close="clearPreview"
    />
  </div>
</template>

<script>
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue";
import DashboardService from "@/views/ceo/service-dashboard/dashboard.service.js";
import { mapGetters, mapActions } from "vuex";
import PreviewPdf from "@/views/debt-solution/views/court-info/components/PreviewPdf.vue";
import ImageViewer from "@/views/commons/components/paragon-soft/components/ImageViewer.vue";
export default {
  components: {
    DragAndDrop,
    PreviewPdf,
    ImageViewer,
  },
  props: {
    checkData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      onControl: false,
      form: {
        check_number: null,
        check_id: null,
        department_expnese_id: null,
      },
      uploadFiles: [],
      fileToUpload: null,
      bankList: [],
      checkList: [],
      previewUrl: null,
      fileName: null,
      fileType: null,
    };
  },
  async created() {
    this.onControl = true;
    this.form.check_id = this.checkData.check_id;
    this.form.department_expnese_id = this.checkData.department_expnese_id;
    await this.getAccountList();
  },
  methods: {
    ...mapActions({
      refreshExpensesCounter: "ExpensesRequest/refreshExpensesCounter",
    }),
    previewFile(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewUrl = e.target.result;
          this.fileName = file.name;
          this.fileType = file.type;
        };
        reader.readAsDataURL(file);
      }
    },
    removeFile() {
      this.fileToUpload = null;
      this.previewUrl = null;
    },
    clearPreview() {
      this.previewUrl = null;
      this.fileName = null;
    },
    async saveOneFile() {
      const result = await this.$refs.fileForm.validate();
      if (!result) return;

      const confirn = await this.showConfirmSwal(
        `Are you sure you want to send the check <strong>${this.form.check_number}</strong> to correspondence?`
      );
      if (!confirn.isConfirmed) return;

      this.form.user_id = this.currentUser.user_id;
      try {
        this.addPreloader();
        const data = await DashboardService.uploadCheck(this.form);
        if (data.success) {
          this.showToast(
            "success",
            "top-right",
            "Success",
            "CheckIcon",
            "Successfully process"
          );
          this.refreshExpensesCounter(true);
          this.$emit("reload");
          this.$emit("close");
        } else {
          this.showToast(
            "danger",
            "top-right",
            "Oop!",
            "AlertOctagonIcon",
            "Failed process"
          );
        }
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },
    async getAccountList() {
      const data = await DashboardService.getAccountList({
        module_id: this.checkData.module_id,
      });
      this.checkList = data;
    },
    async fileFill() {
      this.form.namedoc = this.fileToUpload.name;
      this.form.size = this.fileToUpload.size;
      const reader = new FileReader();
      reader.readAsDataURL(this.fileToUpload);
      reader.onload = async (file) => {
        this.form.file = file.target.result;
      };
    },
    close() {
      this.$emit("close");
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
      updateLetterTable:
        "SpecialistsDigitalRoundLettersStore/G_UPDATE_LETTER_TABLE",
    }),
    isPdf() {
      return this.fileType === "application/pdf";
    },
    checkExpenseScope() {
      return {
        attr: {
          options: this.checkList,
          label: "value",
          reduce: (item) => item.id,
        },
      };
    },
  },
};
</script>

<style scoped lang="scss">
.header-modal {
  border-radius: 3px 3px 0px 0px;
  position: relative;
  padding: 20px 0 20px 15px;
  background: rgba(22, 84, 204, 1);
  width: 100%;
  font-size: 22px;
  display: flex;
  justify-content: space-betweeen;

  span {
    font-size: 1.6rem;
    font-weight: bold;
    margin-left: 8px;
    color: #ffffff;
  }

  .container-icons {
    background-color: #ffffff;
    padding: 2.7px 6.37px;
    border-radius: 0.357rem;
    width: 31px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    transition: all 0.23s ease 0.1s;

    &--dark {
      background-color: #17171a;
    }

    &:hover {
      transform: translate(-7px, 5px);
      cursor: pointer;
    }
  }
}
.header-modal {
  border-radius: 1.5rem 1.5rem 0 0 !important;
}
</style>
