<template>
  <div>
    <svg
      :width="width"
      :height="height"
      :viewBox="'0 0 ' + width + ' ' + height"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6654 15.1812C17.0963 14.8008 17.442 14.3304 17.6789 13.8018C17.9158 13.2732 18.0385 12.6988 18.0385 12.1176C18.0385 11.0256 17.613 9.97824 16.8556 9.20603C16.0983 8.43382 15.0711 8 14 8C12.9289 8 11.9017 8.43382 11.1444 9.20603C10.387 9.97824 9.96153 11.0256 9.96153 12.1176C9.96152 12.6988 10.0841 13.2732 10.3211 13.8018C10.558 14.3304 10.9036 14.8008 11.3346 15.1812C10.2039 15.7032 9.24467 16.5462 8.57147 17.6094C7.89828 18.6726 7.53964 19.911 7.53845 21.1765C7.53845 21.3949 7.62355 21.6044 7.77502 21.7588C7.92649 21.9132 8.13193 22 8.34614 22C8.56036 22 8.7658 21.9132 8.91727 21.7588C9.06874 21.6044 9.15384 21.3949 9.15384 21.1765C9.15384 19.866 9.66441 18.6092 10.5732 17.6825C11.4821 16.7559 12.7147 16.2353 14 16.2353C15.2853 16.2353 16.5179 16.7559 17.4267 17.6825C18.3356 18.6092 18.8461 19.866 18.8461 21.1765C18.8461 21.3949 18.9312 21.6044 19.0827 21.7588C19.2342 21.9132 19.4396 22 19.6538 22C19.8681 22 20.0735 21.9132 20.225 21.7588C20.3764 21.6044 20.4615 21.3949 20.4615 21.1765C20.4603 19.911 20.1017 18.6726 19.4285 17.6094C18.7553 16.5462 17.796 15.7032 16.6654 15.1812ZM14 14.5882C13.5208 14.5882 13.0523 14.4433 12.6538 14.1719C12.2553 13.9004 11.9448 13.5145 11.7614 13.0631C11.578 12.6117 11.53 12.1149 11.6235 11.6357C11.717 11.1564 11.9477 10.7162 12.2866 10.3707C12.6255 10.0252 13.0572 9.78986 13.5273 9.69453C13.9973 9.5992 14.4845 9.64813 14.9273 9.83512C15.37 10.0221 15.7485 10.3388 16.0147 10.7451C16.281 11.1513 16.4231 11.629 16.4231 12.1176C16.4231 12.7729 16.1678 13.4013 15.7134 13.8646C15.259 14.3279 14.6426 14.5882 14 14.5882Z"
        :fill="color"
      />
      <circle cx="14" cy="15" r="13.25" :stroke="color" stroke-width="1.5" />
      <circle
        cx="23.5"
        cy="5.5"
        r="4.1"
        :fill="color"
        :stroke="stroke"
        stroke-width="1.2"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      required: false,
      default: 29,
    },
    height: {
      type: Number,
      required: false,
      default: 29,
    },
    color: {
      type: String,
      required: false,
      default: "#00DD31",
    },
    stroke: {
      type: String,
      required: false,
      default: "#CCF8D6",
    },
  },
};
</script>

