<template>
  <div class="bubble-message">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "Bubble2",
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.bubble-message {
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  white-space: pre-wrap;
  width: fit-content;
  z-index: 1;
}
.bubble-message.me {
  background-color: #0a7cff;
  color: #fff;
}
.bubble-message.other {
  background-color: #efefef;
  color: #000;
}

.dark-layout {
  .bubble-message.other {
    background-color: #22272b;
    color: #fff;
  }
}
</style>