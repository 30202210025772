<template>
  <b-card
    ref="bCarCeDs"
    class="dashboard__card b_card_content h-100"
    :class="isDarkSkin ? 'dashboard__card--dark' : ''"
  >
    <div class="d-flex mb-1">
      <b-avatar rounded="sm" size="4em" class="b_avatar_color">
        <div class="ico_content">
          <b-img :src="icoCeCard" style="width: 85%; height: 85%" />
        </div>
      </b-avatar>

      <div class="ml-1 title_content">
        <span :style="titleCard()"> Specialists </span>
      </div>
    </div>
    <b-row class="m-0 p-0 mt-3">
      <b-col v-for="(item, index) in listCs" :key="'ce' + index">
        <template
          v-if="
            item.name != 'amount_regular_paid' &&
            item.name != 'amount_digital_paid'
          "
        >
          <div
            variant="primary"
            :class="[' mini_car', isDarkSkin ? 'mini_car--dark' : '']"
            style="padding: 0.5rem"
            @click="openModalCe('type_ce', item)"
          >
            <div
              :style="titleCardMini()"
              style="display: block; width: 75px"
              class="text-center"
              v-html="item.showName"
            />
            <span class="num">
              {{ item.value }}
              <span v-if="item.name == 'value_total_regular'"
                >| {{ quantityRegularPaid.value }}</span
              >
              <span v-if="item.name == 'value_total_digital'"
                >| {{ quantityDigitalPaid.value }}</span
              >
              <tabler-icon
                v-if="
                  item.name == 'value_total_regular' ||
                  item.name == 'value_total_digital'
                "
                icon="CreditCardIcon"
                size="12"
                class="text-sucess mr-05"
              />
            </span>
            <span v-if="item.name == 'value_total_regular'" class="num-shorter">
              ($ {{ amountRegularPaid.value | currencyZero }})
            </span>
            <span v-if="item.name == 'value_total_digital'" class="num-shorter">
              ($ {{ amountDigitalPaid.value | currencyZero }})
            </span>
          </div>
        </template>
      </b-col>
    </b-row>
    <div style="margin-left: -15px" class="mt-2">
      <div
        class="w-full d-flex justify-content-between"
        style="padding: 0 15px"
      >
        <div class="ml-1 title_content">
          <span :style="titleCard()"> Request CR </span>
        </div>
        <b-form-select
          v-model="moduleIdSelected"
          text-field="name"
          class="new-style-select"
          :class="isDarkSkin ? 'dark' : ''"
          value-field="id"
          :options="moduleOptions"
        />
      </div>
      <div>
        <b-table-simple
          small
          responsive
          v-if="isShowNcr"
          empty-text="No matching records found"
          class="position-relative table-new-customization"
          table-class="padding-remove"
          show-empty
        >
          <b-thead>
            <b-tr>
              <b-th class="text-center"> TYPE </b-th>
              <b-th class="text-center"> QUANTITY </b-th>
              <b-th class="text-center"> COMPLETED </b-th>
              <b-th class="text-center"> PAID </b-th>
              <b-th class="text-center"> UNPAID </b-th>
              <b-th class="text-center"> ZERO PAY </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(item, i) in objectNcr" :key="i">
              <b-td class="text-center">
                {{ item.type_ncr }}
              </b-td>
              <b-td
                class="text-center td-selected request"
                style="font-size: 13px"
                @click="
                  item.request != 0 ? openModalNcrDetails(item, 1) : false
                "
              >
                <span :style="item.request != 0 ? 'cursor:pointer' : false">
                  {{ item.request }}
                </span>
              </b-td>
              <b-td
                class="text-center td-selected request"
                style="font-size: 13px"
                :style="item.completed != 0 ? 'cursor:pointer' : false"
                @click="
                  item.completed != 0 ? openModalNcrDetails(item, 2) : false
                "
              >
                <span :style="item.completed != 0 ? 'cursor:pointer' : false">
                  {{ item.completed }}
                </span>
              </b-td>
              <b-td
                class="text-center"
                @click="item.paid != 0 ? openModalNcrDetails(item, 3) : false"
              >
                <b-badge
                  variant="light-success"
                  style="font-size: 13px"
                  :style="item.paid != 0 ? 'cursor:pointer' : false"
                >
                  {{ item.paid }}
                  {{
                    item.amount_paid != 0 ? "($" + item.amount_paid + ")" : ""
                  }}
                </b-badge>
              </b-td>
              <b-td
                class="text-center"
                @click="item.unpaid != 0 ? openModalNcrDetails(item, 4) : false"
              >
                <b-badge
                  variant="light-danger"
                  style="font-size: 13px"
                  class="w-full"
                  :style="item.unpaid != 0 ? 'cursor:pointer' : false"
                >
                  {{ item.unpaid }}
                  {{
                    item.amount_unpaid != 0
                      ? "($-" + item.amount_unpaid + ")"
                      : ""
                  }}
                </b-badge>
              </b-td>
              <b-td
                class="text-center"
                @click="item.zero != 0 ? openModalNcrDetails(item, 5) : false"
              >
                <b-badge
                  :style="item.zero != 0 ? 'cursor:pointer' : false"
                  variant="light-warning"
                  style="font-size: 13px"
                  class="w-full"
                >
                  {{ item.zero }}
                </b-badge>
              </b-td>
            </b-tr>
            <b-tr v-if="objectNcr.length == 0">
              <b-td colspan="6" class="text-center" style="height: 50px"
                >No Data
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <div
          v-else
          class="d-flex justify-content-center align-items-center"
          style="min-height: 345px"
        >
          <b-spinner variant="primary" />
        </div>
      </div>
    </div>
    <hr />
    <TicketSection :month="selectMonth" :year="selectYear" />
    <ce-modal
      v-if="statusModalCe"
      :data-client="dataClient"
      :type-modal-name="typeModalName"
      :card-chosen="cardChosen"
      @close="statusModalCe = false"
    />
    <ncr-details-modal
      v-if="openModalNcr"
      :type-ncr-details="typeNcrDetails"
      :type-ncr-id="typeNcrId"
      :year="selectYear"
      :month="selectMonth"
      :module-id="moduleIdSelected"
      :module-name="
        moduleOptions.find((item) => item.id == moduleIdSelected).name
      "
      @hidden="openModalNcr = false"
    />
  </b-card>
</template>
<script>
import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service.js";
import CeModal from "@/views/ceo/dashboard/modals/CeModal.vue";
import OfferMenuBadge from "@/views/main/menu-badge/OfferMenuBadge.vue";
import ncrDetailsModal from "@/views/ceo/dashboard/modals/ncrDetailsModal.vue";
import TicketSection from "@/views/ceo/dashboard/TicketsSection.vue";
export default {
  components: {
    CeModal,
    OfferMenuBadge,
    ncrDetailsModal,
    TicketSection,
  },
  props: {
    selectYear: {
      type: Number,
      default: null,
      required: false,
    },
    selectMonth: {
      type: Number,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      backgroundCe: require("@/assets/images/icons/dashboard-ceo/ce_background.png"),
      backgroundDe: require("@/assets/images/icons/dashboard-ceo/ds_background.png"),
      icoCeCard: require("@/assets/images/icons/dashboard-ceo/ico_ce_card.png"),
      icoDsCard: require("@/assets/images/icons/dashboard-ceo/ico_ds_card.png"),
      sizeScreen: 0,
      statusModalCe: false,
      typeModalName: "",
      listDs: [],
      listCs: [],

      dataClient: {
        statusId: null,
        statusName: null,
        year: this.selectYear,
        month: this.selectMonth,
        idProgram: 0,
      },
      amountRegularPaid: 0,
      amountDigitalPaid: 0,
      quantityRegularPaid: 0,
      quantityDigitalPaid: 0,
      isShowNcr: false,
      openModalNcr: false,
      typeNcrDetails: null,
      typeNcrId: null,
      moduleIdSelected: null,
      moduleOptions: [],
    };
  },
  computed: {},
  watch: {
    selectYear(value) {
      this.getCeIndex();
      this.getNcrData();
      this.dataClient.year = this.selectYear;
    },
    selectMonth(value) {
      this.getCeIndex();
      this.getNcrData();
      this.dataClient.month = this.selectMonth;
    },
    moduleIdSelected(value) {
      this.getNcrData();
    },
  },
  async mounted() {
    await this.getAllModules();
    this.getCeIndex();
    this.getNcrData();
    // responsive font
    setTimeout(() => {
      window.addEventListener("resize", this.checkScreenSize);
      this.checkScreenSize();
    }, 500);
  },
  methods: {
    checkScreenSize() {
      setTimeout(() => {
        const componentCeDs = this.$refs.bCarCeDs;
        this.sizeScreen =
          componentCeDs.clientWidth > 0 ? componentCeDs.clientWidth : 0;
      }, 500);
    },
    titleCard() {
      let size = "18px";
      if (this.sizeScreen >= 407 && this.sizeScreen < 468) {
        size = "16px";
      } else if (this.sizeScreen >= 274 && this.sizeScreen < 407) {
        size = "13px";
      } else if (this.sizeScreen < 274) {
        size = "16px";
      }
      return `display:block;font-size:${size};font-weight:600`;
    },
    titleCardMini() {
      let size = "13px";
      if (this.sizeScreen >= 458 && this.sizeScreen < 488) {
        size = "12px";
      } else if (this.sizeScreen >= 100 && this.sizeScreen < 458) {
        size = "11px";
      }
      return `font-size:${size};font-weight:bold`;
    },
    scrollCard() {
      let scrollActive = "";
      if (this.sizeScreen >= 0 && this.sizeScreen < 423) {
        scrollActive =
          "max-height: 455px;overflow-y: scroll; overflow-x: hidden;";
      }
      return scrollActive;
    },
    openModalCe(typeModal, data) {
      this.typeModalName = typeModal;
      this.statusModalCe = true;
      this.cardChosen = data;
    },
    async getCeIndex() {
      try {
        const params = {
          year: this.selectYear,
          month: this.selectMonth,
        };
        const data = await DashboardServive.getCeStatistics(params);
        if (data.status == 200) {
          this.listCs = data.data || [];

          this.transformTitlesCE();
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    transformTitlesCE() {
      this.listCs.forEach((item) => {
        if (item.name == "value_direct_letters") {
          item.showName = "Direct Letters";
          item.sendBack = "direct_letters";
        }
        if (item.name == "value_regular_letters") {
          item.showName = "Regular Letters";
          item.sendBack = "regular_letters";
        }
        if (item.name == "disputed_account") {
          item.showName = "Disputed accounts";
          item.sendBack = "disputed_accounts";
        }
        if (item.name == "removed_account") {
          item.showName = "Removed accounts";
          item.sendBack = "removed_accounts";
        }
      });
    },
    async getNcrData() {
      try {
        this.isShowNcr = false;
        const params = {
          year: this.selectYear,
          month: this.selectMonth,
          module_id: this.moduleIdSelected,
        };
        const { data } = await DashboardServive.getNcrData(params);
        this.objectNcr = data;
        this.isShowNcr = true;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    openModalNcrDetails(item, type) {
      this.typeNcrId = item.type_ncr_id;
      this.typeNcrDetails = type;
      this.openModalNcr = true;
    },
    async getAllModules() {
      try {
        this.moduleOptions = [
          { id: 2, name: "CRM" },
          { id: 3, name: "Business" },
          { id: 5, name: "Debt Solution" },
          { id: 21, name: "Credit Experts" },
          { id: 7, name: "Boost Credit" },
          { id: 8, name: "Tax Research" },
          { id: 10, name: "Court Info" },
          { id: 28, name: "Specialist" },
          { id: 15, name: "Metamedia" },
          { id: 20, name: "Connection" },
          { id: 23, name: "Financial" },
          { id: 18, name: "Quality" },
        ];
        this.moduleOptions.unshift({ id: null, name: "ALL MODULES" });
      } catch (error) {
        this.showErrorSwal(error);
        console.log(error, "error");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.bg_dark_card {
  background: #111113;
}
.bg_white_card {
  background: #fff;
  box-shadow: 0px 0px 15px 0px #00000026 !important;
}

.b_card_content {
  border-radius: 18px;
  height: 523px;

  .b_row_content {
    background-position: center;
    align-content: flex-start;
    background-repeat: no-repeat;
  }

  .border_right {
    border-right: solid 1px #60606033;
  }

  .b_avatar_color {
    background: rgba(167, 197, 255, 1) !important;
  }

  .title_content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .ico_content {
    border: solid 1px #eee;
    width: 80%;
    height: 83%;
    border-radius: 50%;
    background: #fff;
    text-align: center;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .t-header {
    background: #414bb2 !important;
  }
  .td-selected {
    &.request {
      color: #414bb2 !important;
      font-weight: 900;
    }
    &.paid {
      color: #8fd14f !important;
      font-weight: 900;
    }
    &.unpaid {
      color: #f24726 !important;
      font-weight: 900;
    }
    &.zero {
      color: #fac710 !important;
      font-weight: 900;
    }
  }
}

.mini_car {
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 11.62px 0px rgba(0, 0, 0, 0.15);
  height: 80px;
  background: #fff;
  cursor: pointer;
  &:hover {
    background: #f1f1f1;
  }

  &--dark {
    background: #111113;

    &:hover {
      background: #242426;
    }
  }

  .num {
    display: block;
    font-size: 20px;
    font-weight: bold;
    margin-top: 2px;
    color: rgba(6, 48, 130, 1);
    font-family: "Montserrat", sans-serif;
    // text-decoration: underline;
  }

  .num-shorter {
    display: block;
    font-size: 12px;
    font-weight: bold;
    margin-top: 6px;
    color: rgba(6, 48, 130, 1);
    font-family: "Montserrat", sans-serif;
  }
}

.padding-remove {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
</style>
<style lang="scss" scoped>
.scrollable-container {
  overflow-x: auto; /* Enable horizontal scroll if needed */
  white-space: nowrap; /* Prevent line breaks */
}

.menu-badge {
  position: absolute;
  right: -3%;
  top: -12%;
}
.mr-05 {
  margin-left: 0.3rem;
}
.new-style-select {
  width: 150px;
  background: rgba(237, 241, 248, 1);
  height: 35px;
  border-radius: 3.77px;
  color: #000;
  &.dark {
    background: rgba(17, 17, 19, 1);
    color: #fff;
  }
}
select.new-style-select {
  background-image: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png);
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%;
  &.dark {
    filter: grayscale(1);
  }
}
</style>
