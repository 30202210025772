<template>
  <div>
    <b-modal ref="leads-modal" size="lg" @hidden="$emit('hidden')" hide-footer>
      <template #modal-title>
        <span class="text-uppercase font-weight-bolder">
          TOTAL LEADS OF: {{ flyerCode }}
        </span>
      </template>
      <template #default>
        <div class="card-leads-cr py-1">
          <div
            v-for="(lead, index) in leads"
            :key="index"
            class="card-lead-item rounded"
            :class="{
              ['border-' + lead.borderColor]: lead.borderColor,
              'cursor-pointer': validateLead(lead),
              'card--principal': index === 0,
            }"
            @click="validateLead(lead) && openModalDetail(lead.type)"
          >
            <div class="card-lead-item__header">
              <div class="card-lead-item__header-title">
                {{ lead.title }}
              </div>
              <b-avatar
                size="3em"
                :variant="isDarkSkin ? 'secondary' : 'light'"
              >
                <feather-icon
                  :icon="lead.icon"
                  :class="lead.colorIcon"
                  size="20"
                />
              </b-avatar>
            </div>
            <div
              class="card-lead-item__body"
              :class="`border-top-${lead.borderColor}`"
            >
              <div class="card-lead-item__body-total">
                TOTAL: {{ lead.total }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
    <LeadsDetailModal
      v-if="showLeadsDetailModal"
      :type="type"
      :flyer-code="flyerCode"
      :flyer-id="flyerId"
      :meta-ad-id="metaAdId"
      :date-from="dateFrom"
      :date-to="dateTo"
      :method="method"
      :send-year="sendYear"
      :send-month="sendMonth"
      :send-range="sendRange"
      @hidden="showLeadsDetailModal = false"
    />
  </div>
</template>
  <script>
import ComercialReportService from "@/views/commons/components/reports/tabs/comercial-report/service/comercial-report-v2.service.js";
import LeadsDetailModal from "@/views/commons/components/reports/tabs/comercial-report/modals/leads/LeadsDetailModal.vue";
export default {
  name: "LeadsModal",
  components: {
    LeadsDetailModal,
  },
  props: {
    flyerCode: {
      type: String,
      required: true,
      default: "",
    },
    flyerId: {
      type: Number,
      required: true,
      default: null,
    },
    metaAdId: {
      type: String,
      required: false,
      default: "",
    },
    dateFrom: {
      type: String,
      required: false,
      default: null,
    },
    dateTo: {
      type: String,
      required: false,
      default: null,
    },
    sendYear: {
      type: Number,
      required: false,
      default: null,
    },
    sendMonth: {
      type: Number,
      required: false,
      default: null,
    },
    sendRange: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      leads: [
        {
          title: "Total leads",
          icon: "UserIcon",
          total: 0,
          borderColor: "primary",
          colorIcon: "text-primary",
          type: 0,
        },
        {
          title: "Total leads with number",
          icon: "PhoneOutgoingIcon",
          total: 0,
          borderColor: "primary",
          colorIcon: "text-primary",
          type: 1,
        },
        {
          title: "Total leads without number",
          icon: "PhoneMissedIcon",
          total: 0,
          borderColor: "danger",
          colorIcon: "text-danger",
          type: 2,
        },
      ],
      showLeadsDetailModal: false,
    };
  },
  computed: {},
  created() {
    this.getCountLeadsWithAndWithoutMobile();
  },
  mounted() {
    this.toggleModal("leads-modal");
  },
  methods: {
    async getCountLeadsWithAndWithoutMobile() {
      try {
        this.addPreloader();
        const params = {
          date_from: this.dateFrom,
          date_to: this.dateTo,
          method: "count_leads_with_and_without_mobile",
          year: this.sendYear,
          month: this.sendMonth,
          range: this.sendRange,
          meta_ad_id: this.metaAdId,
        };
        const { data } = await ComercialReportService.showComercialReport(
          params,
          this.flyerId
        );
        this.leads[0].total = data?.[0]?.total_leads ?? 0;
        this.leads[1].total = data?.[0]?.with ?? 0;
        this.leads[2].total = data?.[0]?.without ?? 0;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    openModalDetail(type) {
      this.showLeadsDetailModal = true;
      this.type = type;
      this.method = "get_leads_with_mobile_filter";
    },
    validateLead(lead) {
      return lead.total > 0 && lead.type !== 0;
    },
  },
};
</script>
  <style lang="scss">
.card-leads-cr {
  display: flex;
  gap: 1rem;
  .card-lead-item {
    width: 100%;
    .card-lead-item__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.2rem;
      padding: 1.5rem 1rem;
      .card-lead-item__header-title {
        text-transform: uppercase;
      }
    }
    .card-lead-item__body {
      padding: 0.5rem 1rem;
    }
  }
}
</style>
  