<template>
  <div class="card-payroll-report">
    <div
      style="min-width: 280px"
      class="d-flex justify-content-end align-items-center mb-1 px-1"
    >
      <div
        v-if="selectedType === 2"
        class="absolute-linker pt-2 pb-1"
        :class="{ dark: isDarkSkin }"
      >
        <div
          class="d-flex justify-content-between align-items-center h-full px-1"
          style="font-size: 15px; font-weight: bold"
        >
          <feather-icon
            icon="ChevronLeftIcon"
            size="20"
            class="cursor-pointer"
            @click="prev"
          />
          <span> {{ titleMonthYear }} </span>
          <feather-icon
            icon="ChevronRightIcon"
            size="20"
            class="cursor-pointer"
            @click="next"
          />
        </div>
      </div>
      <v-select
        v-if="selectedType === 1"
        class="mr-1"
        v-model="dataYear"
        :options="optionsYears"
        :reduce="(option) => option.id"
        :clearable="false"
        label="name"
        style="width: 150px"
        @input="refresh()"
      ></v-select>
      <b-form-radio-group
        v-if="!showDetails"
        id="btn-radios-1"
        class="mr-1"
        v-model="selectedType"
        :options="optionsType"
        button-variant="outline-primary"
        size="md"
        name="radio-btn-outline"
        buttons
        @change="handleTypeSearch()"
      ></b-form-radio-group>
      <b-button
        v-if="showDetails"
        variant="primary"
        class="d-flex justify-content-center align-items-center ml-1"
        size="sm"
        @click="returnYear()"
      >
        <tabler-icon icon="ArrowBackIcon" size="24" />
        <span style="padding-left: 3px">Back</span>
      </b-button>
    </div>

    <b-table
      id="expense-payroll-table-ceo"
      ref="expense-payroll-table-ceo"
      slot="table"
      class="position-relative table-new-customization"
      empty-text="No matching records found"
      sticky-header="70vh"
      primary-key="id"
      responsive="md"
      show-empty
      no-border-collapse
      no-provider-filtering
      :busy.sync="isBusy"
      :fields="visibleFields"
      :items="myProvider"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(program_id)="{ item }">
        <div class="d-flex justify-content-center align-items-center">
          <img
            v-b-tooltip.hover.top="item.name"
            :src="`/assets/${item.icons}`"
            alt="Logo"
            height="30"
            width="30"
          />
        </div>
      </template>
      <template #cell(employees)="{ item }">
        <div class="d-flex justify-content-center align-items-center">
          <b-badge variant="primary" class="ml-1">
            {{ item.counter_expense }}
          </b-badge>
        </div>
      </template>
      <template #cell(expense)="data">
        <span
          :class="{
            'text-success': data.item.expense > 0,
            'text-secondary': data.item.expense <= 0,
          }"
        >
          {{ data.item.expense | formatCurrency }}
        </span>
      </template>
      <template #cell(expense_soles)="data">
        <span
          :class="{
            'text-success': data.item.expense_soles > 0,
            'text-secondary': data.item.expense_soles <= 0,
          }"
        >
          {{ data.item.expense_soles | formatCurrencyPEN }}
        </span>
      </template>
      <template #cell(details)="data">
        <div class="d-flex align-items-center justify-content-center">
          <b-badge
            :variant="
              data.item.expense_soles != '0.00'
                ? 'light-primary'
                : 'light-secondary'
            "
            class="cursor-pointer"
            @click="
              data.item.expense_soles != '0.00' &&
                openModalDetailPayroll(data.item)
            "
          >
            Open Detail
            <feather-icon icon="ArrowUpRightIcon" size="20" />
          </b-badge>
        </div>
      </template>
      <template #bottom-row>
        <b-th>
          <b-badge
            variant="primary"
            class="w-100 text-center"
            style="
              font-size: 14px;
              font-weight: 600;
              padding-bottom: 7px;
              padding-top: 7px;
            "
          >
            TOTAL
          </b-badge>
        </b-th>
        <b-th>
          <b-badge
            variant="success"
            class="w-100 text-center text-black"
            style="
              font-size: 14px;
              font-weight: 600;
              padding-bottom: 7px;
              padding-top: 7px;
            "
          >
            {{ totalEmployees }}
          </b-badge>
        </b-th>
        <b-th>
          <b-badge
            variant="success"
            class="w-100 text-center text-black"
            style="
              font-size: 14px;
              font-weight: 600;
              padding-bottom: 7px;
              padding-top: 7px;
            "
          >
            {{ totalExpense | formatCurrency }}
          </b-badge>
        </b-th>
        <b-th>
          <b-badge
            variant="success"
            class="w-100 text-center text-black"
            style="
              font-size: 14px;
              font-weight: 600;
              padding-bottom: 7px;
              padding-top: 7px;
            "
          >
            {{ totalExpenseSoles | formatCurrencyPEN }}
          </b-badge>
        </b-th>
        <b-th class="fill-bottom-row" />
      </template>
    </b-table>
    <modal-detail-payroll
      v-if="showMetaPayrollModal"
      :send-year="dataYear"
      :send-month="dataMonth"
      :send-department="sendDepartment"
      :program="program"
      :title-month-year="titleMonthYear"
      @close="showMetaPayrollModal = false"
    />
  </div>
</template>
<script>
import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service";
import ModalDetailPayroll from "@/views/ceo/dashboard/modals/expenses/ModalDetailPayroll.vue";
export default {
  name: "CardPayrollReport",
  components: {
    ModalDetailPayroll,
  },
  props: {
    sendYear: {
      type: Number,
      required: true,
    },
    sendMonth: {
      type: Number,
      required: true,
    },
    sendDepartment: {
      type: Array,
      required: false,
      default: [],
    },
  },
  data() {
    return {
      isBusy: false,
      program: null,
      fields: [
        {
          key: "program_id",
          label: "Department",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: false,
          visible: false,
        },
        {
          key: "month_name",
          label: "Month",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: false,
          visible: false,
        },
        {
          key: "employees",
          label: "Employees",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: false,
          visible: true,
        },
        {
          key: "expense",
          label: "Expense ($)",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: false,
          visible: true,
        },
        {
          key: "expense_soles",
          label: "Expense (S/.)",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: false,
          visible: true,
        },
        {
          key: "details",
          label: "Details",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: false,
          visible: true,
        },
      ],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      dataYear: null,
      dataMonth: null,
      showMetaPayrollModal: false,
      totalExpense: 0,
      totalExpenseSoles: 0,
      totalEmployees: 0,
      selectedType: 2, //1: year, 2: month
      optionsType: [
        { text: "Year", value: 1 },
        { text: "Month", value: 2 },
      ],
      showDetails: false,
      optionsYears: [],
      totals: {},
    };
  },
  computed: {
    titleMonthYear() {
      return this.selectedType === 1
        ? `${this.dataYear}`
        : `${this.months[this.dataMonth - 1]} ${this.dataYear}`;
    },
    visibleFields() {
      if (this.selectedType === 1) {
        this.getFieldsByKeys("month_name").visible = true;
        this.getFieldsByKeys("program_id").visible = false;
      } else {
        this.getFieldsByKeys("month_name").visible = false;
        this.getFieldsByKeys("program_id").visible = true;
      }
      return this.fields.filter((field) => field.visible);
    },
  },
  created() {
    this.initMonthYear();
    this.generateYears();
  },
  mounted() {},
  methods: {
    async myProvider() {
      try {
        const params = {
          year: this.dataYear,
          month: this.dataMonth,
          type_expense: "payroll",
          type: this.selectedType,
        };
        const { data } = await DashboardServive.getDetailsExpenses(params);

        this.totalExpense = data?.[0]?.total_expense || 0;
        this.totalExpenseSoles = data?.[0]?.total_expense_soles || 0;
        this.totalEmployees = data?.[0]?.total_counter_expense || 0;
        return data;
      } catch (e) {
        this.showErrorSwal(e);
        console.log("error ceoooo", e);
      }
    },
    initMonthYear() {
      this.dataMonth = this.sendMonth;
      this.dataYear = this.sendYear;
    },
    async next() {
      if (this.dataMonth < 12) {
        this.dataMonth++;
      } else {
        this.dataMonth = 1;
        this.dataYear++;
      }
      await this.refresh();
    },
    async prev() {
      if (this.dataMonth > 1) {
        this.dataMonth--;
      } else {
        this.dataMonth = 12;
        this.dataYear--;
      }
      await this.refresh();
    },
    async refresh() {
      this.$emit("newTitleModal", this.titleMonthYear);
      await this.$refs["expense-payroll-table-ceo"].refresh();
    },
    openModalDetailPayroll(program) {
      console.log("program", program);
      this.program = program;
      if (program.month) {
        this.dataMonth = program.month;
      }
      this.showMetaPayrollModal = true;
    },
    getFieldsByKeys(keys) {
      return this.fields.find((field) => field.key === keys);
    },
    async handleTypeSearch() {
      await this.initMonthYear();
      await this.refresh();
    },
    returnYear() {
      this.selectedType = 1;
      this.showDetails = false;
      this.refresh();
    },
    generateYears() {
      const startYear = 2022;
      const currentYear = new Date().getFullYear();
      this.optionsYears = [];
      for (let year = currentYear; year >= startYear; year--) {
        this.optionsYears.push({ id: year, name: year });
      }
    },
  },
};
</script>
<style lang="scss">
.card-payroll-report {
  #expense-payroll-table-ceo {
    .programs_image {
      background-color: #e9e9e9;
      padding: 0.2rem;
      border-radius: 10px;
      width: 3.2rem;
      height: 3.2rem;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .rounded {
      border-radius: 0.357rem !important;
    }
    .b-table-bottom-row {
      position: sticky;
      bottom: 0;
      z-index: 1;

      th:nth-child(1) {
        border-radius: 0px 0px 0px 15px !important;
        margin-left: 5px !important;
        background: #0090e7 !important;
      }

      th:nth-child(2),
      th:nth-child(3),
      th:nth-child(4) {
        font-size: 16px;
        padding-top: 10px;
        padding-bottom: 10px;
        background: #0090e7 !important;
      }

      th:nth-child(5) {
        border-radius: 0px 0px 15px 0px !important;
        margin-left: 10px !important;
        background: #0090e7 !important;
      }
    }
    .fill-bottom-row {
      background-color: #ffffff !important;
    }
  }
}
</style>