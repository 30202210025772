<template>
  <b-card
    ref="bCarSalesPerMonth"
    class="b_card_content dashboard__card"
    :class="isDarkSkin ? 'dashboard__card--dark' : ''"
  >
    <b-card-text>
      <b-row>
        <b-col cols="6">
          <div class="d-flex mb-1">
            <b-avatar rounded="sm" size="4em" class="b_avatar_color">
              <div class="ico_content">
                <b-img :src="icoSalesCard" style="width: 85%; height: 85%" />
              </div>
            </b-avatar>

            <div class="ml-1 title_content">
              <span :style="titleCard()">{{ tabSelected }} per month</span>
            </div>
          </div>
        </b-col>
        <b-col cols="6" class="text-right">
          <b-form-select
            v-if="tabSelected == 'Sales' || tabSelected == 'Incomes'"
            v-model="selectProgram"
            style="margin-top: 4px; width: 100%"
            :class="['ml-1', isDarkSkin ? 'select_border_dark' : '']"
            @change="getTab(tabSelected)"
          >
            <template #first>
              <b-form-select-option
                v-for="(item, index) in listPrograms"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </b-form-select-option>
            </template>
          </b-form-select>
          <b-form-select
            v-else
            v-model="selectExpense"
            style="margin-top: 4px; width: 100%"
            :class="['ml-1', isDarkSkin ? 'select_border_dark' : '']"
            @change="getTab(tabSelected)"
          >
            <template #first>
              <b-form-select-option
                v-for="(item, index) in expenseOptions"
                :key="index"
                :value="item.name"
              >
                {{ item.value }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col
          class="text-right"
          style="font-size: 20px; font-weight: 600; padding: 0; margin-top: 5px"
        >
          <b-button
            v-for="(value, index) in listTabs"
            :key="index"
            size="md"
            :variant="tabSelected == value ? 'primary' : 'light'"
            class="btn_type"
            @click="getTab(value)"
          >
            {{ value }}
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <div style="height: 310px">
            <canvas id="chartJSContainerAds" class="mt-2" width="600" />

            <div class="text-right py-1">
              <span class="mr-1">
                <b-avatar
                  rounded="sm"
                  size="2em"
                  style="background: rgba(0, 221, 49, 1)"
                  ><div class="ico_content"
                /></b-avatar>
                {{ yearBefore }}
              </span>

              <span>
                <b-avatar
                  rounded="sm"
                  size="2em"
                  style="background: rgba(22, 84, 204, 1)"
                  ><div class="ico_content"
                /></b-avatar>
                {{ yearNow }}
              </span>
            </div>
          </div>

          <div
            class="loading_sales"
            :class="[
              statusSales ? '' : 'd-none',
              isDarkSkin ? 'loading_sales--dark' : '',
            ]"
          >
            <b-spinner variant="primary" label="Text Centered" />
          </div>
        </b-col>
      </b-row>
    </b-card-text>
  </b-card>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import Chart from "chart.js";
import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service.js";

export default {
  components: {
    VueApexCharts,
  },
  props: {
    selectYear: {
      type: Number,
      default: null,
      required: false,
    },
    listPrograms: {
      type: Array,
      required: false,
      default: [],
    },
  },
  data() {
    return {
      icoSalesCard: require("@/assets/images/icons/dashboard-ceo/ico_sales_card.png"),
      sizeScreen: 0,
      selectProgram: null,
      listTabs: ["Sales", "Expenses", "Incomes"],
      tabSelected: "Sales",
      yearNow: 2023,
      yearBefore: 2022,
      selectExpense: null,
      iniChart: null,
      chartX: null,

      statusSales: false,
      expenseOptions: [
        {
          name: null,
          value: "Total expenses",
        },
        {
          name: "Payroll",
          value: "Payroll",
        },
        {
          name: "Inversion Creative",
          value: "Inversion Creative",
        },
        {
          name: "Charge backs",
          value: "Charge Backs",
        },
        {
          name: "Void/Refund",
          value: "Void/Refund",
        },
      ],
    };
  },
  watch: {
    selectYear(value) {
      if (this.tabSelected == "Incomes") {
        this.getIncomes();
      } else if (this.tabSelected == "Expenses") {
        this.getReport();
      } else if (this.tabSelected == "Sales") {
        this.getSalesGraphic();
      }
    },
  },
  mounted() {
    // responsive font
    // window.addEventListener('resize', this.checkScreenSize);

    setTimeout(() => {
      // this.checkScreenSize();
    }, 500);

    this.getSalesGraphic();
  },
  methods: {
    async checkScreenSize() {
      setTimeout(() => {
        const componentSalesMonth = this.$refs.bCarSalesPerMonth;
        if (componentSalesMonth && componentSalesMonth.clientWidth) {
          this.sizeScreen =
            componentSalesMonth.clientWidth > 0
              ? componentSalesMonth.clientWidth
              : 0;
          // console.log(componentSalesMonth.clientWidth);
        } else {
          // console.error('Unable to get clientWidth. The component might not be ready yet.');
        }
      }, 500);
    },
    titleCard() {
      let size = "18px";
      if (this.sizeScreen >= 800 && this.sizeScreen < 1000) {
        size = "18px";
      } else if (this.sizeScreen >= 274 && this.sizeScreen < 800) {
        size = "13px";
      } else if (this.sizeScreen < 274) {
        size = "16px";
      }
      return `display:block;font-size:${size};font-weight:600;width:100%`;
    },
    async getReport() {
      try {
        this.statusSales = true;
        const params = {
          type_expense: this.selectExpense,
          year: this.selectYear,
        };
        const { data } = await DashboardServive.getAnnualSummary(params);
        this.yearNow = data[0].year;
        this.yearBefore = data[1].year;
        this.createNewChart(data, "expenses");
        this.statusSales = false;
      } catch (error) {
        this.statusSales = false;
        console.log(("Sales per month error: ", error));
        this.showErrorSwal(error);
      }
    },
    async getIncomes() {
      try {
        this.statusSales = true;
        const params = {
          year: this.selectYear,
          program_id: this.selectProgram,
        };
        const { data } = await DashboardServive.getAnnualIncomesSummary(params);
        this.yearNow = data[0].year;
        this.yearBefore = data[1].year;
        this.createNewChart(data, "incomes");
        this.statusSales = false;
      } catch (error) {
        this.statusSales = false;
        console.log(("Sales per month error: ", error));
        this.showErrorSwal(error);
      }
    },
    async getSalesGraphic() {
      try {
        this.statusSales = true;
        const params = {
          year: this.selectYear,
          program_id: this.selectProgram,
        };
        let data = [];

        data = await DashboardServive.getSalesGraphicIndex(params);
        this.yearNow = data[0].year;
        this.yearBefore = data[1].year;
        this.createNewChart(data, "sales");
        this.statusSales = false;
      } catch (error) {
        console.log(("Sales per month error: ", error));
        this.showErrorSwal(error);
        this.statusSales = false;
      }
    },
    createNewChart(data, type) {
      const chartContainer = document.getElementById("chartJSContainerAds");
      if (chartContainer) {
        this.iniChart = chartContainer.getContext("2d");
        const gradient = this.iniChart.createLinearGradient(0, 0, 0, 400);
        gradient.addColorStop(0, "rgba(255,255,255, 0.01)");
        gradient.addColorStop(1, "rgba(255,255,255, 0.01)");
        const border_colorz = [
          "rgba(22, 84, 204, 1)",
          "rgba(0, 221, 49, 1)",
          "rgba(190, 190, 190, 1)",
        ];
        if (Array.isArray(data)) {
          data.forEach((element, index) => {
            element.label = element.year;
            element.data =
              type == "sales"
                ? element.data_sales
                : type == "incomes"
                ? element.array_incomes
                : type == "expenses"
                ? element.array_expenses
                : "";
            element.backgroundColor = gradient;
            element.tension = 0.1;
            element.fill = true;
            element.borderColor = border_colorz[index];
            element.pointRadius = 5;
          });
        }
        const vm = this;
        const options = {
          type: "line",
          data: {
            labels: [
              "Ene",
              "Feb",
              "Mar",
              "Abr",
              "May",
              "Jun",
              "Jul",
              "Ago",
              "Set",
              "Oct",
              "Nov",
              "Dic",
            ],
            datasets: data,
          },
          options: {
            cutoutPercentage: 10,
            elements: {
              arc: {
                borderWidth: 5,
                borderRadius: 0,
              },
            },
            legend: {
              display: false,
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    stepSize: 1000,
                    callback(value, index, ticks) {
                      if (vm.tabSelected != "Sales") {
                        return `$ ${vm.formatterMoney(value)}`;
                      }
                      return `${vm.formatterMoney(value)}`;
                    },
                  },
                },
              ],
            },
            maintainAspectRatio: false,
            tooltips: {
              callbacks: {
                label(t, d) {
                  if (vm.tabSelected != "Sales") {
                    return `${
                      d.datasets[t.datasetIndex].year
                    }: $ ${vm.formatterMoney(t.value)}`;
                  }
                  return `${d.datasets[t.datasetIndex].year}: ${t.value}`;
                },
              },
            },
          },
        };
        this.chartX = new Chart(this.iniChart, options);
      } else {
        console.error("Chart container not found");
      }
    },
    getTab(title) {
      this.chartX.destroy();
      this.tabSelected = title;

      if (title == "Incomes") {
        this.getIncomes();
      } else if (title == "Expenses") {
        this.getReport();
      } else if (title == "Sales") {
        this.getSalesGraphic();
      }
    },
    formatterMoney(var_num) {
      return Number(var_num).toLocaleString("en-US", {
        style: "decimal",
        maximumFractionDigits: 10,
        minimumFractionDigits: 2,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.b_card_content {
  border-radius: 18px;
  height: 520px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px #00000026 !important;

  &--dark {
    background: rgba(17, 17, 19, 1);
    box-shadow: 0px 0px 15px 0px #ffffff26 !important;
  }

  .btn_type {
    margin-left: 5px;
  }

  .title_content {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  .b_avatar_color {
    background: rgba(167, 197, 255, 1) !important;
  }

  .select_border_dark {
    color: #1654cc;
    border-color: #1654cc !important;
  }

  .loading_sales {
    height: 100%;
    width: 100%;
    position: absolute;
    background: #eee;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    &--dark {
      background: rgba(17, 17, 19, 1);
    }
  }
}
</style>
