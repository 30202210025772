<template>
  <div :class="typeExpense === 'AMG' ? 'type-amg' : 'type-client'">
    <filter-slot
      :filter="[]"
      class="filter-slot-new-customization"
      :filter-principal="filterPrincipal"
      :total-rows="paginate.total"
      :paginate="paginate"
      :start-page="paginate.from"
      :to-page="paginate.to"
      :top-paginate="false"
      :noVisiblePrincipalFilter="true"
      @reload="$refs['detailsExpenses'].refresh()"
    >
      <template #table>
        <b-table
          id="detailsExpenses"
          ref="detailsExpenses"
          no-border-collapse
          class="position-relative table-new-customization"
          :fields="visibleFields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="getExpensesByCard"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
          table-class="text-nowrap"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(module)="{ item }">
            <div class="d-flex justify-content-center align-items-center">
              <img
                v-b-tooltip.hover.left="item.module_name"
                :src="`/assets/${item.icons}`"
                alt="Logo"
                height="30"
                width="30"
              />
            </div>
          </template>
          <template #cell(status)="{ item }">
            <span :class="colorTextStatusCheck(item)" style="font-weight: bold">
              {{ statusCheckText(item) }}
            </span>
            <div class="d-flex justify-content-center" v-if="isRejected">
              <span>{{ item.updated_at | myGlobalDay }}</span>
              <feather-icon
                v-if="item.observation"
                icon="InfoIcon"
                v-b-tooltip.hover.top="item.observation"
                variant="info"
                class="text-info"
                style="margin-left: 3px"
              />
            </div>
            <div class="d-flex justify-content-center" v-else>
              <span>{{ item.check_updated_at | myGlobalDay }}</span>
            </div>
          </template>
          <template #cell(type_pending)="{ item }">
            <b-badge
              class="px-1"
              :class="item.type == 'CLIENT' ? 'clickable' : ''"
              :variant="item.type == 'CLIENT' ? 'primary' : 'info'"
              @click="item.type == 'CLIENT' ? openClientExpenses(item) : false"
            >
              {{ item.type }}
              {{ item.totalClient > 0 ? `(${item.totalClient})` : null }}
            </b-badge>
          </template>
          <template #cell(client)="{ item }">
            <b-badge
              variant="light-primary"
              class="total-client"
              @click="openClientExpenses(item)"
            >
              <span>{{ item.totalClient }}</span>
            </b-badge>
          </template>
          <template #cell(cardholdername)="{ item }">
            <template v-if="item.method == 'CREDIT_CARD'">
              <div class="row">
                <div class="col-4 p-0 justify-content-end d-flex">
                  <type-card :type_card="item.type_card" />
                </div>
                <div
                  class="col-8 p-0 d-flex justify-content-center align-items-center"
                >
                  <strong>XXXX-{{ item.cardnumber.slice(-4) }}</strong>
                </div>
              </div>
            </template>
            <template v-else>
              <b-badge variant="primary">{{ item.method }}</b-badge>
            </template>
          </template>
          <template #cell(check_number)="{ item }">
            <b-row>
              <b-col cols="12">
                <strong>#: </strong>
                <span>{{ item.check_number || "-" }}</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12"
                ><strong>Show: </strong
                ><a
                  v-if="item.check_path"
                  :href="item.check_path"
                  target="_blank"
                  :title="item.file"
                >
                  <amg-icon
                    class="cursor-pointer text-danger"
                    icon="FilePdfIcon"
                    size="18"
                  />
                </a>
                <span v-else>-</span>
              </b-col>
            </b-row>
          </template>
          <template #cell(details)="{ item }">
            <b-row>
              <b-col cols="2">
                <strong>{{
                  method == "CREDIT_CARD" ? "Vendor" : "Pay to"
                }}</strong>
              </b-col>
              <b-col :cols="isPending ? 9 : 10">{{ item.vendor }}</b-col>
              <b-col v-if="isPending" cols="1">
                <feather-icon
                  @click="openVendorConceptEditorModal(item, 'vendor')"
                  icon="EditIcon"
                  v-b-tooltip.hover.right.v-warning="'Edit vendor'"
                  class="text-warning clickable"
                />
              </b-col>
              <div class="w-100" />
              <b-col cols="2"
                ><strong>{{
                  method == "CREDIT_CARD" ? "Concept" : "Memo"
                }}</strong></b-col
              >
              <b-col :cols="isPending ? 9 : 10">{{ item.concept }}</b-col>
              <b-col v-if="isPending" cols="1">
                <feather-icon
                  @click="openVendorConceptEditorModal(item, 'concept')"
                  icon="EditIcon"
                  v-b-tooltip.hover.right.v-warning="'Edit concept'"
                  class="text-warning clickable"
                />
              </b-col>
              <div class="w-100" />
              <b-col cols="2" v-if="method == 'CHECK'"
                ><strong>Address</strong></b-col
              >
              <b-col v-if="method == 'CHECK'">
                <span v-html="formatAddress(item)"></span>
              </b-col>
              <div class="w-100" />
              <b-col v-if="method == 'CHECK'" cols="2"
                ><strong>Date</strong></b-col
              >
              <b-col v-if="method == 'CHECK'">{{
                item.expense_date | myGlobal
              }}</b-col>
              <div class="w-100" />
              <b-col v-if="item.reference_number" cols="2">
                <strong>Ref. #</strong>
              </b-col>
              <b-col v-if="item.reference_number">{{
                item.reference_number
              }}</b-col>
              <div class="w-100" />
              <b-col v-if="item.url_file" cols="2">
                <strong>{{ method == "CHECK" ? "File" : "Invoice" }}</strong>
              </b-col>
              <b-col v-if="item.url_file">
                <a :href="item.url_file" target="_blank" :title="item.file">
                  <amg-icon
                    class="cursor-pointer text-danger"
                    icon="FilePdfIcon"
                    size="18"
                  />
                </a>
              </b-col>
            </b-row>
          </template>
          <template #cell(amount)="{ item }">
            {{ item.amount | formatMoney }}
            <feather-icon
              v-if="isPending"
              @click="openAmountEditorModal(item)"
              icon="EditIcon"
              v-b-tooltip.hover.top.v-warning="'Edit amount'"
              class="text-warning ml-1 clickable"
            />
          </template>
          <template #cell(actions)="{ item }">
            <tabler-icon
              icon="ThumbUpIcon"
              class="text-success clickable"
              size="22"
              v-b-tooltip.hover.top="'Confirm'"
              @click="changeExpenseStatus(item, 'APPROVED')"
            />
            <tabler-icon
              icon="ThumbDownIcon"
              class="text-danger clickable ml-1"
              size="22"
              v-b-tooltip.hover.top="'Reject'"
              @click="openObservationsModal(item)"
            />
            <tabler-icon
              v-if="method == 'CREDIT_CARD'"
              :class="ticketStatusColor(item)"
              v-b-tooltip.hover.top="ticketStatusName(item)"
              icon="TicketIcon"
              class="cursor-pointer text-weight-bolder ml-1"
              style="margin-left: 5px"
              size="18"
              @click="selectCustomerTicket(item)"
            />
          </template>
          <template #cell(expense_date)="{ item }">
            {{ item.expense_date | myGlobal }}
          </template>
          <template #cell(updated_at)="{ item }">
            <div class="d-flex justify-content-center">
              <span>{{ item.updated_at | myGlobalDay }}</span>
              <feather-icon
                v-if="item.observation"
                icon="InfoIcon"
                v-b-tooltip.hover.top="item.observation"
                variant="info"
                class="text-info"
                style="margin-left: 3px"
              />
            </div>
          </template>
          <template #cell(created_at)="{ item }">
            <div>
              <div>
                {{ item.created_by_id == 0 ? "System" : item.created_by }}
              </div>
              <div>{{ item.created_at | myGlobalDay }}</div>
            </div>
          </template>
          <template #cell(tracking)="{ item }">
            <div>
              <feather-icon
                class="text-info cursor-pointer"
                v-b-tooltip.hover.right="'Tracking'"
                @click="openChecksTrackingModal(item)"
                size="16"
                icon="ListIcon"
              />
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>

    <TransactionsByCardModal
      v-if="showTransactionsByCardModal"
      :expense="selectedItem"
      @close="showTransactionsByCardModal = false"
      @approve="approveExpense"
    />
    <ObservationsModal
      v-if="showObservationsModal"
      :expenseData="selectedItem"
      @close="showObservationsModal = false"
      @save="saveObservations"
    />
    <client-expense-request
      v-if="clientExpenseModal.show"
      :expenseDepartment="clientExpenseModal.expenseDepartment"
      @close="clientExpenseModal.show = false"
    />
    <VendorConceptEditorModal
      v-if="showVendorConceptEditorModal"
      :vcTarget="vcTarget"
      @closing="showVendorConceptEditorModal = false"
      @refresh="refresh"
    />
    <ModalCreateCustomerTicket
      v-if="showModalCreateCustomerTicket"
      :customer-ticket="customerTicketSelected"
      :selected-expense="selectedExpense"
      :set-motive="103"
      @hidden="showModalCreateCustomerTicket = false"
      @saved="$refs['detailsExpenses'].refresh()"
    />
    <ModalViewTicketCustomerDetails
      v-if="showModalViewTicketCustomerDetails"
      :customer-ticket="customerTicketSelected"
      @hidden="showModalViewTicketCustomerDetails = false"
    />
    <AmountEditorModal
      v-if="showAmountEditorModal"
      :aTarget="aTarget"
      @closing="showAmountEditorModal = false"
      @refresh="refresh"
    />
    <ChecksTrackingModal
      v-if="showChecksTrackingModal"
      :dataRow="selectedCheck"
      @close="showChecksTrackingModal = false"
    ></ChecksTrackingModal>
  </div>
</template>
<script>
import DashboardService from "@/views/ceo/service-dashboard/dashboard.service";
import Fields from "@/views/ceo/dashboard/modals/expenses-request/data/card-expenses-request.fields.js";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import { mapGetters, mapActions } from "vuex";
import { modifyLabel, modifyVisibility } from "@/helpers/fields-table";
import ObservationsModal from "@/views/ceo/dashboard/modals/expenses-request/ObservationsModal.vue";
import ClientExpenseRequest from "@/views/ceo/dashboard/modals/expenses-request/ClientExpenseRequest.vue";
import TypeCard from "@/views/commons/card/TypeCard.vue";
import TransactionsByCardModal from "@/views/ceo/dashboard/modals/expenses-request/TransactionsByCardModal.vue";
import VendorConceptEditorModal from "@/views/ceo/dashboard/modals/expenses-request/VendorConceptEditorModal.vue";
import ModalCreateCustomerTicket from "@/views/commons/components/customer-tickets/components/modal/ModalCreateCustomerTicket.vue";
import ModalViewTicketCustomerDetails from "@/views/commons/components/customer-tickets/components/modal/ModalViewTicketCustomerDetails.vue";
import AmountEditorModal from "@/views/ceo/dashboard/modals/expenses-request/AmountEditorModal.vue";
import ChecksTrackingModal from "@/views/commons/expenses/components/Modal/ChecksTrackingModal.vue";
export default {
  name: "CardExpensesRequest",
  components: {
    ObservationsModal,
    StatusAccount,
    ClientExpenseRequest,
    TypeCard,
    TransactionsByCardModal,
    VendorConceptEditorModal,
    ModalCreateCustomerTicket,
    ModalViewTicketCustomerDetails,
    AmountEditorModal,
    ChecksTrackingModal,
  },
  props: {
    typeExpense: {
      type: String,
      required: false,
    },
    cardId: {
      type: Number,
      required: false,
    },
    isRejected: {
      type: Boolean,
      default: false,
    },
    isInProcess: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showChecksTrackingModal: false,
      selectedExpense: null,
      showModalViewTicketCustomerDetails: false,
      expenseId: null,
      customerId: null,
      customerTicketSelected: {},
      showModalCreateCustomerTicket: false,
      aTarget: null,
      vcTarget: null,
      showAmountEditorModal: false,
      showVendorConceptEditorModal: false,
      expenses: [],
      fields: Fields,
      showObservationsModal: false,
      showTransactionsByCardModal: false,
      selectedItem: null,
      paginate: {
        perPage: 10,
        currentPage: 1,
        total: 0,
        from: 0,
        to: 0,
      },
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      isBusy: false,
      clientExpenseModal: {
        show: false,
        expenseDepartment: null,
      },
      selectedCheck: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      method: "ExpensesRequest/G_METHOD_EXPENSE",
    }),
    isPending() {
      return !(this.isRejected || this.isInProcess);
    },
    visibleFields() {
      modifyVisibility(this.fields, "client", this.typeExpense == "CLIENT");
      modifyVisibility(this.fields, "status", this.method != "CREDIT_CARD");
      modifyVisibility(this.fields, "actions", this.method == "CREDIT_CARD");
      modifyVisibility(
        this.fields,
        "actions",
        !this.isRejected && !this.isInProcess
      );
      modifyVisibility(this.fields, "type_pending", this.method == "CREDIT_CARD" && this.typeExpense == null);
      modifyVisibility(
        this.fields,
        "cardholdername",
        this.isRejected && this.method == "CREDIT_CARD"
      );
      modifyVisibility(
        this.fields,
        "updated_at",
        this.isRejected && this.method == "CREDIT_CARD"
      );
      // modifyVisibility(this.fields, "type_pending", !this.isRejected);
      modifyLabel(
        this.fields,
        "details",
        this.method == "CREDIT_CARD" ? "TRANSACTION DETAILS" : "CHECK DETAILS"
      );
      modifyLabel(
        this.fields,
        "updated_at",
        !this.isRejected ? "CONFIRMED AT" : "REJECTED AT"
      );
      modifyVisibility(this.fields, "expense_date", this.method != "CHECK");
      modifyVisibility(this.fields, "check_number", this.method == "CHECK");
      modifyVisibility(this.fields, "tracking", this.method == "CHECK");
      if (this.method == "CHECK") {
        modifyLabel(this.fields, "created_at", "REQUESTED BY");
        modifyLabel(this.fields, "details", "CHECK DETAILS");
      } else if (this.method == "E-CHECK") {
        modifyLabel(this.fields, "details", "E-CHECK DETAILS");
      }
      return this.fields.filter((item) => item.visible);
    },
    totales() {
      return {
        total:
          this.expenses.length > 0 ? Number(this.expenses[0].totalAmount) : 0,
        subtotal: this.expenses.reduce((a, b) => a + Number(b.amount), 0),
      };
    },
    isAmg() {
      return this.typeExpense === "AMG";
    },
    getTab() {
      let status;
      if (this.isInProcess) {
        status = null;
      } else if (this.isRejected) {
        status = 3;
      } else {
        status = 2;
      }
      return status;
    },
    getTypeExpense() {
      if (this.isRejected || this.isInProcess) {
        return this.typeExpense;
      } else {
        return null;
      }
    },
  },
  methods: {
    openChecksTrackingModal(item) {
      this.showChecksTrackingModal = true;
      this.selectedCheck = item;
    },
    statusCheckText(item) {
      switch (item.status_check) {
        case "SEND TO CORRESPONDENCE":
          return "IN CORRESPONDENCE";
        case "SEND TO COURIER":
          return "IN COURIER";
        default:
          return item.status_check;
      }
    },
    colorTextStatusCheck(item) {
      switch (item.status) {
        case "SEND TO COURIER":
          return "text-primary";
        case "SEND TO CORRESPONDENCE":
          return "text-info";
        default:
          return "text-warning";
      }
    },
    ticketStatusName(item) {
      if (!item.ticket_code) return "Create Ticket";
      if (item.customer_status_id == 9) return "Completed";
      if (item.customer_status_id == 3) return "In Process";
      return "Not possible";
    },
    ticketStatusColor(item) {
      if (!item.ticket_code) return "text-secondary";
      if (item.customer_status_id == 9) return "text-success";
      if (item.customer_status_id == 3) return "text-warning";
      return "text-danger";
    },
    selectCustomerTicket(item) {
      if (item.ticket_code) {
        this.openModalViewTicketCustomerDetails(item);
      } else {
        this.openModalCreateCustomerTicket(item);
      }
    },
    async openModalViewTicketCustomerDetails(item) {
      this.selectedExpense = { ...item };
      const data = await DashboardService.getCustomerTicket({
        name_text: item.ticket_code,
        user_id: 1,
      });
      if (data.length > 0) {
        this.customerTicketSelected = data[0];
        this.showModalViewTicketCustomerDetails = true;
      } else {
        this.$bvToast.toast("Ticket not found", {
          title: "Error",
          variant: "danger",
        });
      }
    },
    async openModalCreateCustomerTicket(item) {
      this.selectedExpense = { ...item };
      let date = "";
      let formatedAmount = "";
      if (item.expense_date) {
        const parsedDate = new Date(item.expense_date);
        const mo = parsedDate.getMonth() + 1;
        const da = parsedDate.getDate();
        const an = parsedDate.getFullYear();
        date = `${mo}/${da}/${an}`;
      }
      if (item.amount) {
        formatedAmount = new Intl.NumberFormat("en-US", {
          currency: "USD",
          style: "currency",
        }).format(item.amount);
      }
      this.customerTicketSelected = {
        text: `VENDOR: ${item.vendor}\nCONCEPT: ${item.concept}\nAMOUNT: ${formatedAmount}\nDATE: ${date}`,
      };
      this.showModalCreateCustomerTicket = true;
    },
    openVendorConceptEditorModal(item, type) {
      this.showVendorConceptEditorModal = true;
      this.vcTarget = {
        id: type === "vendor" ? item.vendor_id : item.concept_id,
        type: type,
        description: type === "vendor" ? item.vendor : item.concept,
      };
    },
    openAmountEditorModal(item) {
      this.showAmountEditorModal = true;
      this.aTarget = {
        id: item.id,
        amount: item.amount,
      };
    },
    openTransactionsByCardModal(item) {
      this.showTransactionsByCardModal = true;
      this.selectedItem = { ...item };
    },
    refresh() {
      this.$refs["detailsExpenses"].refresh();
    },
    async saveObservations(observation) {
      this.selectedItem.observation = observation;
      await this.changeExpenseStatus(this.selectedItem, "REJECTED");
      this.showObservationsModal = false;
    },
    openObservationsModal(item) {
      this.showObservationsModal = true;
      this.selectedItem = item;
    },
    ...mapActions({
      expensesRequest: "ExpensesRequest/refreshExpensesCounter",
      A_METHOD_EXPENSE: "ExpensesRequest/A_METHOD_EXPENSE",
    }),
    async getExpensesByCard() {
      const params = {
        tab: this.getTab,
        type_expense: this.getTypeExpense,
        page: this.paginate.currentPage,
        perPage: this.paginate.perPage,
        search: this.filterPrincipal.model,
        card_id: this.method == "CHECK" ? null : this.cardId,
        method: this.method,
        isInProcess: this.isInProcess,
      };
      try {
        const response = await DashboardService.getExpensesByCard(params);
        this.expenses = response.data;
        this.paginate.from = response.from;
        this.paginate.currentPage = response.current_page;
        this.paginate.perPage = response.per_page;
        this.paginate.total = response.total;
        this.paginate.to = response.to;
        return this.expenses;
      } catch (error) {
        this.showErrorSwal(error);
        console.log(error);
      }
    },
    async changeExpenseStatus(item, status) {
      const detail = `Module: <b>${item.module_name}</b>, Vendor: <b>${item.vendor}</b>, Amount: <b>$${item.amount}</b>`;
      const action = status == "APPROVED" ? "confirm" : "reject";
      const confirm = await this.showConfirmSwal(
        `Are you sure you want to ${action} this expense request?: ${detail}`
      );
      if (!confirm.isConfirmed) return false;

      const params = {
        id: item.id,
        status: status,
        user_id: this.currentUser.user_id,
        type: item.type,
        observation: item.observation,
        method: item.method,
        // expense_transaction_id: item.expense_transaction_id,
      };
      try {
        this.addPreloader();
        const { data } = await DashboardService.changeExpenseStatus(params);
        if (data.success) {
          this.showToast(
            "success",
            "top-right",
            "Success",
            "CheckIcon",
            "Successfully process"
          );
          await this.refresh();
          this.expensesRequest(true);
        } else {
          this.showToast(
            "danger",
            "top-right",
            "Oop!",
            "AlertOctagonIcon",
            "Failed process"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    openClientExpenses(item) {
      this.clientExpenseModal.show = true;
      this.clientExpenseModal.expenseDepartment = item;
    },
  },
};
</script>

<style lang="scss">
#detailsExpenses .b-table-bottom-row {
  position: sticky;
  bottom: 0px;
  z-index: 1;
}
#detailsExpenses .total-client {
  font-size: 14px;
  padding: 0.8rem;
  span {
    border-bottom: 2px solid #0090e7;
    padding: 0px 5px;
    font-weight: bold !important;
  }
  :hover {
    cursor: pointer;
    span {
      box-shadow: rgba(255, 255, 255, 0.856) 0px 54px 55px,
        rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px,
        rgba(241, 239, 239, 0.09) 0px -3px 5px;
    }
  }
}
#detailsExpenses .b-table-bottom-row th:nth-child(1) {
  border-radius: 0px 0px 0px 15px !important;
  margin-left: 5px !important;
  background: #0090e7 !important;
}
.type-client
  #detailsExpenses
  .b-table-bottom-row
  th:nth-child(n + 2):nth-child(-n + 4),
.type-amg
  #detailsExpenses
  .b-table-bottom-row
  th:nth-child(n + 2):nth-child(-n + 2) {
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #0090e7 !important;
}
.type-client #detailsExpenses .b-table-bottom-row th:nth-child(4),
.type-amg #detailsExpenses .b-table-bottom-row th:nth-child(4) {
  border-radius: 0px 0px 15px 0px !important;
  margin-left: 10px !important;
  background: #0090e7 !important;
}
.type-amg #detailsExpenses .b-table-bottom-row th:nth-child(3) {
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #0090e7 !important;
}
#detailsExpenses .fill-bottom-row {
  background-color: #ffffff !important;
}
.dark-layout #detailsExpenses {
  .fill-bottom-row {
    background-color: #17171a !important;
    border-top: none !important;
  }
}
.foot-totales p {
  padding: 2px 0px;
}
</style>
