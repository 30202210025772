export default [
  {
    key: "origin_module",
    label: "Origin Module",
    sortable: false,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
    thStyle: "background: #0090E7; color: white; border-radius: 15px 0 0 0;",
  },
  {
    key: "lead_name",
    label: "Lead Name",
    sortable: false,
    visible: true,
    thStyle: "background: #0090E7; color: white;",
  },
  {
    key: "mobile",
    label: "Mobile",
    sortable: false,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
    thStyle: "background: #0090E7; color: white;",
  },
  {
    key: "potential",
    label: "Potential",
    sortable: false,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
    thStyle: "background: #0090E7; color: white;",
  },
  {
    key: "dialogue",
    label: "Dialogue",
    sortable: false,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
    thStyle: "background: #0090E7; color: white;",
  },
  {
    key: "appointment",
    label: "Appointment",
    sortable: false,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
    thStyle: "background: #0090E7; color: white;",
  },
  {
    key: "suggest",
    label: "Suggest",
    sortable: false,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
    thStyle: "background: #0090E7; color: white;",
  },
  {
    key: "last_call_at",
    label: "Last call at",
    sortable: false,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
    thStyle: "background: #0090E7; color: white;",
  },
  {
    key: "created_at",
    label: "Contact by",
    sortable: false,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
    thStyle: "background: #0090E7; color: white;",
  },
  {
    key: "count_replies",
    label: "Contacts",
    sortable: false,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
    thStyle: "background: #0090E7; color: white; border-radius: 0 15px 0 0;",
  },
];
