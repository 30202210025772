<template>
  <b-modal
    ref="myModal"
    v-model="onControl"
    header-bg-variant="transparent border-bottom border-bottom-2"
    size="xmd"
    header-class="p-0"
    title="Employee Turnover Details"
    modal-class="modal-primary"
    hide-footer
    scrollable
    @hidden="close"
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <!-- ( ${nameDepartemnt} )  -->
        <span>{{ `Employee Turnover (${titleMonthYear})` }}</span>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="close"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>

    <b-tabs
      v-model="tabIndex"
      lazy
      pills
      nav-class="mb-0"
      active-nav-item-class="bg-primary box-shadow-info"
      class="mt-1"
    >
      <b-tab lazy title="New" :title-link-class="[bgTabsNavs, 'px-3']">
        <tab-new-employee
          :send-year="dataYear"
          :send-month="dataMonth"
          :send-department-id="idDepartment"
        />
      </b-tab>
      <b-tab lazy title="Retired" :title-link-class="[bgTabsNavs, 'px-3']">
        <tab-retired-employee
          :send-year="dataYear"
          :send-month="dataMonth"
          :send-department-id="idDepartment"
        />
      </b-tab>
    </b-tabs>
  </b-modal>
</template>
  
<script>
import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service";
import TabNewEmployee from "@/views/ceo/dashboard/modals/employee/TabNewEmployee.vue";
import TabRetiredEmployee from "@/views/ceo/dashboard/modals/employee/TabRetiredEmployee.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    TabNewEmployee,
    TabRetiredEmployee,
  },

  props: {
    sendYear: {
      type: Number,
      default: null,
    },
    sendMonth: {
      type: Number,
      default: null,
    },
    sendDepartmentId: {
      type: String,
      default: "",
    },
    sendDepartmentName: {
      type: String,
      default: "",
    },
    newEmployees: {
      type: Number,
      default: 0,
    },
    tabSelected: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      onControl: false,
      startPage: 0,
      toPage: 0,
      isBusy: false,
      dataYear: null,
      dataMonth: null,
      idDepartment: null,
      nameDepartemnt: "",
      tabIndex: 0,
    };
  },
  computed: {
    ...mapGetters({
      titleMonthYear: "DashboardCeoStore/G_TITLE_MODAL",
    }),
  },
  async created() {
    this.dataMonth = this.sendMonth;
    this.dataYear = this.sendYear;
    this.idDepartment = this.sendDepartmentId;
    this.nameDepartemnt =
      this.sendDepartmentName == null
        ? "All departments"
        : this.sendDepartmentName;
    this.onControl = true;
    if (this.tabSelected != null) {
      this.tabIndex = this.tabSelected;
    } else {
      this.tabIndex = this.newEmployees == 0 ? 1 : 0;
    }
  },

  methods: {
    close() {
      this.$refs["myModal"].hide();
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.header-modal {
  position: relative;
  padding: 20px 0 20px 15px;
  background: rgba(22, 84, 204, 1);
  width: 100%;
  font-size: 22px;
  display: flex;
  justify-content: space-betweeen;

  span {
    font-size: 1.6rem;
    font-weight: bold;
    margin-left: 8px;
    color: #ffffff;
  }

  .container-icons {
    background-color: #ffffff;
    padding: 2.7px 6.37px;
    border-radius: 0.357rem;
    width: 31px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    transition: all 0.23s ease 0.1s;

    &--dark {
      background-color: #17171a;
    }

    &:hover {
      transform: translate(-7px, 5px);
      cursor: pointer;
    }
  }
}
</style>