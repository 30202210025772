<template>
  <b-modal
    ref="trackingModalOtherPayment"
    :title="`Tracking ${dataClient.lead_name || dataClient.client_name}`"
    title-tag="h3"
    modal-class="modal-primary"
    @hidden="close"
    size="lg"
    scrollable
    hide-footer
  >
    <b-table
      id="tracking-list"
      ref="trackingList"
      no-border-collapse
      class="blue-scrollbar position-relative table-new-customization"
      table-class="wo-padding"
      :fields="fields"
      small
      show-empty
      no-provider-filtering
      sticky-header="50vh"
      primary-key="id"
      responsive="sm"
      :items="trackingData"
      :busy="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(client_name)>
        <span class="text-primary cursor-pointer">
          {{ dataClient.client_name || dataClient.lead_name }}
        </span>
      </template>
      <template #cell(status)="{ item }">
        <b-badge :variant="statusTracking(item.status_id)">
          {{ item.status }}
        </b-badge>
      </template>

      <template #cell(created_by)="{ item }">
        <span>{{ item.created_by }}</span>
      </template>

      <template #cell(created_at)="{ item }">
        <span>{{ item.created_at | myGlobalDay }}</span>
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import OtherPaymentsService from "@/views/commons/components/other-payments/service/other-payment.service";
export default {
  props: {
    dataClient: {
      type: Object,
      default: "",
    },
  },
  data() {
    return {
      trackingData: [],
      fields: [
        {
          key: "client_name",
          label: "Client Name",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "created_by",
          label: "Created By",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "created_at",
          label: "Created At",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      isBusy: false,
    };
  },
  async created() {
    await this.getTrackingData();
  },
  mounted() {
    this.toggleModal("trackingModalOtherPayment");
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async getTrackingData() {
      this.isBusy = true;
      let response = await OtherPaymentsService.getTrackingOtherPayment({
        id: this.dataClient.id,
      });
      this.trackingData = response.data;
      this.isBusy = false;
    },
    statusTracking(status) {
      const color = {
        1: "light-primary",
        2: "light-warning",
        3: "light-success",
        4: "light-danger",
      };
      return color[status] || "light-primary";
    },
  },
};
</script>
