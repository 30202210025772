<template>
  <b-modal
    ref="payable-expense-address-editor-modal"
    modal-class="modal-primary"
    title-class="h3 text-white"
    title="UPDATE ADDRESS BY PAY TO"
    size="lg"
    @hidden="closeModal()"
    centered
    no-close-on-backdrop
    ok-title="Update Address"
    @ok="handleOk"
  >
    <ValidationObserver ref="formValidateAddress">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <b-form-group label="Address">
          <vue-google-autocomplete
            id="address_street"
            ref="address_street"
            v-model="form.address"
            class="form-control input-form fond-white border-hover font-ce"
            placeholder="Please type your address"
            country="us"
            @change="getAddress"
            :class="{ 'border-danger': errors.length > 0 }"
            @placechanged="setAddress"
          />
        </b-form-group>
      </ValidationProvider>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import DepartmentExpenses from "@/views/commons/expenses/services/expenses-department.service";

export default {
  name: "PayableExpenseAddressEditorModal",
  props: {
    selectedAddress: {
      type: Object,
      default: () => {
        return {
          id: null,
          address: null,
          city: null,
          state: null,
          country: null,
          postal_code: null,
          route: null,
          street_number: null,
          state_slug: null,
        };
      },
    },
  },
  components: {
    VueGoogleAutocomplete,
  },
  data() {
    return {
      form: {
        address: null,
        city: null,
        state: null,
        country: null,
        postal_code: null,
        route: null,
        street_number: null,
        state_slug: null,
      },
      place: null,
      formBase: {
        address: null,
        city: null,
        state: null,
        country: null,
        postal_code: null,
        route: null,
        street_number: null,
        state_slug: null,
      },
    };
  },
  mounted() {
    this.form.address = this.selectedAddress.address;
    this.form.city = this.selectedAddress.city;
    this.form.state = this.selectedAddress.state;
    this.form.country = this.selectedAddress.country;
    this.form.postal_code = this.selectedAddress.postal_code;
    this.form.route = this.selectedAddress.route;
    this.form.street_number = this.selectedAddress.street_number;
    this.form.state_slug = this.selectedAddress.state_slug;
    this.toggleModal("payable-expense-address-editor-modal");
  },
  created() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    getAddress(data) {
      this.form = {
        ...this.formBase,
      };
      this.form.address = data;
      if (this.place) {
        const {
          country,
          route,
          locality,
          postal_code,
          street_number,
          administrative_area_level_1,
        } = this.place;
        const state = data.split(",");
        this.form.address = data;
        this.form.city = locality;
        this.form.state = state[state.length - 2];
        this.form.country = country;
        this.form.postal_code = postal_code;
        this.form.route = route;
        this.form.street_number = street_number;
        this.form.state_slug = administrative_area_level_1;
      }
    },
    setAddress(address) {
      this.place = address;
    },
    async handleOk(event) {
      event.preventDefault();
      try {
        let isValid = await this.$refs["formValidateAddress"].validate();
        if (!isValid) return;
        if (this.selectedAddress.address == this.form.address) {
          this.showAlertWarning("Addresses are the same");
          return;
        }
        const response = await this.showConfirmSwal(
          "Are you sure you want to update the address?",
          null,
          {
            html: `<strong>Current address:</strong> ${this.selectedAddress.address}<br>
          <strong>New address:</strong> ${this.form.address}`,
          }
        );
        if (!response.isConfirmed) return;
        await DepartmentExpenses.updatePayableExpensesAddresses({
          ...this.form,
          id: this.selectedAddress.id,
        });
        this.showAlertSuccess("Address updated successfully");
        this.$emit("refresh");
        this.closeModal();
      } catch (e) {
        this.showAlertWarning(e);
      }
    },
    showAlertWarning(message) {
      this.showToast(
        "warning",
        "top-right",
        "Warning",
        "AlertTriangleIcon",
        message
      );
    },
    showAlertSuccess(message) {
      this.showToast("success", "top-right", "Success", "CheckIcon", message);
    },
  },
};
</script>
