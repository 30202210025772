<template>
  <div>
    <b-modal
      ref="leads-detail-modal"
      size="xmd"
      @hidden="$emit('hidden')"
      hide-footer
    >
      <template #modal-title>
        <span class="text-uppercase font-weight-bolder"> {{ title }} </span>
      </template>
      <template #default>
        <filter-slot
          class="filter-slot-new-customization"
          :filter="filterSlot.filters"
          :total-rows="filterSlot.totalRows"
          :paginate="filterSlot.paginate"
          :start-page="filterSlot.startPage"
          :to-page="filterSlot.toPage"
          :filter-principal="filterSlot.filterPrincipal"
          :top-paginate="false"
          @reload="$refs['lead-detail-table'].refresh()"
        >
          <b-table
            id="lead-detail-table"
            ref="lead-detail-table"
            slot="table"
            class="position-relative table-new-customization"
            empty-text="No matching records found"
            sticky-header="60vh"
            primary-key="id"
            responsive="md"
            show-empty
            no-border-collapse
            no-provider-filtering
            :busy.sync="isBusy"
            :fields="fields"
            :items="myProvider"
            :per-page="filterSlot.paginate.perPage"
            :current-page="filterSlot.paginate.currentPage"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(nickname)="data">
              <router-link
                class="d-block text-primary"
                :class="textLink"
                :to="`/socialnetwork/leads/new/dashboard/${data.item.lead_id}`"
                target="_blank"
              >
                {{ data.item.nickname }}
              </router-link>
              <div v-if="data.item.full_name">
                {{ data.item.full_name }}
              </div>
              <div v-else>-</div>
            </template>
            <template #cell(created_at)="data">
              {{ data.item.created_at | myGlobal }}
            </template>
            <template #cell(source_url)="data">
              <div
                class="d-flex justify-content-center flex-column align-items-center"
              >
                <b-avatar
                  :src="data.item.source_url"
                  size="40"
                  square
                  icon="file-earmark-image-fill"
                  class="rounded"
                  variant="light"
                />
                <span class="pt-1">
                  {{ data.item.source_name }}
                </span>
              </div>
            </template>
            <template #cell(mobile)="data">
              <span v-if="data.item.mobile">
                {{ data.item.mobile }}
              </span>
              <span v-else>-</span>
            </template>
            <template #cell(potential)="data">
              <div>
                <b-badge
                  :variant="badgeVariant(data.item.potential).variant"
                  class="text-capitalize"
                >
                  {{ badgeVariant(data.item.potential).name }}
                </b-badge>
              </div>
              <div>
                <b-badge
                  :variant="
                    data.item.potential == 2
                      ? 'light-secondary'
                      : 'light-success'
                  "
                  class="text-capitalize mt-1"
                >
                  {{ data.item.motive }}
                </b-badge>
              </div>
            </template>
          </b-table>
        </filter-slot>
      </template>
    </b-modal>
  </div>
</template>
<script>
import ComercialReportService from "@/views/commons/components/reports/tabs/comercial-report/service/comercial-report-v2.service.js";
export default {
  name: "LeadsDetailModal",
  props: {
    type: {
      type: Number,
      required: true,
      default: null,
    },
    flyerCode: {
      type: String,
      required: true,
      default: "",
    },
    flyerId: {
      type: Number,
      required: true,
      default: null,
    },
    metaAdId: {
      type: String,
      required: false,
      default: "",
    },
    dateFrom: {
      type: String,
      required: true,
      default: null,
    },
    dateTo: {
      type: String,
      required: true,
      default: null,
    },
    method: {
      type: String,
      required: true,
      default: "",
    },
    sendYear: {
      type: Number,
      required: false,
      default: null,
    },
    sendMonth: {
      type: Number,
      required: false,
      default: null,
    },
    sendRange: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      isBusy: false,
      fields: [
        {
          key: "nickname",
          label: "Nickname",
          sortable: false,
          visible: true,
        },
        {
          key: "created_at",
          label: "Created at",
          sortable: false,
          visible: true,
        },
        {
          key: "source_url",
          label: "Source",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: false,
          visible: true,
        },
        {
          key: "mobile",
          label: "Number",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: false,
          visible: true,
        },
        {
          key: "potential",
          label: "Potential",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: false,
          visible: true,
        },
      ],
      filterSlot: {
        filters: [],
        paginate: {
          currentPage: 1,
          perPage: 50,
        },
        startPage: 0,
        toPage: 0,
        totalRows: 0,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Search...",
          model: "",
        },
      },
    };
  },
  computed: {
    title() {
      const tiltes = {
        1: "Leads with number",
        2: "Leads without number",
      };
      return tiltes[this.type];
    },
  },
  created() {},
  mounted() {
    this.toggleModal("leads-detail-modal");
  },
  methods: {
    async myProvider(ctx) {
      try {
        const orderBy = ctx.sortBy ? ctx.sortBy : "created_at";
        let order = ctx.sortDesc ? "desc" : "asc";
        order = ctx.sortBy ? order : "desc";

        const params = {
          search_text: this.filterSlot.filterPrincipal.model,
          date_from: this.dateFrom,
          date_to: this.dateTo,
          flyer_id: this.flyerId,
          meta_ad_id: this.metaAdId,
          type: this.type,
          npage: this.filterSlot.paginate.currentPage,
          perpage: this.filterSlot.paginate.perPage,
          orderby: orderBy,
          order: order,
          method: this.method,
          year: this.sendYear,
          month: this.sendMonth,
          range: this.sendRange,
        };

        const { data } = await ComercialReportService.getLeadsWithMobileFilter(
          params
        );

        this.filterSlot.totalRows = data.total;
        this.filterSlot.startPage = data.from ?? 0;
        this.filterSlot.toPage = data.to ?? 0;
        this.filterSlot.paginate.currentPage = data.current_page;
        this.filterSlot.paginate.perPage = data.per_page;

        return data.data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    badgeVariant(value) {
      const variants = {
        1: "light-primary",
        2: "light-warning",
        3: "light-success",
        4: "light-secondary",
      };
      const names = {
        1: "Potential",
        2: "Not potential",
        3: "Confirm",
        4: "Recovery",
      };

      return {
        variant: variants[value] ?? "light-secondary",
        name: names[value] ?? "Unknown",
      };
    },
  },
};
</script>
<style lang="scss">
#lead-detail-table {
  .rounded {
    border-radius: 0.357rem !important;
  }
}
</style>