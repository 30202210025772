export default [
  {
    key: 'client_name',
    label: 'Client', 
    sortable: false,
  },
  {
    key: 'service',
    label: 'Service',
    thClass: 'text-center',
    tdClass: 'text-center',
    sortable: false,
  },
  {
    key: 'phone_client',
    label: 'Phone Client',
    thClass: 'text-center',
    tdClass: 'text-center',
    sortable: false,
  },
  {
    key: 'service_line',
    label: 'Service Line',
    thClass: 'text-center',
    tdClass: 'text-center',
    sortable: false,
  }, 
  {
    key: 'created_at',
    label: 'Date',
    thClass: 'text-center',
    tdClass: 'text-center',
    sortable: false,
  }, 
  {
    key: 'agent',
    label: 'Agent',
    thClass: 'text-center',
    tdClass: 'text-center',
    sortable: false,
  },
  {
    key: 'result',
    label: 'Result',
    thClass: 'text-center',
    tdClass: 'text-center',
    sortable: false,
  },
  {
    key: "details",
    label: "Details",
    thClass: 'text-center',
    tdClass: 'text-center',
    sortable: false,
  },
];
