export default [
  {
    key: "check_number",
    label: "Check Number",
    thClass: "text-left",
    tdClass: "text-left",
    visible: true,
  },
  {
    key: "details",
    label: "Transaction details",
    visible: true,
    tdClass: "text-left col-4",
  },
  {
    key: "type",
    label: "Type",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    key: "amount",
    label: "Amount",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  // {
  //   key: "check_number",
  //   label: "Check Number",
  //   thClass: "text-center",
  //   tdClass: "text-center",
  //   visible: true,
  // },
  {
    key: "date",
    label: "Date",
    visible: true,
  },
  {
    key: "created_by",
    label: "Registered by",
    visible: true,
  },
  {
    key: "collected_at",
    label: "Collected at",
    visible: false,
  },
  {
    key: "confirmed_at",
    label: "Confirmed at",
    visible: true,
  },
  {
    key: "tracking",
    label: "Tracking",
    visible: true,
  },
];
