var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"recoveryCreditorObserver"},[_c('b-container',{staticClass:"mt-1"},[_c('h4',[_vm._v("Recovery")]),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Name","label-for":"recovery"}},[_c('b-form-input',{attrs:{"id":"recovery","name":"recovery","autofocus":"","disabled":_vm.onlyView,"maxlength":"255","state":errors[0] ? false : null,"trim":""},model:{value:(_vm.userData.rName),callback:function ($$v) {_vm.$set(_vm.userData, "rName", $$v)},expression:"userData.rName"}})],1)]}}])})],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"rules":_vm.userData.recovery_phone.length > 1 ? 'mobile|required':'mobile'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"recoveryPhone"}},[_vm._v("Phone")]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover-right",value:('Add Recovery Phone'),expression:"'Add Recovery Phone'",modifiers:{"hover-right":true}}],staticClass:"p-0 m-0",attrs:{"variant":"outline","disabled":_vm.onlyView},on:{"click":function($event){return _vm.addInput(1)}}},[_c('feather-icon',{staticClass:"text-primary",attrs:{"icon":"PlusSquareIcon","size":"18"}})],1)],1),_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('(###) ###-####'),expression:"'(###) ###-####'"}],staticClass:"widthInput",attrs:{"id":"recoveryPhone","autofocus":"","trim":"","disabled":_vm.onlyView,"state":errors[0] ? false : null},model:{value:(_vm.userData.recovery_phone[0].phone),callback:function ($$v) {_vm.$set(_vm.userData.recovery_phone[0], "phone", $$v)},expression:"userData.recovery_phone[0].phone"}})]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"rules":_vm.userData.recovery_agent.length >1 ? 'required|min:2|max:255':'min:2|max:255'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"recoveryAgent"}},[_vm._v("Agent")]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover-right",value:('Add Recovery Agent'),expression:"'Add Recovery Agent'",modifiers:{"hover-right":true}}],staticClass:"p-0 m-0",attrs:{"variant":"outline","disabled":_vm.onlyView},on:{"click":function($event){return _vm.addInput(5)}}},[_c('feather-icon',{staticClass:"text-primary",attrs:{"icon":"PlusSquareIcon","size":"18"}})],1)],1),_c('b-form-input',{staticClass:"widthInput",attrs:{"id":"recoveryAgent","autofocus":"","trim":"","disabled":_vm.onlyView,"maxlength":"255","state":errors[0] ? false : null},model:{value:(_vm.userData.recovery_agent[0].agent),callback:function ($$v) {_vm.$set(_vm.userData.recovery_agent[0], "agent", $$v)},expression:"userData.recovery_agent[0].agent"}})]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"rules":"min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"recoveryExtension"}},[_vm._v("Extension")])]),_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('/ ######'),expression:"'/ ######'"}],staticClass:"widthInput",attrs:{"id":"recoveryExtension","autofocus":"","trim":"","disabled":_vm.onlyView,"state":errors[0] ? false : null},model:{value:(_vm.userData.recovery_agent[0].extension),callback:function ($$v) {_vm.$set(_vm.userData.recovery_agent[0], "extension", $$v)},expression:"userData.recovery_agent[0].extension"}})]}}])})],1)],1),_c('b-row',[(_vm.userData.recovery_phone.length > 1)?_c('b-col',{attrs:{"cols":"4"}},_vm._l((_vm.userData.recovery_phone),function(item,index){return _c('b-row',{key:index},[(index > 0)?_c('b-col',{staticClass:"mt-1"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"recoveryPhone"}},[_vm._v("Phone")]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover-right",value:('Delete Recovery Phone'),expression:"'Delete Recovery Phone'",modifiers:{"hover-right":true}}],staticClass:"p-0 m-0",attrs:{"variant":"outline","disabled":_vm.onlyView},on:{"click":function($event){return _vm.deleteInput(1, index)}}},[_c('feather-icon',{staticClass:"text-danger",attrs:{"icon":"XCircleIcon","size":"18"}})],1)],1),_c('validation-provider',{attrs:{"rules":"mobile|required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('(###) ###-####'),expression:"'(###) ###-####'"}],staticClass:"widthInput",attrs:{"id":"recoveryPhone","autofocus":"","trim":"","disabled":_vm.onlyView,"state":errors[0] ? false : null},model:{value:(_vm.userData.recovery_phone[index].phone),callback:function ($$v) {_vm.$set(_vm.userData.recovery_phone[index], "phone", $$v)},expression:"userData.recovery_phone[index].phone"}})]}}],null,true)})],1):_vm._e()],1)}),1):_c('b-col',{attrs:{"cols":"4"}}),(_vm.userData.recovery_agent.length > 1)?_c('b-col',{attrs:{"cols":"8"}},_vm._l((_vm.userData.recovery_agent),function(item,index){return _c('b-row',{key:index},[(index > 0)?_c('b-col',{staticClass:"mt-1",attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"recoveryAgent"}},[_vm._v("Agent")]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover-right",value:('Delete Recovery Agent'),expression:"'Delete Recovery Agent'",modifiers:{"hover-right":true}}],staticClass:"p-0 m-0",attrs:{"variant":"outline","disabled":_vm.onlyView},on:{"click":function($event){return _vm.deleteInput(5, index)}}},[_c('feather-icon',{staticClass:"text-danger",attrs:{"icon":"XCircleIcon","size":"18"}})],1)],1),_c('validation-provider',{attrs:{"rules":"min:2|max:255|required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"widthInput",attrs:{"id":"recoveryAgent","autofocus":"","trim":"","disabled":_vm.onlyView,"maxlength":"255","state":errors[0] ? false : null},model:{value:(_vm.userData.recovery_agent[index].agent),callback:function ($$v) {_vm.$set(_vm.userData.recovery_agent[index], "agent", $$v)},expression:"userData.recovery_agent[index].agent"}})]}}],null,true)})],1):_vm._e(),(index > 0)?_c('b-col',{staticClass:"mt-1",attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"recoveryExtension"}},[_vm._v("Extension")])]),_c('validation-provider',{attrs:{"rules":"min:2","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('/ ######'),expression:"'/ ######'"}],staticClass:"widthInput",attrs:{"id":"recoveryExtension","autofocus":"","trim":"","disabled":_vm.onlyView,"state":errors[0] ? false : null},model:{value:(_vm.userData.recovery_agent[index].extension),callback:function ($$v) {_vm.$set(_vm.userData.recovery_agent[index], "extension", $$v)},expression:"userData.recovery_agent[index].extension"}})]}}],null,true)})],1):_vm._e()],1)}),1):_vm._e()],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"rules":"mobile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',[_vm._v("Fax")]),_c('b-button',{staticClass:"p-0 paddingB",attrs:{"variant":"outline","disabled":_vm.isDisabledFax || _vm.onlyView},on:{"click":function($event){return _vm.addInput(2)}}},[_c('feather-icon',{staticClass:"mr-50 text-primary",attrs:{"icon":"PlusSquareIcon","size":"18"}})],1)],1),(_vm.userData.recoveryFax.length > 0)?[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('(###) ###-####'),expression:"'(###) ###-####'"}],staticClass:"widthInput",attrs:{"id":"recoveryFax","name":"recoveryFax","autofocus":"","disabled":_vm.onlyView,"state":errors[0] ? false : null},model:{value:(_vm.userData.recoveryFax[0].fax),callback:function ($$v) {_vm.$set(_vm.userData.recoveryFax[0], "fax", $$v)},expression:"userData.recoveryFax[0].fax"}})]:_vm._e()]}}])}),(_vm.userData.recoveryFax.length > 1)?_c('div',[_c('validation-provider',{attrs:{"rules":"mobile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _vm._l((_vm.userData.recoveryFax),function(item,index){return _c('b-row',{key:index},[(index > 0)?_c('b-col',{staticClass:"mt-1 ml-1 p-0"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('(###) ###-####'),expression:"'(###) ###-####'"}],attrs:{"id":"fax","name":"fax","autofocus":"","trim":"","disabled":_vm.onlyView,"state":errors[0] ? false : null},model:{value:(item.fax),callback:function ($$v) {_vm.$set(item, "fax", $$v)},expression:"item.fax"}}),_c('div',{staticClass:"paddingIconoX"},[_c('b-button',{staticClass:"p-0 m-0",attrs:{"variant":"outline","disabled":_vm.onlyView},on:{"click":function($event){return _vm.deleteInput(2, index)}}},[_c('feather-icon',{staticClass:"text-danger",attrs:{"icon":"XCircleIcon","size":"18"}})],1)],1)],1)]):_vm._e()],1)})}}],null,false,995207157)})],1):_vm._e()],1),_c('b-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"rules":"email|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',[_vm._v("Email")]),_c('b-button',{staticClass:"p-0 paddingB",attrs:{"variant":"outline","disabled":_vm.isDisabledEmail || _vm.onlyView},on:{"click":function($event){return _vm.addInput(3)}}},[_c('feather-icon',{staticClass:"mr-50 text-primary",attrs:{"icon":"PlusSquareIcon","size":"18"}})],1)],1),(_vm.userData.recoveryEmail.length > 0)?[_c('div',[_c('b-form-input',{staticClass:"widthInput",attrs:{"id":"email","name":"email","autofocus":"","trim":"","disabled":_vm.onlyView,"state":errors[0] ? false : null},model:{value:(_vm.userData.recoveryEmail[0].email),callback:function ($$v) {_vm.$set(_vm.userData.recoveryEmail[0], "email", $$v)},expression:"userData.recoveryEmail[0].email"}})],1)]:_vm._e()]}}])}),(_vm.userData.recoveryEmail.length > 1)?_c('div',[_c('validation-provider',{attrs:{"rules":"email|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _vm._l((_vm.userData.recoveryEmail),function(item,index){return _c('b-row',{key:index},[(index > 0)?_c('b-col',{staticClass:"mt-1 ml-1 p-0"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('b-form-input',{attrs:{"name":"email","autofocus":"","trim":"","disabled":_vm.onlyView,"state":errors[0] ? false : null},model:{value:(item.email),callback:function ($$v) {_vm.$set(item, "email", $$v)},expression:"item.email"}}),_c('div',{staticClass:"paddingIconoX"},[_c('b-button',{staticClass:"p-0 m-0",attrs:{"variant":"outline","disabled":_vm.onlyView},on:{"click":function($event){return _vm.deleteInput(3, index)}}},[_c('feather-icon',{staticClass:"text-danger",attrs:{"icon":"XCircleIcon","size":"18"}})],1)],1)],1)]):_vm._e()],1)})}}],null,false,3033330778)})],1):_vm._e()],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"% Min of settlement","label-for":"settlement"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##.##'),expression:"'##.##'"}],attrs:{"id":"settlement","type":"number","name":"settlement","autofocus":"","disabled":_vm.onlyView,"trim":""},model:{value:(_vm.userData.settlement),callback:function ($$v) {_vm.$set(_vm.userData, "settlement", $$v)},expression:"userData.settlement"}})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":"Settle with A.M.G"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{staticClass:"paddingT d-flex",attrs:{"options":_vm.options,"disabled":_vm.onlyView,"aria-describedby":ariaDescribedby},model:{value:(_vm.userData.settle),callback:function ($$v) {_vm.$set(_vm.userData, "settle", $$v)},expression:"userData.settle"}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }