<template>
  <div>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        v-for="tab in serviceTabs"
        :key="tab.program_id + 'serviceTab'"
        exact
        :link-classes="['px-3', bgTabsNavs]"
        :active="tab.program_id === selectedServiceTab"
        @click="changeServiceTab(tab)"
        >{{ tab.name }}
      </b-nav-item>
    </b-nav>
    <b-card
      :key="selectedServiceTab + 'serviceTab'"
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <b-nav card-header pills class="m-0" :class="isCeo ? 'mt-1' : ''">
        <b-nav-item
          v-for="tab in tabs"
          :key="tab.id"
          exact
          :link-classes="[
            'sub-tab-nav ',
            tab.id === selectedTab ? 'router-link-active' : '',
            'px-3',
            bgTabsNavs,
          ]"
          :active="tab.id === selectedTab"
          @click="changeStatusTab(tab)"
          >{{ tab.name }}
          <span class="ml-2">
            <feather-icon
              icon
              :badge="tab.counter"
              :badge-classes="{
                'badge-success badge-glow ': tab.id === 1,
                'badge-danger badge-glow ': tab.id === 2,
                'badge-warning dagge-glow ': tab.id === 3,
              }"
            />
          </span>
        </b-nav-item>
      </b-nav>

      <b-card
        :key="selectedTab"
        no-body
        class="border-top-info border-3 border-table-radius px-0"
      >
        <filter-slot
          :filter="filters"
          :filter-principal="filterPrincipal"
          :total-rows="totalRows"
          :paginate="paginate"
          :start-page="startPage"
          :to-page="toPage"
          @reload="$refs['refAlertWaiting'].refresh()"
        >
          <template #table>
            <b-table
              ref="refAlertWaiting"
              no-border-collapse
              class="position-relative"
              :fields="filteredFields"
              show-empty
              no-provider-filtering
              sticky-header="50vh"
              primary-key="id"
              responsive="sm"
              :items="items"
              :current-page="paginate.currentPage"
              :per-page="paginate.perPage"
              :filter="searchInput"
              :busy.sync="isBusy"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </template>

              <template #cell(client)="data">
                <p v-if="[24, 26].includes(moduleId)" class="m-0">
                  {{ data.item.client }}
                </p>
                <p
                  v-else-if="moduleId === 23"
                  class="m-0 cursor-pointer text-primary"
                  @click="openModalClientDetail(data.item)"
                >
                  {{ data.item.client }}
                </p>
                <router-link
                  v-else
                  class
                  :to="{
                    name: isCeo
                      ? dashboardRouteNameByCeo(data.item.module_id)
                      : dashboardRouteName,
                    params: {
                      idClient: data.item.client_acount_id,
                    },
                  }"
                  target="_blank"
                  >{{ data.item.client }}</router-link
                >
              </template>

              <template #cell(amount)="data">
                <div>$ {{ data.item.amount }}</div>
              </template>

              <template #cell(supervisor)="data">
                <div>
                  {{ data.item.supervisor ? data.item.supervisor : "-" }}
                </div>
              </template>

              <template #cell(created_at)="data">
                {{ data.item.created_at | myGlobalWithHour }}
              </template>

              <template #cell(status_transaction_id)="data">
                <b-badge
                  v-if="[1, 5].includes(data.item.status_transaction_id)"
                  variant="light-success"
                  class="px-1"
                  pill
                  >Success</b-badge
                >
                <b-badge
                  v-else-if="data.item.status_transaction_id === 8"
                  variant="light-warning"
                  class="px-1"
                  pill
                  >Captured/Pending</b-badge
                >
                <b-badge
                  v-else-if="data.item.status_transaction_id === 10"
                  variant="light-warning"
                  class="px-1"
                  pill
                  >Authorized/Pending</b-badge
                >
                <b-badge v-else variant="light-danger" class="px-1" pill
                  >Declined</b-badge
                >
              </template>
            </b-table>
          </template>
        </filter-slot>
      </b-card>
    </b-card>

    <modal-client-detail
      v-if="modalClientDetailC"
      :client-information="clientInformation"
      @hideModal="modalClientDetailC = false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import IconStatus from "@/views/specialist-digital/views/process-cr/view/components/grids/IconStatus.vue";
import AlertWaitingFilters from "@/views/ce-digital/sub-modules/customer-service/views/clients-table/components/modals/alerts-pending/alert-waiting.filters";
import GlobalService from "@/views/services/global.service";
import ClientService from "@/views/commons/components/clients/services/clients.services";
import ModalClientDetail from "@/views/ce-digital/sub-modules/financial/view/pending-payments/view/components/modal/ModalClientDetail.vue";
import NabvarService from "@/layouts/components/navbar/service/navbar.service.js";

export default {
  name: "DashboardTracking",
  components: {
    IconStatus,
    ModalClientDetail,
  },
  props: {
    notification: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tabs: [
        {
          id: 1,
          name: "SUCCESSFULLY",
          counter: 0,
        },
        {
          id: 2,
          name: "DECLINED",
          counter: 0,
        },
        {
          id: 3,
          name: "AUTHORIZED",
          counter: 0,
        },
      ],
      serviceTabs: [],
      fields: [
        {
          key: "account",
          label: "ACCOUNT",
          class: "text-left",
          sortable: false,
          visible: true,
        },
        {
          key: "client",
          label: "CLIENT",
          class: "text-left",
          sortable: false,
          visible: true,
        },
        {
          key: "user",
          label: "AGENT",
          class: "text-left",
          sortable: false,
          visible: true,
        },
        {
          key: "card_number",
          label: "CARD NUMBER",
          class: "text-left",
          sortable: false,
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "amount",
          label: "AMOUNT",
          class: "text-left",
          sortable: false,
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "created_at",
          label: "IMPORT DATE",
          class: "text-left",
          sortable: false,
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "status_transaction_id",
          label: "STATUS TRANSACTION",
          class: "text-center",
          sortable: false,
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      selectedTab: null,
      filters: AlertWaitingFilters,
      modalClientDetailC: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by client name...",
        model: "",
      },
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      startPage: 0,
      toPage: 0,
      isBusy: false,
      searchInput: "",
      items: [],
      clientInformation: {
        type: Object,
        default: () => {},
      },
      routers: {
        3: "bussiness-dashboard-clients",
        4: "administration-dashboard",
        5: "debt-solution-dashboard",
        6: "credit-experts-dashboard",
        7: "boost-credit-dashboard",
        8: "tax-research-client-dashboard",
        10: "claim-department-dashboard",
        11: "specialists-client-dashboard",
        14: "bookeeping-dashboard",
        18: "quality-client-dashboard",
        20: "connection-clients-account",
        22: "ce-customer-service-dashboard",
      },
      selectedServiceTab: null,
    };
  },
  async mounted() {
    Promise.all([
      this.usersAllPrograms(),
      this.getAutomaticPaymentsNotificationTabs(),
    ]);
    if (!this.isCeo) {
      this.serviceTabs = [];
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    dashboardRouteName() {
      return this.routers[this.moduleId] || null;
    },
    filteredFields() {
      if ([6, 20, 21, 22, 23, 24, 26].includes(this.moduleId)) {
        this.fields[2].visible = false;
        this.fields[3].visible = false;
      }
      return this.fields.filter((item) => item.visible);
    },
    visibleFields() {
      return this.fields.filter((item) => item.visible);
    },
  },
  watch: {
    async selectedServiceTab(newValue, oldValue) {
      await this.getTransactions(newValue);
    },
  },
  methods: {
    async openModalClientDetail(item) {
      const params = {
        client_account_id: item.client_acount_id,
      };
      const [clientInfo] = await GlobalService.getDetailsClient(params);
      this.clientInformation = clientInfo;
      this.modalClientDetailC = true;
    },
    changeStatusTab(tab) {
      this.selectedTab = tab.id;
      this.getTransactions(this.selectedServiceTab);
    },
    async changeServiceTab(tab) {
      this.selectedServiceTab = tab.program_id;
    },
    // async getCounterStatusTransactions() {
    //   const params = {
    //     import_id: this.notification.import_id,
    //     user_id: this.currentUser.user_id,
    //     module_id: this.isCeo ? this.selectedServiceTab : this.moduleId,
    //   };
    //   const data = await GlobalService.getCounterStatusTransactions(params);
    //   this.tabs[0].counter = data[0].transactions_succesfully;
    //   this.tabs[1].counter = data[0].transactions_error;
    //   // this.tabs[2].counter = data[0].captured_pending
    // },

    async getAutomaticPaymentsNotificationTabs() {
      const params = {
        import_id: this.notification.import_id,
        user_id: this.currentUser.user_id,
        module_id: this.isCeo ? this.selectedServiceTab : this.moduleId,
      };
      const data = await NabvarService.getAutomaticPaymentsNotificationTabs(
        params
      );
      this.serviceTabs = data;
      this.selectedServiceTab = data[0].program_id;
      this.selectedTab = 1;
      // this.tabs[0].counter = data[0].approved;
      // this.tabs[1].counter = data[0].declined;
      // this.tabs[2].counter = data[0].pending
    },

    async getTransactions(program_id) {
      const params = {
        import_id: this.notification.import_id,
        user_id: this.currentUser.user_id,
        per_page: this.paginate.perPage,
        advisor_id: this.filters[2].model,
        show_success: this.selectedTab,
        search: this.filterPrincipal.model,
        page: this.paginate.currentPage,
        module_id: this.isCeo ? this.selectedServiceTab : this.moduleId,
        program_id: program_id,
      };
      const data = await GlobalService.getAutomaticPayments(params);
      if (data.data.length !== 0) {
        this.tabs[0].counter = data.data[0].approved;
        this.tabs[1].counter = data.data[0].declined;
        // this.tabs[2].counter = data.data[0].pending
      }
      this.items = data.data || [];
      this.startPage = data.from;
      this.paginate.currentPage = data.current_page;
      this.paginate.perPage = data.per_page;
      this.nextPage = this.startPage + 1;
      this.endPage = data.last_page;
      this.totalRows = data.total;
      this.toPage = data.to;
    },

    async usersAllPrograms() {
      try {
        if (this.moduleId === null) {
          return;
        }
        const data = await ClientService.usersAllPrograms({
          id: this.moduleId,
        });
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    close() {
      this.$emit("close");
    },
    dashboardRouteNameByCeo(item) {
      return this.routers[item] || null;
    },
  },
};
</script>
