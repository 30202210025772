<template>
  <b-modal
    title-class="h3 text-white"
    v-model="showModal"
    size="md"
    centered
    :title="'Messages'"
    hide-footer
    modal-class="modal-primary"
    body-class="p-0"
    header-bg-variant="transparent border-bottom border-bottom-2"
    header-class="p-0"
    @hidden="emitClose"
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <span>{{
          G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.sender
            ? G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR.sender.name
            : "Not found"
        }}</span>

        <slot name="update"></slot>

        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="emitClose"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>

    <div style="height: 75vh !important">
      <messenger-current-chat class="h-100" />
    </div>
  </b-modal>
</template>

<script>
import MessengerCurrentChat from "@/views/commons/Messenger/components/messenger/messages/MessageContent.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    MessengerCurrentChat,
  },
  data() {
    return {
      showModal: true,
    };
  },

  computed: {
    ...mapGetters({
      G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR:
        "MessengerMetaStore/G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR",
    }),
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
  },
};
</script>