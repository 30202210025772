<template>
  <div>
    <filter-slot
      class="filter-slot-new-customization"
      :filter="[]"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :no-visible-principal-filter="true"
      :top-paginate="false"
      @reload="$refs['refUnnotedCallsGrid'].refresh()"
      paginate-left
    >
      <template #table>
        <b-table
          class="position-relative table-new-customization"
          primary-key="original_id"
          ref="refUnnotedCallsGrid"
          no-border-collapse
          :fields="filteredFields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          responsive="sm"
          :items="getUnnotedCalls"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(leads_or_clients)="data">
            <div v-if="tab == 1">
              <template v-if="data.item.leads_or_clients.length == 1">
                <div class="text-center">
                  {{ data.item.leads_or_clients[0].name }}
                </div>
              </template>

              <template v-else>
                <div class="text-center text-muted">Unknown</div>
              </template>
            </div>
            <div v-else>
              <template v-for="(lead, index) in data.item.note_leads">
                <div class="text-center" :key="index">{{ lead.leads }}</div>
              </template>
            </div>
          </template>
          <template #cell(phone_clients)="data">
            <div v-if="data.item.phone_clients.length == 1">
              <div
                v-for="(phone_client, index) in data.item.phone_clients"
                :key="index"
              >
                <div class="text-center">{{ phone_client.phone_client }}</div>
              </div>
            </div>

            <div
              v-else-if="
                data.item.phone_clients.length != 1 &&
                data.item.from_number != null
              "
            >
              <div class="text-center">{{ data.item.from_number }}</div>
            </div>

            <div class="text-center" v-else>No phone client</div>
          </template>
          <template #cell(service_lines)="data">
            <div v-if="data.item.service_lines.length == 1">
              <div
                v-for="(service_line, index) in data.item.service_lines"
                :key="index"
              >
                <div class="text-center">{{ service_line.service_line }}</div>
              </div>
            </div>

            <div
              v-else-if="
                data.item.service_lines.length != 1 &&
                data.item.to_number != null
              "
            >
              <div class="text-center">{{ data.item.to_number }}</div>
            </div>

            <div class="text-center" v-else>No service line</div>
          </template>
          <template #cell(direction)="data">
            <div class="text-center">
              {{ data.item.direction }}
            </div>
          </template>
          <template #cell(start_time)="data">
            <div class="align-middle text-center">
              {{ data.item.start_time | myGlobalWithHour }}
            </div>
          </template>
          <template #cell(audio)="data">
            <div class="align-middle text-center">
              <audio-player
                v-if="data.item.show_audio && data.item.record_url"
                class="rounded-lg px-1 custom-audio border-light"
                :src="data.item.record_url"
                :index="data.item.call_id"
                :chat="true"
              />
              <feather-icon
                v-else
                @click="data.item.show_audio = !data.item.show_audio"
                icon="LoaderIcon"
                class="text-primary cursor-pointer"
                v-b-tooltip.hover
                title="Load record"
              ></feather-icon>

              <div class="align-middle text-center mt-1">
                {{ convertMSeconds(data.item.duration) }}
              </div>
            </div>
          </template>
          <template #cell(actions)="data">
            <div class="align-middle text-left">
              <b-button
                v-if="
                  tab == isPendingTab &&
                  (isCeo || isChief) &&
                  data.item.transcription_status != 'IN_PROGRESS'
                "
                class="my-05 w-100"
                variant="outline-primary"
                @click="openGenerateAutomaticNoteModal(data.item)"
                v-b-tooltip.hover
                title="Generate Automatic Note"
              >
                <feather-icon icon="EditIcon" size="13" class="mr-05" />

                Create Note
              </b-button>

              <div
                class="d-flex flex-column align-items-center"
                align="center"
                v-if="data.item.transcription_status == 'IN_PROGRESS'"
              >
                <b-spinner label="" variant="warning"></b-spinner>
                <span class="text-warning text-center mt-1">
                  Generating Summary ...</span
                >
              </div>

              <!-- <b-button
                v-if="
                  tab == isPendingTab &&
                  data.item.transcription_status != 'IN_PROGRESS'
                "
                class="my-05 w-100"
                variant="outline-primary"
                @click="changeToNotApplicable(data.item)"
              >
                Not Applicable
              </b-button> -->

              <b-button
                @click="openViewAutomaticNote(data.item.note_id)"
                v-if="tab == 2 && data.item.note_id != null"
                class="my-05 w-100"
                variant="outline-primary"
              >
                <feather-icon icon="EyeIcon" size="13" class="mr-05" />
                View Note
              </b-button>
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <GenerateAutomaticNote
      v-if="showGenerateAutomaticNoteModal"
      :call="selectedCall"
      :contact="contact"
      @hidden="showGenerateAutomaticNoteModal = false"
      @updateMainGrid="handleUpdateMainGrid"
    />
    <ViewAutomaticNote
      v-if="showViewAutomaticNoteModal"
      :noteId="noteId"
      @hidden="showViewAutomaticNoteModal = false"
    />
  </div>
</template>

<script>
import CallNotesService from "@/views/commons/components/ring-central/notes/service/notes.service.js";
import AudioPlayer from "@/views/commons/components/customer-tickets/chat-components/AudioPlayer.vue";
import GenerateAutomaticNote from "@/views/commons/components/ring-central/notes/GenerateAutomaticNote.vue";
import ViewAutomaticNote from "@/views/commons/components/ring-central/notes/ViewAutomaticNote.vue";

export default {
  props: {
    tab: {
      type: Number,
      required: true,
    },
    moduleId: {
      type: [String, Number],
      required: true,
    },
    year: {
      type: Number,
      default: null,
      required: false,
    },
    month: {
      type: Number,
      default: null,
      required: false,
    },
    date: {
      type: [String, Number],
      default: "",
    },
  },
  components: {
    AudioPlayer,
    GenerateAutomaticNote,
    ViewAutomaticNote,
  },
  data() {
    return {
      showGenerateAutomaticNoteModal: false,
      selectedCall: null,
      contact: null, // lead or client selected if is one
      showViewAutomaticNoteModal: false,
      noteId: null,
      startPage: 0,
      toPage: 0,
      isBusy: false,
      sortBy: "client_name",
      sortDesc: true,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by client name...",
        model: "",
      },
      fields: [
        {
          key: "leads_or_clients",
          sortable: false,
          label: "name",
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "phone_clients",
          sortable: false,
          label: "PHONE CLIENT",
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "service_lines",
          sortable: false,
          label: "Service Line",
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "direction",
          sortable: false,
          label: "direction",
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "start_time",
          sortable: false,
          label: "start time",
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "audio",
          sortable: false,
          label: "Audio",
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "actions",
          sortable: false,
          label: "actions",
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      totalRows: 0,
      paginate: {
        perPage: 50,
        currentPage: 1,
      },
    };
  },
  created() {
    this.bindUpdateManualNotes();
    if (this.tab == 3) {
      this.fields = this.fields.map((field) => {
        if (field.key === "actions") {
          field.visible = false;
        }
        return field;
      });
    }
    this.filteredFields = this.fields.filter((field) => field.visible);
  },
  computed: {
    isPendingTab() {
      return 1;
    },
  },
  beforeDestroy() {
    try {
      window.socket.unbind("update-manual-notes");
    } catch (error) {
      console.log("update-manual-notes", error);
    }
  },
  methods: {
    async bindUpdateManualNotes() {
      try {
        window.socket.bind("update-manual-notes", async ({ data }) => {
          let existItem = document.querySelector(
            `[data-pk="${data.original_id}"]`
          );
          if (existItem) {
            existItem.remove();
            this.$emit("updateMainGrid");
          }
        });
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    handleUpdateMainGrid() {
      this.$refs["refUnnotedCallsGrid"].refresh();
      this.$emit("updateMainGrid");
    },
    async getUnnotedCalls(ctx) {
      let params = {
        moduleId: this.moduleId,
        tab: this.tab,
        page: ctx.currentPage,
        perPage: ctx.perPage,
        year: this.year,
        month: this.month,
        date: this.tab == 1 ? this.date : null,
      };

      if (this.tab === 1) {
        params = {
          ...params,
          get_only_counter: false,
        };
      }
      const { data } = await CallNotesService.getAll(params);
      data.data.forEach((row) => {
        row.show_audio = false;
      });

      this.startPage = data.from;
      this.paginate.currentPage = data.current_page;
      this.paginate.perPage = data.per_page;
      this.totalRows = data.total;
      this.toPage = data.to;
      return data.data || [];
    },

    async openGenerateAutomaticNoteModal(call) {
      this.showGenerateAutomaticNoteModal = true;
      this.selectedCall = call;
      if (call.leads_or_clients.length == 1) {
        this.contact = call.leads_or_clients[0];
      } else {
        this.contact = null;
      }
    },
    // async changeToNotApplicable(call) {
    //   try {
    //     const { isConfirmed } = await this.showConfirmSwal();

    //     if (!isConfirmed) return;

    //     this.addPreloader();
    //     const params = {
    //       original_id: call.original_id,
    //       transcription_status: 2, // 2 is not applicable 1 is done 3 is in progress
    //     };
    //     const { data, status } = await CallNotesService.update(params);
    //     if (status === 200) {
    //       this.showSuccessSwal(data.message);
    //       this.$refs["refUnnotedCallsGrid"].refresh();
    //       this.$emit("updateMainGrid");
    //     }
    //   } catch (error) {
    //     this.showErrorSwal(error);
    //   } finally {
    //     this.removePreloader();
    //   }
    // },

    openViewAutomaticNote(noteId) {
      this.showViewAutomaticNoteModal = true;
      this.noteId = noteId;
    },
  },
  watch: {
    tab(newValue, oldValue) {
      this.$refs["refUnnotedCallsGrid"].refresh();
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-audio {
  transform: scale(0.8) !important;
  border-width: 2px !important;
}
.header-modal {
  position: relative;
  padding: 20px 0 20px 15px;
  background: rgba(22, 84, 204, 1);
  width: 100%;
  font-size: 22px;
  display: flex;
  justify-content: space-betweeen;

  span {
    font-size: 1.3rem;
    font-weight: bold;
    margin-left: 8px;
    color: #ffffff;
  }

  .container-icons {
    background-color: #ffffff;
    padding: 2.7px 6.37px;
    border-radius: 0.357rem;
    width: 31px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    transition: all 0.23s ease 0.1s;

    &--dark {
      background-color: #17171a;
    }

    &:hover {
      transform: translate(-7px, 5px);
      cursor: pointer;
    }
  }
}
.my-05 {
  margin: 0.5rem;
}
.mr-05 {
  margin-right: 0.5rem;
}
.p-05 {
  padding: 0.5rem;
}

.fs-10 {
  font-size: 1rem;
}
.fs-12 {
  font-size: 1.2rem;
}

.type-item {
  font-size: 0.9rem;
}
.id-item {
  font-size: 0.9rem;
}

.call-participants-container {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 1rem;
}
</style>