<template>
  <b-modal
    ref="matchTransactionModal"
    modal-class="modal-primary"
    title-class="h3 text-white"
    title="Matched Transactions"
    size="xlg"
    @hidden="closeModal()"
    hide-footer
    centered
    no-close-on-backdrop
  >
    <div class="ml-1 my-1">
      <div
        class="absolute-linker d-flex justify-content-between align-items-center"
        :class="{ dark: isDarkSkin }"
      >
        <div>
          <v-select
            :options="creditCards"
            v-model="cardSelected"
            option-value="id"
            label="cardname"
            :clearable="false"
            placeholder="Select a card"
            style="min-width: 300px"
            @input="refresh()"
          >
            <template #selected-option="option">
              <div class="d-flex flex-column">
                <span>Bank: {{ option.bank }}</span>
                <span
                  >Card: {{ option.cardname }} | XXXX-{{
                    option.cardnumber.slice(-4)
                  }}</span
                >
              </div>
            </template>
            <template v-slot:option="option">
              <div class="d-flex flex-column">
                <span>Bank: {{ option.bank }}</span>
                <span
                  >Card: {{ option.cardname }} | XXXX-{{
                    option.cardnumber.slice(-4)
                  }}</span
                >
              </div>
            </template>
          </v-select>
        </div>
        <div class="d-flex align-items-center flex-column h-full px-1">
          <div>
            <feather-icon
              icon="ChevronLeftIcon"
              size="20"
              class="cursor-pointer"
              @click="prev()"
            />
            <span class="font-size:15px">
              {{ months[dataMonth - 1] }} {{ dataYear }}
            </span>
            <feather-icon
              icon="ChevronRightIcon"
              size="20"
              class="cursor-pointer"
              :class="{ 'disabled-next': disableNext }"
              @click="next()"
            />
          </div>
          <div class="mt-1">
            <b-button
              variant="outline-success"
              @click="openImportTransactions()"
            >
              <div class="d-flex align-items-center" style="gap: 10px">
                <feather-icon icon="UploadIcon" />
                <span>Import Transactions</span>
              </div>
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <filter-slot
      :filter="[]"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :filter-principal="filterPrincipal"
      @reload="refresh"
      :noVisiblePrincipalFilter="true"
    >
      <template #table>
        <b-table
          ref="matchedTransactionTable"
          :items="getData"
          :fields="fields"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          responsive
          show-empty
          :busy.sync="isBusy"
          class="position-relative table-new-customization"
          sticky-header="70vh"
          foot-clone
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle" />
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(department)="{ item }">
            <img
              v-b-tooltip.hover.top="item.department"
              :src="`/assets/${item.icons}`"
              alt="Logo"
              height="30"
              width="30"
            />
          </template>
          <template #cell(amount)="{ item }">
            <b-badge variant="light-success" style="font-size: 15px">{{
              item.amount | formatMoney
            }}</b-badge>
          </template>
          <template #cell(type)="{ item }">
            <b-badge
              class="px-1"
              :class="item.type == 'CLIENT' ? 'clickable' : ''"
              :variant="item.type == 'CLIENT' ? 'primary' : 'info'"
              @click="item.type == 'CLIENT' ? openClientExpenses(item) : false"
            >
              {{ item.type }}
              {{ item.totalClient > 0 ? `(${item.totalClient})` : null }}
            </b-badge>
          </template>
          <template #cell(date)="{ item }">
            <span>
              {{ item.date | myGlobal }}
            </span>
          </template>
          <template #foot(type)>
            <div
              class="row d-flex justify-content-end flex-column"
              style="gap: 5px"
            >
              <div><b-badge variant="info">Sub total:</b-badge></div>
              <div><b-badge variant="info">Total:</b-badge></div>
            </div></template
          >
          <template #foot(amount)>
            <div
              class="row d-flex justify-content-center flex-column"
              style="gap: 5px"
            >
              <div>
                {{ subTotal | formatMoney }}
              </div>
              <div>{{ totalAmount | currencyTwo | myMoney }}</div>
            </div>
          </template>
          <template #foot()> &nbsp;</template>
        </b-table>
      </template>
    </filter-slot>
    <client-expense-request
      v-if="clientExpenseModal.show"
      :expenseDepartment="clientExpenseModal.expenseDepartment"
      @close="clientExpenseModal.show = false"
    />
  </b-modal>
</template>
<script>
import DashboardService from "@/views/ceo/service-dashboard/dashboard.service";
import ClientExpenseRequest from "@/views/ceo/dashboard/modals/expenses-request/ClientExpenseRequest.vue";
import moment from "moment";

export default {
  name: "MatchTransactionModal",
  components: {
    ClientExpenseRequest,
  },
  props: {
    sendMonth: {
      type: Number,
      required: true,
    },
    sendYear: {
      type: Number,
      required: true,
    },
    creditCardId: {
      type: Number,
      required: true,
    },
    cardNumber: {
      type: String,
      required: true,
    },
    creditCards: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      cardSelected: null,
      dataYear: null,
      cpyMonth: null,
      cpyYear: null,
      dataMonth: null,
      totalAmount: 0,
      clientExpenseModal: {
        show: false,
        expenseDepartment: null,
      },
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by concept",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      startPage: 0,
      toPage: 0,
      totalRows: 0,
      matchTransaction: [],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      isBusy: false,
      fields: [
        {
          key: "department",
          label: "Department",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "vendor",
          label: "Vendor",
          visible: true,
        },
        {
          key: "concept",
          label: "Concept",
          thClass: "text-left",
          tdClass: "text-left",
          visible: true,
        },
        {
          key: "description",
          label: "Description import",
          thClass: "text-left",
          tdClass: "text-left",
          visible: true,
        },
        {
          key: "type",
          label: "Type",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "amount",
          label: "Amount",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "date",
          label: "Date Transaction",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
      ],
    };
  },
  computed: {
    disableNext() {
      const now = moment();
      const sameYear = now.year() === this.dataYear;
      const sameMonth = now.month() + 1 === this.dataMonth;
      return sameYear && sameMonth;
    },
    subTotal() {
      return this.matchTransaction.reduce(
        (total, item) => total + parseFloat(item.amount),
        0
      );
    },
    titleModal() {
      // Obtén los últimos cuatro dígitos
      const lastFourDigits = this.cardNumber.slice(-4);

      // Reemplaza todos los dígitos excepto los últimos cuatro con X
      const maskedCardNumber =
        this.cardNumber.slice(0, -4).replace(/\d/g, "X") + lastFourDigits;

      return `Card: ${maskedCardNumber}`;
    },
  },
  mounted() {
    this.cardSelected = this.creditCards.find(
      (item) => item.id === this.creditCardId
    );
    this.initMonthYear();
    this.toggleModal("matchTransactionModal");
  },
  async created() {
    // await this.getData();
  },
  methods: {
    initMonthYear() {
      this.dataMonth = this.sendMonth;
      this.dataYear = this.sendYear;
    },
    async next() {
      if (this.dataMonth == null) this.dataMonth = this.cpyMonth;
      if (this.disableNext) return;
      if (this.dataMonth < 12) {
        this.dataMonth++;
      } else {
        this.dataMonth = 1;
        this.dataYear++;
      }
      await this.refresh();
    },
    async prev() {
      if (this.dataMonth == null) this.dataMonth = this.cpyMonth;
      if (this.dataMonth > 1) {
        this.dataMonth--;
      } else {
        this.dataMonth = 12;
        this.dataYear--;
      }
      await this.refresh();
    },
    async refresh() {
      this.$refs.matchedTransactionTable.refresh();
    },
    openClientExpenses(item) {
      this.clientExpenseModal.show = true;
      this.clientExpenseModal.expenseDepartment = JSON.parse(item.clients)[0];
      this.clientExpenseModal.expenseDepartment.method = this.method;
    },
    closeModal() {
      this.$emit("close");
    },
    async getData() {
      try {
        this.addPreloader();
        const data = await DashboardService.getMatchedImportExpensesCard({
          credit_card_expense_id: this.cardSelected.id,
          month: this.dataMonth,
          year: this.dataYear,
          page: this.paginate.currentPage,
          perpage: this.paginate.perPage,
        });
        this.matchTransaction = data.data;
        this.totalRows = data.total;
        this.startPage = data.from;
        this.toPage = data.to;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        if (this.matchTransaction.length > 0) {
          this.totalAmount = this.matchTransaction[0].total_amount;
        }
        return this.matchTransaction;
      } catch (error) {
        console.log(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>

<style scoped lang="scss" scoped>
.table-new-customization::v-deep tfoot {
  position: sticky;
  bottom: 0;
  color: rgba(63, 122, 250, 0.882) !important;
  font-size: 14.5px;
  font-weight: bold !important;
}

.table-new-customization::v-deep tfoot tr th {
  font-size: 13px;
  background: rgba(63, 122, 250, 0.882) !important;
  color: #fff !important;
}

.table-new-customization::v-deep tfoot tr {
  position: sticky !important;
  bottom: 0 !important;
}

.table-new-customization::v-deep tfoot tr th:first-child {
  border-radius: 0 0 0 15px !important;
}

.table-new-customization::v-deep tfoot tr th:last-child {
  border-radius: 0 0 15px 0 !important;
}
</style>