<template>
  <div>
    <b-modal
      ref="modal-sentiment-analysis-of-calls"
      header-bg-variant="transparent border-bottom border-bottom-2"
      size="xmd"
      header-class="p-0"
      modal-class="modal-primary"
      @hidden="$emit('close')"
      hide-footer
      scrollable
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <div v-if="dataObject" >
            <img
              :src="getProgramImageRoute(getProgramId(dataObject.pageName))" key=""
              alt="img_services"
              style="max-width: 35px; max-height: 35px"
            />
          </div>
          <span class="align-self-center" >Sentiment Analysis - {{nameSentiment}}  </span>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="$emit('close')"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>
      <div> 
        <ChatSentimentGrid :send-sentiment="nameSentiment" :year="year" :month="month" :page-id="dataObject._id" />
      </div>
    </b-modal>
  </div>
</template>
<script>
import ChatSentimentGrid from "@/views/commons/Messenger/sentiment-analysis/components/ChatSentimentGrid.vue";
export default {
  name: "SentimentAnalysisOfCallsModal",
  components: {
    ChatSentimentGrid,
  },
  props: {
    dataObject : {
      type: Object,
      default: null
    },
    nameSentiment : {
      type: String,
      default: null
    }, 
    year: {
      type: Number,
      default: null
    },
    month: {
      type: Number,
      default: null
    },
  },
  data() {
    return {};
  },
  computed: {

  },
  created() {},
  mounted() {
    this.toggleModal("modal-sentiment-analysis-of-calls");
  },
  methods: {
    getProgramId(page_name) {  
      const programs = {
        BUSINESS: 1,
        "BOOST CREDIT": 2,
        "CREDIT EXPERTS": 3,
        "DEBT SOLUTION": 4,
        "TAX RESEARCH": 5,
        "COURT INFO": 6,
        SPECIALIST: 7,
        KEYBOOK: 8,
        PARAGON: 9,
      };
      page_name = page_name.trim().toUpperCase();
      const programId = programs[page_name] ?? null;
      return programId;
    },
  },
};
</script>
<style lang="scss">
.header-modal {
    position: relative;
    padding: 20px 0 20px 15px;
    background: rgba(22, 84, 204, 1);
    width: 100%;
    font-size: 22px;
    display: flex;

    span {
      font-size: 1.6rem;
      font-weight: bold;
      margin-left: 8px;
      color: #ffffff;
    }

    .container-icons {
      background-color: #ffffff;
      padding: 2.7px 6.37px;
      border-radius: 0.357rem;
      width: 31px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 15px;
      right: 20px;
      transition: all 0.23s ease 0.1s;

      &--dark {
        background-color: #17171a;
      }

      &:hover {
        transform: translate(-7px, 5px);
        cursor: pointer;
      }
    }
  }
</style>