<template>
  <div>
    <b-modal
      ref="modalName"
      title-class="h3 text-white"
      :title="`CONFIRM COLLECT | CHECK #${checkData.check_number}`"
      size="lg"
      @hidden="closeModal()"
      centered
      no-close-on-backdrop
      modal-class="custom-modal-amg"
    >
      <validation-observer ref="fileForm">
        <b-row>
          <b-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              name="check_number"
              rules="required"
            >
              <b-form-group label="Collect Date">
                <b-form-datepicker
                  id="date"
                  v-model="form.collect_date"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  :min="checkData.check_updated_at"
                  :max="checkData.collect_date"
                  :state="errors.length > 0 ? false : null"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              name="file"
              rules="required"
            >
              <b-form-group label="Check File">
                <b-input-group>
                  <template #append>
                    <div
                      class="d-flex justify-content-center align-items-center"
                      v-if="fileToUpload"
                    >
                      <feather-icon
                        icon="FileIcon"
                        class="cursor-pointer text-primary ml-1"
                        size="20"
                        v-b-tooltip.hover.top="'Preview'"
                        @click="previewFile(fileToUpload)"
                      />
                      <feather-icon
                        icon="TrashIcon"
                        class="cursor-pointer text-danger ml-1"
                        size="20"
                        v-b-tooltip.hover.top="'Remove'"
                        @click="removeFile"
                      />
                    </div>
                  </template>
                  <b-form-file v-model="fileToUpload" @input="fileFill" />
                </b-input-group>
                <span v-if="errors[0]" class="text-danger">
                  File {{ errors[0] }}</span
                >
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <b-button variant="custom-amg" @click="confirm()" @input="fileFill">
          Confirm
        </b-button>
      </template>

      <image-viewer
        v-if="previewUrl && !isPdf"
        :objectImages="[previewUrl]"
        :route="previewUrl"
        :showBottom="false"
        @hidden="clearPreview"
        style="z-index: 1002 !important"
      />
    </b-modal>

    <preview-pdf
      v-if="previewUrl && isPdf"
      :pdf-url="previewUrl"
      :pdf-name="fileName"
      @close="clearPreview"
    />
  </div>
</template>
<script>
import DashboardService from "@/views/ceo/service-dashboard/dashboard.service";
import { mapGetters, mapActions } from "vuex";
import PreviewPdf from "@/views/debt-solution/views/court-info/components/PreviewPdf.vue";
import ImageViewer from "@/views/commons/components/paragon-soft/components/ImageViewer.vue";

export default {
  props: {
    checkData: {
      type: Object,
      required: true,
    },
  },
  components: {
    PreviewPdf,
    ImageViewer,
  },
  data() {
    return {
      form: {
        check_number: null,
        check_id: null,
        department_expense_id: null,
        type: null,
        is_collected: true,
        collect_date: null,
      },
      fileToUpload: null,
      previewUrl: null,
      fileName: null,
      fileType: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isPdf() {
      return this.fileType === "application/pdf";
    },
  },
  mounted() {
    this.toggleModal("modalName");
  },
  created() {
    this.form.check_id = this.checkData.check_id;
    this.form.department_expense_id = this.checkData.department_expense_id;
    this.form.type = this.checkData.type;
    this.form.check_number = this.checkData.check_number;
    this.form.user_id = this.currentUser.user_id;
  },
  methods: {
    ...mapActions({
      refreshExpensesCounter: "ExpensesRequest/refreshExpensesCounter",
    }),
    previewFile(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewUrl = e.target.result;
          this.fileName = file.name;
          this.fileType = file.type;
        };
        reader.readAsDataURL(file);
      }
    },
    removeFile() {
      this.fileToUpload = null;
      this.previewUrl = null;
    },
    clearPreview() {
      this.previewUrl = null;
      this.fileName = null;
    },
    async confirm() {
      const result = await this.$refs.fileForm.validate();
      if (!result) return;
      const confirm = await this.showConfirmSwal(
        "Are you sure the check was collected?"
      );
      if (!confirm.isConfirmed) return false;

      try {
        this.addPreloader();
        const data = await DashboardService.changeStatusCollect(this.form);
        if (data.success) {
          this.showToast(
            "success",
            "top-right",
            "Success",
            "CheckIcon",
            "Successfully process"
          );
          this.refreshExpensesCounter(true);
          this.$emit("reload");
          this.$emit("close");
        } else {
          this.showToast(
            "danger",
            "top-right",
            "Oop!",
            "AlertOctagonIcon",
            "Failed process"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async fileFill() {
      this.form.namedoc = this.fileToUpload.name;
      this.form.size = this.fileToUpload.size;
      const reader = new FileReader();
      reader.readAsDataURL(this.fileToUpload);
      reader.onload = async (file) => {
        this.form.file = file.target.result;
      };
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>