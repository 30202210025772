<template>
  <b-container class="mt-1">
    <h4>Basic Information</h4>
    <validation-observer ref="basicInformationObserver">
      <b-row>
        <b-col>
          <b-form-group label="Creditor Parent" label-for="creditorParent">
            <vue-autosuggest
              ref="autocomplete"
              v-model="userData.parentName"
              :suggestions="filteredOptions"
              :get-suggestion-value="getSuggestionValue"
              :input-props="{
                id: 'creditorParent',
                class: 'form-control',
                disabled: onlyView
              }"
              :limit="10"
              @input="searchCreditorParent"
              @selected="selectHandler"
            >
              <template slot-scope="{ suggestion }">
                <span class="my-suggestion-item">
                  {{ suggestion.item.value }}
                </span>
              </template>
            </vue-autosuggest>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <validation-provider
            name="Creditor Name"
            rules="required|max:255"
            v-slot="{ errors }"
          >
            <b-form-group label="Creditor Name" label-for="creditorName">
              <b-form-input
                id="creditorName"
                v-model="userData.creditorName"
                name="creditorName"
                autofocus
                maxlength="255"
                :disabled="onlyView"
                :state="errors[0] ? false : null"
                :class="errors[0] ? 'rounded border border-danger' : ''"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col>
          <validation-provider v-slot="{ errors }" rules="max:200">
            <b-form-group label="Address" label-for="address">
              <vue-google-autocomplete
                id="address"
                ref="address"
                class="form-control input-form"
                placeholder="Please type your address"
                country="us"
                :disabled="onlyView"
                :style="errors[0] ? 'border:1px solid red !important;' : ''"
                :class="errors[0] ? 'rounded border border-danger' : ''"
                :state="errors[0] ? false : null"
                v-on:placechanged="getCreditorAddress"
                v-model="userData.creditorAddress"
                style="height: 30px !important"
                @keyup="(e) => onChangeAddress(e, userData)"
              ></vue-google-autocomplete>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="1" class="d-flex align-items-center justify-content-center">
          <b-button
            size="sm"
            variant="outline-secondary"
            class="btn-icon p-0"
            :disabled="onlyView"
            @click="clearAddress"
            ><feather-icon icon="XIcon" />
          </b-button>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col sm="6">
          <validation-provider v-slot="{ errors }" rules="max:50">
            <b-form-group label="City" label-for="city">
              <b-form-input
                id="city_address"
                maxlength="50"
                autofocus
                trim
                :disabled="onlyView"
                :state="errors[0] ? false : null"
                :class="errors[0] ? 'rounded border border-danger' : ''"
                name="city_address"
                v-model="userData.city_address"
              ></b-form-input>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col sm="6">
          <validation-provider name="state" rules="integer" v-slot="{ errors }">
            <b-form-group label="State" label-for="state">
              <v-select
                id="state_address"
                label="state"
                :options="G_STATUS_EEUU"
                :reduce="(el) => el.id"
                :disabled="onlyView"
                v-model="userData.state_address"
                :style="errors[0] ? 'border: 1px solid red !important;' : ''"
                :state="errors[0] ? false : null"
                :class="errors[0] ? 'rounded border border-danger' : ''"
              ></v-select>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col sm="6">
          <validation-provider v-slot="{ errors }" rules="max:10">
            <b-form-group label="Zip code" label-for="zipcode">
              <b-form-input
                maxlength="10"
                autofocus
                id="zipcode_address"
                name="zipcode_address"
                ref="zipcode_address"
                :disabled="onlyView"
                v-model="userData.zip_code_address"
                :class="errors[0] ? 'rounded border border-danger' : ''"
                :style="errors[0] ? 'border:1px solid red !important;' : ''"
                :state="errors[0] ? false : null"
                v-mask="'######'"
              ></b-form-input>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col sm="6">
          <validation-provider name="country" rules="integer" v-slot="{ errors }">
            <b-form-group label="Country" label-for="country">
              <v-select
                id="country_address"
                label="name"
                :options="G_COUNTRIES"
                :reduce="(el) => el.id"
                :disabled="onlyView"
                v-model="userData.country_address"
                :style="errors[0] ? 'border:1px solid red !important;' : ''"
                :state="errors[0] ? false : null"
                :class="errors[0] ? 'rounded border border-danger' : ''"
              ></v-select>
            </b-form-group>
          </validation-provider>
        </b-col>

      </b-row>

      <b-row class="mt-1">
        <b-col>
          <validation-provider name="address" v-slot="{ errors }">
            <b-form-group label="Mailing Address" label-for="mailingAddress">
              <vue-google-autocomplete
                id="mailingAddress"
                ref="refMailingAddress"
                class="form-control input-form"
                placeholder="Please type your mailing address"
                country="us"
                :disabled="onlyView"
                v-model="userData.creditorMailingAddress"
                v-on:placechanged="getCreditorMailingAddressData"
                style="height: 30px !important"
                :style="errors[0] ? 'border:1px solid red !important;' : ''"
                :class="errors[0] ? 'rounded border border-danger' : ''"
                :state="errors[0] ? false : null"
                @keyup="(e) => onChangeMaillingAddress(e, userData)"
              ></vue-google-autocomplete>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="1" class="d-flex align-items-center justify-content-center">
          <b-button
            size="sm"
            variant="outline-secondary"
            class="btn-icon p-0"
            :disabled="onlyView"
            @click="clearMailingAddress"
            ><feather-icon icon="XIcon" />
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="6">
          <validation-provider v-slot="{ errors }" rules="max:50">
            <b-form-group label="City" label-for="city">
              <b-form-input
                id="city"
                maxlength="50"
                autofocus
                trim
                :disabled="onlyView"
                :state="errors[0] ? false : null"
                :class="errors[0] ? 'rounded border border-danger' : ''"
                name="city"
                v-model="userData.creditorCity"
              ></b-form-input>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col sm="6">
          <validation-provider name="state" rules="integer" v-slot="{ errors }">
            <b-form-group label="State" label-for="state">
              <v-select
                id="state"
                label="state"
                :options="G_STATUS_EEUU"
                :reduce="(el) => el.id"
                :disabled="onlyView"
                v-model="userData.creditorState"
                :style="errors[0] ? 'border: 1px solid red !important;' : ''"
                :state="errors[0] ? false : null"
                :class="errors[0] ? 'rounded border border-danger' : ''"
              ></v-select>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="6">
          <validation-provider v-slot="{ errors }" rules="max:10">
            <b-form-group label="Zip code" label-for="zipcode">
              <b-form-input
                maxlength="10"
                autofocus
                id="zipcode"
                name="zipcode"
                ref="zipcode"
                :disabled="onlyView"
                v-model="userData.creditorZipCode"
                :class="errors[0] ? 'rounded border border-danger' : ''"
                :style="errors[0] ? 'border:1px solid red !important;' : ''"
                :state="errors[0] ? false : null"
                v-mask="'######'"
              ></b-form-input>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col sm="6">
          <validation-provider name="country" rules="integer" v-slot="{ errors }">
            <b-form-group label="Country" label-for="country">
              <v-select
                id="country"
                label="name"
                :options="G_COUNTRIES"
                :reduce="(el) => el.id"
                :disabled="onlyView"
                v-model="userData.creditorCountry"
                :style="errors[0] ? 'border:1px solid red !important;' : ''"
                :state="errors[0] ? false : null"
                :class="errors[0] ? 'rounded border border-danger' : ''"
              ></v-select>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
  </b-container>
</template>
<script>
import { VueAutosuggest } from "vue-autosuggest";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { ValidationObserver } from "vee-validate";
import CreditorsService from "../../service/creditors.service";
import { required, email } from "@validations";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "BasicInformationCreditorsEdit",
  props: {
    userData: {
      type: Object,
      required: true,
    },
    onlyView: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ValidationObserver,
    VueAutosuggest,
    VueGoogleAutocomplete,
  },
  data() {
    return {
      section_required: false,
      required,
      email,
      dataCreditor: [],
      filteredOptions: [],
      isDisabledPhone: false,
      isDisabledFax: false,
      isDisabledEmail: false,
      isDisabledPhoneCustoner: false,
      showPhone: false,
      showFax: false,
      showEmail: false,
      showPhoneCustoner: false,
      dataPhone: [],
      dataFax: [],
      dataEmail: [],
      options: [
        { text: "Yes", value: "1" },
        { text: "No", value: "2" },
      ],
    };
  },
  methods: {
    onChangeAddress(event, data){
      this.$set(data, 'creditorAddress', event.target.value)
    },
    onChangeMaillingAddress(event, data){
      this.$set(data, 'creditorMailingAddress', event.target.value)
    },
    clearMailingAddress() {
      this.userData.creditorMailingAddress = "";
      this.$refs.refMailingAddress.$el.value = "";
    },
    clearAddress() {
      this.userData.creditorAddress = "";
      this.$refs.address.$el.value = "";
    },
    async searchCreditorParent(text) {
      this.userData.parentName = null;
      if (text === "" || text === undefined) {
        this.filteredOptions = [];
        return;
      }
      try {
        const data = await CreditorsService.GET_CREDITORS_PARENTS({ q: text });
        this.filteredOptions = [{ data: data }];
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    selectHandler(data) {
      this.user_id = data.item.id;
      this.creditorParent = data.item.value;
      this.users = null;
      this.statusSelected = false;
    },
    getSuggestionValue(suggestion) {
      this.userData.parent_id = suggestion.item.id;
      return suggestion.item.value;
    },
    getCreditorAddress(data, placeResultData) {
      this.$refs.address.$el.value = placeResultData.formatted_address;
      this.userData.creditorAddress = placeResultData.formatted_address;
      
      this.userData.zip_code_address = data.postal_code;
      this.userData.city_address = data.locality;
      this.userData.state_address = this.G_STATUS_EEUU.find(
        (e) => e.slug === data.administrative_area_level_1
      ).id;
      this.userData.country_address = this.G_COUNTRIES.find(
        (e) => e.name.toLowerCase() == "estados unidos"
      ).id;
    },
    getCreditorMailingAddressData(data, placeResultData, id) {
      this.$refs.refMailingAddress.$el.value = `${data.street_number || ""} ${
        data.route
      }`;
      this.userData.creditorMailingAddress = `${
        data.street_number ? data.street_number : ""
      } ${data.route}`;
      this.$refs.zipcode.$el.value = data.postal_code;
      this.userData.creditorZipCode = data.postal_code;
      this.userData.creditorCity = data.locality;
      this.userData.creditorState = this.G_STATUS_EEUU.find(
        (e) => e.slug === data.administrative_area_level_1
      ).id;
      this.userData.creditorCountry = this.G_COUNTRIES.find(
        (e) => e.name.toLowerCase() == "estados unidos"
      ).id;
    },
    setRequiredSection(error) {
      if (error) {
        this.$emit("section_required", [true]);
      } else {
        this.$emit("section_required", [false]);
      }
    },
    async validate(){
      return await this.$refs.basicInformationObserver.validate();
    },
    ...mapActions({
      A_GET_STATUS_EEUU:
        "DebtSolutionCreditors/A_GET_STATUS_EEUU",
      A_GET_COUNTRIES:
        "DebtSolutionCreditors/A_GET_COUNTRIES"
    }),
  },
  computed: {
    ...mapGetters({
      G_STATUS_EEUU: "DebtSolutionCreditors/G_STATUS_EEUU",
      G_COUNTRIES: "DebtSolutionCreditors/G_COUNTRIES",
    }),
  },
  mounted(){
    this.A_GET_STATUS_EEUU()
    this.A_GET_COUNTRIES()
  }
};
</script>
<style lang="scss">
.paddingB {
  padding-bottom: 4px !important;
}
.paddingT {
  padding-top: 4px !important;
}
.ppaddingTP {
  padding-top: 23px !important;
}
.paddingIconoX {
  padding-top: 8px !important;
  padding-left: 3px !important;
}
.widthInput {
  width: 96%;
}
.widthInputPhone {
  width: 80%;
}
</style>
