import { amgApi } from "@/service/axios";

class DashboardService {
  /* Retainer Fee */
  async getDebtSolutionAnnualGraphicFee(params) {
    try {
      const { data } = await amgApi.post('commons/fee/graphics/index', params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDebtSolutionRetainerFee(params) {
    try {
      const { data } = await amgApi.post('commons/fee/index', { ...params, program_id: 4 });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDetailedRetainerFee(params) {
    try {
      const { data } = await amgApi.post('commons/fee/details/index', { ...params, program_id: 4 });
      return data;
    } catch (error) {
      throw error;      
    }
  }

  //settlements
  async getDebtSolutionSettlementAmounts(params) {
    try {
      const data = await amgApi.post('commons/settlement/index', params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDebtSolutionDetailedSettlement(params) {
    const type = 'detail';
    try {
      const data = await amgApi.post('commons/settlement/show', { ...params, type });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDebtSolutionAnnualSettlement(params) {
    const type = 'annual';
    try {
      const data = await amgApi.post('commons/settlement/show', { ...params, type });
      return data;
    } catch (error) {
      throw error;
    }
  }

  //To negotiate
  async getDebtSolutionBalance(params) {
    try {
      const { data } = await amgApi.post('commons/balance/index', params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDetailedDebtSolutionBalance(params) {
    try {
      const { data } = await amgApi.post('commons/balance/show', params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  //others
  async getDebtSolutionOthers(params) {
    try {
      const data = await amgApi.post('commons/others/index', params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDebtSolutionDetailedOthers(params) {
    try {
      const { data } = await amgApi.post('commons/others/details/index', { ...params, program_id: 4 });
      return data;
    } catch (error) {
      throw error;
    }
  }
  
  async getDebtSolutionAnnualGraphicOthers(params) {
    try {
      const { data } = await amgApi.post('commons/others/graphics/index', { ...params, program_id: 4 });
      return data;
    } catch (error) {
      throw error;
    }
  }


}
export default new DashboardService();
