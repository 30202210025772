<template>
  <div class="daily-settlement-merchants">
    <b-row class="p-2">
      <b-col cols="12" class="d-flex align-items-center justify-content-start">
        <div class="d-flex align-items-center" style="gap: 1rem; width: 96%">
          <div class="p-1 rounded bg-primary">
            <svg
              width="26"
              height="26"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 11H13V7H15C15.2652 7 15.5196 7.10536 15.7071 7.29289C15.8946 7.48043 16 7.73478 16 8C16 8.26522 16.1054 8.51957 16.2929 8.70711C16.4804 8.89464 16.7348 9 17 9C17.2652 9 17.5196 8.89464 17.7071 8.70711C17.8946 8.51957 18 8.26522 18 8C18 7.20435 17.6839 6.44129 17.1213 5.87868C16.5587 5.31607 15.7956 5 15 5H13V3C13 2.73478 12.8946 2.48043 12.7071 2.29289C12.5196 2.10536 12.2652 2 12 2C11.7348 2 11.4804 2.10536 11.2929 2.29289C11.1054 2.48043 11 2.73478 11 3V5H10C8.93913 5 7.92172 5.42143 7.17157 6.17157C6.42143 6.92172 6 7.93913 6 9C6 10.0609 6.42143 11.0783 7.17157 11.8284C7.92172 12.5786 8.93913 13 10 13H11V17H9C8.73478 17 8.48043 16.8946 8.29289 16.7071C8.10536 16.5196 8 16.2652 8 16C8 15.7348 7.89464 15.4804 7.70711 15.2929C7.51957 15.1054 7.26522 15 7 15C6.73478 15 6.48043 15.1054 6.29289 15.2929C6.10536 15.4804 6 15.7348 6 16C6 16.7956 6.31607 17.5587 6.87868 18.1213C7.44129 18.6839 8.20435 19 9 19H11V21C11 21.2652 11.1054 21.5196 11.2929 21.7071C11.4804 21.8946 11.7348 22 12 22C12.2652 22 12.5196 21.8946 12.7071 21.7071C12.8946 21.5196 13 21.2652 13 21V19H14C15.0609 19 16.0783 18.5786 16.8284 17.8284C17.5786 17.0783 18 16.0609 18 15C18 13.9391 17.5786 12.9217 16.8284 12.1716C16.0783 11.4214 15.0609 11 14 11ZM11 11H10C9.46957 11 8.96086 10.7893 8.58579 10.4142C8.21071 10.0391 8 9.53043 8 9C8 8.46957 8.21071 7.96086 8.58579 7.58579C8.96086 7.21071 9.46957 7 10 7H11V11ZM14 17H13V13H14C14.5304 13 15.0391 13.2107 15.4142 13.5858C15.7893 13.9609 16 14.4696 16 15C16 15.5304 15.7893 16.0391 15.4142 16.4142C15.0391 16.7893 14.5304 17 14 17Z"
                fill="white"
              />
            </svg>
          </div>
          <div class="d-flex justify-content-between w-100">
            <div class="settlements-info">
              <span class="title">Transactions By Merchant</span>
              <span class="secondary-title"
                >Transactions Date:
                {{ G_DAILY_SETTLEMENTS_DATE_RANGE.startDate | myGlobal }} -
                {{ G_DAILY_SETTLEMENTS_DATE_RANGE.endDate | myGlobal }}</span
              >
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row
      class="p-2 mx-255 d-flex justify-content-center align-items-center"
      v-if="!hide"
    >
      <b-col
        lg="3"
        v-for="(merchant, index) in G_GET_DAILY_SETTLEMENT_BY_MERCHANT"
        :key="index"
      >
        <b-card
          class="mx-2 rounded-lg merchant-card cursor-pointer"
          :class="[
            merchant.authorize == merchant.soft
              ? 'border-success'
              : 'border-danger',
          ]"
          @click="changeMerchant(merchant)"
        >
          <div class="rounded text-left">
            <b-row>
              <b-col
                :cols="
                  G_DAILY_SETTLEMENTS_DATE_RANGE.startDate ===
                  G_DAILY_SETTLEMENTS_DATE_RANGE.endDate
                    ? 10
                    : 12
                "
              >
                <p
                  class="merchant-title"
                  v-b-tooltip.hover.top="`${merchant.merchant}`"
                >
                  {{ merchant.merchant }}
                </p>
                <div>
                  <template v-if="merchant.programs">
                    <b-avatar
                      v-for="(program, index) in JSON.parse(merchant.programs)"
                      :key="index"
                      :src="getProgramImageRoute(program.id)"
                      v-b-tooltip.hover.top="program.name"
                      size="1.5rem"
                      style="margin-right: 0.5rem"
                    />
                  </template>
                </div>
              </b-col>
              <b-col
                cols="2"
                v-if="
                  G_DAILY_SETTLEMENTS_DATE_RANGE.startDate ===
                    G_DAILY_SETTLEMENTS_DATE_RANGE.endDate &&
                  G_DAILY_AUTHORIZE.length > 0 &&
                  merchant.authorize == merchant.soft
                "
                :style="
                  G_DAILY_AUTHORIZE[index].checked == 1
                    ? 'pointer-events: none; cursor: not-allowed'
                    : ''
                "
                @click.stop="handleChecked(merchant)"
              >
                <b-form-checkbox
                  style="pointer-events: none; cursor: not-allowed"
                  :disabled="
                    blockCheckbox || G_DAILY_AUTHORIZE[index].checked == 1
                  "
                  class="d-inline-block float-right"
                  :checked="G_DAILY_AUTHORIZE[index].checked"
                  value="1"
                  unchecked-value="0"
                />
              </b-col>
            </b-row>

            <div class="d-flex justify-content-between my-1">
              <span class="merchant-subtitle"> Authorize </span>
              <span class="merchant-subtitle"> AMG </span>
            </div>

            <div class="d-flex justify-content-between">
              <span class="merchant-amount">
                <animated-number
                  class="d-inline-block"
                  :number="merchant.authorize"
                  :currency="isCurrency"
                  :animationDuration="animationDuration"
                ></animated-number>
                <small class="transactions mt-1">
                  {{ merchant.authorize_count }} transactions.
                </small>
              </span>
              <span class="merchant-amount">
                <animated-number
                  class="d-inline-block"
                  :number="merchant.soft"
                  :currency="isCurrency"
                  :animationDuration="animationDuration"
                ></animated-number>
                <small class="transactions mt-1">
                  {{ merchant.soft_count }} transactions.</small
                >
              </span>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row
      class="p-2 mx-255 d-flex justify-content-center align-items-center"
      v-if="hide"
    >
      <b-col
        lg="3"
        v-for="(merchant, index) in G_GET_DAILY_SETTLEMENT_BY_MERCHANT"
        :key="index"
      >
        <b-card class="mx-2 rounded-lg merchant-card cursor-pointer">
          <b-skeleton-img no-aspect height="100%" width="100%"></b-skeleton-img>
        </b-card>
      </b-col>
    </b-row>
    <daily-settlement-detail-by-merchant
      v-if="showMerchantDetail"
      @hidden="
        (showMerchantDetail = false), resetCurrentDailySettlementMerchant()
      "
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import DailySettlementDetailByMerchant from "@/views/commons/components/reports/tabs/daily-payment-report/components/DailySettlemenDetailByMerchant.vue";
import PaymentService from "@/views/crm/views/payments/service/payments.service.js";
import DailyAuthorizeFormModal from "@/views/commons/components/reports/tabs/daily-payment-report/components/DailyAuthorizeFormModal.vue";

export default {
  computed: {
    ...mapGetters({
      G_DAILY_SETTLEMENTS_DATE_RANGE:
        "ManagementReportStore/G_DAILY_SETTLEMENTS_DATE_RANGE",
      G_GET_DAILY_SETTLEMENT_BY_MERCHANT:
        "ManagementReportStore/G_GET_DAILY_SETTLEMENT_BY_MERCHANT",
      G_CURRENT_DAILY_SETTLEMENT_MERCHANT:
        "ManagementReportStore/G_CURRENT_DAILY_SETTLEMENT_MERCHANT",
      G_DAILY_AUTHORIZE: "ManagementReportStore/G_DAILY_AUTHORIZE",
      G_GET_DAILY_SETTLEMENT_PIE:
        "ManagementReportStore/G_GET_DAILY_SETTLEMENT_PIE",
      G_GET_DAILY_SETTLEMENT: "ManagementReportStore/G_GET_DAILY_SETTLEMENT",
    }),
    blockCheckbox() {
      return this.G_DAILY_AUTHORIZE.every((item) => item.checked == 1);
    },
  },
  data() {
    return {
      isCurrency: true,
      animationDuration: 1000,
      showMerchantDetail: false,
      hide: true,
      count_matched: 0,
      showCheckbox: false,
      showAuthorizeForm: false,
      selectedMerchant: null,
    };
  },
  components: {
    DailySettlementDetailByMerchant,
    DailyAuthorizeFormModal,
  },
  watch: {
    G_DAILY_SETTLEMENTS_DATE_RANGE(newValue, oldValue) {
      this.fetchSettlementsByMerchant();
      this.changeDailyAuthorize();
    },
    G_GET_DAILY_SETTLEMENT(newValue, oldValue) {
      this.fetchSettlementsByMerchant();
    },
    G_DAILY_AUTHORIZE(newVal, oldVal) {
      if (newVal != oldVal) {
        this.checkShowCheckBox();
      }
    },
  },
  async mounted() {
    this.fetchSettlementsByMerchant();
  },
  methods: {
    checkShowCheckBox() {
      this.showCheckbox = this.G_DAILY_AUTHORIZE.filter(
        (item) => item.merchant_id !== null
      ).every((item) => parseInt(item.checked) === 1);
    },
    ...mapActions({
      A_GET_DAILY_SETTLEMENT_BY_MERCHANT:
        "ManagementReportStore/A_GET_DAILY_SETTLEMENT_BY_MERCHANT",
      A_CURRENT_DAILY_SETTLEMENT_MERCHANT:
        "ManagementReportStore/A_CURRENT_DAILY_SETTLEMENT_MERCHANT",
      A_GET_DAILY_AUTHORIZE_TITLE_COUNTER:
        "ManagementReportStore/A_GET_DAILY_AUTHORIZE_TITLE_COUNTER",
    }),
    ...mapMutations({
      M_DAILY_AUTHORIZE: "ManagementReportStore/M_DAILY_AUTHORIZE",
    }),
    resetCurrentDailySettlementMerchant() {
      this.A_CURRENT_DAILY_SETTLEMENT_MERCHANT({
        merchantId: null,
        merchantName: null,
      });
    },
    async fetchSettlementsByMerchant() {
      try {
        this.hide = true;
        await this.A_GET_DAILY_SETTLEMENT_BY_MERCHANT({
          range: this.G_DAILY_SETTLEMENTS_DATE_RANGE,
        });
        if (this.G_GET_DAILY_SETTLEMENT_PIE[0]) {
          this.count_matched =
            this.G_GET_DAILY_SETTLEMENT_PIE[0].value +
            (this.G_GET_DAILY_SETTLEMENT_PIE?.[1]?.value || 0);
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.hide = false;
      }
    },
    changeMerchant(merchant) {
      if (
        this.A_CURRENT_DAILY_SETTLEMENT_MERCHANT.merchantId ==
        merchant.merchant_id
      )
        return;
      this.showMerchantDetail = true;
      this.A_CURRENT_DAILY_SETTLEMENT_MERCHANT({
        merchantId: merchant.merchant_id,
        merchantName: merchant.merchant,
      });
    },

    async changeCurrentCheckboxStatus(status) {
      let index = this.G_DAILY_AUTHORIZE.findIndex(
        (e) => e.merchant_id == this.selectedMerchant.merchant_id
      );
      let copy = JSON.parse(JSON.stringify(this.G_DAILY_AUTHORIZE));
      copy[index].checked = status;
      this.M_DAILY_AUTHORIZE(copy);
    },

    async handleChecked(merchant) {
      this.selectedMerchant = merchant;
      this.changeCurrentCheckboxStatus(1);
      this.registerCheckedMerchant(merchant);
    },
    async registerCheckedMerchant(item) {
      try {
        this.addPreloader();
        let params = {
          date: this.G_DAILY_SETTLEMENTS_DATE_RANGE.startDate,
          merchant_id: this.selectedMerchant.merchant_id,
          authorize_amount: item.authorize,
        };
        const { data } = await PaymentService.toogleDailyAuthorize(params);

        let dailyAuthorize = JSON.parse(JSON.stringify(this.G_DAILY_AUTHORIZE));
        let index = dailyAuthorize.findIndex(
          (item) => item.merchant_id === this.selectedMerchant.merchant_id
        );
        if (index !== -1) {
          dailyAuthorize[index].checked = data.value;
          this.showGenericToast({
            position: "top-right",
            variant: "success",
            icon: "CheckIcon",
            title: "Success",
            text: data.message,
          });
        }
        await this.M_DAILY_AUTHORIZE(dailyAuthorize);
        let firstFourChecked = this.G_DAILY_AUTHORIZE.slice(0, 4);
        let allChecked = firstFourChecked.every((item) => item.checked == 1);
        if (allChecked) {
          this.handleCheckedDay();
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async handleCheckedDay() {
      try {
        let dateString = this.G_DAILY_SETTLEMENTS_DATE_RANGE.startDate;
        const date = new Date(dateString);
        const options = { month: "2-digit", day: "2-digit", year: "numeric" };
        const formattedDate = date.toLocaleDateString("en-US", options);

        const result = await this.showConfirmSwal(
          `VALIDATE DAY ${formattedDate} ?`
        );
        if (result.isConfirmed) {
          let params = {
            date: this.G_DAILY_SETTLEMENTS_DATE_RANGE.startDate,
            merchant_id: null,
          };
          const { data } = await PaymentService.toogleDailyAuthorize(params);

          let dailyAuthorize = JSON.parse(
            JSON.stringify(this.G_DAILY_AUTHORIZE)
          );
          let index = dailyAuthorize.findIndex(
            (item) => item.merchant_id === null
          );
          if (index !== -1) {
            dailyAuthorize[index].checked = data.value;
            this.showGenericToast({
              position: "top-right",
              variant: "success",
              icon: "CheckIcon",
              title: "Success",
              text: "The day has been validated",
            });
          }
          this.M_DAILY_AUTHORIZE(dailyAuthorize);
        }
        this.A_GET_DAILY_AUTHORIZE_TITLE_COUNTER();
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async changeDailyAuthorize() {
      try {
        let params = {
          date: this.G_DAILY_SETTLEMENTS_DATE_RANGE.startDate,
        };
        const { data } = await PaymentService.getDailyAuthorize(params);
        this.M_DAILY_AUTHORIZE(data);
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>

<style lang="scss">
.daily-settlement-merchants {
  .title {
    font-size: 1.2rem;
    font-weight: 500;
    color: #000000;
    font-family: "Montserrat", sans-serif;
    line-height: 1.5rem;
  }

  .secondary-title {
    color: #868686;
    font-size: 1.2rem;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    line-height: 1.5rem;
    margin-top: 0.5rem;
  }
  .settlements-info {
    display: grid;
    grid-template-rows: auto;
  }

  .merchant-title {
    font-size: 1.2rem;
    font-weight: 700;
    color: #606060;
    font-family: "Montserrat", sans-serif;
    line-height: 1.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
  }

  .merchant-subtitle {
    color: #606060;
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    font-weight: 800;
  }

  .merchant-amount {
    color: #000000;
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .transactions {
    color: #000000;
    font-family: "Montserrat", sans-serif;
    font-size: 0.8rem;
  }
  .merchant-card {
    box-shadow: 2px 2px 9px 3px rgba(34, 41, 47, 0.1) !important;
    background-color: #f9f9f9 !important;
  }
  .line {
    border-color: #606060;
    height: 1px;
  }

  .mx-255 {
    margin-left: 2.55rem !important;
    margin-right: 2.55rem !important;
  }

  .primary-border {
    border: 2px solid #0090e7 !important;
    box-shadow: none !important;
  }
}

.dark-layout {
  .daily-settlement-merchants {
    .merchant-card {
      background-color: #111113 !important;
      box-shadow: none !important;
    }
    .merchant-title {
      color: #b4b4b4;
    }
    .line {
      border-color: #606060;
    }
    .merchant-amount {
      color: #ffffff;
    }
    .transactions {
      color: #ffffff;
    }
    .merchant-subtitle {
      color: #b4b4b4;
    }
    .title {
      color: #ffffff;
    }
  }
}
</style>