<template>
  <b-modal
    ref="modalAttendanceCeo"
    modal-class="modal-primary"
    title-class="h3 text-white font-weight-bolder"
    :title="`Attendance Detail ${titleMonthYear}`"
    size="lg"
    @hidden="closeModal()"
    hide-footer
    centered
    no-close-on-backdrop
  >
    <div class="card-payroll-report">
      <div
        style="min-width: 280px"
        class="d-flex justify-content-end align-items-center mb-1 px-1"
      >
        <div class="absolute-linker pt-2 pb-1" :class="{ dark: isDarkSkin }">
          <div
            class="d-flex justify-content-between align-items-center h-full px-1"
            style="font-size: 15px; font-weight: bold"
          >
            <feather-icon
              icon="ChevronLeftIcon"
              size="20"
              class="cursor-pointer"
              @click="prev"
            />
            <span> {{ titleMonthYear }} </span>
            <feather-icon
              icon="ChevronRightIcon"
              size="20"
              class="cursor-pointer"
              :class="{ 'disabled-next': disableNext }"
              @click="next"
            />
          </div>
        </div>
      </div>
      <b-table
        id="expense-payroll-table-ceo"
        ref="expense-payroll-table-ceo"
        slot="table"
        class="position-relative table-new-customization"
        empty-text="No matching records found"
        sticky-header="70vh"
        primary-key="id"
        responsive="md"
        show-empty
        no-border-collapse
        no-provider-filtering
        :busy.sync="isBusy"
        :fields="visibleFields"
        :items="items"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(module_id)="{ item }">
          <div class="d-flex justify-content-center align-items-center">
            <img
              v-b-tooltip.hover.top="item.name"
              :src="`/assets/${item.icons}`"
              alt="Logo"
              height="30"
              width="30"
            />
          </div>
        </template>
        <template #cell(lates)="{ item }">
          <b-badge
            class="clickable"
            variant="light-warning"
            @click="openDetailAttendance(item, 'lates')"
          >
            {{ item.lates }}
            <feather-icon icon="ArrowUpRightIcon" size="20" />
          </b-badge>
        </template>
        <template #cell(absences)="{ item }">
          <b-badge
            class="clickable"
            variant="light-danger"
            @click="openDetailAttendance(item, 'absences')"
          >
            {{ item.absences }}
            <feather-icon icon="ArrowUpRightIcon" size="20" />
          </b-badge>
        </template>
        <template #bottom-row>
          <b-th>
            <b-badge
              variant="primary"
              class="w-100 text-center"
              style="
                font-size: 14px;
                font-weight: 600;
                padding-bottom: 7px;
                padding-top: 7px;
              "
            >
              TOTAL
            </b-badge>
          </b-th>
          <b-th>
            <b-badge
              variant="success"
              class="w-100 text-center text-black"
              style="
                font-size: 14px;
                font-weight: 600;
                padding-bottom: 7px;
                padding-top: 7px;
              "
            >
              {{ totalLates }}
            </b-badge>
          </b-th>
          <b-th>
            <b-badge
              variant="success"
              class="w-100 text-center text-black"
              style="
                font-size: 14px;
                font-weight: 600;
                padding-bottom: 7px;
                padding-top: 7px;
              "
            >
              {{ totalAbsences }}
            </b-badge>
          </b-th>
        </template>
      </b-table>
      <modal-detail-attendance
        v-if="showAttendanceDetail"
        :send-year="dataYear"
        :send-month="dataMonth"
        :module-selected="moduleSelected"
        :title-month-year="titleMonthYear"
        :type-selected="typeSelected"
        @close="showAttendanceDetail = false"
      />
    </div>
  </b-modal>
</template>
<script>
import moment from "moment";
import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service";
import ModalDetailAttendance from "@/views/ceo/dashboard/modals/attendance/ModalDetailAttendance.vue";
export default {
  name: "CardPayrollReport",
  components: {
    ModalDetailAttendance,
  },
  props: {
    sendYear: {
      type: Number,
      required: true,
    },
    sendMonth: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      moduleSelected: null,
      typeSelected: 1,
      fields: [
        {
          key: "module_id",
          label: "Module",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: false,
          visible: true,
        },
        {
          key: "lates",
          label: "Late",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: false,
          visible: true,
        },
        {
          key: "absences",
          label: "Absences",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: false,
          visible: true,
        },
      ],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      dataYear: null,
      dataMonth: null,
      showAttendanceDetail: false,
      totalLates: 0,
      totalAbsences: 0,
      totals: {},
      items: [],
    };
  },
  computed: {
    titleMonthYear() {
      return `${this.months[this.dataMonth - 1]} ${this.dataYear}`;
    },
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    disableNext() {
      const now = moment();
      const sameYear = now.year() === this.dataYear;
      const sameMonth = now.month() + 1 === this.dataMonth;
      return sameYear && sameMonth;
    },
  },
  mounted() {
    this.toggleModal("modalAttendanceCeo");
    this.reloadAttendance();
  },
  created() {
    this.initMonthYear();
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async reloadAttendance() {
      try {
        this.isBusy = true;
        const { absences, lates, grouped } =
          await DashboardServive.getCounterAttendance({
            month: this.dataMonth,
            year: this.dataYear,
          });
        this.dataAttendance = { absences, lates, grouped };
        this.totalLates = lates.reduce((acc, curr) => acc + curr.totals, 0);
        this.totalAbsences = absences.reduce(
          (acc, curr) => acc + curr.totals,
          0
        );
        this.items = grouped;
      } catch (error) {
        console.log(error);
      } finally {
        this.isBusy = false;
      }
    },
    initMonthYear() {
      this.dataMonth = this.sendMonth;
      this.dataYear = this.sendYear;
    },
    async next() {
      if (this.disableNext) return;
      if (this.dataMonth < 12) {
        this.dataMonth++;
      } else {
        this.dataMonth = 1;
        this.dataYear++;
      }
      await this.refresh();
    },
    async prev() {
      if (this.dataMonth > 1) {
        this.dataMonth--;
      } else {
        this.dataMonth = 12;
        this.dataYear--;
      }
      await this.refresh();
    },
    async refresh() {
      this.reloadAttendance();
    },
    openDetailAttendance(module, type) {
      this.typeSelected = type;
      this.moduleSelected = module;
      this.showAttendanceDetail = true;
    },
    getFieldsByKeys(keys) {
      return this.fields.find((field) => field.key === keys);
    },
    async handleTypeSearch() {
      await this.initMonthYear();
      await this.refresh();
    },
  },
};
</script>
<style lang="scss">
.card-payroll-report {
  #expense-payroll-table-ceo {
    .programs_image {
      background-color: #e9e9e9;
      padding: 0.2rem;
      border-radius: 10px;
      width: 3.2rem;
      height: 3.2rem;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .rounded {
      border-radius: 0.357rem !important;
    }
    .b-table-bottom-row {
      position: sticky;
      bottom: 0;
      z-index: 1;

      th:nth-child(1) {
        border-radius: 0px 0px 0px 15px !important;
        margin-left: 5px !important;
        background: #0090e7 !important;
      }

      th:nth-child(2),
      th:nth-child(3) {
        font-size: 16px;
        padding-top: 10px;
        padding-bottom: 10px;
        background: #0090e7 !important;
      }

      th:nth-child(4) {
        border-radius: 0px 0px 15px 0px !important;
        margin-left: 10px !important;
        background: #0090e7 !important;
      }
    }
    .fill-bottom-row {
      background-color: #ffffff !important;
    }
  }
}
.disabled-next {
  cursor: not-allowed;
}
</style>