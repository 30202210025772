export default [
  {
    type: 'datepicker',
    margin: true,
    showLabel: true,
    label: 'Completed Date From',
    placeholder: 'Date',
    class: 'font-small-3',
    model: null,
    locale: 'en',
    dateFormatOptions: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    cols: 6,
  },
  {
    type: 'datepicker',
    margin: true,
    showLabel: true,
    label: 'Completed Date To',
    placeholder: 'Date',
    class: 'font-small-3',
    model: null,
    locale: 'en',
    dateFormatOptions: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    cols: 6,
  },
];
