<template>
  <div class="message-reply-to" @click="searchMessage()">
    <div class="user-reply">
      <feather-icon icon="CornerUpRightIcon" size="14" />
      {{ userReply }}
    </div>
    <div
      class="message-content-reply"
      :class="{
        me: message.me,
        other: !message.me,
      }"
    >
      <p
        v-if="isMessageText"
        class="message-text"
        v-html="processMessageChat(replyTo.message)"
      ></p>
      <p v-if="isAttachment" class="message-text attachment">
        {{ replyTo.attachments.length }} attachment(s)
        <feather-icon icon="ImageIcon" size="16" />
      </p>
      <div v-if="replyTo.isSticker" class="message-text">
        <img class="message-sctiker" :src="replyTo.sticker" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "MessageReplyTo",
  props: {
    message: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      CURRENT_USER: "auth/currentUser",
    }),

    replyTo() {
      return this.message.replyTo;
    },
    isMessageText() {
      return this.replyTo.message !== "";
    },
    isAttachment() {
      return this.replyTo.attachments.length > 0;
    },
    userValidFrom() {
      return this.message.user && this.message.user.id;
    },
    userValidTo() {
      return this.replyTo.user && this.replyTo.user.id;
    },
    userReply() {
      const userFrom = this.userValidFrom
        ? this.message.user.name
        : this.message.sender.name;
      const userTo = this.userValidTo
        ? this.replyTo.user.name
        : this.replyTo.sender.name;

      if (this.isCurrentUserFrom) {
        return `You replied to ${userTo}`;
      }
      if (this.isCurrentUserTo) {
        return `${userFrom} replied to you`;
      }

      if (this.isCurrentUserFrom && this.isCurrentUserTo) {
        return `You replied to your own message`;
      }

      if (
        this.userValidFrom &&
        this.userValidTo &&
        this.message.user.id === this.replyTo.user.id
      ) {
        return `${userFrom} replied to their own message`;
      }

      return `${userFrom} replied to ${userTo}`;
    },
    isCurrentUserFrom() {
      return (
        this.userValidFrom && this.CURRENT_USER.user_id === this.message.user.id
      );
    },
    isCurrentUserTo() {
      return (
        this.userValidTo && this.CURRENT_USER.user_id === this.replyTo.user.id
      );
    },
  },
  created() {},
  mounted() {},
  methods: {
    processMessageChat(text) {
      const urlRegex =
        /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
      return text.replace(urlRegex, (url) => {
        return `<a href="${url}" class="message-url" target="_blank" rel="noopener noreferrer">${url}</a>`;
      });
    },
    ...mapActions({
      A_SET_SEARCH_MESSAGE: "MessengerMetaStore/A_SET_SEARCH_MESSAGE",
    }),
    searchMessage() {
      this.A_SET_SEARCH_MESSAGE(this.replyTo);
    },
  },
};
</script>
<style lang="scss">
.message-reply-to {
  position: relative;
  font-size: 0.89rem;
  color: #828282;
  white-space: pre-wrap;
  width: fit-content;
  cursor: pointer;
  .message-content-reply {
    position: relative;
    border-radius: 1rem 1rem 1rem 0;
    padding: 0.5rem 1rem 1rem;
    background-color: #cee3ff;
    bottom: -1.2rem;
    .message-text {
      margin: 0 !important;
      .message-sctiker {
        max-width: 40px !important;
        max-height: 40px !important;
      }
    }
    .message-text.attachment {
      display: flex;
      align-items: center;
    }
  }
  .message-content-reply.me {
    border-radius: 1rem 1rem 0 1rem !important;
  }
  .user-reply {
    position: relative;
    padding: 0 1rem;
    bottom: -1rem;
  }
}

.dark-layout {
  .message-reply-to {
    .message-content-reply {
      background-color: rgba(0, 144, 231, 0.1) !important;
    }
  }
}
</style>