<template>
  <b-modal
    ref="myModal"
    v-model="onControl"
    header-bg-variant="transparent border-bottom border-bottom-2"
    size="xlg"
    header-class="p-0"
    title="Payroll Detail"
    modal-class="modal-primary modal-void-refund"
    hide-footer
    scrollable
    @hidden="close"
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <span>Void/Refund Details ({{ titleMonthYear }})</span>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="close"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>

    <filter-slot
      :filter="filter"
      :top-paginate="false"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refTableExpensesDetail'].refresh()"
      class="filter-slot-new-customization"
      show-minimal
    >
      <template #buttons-filter-end>
        <div style="min-width: 300px" class="ml-1">
          <div class="absolute-linker" :class="{ dark: isDarkSkin }">
            <div
              class="d-flex justify-content-between align-items-center h-full px-1"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="20"
                class="cursor-pointer"
                @click="prev"
              />
              <span class="font-size:15px">
                {{ months[dataMonth - 1] }} {{ dataYear }}
              </span>
              <feather-icon
                icon="ChevronRightIcon"
                size="20"
                class="cursor-pointer"
                @click="next"
              />
            </div>
          </div>
        </div>
        <div class="total-cb">TOTAL: {{ totalRows }}</div>
      </template>
      <template #table>
        <b-table
          ref="refTableExpensesDetail"
          no-border-collapse
          class="position-relative table-new-customization"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="searchDetailExpenses"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(program_id)="data">
            <div class="text-center">
              <img
                v-b-tooltip.hover.top="data.item.program"
                :src="getProgramImageRoute(data.item.program_id)"
                alt="img_services"
                style="height: 40px"
              />
            </div>
          </template>
          <template #cell(account)="data">
            <div class="align-middle text-center">
              {{ data.item.account }}
            </div>
          </template>
          <template #cell(amount)="data">
            <div
              class="align-middle text-center"
              v-if="data.item.expense_amount"
            >
              <span class="text-danger">
                $ {{ data.item.expense_amount | currency }}
              </span>
              <span> ($ {{ data.item.amount | currency }}) </span>
            </div>
            <div class="align-middle text-center text-danger" v-else>
              $ {{ data.item.amount | currency }}
            </div>
          </template>
          <template #cell(client)="data">
            <div v-if="!data.item.lead_id" class="d-flex flex-column">
              <router-link
                class="font-weight-bolder"
                target="_blank"
                :to="
                  routerDashboardRedirectioner(
                    data.item.program_id,
                    data.item.client_account_id
                  )
                "
              >
                {{ data.item.client }} <br />
                {{ data.item.account }}
              </router-link>
            </div>
            <div v-else>
              <router-link
                class="text-warning font-weight-bolder"
                target="_blank"
                :to="{
                  name: dashboardLeadRouteName(data.item.module_id),
                  params: { id: data.item.lead_id },
                }"
              >
                {{ data.item.client }} <br />
                ID: {{ data.item.lead_id }}
              </router-link>
            </div>
          </template>
          <template #cell(type_transaction)="data">
            <div class="align-middle text-center">
              <b-row>
                <b-col cols="8">
                  <b-badge
                    :variant="
                      data.item.type_transaction.includes('Refund')
                        ? 'light-primary'
                        : 'light-info'
                    "
                  >
                    {{ data.item.type_transaction }}
                  </b-badge>
                </b-col>
                <b-col cols="4">
                  <feather-icon
                    icon="InfoIcon"
                    size="18"
                    v-b-tooltip.hover.top="data.item.comment"
                  />
                </b-col>
              </b-row>
            </div>
          </template>
          <template #cell(created_at)="data">
            <div class="align-middle text-center">
              <div>
                <span>{{ data.item.created_by }}</span>
              </div>
              <div class="text-center">
                <span>{{ data.item.created_at | myGlobalDay }}</span>
              </div>
            </div>
          </template>
          <template #cell(processed_by)="data">
            <div class="align-middle text-center">
              <div class="text-center">
                <div>
                  <span>{{ data.item.processed_by }}</span>
                </div>
                <span>{{ data.item.processed_at | myGlobalDay }}</span>
              </div>
            </div>
          </template>
          <template #bottom-row>
            <b-th colspan="2" class="fill-bottom-row" />
            <b-th>
              <b-badge
                variant="danger"
                class="w-100 text-center"
                style="
                  font-size: 14px;
                  font-weight: 600;
                  background-color: #eb5648;
                  color: white;
                "
              >
                TOTAL
              </b-badge>
            </b-th>
            <b-th>
              <b-badge
                class="w-100 text-center"
                style="
                  font-size: 14px;
                  font-weight: 600;
                  background-color: #eb5648;
                  color: white;
                "
                v-if="totalExpense > 0"
              >
                {{ "$ " }}{{ totalExpense | currencyZero }}
              </b-badge>
              <b-badge
                class="w-100 text-center"
                style="
                  font-size: 14px;
                  font-weight: 600;
                  background-color: #eb5648;
                  color: white;
                "
                v-else
              >
                {{ "$ " }}{{ totalAmount | currencyZero }}
              </b-badge>
            </b-th>
            <b-th class="fill-bottom-row" colspan="2" />
          </template>
        </b-table>
      </template>
    </filter-slot>
  </b-modal>
</template>

<script>
import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service";
import ProgramsFilter from "@/views/ceo/dashboard/data/programs-filter";
import { mapGetters } from "vuex";

export default {
  props: {
    sendYear: {
      type: Number,
      default: null,
    },
    sendMonth: {
      type: Number,
      default: null,
    },
    sendPrograms: {
      type: Array,
      required: false,
      default: [],
    },
  },
  data() {
    return {
      onControl: false,
      startPage: 0,
      toPage: 0,
      isBusy: false,
      dataYear: null,
      dataMonth: null,
      titleModal: "",
      idPrograms: null,
      initialProgram: "",
      sortBy: "client_name",
      sortDesc: true,
      filter: ProgramsFilter,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by client name...",
        model: "",
      },
      fields: [
        {
          key: "program_id",
          sortable: false,
          label: "Program",
        },
        {
          key: "client",
          sortable: false,
          label: "Client Name",
        },
        {
          key: "transaction_id",
          sortable: false,
          label: "Transaction ID",
        },
        {
          key: "amount",
          sortable: false,
          label: "Amount",
          class: "text-center",
          thClass: "text-center",
        },
        {
          key: "type_transaction",
          sortable: false,
          label: "Type / Comment",
          class: "text-center",
          thClass: "text-center",
        },
        {
          key: "created_at",
          sortable: false,
          label: "Created At",
          class: "text-center",
          thClass: "text-center",
        },
        {
          key: "processed_by",
          sortable: false,
          label: "Processed At",
          class: "text-center",
          thClass: "text-center",
        },
      ],
      voidRefundDetailItems: [],
      totalRows: 1,
      paginate: {
        perPage: 50,
        currentPage: 1,
      },
      totalAmount: 0,
      totalExpense: 0,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },

  computed: {
    ...mapGetters({
      titleMonthYear: "DashboardCeoStore/G_TITLE_MODAL",
    }),
  },

  async created() {
    this.onControl = true;
    this.idPrograms = this.sendProgramsId;
    this.dataMonth = this.sendMonth;
    this.dataYear = this.sendYear;
    this.initialProgram = this.sendProgramsInitial;
    this.filter[0].options = this.sendPrograms;
  },

  methods: {
    close() {
      this.$refs["myModal"].hide();
      this.$emit("close");
    },

    async searchDetailExpenses(ctx) {
      const { data } = await DashboardServive.getDetailsExpenses({
        year: this.dataYear,
        type_expense: "void-refund",
        program_id: this.filter[0].model,
        month: this.dataMonth,
        npage: ctx.currentPage,
        perpage: ctx.perPage,
        search: this.filterPrincipal.model,
      });

      if (data.data.length === 0) {
        this.voidRefundDetailItems = [];
        this.totalAmount = 0;
      } else {
        this.voidRefundDetailItems = data.data;
        this.totalAmount = this.voidRefundDetailItems[0].total_amount;
        this.totalExpense = this.voidRefundDetailItems[0].total_expense;
      }

      this.voidRefundDetailItems = data.data;
      this.startPage = data.from;
      this.paginate.currentPage = data.current_page;
      this.paginate.perPage = data.per_page;
      this.totalRows = data.total;
      this.toPage = data.to;
      return this.voidRefundDetailItems || [];
    },

    routers(program, id) {
      let route = "";
      switch (program) {
        case 1:
          route = `/bussiness/clients/account/${id}`;
          break;
        case 2:
          route = `/boostcredit/clients/account/${id}`;
          break;
        case 3:
          route = `/creditexperts/clients/account/${id}`;
          break;
        case 4:
          route = `/debtsolution/clients/account/${id}`;
          break;
        case 5:
          route = `/taxresearch/clients/account/${id}`;
          break;
        case 7:
          route = `/specialists/clients/account/${id}`;
          break;
        case 8:
          route = `/bookeeping/clients/account/${id}`;
          break;
        case 9:
          route = `/ti/clients/account/${id}`;
          break;
      }
      return route;
    },
    async next() {
      if (this.dataMonth < 12) {
        this.dataMonth++;
      } else {
        this.dataMonth = 1;
        this.dataYear++;
      }
      this.refresh();
    },
    async prev() {
      if (this.dataMonth > 1) {
        this.dataMonth--;
      } else {
        this.dataMonth = 12;
        this.dataYear--;
      }
      this.refresh();
    },
    refresh() {
      this.$refs["refTableExpensesDetail"].refresh();
    },
    dashboardLeadRouteName(module_id) {
      const routers = {
        2: "lead-show",
        3: "bussiness-lead-show",
        4: "administration-lead-show",
        5: "debtsolution-lead-show",
        6: "creditexperts-lead-show",
        7: "boostcredit-lead-show",
        8: "tax-research-lead-show",
        10: "claimdepartment-lead-show",
        11: "specialists-leads-show",
        12: "ti-lead-show",
        14: "bookeeping-lead-show",
        20: "connection-lead-show",
        22: "customerservice-lead-show",
        23: "financial-leads-lead-show",
        25: "specialist-dg-lead-show",
        26: "sales-lead-show",
      };
      return routers[module_id] || null;
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-void-refund {
  .text-money {
    font-weight: 500;

    &::before {
      content: "$";
    }
  }
  .header-modal {
    position: relative;
    padding: 20px 0 20px 15px;
    background: rgba(22, 84, 204, 1);
    width: 100%;
    font-size: 22px;
    display: flex;
    justify-content: space-betweeen;

    span {
      font-size: 1.6rem;
      font-weight: bold;
      margin-left: 8px;
      color: #ffffff;
    }

    .container-icons {
      background-color: #ffffff;
      padding: 2.7px 6.37px;
      border-radius: 0.357rem;
      width: 31px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 15px;
      right: 20px;
      transition: all 0.23s ease 0.1s;

      &--dark {
        background-color: #17171a;
      }

      &:hover {
        transform: translate(-7px, 5px);
        cursor: pointer;
      }
    }
  }

  .b-table-bottom-row th:nth-child(2) {
    border-radius: 0px 0px 0px 15px !important;
    margin-left: 5px;
    background: #eb5648 !important;
  }

  .b-table-bottom-row th:nth-child(3) {
    border-radius: 0px 0px 15px 0px !important;
    margin-left: -10px;
    background: #eb5648 !important;
  }
  .fill-bottom-row {
    background-color: #ffffff !important;
  }
}

.dark-layout {
  .fill-bottom-row {
    background-color: #17171a !important;
    border-top: none !important;
  }
}
</style>


<style lang="scss" scoped>
.total-cb {
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  font-size: 15px;
  font-weight: 600;
  background: rgba(231, 0, 0, 0.233);
  color: rgb(239, 119, 119);
}
</style>