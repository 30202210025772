<template>
  <b-modal
    v-model="onControl"
    @hidden="close"
    size="caro"
    title="Department Expenses"
    modal-class="custom-modal-amg"
    hide-footer
  >
    <template #modal-title>
      <tabler-icon icon="StairsDownIcon" class="text-white" size="22" />
      Method: <span class="text-uppercase">{{ methodTitle }}</span> | Type:
      {{ dataRow.type }} | Status:
      {{ status == "APPROVED" ? "CONFIRMED" : status }}
      {{
        card_expense_id != null
          ? "| Module: ALL"
          : dataRow.module_name
          ? `| Module: ${dataRow.module_name}`
          : ""
      }}
    </template>
    <filter-slot
      :filter="[]"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :filter-principal="filterPrincipal"
      @reload="refresh"
    >
      <template #buttons>
        <div class="ml-1">
          <b-form-group class="m-0">
            <v-select
              v-bind="payableExpenseScope.attr"
              v-model="selectedPayable"
              :placeholder="placeholderSelectVendor"
              style="width: 290px"
            ></v-select>
          </b-form-group>
        </div>
      </template>
      <template #table>
        <b-table
          ref="refDetailDepartmentExpenses"
          :items="getDetailDepartmentExpenses"
          :fields="visibleFields"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          show-empty
          :busy.sync="isBusy"
          sticky-header="60vh"
          class="table-new-customization filter-slot-new-customization"
          foot-clone
          table-class="text-nowrap"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle" />
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(payable)="{ item }">
            <span>{{ item.payable }}</span>
          </template>
          <template #cell(client)="{ item }">
            <b-badge
              variant="light-primary"
              class="total-client"
              @click="openClientExpenses(item)"
            >
              <span>{{ item.totalClient }}</span>
            </b-badge>
          </template>
          <template #cell(module)="{ item }">
            <div class="d-flex justify-content-center align-items-center">
              <img
                v-b-tooltip.hover.left="item.module"
                :src="`/assets/${item.icon}`"
                alt="Logo"
                height="30"
                width="30"
              />
            </div>
          </template>
          <template #cell(description)="{ item }">
            <p
              class="text-info text-truncate"
              style="width: 140px; max-width: 140px"
              v-b-tooltip
              :title="item.description"
            >
              {{ item.description }}
            </p>
          </template>
          <template #cell(details)="{ item }">
            <b-row>
              <b-col cols="2"
                ><strong>{{ titleVendor }}</strong></b-col
              >
              <b-col>{{ item.payable }}</b-col>
              <div class="w-100" />
              <b-col cols="2"
                ><strong>{{ titleConcept }}</strong></b-col
              >
              <b-col>{{ item.description }}</b-col>
              <div class="w-100" />
              <b-col v-if="item.reference_number" cols="2">
                <strong>Ref. number</strong>
              </b-col>
              <b-col v-if="item.reference_number">{{
                item.reference_number
              }}</b-col>
              <div class="w-100" />
              <b-col v-if="isCheck" cols="2"><strong>Address</strong></b-col>
              <b-col v-if="isCheck"
                ><span v-html="formatAddress(item)"></span
              ></b-col>
              <div class="w-100" />
              <b-col v-if="isCheck" cols="2"><strong>Date</strong></b-col>
              <b-col v-if="isCheck">{{ item.date | myGlobal }}</b-col>
              <div class="w-100" />
              <b-col v-if="item.url_file" cols="2">
                <strong>{{ !isCheck ? "Invoice" : "File" }}: </strong>
              </b-col>
              <b-col v-if="item.url_file">
                <a :href="item.url_file" target="_blank" :title="item.file">
                  <amg-icon
                    class="cursor-pointer text-danger"
                    icon="FilePdfIcon"
                    size="18"
                  />
                </a>
              </b-col>
            </b-row>
          </template>
          <template #cell(check_number)="{ item }">
            <span>{{ item.check_number || "-" }}</span>
          </template>
          <template #cell(status_check)="{ item }">
            <span :class="colorTextStatusCheck(item)" style="font-weight: bold">
              {{ statusCheckText(item) }}
            </span>
          </template>
          <template #cell(method)="{ item }">
            <template v-if="item.method == 'CREDIT_CARD'">
              <div class="row">
                <div class="col-4 p-0 justify-content-end d-flex">
                  <type-card :type_card="item.type_card" />
                </div>
                <div
                  class="col-8 p-0 d-flex justify-content-center align-items-center"
                >
                  <strong>XXXX-{{ item.cardnumber.slice(-4) }}</strong>
                </div>
              </div>
            </template>
            <template v-else>
              <b-badge variant="primary">{{ item.method }}</b-badge>
            </template>
          </template>
          <template #cell(amount)="{ value }">
            <span> {{ value | currencyTwo | myMoney }}</span>
          </template>
          <template #cell(recurring_payment)="{ value }">
            <b-badge :variant="value ? 'success' : 'danger'">{{
              value ? "YES" : "NO"
            }}</b-badge>
          </template>
          <template #cell(date)="{ value }">
            <span>{{ value | myGlobal }}</span>
          </template>
          <template #cell(created_by)="{ item }">
            <div>
              <div>{{ item.created_by }}</div>
              <div>{{ item.created_at | myGlobalDay }}</div>
            </div>
          </template>
          <template #cell(collected_at)="{ item }">
            {{ item.collect_date | myGlobal }}
            <a :href="item.collect_path" target="_blank">
              <feather-icon
                class="cursor-pointer text-danger"
                icon="FileIcon"
                size="18"
              />
            </a>
          </template>
          <template #cell(approved_by)="{ item }">
            <div>
              <span class="d-block">{{ item.updated_by }}</span>
              <span class="d-block">{{ item.updated_at | myGlobalDay }}</span>
            </div>
          </template>
          <template #cell(deleted_by)="{ item }">
            <div>
              <span class="d-block">{{ item.updated_by }}</span>
              <span>{{ item.updated_at | myGlobalDay }}</span>
              <feather-icon
                v-if="item.observation"
                icon="InfoIcon"
                v-b-tooltip.hover.top="item.observation"
                variant="info"
                class="text-info"
                style="margin-left: 3px"
              />
            </div>
          </template>
          <template #cell(tracking)="{ item }">
            <div>
              <feather-icon
                class="text-info cursor-pointer"
                v-b-tooltip.hover.right="'Tracking'"
                @click="openChecksTrackingModal(item)"
                size="16"
                icon="ListIcon"
              />
            </div>
          </template>
          <template #foot(date)>
            <div
              class="row d-flex justify-content-end flex-column"
              style="gap: 5px"
            >
              <div><b-badge variant="info">Sub total:</b-badge></div>
              <div><b-badge variant="info">Total:</b-badge></div>
            </div></template
          >
          <template #foot(amount)>
            <div
              class="row d-flex justify-content-center flex-column"
              style="gap: 5px"
            >
              <div>
                {{ calculateSubTotal("amount") | currencyTwo | myMoney }}
              </div>
              <div>{{ total | currencyTwo | myMoney }}</div>
            </div></template
          >
          <template #foot()> &nbsp;</template>
        </b-table>
      </template>
    </filter-slot>
    <client-expense-request
      v-if="clientExpenseModal.show"
      :expenseDepartment="clientExpenseModal.expenseDepartment"
      @close="clientExpenseModal.show = false"
    />
    <ChecksTrackingModal
      v-if="showChecksTrackingModal"
      :dataRow="selectedCheck"
      @close="showChecksTrackingModal = false"
    ></ChecksTrackingModal>
  </b-modal>
</template>

<script>
import Fields from "@/views/commons/expenses/data/expenses-detail-dept.fields";
import ExpensesService from "@/views/commons/expenses/services/expenses-department.service.js";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import RoutesProgram from "@/views/commons/components/ring-central/call-log/data/RoutesName.js";
import TypeCard from "@/views/commons/card/TypeCard.vue";
import DepartmentExpenses from "@/views/commons/expenses/services/expenses-department.service";
import ClientExpenseRequest from "@/views/ceo/dashboard/modals/expenses-request/ClientExpenseRequest.vue";
import { modifyLabel, modifyVisibility } from "@/helpers/fields-table";
import ChecksTrackingModal from "@/views/commons/expenses/components/Modal/ChecksTrackingModal.vue";
export default {
  components: {
    StatusAccount,
    TypeCard,
    ClientExpenseRequest,
    ChecksTrackingModal,
  },
  props: {
    year: {
      required: true,
      type: Number,
    },
    month: {
      required: true,
      type: Number,
    },
    module_id: {
      type: Number,
      default: null,
    },
    dataRow: {
      type: Object,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    program_id: {
      type: Number,
      default: null,
    },
    card_expense_id: {
      type: Number,
      default: null,
    },
    currentTab: {
      type: String,
      default: "CREDIT_CARD",
    },
  },
  async created() {
    this.onControl = true;
    this.filterColumn("client", this.dataRow.type == "CLIENT");
    this.filterColumn("approved_by", this.status == "APPROVED");
    this.filterColumn(
      "collected_at",
      this.status == "APPROVED" && this.dataRow.method === "CHECK"
    );
    this.filterColumn("tracking", this.dataRow.method === "CHECK");
    this.filterColumn("deleted_by", this.status == "REJECTED");
    await this.loadPayableExpense();
  },
  data() {
    return {
      onControl: false,
      items: [],
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      startPage: 0,
      toPage: 0,
      totalRows: 0,
      isBusy: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by concept",
        model: "",
      },
      fields: Fields,
      total: 0,
      subTotal: 0,
      routesPrograms: [...RoutesProgram],
      dataPayableExpense: [],
      selectedPayable: null,
      clientExpenseModal: {
        show: false,
        expenseDepartment: null,
      },
      showChecksTrackingModal: false,
      selectedCheck: null,
    };
  },
  computed: {
    visibleFields() {
      modifyVisibility(this.fields, "module", this.card_expense_id != null);
      return this.fields.filter((item) => item.visible);
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    programId() {
      return this.$route.matched[0].meta.program;
    },
    payableExpenseScope() {
      return {
        attr: {
          options: this.dataPayableExpense,
          label: "description",
          reduce: (item) => item.id,
        },
      };
    },
    methodTitle() {
      switch (this.dataRow.method) {
        case "CREDIT_CARD":
          return "Cards";
        case "CHECK":
          return "Checks";
        default:
          return "E-Checks";
      }
    },
    isCheck() {
      return this.dataRow.method === "CHECK";
    },
    titleVendor() {
      let title;
      switch (this.dataRow.method) {
        case "CREDIT_CARD":
          title = "Vendor";
          break;
        case "CHECK":
        case "E-CHECK":
          title = "Pay to";
          break;
        default:
          title = "Vendor";
          break;
      }
      return title;
    },
    titleConcept() {
      let title;
      switch (this.dataRow.method) {
        case "CREDIT_CARD":
          title = "Concept";
          break;
        case "CHECK":
        case "E-CHECK":
          title = "Memo";
          break;
        default:
          title = "Concept";
          break;
      }
      return title;
    },
    placeholderSelectVendor() {
      let title;
      switch (this.currentTab) {
        case "CREDIT_CARD":
          title = "Select a vendor";
          break;
        case "CHECK":
        case "E-CHECK":
          title = "Select a pay to";
          break;
        default:
          title = "Select a vendor";
          break;
      }
      return title;
    },
    placeholderSearchConcept() {
      let title;
      switch (this.currentTab) {
        case "CREDIT_CARD":
          title = "Search by concept";
          break;
        case "CHECK":
        case "E-CHECK":
          title = "Search by memo";
          break;
        default:
          title = "Search by concept";
          break;
      }
      return title;
    },
  },
  methods: {
    openChecksTrackingModal(item) {
      this.showChecksTrackingModal = true;
      this.selectedCheck = item;
    },
    async getDetailDepartmentExpenses() {
      try {
        this.total = 0;
        this.items = [];
        const params = {
          module_id: this.module_id,
          year: this.year,
          month: this.month,
          page: this.paginate.currentPage,
          per_page: this.paginate.perPage,
          status: this.status == "IN PROCESS" ? "PENDING" : this.status,
          type: this.dataRow.type,
          description: this.filterPrincipal.model,
          program_id:
            this.program_id == null ? this.programId : this.program_id,
          payable_id: this.selectedPayable,
          card_expense_id: this.card_expense_id,
          method: this.dataRow.method,
        };
        const data = await ExpensesService.getDetailDepartmentExpenses(params);

        this.totalRows = data.total;
        this.startPage = data.from;
        this.toPage = data.to;
        this.items = data.data;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;

        if (data.data.length > 0) {
          this.total = data.data[0].total;
        }

        return data.data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    close() {
      this.$emit("close");
    },
    refresh() {
      this.$refs.refDetailDepartmentExpenses.refresh();
    },
    filterColumn(key, bool) {
      const field = this.fields.find((field) => field.key == `${key}`);
      field.visible = bool;
    },
    calculateSubTotal(field) {
      return this.items.reduce((total, item) => {
        return parseFloat(total) + parseFloat(item[field]);
      }, 0);
    },
    getRouterName(item) {
      let route = ["lead-show", { id: item.lead_id }];

      if (this.moduleId === 18) {
        return ["quality-lead-show", { id: item.lead_id }];
      }

      for (const element of this.routesPrograms) {
        if (element.module.includes(this.moduleId)) {
          if (item.account_id) {
            route = [
              element.dashboardCLient,
              { [element.clientParam]: item.account_id },
            ];
          } else {
            route = [element.dashboardLeads, { id: item.lead_id }];
          }
        }
      }
      return route;
    },
    async loadPayableExpense() {
      try {
        const data = await DepartmentExpenses.getPayableExpenses({
          module_id: this.moduleId,
          program_id: this.programId,
          method: this.currentTab,
        });
        this.dataPayableExpense = data;
      } catch (ex) {
        this.showErrorSwal(ex);
      }
    },
    openClientExpenses(item) {
      this.clientExpenseModal.show = true;
      this.clientExpenseModal.expenseDepartment = JSON.parse(item.clients)[0];
      this.clientExpenseModal.expenseDepartment.method = item.method;
    },
    colorTextStatusCheck(item) {
      switch (item.status_check) {
        case "PENDING":
          return "text-warning";
        case "SEND TO COURIER":
          return "text-primary";
        case "SEND TO CORRESPONDENCE":
          return "text-info";
        case "REJECTED":
          return "text-danger";
        case "COLLECTED":
          return "text-success";
        case "PENDING COLLECT":
          return "text-warning";
        default:
          return "text-warning";
      }
    },
    statusCheckText(item) {
      switch (item.status_check) {
        case "PENDING":
          return "IN CEO";
        case "SEND TO CORRESPONDENCE":
          return "IN CORRESPONDENCE";
        case "SEND TO COURIER":
          return "IN COURIER";
        case "PENDING COLLECTION":
          return "PENDING COLLECT";
        default:
          return item.status_check;
      }
    },
  },
  watch: {
    selectedPayable() {
      this.refresh();
    },
    currentTab: {
      handler(value) {
        if (value) {
          switch (value) {
            case "CREDIT_CARD": {
              this.filterColumn("method", true);
              this.filterColumn("recurring_payment", true);
              this.filterColumn("status_check", false);
              this.filterColumn("date", true);
              this.filterColumn("check_number", false);
              modifyLabel(this.fields, "date", "Date Expense");
              modifyLabel(this.fields, "created_by", "registered by");
              modifyLabel(this.fields, "details", "Transaction details");
              break;
            }
            case "CHECK":
            case "E-CHECK": {
              this.filterColumn("status_check", true);
              this.filterColumn("method", false);
              this.filterColumn("recurring_payment", false);
              this.filterColumn("date", false);
              this.filterColumn("check_number", true);
              modifyLabel(this.fields, "created_by", "requested by");
              modifyLabel(this.fields, "details", "Check Details");
              break;
            }
            default: {
              break;
            }
          }
        }
      },
      deep: true,
      immediate: true,
    },
    placeholderSearchConcept: {
      handler(value) {
        this.filterPrincipal.placeholder = value;
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.table-new-customization::v-deep tfoot {
  position: sticky;
  bottom: 0;
  color: rgba(63, 122, 250, 0.882) !important;
  font-size: 14.5px;
  font-weight: bold !important;
}

.table-new-customization::v-deep tfoot tr th {
  font-size: 13px;
  background: rgba(63, 122, 250, 0.882) !important;
  color: #fff !important;
}

.table-new-customization::v-deep tfoot tr {
  position: sticky !important;
  bottom: 0 !important;
}

.table-new-customization::v-deep tfoot tr th:first-child {
  border-radius: 0 0 0 15px !important;
}

.table-new-customization::v-deep tfoot tr th:last-child {
  border-radius: 0 0 15px 0 !important;
}
.total-client {
  font-size: 14px;
  padding: 0.8rem;
  span {
    border-bottom: 2px solid #0090e7;
    padding: 0px 5px;
    font-weight: bold !important;
  }
  :hover {
    cursor: pointer;
    span {
      box-shadow: rgba(255, 255, 255, 0.856) 0px 54px 55px,
        rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px,
        rgba(241, 239, 239, 0.09) 0px -3px 5px;
    }
  }
}
</style>
