var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"total-paid-details",attrs:{"modal-class":"custom-modal-amg","title-class":"h3 text-white","title":_vm.title,"size":"xmd","header-bg-variant":"transparent","header-class":"p-0","centered":"","hide-footer":""},on:{"hidden":function($event){return _vm.closeModal()}},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('div',{staticClass:"header-modal"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"container-icons",class:_vm.isDarkSkin ? 'container-icons--dark' : '',on:{"click":function($event){return _vm.closeModal()}}},[_c('feather-icon',{staticClass:"pointer",class:_vm.isDarkSkin ? 'text-light' : 'text-primary',attrs:{"icon":"XIcon","size":"18"}})],1)])]},proxy:true}])},[_c('b-row',{staticClass:"client-info"},[_c('b-col',{staticClass:"p-0",attrs:{"cols":"6"}},[_c('div',{staticClass:"mr-1",class:_vm.isDarkSkin ? 'border-custom' : 'border-custom-light'},[_c('p',[_vm._v("Bank Name | "+_vm._s(_vm.bank_account.bank_name))])])]),_c('b-col',{staticClass:"p-0",attrs:{"cols":"6"}},[_c('div',{staticClass:"ml-1",class:_vm.isDarkSkin ? 'border-custom' : 'border-custom-light'},[_c('p',[_vm._v("Account Name | "+_vm._s(_vm.bank_account.account_name))])])])],1),_c('div',[_c('b-table',{staticClass:"position-relative table-new-customization",attrs:{"sticky-header":"70vh","show-empty":"","items":_vm.items,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(amount)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm._f("formatMoney")(item.amount)))])]}},{key:"cell(approved_at)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(item.approved_at))+" ")]}},{key:"cell(payment_date)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(item.payment_date))+" ")]}},{key:"cell(user_name)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(item.user_name))]),_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(_vm._f("myGlobalDay")(item.created_at)))])]}},{key:"cell(creditor)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-block text-warning font-weight-bolder"},[_vm._v(_vm._s(item.creditor_name))]),_c('span',{staticClass:"d-block"},[_vm._v("#"+_vm._s(item.account_number || item.account_number2))])]}},{key:"cell(lead_name)",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('router-link',{attrs:{"to":_vm.routerDashboardRedirectioner(
                  item.program_id,
                  item.client_account_id
                ),"target":"_blank"}},[_vm._v(_vm._s(item.lead_name)+" ")]),_c('status-account',{attrs:{"account":Object.assign({}, item)}}),_c('span',[_vm._v(_vm._s(item.account))])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }