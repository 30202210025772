var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"bounce"}},[(_vm.show)?_c('div',{staticClass:"card-details",style:(_vm.isDarkSkin ? 'background-color: #262626 !important' : '')},[_c('b-table',{ref:"refOtherPayments",staticClass:"blue-scrollbar position-relative table-new-customization",attrs:{"slot":"table","no-provider-filtering":"","items":_vm.payments,"fields":_vm.fields,"small":"","primary-key":"id","table-class":"text-nowrap border-radius-4","responsive":"sm","show-empty":"","sticky-header":"240px","busy":_vm.isBusy},on:{"update:busy":function($event){_vm.isBusy=$event}},slot:"table",scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(client_name)",fn:function(ref){
var item = ref.item;
return [(item.client_name)?_c('router-link',{staticClass:"text-primary font-weight-bolder",attrs:{"target":"_blank","to":{
            name: _vm.pathDashboard,
            params: { idClient: ("" + (item.client_account_id)) },
          }}},[_vm._v(" "+_vm._s(item.client_name)+" "),_c('div',[_vm._v(_vm._s(item.account))])]):_c('router-link',{staticClass:"text-warning font-weight-bolder",attrs:{"target":"_blank","to":{
            name: 'management-lead-show',
            params: { id: item.lead_id },
          }}},[_vm._v(" "+_vm._s(item.lead_name)+" "),_c('br'),_vm._v(" ID: "+_vm._s(item.lead_id)+" ")])]}},{key:"cell(client_type)",fn:function(ref){
          var item = ref.item;
return [_c('b-badge',{attrs:{"variant":item.lead_name ? 'light-warning' : 'light-success'}},[_vm._v(_vm._s(item.lead_name ? "CRM" : "CLIENT")+" ")])]}},{key:"cell(modality)",fn:function(ref){
          var item = ref.item;
return [_c('div',[_c('b',[_vm._v("NUMBER:")]),_vm._v(" "+_vm._s(item.serial_number))]),_c('div',[_c('b',[_vm._v("DATE:")]),_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(item.serial_date)))]),_c('div',[_c('b',[_vm._v("HOLDER:")]),_vm._v(" "+_vm._s(item.owner ? item.owner : "-"))])]}},{key:"cell(transaction_id)",fn:function(ref){
          var item = ref.item;
return [_c('div',[_c('b',[_vm._v("METHOD:")]),_vm._v(" "+_vm._s(item.method))]),_c('div',[_c('b',[_vm._v("CONCEPT:")]),_vm._v(" "+_vm._s(item.modality === "Monthly" ? "Monthly Payment" : item.modality)+" ")]),_c('div',[_c('b',[_vm._v("TRANSACTION ID:")]),_vm._v(" "+_vm._s(item.voucher_id))])]}},{key:"cell(amount)",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"text-success"},[_vm._v("$ "+_vm._s(_vm._f("currency")(item.amount)))])]}},{key:"cell(tracking)",fn:function(ref){
          var item = ref.item;
return [_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"mr-1 pointer text-primary",attrs:{"icon":"ListIcon","size":"18","title":"Tracking"},on:{"click":function($event){return _vm.showTracking(item)}}})]}}],null,false,1809549798)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }