<template>
  <b-modal
    v-model="onControl"
    header-bg-variant="transparent border-bottom border-bottom-2"
    size="md"
    header-class="p-0"
    title="Payroll Detail"
    modal-class="modal-primary modal-chargeback-status-tracking xd"
    hide-footer
    scrollable
    @hidden="close"
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <span class="text-tittle">Charge Backs Tracking Status</span>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="$emit('close')"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>

    <filter-slot
      :top-paginate="false"
      :filter="[]"
      :no-visible-principal-filter="true"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refTableChargeBackStatusTracking'].refresh()"
      class="filter-slot-new-customization"
    >
      <template #table>
        <b-table
          ref="refTableChargeBackStatusTracking"
          no-border-collapse
          class="position-relative table-new-customization"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="searchChargeBackStatusTracking"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(module_id)="data">
            <div class="d-flex justify-content-center">
              <img
                v-b-tooltip.hover.top="data.item.module_name"
                :src="`/assets/${data.item.icons}`"
                alt="Logo"
                height="35"
                width="35"
                style="background: #fafafa; border-radius: 5px; padding: 3px"
              />
            </div>
            <!-- <div class="d-flex justify-content-center">
                <span> {{ data.item.module_name }}</span>
              </div> -->
          </template>
          <template #cell(status)="data">
            <div class="d-flex flex-column">
              <div>
                <b-badge
                  :variant="
                    data.item.status_id == 1
                      ? 'light-warning'
                      : data.item.status_id == 2
                      ? 'light-success'
                      : 'light-info'
                  "
                >
                  {{ data.item.status }}
                </b-badge>
              </div>
              <div v-if="data.item.status_id != 1">
                [
                <b-badge
                  class="font-italic"
                  style="font-size: 9px"
                  :variant="
                    getVariantStatus(
                      data.item.responsable
                        ? data.item.responsable_id
                        : data.item.dispute_id,
                      data.item.status_id
                    )
                  "
                >
                  {{
                    data.item.responsable
                      ? data.item.responsable
                      : data.item.dispute
                  }}
                </b-badge>
                ]
              </div>
            </div>
          </template>
          <template #cell(dispute_response)="data">
            <div class="d-flex flex-column">
              <div v-if="data.item.status_id == 3 && data.item.dispute_id == 3">
                <b-badge
                  :variant="
                    data.item.dispute_response_id == 1
                      ? 'light-warning'
                      : data.item.dispute_response_id == 2
                      ? 'light-success'
                      : 'light-danger'
                  "
                >
                  {{ data.item.dispute_response }}
                </b-badge>
              </div>
              <div v-else>-</div>
            </div>
          </template>
          <template #cell(created_at)="data">
            <div class="align-middle text-center">
              <div class="text-center">
                <span>{{ data.item.created_at | myGlobalDay }}</span>
              </div>
              <div>
                <span>{{ data.item.created_by }}</span>
              </div>
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
  </b-modal>
</template>

<script>
import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service";
import { mapGetters } from "vuex";

export default {
  props: {
    idChargeBack: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      onControl: false,
      startPage: 0,
      toPage: 0,
      isBusy: false,
      dataYear: null,
      dataMonth: null,
      titleModal: "",
      idPrograms: null,
      initialProgram: "",
      sortBy: "client_name",
      sortDesc: true,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by client name...",
        model: "",
      },
      fields: [
        {
          key: "auth_code",
          sortable: false,
          label: "AUTHCODE",
          class: "text-center",
          thClass: "text-center",
        },
        {
          key: "module_id",
          sortable: false,
          label: "Module",
          class: "text-center",
          thClass: "text-center",
        },
        {
          key: "status",
          sortable: false,
          label: "Status",
          class: "text-center",
          thClass: "text-center",
        },
        {
          key: "created_at",
          sortable: false,
          label: "Created At",
          class: "text-center",
          thClass: "text-center",
        },
      ],
      items: [],
      totalRows: 1,
      paginate: {
        perPage: 50,
        currentPage: 1,
      },
    };
  },

  computed: {
    ...mapGetters({
      titleMonthYear: "DashboardCeoStore/G_TITLE_MODAL",
    }),
  },

  async created() {
    this.onControl = true;
  },

  methods: {
    close() {
      this.$emit("close");
    },
    async searchChargeBackStatusTracking(ctx) {
      const { data } = await DashboardServive.chargeBackStatusTracking({
        id_chargeback: this.idChargeBack,
        npage: ctx.currentPage,
        perpage: ctx.perPage,
      });
      this.items = data.data;
      this.startPage = data.from;
      this.paginate.currentPage = data.current_page;
      this.paginate.perPage = data.per_page;
      this.totalRows = data.total;
      this.toPage = data.to;
      return this.items || [];
    },
    routers(program, id) {
      let route = "";
      switch (program) {
        case 1:
          route = `/bussiness/clients/account/${id}`;
          break;
        case 2:
          route = `/boostcredit/clients/account/${id}`;
          break;
        case 3:
          route = `/creditexperts/clients/account/${id}`;
          break;
        case 4:
          route = `/debtsolution/clients/account/${id}`;
          break;
        case 5:
          route = `/taxresearch/clients/account/${id}`;
          break;
        case 7:
          route = `/specialists/clients/account/${id}`;
          break;
        case 8:
          route = `/bookeeping/clients/account/${id}`;
          break;
        case 9:
          route = `/ti/clients/account/${id}`;
          break;
      }
      return route;
    },
    getVariantStatus(sub_status_id, status_id) {
      let variant = "";
      if (status_id == 2) {
        switch (sub_status_id) {
          case 1:
            variant = "light-orange";
            break;
          case 2:
            variant = "light-success";
            break;
          case 3:
            variant = "light-primary";
            break;
          case 4:
            variant = "light-info";
            break;
        }
      } else {
        switch (sub_status_id) {
          case 1:
            variant = "light-warning";
            break;
          case 2:
            variant = "light-primary";
            break;
          case 3:
            variant = "light-success";
            break;
        }
      }

      return variant;
    },
  },
};
</script>
<style lang="scss" scoped >
.modal-chargeback-status-tracking {
  .text-money {
    font-weight: 500;
    &::before {
      content: "$";
    }
  }

  .header-modal {
    position: relative;
    padding: 20px 0 20px 15px;
    background: rgba(22, 84, 204, 1);
    width: 100%;
    font-size: 22px;
    display: flex;
    justify-content: space-betweeen;

    & .text-tittle {
      font-size: 1.6rem;
      font-weight: bold;
      margin-left: 8px;
      color: #ffffff !important;
    }

    .container-icons {
      background-color: #ffffff;
      padding: 2.7px 6.37px;
      border-radius: 0.357rem;
      width: 31px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 15px;
      right: 20px;
      transition: all 0.23s ease 0.1s;

      &--dark {
        background-color: #17171a;
      }

      &:hover {
        transform: translate(-7px, 5px);
        cursor: pointer;
      }
    }
  }
}
</style>
