<template>
  <b-modal
    ref="otherPayment"
    modal-class="modal-primary"
    title-class="h3 text-white"
    title="Other Payments"
    size="caro"
    @hidden="closeModal()"
    hide-footer
    centered
    no-close-on-backdrop
  >
    <div>
      <b-nav card-header pills class="m-0">
        <b-nav-item
          @click="statusModal = 1"
          :active="statusModal == 1"
          exact
          exact-active-class="tab-nav"
          :link-classes="['tab-nav', 'px-3 h-full', bgTabsNavs]"
        >
          ZELLE / DEPOSIT
          <b-badge
            v-if="counters.zelle > 0 || counters.deposit > 0"
            variant="danger"
            pill
            class="ml-1"
          >
            {{ Number(counters.zelle) + Number(counters.deposit) }}
          </b-badge>
        </b-nav-item>
        <b-nav-item
          @click="statusModal = 2"
          :active="statusModal == 2"
          exact
          exact-active-class="tab-nav"
          :link-classes="['tab-nav', 'px-3 h-full', bgTabsNavs]"
        >
          CASH
          <b-badge v-if="counters.cash > 0" variant="danger" pill class="ml-1">
            {{ counters.cash }}
          </b-badge>
        </b-nav-item>
        <b-nav-item
          @click="statusModal = 3"
          :active="statusModal == 3"
          exact
          exact-active-class="tab-nav"
          :link-classes="['tab-nav', 'px-3 h-full', bgTabsNavs]"
        >
          MONEY ORDER / CASHIER CHECK / CHECK
          <b-badge v-if="counters.check > 0" variant="danger" pill class="ml-1">
            {{ counters.check }}
          </b-badge>
        </b-nav-item>
      </b-nav>
      <b-card
        no-body
        class="border-top-primary border-3 border-table-radius px-0"
      >
        <CheckPaymentTable
          v-if="statusModal == 3"
          :key="statusModal"
          is-pending-modal
          :status-modal="1"
          :status-tab-modal="statusModal"
          @reload="reloadCounters"
        />
        <OtherPaymentTable
          v-else
          :key="statusModal + 1"
          is-pending-modal
          :status-modal="1"
          :status-tab-modal="statusModal"
          @reload="reloadCounters"
        />
      </b-card>
    </div>
  </b-modal>
</template>
<script>
import OtherPaymentTable from "@/views/commons/components/other-payments/view/component/OtherPaymentsTable.vue";
import CheckPaymentTable from "@/views/commons/components/other-payments/view/component/CheckPaymentTable.vue";
export default {
  components: {
    OtherPaymentTable,
    CheckPaymentTable,
  },
  props: {
    counters: Object,
    type: Number,
  },
  data() {
    return {
      statusModal: 1,
    };
  },
  mounted() {
    this.toggleModal("otherPayment");
    this.statusModal = this.type;
  },
  created() {},
  methods: {
    reloadCounters() {
      this.$emit("reload");
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>