<template>
  <div class="general-card-ds">
    <div class="card-header-ds">
      <div class="card-title-ds">{{ title }}</div>
      <div v-if="showOffers" class="offers">
        <slot name="offers"></slot>
      </div>
    </div>
    <div class="card-body-ds">
      <div class="body-header-ds">
        <slot name="body-header"></slot>
      </div>
      <div class="body-content-ds">
        <slot name="body-content"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "GeneralCard",
  props: {
    title: {
      type: String,
      required: true,
      default: "",
    },
    showOffers: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="scss">
.general-card-ds {
  background: #ffffff;
  padding: 2rem;
  border-radius: 0.3rem;
  font-family: "montserrat";
  height: 100%;
  .card-header-ds {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #3d3d3d;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 0.4rem;
    .card-title-ds {
      font-size: 1.3rem;
      font-weight: 600;
    }
  }
  .card-body-ds {
    padding-top: 1rem;
    .body-content-ds {
      padding-top: 1rem;
    }
  }
}

.dark-layout {
  .general-card-ds {
    background: #1a1a1a;
    .card-header-ds {
      color: #ffffff;
      border-bottom: 1px solid #3d3d3d;
    }
  }
}
</style>