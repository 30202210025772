<template>
  <div>
    <cool-light-box
      class="align-items-center"
      :items="itemImage"
      :index="showImage"
      @close="showImage = null"
    />
    <b-modal
      ref="myModal"
      v-model="onControl"
      header-bg-variant="transparent border-bottom border-bottom-2"
      header-class="p-0"
      title="Client Payments Detail"
      modal-class="modal-primary"
      hide-footer
      size="xmd"
      scrollable
      @hidden="close"
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <span> Sales ({{ titleMonthYear }}) - {{ flyerName }}</span>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark': ''"
            @click="close"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light': 'text-primary'"
            />
          </div>
        </div>
      </template>
      <filter-slot
        :filter="[]"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="$refs['refTableIncomesDetail'].refresh()"
      >
        <template #table>
          <b-table
            ref="refTableIncomesDetail"
            no-border-collapse
            class="position-relative"
            :fields="fields"
            show-empty
            no-provider-filtering
            sticky-header="50vh"
            primary-key="id"
            responsive="sm"
            :items="searchDetailIncomes"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
            :busy.sync="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2 ">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #head(program_id)="data">
              <div class="text-left">
                {{ data.label }}
              </div>
            </template>
            <template #head(fee)="data">
              <div class="text-center">
                {{ data.label }}
              </div>
            </template>
            <template #head(amount)="data">
              <div class="text-center">
                {{ data.label }}
              </div>
            </template>
            <template #head(flyer_preview)="data">
              <div class="text-left">
                {{ data.label }}
              </div>
            </template>
            <template #head(lead_name)="data">
              <div class="text-center">
                {{ data.label }}
              </div>
            </template>
            <template #head(source)="data">
              <div class="text-center">
                {{ data.label }}
              </div>
            </template>
            <template #head(created_at)="data">
              <div class="text-center">
                {{ data.label }}
              </div>
            </template>
            <template #cell(program_id)="data">
              <div class="charge__programs">
                <img
                  :src="imgPrograms(data.item.program_id)"
                  alt="img_services"
                >
              </div>
            </template>
            <template #cell(client_name)="data">
              <div class="align-middle text-left">
                <div v-if="data.item.client_name">
                  <router-link
                    class
                    target="_blank"
                    :to="routers(data.item.program_id, data.item.client_account_id)"
                  >
                    {{ data.item.client_name }} <br>
                    {{ data.item.account }}
                  </router-link>
                </div>
                <div v-else>
                  {{ 'Unknown' }}
                </div>
              </div>
            </template>
            <template #cell(fee)="data">
              <div class="text-center text-no-wrap">
                <span class="text-no-wrap">$ {{ data.item.fee | currency }}</span>
              </div>
            </template>
            <template #cell(amount)="data">
              <div class="text-center text-no-wrap">
                $ {{ data.item.amount | currency }}
              </div>
            </template>
            <template #cell(created_at)="data">
              <div class="text-center">
                <span>
                  {{ data.item.seller_name }}
                </span><br>
                <span>
                  {{ data.item.created_at | myGlobalWithHour }}
                </span>
              </div>
            </template>
          </b-table>
          <b-tr>
            <div style="padding-right: 305px;"></div>
            <b-td colspan="5" class="w-25"></b-td>
            <b-td colspan="1" class="text-right font-weight-bolder py-px pr-2">
              TOTAL
            </b-td>
            <b-td colspan="1" class="text-left py-px pr-3" style="padding-right: 40px;">
              <b-badge variant="primary" style="font-size: 16px;" class="w-100">$ {{ totalFee | currencyZero }}</b-badge>
            </b-td>
            <div style="padding-right: 30px;"></div>
            <b-td colspan="1" class="text-left py-px pr-3">
              <b-badge variant="primary" style="font-size: 16px;" class="w-100">$ {{ totalInitialPayment | currencyZero }}</b-badge>
            </b-td>
          </b-tr>
        </template>
      </filter-slot>
    </b-modal>
  </div>
</template>
<script>
import DashboardServive from '@/views/ceo/service-dashboard/dashboard.service';
import ProgramsFilter from '@/views/ceo/dashboard/data/programs-filter';
import CoolLightBox from 'vue-cool-lightbox';
import { mapGetters } from 'vuex';

export default {
  components: {
    CoolLightBox,
  },
  props: {
    sendYear: {
      type: Number,
      default: null,
    },
    sendMonth: {
      type: Number,
      default: null,
    },
    sendProgramId: {
      type: Number,
      default: null,
    },
    flyerId: {
      type: Number,
      required: true,
    },
    flyerName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      /* eslint-disable global-require */
      imgServices: require('@/assets/images/icons/dashboard-ceo/services.svg'),
      imgDebtSolution: require('@/assets/images/icons/dashboard-ceo/logo-debt-solution.svg'),
      imgBoostCredit: require('@/assets/images/icons/dashboard-ceo/logo-boost-credit.svg'),
      imgCreditExperts: require('@/assets/images/icons/dashboard-ceo/logo-credit-experts.svg'),
      imgBusiness: require('@/assets/images/icons/dashboard-ceo/logo-business.svg'),
      imgTaxResearch: require('@/assets/images/icons/dashboard-ceo/logo-tax-research.svg'),
      imgSpecialist: require('@/assets/images/icons/dashboard-ceo/logo-specialist.svg'),
      imgKeyBook: require('@/assets/images/icons/dashboard-ceo/logo-keybook.svg'),
      onControl: false,
      startPage: 0,
      toPage: 0,
      isBusy: false,
      dataYear: null,
      dataMonth: null,
      titleModal: '',
      idPrograms: null,
      itemImage: [],
      initialProgram: '',
      listPrograms: [],
      sortBy: 'flyer_name',
      sortDesc: true,
      filter: ProgramsFilter,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search by lead name...',
        model: '',
      },
      fields: [
        {
          key: 'program_id',
          sortable: false,
          label: 'Program',
        },
        {
          key: 'client_name',
          sortable: false,
          label: 'Client Name',
        },
        {
          key: 'seller_name',
          sortable: false,
          label: 'Seller Name',
        },
        {
          key: 'fee',
          sortable: false,
          label: 'Fee',
        },
        {
          key: 'amount',
          sortable: false,
          label: 'Initial Payment',
        },
        {
          key: 'source_name',
          sortable: false,
          label: 'Source',
        },
        {
          key: 'created_at',
          sortable: false,
          label: 'Created by',
        },
      ],
      mainProps: {
        center: false,
        fluidGrow: false,

        width: 100,

        block: false,

        fluid: true,
      },
      repliesDetailedItems: [],
      showImage: null,
      totalRows: 1,
      totalFee: 0,
      totalInitialPayment: 0,
      paginate: {
        perPage: 50,
        currentPage: 1,
      },
      typeExpenses: '',
    };
  },
  computed: {
    ...mapGetters({
      titleMonthYear: 'DashboardCeoStore/G_TITLE_MODAL',
    }),
  },
  async created() {
    await this.getPrograms();
    this.onControl = true;
    this.dataMonth = this.sendMonth;
    this.dataYear = this.sendYear;
  },
  methods: {
    close() {
      this.$refs['myModal'].hide();
      this.$emit('close');
    },
    async searchDetailIncomes(ctx) {
      const { data } = await DashboardServive.getMarketingRoiDetailedData({
        year: this.dataYear,
        type: 'sales_by_flyer',
        program_id: this.filter[0].model,
        search_text: this.filterPrincipal.model,
        month: this.dataMonth,
        page: ctx.currentPage,
        perpage: ctx.perPage,
        flyer_id: this.flyerId,
      });
      this.repliesDetailedItems = data.data;
      this.repliesDetailedItems.forEach(reply => {
        reply.show_more = false;
      });
      this.pushImage();
      this.startPage = data.from;
      this.paginate.currentPage = data.current_page;
      this.paginate.perPage = data.per_page;
      this.totalRows = data.total;
      this.toPage = data.to;
      this.totalFee = this.repliesDetailedItems[0]?.total_fee ?? 0;
      this.totalInitialPayment = this.repliesDetailedItems[0]?.total_initial_payment ?? 0;
      return this.repliesDetailedItems || [];
    },
    pushImage() {
      const imageArray = [];
      this.repliesDetailedItems.forEach(element => {
        imageArray.push({
          title: element.campaign,
          src: element.route,
          thumb: element.route_thumb,
        });
      });
      this.itemImage = imageArray;
    },
    openImage(index) {
      this.showImage = index;
    },
    routers(program, id) {
      let route = '';
      // eslint-disable-next-line default-case
      switch (program) {
        case 1:
          route = `/bussiness/clients/account/${id}`;
          break;
        case 2:
          route = `/boostcredit/clients/account/${id}`;
          break;
        case 3:
          route = `/creditexperts/clients/account/${id}`;
          break;
        case 4:
          route = `/debtsolution/clients/account/${id}`;
          break;
        case 5:
          route = `/taxresearch/clients/account/${id}`;
          break;
        case 7:
          route = `/specialists/clients/account/${id}`;
          break;
        case 8:
          route = `/bookeeping/clients/account/${id}`;
          break;
        case 9:
          route = `/ti/clients/account/${id}`;
          break;
      }
      return route;
    },
    imgPrograms(programId) {
      switch (programId) {
        case 1:
          return this.imgBusiness;
        case 2:
          return this.imgBoostCredit;
        case 3:
          return this.imgCreditExperts;
        case 4:
          return this.imgDebtSolution;
        case 5:
          return this.imgTaxResearch;
        case 7:
          return this.imgSpecialist;
        case 8:
          return this.imgKeyBook;
        default:
          return this.imgKeyBook;
      }
    },
    async getPrograms() {
      try {
        const { data } = await DashboardServive.programs();
        const defaultOption = {
          id: null,
          name: 'All programs',
          value: 'All programs',
        };
        data.unshift(defaultOption);
        this.filter[0].options = data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>
    <style lang="scss" scoped>
        .text-money{
        font-weight:500;
        &::before{
            content:"$";
        }
        }
        .header-modal{
        position:relative;
        padding: 20px 0 20px 15px;
        background:rgba(22, 84, 204, 1);
        width:100%;
        font-size:22px;
        display:flex;
        justify-content:space-betweeen;
        span{
                font-size:1.6rem;
                font-weight: bold;
                margin-left: 8px;
                color:#FFFFFF;
            }

            .container-icons{
                background-color:#FFFFFF;
                padding: 2.7px 6.37px;
                border-radius: 0.357rem;
                width: 31px;
                height: 30px;
                display:flex;
                align-items:center;
                justify-content:center;
                position:absolute;
                top:15px;
                right:20px;
                transition: all 0.23s ease 0.1s;
                &--dark{
                background-color:#17171a;
                }
                &:hover{
                transform: translate(-7px, 5px);
                cursor:pointer;
                }
            }
        }
        .charge__programs {
        width: 47px;
        height: 40px;
        background-color: #E9E9E9;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 35px;
            height: 35px;
        }
        }
        .fixed-badge-width {
            width: 30px; // Set your desired fixed width here
            text-align: center; // Center the text within the fixed width
        }
</style>
