<template>
  <b-modal
    v-model="onControl"
    title="Deposit"
    title-tag="h3"
    modal-class="modal-primary"
    button-size="xmd"
    ok-title="OK"
    centered
    @hidden="close"
    size="xmd"
    scrollable
    no-close-on-backdrop
  >
    <b-card>
      <b-card-title>PAYMENTS</b-card-title>
      <b-table
        id="payments-list"
        ref="paymentsList"
        no-border-collapse
        class="position-relative"
        show-empty
        no-provider-filtering
        sticky-header="50vh"
        primary-key="id"
        responsive="sm"
        :items="items"
        :fields="fields"
        :style="payments.length ? '' : 'overflow-y: scroll; height: 238px;'"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>

        <template #cell(delete)="data">
          <feather-icon
            v-if="payments.length > 1"
            class="text-danger cursor-pointer"
            icon="MinusCircleIcon"
            @click="deleteRow(data.item.id)"
          />
        </template>

        <template #cell(client_name)="{ item }">
          <span class="text-primary cursor-pointer">
            {{ item.client_name || item.lead_name }}
            <b-badge
              class="ml-1"
              :variant="item.lead_name ? 'light-warning' : 'light-success'"
              >{{ item.lead_name ? "Lead" : "Client" }}
            </b-badge>
          </span>
        </template>

        <template #cell(modality)="{ item }">
          {{ item.modality === "Monthly" ? "Monthly Payment" : item.modality }}
        </template>

        <template #cell(amount)="{ item }">
          <span class="text-success">$ {{ item.amount | currency }}</span>
        </template>

        <template #cell(transaction_id)="{ item }">
          {{ item.voucher_id }}
        </template>
      </b-table>
    </b-card>

    <validation-observer ref="refFormObserver">
      <b-card>
        <b-card-title>ENTER INFORMATION OF THE DEPOSIT</b-card-title>
        <b-row class="mt-2">
          <b-col lg="6">
            <b-row>
              <b-col>
                <validation-provider
                  v-slot="{ errors }"
                  name="depositId"
                  rules="required"
                >
                  <b-form-group
                    label="DEPOSIT NUMBER"
                    label-for="deposit_number"
                  >
                    <b-form-input
                      v-model="depositData.deposit_number"
                      id="deposit_number"
                      trim
                      :state="errors[0] ? false : null"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label-for="amount_deposit"
                  :label="`Amount ($ ${totalAmount.toFixed(2)})`"
                >
                  <money
                    :value="totalAmount"
                    v-bind="vMoney"
                    id="amount_deposit"
                    class="form-control"
                    disabled
                  >
                  </money>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <validation-provider
                  v-slot="{ errors }"
                  name="depositDate"
                  rules="required"
                >
                  <b-form-group label="Date" label-for="date_deposit">
                    <b-form-input
                      v-model="depositData.date"
                      id="date_deposit"
                      type="date"
                      trim
                      :state="errors[0] ? false : null"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <validation-provider
                  v-slot="{ errors }"
                  name="depositHour"
                  rules="required"
                >
                  <b-form-group label="Hour" label-for="hour_deposit">
                    <b-form-input
                      v-model="depositData.hour"
                      id="hour_deposit"
                      type="time"
                      step="3600"
                      trim
                      :state="errors[0] ? false : null"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6" class="text-center">
            <b-form-group
              v-b-tooltip.hover.top="'Upload an image of the deposit'"
            >
              <div class="image-upload">
                <label for="file_input_1" class="label-image">
                  <div class="form-group cursor-pointer">
                    <input
                      type="file"
                      id="file_input_1"
                      accept="image/*"
                      @change="getImage"
                      hidden
                    />
                    <figure class="figure">
                      <img
                        class="image-message"
                        :src="imageShow"
                        alt="Upload an image"
                      />
                    </figure>
                  </div>
                </label>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </validation-observer>

    <template #modal-footer>
      <b-button variant="success" @click="save"> Save </b-button>
    </template>
  </b-modal>
</template>

<script>
import OtherPaymentsService from "@/views/commons/components/other-payments/service/other-payment.service.js";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    payments: [],
    transactions: [],
  },
  components: {},
  data() {
    let hour = new Date().toTimeString().split(" ")[0];
    let hourMinute = hour.split(":")[0] + ":" + hour.split(":")[1];
    return {
      onControl: false,
      items: [],
      fields: [
        {
          key: "delete",
          label: "",
        },
        {
          key: "client_name",
          label: "Client Name",
        },
        {
          key: "modality",
          label: "Modality",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "voucher_id",
          label: "Transaction ID",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "amount",
          label: "Amount",
        },
      ],
      defaultImage: "/assets/images/icons/sms-img.png",
      viewImage: "",
      depositData: {
        deposit_number: "",
        date: new Date().toISOString().split("T")[0],
        hour: hourMinute,
        image: "",
        payments: [],
      },
      vmoneyValidate: false,
      vMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        precision: 2,
        masked: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    imageShow() {
      return this.viewImage;
    },
    totalAmount() {
      return this.payments.reduce((acc, { amount }) => acc + amount * 1, 0);
    },
    depositPayments() {
      this.payments.forEach(({ id }) => {
        this.depositData.payments.push(id);
      });

      return this.depositData.payments;
    },
  },
  methods: {
    ...mapActions({
      A_COUNTER_OP: "other-payment-store/A_COUNTER_OP",
    }),
    async save() {
      try {
        this.vmoneyValidate = true;
        const validation = await this.$refs.refFormObserver.validate();
        if (validation) {
          if (this.viewImage == this.defaultImage) {
            await this.showInfoSwal(
              "Please upload an image of the deposit",
              "Image required"
            );
          } else {
            const confirm = await this.showConfirmSwal();
            if (!confirm.isConfirmed) {
              return;
            }
            const formData = new FormData();
            formData.append("payments", this.depositPayments);
            formData.append("deposit_number", this.depositData.deposit_number);
            formData.append("amount", this.totalAmount);
            formData.append("date", this.depositData.date);
            formData.append("hour", this.depositData.hour.concat(":00"));
            formData.append("created_by", this.currentUser.user_id);
            formData.append("image", this.depositData.image);
            this.addPreloader();
            await OtherPaymentsService.saveDeposit(formData);
            this.$emit("refresh");
            this.$emit("close");
            this.showSuccessSwal();
            await this.A_COUNTER_OP({ module_id: this.moduleId });
          }
        }
      } catch (error) {
        if (error.response.status == 422) {
          this.showInfoSwal(error.response.data.error, "Warning");
        } else {
          this.showErrorSwal(error);
        }
      } finally {
        this.removePreloader();
      }
    },
    close() {
      this.onControl = false;
      this.$emit("close");
    },
    paymentsProvider() {
      this.items = this.payments;
    },
    getImage(e) {
      let file = e.target.files[0];
      this.depositData.image = file;
      this.uploadImage(file);
    },
    uploadImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.viewImage = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    deleteRow(id) {
      for (let i = 0; i < this.payments.length; i++) {
        if (this.payments[i].id == id) {
          this.payments.splice(i, 1);
        }
      }
    },
  },
  created() {
    this.paymentsProvider();
    this.viewImage = this.defaultImage;
    this.onControl = true;
  },
  mounted() {},
};
</script>

<style scoped>
.figure {
  width: 237px;
}

.image-message {
  max-width: 14rem !important;
  min-width: 14rem !important;
  max-height: 14rem !important;
}
</style>
