<template>
  <div>
    <b-modal
      v-model="onControl"
      @hidden="close"
      size="caro"
      modal-class="custom-modal-amg"
      hide-footer
    >
      <template #modal-title>
        <h2 class="text-white text-uppercase">
          {{ methodTitle }}
        </h2>
      </template>
      <div class="container-fluid mx-1">
        <div
          class="absolute-linker row d-flex align-items-end my-2"
          :class="{
            dark: isDarkSkin,
          }"
        >
          <!-- Filter by credit card -->
          <div class="d-flex align-items-center" v-if="method == 'CREDIT_CARD'">
            <v-select
              :options="creditCards"
              v-model="cardSelected"
              label="cardholdername"
              :reduce="(option) => option.credit_card_expense_id"
              placeholder="Select a card"
              style="min-width: 300px"
              @input="changeCreditCard()"
              :clearable="false"
            >
              <template v-slot:option="option">
                <div class="d-flex flex-column">
                  <span>Bank: {{ option.bank_name }}</span>
                  <span
                    >Card: {{ option.cardholdername }} | XXXX-{{
                      option.cardnumber.slice(-4)
                    }}</span
                  >
                </div>
              </template>
              <template #selected-option="option">
                <div class="d-flex flex-column">
                  <span>Bank: {{ option.bank_name }}</span>
                  <span
                    >Card: {{ option.cardholdername }} | XXXX-{{
                      option.cardnumber.slice(-4)
                    }}</span
                  >
                </div>
              </template>
            </v-select>
          </div>
          <!-- Filter by department -->
          <div
            class="d-flex"
            :class="{ 'flex-shrink-0 ml-1': method === 'CREDIT_CARD' }"
          >
            <v-select
              :options="departments"
              v-model="departmentSelected"
              label="name"
              :reduce="(option) => option.module_id"
              placeholder="Select a department"
              style="min-width: 150px"
              :clearable="false"
              @input="refresh()"
            >
              <template v-slot:option="option">
                <div>
                  <span
                    ><img
                      :src="`/assets/${option.icons}`"
                      alt="Logo"
                      height="20"
                      width="20"
                  /></span>
                  <span style="margin-left: 5px">{{ option.name }}</span>
                </div>
              </template>
              <template #selected-option="option">
                <div>
                  <span
                    ><img
                      :src="`/assets/${option.icons}`"
                      alt="Logo"
                      height="20"
                      width="20"
                  /></span>
                  <span style="margin-left: 5px">{{ option.name }}</span>
                </div>
              </template>
            </v-select>
          </div>
          <!-- Filter by date -->
          <div class="d-flex align-items-center h-full px-1 ms-auto">
            <feather-icon
              icon="ChevronLeftIcon"
              size="20"
              class="cursor-pointer"
              @click="prev()"
            />
            <span class="font-size:15px">
              {{ months[dataMonth - 1] }} {{ dataYear }}
            </span>
            <feather-icon
              icon="ChevronRightIcon"
              size="20"
              class="cursor-pointer"
              :class="{ 'disabled-next': disableNext }"
              @click="next()"
            />
          </div>
        </div>
      </div>
      <filter-slot
        :filter="[]"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        :filter-principal="filterPrincipal"
        @reload="refresh"
        :noVisiblePrincipalFilter="method == 'CHECK'"
      >
        <template #buttons>
          <div class="ml-1">
            <b-form-group class="m-0">
              <v-select
                v-bind="payableExpenseScope.attr"
                v-model="selectedPayable"
                :placeholder="placeholderSelectVendor"
                style="width: 290px"
              ></v-select>
            </b-form-group>
          </div>
        </template>
        <template #table>
          <b-table
            ref="refDetailDepartmentExpenses"
            :items="getDetailExpenses"
            :fields="visibleFields"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
            show-empty
            :busy.sync="isBusy"
            sticky-header="60vh"
            class="table-new-customization filter-slot-new-customization"
            foot-clone
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle" />
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(type)="{ item }">
              <b-badge
                class="px-1"
                :class="item.type == 'CLIENT' ? 'clickable' : ''"
                :variant="item.type == 'CLIENT' ? 'primary' : 'info'"
                @click="
                  item.type == 'CLIENT' ? openClientExpenses(item) : false
                "
              >
                {{ item.type }}
                {{ item.totalClient > 0 ? `(${item.totalClient})` : null }}
              </b-badge>
            </template>
            <template #cell(concept)="{ item }">
              <p
                class="text-info text-truncate"
                style="width: 140px; max-width: 140px"
                v-b-tooltip
                :title="item.description"
              >
                {{ item.concept }}
              </p>
            </template>
            <template #cell(details)="{ item }">
              <b-row>
                <b-col cols="2"
                  ><strong>{{
                    method == "CREDIT_CARD" ? "Vendor" : "Pay to"
                  }}</strong></b-col
                >
                <b-col>{{ item.vendor }}</b-col>
                <div class="w-100" />
                <b-col cols="2"
                  ><strong>{{
                    method == "CREDIT_CARD" ? "Concept" : "Memo"
                  }}</strong></b-col
                >
                <b-col>{{ item.concept }}</b-col>
                <div class="w-100" />
                 <b-col v-if="item.check_number && method == 'E-CHECK'" cols="2">
                  <strong>Check #</strong>
                </b-col>
                <b-col v-if="item.check_number && method == 'E-CHECK'">{{
                  item.check_number
                }}</b-col>
                <div class="w-100" />
                <b-col v-if="item.reference_number" cols="2">
                  <strong>Ref. #</strong>
                </b-col>
                <b-col v-if="item.reference_number">{{
                  item.reference_number
                }}</b-col>
                <div class="w-100" />
                <b-col v-if="method == 'CHECK'" cols="2">
                  <strong>Address</strong>
                </b-col>
                <b-col v-if="method == 'CHECK'"
                  ><span v-html="formatAddress(item)"></span
                ></b-col>
                <div class="w-100" />
                <b-col v-if="method == 'CHECK'" cols="2"
                  ><strong>Date</strong></b-col
                >
                <b-col v-if="method == 'CHECK'">{{
                  item.date | myGlobal
                }}</b-col>
                <div class="w-100" />
                <b-col v-if="item.url_file" cols="2">
                  <strong>{{ method == "CHECK" ? "File" : "Invoice" }} </strong>
                </b-col>
                <b-col v-if="item.url_file">
                  <a :href="item.url_file" target="_blank" :title="item.file">
                    <amg-icon
                      class="cursor-pointer text-danger"
                      icon="FilePdfIcon"
                      size="18"
                    />
                  </a>
                </b-col>
              </b-row>
            </template>
            <template #cell(amount)="{ value }">
              <span class="text-success">
                {{ value | currencyTwo | myMoney }}</span
              >
            </template>
            <template #cell(date)="{ value }">
              <span>{{ value | myGlobal }}</span>
            </template>
            <template #cell(created_by)="{ item }">
              <div>
                <div>
                  {{ item.created_by_id == 0 ? "System" : item.created_by }}
                </div>
                <div>{{ item.created_at | myGlobalDay }}</div>
              </div>
            </template>
            <template #cell(collected_at)="{ item }">
              {{ item.collect_date | myGlobal }}
              <a :href="item.collect_path" target="_blank">
                <feather-icon
                  class="cursor-pointer text-danger"
                  icon="FileIcon"
                  size="18"
                />
              </a>
            </template>
            <template #cell(check_number)="{ item }">
              <b-row>
                <b-col cols="12">
                  <strong>#: </strong>
                  <span>{{ item.check_number || "-" }}</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12"
                  ><strong>Show: </strong
                  ><a
                    v-if="item.check_path"
                    :href="item.check_path"
                    target="_blank"
                    :title="item.file"
                  >
                    <amg-icon
                      class="cursor-pointer text-danger"
                      icon="FilePdfIcon"
                      size="18"
                    />
                  </a>
                  <span v-else>-</span>
                </b-col>
              </b-row>
            </template>
            <template #cell(confirmed_at)="{ item }">
              <div>
                <!-- <div>{{ item.approved_by }}</div> -->
                <div>{{ item.confirmed_at | myGlobalDay }}</div>
              </div>
            </template>
            <template #cell(tracking)="{ item }">
              <div>
                <feather-icon
                  class="text-info cursor-pointer"
                  v-b-tooltip.hover.right="'Tracking'"
                  @click="openChecksTrackingModal(item)"
                  size="16"
                  icon="ListIcon"
                />
              </div>
            </template>
            <template #foot(type)>
              <div
                class="row d-flex justify-content-end flex-column"
                style="gap: 5px"
              >
                <div><b-badge variant="info">Sub total:</b-badge></div>
                <div><b-badge variant="info">Total:</b-badge></div>
              </div></template
            >
            <template #foot(amount)>
              <div
                class="row d-flex justify-content-center flex-column"
                style="gap: 5px"
              >
                <div>
                  {{ subTotal | formatMoney }}
                </div>
                <div>{{ totalAmount | currencyTwo | myMoney }}</div>
              </div></template
            >
            <template #foot()> &nbsp;</template>
          </b-table>
        </template>
      </filter-slot>
      <client-expense-request
        v-if="clientExpenseModal.show"
        :expenseDepartment="clientExpenseModal.expenseDepartment"
        @close="clientExpenseModal.show = false"
      />
    </b-modal>
    <ChecksTrackingModal
      v-if="showChecksTrackingModal"
      :dataRow="selectedCheck"
      @close="showChecksTrackingModal = false"
    ></ChecksTrackingModal>
  </div>
</template>

<script>
import Fields from "@/views/ceo/dashboard/data/expenses-detail.fields.js";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import RoutesProgram from "@/views/commons/components/ring-central/call-log/data/RoutesName.js";
import TypeCard from "@/views/commons/card/TypeCard.vue";
import DepartmentExpenses from "@/views/commons/expenses/services/expenses-department.service";
import ClientExpenseRequest from "@/views/ceo/dashboard/modals/expenses-request/ClientExpenseRequest.vue";
import DashboardService from "@/views/ceo/service-dashboard/dashboard.service";
import ChecksTrackingModal from "@/views/commons/expenses/components/Modal/ChecksTrackingModal.vue";
import { modifyVisibility, modifyLabel } from "@/helpers/fields-table";
import moment from "moment";

export default {
  components: {
    StatusAccount,
    TypeCard,
    ClientExpenseRequest,
    ChecksTrackingModal,
  },
  props: {
    sendYear: {
      required: true,
      type: Number,
    },
    sendMonth: {
      required: true,
      type: Number,
    },
    module_id: {
      type: Number,
      default: null,
    },
    cardId: {
      type: Number,
      default: null,
    },
    method: {
      type: String,
      required: true,
      default: "CREDIT_CARD",
    },
  },
  async created() {
    this.onControl = true;
    this.cardSelected = this.cardId;
    this.departmentSelected = this.module_id;
    this.initMonthYear();
    await this.getCardList();
    await this.getDepartmentList();
    this.loadPayableExpense();
    this.ready = true;
    // await this.getDetailExpenses();
  },
  data() {
    return {
      onControl: false,
      items: [],
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      startPage: 0,
      toPage: 0,
      totalRows: 0,
      isBusy: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by concept",
        model: "",
      },
      fields: Fields,
      total: 0,
      routesPrograms: [...RoutesProgram],
      dataPayableExpense: [],
      selectedPayable: null,
      clientExpenseModal: {
        show: false,
        expenseDepartment: null,
      },
      creditCards: [],
      cardSelected: null,
      cpyMonth: null,
      dataYear: null,
      dataMonth: null,
      cpyYear: null,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      departments: [],
      departmentSelected: null,
      totalAmount: 0,
      ready: false,
      showChecksTrackingModal: false,
      selectedCheck: null,
      dataPayableExpense: [],
      childrenCount: 3,
    };
  },
  computed: {
    visibleFields() {
      modifyVisibility(
        this.fields,
        "tracking",
        ["CHECK", "E-CHECK"].includes(this.method)
      );
      modifyVisibility(this.fields, "date", this.method != "CHECK");
      modifyVisibility(this.fields, "collected_at", this.method == "CHECK");
      modifyVisibility(this.fields, "check_number", this.method == "CHECK");
      if (this.method == "CHECK") {
        modifyLabel(this.fields, "details", "CHECK DETAILS");
        modifyLabel(this.fields, "created_by", "REQUESTED BY");
      } else if (this.method == "E-CHECK") {
        modifyLabel(this.fields, "details", "E-CHECK DETAILS");
      }
      return this.fields.filter((item) => item.visible);
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    programId() {
      return this.$route.matched[0].meta.program;
    },
    payableExpenseScope() {
      return {
        attr: {
          options: this.dataPayableExpense,
          label: "description",
          reduce: (item) => item.id,
        },
      };
    },
    subTotal() {
      return this.items.reduce(
        (total, item) => total + parseFloat(item.amount),
        0
      );
    },
    disableNext() {
      const now = moment();
      const sameYear = now.year() === this.dataYear;
      const sameMonth = now.month() + 1 === this.dataMonth;
      return sameYear && sameMonth;
    },
    generateYears() {
      const years = [];
      const currentYear = new Date().getFullYear();
      for (let i = currentYear; i >= 2023; i--) {
        years.push(i);
      }
      return years;
    },
    payableExpenseScope() {
      return {
        attr: {
          options: this.dataPayableExpense,
          label: "description",
          reduce: (item) => item.id,
        },
      };
    },
    placeholderSelectVendor() {
      let title;
      switch (this.method) {
        case "CREDIT_CARD":
          title = "Select a vendor";
          break;
        case "CHECK":
        case "E-CHECK":
          title = "Select a pay to";
          break;
        default:
          title = "Select a vendor";
          break;
      }
      return title;
    },
    methodTitle() {
      switch (this.method) {
        case "CREDIT_CARD":
          return "TRANSACTIONS CONFIRMED IN THE SOFT";
        case "CHECK":
          return "CHECKS";
        default:
          return "E-CHECKS";
      }
    },
    placeholderSearchConcept() {
      let title;
      switch (this.method) {
        case "CREDIT_CARD":
          title = "Search by concept";
          break;
        case "CHECK":
        case "E-CHECK":
          title = "Search by memo";
          break;
        default:
          title = "Search by concept";
          break;
      }
      return title;
    },
  },
  methods: {
    async getDetailExpenses() {
      try {
        const params = {
          moduleId: this.departmentSelected,
          year: this.dataYear,
          month: this.dataMonth,
          page: this.paginate.currentPage,
          perpage: this.paginate.perPage,
          cardId: this.cardSelected,
          search: this.filterPrincipal.model,
          method: this.method,
          payable: this.selectedPayable,
        };
        const data = await DashboardService.getDetailExpenses(params);
        this.items = data.data;
        this.totalRows = data.total;
        this.startPage = data.from;
        this.toPage = data.to;
        this.items = data.data;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        if (this.items.length > 0) {
          this.totalAmount = this.items[0].total_amount;
        }
        return this.items;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getCardList() {
      try {
        const params = {
          month: this.dataMonth,
          year: this.dataYear,
        };
        const data = await DashboardService.getCardList(params);
        this.creditCards = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async getDepartmentList() {
      try {
        const data = await DashboardService.getDepartmentList({
          month: this.dataMonth,
          year: this.dataYear,
          cardId: this.cardSelected,
          method: this.method,
        });
        this.departments = data;
      } catch (ex) {
        this.showErrorSwal(ex);
      }
    },
    close() {
      // let reloadData = {
      //   cardId: this.cardSelected,
      //   year: this.dataYear,
      //   month: this.dataMonth,
      // }
      // this.$emit("reload", reloadData);
      this.$emit("close");
    },
    openChecksTrackingModal(item) {
      this.showChecksTrackingModal = true;
      this.selectedCheck = item;
    },
    async changeCreditCard() {
      await this.getDepartmentList();
      let index = this.departments.findIndex(
        (item) => item.id == this.departmentSelected
      );
      if (index == -1) {
        this.departmentSelected = null;
        this.notifySelectDepartment();
      }
      this.refresh();
    },
    async refresh() {
      // await this.getDetailExpenses();
      this.$refs.refDetailDepartmentExpenses.refresh();
    },
    async prev() {
      if (this.departmentSelected == null) {
        this.notifySelectDepartment();
        return;
      }
      if (this.dataMonth == null) this.dataMonth = this.cpyMonth;
      if (this.dataMonth > 1) {
        this.dataMonth--;
      } else {
        this.dataMonth = 12;
        this.dataYear--;
      }
      await this.refresh();
    },
    async next() {
      if (this.departmentSelected == null) {
        this.notifySelectDepartment();
        return;
      }
      if (this.dataMonth == null) this.dataMonth = this.cpyMonth;
      if (this.disableNext) return;
      if (this.dataMonth < 12) {
        this.dataMonth++;
      } else {
        this.dataMonth = 1;
        this.dataYear++;
      }
      await this.refresh();
    },
    initMonthYear() {
      this.dataMonth = this.sendMonth;
      this.dataYear = this.sendYear;
    },

    filterColumn(key, bool) {
      const field = this.fields.find((field) => field.key == `${key}`);
      field.visible = bool;
    },
    getRouterName(item) {
      let route = ["lead-show", { id: item.lead_id }];

      if (this.moduleId === 18) {
        return ["quality-lead-show", { id: item.lead_id }];
      }

      for (const element of this.routesPrograms) {
        if (element.module.includes(this.moduleId)) {
          if (item.account_id) {
            route = [
              element.dashboardCLient,
              { [element.clientParam]: item.account_id },
            ];
          } else {
            route = [element.dashboardLeads, { id: item.lead_id }];
          }
        }
      }
      return route;
    },
    notifySelectDepartment() {
      this.showToast(
        "warning",
        "top-right",
        "Success",
        "CheckIcon",
        "Select a department"
      );
    },
    openClientExpenses(item) {
      this.clientExpenseModal.show = true;
      this.clientExpenseModal.expenseDepartment = JSON.parse(item.clients)[0];
      this.clientExpenseModal.expenseDepartment.method = this.method;
    },
    async loadPayableExpense() {
      try {
        const data = await DepartmentExpenses.getPayableExpenses({
          module_id: this.departmentSelected,
          program_id: null,
          method: this.method,
        });
        this.dataPayableExpense = data;
      } catch (ex) {
        this.showErrorSwal(ex);
      }
    },
  },
  watch: {
    selectedPayable() {
      this.refresh();
    },
    departmentSelected() {
      this.selectedPayable = null;
      this.loadPayableExpense();
    },
    placeholderSearchConcept: {
      handler(value) {
        this.filterPrincipal.placeholder = value;
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.table-new-customization::v-deep tfoot {
  position: sticky;
  bottom: 0;
  color: rgba(63, 122, 250, 0.882) !important;
  font-size: 14.5px;
  font-weight: bold !important;
}

.table-new-customization::v-deep tfoot tr th {
  font-size: 13px;
  background: rgba(63, 122, 250, 0.882) !important;
  color: #fff !important;
}

.table-new-customization::v-deep tfoot tr {
  position: sticky !important;
  bottom: 0 !important;
}

.table-new-customization::v-deep tfoot tr th:first-child {
  border-radius: 0 0 0 15px !important;
}

.table-new-customization::v-deep tfoot tr th:last-child {
  border-radius: 0 0 15px 0 !important;
}
.total-client {
  font-size: 14px;
  padding: 0.8rem;
  span {
    border-bottom: 2px solid #0090e7;
    padding: 0px 5px;
    font-weight: bold !important;
  }
  :hover {
    cursor: pointer;
    span {
      box-shadow: rgba(255, 255, 255, 0.856) 0px 54px 55px,
        rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px,
        rgba(241, 239, 239, 0.09) 0px -3px 5px;
    }
  }
}
.row {
  display: flex;
  align-items: center;
}

.d-flex {
  display: flex;
}

.ms-auto {
  margin-left: auto;
}

.flex-shrink-0 {
  flex-shrink: 0;
}
</style>
