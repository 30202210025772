import { amgApi } from "@/service/axios";

class DashboardService {

    async getCalls4Hour(payload) {
        try {
            const data = await amgApi.post('/ring-central/get-call-for-hour', payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on getCalls4Hour:", error);
            throw error;
        }
    }

    async getCallsByDuration(payload) {
        try {
            const data = await amgApi.post('/ring-central/get-call-by-duration', payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on getCallsByDuration:", error);
            throw error;
        }
    }

    async getFrecuencyClientsContacts(payload) {
        try {
            const data = await amgApi.post('/ring-central/get-frecuency-clients-contact', payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on getFrecuencyClientsContacts:", error);
            throw error;
        }
    }

    async getCallsByRange(payload) {
        try {
            const data = await amgApi.post('/ring-central/get-calls-by-range', payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on getCallsByRange:", error);
            throw error;
        }
    }
}

export default new DashboardService();
