export default [
  {
    key: "program_id",
    type: "select",
    margin: true,
    showLabel: true,
    label: "Service",
    placeholder: "Select Program",
    model: null,
    options: [],
    reduce: "id",
    selectText: "value",
    cols: 12,
    visible: true,
  },
  {
    key: "source_id",
    type: "select",
    margin: true,
    showLabel: true,
    label: "Source",
    placeholder: "Select Source",
    model: null,
    options: [],
    reduce: "id",
    selectText: "name",
    cols: 12,
    visible: true,
  },
  {
    key: "state",
    type: "select",
    margin: true,
    showLabel: true,
    label: "State",
    placeholder: "Select State",
    model: null,
    options: [],
    reduce: "slug",
    selectText: "state",
    cols: 12,
    visible: false,
  },
  {
    key: "investment_from",
    type: "input-masked",
    margin: true,
    showLabel: true,
    label: "INV From ($):",
    placeholder: "Initial Range",
    vMask: "#######",
    model: null,
    cols: 6,
    visible: true,
  },
  {
    key: "investment_to",
    type: "input-masked",
    margin: true,
    showLabel: true,
    label: "INV To ($):",
    model: null,
    placeholder: "End Range",
    vMask: "#######",
    cols: 6,
    visible: true,
  },
  {
    key: "ip_from",
    type: "input-masked",
    margin: true,
    showLabel: true,
    label: "IP From ($):",
    placeholder: "Initial Range",
    vMask: "#######",
    model: null,
    cols: 6,
    visible: true,
  },
  {
    key: "ip_to",
    type: "input-masked",
    margin: true,
    showLabel: true,
    label: "IP To ($):",
    model: null,
    placeholder: "End Range",
    vMask: "#######",
    cols: 6,
    visible: true,
  },
  {
    key: "fee_from",
    type: "input-masked",
    margin: true,
    showLabel: true,
    label: "FEE From ($):",
    placeholder: "Initial Range",
    vMask: "#######",
    model: null,
    cols: 6,
    visible: true,
  },
  {
    key: "fee_to",
    type: "input-masked",
    margin: true,
    showLabel: true,
    label: "FEE To ($):",
    model: null,
    placeholder: "End Range",
    vMask: "#######",
    cols: 6,
    visible: true,
  },
];
