<template>
  <div>
    <b-modal
      v-model="showModal"
      title="CHECKS PENDING APPROVAL"
      size="xlg"
      @hidden="closeModal"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-nav card-header pills class="m-0">
        <b-nav-item
          v-for="(tab, index) in tabs"
          :key="index"
          exact
          :link-classes="['px-3', bgTabsNavs]"
          :active="tabSelected === index"
          @click="changeTab(index)"
          >{{ tab }}
          <span v-if="showCounter(index) > 0" class="ml-2">
            <feather-icon
              icon
              :badge="showCounter(index)"
              badge-classes="badge-danger"
          /></span>
        </b-nav-item>
      </b-nav>
      <div class="border-top-primary border-3 border-table-radius px-0 pt-2">
        <b-table
          ref="detailsExpenses"
          no-border-collapse
          class="position-relative table-new-customization"
          :fields="visibleFields"
          show-empty
          no-provider-filtering
          sticky-header="70vh"
          primary-key="id"
          responsive="sm"
          :items="items"
          table-class="text-nowrap"
        >
          <template #cell(module)="{ item }">
            <div class="d-flex justify-content-center align-items-center">
              <img
                v-b-tooltip.hover.left="item.module_name"
                :src="`/assets/${item.icons}`"
                alt="Logo"
                height="30"
                width="30"
              />
            </div>
          </template>
          <template #cell(type)="{ item }">
            <b-badge
              class="px-1"
              :class="item.type == 'CLIENT' ? 'clickable' : ''"
              :variant="item.type == 'CLIENT' ? 'primary' : 'info'"
              @click="item.type == 'CLIENT' && openClientExpenses(item)"
            >
              {{ item.type }}
              {{ item.total_clients > 0 ? `(${item.total_clients})` : null }}
            </b-badge>
          </template>
          <template #cell(check_details)="{ item }">
            <b-row>
              <b-col cols="2"><strong>Pay to</strong></b-col>
              <b-col cols="9">{{ item.vendor }}</b-col>
              <b-col cols="1">
                <feather-icon
                  v-if="isCeo && item.status_check == 'PENDING'"
                  @click="openVendorConceptEditorModal(item, 'vendor')"
                  icon="EditIcon"
                  v-b-tooltip.hover.right.v-warning="'Edit vendor'"
                  class="text-warning clickable"
                />
              </b-col>
              <div class="w-100"></div>
              <b-col cols="2"><strong>Memo</strong></b-col>
              <b-col cols="9">{{ item.concept }}</b-col>
              <b-col cols="1">
                <feather-icon
                  v-if="isCeo && item.status_check == 'PENDING'"
                  @click="openVendorConceptEditorModal(item, 'concept')"
                  icon="EditIcon"
                  v-b-tooltip.hover.right.v-warning="'Edit concept'"
                  class="text-warning clickable"
                />
              </b-col>
              <div class="w-100"></div>
              <b-col cols="2"><strong>Address</strong></b-col>
              <b-col cols="9">
                <span v-html="formatAddress(item)"></span>
              </b-col>
              <b-col cols="1">
                <feather-icon
                  v-if="isCeo && item.status_check == 'PENDING'"
                  @click="openAddressEditorModal(item)"
                  icon="EditIcon"
                  v-b-tooltip.hover.right.v-warning="'Edit address'"
                  class="text-warning clickable"
                />
              </b-col>
              <div class="w-100"></div>
              <b-col cols="2"><strong>Date</strong></b-col>
              <b-col>{{ item.payment_date | myGlobal }}</b-col>
              <div class="w-100" />
              <b-col cols="2" v-if="item.url_file"><strong>File</strong></b-col>
              <b-col v-if="item.url_file">
                <a :href="item.url_file" target="_blank" :title="item.file">
                  <amg-icon
                    class="cursor-pointer text-danger"
                    icon="FilePdfIcon"
                    size="18"
                  />
                </a>
              </b-col>
            </b-row>
          </template>
          <template #cell(amount)="{ item }">
            {{ item.amount | formatMoney }}
          </template>
          <template #cell(status_check)="{ item }">
            <span :class="colorText(item)">{{ statusCheck(item) }}</span>
          </template>
          <template #cell(check_number)="{ item }">
            <div v-if="item.old_check_number" class="d-flex flex-column">
              <div class="d-flex">
                <span class="text-warning"> Old: </span>
                <span style="margin-left: 5px">
                  {{ item.old_check_number }}
                </span>
              </div>
              <div class="d-flex">
                <span class="text-warning"> New: </span>
                <span style="margin-left: 5px">
                  {{ item.check_number }}
                  <tabler-icon
                    v-if="item.validate_check_number == 1 && tabSelected == 2"
                    icon="CheckIcon"
                    class="text-success cursor-pointer"
                    size="22"
                    v-b-tooltip.hover.top="'Mark as read'"
                    @click="validateCheckNumber(item)"
                  />
                </span>
              </div>
            </div>
            <div v-else>
              <span>{{ item.check_number || "-" }}</span>
            </div>
          </template>
          <template #cell(requested_by)="{ item }">
            <span class="d-block">{{
              item.requested_by_id == 0 ? "System" : item.requested_by
            }}</span>
            <span class="d-block">{{ item.requested_at | myGlobalDay }} </span>
          </template>

          <template #cell(actions)="{ item }">
            <tabler-icon
              v-if="item.status_check == 'PENDING' && tabSelected == 0"
              icon="ThumbUpIcon"
              class="text-success clickable"
              size="22"
              v-b-tooltip.hover.top="'Approve'"
              @click="uploadCheck(item)"
            />
            <tabler-icon
              v-if="item.status_check == 'PENDING' && tabSelected == 0"
              icon="ThumbDownIcon"
              class="text-danger clickable"
              size="22"
              v-b-tooltip.hover.top="'Reject'"
              @click="openObservationsModal(item, 'rejected')"
            />
            <feather-icon
              v-if="
                item.status_check == 'PENDING COLLECTION' && tabSelected == 1
              "
              icon="DollarSignIcon"
              class="text-success clickable"
              size="22"
              v-b-tooltip.hover.top="'Collected'"
              @click="confirmCheck(item)"
            />
            <tabler-icon
              v-if="
                item.status_check == 'PENDING COLLECTION' && tabSelected == 1
              "
              icon="CircleXIcon"
              class="text-danger clickable ml-1"
              size="22"
              v-b-tooltip.hover.top="'Not collected'"
              @click="openObservationsModal(item, 'not collected')"
            />
          </template>
          <template #cell(tracking)="{ item }">
            <div>
              <feather-icon
                class="text-info cursor-pointer"
                v-b-tooltip.hover.right="'Tracking'"
                @click="openChecksTrackingModal(item)"
                size="16"
                icon="ListIcon"
              />
            </div>
          </template>
        </b-table>
      </div>
    </b-modal>
    <UploadCheck
      v-if="showUploadCheck"
      :checkData="itemSelected"
      @close="showUploadCheck = false"
      @reload="refresh"
    />
    <ObservationsModal
      v-if="showObservationsModal"
      :expenseData="selectedItem"
      @close="showObservationsModal = false"
      @save="annulCheck"
    />
    <client-expense-request
      v-if="clientExpenseModal.show"
      :expenseDepartment="clientExpenseModal.expenseDepartment"
      @close="clientExpenseModal.show = false"
    />
    <ConfirmCheck
      v-if="showConfirmCheck"
      :checkData="itemSelected"
      @close="showConfirmCheck = false"
      @reload="refresh"
    />
    <VendorConceptEditorModal
      v-if="showVendorConceptEditorModal"
      :vcTarget="vcTarget"
      @closing="showVendorConceptEditorModal = false"
      @refresh="refresh"
    />
    <ChecksTrackingModal
      v-if="showChecksTrackingModal"
      :dataRow="selectedCheck"
      @close="showChecksTrackingModal = false"
    ></ChecksTrackingModal>
    <PayableExpenseAddressEditorModal
      v-if="showAddressEditorModal"
      :selectedAddress="selectedAddress"
      @close="showAddressEditorModal = false"
      @refresh="refresh"
    />
  </div>
</template>
<script>
import DashboardService from "@/views/ceo/service-dashboard/dashboard.service";
import Fields from "@/views/ceo/dashboard/modals/expenses-request/data/pending-approval-checks.fields.js";
import UploadCheck from "@/views/ceo/dashboard/modals/expenses-request/UploadCheck.vue";
import ObservationsModal from "@/views/ceo/dashboard/modals/expenses-request/ObservationsModal.vue";
import ClientExpenseRequest from "@/views/ceo/dashboard/modals/expenses-request/ClientExpenseRequest.vue";
import ConfirmCheck from "@/views/ceo/dashboard/modals/expenses-request/ConfirmCheck.vue";
import VendorConceptEditorModal from "@/views/ceo/dashboard/modals/expenses-request/VendorConceptEditorModal.vue";
import ChecksTrackingModal from "@/views/commons/expenses/components/Modal/ChecksTrackingModal.vue";
import { mapGetters, mapActions } from "vuex";
import PayableExpenseAddressEditorModal from "@/views/ceo/dashboard/modals/expenses-request/PayableExpenseAddressEditorModal.vue";
import { modifyLabel, modifyVisibility } from "@/helpers/fields-table";

export default {
  components: {
    UploadCheck,
    ObservationsModal,
    ClientExpenseRequest,
    ConfirmCheck,
    VendorConceptEditorModal,
    ChecksTrackingModal,
    PayableExpenseAddressEditorModal,
  },
  props: {
    tab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showModal: false,
      tabs: ["NEW", "UNDERVIEW", "# CHANGED"],
      items: [],
      fields: Fields,
      showObservationsModal: false,
      itemSelected: null,
      showUploadCheck: false,
      showConfirmCheck: false,
      clientExpenseModal: {
        show: false,
        expenseDepartment: null,
      },
      checksData: 0,
      checksToReview: 0,
      checksChanged: 0,
      showVendorConceptEditorModal: false,
      vcTarget: null,
      tabSelected: 0,
      showChecksTrackingModal: false,
      selectedCheck: null,
      showAddressEditorModal: false,
      selectedAddress: null,
    };
  },
  async created() {
    this.tabSelected = this.tab;
    await this.getPendingApprovalChecks();
    await this.getChecksCounter();
    this.showModal = true;
  },
  watch: {
    tabSelected(newValue) {
      this.getPendingApprovalChecks();
    },
    G_REFRESH_EXPENSES_COUNTER(newVal) {
      if (newVal) {
        this.getChecksCounter();
        this.refreshExpensesCounter(false);
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_REFRESH_EXPENSES_COUNTER: "ExpensesRequest/G_REFRESH_EXPENSES_COUNTER",
    }),
    visibleFields() {
      modifyVisibility(this.fields, "check_number", this.tabSelected != 0);
      modifyVisibility(this.fields, "actions", this.tabSelected != 2);
      return this.fields.filter((field) => field.visible);
    },
  },
  methods: {
    openChecksTrackingModal(item) {
      this.showChecksTrackingModal = true;
      this.selectedCheck = item;
      this.selectedCheck.method = "CHECK";
    },
    ...mapActions({
      refreshExpensesCounter: "ExpensesRequest/refreshExpensesCounter",
    }),
    filterColumn(key, bool) {
      let field = this.fields.find((field) => field.key == `${key}`);
      field.visible = bool;
    },
    openVendorConceptEditorModal(item, type) {
      this.showVendorConceptEditorModal = true;
      this.vcTarget = {
        id: type === "vendor" ? item.vendor_id : item.concept_id,
        type: type,
        description: type === "vendor" ? item.vendor : item.concept,
      };
    },
    openAddressEditorModal(item) {
      this.selectedAddress = {
        id: item.payable_expense_address_id,
        address: item.street_address,
        city: item.city,
        state: item.state,
        country: item.country,
        postal_code: item.postal_code,
        route: item.route,
        street_number: item.street_number,
        state_slug: item.state_slug,
      };
      this.showAddressEditorModal = true;
    },
    async getPendingApprovalChecks() {
      const params = {
        tab: this.tabSelected,
      };
      try {
        this.addPreloader();
        const data = await DashboardService.getPendingApprovalChecks(params);
        this.items = data;
        if (this.tabSelected == 2) {
          this.items.forEach((item) => {
            item._rowVariant = item.validate_check_number ? "danger" : null;
          });
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async getChecksCounter() {
      try {
        const data = await DashboardService.getChecksCounter();
        this.checksData = data.new;
        this.checksToReview = data.to_review;
        this.checksChanged = data.changed;
      } catch (error) {
        console.log(error);
      }
    },
    uploadCheck(item) {
      this.itemSelected = item;
      this.showUploadCheck = true;
    },
    openObservationsModal(item, reason) {
      this.reason = reason;
      this.showObservationsModal = true;
      this.selectedItem = item;
      this.selectedItem.method = "CHECK";
    },
    confirmCheck(item) {
      this.itemSelected = item;
      this.showConfirmCheck = true;
    },
    openClientExpenses(item) {
      this.clientExpenseModal.show = true;
      this.clientExpenseModal.expenseDepartment = {
        id: item.department_expense_id,
        type: item.type,
        vendor: item.vendor,
        concept: item.concept,
        method: "CHECK",
      };
    },
    async validateCheckNumber(item) {
      try {
        this.addPreloader();
        const params = {
          check_id: item.check_id,
        };
        const data = await DashboardService.validateCheckNumber(params);
        if (data.success) {
          this.toastSuccess();
          await this.refresh();
          await this.refreshCounter();
        } else {
          this.toastDanger();
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async annulCheck(observation) {
      let message = `Are you sure you want to annul this check?: ${this.selectedItem.check_number}`;
      let message2 = `Are you sure you want to annul this request?`;
      const confirm = await this.showConfirmSwal(
        this.selectedItem.check_number == null ? message2 : message
      );
      if (!confirm.isConfirmed) return false;

      try {
        this.addPreloader();
        const params = {
          check_id: this.selectedItem.check_id,
          user_id: this.currentUser.user_id,
          department_expense_id: this.selectedItem.department_expense_id,
          type: this.selectedItem.type,
          reason: this.reason,
          method: this.selectedItem.method,
          observation: observation,
        };
        const data = await DashboardService.annulCheck(params);
        if (data.success) {
          this.toastSuccess();
          await this.refresh();
          await this.refreshCounter();
        } else {
          this.toastDanger();
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
        this.showObservationsModal = false;
      }
    },
    showCounter(index) {
      switch (index) {
        case 0:
          return this.checksData;
        case 1:
          return this.checksToReview;
        case 2:
          return this.checksChanged;
        default:
          return 0;
      }
    },
    statusCheck(item) {
      switch (item.status_check) {
        case "PENDING":
          return "IN CEO";
        case "SEND TO COURIER":
          return "IN COURIER";
        case "SEND TO CORRESPONDENCE":
          return "IN CORRESPONDENCE";
        case "PENDING COLLECTION":
          return "PENDING COLLECT";
        default:
          return item.status_check;
      }
    },
    colorText(item) {
      switch (item.status_check) {
        case "PENDING":
          return "text-warning";
        case "SEND TO COURIER":
          return "text-primary";
        case "SEND TO CORRESPONDENCE":
          return "text-info";
        case "REJECTED":
          return "text-danger";
        case "COLLECTED":
          return "text-success";
        default:
          return "text-warning";
      }
    },
    toastSuccess() {
      this.showToast(
        "success",
        "top-right",
        "Success",
        "CheckIcon",
        "Successfully process"
      );
    },
    toastDanger() {
      this.showToast(
        "danger",
        "top-right",
        "Oop!",
        "AlertOctagonIcon",
        "Failed process"
      );
    },
    changeTab(index) {
      this.tabSelected = index;
    },
    async refresh() {
      await this.getChecksCounter();
      this.getPendingApprovalChecks();
    },
    async refreshCounter() {
      await this.refreshExpensesCounter(true);
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>