<template>
  <div>
    <b-modal
      :title="titleModal"
      v-model="showModal"
      :hide-footer="page == 1"
      size="xmd"
      @hidden="close()"
      modal-class="custom-modal-amg"
    >
      <div class="ml-1 my-1 d-flex justify-content-end align-items-center">
        <!-- <div v-if="page == 2" class="d-flex align-items-center mr-2">
          Filter by card
          <v-select
            :options="creditCards"
            v-model="selectedCard"
            label="value"
            style="min-width: 200px; margin-left: 10px"
            :reduce="(item) => item.id"
            @input="refreshCardGrid()"
          />
        </div> -->
        <div
          class="absolute-linker d-flex justify-content-end align-items-center"
          :class="{ dark: isDarkSkin }"
        >
          <div class="d-flex align-items-center mr-2">
            <p class="m-0">Filter by:</p>
            <p
              :class="useMonthFilter ? '' : 'text-warning'"
              class="my-0"
              style="font-weight: bold; margin-left: 5px"
            >
              Month
            </p>
            <b-form-checkbox
              switch
              size="md"
              v-model="useMonthFilter"
              style="padding: 5px"
              @input="changeFilter()"
            ></b-form-checkbox>
            <p
              :class="useMonthFilter ? 'text-warning' : ''"
              class="my-0"
              style="font-weight: bold"
            >
              Year
            </p>
          </div>
          <div v-if="useMonthFilter">
            <v-select
              :options="generateYears"
              v-model="dataYear"
              :class="{ 'dark-select': isDarkSkin }"
              class="mr-1"
              style="min-width: 100px"
              @input="changeYear()"
            >
            </v-select>
          </div>
          <div v-else class="d-flex align-items-center h-full px-1">
            <feather-icon
              icon="ChevronLeftIcon"
              size="20"
              class="cursor-pointer"
              @click="prev()"
            />
            <span class="font-size:15px">
              {{ months[dataMonth - 1] }} {{ dataYear }}
            </span>
            <feather-icon
              icon="ChevronRightIcon"
              size="20"
              class="cursor-pointer"
              :class="{ 'disabled-next': disableNext }"
              @click="next()"
            />
          </div>
        </div>
      </div>
      <div v-if="page == 1 || page == 2">
        <b-table
          ref="bankCardTable"
          :items="page == 1 ? totalImportedsByBank : totalImportedsByCard"
          :fields="visibleFields"
          responsive
          sticky-header="50vh"
          class="position-relative table-new-customization"
        >
          <template #cell(cardholdername)="{ item }">
            <div class="d-flex justify-content-center align-items-center">
              <type-card :type_card="item.type_card" />
              <strong class="ml-1">XXXX-{{ item.cardnumber.slice(-4) }}</strong>
            </div>
          </template>
          <template #cell(name)="{ item }">
            <div class="text-warning">
              {{ item.name }}
            </div>
          </template>
          <template #cell(total)="{ item }">
            <div
              :style="
                item.total > 0
                  ? 'color: #0090e7; text-decoration: underline; text-underline-offset: 3px;'
                  : ''
              "
              :class="item.total > 0 ? 'cursor-pointer' : ''"
              @click="item.total > 0 && showDetails(item)"
            >
              {{ item.total | formatMoney }}
            </div>
          </template>
          <template #bottom-row>
            <b-th class="bg-color">
              <div class="d-flex justify-content-center">
                <b-badge
                  variant="info"
                  class="text-center"
                  style="
                    font-size: 14px;
                    font-weight: 600;
                    padding-bottom: 7px;
                    padding-top: 7px;
                    width: 50%;
                  "
                >
                  <div>TOTAL</div>
                </b-badge>
              </div>
            </b-th>
            <b-th class="bg-color">
              <div class="d-flex justify-content-center">
                <b-badge
                  variant="success"
                  class="text-center text-black"
                  style="
                    font-size: 14px;
                    font-weight: 600;
                    padding-bottom: 7px;
                    padding-top: 7px;
                    width: 50%;
                  "
                >
                  <div>{{ totalAmount2 | formatMoney }}</div>
                </b-badge>
              </div>
            </b-th>
          </template>
        </b-table>
      </div>
      <div v-else>
        <filter-slot
          :filter="[]"
          :filter-principal="filterPrincipal"
          :total-rows="totalRows"
          :paginate="paginate"
          :start-page="startPage"
          :to-page="toPage"
          @reload="$refs['importedTransactionsTable'].refresh()"
        >
          <b-table
            ref="importedTransactionsTable"
            slot="table"
            :items="getImportedTransactions"
            :fields="fields"
            responsive
            show-empty
            no-border-collapse
            class="position-relative table-new-customization"
            sticky-header="50vh"
            :tbody-tr-class="rowClass"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
          >
            <template #cell(cardholdername)="{ item }">
              <div class="row">
                <div class="col-4 p-0 justify-content-center d-flex">
                  <type-card :type_card="item.type_card" />
                </div>
                <div
                  class="col-8 p-0 d-flex justify-content-center align-items-center"
                >
                  <strong>XXXX-{{ item.cardnumber.slice(-4) }}</strong>
                </div>
              </div>
            </template>
            <template #cell(transaction_date)="{ item }">
              <span class="text-center">
                {{ item.transaction_date | myGlobal }}
              </span>
            </template>
            <template #cell(amount)="{ item }">
              <span class="text-center">
                {{ item.amount | formatMoney }}
              </span>
            </template>
            <template #cell(description)="{ item }">
              <span class="text-center">
                {{ item.description }}
              </span>
            </template>
            <template #cell(ticket)="{ item }">
              <tabler-icon
                v-if="!item.department_expense_id"
                :class="item.ticket_code ? 'text-success' : 'text-secondary'"
                v-b-tooltip.hover.top="
                  item.ticket_code ? 'Show Ticket' : 'Create Ticket'
                "
                icon="TicketIcon"
                class="cursor-pointer text-weight-bolder ml-1"
                style="margin-left: 5px"
                size="18"
                @click="
                  item.department_expense_id ? '' : selectCustomerTicket(item)
                "
              />
            </template>
            <template #bottom-row>
              <b-th
                class="bg-color"
                style="border-radius: 0px 0px 0px 15px"
              ></b-th>
              <b-th class="bg-color">
                <b-badge
                  variant="info"
                  class="w-100 text-center"
                  style="
                    font-size: 14px;
                    font-weight: 600;
                    padding-bottom: 7px;
                    padding-top: 7px;
                  "
                >
                  <div>SUBTOTAL</div>
                  <div>TOTAL</div>
                </b-badge>
              </b-th>
              <b-th class="bg-color">
                <b-badge
                  variant="success"
                  class="w-100 text-center text-black"
                  style="
                    font-size: 14px;
                    font-weight: 600;
                    padding-bottom: 7px;
                    padding-top: 7px;
                  "
                >
                  <div>{{ subTotalAmount | formatMoney }}</div>
                  <div>{{ totalAmount | formatMoney }}</div>
                </b-badge>
              </b-th>
              <b-th
                class="bg-color"
                colspan="2"
                style="border-radius: 0px 0px 15px 0px"
              ></b-th>
            </template>
          </b-table>
        </filter-slot>
      </div>
      <template #modal-footer>
        <b-button variant="info" @click="returnToPage()">
          Return back
        </b-button>
      </template>
    </b-modal>
    <ModalCreateCustomerTicket
      v-if="showModalCreateCustomerTicket"
      :customer-ticket="customerTicketSelected"
      :selected-expense-transaction="selectedExpenseTransaction"
      :set-motive="103"
      @hidden="showModalCreateCustomerTicket = false"
      @saved="refresh()"
    />
    <ModalViewTicketCustomerDetails
      v-if="showModalViewTicketCustomerDetails"
      :customer-ticket="customerTicketSelected"
      @hidden="showModalViewTicketCustomerDetails = false"
    />
  </div>
</template>
<script>
import DashboardService from "@/views/ceo/service-dashboard/dashboard.service";
import Fields from "@/views/ceo/dashboard/data/imported-transactions.fields.js";
import Fields2 from "@/views/ceo/dashboard/data/importeds-by-bank.fields.js";
import DetailDepartmentExpenses from "@/views/commons/expenses/components/Modal/DetailDepartmentExpenses.vue";
import TypeCard from "@/views/commons/card/TypeCard.vue";
import moment from "moment";
import { modifyVisibility } from "@/helpers/fields-table";
import ModalCreateCustomerTicket from "@/views/commons/components/customer-tickets/components/modal/ModalCreateCustomerTicket.vue";
import ModalViewTicketCustomerDetails from "@/views/commons/components/customer-tickets/components/modal/ModalViewTicketCustomerDetails.vue";
export default {
  name: "ExpensesByDepartment",
  components: {
    DetailDepartmentExpenses,
    TypeCard,
    ModalCreateCustomerTicket,
    ModalViewTicketCustomerDetails,
  },
  data() {
    return {
      selectedExpense: null,
      selectedExpenseTransaction: null,
      showModalViewTicketCustomerDetails: false,
      customerTicketSelected: {},
      showModalCreateCustomerTicket: false,
      showModal: false,
      importedTransactions: [],
      totalImportedsByBank: [],
      totalImportedsByCard: [],
      fields: Fields,
      fields2: Fields2,
      selectedCard: null,
      creditCards: [],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      dataYear: null,
      dataMonth: null,
      cpyMonth: null,
      cpyYear: null,
      useMonthFilter: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by description...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      startPage: null,
      toPage: null,
      totalRows: 0,
      page: 1,
      selectedItem: null,
      cardNumber: null,
      bankName: null,
      bankId: null,
    };
  },
  props: {
    sendYear: {
      type: Number,
      required: true,
    },
    sendMonth: {
      type: Number,
      required: true,
    },
    showOnly: {
      type: String,
      default: null,
    },
  },
  async created() {
    this.initMonthYear();
    await this.getTotalImportedsByBank();
    this.showModal = true;
  },
  computed: {
    pendingTransactions() {
      return this.importedTransactions.filter(
        (item) => item.department_expense_id === null
      );
    },
    subTotalAmount() {
      return this.importedTransactions.reduce(
        (acum, item) => acum + parseFloat(item.amount),
        0
      );
    },
    totalAmount() {
      return this.importedTransactions.length > 0
        ? this.importedTransactions[0].total_amount
        : 0;
    },
    totalAmount2() {
      if (this.page == 1) {
        return this.totalImportedsByBank.reduce(
          (acum, item) => acum + parseFloat(item.total),
          0
        );
      } else {
        return this.totalImportedsByCard.reduce(
          (acum, item) => acum + parseFloat(item.total),
          0
        );
      }
    },
    generateYears() {
      const years = [];
      const currentYear = new Date().getFullYear();
      for (let i = currentYear; i >= 2023; i--) {
        years.push(i);
      }
      return years;
    },
    disableNext() {
      const now = moment();
      const sameYear = now.year() === this.dataYear;
      const sameMonth = now.month() + 1 === this.dataMonth;
      return sameYear && sameMonth;
    },
    titleModal() {
      const title =
        this.showOnly == "unmatched"
          ? "Unmatched Transactions"
          : "Imported Transactions";
      if (this.page == 2) return `${title} | Bank: ${this.bankName}`;
      if (this.page == 3 && this.selectedItem != null)
        return `${title} | Card: ${this.cardNumber}`;
      return title;
    },
    visibleFields() {
      modifyVisibility(this.fields2, "cardholdername", this.page == 2);
      modifyVisibility(this.fields2, "name", this.page == 1);
      return this.fields2.filter((item) => item.visible);
    },
  },
  methods: {
    selectCustomerTicket(item) {
      if (item.ticket_code) {
        this.openModalViewTicketCustomerDetails(item);
      } else {
        this.openModalCreateCustomerTicket(item);
      }
    },
    async openModalViewTicketCustomerDetails(item) {
      this.selectedExpenseTransaction = { ...item };
      const data = await DashboardService.getCustomerTicket({
        name_text: item.ticket_code,
        user_id: 1,
      });
      if (data.length > 0) {
        this.customerTicketSelected = data[0];
        this.showModalViewTicketCustomerDetails = true;
      } else {
        this.$bvToast.toast("Ticket not found", {
          title: "Error",
          variant: "danger",
        });
      }
    },
    async openModalCreateCustomerTicket(item) {
      this.selectedExpenseTransaction = { ...item };
      let date = "";
      let formatedAmount = "";
      if (item.transaction_date) {
        const parsedDate = new Date(item.transaction_date);
        const mo = parsedDate.getMonth() + 1;
        const da = parsedDate.getDate();
        const an = parsedDate.getFullYear();
        date = `${mo}/${da}/${an}`;
      }
      if (item.amount) {
        formatedAmount = new Intl.NumberFormat("en-US", {
          currency: "USD",
          style: "currency",
        }).format(item.amount);
      }
      this.customerTicketSelected = {
        text: `CARD: XXXX-${item.cardnumber.slice(-4)}\nDESCRIPTION: ${
          item.description
        }\nAMOUNT: ${formatedAmount}\nDATE: ${date}`,
      };
      this.showModalCreateCustomerTicket = true;
    },
    changeYear() {
      this.refresh();
    },
    returnToPage() {
      switch (this.page) {
        case 3:
          this.page = 2;
          break;
        case 2:
          this.page = 1;
          break;
      }
      this.refresh();
    },
    async showDetails(item) {
      this.selectedItem = item;
      switch (this.page) {
        case 1:
          this.page = 2;
          this.bankName = item.name;
          this.bankId = item.bank_id;
          await this.getCreditCards();
          await this.getTotalImportedsByCard();
          break;
        case 2:
          this.page = 3;
          this.cardNumber = `XXXX-${item.cardnumber.slice(-4)}`;
          await this.getImportedTransactions();
          break;
      }
    },
    async prev() {
      if (this.dataMonth == null) this.dataMonth = this.cpyMonth;
      if (this.dataMonth > 1) {
        this.dataMonth--;
      } else {
        this.dataMonth = 12;
        this.dataYear--;
      }
      await this.refresh();
    },
    async next() {
      if (this.dataMonth == null) this.dataMonth = this.cpyMonth;
      if (this.disableNext) return;
      if (this.dataMonth < 12) {
        this.dataMonth++;
      } else {
        this.dataMonth = 1;
        this.dataYear++;
      }
      await this.refresh();
    },
    initMonthYear() {
      this.dataMonth = this.sendMonth;
      this.dataYear = this.sendYear;
    },
    changeFilter() {
      if (this.useMonthFilter) {
        this.cpyYear = this.dataYear;
        this.cpyMonth = this.dataMonth;
        this.dataMonth = null;
      } else {
        this.dataMonth = this.cpyMonth;
        this.dataYear = this.cpyYear;
      }
      this.refresh();
    },
    async refresh() {
      switch (this.page) {
        case 1:
          this.getTotalImportedsByBank();
          break;
        case 2:
          this.getTotalImportedsByCard();
          break;
        case 3:
          this.$refs["importedTransactionsTable"].refresh();
          break;
      }
    },
    async getTotalImportedsByBank() {
      try {
        this.addPreloader();
        const params = {
          year: this.dataYear,
          month: this.dataMonth,
          show_only: this.showOnly,
        };
        const { data } = await DashboardService.getTotalImportedsByBank(params);
        this.totalImportedsByBank = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async getTotalImportedsByCard() {
      try {
        this.addPreloader();
        const params = {
          year: this.dataYear,
          month: this.dataMonth,
          show_only: this.showOnly,
          bank_id: this.bankId,
          // card_to_filter: this.selectedCard,
        };
        const { data } = await DashboardService.getTotalImportedsByCard(params);
        this.totalImportedsByCard = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async getImportedTransactions() {
      try {
        this.addPreloader();
        const params = {
          year: this.dataYear,
          month: this.dataMonth,
          card_id: this.selectedCard,
          per_page: this.paginate.perPage,
          page: this.paginate.currentPage,
          search: this.filterPrincipal.model,
          show_only: this.showOnly,
          credit_card_id: this.selectedItem.credit_card_expense_id,
        };
        const { data } = await DashboardService.getImportedTransactions(params);
        this.importedTransactions = data.data;
        this.paginate.from = data.from;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.totalRows = data.total;
        this.paginate.to = data.to;
        this.startPage = data.from;
        this.toPage = data.to;
        return this.importedTransactions;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async getCreditCards() {
      try {
        this.addPreloader();
        const { data } = await DashboardService.getCreditCards({
          card_bank_id: this.selectedItem.bank_id,
        });
        this.creditCards = data;
      } catch (error) {
        console.log(error);
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    refreshCardGrid() {
      this.getTotalImportedsByCard();
      this.$refs["bankCardTable"].refresh();
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.department_expense_id === null) return "table-danger";
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.dark-select {
  background-color: #333;
  color: #fff;
}
.dark .absolute-linker {
  background-color: #444;
}
.disabled-next {
  cursor: not-allowed;
  opacity: 0.5;
}
.shadow-red-left {
  background-color: green !important;
  box-shadow: -5px 0px 10px rgba(255, 0, 0, 0.5);
}
.bg-color {
  background-color: #3f7afa !important;
}
</style>
