<template>
  <b-modal
    v-model="showModal"
    header-bg-variant="transparent border-bottom border-bottom-2"
    header-class="p-0"
    modal-class="modal-primary"
    @hidden="$emit('hidden')"
    hide-footer
    size="xl"
  >
    <template #modal-header>
      <div class="header-modal">
        <div class="d-flex align-items-center">
          <img height="35px" width="35px" :src="geModuleImageRoute(moduleId)" />
          <span>Pending Unnoted Calls : {{ formattedDate }}</span>
        </div>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="$emit('hidden')"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>

    <UnnotedCallsGrid
      :tab="tab"
      :module-id="moduleId"
      :date="date"
      @updateMainGrid="$emit('updateMainGrid')"
    />
  </b-modal>
</template>

<script>
import UnnotedCallsGrid from "@/views/commons/components/ring-central/notes/UnnotedCallsGrid.vue";

export default {
  props: {
    moduleId: {
      type: [String, Number],
      required: true,
    },
    tab: {
      type: Number,
      required: true,
    },
    date: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      showModal: true,
    };
  },
  components: {
    UnnotedCallsGrid,
  },
  computed: {
    formattedDate() {
      //  02/08/2024
      return this.date ? this.$moment(this.date).format("MM/DD/YYYY") : "";
    },
  },
};
</script>
<style lang="scss" scoped>
.header-modal {
  position: relative;
  padding: 20px 0 20px 15px;
  background: rgba(22, 84, 204, 1);
  width: 100%;
  font-size: 22px;
  display: flex;
  justify-content: space-betweeen;

  span {
    font-size: 1.3rem;
    font-weight: bold;
    margin-left: 8px;
    color: #ffffff;
  }

  .container-icons {
    background-color: #ffffff;
    padding: 2.7px 6.37px;
    border-radius: 0.357rem;
    width: 31px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    transition: all 0.23s ease 0.1s;

    &--dark {
      background-color: #17171a;
    }

    &:hover {
      transform: translate(-7px, 5px);
      cursor: pointer;
    }
  }
}
</style>