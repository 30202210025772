export default [
    {
        key: 'bank',
        label: 'Bank',
        class: 'text-center',
        visible: true,
    },
    {
        key: 'module',
        label: 'Module',
        class: 'text-center',
        visible: true,
    },
    {
        key: 'cardholdername',
        label: 'Credit card',
        class: 'text-center',
        visible: true,
    },
    {
        key: 'total_clients',
        label: 'Clients',
        class: 'text-center',
        visible: true,
    },
    {
        key: 'total_departments',
        label: 'AMG',
        class: 'text-center',
        visible: true,
    },
    {
        key: 'total',
        label: 'Total',
        class: 'text-center',
        visible: true,
    },
]
