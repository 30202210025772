<template>
  <div
    class="dashboard__card"
    style="min-height: 370px"
    :class="isDarkSkin ? 'dashboard__card--dark' : ''"
  >
    <div class="card-charge">
      <div class="w-full">
        <div class="card-charge__header">
          <div class="charge-logo">
            <img :src="imgServices" alt="img_services" />
          </div>
          <h2 class="title" :class="isDarkSkin ? 'title--dark' : ''">
            Clients & Payments
          </h2>
        </div>
        <div class="mt-2">
          <b-table-simple bordered striped small responsive v-if="isLoaded">
            <b-thead head-variant="primary">
              <b-tr>
                <b-th class="text-center"></b-th>
                <b-th
                  class="text-center bg-primary text-white font-weight-bolder"
                  colspan="3"
                >
                  MONTHLY PAYMENTS
                </b-th>
                <b-th
                  class="text-center bg-danger text-white font-weight-bolder"
                  colspan="2"
                >
                  IN HOLD
                </b-th>
                <b-th
                  class="text-center bg-danger text-white font-weight-bolder"
                  colspan="2"
                >
                  WITHOUT PAYMENTS
                </b-th>
                <b-th
                  class="text-center bg-primary text-white font-weight-bolder"
                  colspan="8"
                >
                  AUTOMATIC PAYMENTS
                </b-th>
              </b-tr>
              <b-tr>
                <b-th></b-th>
                <b-th class="text-center">%</b-th>
                <b-th class="text-center">QUANTITY</b-th>
                <b-th class="text-center">$</b-th>
                <b-th class="text-center">QUANTITY</b-th>
                <b-th class="text-center">$</b-th>
                <b-th class="text-center">QUANTITY</b-th>
                <b-th class="text-center">$</b-th>
                <b-th class="text-center">5</b-th>
                <b-th class="text-center">10</b-th>
                <b-th class="text-center">15</b-th>
                <b-th class="text-center">20</b-th>
                <b-th class="text-center">25</b-th>
                <b-th class="text-center">30</b-th>
                <b-th class="text-center">SUCCESS</b-th>
                <b-th class="text-center">DECLINED</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(item, i) in dataMontlyPayments" :key="i">
                <b-td>
                  <div class="d-flex align-items-center" style="gap: 20px">
                    <img
                      :src="imgPrograms(item.program_id)"
                      alt="img_services"
                      width="35"
                    />
                    <!-- <div class="font-weight-bolder">
                      {{ item.program_name }}
                    </div> -->
                  </div>
                </b-td>
                <b-td class="text-center">{{ item.progress }}%</b-td>
                <b-td class="text-center">{{ item.c_paid_payments }}</b-td>
                <b-td
                  class="text-primary text-right cursor-pointer"
                  @click="ModalMonthlyPayment(item)"
                >
                  $ {{ item.paid_payments }}
                </b-td>
                <b-td class="text-center">
                  {{ missingMonthlyPayments[i].total_clients }}
                </b-td>
                <b-td
                  class="text-danger text-right cursor-pointer"
                  @click="ModalClientsInHold(item)"
                >
                  $ {{ missingMonthlyPayments[i].total_missing_payment }}
                </b-td>
                <b-td class="text-center">
                  {{ noMonthlyPayments.counters[i] }}
                </b-td>
                <b-td
                  class="text-danger text-right cursor-pointer"
                  @click="ModalClientsNoPayment(item)"
                >
                  $ {{ noMonthlyPayments.amounts[i].total_no_payments }}
                </b-td>
                <b-td class="text-center">
                  <b-spinner
                    v-if="autoPayments[i].state === 0"
                    small
                    variant="primary"
                  />
                  <template v-else>
                    <div v-if="autoPayments[i].pay_5">
                      <span
                        class="font-weight-bolder text-primary cursor-pointer"
                        @click="
                          autoPayments[i].pay_5 > 0 &&
                            ModalClientAutomatic(autoPayments[i], 5, 1)
                        "
                      >
                        {{ autoPayments[i].pay_5 }}
                      </span>
                      |
                      <span
                        class="font-weight-bolder text-danger cursor-pointer"
                        @click="
                          autoPayments[i].wo_5 > 0 &&
                            ModalClientAutomatic(autoPayments[i], 5, 0)
                        "
                      >
                        {{ autoPayments[i].wo_5 }}
                        <span
                          v-if="autoPayments[i].re_5 > 0"
                          @click.stop="
                            ModalClientAutomatic(autoPayments[i], 5, 0, true)
                          "
                          class="font-weight-bolder text-success"
                        >
                          ({{ autoPayments[i].re_5 }})
                        </span>
                      </span>
                    </div>
                    <span v-else> - </span>
                  </template>
                </b-td>
                <b-td class="text-center"
                  ><b-spinner
                    v-if="autoPayments[i].state === 0"
                    small
                    variant="primary"
                  />
                  <template v-else>
                    <div v-if="autoPayments[i].pay_10">
                      <span
                        class="font-weight-bolder text-primary cursor-pointer"
                        @click="
                          autoPayments[i].pay_10 > 0 &&
                            ModalClientAutomatic(autoPayments[i], 10, 1)
                        "
                      >
                        {{ autoPayments[i].pay_10 }}
                      </span>
                      |
                      <span
                        class="font-weight-bolder text-danger cursor-pointer"
                        @click="
                          autoPayments[i].wo_10 > 0 &&
                            ModalClientAutomatic(autoPayments[i], 10, 0)
                        "
                      >
                        {{ autoPayments[i].wo_10 }}
                        <span
                          v-if="autoPayments[i].re_10 > 0"
                          @click.stop="
                            ModalClientAutomatic(autoPayments[i], 10, 0, true)
                          "
                          class="font-weight-bolder text-success"
                        >
                          ({{ autoPayments[i].re_10 }})
                        </span>
                      </span>
                    </div>
                    <span v-else> - </span>
                  </template>
                </b-td>
                <b-td class="text-center">
                  <b-spinner
                    v-if="autoPayments[i].state === 0"
                    small
                    variant="primary"
                  />
                  <template v-else>
                    <div v-if="autoPayments[i].pay_15">
                      <span
                        class="font-weight-bolder text-primary cursor-pointer"
                        @click="
                          autoPayments[i].pay_15 > 0 &&
                            ModalClientAutomatic(autoPayments[i], 15, 1)
                        "
                      >
                        {{ autoPayments[i].pay_15 }}
                      </span>
                      |
                      <span
                        class="font-weight-bolder text-danger cursor-pointer"
                        @click="
                          autoPayments[i].wo_15 > 0 &&
                            ModalClientAutomatic(autoPayments[i], 15, 0)
                        "
                      >
                        {{ autoPayments[i].wo_15 }}
                        <span
                          v-if="autoPayments[i].re_15 > 0"
                          @click.stop="
                            ModalClientAutomatic(autoPayments[i], 15, 0, true)
                          "
                          class="font-weight-bolder text-success"
                        >
                          ({{ autoPayments[i].re_15 }})
                        </span>
                      </span>
                    </div>
                    <span v-else> - </span>
                  </template>
                </b-td>
                <b-td class="text-center">
                  <b-spinner
                    v-if="autoPayments[i].state === 0"
                    small
                    variant="primary"
                  />
                  <template v-else>
                    <div v-if="autoPayments[i].pay_20">
                      <span
                        class="font-weight-bolder text-primary cursor-pointer"
                        @click="
                          autoPayments[i].pay_20 > 0 &&
                            ModalClientAutomatic(autoPayments[i], 20, 1)
                        "
                      >
                        {{ autoPayments[i].pay_20 }}
                      </span>
                      |
                      <span
                        class="font-weight-bolder text-danger cursor-pointer"
                        @click="
                          autoPayments[i].wo_20 > 0 &&
                            ModalClientAutomatic(autoPayments[i], 20, 0)
                        "
                      >
                        {{ autoPayments[i].wo_20 }}
                        <span
                          v-if="autoPayments[i].re_20 > 0"
                          @click.stop="
                            ModalClientAutomatic(autoPayments[i], 20, 0, true)
                          "
                          class="font-weight-bolder text-success"
                        >
                          ({{ autoPayments[i].re_20 }})
                        </span>
                      </span>
                    </div>
                    <span v-else> - </span>
                  </template>
                </b-td>
                <b-td class="text-center">
                  <b-spinner
                    v-if="autoPayments[i].state === 0"
                    small
                    variant="primary"
                  />
                  <template v-else>
                    <div v-if="autoPayments[i].pay_25">
                      <span
                        class="font-weight-bolder text-primary cursor-pointer"
                        @click="
                          autoPayments[i].pay_25 > 0 &&
                            ModalClientAutomatic(autoPayments[i], 25, 1)
                        "
                      >
                        {{ autoPayments[i].pay_25 }}
                      </span>
                      |
                      <span
                        class="font-weight-bolder text-danger cursor-pointer"
                        @click="
                          autoPayments[i].wo_25 > 0 &&
                            ModalClientAutomatic(autoPayments[i], 25, 0)
                        "
                      >
                        {{ autoPayments[i].wo_25 }}
                        <span
                          v-if="autoPayments[i].re_25 > 0"
                          @click.stop="
                            ModalClientAutomatic(autoPayments[i], 25, 0, true)
                          "
                          class="font-weight-bolder text-success"
                        >
                          ({{ autoPayments[i].re_25 }})
                        </span>
                      </span>
                    </div>
                    <span v-else> - </span>
                  </template>
                </b-td>
                <b-td class="text-center">
                  <b-spinner
                    v-if="autoPayments[i].state === 0"
                    small
                    variant="primary"
                  />
                  <template v-else>
                    <div v-if="autoPayments[i].pay_30">
                      <span
                        class="font-weight-bolder text-primary cursor-pointer"
                        @click="
                          autoPayments[i].pay_30 > 0 &&
                            ModalClientAutomatic(autoPayments[i], 30, 1)
                        "
                      >
                        {{ autoPayments[i].pay_30 }}
                      </span>
                      |
                      <span
                        class="font-weight-bolder text-danger cursor-pointer"
                        @click="
                          autoPayments[i].wo_30 > 0 &&
                            ModalClientAutomatic(autoPayments[i], 30, 0)
                        "
                      >
                        {{ autoPayments[i].wo_30 }}
                        <span
                          v-if="autoPayments[i].re_30 > 0"
                          @click.stop="
                            ModalClientAutomatic(autoPayments[i], 30, 0, true)
                          "
                          class="font-weight-bolder text-success"
                        >
                          ({{ autoPayments[i].re_30 }})
                        </span>
                      </span>
                    </div>
                    <span v-else> - </span>
                  </template>
                </b-td>
                <b-td class="text-center">
                  <b-spinner
                    v-if="autoPayments[i].state === 0"
                    small
                    variant="primary"
                  />
                  <template v-else>
                    <div v-if="autoPayments[i].total_pay">
                      <span
                        class="font-weight-bolder text-primary cursor-pointer"
                        @click="
                          autoPayments[i].total_pay > 0 &&
                            ModalClientAutomatic(autoPayments[i], null, 1)
                        "
                      >
                        {{ autoPayments[i].total_pay }}
                      </span>
                    </div>
                    <span
                      v-else
                      class="font-weight-bolder text-primary cursor-pointer"
                    >
                      0
                    </span>
                  </template>
                </b-td>
                <b-td class="text-center">
                  <b-spinner
                    v-if="autoPayments[i].state === 0"
                    small
                    variant="primary"
                  />
                  <template v-else>
                    <div v-if="autoPayments[i].total_wo">
                      <span
                        class="font-weight-bolder text-danger cursor-pointer"
                        @click="
                          autoPayments[i].total_wo > 0 &&
                            ModalClientAutomatic(autoPayments[i], null, 0)
                        "
                      >
                        {{ autoPayments[i].total_wo }}
                        <span
                          v-if="autoPayments[i].total_re > 0"
                          @click.stop="
                            ModalClientAutomatic(autoPayments[i], null, 0, true)
                          "
                          class="font-weight-bolder text-success"
                        >
                          ({{ autoPayments[i].total_re }})
                        </span>
                      </span>
                    </div>
                    <span
                      v-else
                      class="font-weight-bolder text-danger cursor-pointer"
                    >
                      0
                    </span>
                  </template>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <div
            v-else
            class="d-flex justify-content-center align-items-center"
            style="min-height: 345px"
          >
            <b-spinner variant="primary" />
          </div>
        </div>
      </div>
      <detail-monthly-payment
        v-if="showModalMonthlyPayment"
        :send-year="dataYear"
        :send-month="dataMonth"
        :send-programs-id="idProgram"
        :send-programs="arrPrograms"
        @close="showModalMonthlyPayment = false"
      />
      <detail-clients-in-hold
        v-if="showModalClientsInHold"
        :send-year="dataYear"
        :send-month="dataMonth"
        :send-programs-id="idProgram"
        :send-programs="arrPrograms"
        @close="showModalClientsInHold = false"
      />
      <detail-clients-no-payment
        v-if="showModalClientsNoPayment"
        :send-year="dataYear"
        :send-month="dataMonth"
        :send-programs-id="idProgram"
        :send-programs="arrPrograms"
        @close="showModalClientsNoPayment = false"
      />
      <clients-auto-modal
        v-if="showModalClientsAuto"
        :send-year="dataYear"
        :send-month="dataMonth"
        :send-programs-id="idProgram"
        :send-programs="arrPrograms"
        :day-payment="dayPayment"
        :is-paid="isPaid"
        :is-recovered="isRecovered"
        @close="showModalClientsAuto = false"
      />
    </div>
  </div>
</template>
<script>
import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service";
import DetailMonthlyPayment from "@/views/ceo/dashboard/modals/monthly-payments/DetailMonthlyPayments.vue";
import DetailClientsInHold from "@/views/ceo/dashboard/modals/clients-in-hold/DetailClientsInHold.vue";
import DetailClientsNoPayment from "@/views/ceo/dashboard/modals/clients-no-payment/DetailClientsNoPayment.vue";
import ClientsAutomaticPayments from "@/views/ceo/dashboard/ClientsAutomaticPayments.vue";
import ClientsAutoModal from "@/views/ceo/dashboard/modals/ClientsAutoModal.vue";

export default {
  components: {
    DetailMonthlyPayment,
    DetailClientsInHold,
    DetailClientsNoPayment,
    ClientsAutomaticPayments,
    ClientsAutoModal,
  },
  props: {
    selectYear: {
      type: Number,
      default: null,
      required: false,
    },
    selectMonth: {
      type: Number,
      default: null,
      required: false,
    },
    listPrograms: {
      type: Array,
      required: false,
      default: [],
    },
  },
  data() {
    return {
      imgServices: require("@/assets/images/icons/dashboard-ceo/services.svg"),
      imgDebtSolution: require("@/assets/images/icons/dashboard-ceo/logo-debt-solution.svg"),
      imgBoostCredit: require("@/assets/images/icons/dashboard-ceo/logo-boost-credit.svg"),
      imgCreditExperts: require("@/assets/images/icons/dashboard-ceo/logo-credit-experts.svg"),
      imgBusiness: require("@/assets/images/icons/dashboard-ceo/logo-business.svg"),
      imgTaxResearch: require("@/assets/images/icons/dashboard-ceo/logo-tax-research.svg"),
      imgSpecialist: require("@/assets/images/icons/dashboard-ceo/logo-specialist.svg"),
      imgKeyBook: require("@/assets/images/icons/dashboard-ceo/logo-keybook.svg"),
      value: 75,
      objCharge: [
        {
          slug: "DS",
          program: "Debt Solution",
          value: 1258,
          percentage: 80,
        },
        {
          slug: "BC",
          program: "Boost Credit",
          value: 4523,
          percentage: 70,
        },
        {
          slug: "CE",
          program: "Credit Experts",
          value: 1125,
          percentage: 60,
        },
        {
          slug: "BU",
          program: "Business",
          value: 1252,
          percentage: 70,
        },
        {
          slug: "TR",
          program: "Tax Research",
          value: 1258,
          percentage: 80,
        },
        {
          slug: "SP",
          program: "Specialist",
          value: 1278,
          percentage: 80,
        },
      ],
      dataMontlyPayments: [
        {
          progress: 0,
          program_id: 1,
          program_name: "Business",
          paid_payments: 0,
          active_payments: 0,
          c_paid_payments: 0,
        },
        {
          progress: 0,
          program_id: 2,
          program_name: "Boost Credit",
          paid_payments: 0,
          active_payments: 0,
          c_paid_payments: 0,
        },
        {
          progress: 0,
          program_id: 3,
          program_name: "Credit Experts",
          paid_payments: 0,
          active_payments: 0,
          c_paid_payments: 0,
        },
        {
          progress: 0,
          program_id: 4,
          program_name: "Debt Solution",
          paid_payments: 0,
          active_payments: 0,
          c_paid_payments: 0,
        },
        {
          progress: 0,
          program_id: 5,
          program_name: "Tax Research",
          paid_payments: 0,
          active_payments: 0,
          c_paid_payments: 0,
        },
        {
          progress: 0,
          program_id: 7,
          program_name: "Specialist",
          paid_payments: 0,
          active_payments: 0,
          c_paid_payments: 0,
        },
        {
          progress: 0,
          program_id: 8,
          program_name: "KeyBook",
          paid_payments: 0,
          active_payments: 0,
          c_paid_payments: 0,
        },
      ],
      arrPrograms: [],
      missingMonthlyPayments: [],
      noMonthlyPayments: {
        amounts: [],
        counters: [],
      },
      autoPayments: [
        {
          program_id: 1,
          state: 0,
          pay_5: 0,
          wo_5: 0,
          pay_10: 0,
          wo_10: 0,
          pay_15: 0,
          wo_15: 0,
          pay_20: 0,
          wo_20: 0,
          pay_25: 0,
          wo_25: 0,
          pay_30: 0,
          wo_30: 0,
          total_pay: 0,
          total_wo: 0,
        },
        {
          program_id: 2,
          state: 0,
          pay_5: 0,
          wo_5: 0,
          pay_10: 0,
          wo_10: 0,
          pay_15: 0,
          wo_15: 0,
          pay_20: 0,
          wo_20: 0,
          pay_25: 0,
          wo_25: 0,
          pay_30: 0,
          wo_30: 0,
          total_pay: 0,
          total_wo: 0,
        },
        {
          program_id: 3,
          state: 0,
          pay_5: 0,
          wo_5: 0,
          pay_10: 0,
          wo_10: 0,
          pay_15: 0,
          wo_15: 0,
          pay_20: 0,
          wo_20: 0,
          pay_25: 0,
          wo_25: 0,
          pay_30: 0,
          wo_30: 0,
          total_pay: 0,
          total_wo: 0,
        },
        {
          program_id: 4,
          state: 0,
          pay_5: 0,
          wo_5: 0,
          pay_10: 0,
          wo_10: 0,
          pay_15: 0,
          wo_15: 0,
          pay_20: 0,
          wo_20: 0,
          pay_25: 0,
          wo_25: 0,
          pay_30: 0,
          wo_30: 0,
          total_pay: 0,
          total_wo: 0,
        },
        {
          program_id: 5,
          state: 0,
          pay_5: 0,
          wo_5: 0,
          pay_10: 0,
          wo_10: 0,
          pay_15: 0,
          wo_15: 0,
          pay_20: 0,
          wo_20: 0,
          pay_25: 0,
          wo_25: 0,
          pay_30: 0,
          wo_30: 0,
          total_pay: 0,
          total_wo: 0,
        },
        {
          program_id: 7,
          state: 0,
          pay_5: 0,
          wo_5: 0,
          pay_10: 0,
          wo_10: 0,
          pay_15: 0,
          wo_15: 0,
          pay_20: 0,
          wo_20: 0,
          pay_25: 0,
          wo_25: 0,
          pay_30: 0,
          wo_30: 0,
          total_pay: 0,
          total_wo: 0,
        },
        {
          program_id: 8,
          state: 0,
          pay_5: 0,
          wo_5: 0,
          pay_10: 0,
          wo_10: 0,
          pay_15: 0,
          wo_15: 0,
          pay_20: 0,
          wo_20: 0,
          pay_25: 0,
          wo_25: 0,
          pay_30: 0,
          wo_30: 0,
          total_pay: 0,
          total_wo: 0,
        },
      ],
      emptyAuto: [],
      idProgram: null,
      isPaid: null,
      isRecovered: null,
      dayPayment: null,
      showModalMonthlyPayment: false,
      showModalClientsInHold: false,
      showModalClientsNoPayment: false,
      showModalClientsAuto: false,
      isLoaded: false,
    };
  },

  watch: {
    async selectMonth(newVal) {
      this.dataMonth = newVal;
      this.isLoaded = false;
      await this.getMontlyPayments();
      await this.getHoldMissingPayments();
      await this.getMissingMonthlypayments();
      this.getAutomaticPayments();
      this.isLoaded = true;
    },
    async selectYear(newVal) {
      this.dataYear = newVal;
      this.isLoaded = false;
      await this.getMontlyPayments();
      await this.getHoldMissingPayments();
      await this.getMissingMonthlypayments();
      this.getAutomaticPayments();
      this.isLoaded = true;
    },
  },
  async created() {
    this.dataMonth = this.selectMonth;
    this.dataYear = this.selectYear;
    this.isLoaded = false;
    await this.getMontlyPayments();
    await this.getHoldMissingPayments();
    await this.getMissingMonthlypayments();
    this.getAutomaticPayments();
    this.isLoaded = true;
  },

  methods: {
    ModalMonthlyPayment(item) {
      this.arrPrograms = this.listPrograms;
      this.idProgram = item.program_id;
      this.showModalMonthlyPayment = true;
    },
    ModalClientsInHold(item) {
      this.arrPrograms = this.listPrograms;
      this.idProgram = item.program_id;
      this.showModalClientsInHold = true;
    },
    ModalClientsNoPayment(item) {
      this.arrPrograms = this.listPrograms;
      this.idProgram = item.program_id;
      this.showModalClientsNoPayment = true;
    },
    ModalClientAutomatic(item, day, isPaid, isRecovered = null) {
      this.arrPrograms = this.listPrograms;
      this.idProgram = item.program_id * 1;
      this.dayPayment = day;
      this.isPaid = isPaid;
      this.isRecovered = isRecovered;
      this.showModalClientsAuto = true;
    },

    async getAutomaticPayments() {
      try {
        const params = {
          month: this.dataMonth,
          year: this.dataYear,
        };
        this.autoPayments.map((item) => {
          item.state = 0;
        });
        let { data } = await DashboardServive.getClientAutomaticPayments(
          params
        );
        data = Object.entries(data).map(([key, value]) => ({
          program_id: key,
          state: 1,
          ...value,
        }));
        this.autoPayments = data;
      } catch (error) {
        console.error(error);
      }
    },
    async getMontlyPayments() {
      try {
        const params = {
          month: this.dataMonth,
          year: this.dataYear,
        };
        const { data } = await DashboardServive.getMonthlyPayments(params);
        if (data) {
          this.dataMontlyPayments = data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getHoldMissingPayments() {
      try {
        const params = {
          month: this.dataMonth,
          year: this.dataYear,
        };
        const { data } = await DashboardServive.getMissingPaymentsInHoldClients(
          params
        );
        this.missingMonthlyPayments = data;
      } catch (error) {
        console.error(error);
      }
    },
    async getMissingMonthlypayments() {
      try {
        const params = {
          month: this.dataMonth,
          year: this.dataYear,
        };
        const { data } = await DashboardServive.getMissingMonthlypayments(
          params
        );
        this.noMonthlyPayments.amounts = data.data;
        this.noMonthlyPayments.counters = data.counters;
      } catch (error) {
        console.log(error);
      }
    },
    imgPrograms(program_id) {
      switch (program_id) {
        case 1:
          return this.imgBusiness;
        case 2:
          return this.imgBoostCredit;
        case 3:
          return this.imgCreditExperts;
        case 4:
          return this.imgDebtSolution;
        case 5:
          return this.imgTaxResearch;
        case 7:
          return this.imgSpecialist;
        case 8:
          return this.imgKeyBook;
        default:
          return this.imgKeyBook;
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$color-secondary: #ffffff;
$bg-card-white: #ffffff;
$bg-card-dark: #111113;
$box-shadow-white: #00000026;

.dashboard__card {
  box-shadow: 0px 0px 15px 0px $box-shadow-white;
  border-radius: 15px;
  margin-bottom: 27px;
  padding: 18px;
  margin-top: 0px !important;
  background-color: $bg-card-white;

  &--dark {
    background-color: $bg-card-dark;
    box-shadow: 0px 0px 22.935px 0px rgba(255, 249, 249, 0.1);
  }

  .card-charge {
    &__header {
      display: flex;
      padding-left: 8px;

      .charge-logo {
        margin-right: 5px;
        width: 43px;
        height: 43px;
        border-radius: 10px;
        background-color: #a7c5ff;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 32px;
          height: 32px;
        }
      }

      .title {
        display: flex;
        margin: 0px;
        justify-content: center;
        align-items: center;
        color: #3d3d3d;
        font-family: Montserrat;
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 500;
        line-height: 122.25%;
        margin-left: 5px;

        &--dark {
          color: $color-secondary;
        }
      }
    }

    &__body {
      .charge {
        display: flex;
        margin: 10px auto;
        padding: 6px 8px;
        border-radius: 12px;

        &__programs {
          width: 47px;
          height: 40px;
          background-color: #e9e9e9;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 35px;
            height: 35px;
          }
        }

        &__details {
          width: 100%;
          margin: 0px 13px;

          &--program {
            margin: 0px;
            color: #5f5f5f;
            font-family: Montserrat;
            font-size: 1.1rem;
            font-style: normal;
            font-weight: 500;

            .value {
              font-weight: 700;
              margin-left: 3px;
              margin-right: 3px;

              &::before {
                content: "$";
              }
            }
          }

          .subtitle-dark {
            color: #b4b4b4;
          }
        }
      }

      .charge-data {
        margin: 10px auto;
        padding: 16px 8px;
        border-radius: 12px;

        &:hover {
          background-color: #eeeeee;
          cursor: pointer;
        }
      }

      .charge__dark:hover {
        background-color: #1b1b1b;
      }
    }
  }
}
</style>
