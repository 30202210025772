<template>
  <b-modal
    v-model="onControl"
    header-bg-variant="transparent border-bottom border-bottom-2"
    size="xmd"
    header-class="p-0"
    title="Sales Detail"
    modal-class="modal-primary"
    hide-footer
    scrollable
    @hidden="close()"
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <span>{{ "Call Details" }}</span>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="$emit('close')"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>

    <filter-slot
      :filter="[]"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refTablasCallsByLead'].refresh()"
    >
      <template #table>
        <b-table
          ref="refTablasCallsByLead"
          no-border-collapse
          class="position-relative"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="myProvider"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #empty>
            <div class="text-center text-primary my-2">
              <strong>No data on who has made the calls.</strong>
            </div>
          </template>
          <template #head(content_note)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(call_record)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(created_at)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(created_by)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>

          <template #cell(call_record)="data">
            <div class="text-center">
              <div
                style="
                  padding: 12px 10px;
                  margin-right: 10px;
                  display: inline-block;
                  border: 1px solid #e0e0e0;
                  border-radius: 5px;
                  background: #f5f5f5;
                "
              >
                <wave-surfer-player
                  style="height: 1.5rem"
                  :url="data.item.call_record"
                  :options="audioOptions"
                  :wave-surfer-id="`wave-surfer-${data.index}`"
                  :elapsed-time-width="95"
                  show-duration
                />
              </div>
            </div>
          </template>
          <template #cell(created_by)="data">
            <div class="align-middle text-center">
              <span :class="data.item.created_by ? '' : 'text-danger'">
                {{ data.item.created_by ? data.item.created_by : "Unknown" }}
              </span>
              <br />
              <span>
                {{ data.item.created_at | myGlobalWithHour }}
              </span>
            </div>
          </template>
          <template #cell(content_note)="data">
            <div
              class="align-middle text-center"
              v-html="data.item.content_note"
            ></div>
          </template>
        </b-table>
      </template>
    </filter-slot>
  </b-modal>
</template>

<script>
import DashboardService from "@/views/crm/views/dashboard/service/dashboard.service";
import WaveSurferPlayer from "@/views/management/views/approval-process/settlement-approval/components/WaveSurferPlayer.vue";

export default {
  components: {
    WaveSurferPlayer,
  },
  props: {
    leadId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      audioOptions: {
        waveColor: "#99989A",
        progressColor: "#0077E6",
        barWidth: 3,
        barRadius: 3,
        cursorWidth: 0,
        height: 20,
        barGap: 3,
        backend: "MediaElement",
        minPxPerSec: 5,
        normalizeToZero: true,
        waveOutlineWidth: 0,
        barMinHeight: 2,
      },
      onControl: false,
      startPage: 0,
      toPage: 0,
      isBusy: false,
      fields: [
        {
          key: "content_note",
          sortable: false,
          label: "Note",
          visible: true,
        },
        {
          key: "call_record",
          sortable: false,
          label: "Call Record",
          visible: true,
        },
        {
          key: "created_by",
          sortable: false,
          label: "Created by",
          visible: true,
          thStyle: { width: "200px" },
        },
      ],
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      totalRows: 1,
      paginate: {
        perPage: 50,
        currentPage: 1,
      },
    };
  },

  computed: {},
  watch: {},
  async created() {
    this.onControl = true;
    console.log(this.leadId);
  },
  methods: {
    close() {
      this.onControl = false;
      this.$emit("close");
    },
    async myProvider() {
      const params = {
        lead_id: this.leadId,
        type: "detailed_calls_by_lead",
        page: this.paginate.currentPage,
        perpage: this.paginate.perPage,
      };

      const { data } = await DashboardService.getDetailedCallsByLead(params);
      const items = data.data;
      this.startPage = data.from;
      this.paginate.currentPage = data.current_page;
      this.paginate.perPage = data.per_page;
      this.totalRows = data.total;
      this.toPage = data.to;
      return items || [];
    },
  },
};
</script>
    <style lang="scss" scoped>
.text-money {
  font-weight: 500;

  &::before {
    content: "$";
  }
}

.header-modal {
  position: relative;
  padding: 20px 0 20px 15px;
  background: rgba(22, 84, 204, 1);
  width: 100%;
  font-size: 22px;
  display: flex;
  justify-content: space-betweeen;

  span {
    font-size: 1.6rem;
    font-weight: bold;
    margin-left: 8px;
    color: #ffffff;
  }

  .container-icons {
    background-color: #ffffff;
    padding: 2.7px 6.37px;
    border-radius: 0.357rem;
    width: 31px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    transition: all 0.23s ease 0.1s;

    &--dark {
      background-color: #17171a;
    }

    &:hover {
      transform: translate(-7px, 5px);
      cursor: pointer;
    }
  }
}

.charge__programs {
  width: 47px;
  height: 40px;
  background-color: #e9e9e9;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 35px;
    height: 35px;
  }
}
</style>
  <style>
.b-table-bottom-row {
  position: sticky;
  bottom: 0px;
  z-index: 1;
}
</style>
