<template>
  <div>
    <b-modal
      v-model="modalUp"
      title="Voucher"
      @hidden="hideModal(false)"
      size="md"
      title-class="h3 text-white"
      :header-bg-variant="isDarkSkin ? 'primary' : 'primary'"
      centered
      modal-class="modal-primary"
      footer-class="d-flex flex-row justify-content-around"
    >
      <div
        id="voucher"
        :class="isDarkSkin ? 'line_card_dark' : 'line_card'"
        class="pb-1"
      >
        <b-row>
          <b-col class="text-center">
            <amg-icon
              icon="AmgIcon"
              size="100"
              class="my-1"
              :class="isDarkSkin ? 'text-light' : 'text-dark'"
            />
            <h1>Voucher</h1>
            <h3>{{ dataClient.voucher_id }}</h3>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col cols="6">
            <b-form-group>
              <b-input-group>
                <b-input-group-prepend class="w-30">
                  <b-input-group-text
                    class="w-100 bg-primary text-white font-strong"
                  >
                    <span>Client</span>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input
                  :value="dataClient.client_name || dataClient.lead_name"
                  class="border-1"
                  style="opacity: 1 !important"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group>
              <b-input-group>
                <b-input-group-prepend class="w-30">
                  <b-input-group-text
                    class="w-100 bg-primary text-white font-strong"
                  >
                    <span>Program</span>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input
                  :value="dataClient.program"
                  class="border-1"
                  style="opacity: 1 !important"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group>
              <b-input-group>
                <b-input-group-prepend class="w-30">
                  <b-input-group-text
                    class="w-100 bg-primary text-white font-strong"
                  >
                    <span>Amount</span>
                  </b-input-group-text>
                </b-input-group-prepend>
                <money
                  v-model="dataClient.amount"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    prefix: '$ ',
                    precision: 2,
                    masked: false,
                  }"
                  disabled
                  class="form-control pl-2"
                  style="opacity: 1 !important"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group>
              <b-input-group>
                <b-input-group-prepend class="w-30">
                  <b-input-group-text
                    class="w-100 bg-primary text-white font-strong"
                  >
                    <span>Concept</span>
                  </b-input-group-text>
                </b-input-group-prepend>

                <b-form-input
                  :value="
                    dataClient.modality === 'Monthly'
                      ? 'Monthly Payment'
                      : dataClient.modality
                  "
                  class="border-1"
                  style="opacity: 1 !important"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group>
              <b-input-group>
                <b-input-group-prepend class="w-30">
                  <b-input-group-text
                    class="w-100 bg-primary text-white font-strong"
                  >
                    <span>Method</span>
                  </b-input-group-text>
                </b-input-group-prepend>

                <b-form-input
                  :value="dataClient.method"
                  class="border-1"
                  style="opacity: 1 !important"
                  disabled
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <template #modal-footer>
        <b-button @click="sendToManagement" size="xl" variant="success">
          <FeatherIcon icon="SendIcon" class="mr-1" size="20" /> SEND TO
          MANAGEMENT
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
//vuex
import { mapActions, mapGetters } from "vuex";
//services
import OtherPaymentsService from "@/views/commons/components/other-payments/service/other-payment.service.js";

export default {
  props: {
    dataClient: {
      type: Object,
    },
  },
  data() {
    return {
      modalUp: true,
    };
  },
  methods: {
    ...mapActions({
      A_COUNTER_OP: "other-payment-store/A_COUNTER_OP",
    }),
    hideModal(status) {
      this.modalUp = false;
      this.$emit("hide", status);
    },
    async sendToManagement() {
      const { isConfirmed } = await this.showConfirmSwal(
        "Are you sure you want to send this payment?",
        "Management department will be able to start rejecting or approving it"
      );
      if (!isConfirmed) return;
      this.addPreloader();
      try {
        await OtherPaymentsService.changeStatusOtherPayment({
          id: this.dataClient.id, //id of other_payment
          module_id: this.dataClient.moduleId,
          status: 2,
          user_id: this.currentUser.user_id,
        });
        await this.A_COUNTER_OP({ module_id: this.moduleId });
        this.$emit("refreshTable");
        this.hideModal(false);
        this.showSuccessSwal();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async printVoucher() {
      await this.$htmlToPaper("voucher");
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
};
</script>
<style lang="scss" scoped>
.w-35 {
  width: 35% !important;
}
.w-30 {
  width: 30% !important;
}
.line_card {
  border: solid 1px #d8d6de;
  padding: 0 15px 0 15px;
  margin: 10px 0 10px 0;
  border-radius: 5px;
}
.line_card_dark {
  border: solid 1px rgba(255, 255, 255, 0.4);
  padding: 0 15px 0 15px;
  margin: 10px 0 10px 0;
  border-radius: 5px;
}
</style>
