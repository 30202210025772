<template>
  <b-modal
    v-model="isActive"
    modal-class="modal-primary"
    scrollable
    hide-footer
    centered
    size="xmd"
    @hidden="handleHidden"
  >
    <template #modal-title>
      <span class="title-bold">OFFER RESUME</span>
    </template>
    <b-overlay variant="transparent" :show="loading">
      <b-container fluid>
        <b-row class="my-1">
          <b-col cols="12">
            <!-- Client Account -->
            <div class="mb-2">
              <p class="text-bold text-gray-500 title-bold">
                {{ info.client }} ({{ info.client_account }})
              </p>
            </div>

            <ul class="px-0 ml-2">
              <!-- Original Creditor -->
              <li class="list-item-text text-gray-500 fs-1">
                <span>ORIGINAL CREDITOR:</span>
                <h4 class="d-inline fs-1">
                  {{ info.creditor }}
                  <span class="text-warning fs-1"
                    >#{{ info.creditor_account }}</span
                  >
                </h4>
              </li>
              <!-- Current Creditor -->
              <li
                class="list-item-text text-gray-500 fs-1"
                v-if="info.creditor != info.collection"
              >
                <span>CURRENT CREDITOR:</span>
                <h4 class="d-inline fs-1">
                  {{ info.collection }}
                  <span class="text-warning fs-1" v-if="info.collection_account"
                    >#{{ info.collection_account }}</span
                  >
                </h4>
              </li>
            </ul>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col :cols="cols">
            <!-- Negotiator -->
            <b-input-group>
              <template #prepend v-if="isPhone">
                <b-input-group-text class="prepend__width_number"
                  >01.</b-input-group-text
                >
              </template>
              <b-input-group-text class="prepend__width_text"
                >NEGOTIATOR</b-input-group-text
              >
              <b-form-input
                style="border-radius: 0px 5px 0px 0px !important"
                :value="info.negotiator"
                class="prepend__width_value"
              />
            </b-input-group>

            <!-- Debt Amount -->
            <b-input-group>
              <template #prepend v-if="isPhone">
                <b-input-group-text class="prepend__width_number"
                  >02.</b-input-group-text
                >
              </template>
              <b-input-group-text class="prepend__width_text"
                >DEBT AMOUNT</b-input-group-text
              >
              <b-form-input
                :value="
                  '$ ' +
                  (info.balance
                    ? $options.filters.currency(info.balance)
                    : '0.00')
                "
                class="prepend__width_value"
              />
            </b-input-group>

            <!-- Offer Made -->
            <b-input-group>
              <template #prepend v-if="isPhone">
                <b-input-group-text class="prepend__width_number"
                  >03.</b-input-group-text
                >
              </template>
              <b-input-group-text class="prepend__width_text"
                >OFFER MADE</b-input-group-text
              >
              <b-form-input
                :value="`$ ${
                  info.offer_amount
                    ? $options.filters.currency(info.offer_amount)
                    : '0.00'
                } (${amountPercentage.toFixed(2)}%)`"
                class="prepend__width_value"
              />
            </b-input-group>

            <!-- Savings -->
            <b-input-group>
              <template #prepend v-if="isPhone">
                <b-input-group-text class="prepend__width_number"
                  >04.</b-input-group-text
                >
              </template>
              <b-input-group-text class="prepend__width_text"
                >SAVINGS</b-input-group-text
              >
              <b-form-input
                :value="
                  '$ ' +
                  (info.saving
                    ? $options.filters.currency(info.saving)
                    : '0.00')
                "
                class="prepend__width_value"
              />
            </b-input-group>

            <!-- AMG PROFIT -->
            <b-input-group>
              <template #prepend v-if="isPhone">
                <b-input-group-text class="prepend__width_number"
                  >05.</b-input-group-text
                >
              </template>
              <b-input-group-text class="prepend__width_text"
                >AMG PROFIT (25%)</b-input-group-text
              >
              <b-form-input
                :value="
                  '$ ' +
                  (info.profit
                    ? $options.filters.currency(info.profit)
                    : '0.00')
                "
                class="prepend__width_value"
              />
            </b-input-group>

            <!-- SETTLEMENT COST -->
            <b-input-group>
              <template #prepend v-if="isPhone">
                <b-input-group-text class="prepend__width_number"
                  >06.</b-input-group-text
                >
              </template>
              <b-input-group-text class="prepend__width_text"
                >SETTLEMENT COST</b-input-group-text
              >
              <b-form-input
                :value="
                  '$ ' +
                  (info.settlement_cost
                    ? $options.filters.currency(info.settlement_cost)
                    : '0.00')
                "
                class="prepend__width_value"
              />
            </b-input-group>

            <!-- Total cost -->
            <b-input-group>
              <template #prepend v-if="isPhone">
                <b-input-group-text
                  class="prepend__width_number_h"
                ></b-input-group-text>
              </template>
              <b-input-group-text class="prepend__width_text_h"
                >TOTAL COST</b-input-group-text
              >
              <b-form-input
                :value="
                  '$ ' +
                  (totalCost
                    ? $options.filters.currency(totalCost.toFixed(2))
                    : '0.00')
                "
                class="prepend__width_value_h"
              />
            </b-input-group>
          </b-col>

          <b-col :cols="cols" :class="[cols == 12 ? 'mt-3' : '']">
            <!-- Available Balance -->
            <b-input-group>
              <template #prepend v-if="isPhone">
                <b-input-group-text class="prepend__width_number"
                  >07.</b-input-group-text
                >
              </template>
              <b-input-group-text class="prepend__width_text"
                >AVAILABLE BALANCE</b-input-group-text
              >
              <b-form-input
                style="border-radius: 0px 5px 0px 0px !important"
                :value="
                  '$ ' +
                  (info.av_balance
                    ? $options.filters.currency(info.av_balance)
                    : '0.00')
                "
                class="prepend__width_value"
              />
            </b-input-group>

            <!-- Type of Payment -->
            <b-input-group>
              <template #prepend v-if="isPhone">
                <b-input-group-text class="prepend__width_number"
                  >08.</b-input-group-text
                >
              </template>
              <b-input-group-text class="prepend__width_text"
                >TYPE OF PAYMENT</b-input-group-text
              >
              <b-form-input
                :value="info.payment_type"
                class="prepend__width_value"
              />
            </b-input-group>

            <!-- Payment Format -->
            <b-input-group>
              <template #prepend v-if="isPhone">
                <b-input-group-text class="prepend__width_number"
                  >09.</b-input-group-text
                >
              </template>
              <b-input-group-text class="prepend__width_text"
                >PAYMENT FORMAT</b-input-group-text
              >
              <b-form-input
                :value="info.payment_format"
                class="prepend__width_value"
              />
            </b-input-group>

            <!-- Payment Method -->
            <b-input-group>
              <template #prepend v-if="isPhone">
                <b-input-group-text class="prepend__width_number"
                  >10.</b-input-group-text
                >
              </template>
              <b-input-group-text class="prepend__width_text"
                >PAYMENT METHOD</b-input-group-text
              >
              <b-form-input
                :value="qFractionsPayments"
                class="prepend__width_value"
              />
            </b-input-group>

            <!-- Audio File -->
            <b-input-group v-if="isCeo">
              <template #prepend v-if="isPhone">
                <b-input-group-text class="prepend__width_number"
                  >11.</b-input-group-text
                >
              </template>
              <b-input-group-text class="prepend__width_text"
                >AUDIO FILE</b-input-group-text
              >

              <b-input-group-text
                class="prepend__width_value form-control"
                style="pointer-events: all; text-decoration: none"
              >
                <wave-surfer-player
                  style="width: 104%"
                  v-if="showAudio"
                  :url="audioUrl"
                  :waveSurferId="`waveSurfer-${info.id}`"
                  :options="audioOptions"
                ></wave-surfer-player>
              </b-input-group-text>
            </b-input-group>

            <!-- Offer Document -->
            <b-input-group>
              <template #prepend v-if="isPhone">
                <b-input-group-text class="prepend__width_number"
                  >12.</b-input-group-text
                >
              </template>
              <b-input-group-text class="prepend__width_text"
                >OFFER DOCUMENT</b-input-group-text
              >
              <div
                class="radio__group prepend__width_value form-control"
                style="
                  pointer-events: all;
                  text-decoration: none;
                  border-top-right-radius: 0px;
                  border-bottom-right-radius: 0px;
                "
                :class="[isDarkSkin ? '' : 'border-dark']"
              >
                <a
                  target="_blank"
                  class="text-right d-flex"
                  :href="documentUrl"
                  v-if="documentUrl != 'there is no file with that name'"
                  >View File</a
                >
                <span class="text-right d-flex" v-else>No File</span>
              </div>
            </b-input-group>

            <!-- Payment Date -->
            <b-input-group v-if="!createsMomentIsAfterDeployDate">
              <template #prepend v-if="isPhone">
                <b-input-group-text class="prepend__width_number"
                  >13.</b-input-group-text
                >
              </template>
              <b-input-group-text class="prepend__width_text"
                >PAYMENT DATE</b-input-group-text
              >
              <b-form-input
                :value="formatedDate"
                class="prepend__width_value"
              />
            </b-input-group>
          </b-col>
        </b-row>
      </b-container>
      <!-- Buttons -->
      <div
        v-if="info.status !== 'APPROVED' && info.status !== 'REJECTED'"
        class="mt-5 d-flex flex-column align-items-center gap-5"
      >
        Comment by Chief:
        <div v-if="info.last_comment" class="data-last-comment mb-2">
          <div>
            {{ offerInfo.last_comment.comment }}
          </div>
          <div class="text-right text-muted mt-2">
            {{ offerInfo.last_comment.user_name }} |
            {{ offerInfo.last_comment.created_at | myGlobalWithHour }}
          </div>
        </div>
        <div class="d-flex justify-content-center pb-2" style="gap: 1rem">
          <b-button
            variant="outline-success"
            @click="continueReview(true)"
            title="Approve the offer"
            >Approve</b-button
          >
          <b-button
            title="Reject the offer"
            variant="outline-danger"
            @click="continueReview()"
          >
            Reject
          </b-button>
        </div>
      </div>
    </b-overlay>
    <!-- Modal  -->

    <b-modal
      v-model="continueInfo.modal"
      centered
      :title="messageModalTitle"
      :ok-title="messageModalButton"
      @hidden="clearMessage()"
      @ok="reviewOffer"
    >
      <ValidationObserver ref="form">
        <template v-if="!isApproved">
          <ValidationProvider v-slot="{ errors }" rules="required">
            <b-form-group label="Reason">
              <b-form-select
                v-model="continueInfo.selected"
                :state="errors[0] ? false : null"
                :options="continueInfo.options"
              />
            </b-form-group>
          </ValidationProvider>
          <template v-if="continueInfo.selected">
            <ValidationProvider v-slot="{ errors }" rules="required">
              <b-form-group label="Message">
                <b-form-textarea
                  v-model="continueInfo.message"
                  placeholder="Message..."
                  :state="errors[0] ? false : null"
                />
              </b-form-group>
            </ValidationProvider>
          </template>
          <template v-if="!isCeo">
            <b-form-group label="Recontact Date">
              <b-form-datepicker
                v-model="recontact_date"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              />
            </b-form-group>
          </template>
        </template>
        <b-form-group v-else label="Message">
          <b-form-textarea
            v-model="continueInfo.message"
            placeholder="Message..."
          />
        </b-form-group>
      </ValidationObserver>
    </b-modal>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import SettingsService from "@/views/commons/components/settings/services/Settings.service";
import SettlementApprovalService from "@/views/management/views/approval-process/settlement-approval/settlement-approval.service";
import WaveSurferPlayer from "./WaveSurferPlayer.vue";

export default {
  name: "ResumedOfferModal",
  props: {
    show: Boolean,
    info: Object,
  },
  components: {
    WaveSurferPlayer,
  },
  data() {
    return {
      audioOptions: {},
      showAudio: false,
      interval: null,
      seconds: 0,
      isActive: false,
      isApproved: null,
      loading: false,
      message: "",
      documentUrl: null,
      audioUrl: null,
      continueInfo: {
        modal: false,
        mode: "",
        options: [
          { value: null, text: "Please select an option", disabled: true },
          "Incorret Information",
          "Negotiation too hight",
          "Missing Information",
          "Poorly Done Negotiation",
          "Others",
        ],
        selected: null,
        message: "",
      },
      qPayments: "",
      qPaymentsArray: [],
      recontact_date: null,
      reason_rejected: null,
      commentReasonRejected: "",
      cols: 6,
      linkHasExpired: false,
    };
  },
  computed: {
    offerInfo() {
      let item = this.info;
      item.last_comment = JSON.parse(item.last_comment);
      let comment = item.last_comment.comment.split(":");
      if (comment.length > 1) {
        item.last_comment.comment = comment[1];
      }
      return item;
    },
    isPhone() {
      return !(this.screenWidth <= 600 && this.screenWidth > 0);
    },
    totalCost() {
      return (
        Number(this.info.offer_amount) +
        Number(this.info.profit) +
        Number(this.info.settlement_cost)
      );
    },
    amountPercentage() {
      return (Number(this.info.offer_amount) / Number(this.info.balance)) * 100;
    },
    messageModalTitle() {
      if (this.isApproved) {
        return "Approve Offer";
      }
      return "Reject Offer";
    },
    messageModalButton() {
      if (this.isApproved) {
        return "Approve";
      }
      return "Reject";
    },
    formatedDate() {
      return moment(this.info.payment_date).format("MM/DD/YYYY");
    },
    deployDate() {
      return this.$moment("2023-01-14");
    },
    createsMoment() {
      return this.$moment(this.info.offer_created_at);
    },
    createsMomentIsAfterDeployDate() {
      return this.createsMoment.isAfter(this.deployDate);
    },
    qFractionsPayments() {
      const data =
        this.qPayments > 1 ? " (" + this.qPayments + " payments)" : " ";
      return this.info.payment_method + data;
    },
    ...mapGetters({ currentUser: "auth/currentUser" }),
    isCeo() {
      return this.currentUser.role_id === 1;
    },
  },
  watch: {
    screenWidth() {
      if (this.screenWidth >= 1300) {
        this.cols = 6;
      } else {
        this.cols = 12;
      }
    },
    async show(val) {
      if (val) {
        this.isActive = true;
        await this.getFileUrl();
        await this.getPaymentFractions();
        this.interval = setInterval(() => {
          this.seconds++;
          if (this.seconds > 7200) {
            this.linkHasExpired = true;
            clearInterval(this.interval);
          }
        }, 7200000);
      } else {
        clearInterval(this.interval);
      }
    },
  },
  methods: {
    clearMessage() {
      this.continueInfo.message = "";
      this.continueInfo.selected = null;
    },
    handleHidden() {
      this.continueInfo.modal = false;
      this.loading = false;
      this.isActive = false;
      this.$emit("closing");
    },
    continueReview(approved = false) {
      this.isApproved = approved;
      this.continueInfo.modal = true;
    },
    async reviewOffer(evt) {
      try {
        this.addPreloader();

        evt.preventDefault();

        const validate = await this.$refs.form.validate();

        if (!validate) {
          return;
        }
        const { selected, message } = this.continueInfo;

        this.commentReasonRejected = `${selected ?? "APPROVED"}: ${message}`;

        this.reason_rejected = message;

        let params = {
          offer_id: this.info.id,
          user_id: this.currentUser.user_id,
          creditor_id: this.info.creditor_id,
          client_account: this.info.client_account_id,
          settlement_cost: this.info.settlement_cost,
          is_accepted: this.isApproved,
          comment: this.commentReasonRejected,
          recontact_date: this.recontact_date,
          reason_rejected: this.commentReasonRejected,
        };

        const data = await amgApi.post("/debt-solution/update-offer", params);
        console.log(data)
        // if (res.status != 200) {
        //   this.handleHidden();
        //   return;
        // }
        const offerType = this.isApproved ? 1 : 2;
        if (!this.info.motive_id) {
          await this.sendOffersMessage(offerType);
        }

        this.$swal.fire({
          icon: "success",
          title: "Offer Reviewed",
        });
        this.removePreloader();
        this.handleHidden();
        this.$emit("reload");
      } catch (error) {
        this.showErrorSwal(error);
        console.log(error);
        throw error;
      } finally {
        this.removePreloader();
      }
    },
    async getFileUrl() {
      try {
        this.loading = true;
        this.showAudio = false;
        const { data, status } = await amgApi.post(
          "/debt-solution/get-offer-documents",
          {
            offer_id: this.info.id,
          }
        );
        if (status === 200) {
          this.documentUrl = data.document;
          this.audioUrl = data.audio;
          this.linkHasExpired = false;
          const audio = new Audio();
          audio.controls = true;
          audio.style.width = "100%";

          this.audioOptions = {
            waveColor: "#99989A",
            progressColor: "#0077E6",
            barWidth: 3,
            barRadius: 3,
            cursorWidth: 0,
            height: 20,
            barGap: 3,
            width: 180,
            backend: "MediaElement",
            minPxPerSec: 5,
            normalizeToZero: true,
            waveOutlineWidth: 0,
            barMinHeight: 2,
          };
        }
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.loading = false;
        this.showAudio = true;
      }
    },
    async sendOffersMessage(type) {
      try {
        const params = {
          module_id: 5, //DebtSolution
          creditor_account: this.info.creditor_account,
          offer_id: this.info.id,
          type_offer: type, // 1: APPROVED 2: REJECT
          creditor_id: this.info.ds_credit_id,
        };

        const response = await SettingsService.sendOffersMessage(params);
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    async getPaymentFractions() {
      try {
        const response = await SettlementApprovalService.getPaymentFractions({
          offerId: this.info.id,
        });
        if (response.status === 200) {
          this.qPayments = response.data.qFractions;
          this.qPaymentsArray = response.data.paymentFractions;
        }
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600;700&display=swap");

.border-dark {
  border: 1px solid #d8d6de !important;
}
.highlighted__field {
  border: 1px solid #00d25b;
  border-radius: 2px;
}

.prepend__width_number {
  justify-content: center;
  width: 3rem;
  border-radius: 0px 0px 0px 0px;
}
.prepend__width {
  justify-content: center;
  width: 12rem;
  border-radius: 0px 0px 0px 0px;
}
.prepend__width_text {
  justify-content: left;
  width: 16rem;
  border-radius: 0px 0px 0px 0px;
  border-right: none !important;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  color: #99989a !important;
}
.prepend__width_number_h {
  justify-content: center;
  width: 5rem;
  border-radius: 0px 0px 0px 0px;
  opacity: 0 !important;
}
.prepend__width_h {
  justify-content: center;
  width: 12rem;
  border-radius: 0px 0px 0px 0px;
}
.prepend__width_text_h {
  justify-content: left;
  width: 16rem;
  border-radius: 0px 0px 0px 0px;
  border-right: none !important;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  color: #17161a !important;
  border: 1px solid #00d25b !important;
  background-color: #68ff80 !important;
}

.prepend__width_value_h {
  color: #00d25b !important;
  border-radius: 0px 0px 0px 0px;
  border: 1px solid #00d25b !important;
  pointer-events: none;
  justify-content: right;
  font-family: "Inter", sans-serif;
  text-align: right;
}
.prepend__width_value {
  color: #99989a !important;
  border-radius: 0px 0px 0px 0px;
  pointer-events: none;
  justify-content: right;
  text-align: right;
}
.radio__group__audio {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 0 5px 5px 0;
  flex: 1;
}

.radio__group {
  display: flex;
  justify-content: right;
  align-items: right;
  gap: 2rem;
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 0 5px 5px 0;
  flex: 1;
}
.radio__group__audio {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 0 5px 5px 0;
  flex: 1;
}
.title-bold {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 1.4rem;
}
.list-item-text {
  font-size: 1.1rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.text-gray-500 {
  color: #99989a;
}

.fs-1 {
  font-size: 1rem;
}
.creditor-data-container {
  padding-left: 16%;
  padding-right: 16%;
}
.data-last-comment {
  border: 1px solid #414141;
  width: 80%;
  padding: 10px;
  border-radius: 5px;
}
</style>
