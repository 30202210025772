import { amgApi } from "@/service/axios";

class ComercialReportService {
  async getComercialReport(params) {
    try {
      const data = await amgApi.post(
        "/creative/comercial-report/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getSources(params) {
    try {
      const data = await amgApi.post(
        "/lead/social-network/settings/get-source-for-filters",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async showComercialReport(params, flyer_id) {
    try {
      const data = await amgApi.post(
        `/creative/comercial-report/show/${flyer_id}`,
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getLeadsWithMobileFilter(params) {
    try {
      const data = await amgApi.post(
        "/creative/comercial-report/leads/mobile/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getSalesByFlyer(params) {
    try {
      const data = await amgApi.post(
        "/creative/comercial-report/sales/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCommercialReportCeo(params) {
    try {
      const data = await amgApi.post(
        "/creative/comercial-report/ceo/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}

export default new ComercialReportService();
