<template>
  <b-modal
    v-model="showModal"
    title="Credit Cards"
    @hidden="closeModal()"
    hide-footer
    header-bg-variant="transparent border-bottom border-bottom-2"
    modal-class="custom-modal-amg"
    header-class="p-0"
    size="caro"
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <span>SETTINGS</span>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="closeModal()"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        v-for="(title, index) in options"
        :key="index"
        :active="index == selectedIndex"
        :link-classes="['px-3', bgTabsNavs]"
        @click="changeNav(index)"
        >{{ title }}</b-nav-item
      >
    </b-nav>

    <credit-cards v-if="selectedIndex === 0" />
    <checks
      v-else-if="selectedIndex === 1 || selectedIndex === 2"
      :type="typeCheck"
    />
  </b-modal>
</template>
<script>
import CreditCards from "@/views/commons/components/credit-cards-expenses/CreditCards.vue";
import Checks from "@/views/commons/components/check-expenses/Checks.vue";

export default {
  components: {
    CreditCards,
    Checks,
  },
  props: {
    indexSelected: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showModal: false,
      options: ["Credit Cards", "Checks", "E-Checks"],
      selectedIndex: this.indexSelected,
    };
  },
  computed: {
    typeCheck() {
      if (this.selectedIndex === 1) return "CHECK";
      else if (this.selectedIndex === 2) return "E-CHECK";
      return "";
    },
  },
  created() {
    this.showModal = true;
  },
  methods: {
    changeNav(index) {
      this.selectedIndex = index;
    },
    closeModal() {
      this.$emit("close");S
    },
  },
};
</script>
