<template>
  <div>
    <b-nav-item
      style="list-style: none"
      @click.stop="notificationModal = true"
      v-if="G_OFFER_NOTIFICATION_COUNTER > 0"
    >
      <b-badge pill variant="danger" class="menu-badge">
        <h5 class="text-white text-bold">
          <strong>{{
            G_OFFER_NOTIFICATION_COUNTER > 99
              ? "+99"
              : G_OFFER_NOTIFICATION_COUNTER
          }}</strong>
        </h5>
      </b-badge>
    </b-nav-item>

    <b-modal
      v-model="notificationModal"
      title-class="h3 text-white"
      size="lg"
      scrollable
      title="Settlements"
      hide-footer
      modal-class="modal-primary"
    >
      <offer-notification-list
        @closeNotifications="notificationModal = false"
      ></offer-notification-list>
    </b-modal>

    <OfferResumeModal
      :show="showResumeModal"
      :info="resumeModalInfo"
      @closing="closeResumeModal"
      @reload="updateNotifications"
    ></OfferResumeModal>

    <SettlementOfferModal
      v-if="showSettlementOfferModal"
      :offerInfo="offerInfo"
      @closing="closeSettlementOfferModal"
      @reload="updateNotifications"
    ></SettlementOfferModal>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import NotificationList from "../../../layouts/components/navbar/components/notifications/NotificationList.vue";
import NewClientStatusHoldModal from "../../../layouts/components/navbar/components/notifications/NewClientStatusHoldModal.vue";
import PriorityOfferService from "@/views/debt-solution/views/priority-offers/services/priorityOffersService.js";

import SettlementOfferModal from "@/views/debt-solution/views/settlement-offer/SettlementOfferModal.vue";
import OfferResumeModal from "@/views/management/views/approval-process/settlement-approval/components/ResumedOfferModal.vue";

import OfferNotificationList from "@/layouts/components/navbar/components/notifications/OfferNotificationList.vue";

export default {
  mounted() {
    if (this.currentUser) {
      this.A_GET_OFFER_NOTIFICATIONS({ role_id: this.currentUser.role_id });
    }
  },
  components: {
    VuePerfectScrollbar,
    NotificationList,
    NewClientStatusHoldModal,
    OfferResumeModal,
    SettlementOfferModal,
    OfferNotificationList,
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_OFFER_NOTIFICATION_COUNTER:
        "OfferNotificationStore/G_OFFER_NOTIFICATION_COUNTER",
    }),
    ...mapState({
      S_OFFER_NOTIFICATION: (state) =>
        state.OfferNotificationStore.S_OFFER_NOTIFICATION,
    }),
  },
  data() {
    return {
      showSettlementOfferModal: false,
      offerInfo: {},
      showResumeModal: false,
      resumeModalInfo: {},
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      notificationModal: false,
      accountsHoldModal: false,
      selectedNotification: {},
    };
  },
  // watch: {
  //   G_OFFER_NOTIFICATION_COUNTER: {
  //     handler: function (val) {
  //       if (val > 0) {
  //         setTimeout(() => {
  //           // this.$emit("play");
  //           this.$refs.notificationIcon.classList.add("wiggle");
  //         }, 1000);

  //         setTimeout(() => {
  //           this.$refs.notificationIcon.classList.remove("wiggle");
  //         }, 10000);
  //       }
  //     },
  //     deep: true,
  //   },
  // },

  methods: {
    closeSettlementOfferModal() {
      this.showSettlementOfferModal = false;
    },
    // show to supervisor
    async settlementOfferModal(notification) {
      const { data } = await PriorityOfferService.getOfferById(
        notification.link
      );
      this.offerInfo = {
        mode: "watch",
        client: data[0].client,
        creditorName: data[0].creditor,
        creditorAccount: data[0].creditor_account,
        creditorId: data[0].creditor_id,
        balance: data[0].balance,
        id: data[0].id,
        clientAccountId: data[0].client_account_id,
        creditor_id: data[0].ds_credit_id,
      };
      this.showSettlementOfferModal = true;
    },

    updateNotifications() {
      this.A_GET_OFFER_NOTIFICATIONS({ role_id: this.currentUser.role_id });
    },
    // show to ceo
    async resumeOfferModal(notification) {
      const { data } = await PriorityOfferService.getOfferById(
        notification.link
      );
      this.resumeModalInfo = { ...data[0] };
      this.showResumeModal = true;
    },
    ...mapActions({
      A_GET_OFFER_NOTIFICATIONS:
        "OfferNotificationStore/A_GET_OFFER_NOTIFICATIONS",
    }),
    ...mapMutations({
      DECREASE_NOTIFICATION_COUNTER:
        "OfferNotificationStore/DECREASE_NOTIFICATION_COUNTER",
    }),
    openModalAccountHold(notification) {
      this.accountsHoldModal = true;
      this.selectedNotification = notification;
    },
    closeResumeModal() {
      this.showResumeModal = false;
    },
  },
};
</script>

<style>
/* .wiggle {
  animation: wiggle 2s linear infinite;
}
@keyframes wiggle {
  0%,
  7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%,
  100% {
    transform: rotateZ(0);
  }
} */

.menu-badge {
  border-radius: 50%;
  min-width: 25px;
  max-height: 25px;
}
</style>
