<template>
  <b-modal
    ref="modalName"
    modal-class="modal-primary"
    title-class="h3 text-white"
    title="SEARCH SYNC"
    size="xmd"
    @hidden="closeModal()"
    hide-footer
    centered
    no-close-on-backdrop
  >
    <b-row class="client-info">
      <b-col cols="4" class="p-0">
        <span class="mb-1">Vendor</span>
        <div :class="isDarkSkin ? 'border-custom' : 'border-custom-light'">
          <p>{{ dataRow.vendor }}</p>
        </div>
      </b-col>
      <b-col cols="4" class="p-0">
        <span class="ml-1 mb-1">Concept</span>
        <div class="ml-1" :class="isDarkSkin ? 'border-custom' : 'border-custom-light'">
          <p>{{ dataRow.concept }}</p>
        </div>
      </b-col>
      <b-col cols="2" class="p-0">
        <span class="ml-1">Amount</span>
        <div
          class="ml-1 mb-1"
          :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
        >
          <p>{{ dataRow.amount | formatMoney }}</p>
        </div>
      </b-col>
      <b-col cols="2" class="p-0">
        <span class="ml-1 mb-1">Date</span>
        <div class="ml-1" :class="isDarkSkin ? 'border-custom' : 'border-custom-light'">
          <p>{{ dataRow.transaction_date | myGlobal }}</p>
        </div>
      </b-col>
    </b-row>
    <b-table
      ref="importTransactionsTable"
      id="importTransactionsTable"
      class="position-relative table-new-customization"
      :items="myProvider"
      :fields="fields"
      responsive="md"
      sticky-header="70vh"
      show-empty
    >
      <template #cell(pick)="{ item }">
        <b-form-group>
          <b-form-checkbox
            v-model="item.selected"
            @input="onSelectedRow(item)"
          />
        </b-form-group>
      </template>
      <template #cell(transaction_date)="{ item }">
        <span>
          {{ item.transaction_date | myGlobal }}
        </span>
      </template>
      <template #cell(amount)="{ item }">
        <b-badge
          variant="light-success"
          style="font-size: 15px"
          class="cursor-pointer"
          >{{ item.amount | formatMoney }}</b-badge
        >
      </template>
      <template #cell(select)="{ item }">
        <feather-icon
          icon="CheckIcon"
          size="18"
          class="cursor-pointer text-warning ml-1"
          v-b-tooltip.hover.top="'Select'"
          @click="selectSync(item)"
        />
      </template>
    </b-table>
  </b-modal>
</template>
<script>
import DashboardService from "@/views/ceo/service-dashboard/dashboard.service";

export default {
  props: {
    dataRow: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      fields: [
        {
          key: "transaction_date",
          label: "Date",
          thClass: "text-center col-2",
          tdClass: "text-center",
        },
        {
          key: "amount",
          label: "Amount",
          thClass: "text-center col-2",
          tdClass: "text-center",
        },
        {
          key: "description",
          label: "Description",
          thClass: "text-left",
        },
        {
          key: "select",
          label: "Sync",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
    };
  },
  mounted() {
    this.toggleModal("modalName");
  },
  methods: {
    async selectSync(item) {
      const confirm = await this.showConfirmSwal(
        `Are you sure you want to select this imported expense?`
      );
      if (!confirm.isConfirmed) return false;

      try {
        this.addPreloader();
        const {data} = await DashboardService.selectManualSyncImport({
          id: item.id,
          department_expense_id: this.dataRow.id,
        });
        if (data.success) {
          this.showToast(
            "success",
            "top-right",
            "Success",
            "CheckIcon",
            "Successfully process"
          );
          this.$emit("refresh");
          this.$emit("close");
        } else {
          this.showToast(
            "danger",
            "top-right",
            "Oop!",
            "AlertOctagonIcon",
            "Failed process"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async myProvider() {
      try {
        this.addPreloader();
        const params = {
          month: this.dataRow.pmonth,
          year: this.dataRow.pyear,
          credit_card_expense_id: this.dataRow.credit_card_expense_id,
          amount: this.dataRow.amount,
        };
        const data = await DashboardService.getUnmatchedTransactionsToSync(
          params
        );
        this.items = data;
        return this.items;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";
</style>