<template>
  <b-modal
    v-model="showUnnotedCallsModal"
    @hidden="$emit('hidden')"
    header-bg-variant="transparent border-bottom border-bottom-2"
    header-class="p-0"
    modal-class="modal-primary"
    hide-footer
    size="xl"
  >
    <template #modal-header>
      <div class="header-modal">
        <div class="d-flex align-items-center">
          <img height="35px" width="35px" :src="geModuleImageRoute(moduleId)" />
          <span>{{ modalTitle }}</span>
        </div>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="$emit('hidden')"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>

    <template #default>
      <div class="d-flex justify-content-between">
        <b-nav card-header pills class="m-0">
          <b-nav-item
            v-for="tab in tabs"
            :key="tab.id"
            exact
            :link-classes="[
              'sub-tab-nav ',
              tab.id === selectedTab ? 'router-link-active' : '',
              'px-3',
              bgTabsNavs,
            ]"
            :active="tab.id === selectedTab"
            @click="handleChangeTab(tab)"
          >
            <div class="d-flex align-items-center justify-content-center">
              <span>{{ tab.name }}</span>
            </div>

            <span class="ml-2" v-if="tab.counter > 0">
              <feather-icon
                icon
                :badge="tab.counter"
                :badge-classes="'badge-danger'"
              />
            </span>
          </b-nav-item>
        </b-nav>
      </div>

      <div v-if="selectedTab == 1" class="p-2">
        <div class="calendar-container">
          <div v-for="day in dayOfWeek" :key="day.id" class="calendar-header">
            {{ day.name }}
          </div>
        </div>

        <div class="calendar-items" v-if="unnotedCalls.length > 0">
          <div
            v-for="index in unnotedCalls[0].dayOfWeek - 1"
            :key="'placeholder-' + index"
            class="placeholder"
          ></div>

          <div
            v-for="call in unnotedCalls"
            :key="call.id"
            class="calendar-item"
            @click="openPendingUnnotedCalls(call.date)"
          >
            <span class="day"> {{ call.dayOfMonth }} </span>
            <span class="calls">
              <feather-icon
                :class="call.calls == 0 ? 'text-muted' : ''"
                icon="PhoneCallIcon"
                size="25"
                class="mr-1 unnote-call-date"
              />
              <span :class="call.calls == 0 ? 'text-muted' : ''">
                {{ call.calls }}
              </span>
            </span>
          </div>
        </div>
      </div>

      <div v-else>
        <UnnotedCallsGrid
          :tab="selectedTab"
          :module-id="moduleId"
          :year="year"
          :month="month"
          :date="date"
        />
      </div>

      <PendingUnnotedCalls
        v-if="showPendingUnnotedCalls"
        :module-id="moduleId"
        :tab="selectedTab"
        :date="date"
        @hidden="showPendingUnnotedCalls = false"
        @updateMainGrid="
          getUnnotedCalls(), getUnnotedCallCounters(), $emit('updateMainGrid')
        "
      />
    </template>
  </b-modal>
</template>

<script>
import CallNotesService from "@/views/commons/components/ring-central/notes/service/notes.service.js";
import UnnotedCallsGrid from "@/views/commons/components/ring-central/notes/UnnotedCallsGrid.vue";
import PendingUnnotedCalls from "@/views/commons/components/ring-central/notes/PendingUnnotedCalls.vue";

export default {
  props: {
    moduleId: {
      type: [String, Number],
      required: true,
    },
    year: {
      type: Number,
      default: null,
      required: false,
    },
    month: {
      type: Number,
      default: null,
      required: false,
    },
  },
  components: {
    UnnotedCallsGrid,
    PendingUnnotedCalls,
  },
  computed: {
    isPendingTab() {
      return this.selectedTab === 1;
    },
    modalTitle() {
      if (this.year != null && this.month != null) {
        let monthInText = new Date(this.year, this.month - 1, 1).toLocaleString(
          "default",
          { month: "long" }
        );
        return `UNNOTED CALLS: ${monthInText.toUpperCase()} - ${this.year}`;
      } else {
        let currentMonthInText = new Date().toLocaleString("default", {
          month: "long",
        });
        return `UNNOTED CALLS: ${currentMonthInText.toUpperCase()} - ${new Date().getFullYear()}`;
      }
    },
  },
  data() {
    return {
      unnotedCalls: [],
      date: "",
      showPendingUnnotedCalls: false,
      showUnnotedCallsModal: true,
      tabs: [
        {
          id: 1,
          name: "PENDING",
          counter: 0,
          key: "pending",
        },
        {
          id: 2,
          name: "DONE",
          counter: 0,
          key: "done",
        },
        // {
        //   id: 3,
        //   name: "NOT APPLICABLE",
        //   counter: 0,
        //   key: "not_applicable",
        // },
      ],
      selectedTab: 1,
      dayOfWeek: [
        {
          id: 1,
          name: "Sunday",
        },
        {
          id: 2,
          name: "Monday",
        },
        {
          id: 3,
          name: "Tuesday",
        },
        {
          id: 4,
          name: "Wednesday",
        },
        {
          id: 5,
          name: "Thursday",
        },
        {
          id: 6,
          name: "Friday",
        },
        {
          id: 7,
          name: "Saturday",
        },
      ],
    };
  },
  async mounted() {
    await this.getUnnotedCallCounters();

    if (this.selectedTab == this.isPendingTab) {
      await this.getUnnotedCalls();
    }
  },
  methods: {
    async getUnnotedCallCounters() {
      try {
        const { data } = await CallNotesService.getUnnotedCallCounters({
          module_id: this.moduleId,
          year: this.year ?? new Date().getFullYear(),
          month: this.month ?? new Date().getMonth() + 1,
        });

        if (data.length === 0) {
          return;
        }

        this.tabs = this.tabs.map((tab) => {
          tab.counter = data[0][tab.key];
          return tab;
        });
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async openPendingUnnotedCalls(date) {
      this.date = date;
      this.showPendingUnnotedCalls = true;
    },
    async getUnnotedCalls() {
      try {
        this.addPreloader();
        let params = {
          moduleId: this.moduleId,
          tab: this.selectedTab,
          year: this.year,
          month: this.month,
        };
        if (this.selectedTab === 1) {
          params = {
            ...params,
            get_only_counter: true,
          };
        }
        const { data } = await CallNotesService.getAll(params);
        this.unnotedCalls = data.data || [];
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    handleChangeTab(tab) {
      this.selectedTab = tab.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-audio {
  transform: scale(0.8) !important;
  border-width: 2px !important;
}
.header-modal {
  position: relative;
  padding: 20px 0 20px 15px;
  background: rgba(22, 84, 204, 1);
  width: 100%;
  font-size: 22px;
  display: flex;
  justify-content: space-betweeen;

  span {
    font-size: 1.3rem;
    font-weight: bold;
    margin-left: 8px;
    color: #ffffff;
  }

  .container-icons {
    background-color: #ffffff;
    padding: 2.7px 6.37px;
    border-radius: 0.357rem;
    width: 31px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    transition: all 0.23s ease 0.1s;

    &--dark {
      background-color: #17171a;
    }

    &:hover {
      transform: translate(-7px, 5px);
      cursor: pointer;
    }
  }
}
.my-05 {
  margin: 0.5rem;
}
.p-02 {
  padding: 0.2rem;
}
.fs-12 {
  font-size: 1.2rem;
}
.dark-layout {
  .unnote-call-date {
    color: #ffffff;
  }
}
.unnote-call-date {
  color: #000000;
}
.total-unnoted-calls {
  font-size: 1.5rem;
  font-weight: bold;
}
.calendar-container {
  display: flex;
  justify-content: flex-start;
}
.calendar-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  cursor: pointer;
}
.calendar-header {
  font-size: 1.5rem;
  font-weight: bold;
  flex-grow: 1;
  text-align: center;
  border: 1px solid #ccc;
  padding: 0.5rem;
  flex-basis: calc(100% / 7);
  max-width: calc(100% / 7);
}
.placeholder {
  flex-basis: calc(100% / 7);
  max-width: calc(100% / 7);
  box-sizing: border-box;
  border: 1px solid #ccc;
}
.calendar-item {
  height: 100px;
  flex-basis: calc(100% / 7);
  max-width: calc(100% / 7);
  text-align: center;
  border: 1px solid #ccc;
  box-sizing: border-box;
  padding: 0.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .day {
    font-size: 1.5rem;
    font-weight: bold;
    background-color: rgba(22, 84, 204, 1);
    width: 20%;
    border-radius: 0px 0px 15px 0;
    position: absolute;
    top: 0;
    left: 0;
    color: #ffffff;
  }

  .calls {
    font-size: 1.7rem;
    display: block;
    width: 100%;
    font-weight: bold;
  }
}
</style>