<template>
  <b-modal
    ref="modalTransactionUnMatched"
    modal-class="modal-primary un-matched-transactions-modal"
    title-class="h3 text-white"
    title="UNMATCHED TRANSACTIONS"
    size="xlg"
    @hidden="closeModal()"
    hide-footer
    centered
    no-close-on-backdrop
  >
    <div>
      <div class="my-1">
        <div
          class="absolute-linker d-flex justify-content-between align-items-center"
          :class="{ dark: isDarkSkin }"
        >
          <div class="filter-selected-card">
            <v-select
              :options="creditCards"
              v-model="cardSelected"
              option-value="id"
              label="cardname"
              :clearable="false"
              placeholder="Select a card"
              style="min-width: 300px"
            >
              <template #selected-option="option">
                <div class="d-flex flex-column">
                  <span>Bank: {{ option.bank }}</span>
                  <span
                    >Card: {{ option.cardname }} | XXXX-{{
                      option.cardnumber.slice(-4)
                    }}</span
                  >
                </div>
              </template>
              <template v-slot:option="option">
                <div class="d-flex flex-column">
                  <span>Bank: {{ option.bank }}</span>
                  <span
                    >Card: {{ option.cardname }} | XXXX-{{
                      option.cardnumber.slice(-4)
                    }}</span
                  >
                </div>
              </template>
            </v-select>
          </div>
          <div class="d-flex align-items-center h-full px-1">
            <feather-icon
              icon="ChevronLeftIcon"
              size="20"
              class="cursor-pointer"
              @click="prev()"
            />
            <span class="font-size:15px">
              {{ months[filter.dataMonth - 1] }} {{ filter.dataYear }}
            </span>
            <feather-icon
              icon="ChevronRightIcon"
              size="20"
              class="cursor-pointer"
              :class="{ 'disabled-next': disableNext }"
              @click="next()"
            />
          </div>
        </div>
      </div>

      <template v-if="tabsTransactions.length > 0">
        <b-tabs
          v-model="activeTab"
          active-tab-class="p-0 "
          pills
          nav-class="mb-0 mt-1"
          active-nav-item-class="bg-primary box-shadow-primary border-primary primary"
        >
          <b-tab
            v-for="(item, index) in tabsTransactions"
            :key="index"
            :title="item.title"
            :active="index === 0"
            class="mb-1"
          >
            <template #title>
              {{ item.title }}
              <span class="ml-2" v-if="item.total_items > 0">
                <feather-icon
                  icon
                  :badge="item.total_items"
                  badge-classes="badge-danger"
              /></span>
            </template>
            <b-card
              no-body
              class="border-top-primary border-3 border-table-radius"
            >
              <filter-slot
                v-if="index === activeTab"
                :filter="[]"
                :paginate="paginate"
                :totalRows="paginate.totalRows"
                :start-page="paginate.startPage"
                :to-page="paginate.toPage"
                :no-visible-principal-filter="true"
                :top-paginate="false"
                :filter-principal="filterSearch"
                :showResetFilter="false"
              >
                <b-table
                  ref="unmatchedTransactionsTable"
                  slot="table"
                  :items="item.dataPaginada"
                  :fields="fields"
                  responsive
                  class="position-relative table-new-customization detailed-unmatched-transactions"
                  sticky-header="50vh"
                  :top-paginate="false"
                  :class="tabClass"
                  show-empty
                  no-provider-filtering
                >
                  <template #cell(department)="{ item }">
                    <img
                      v-b-tooltip.hover.top="item.department"
                      :src="`/assets/${item.icon}`"
                      alt="Logo"
                      height="40"
                      width="40"
                    />
                  </template>
                  <template #cell(amount)="{ item }">
                    <span
                      :class="{
                        'bold-text': currentTab && currentTab.key === 'import',
                      }"
                    >
                      {{ item.amount | formatMoney }}
                    </span>
                  </template>
                  <template #cell(transaction_date)="{ item }">
                    {{ item.transaction_date | myGlobal }}
                  </template>
                  <template #cell(type)="{ item }">
                    <div class="type--expense">
                      {{ item.type }}
                      <template v-if="item.type === 'CLIENT'">
                        <b-badge
                          variant="light-primary"
                          class="total-client cursor-pointer"
                          @click="openModalClientExpense(item)"
                        >
                          <span>{{ item.total_clients }}</span>
                        </b-badge>
                      </template>
                    </div>
                  </template>
                  <template #cell(register_by)="{ item }">
                    <span>{{ item.register_by }}</span>
                    <br />
                    <span>{{ item.created_date | myGlobalDay }}</span>
                  </template>
                  <template #cell(confirmed_date)="{ item }">
                    <span v-if="item.created_by_id == 1">
                      {{ item.created_date | myGlobalDay }}
                    </span>
                    <span v-else>
                      {{ item.confirmed_date | myGlobalDay }}
                    </span>
                  </template>
                  <template #cell(ticket)="{ item }">
                    <tabler-icon
                      v-if="!item.department_expense_id"
                      :class="ticketStatusColor(item)"
                      v-b-tooltip.hover.top="ticketStatusName(item)"
                      icon="TicketIcon"
                      class="cursor-pointer text-weight-bolder ml-1"
                      style="margin-left: 5px"
                      size="18"
                      @click="
                        item.department_expense_id
                          ? ''
                          : selectCustomerTicket(item)
                      "
                    />
                    <feather-icon
                      v-if="activeTab == 0"
                      icon="RefreshCwIcon"
                      size="18"
                      class="cursor-pointer text-warning ml-1"
                      style="margin-left: 5px"
                      v-b-tooltip.hover.top="'Sync'"
                      @click="openManualSyncTransactions(item)"
                    />
                    <feather-icon
                      v-if="activeTab == 0"
                      icon="TrashIcon"
                      size="18"
                      class="cursor-pointer text-danger ml-1"
                      v-b-tooltip.hover.top="'Annul confirmation'"
                      @click="annulConfirmation(item)"
                    />
                  </template>
                  <template #bottom-row>
                    <b-th
                      colspan="2"
                      v-if="
                        activeTab === 0 &&
                        currentTab &&
                        currentTab.key === 'soft'
                      "
                    >
                      <b-badge
                        variant="primary"
                        class="w-100 text-left"
                        style="font-size: 14px; font-weight: 600"
                      >
                      </b-badge>
                    </b-th>
                    <b-th>
                      <b-badge
                        variant="primary"
                        class="w-100 text-center"
                        style="font-size: 14px; font-weight: 600"
                      >
                        TOTAL
                      </b-badge>
                    </b-th>
                    <b-th>
                      <b-badge
                        variant="primary"
                        class="w-100 text-center totales-footer--unmatched-transactions"
                        style="font-size: 14px; font-weight: 600"
                      >
                        {{ totales | formatMoney }}
                      </b-badge>
                    </b-th>
                    <b-th class="fill-bottom-row" colspan="5" />
                  </template>
                </b-table>
              </filter-slot>
            </b-card>
          </b-tab>
        </b-tabs>
      </template>
      <template v-else>
        <div
          class="text-center custom-class d-flex justify-content-center align-items-center flex-column"
        >
          <img
            class="custom-img my-3"
            :src="require('@/assets/images/accounts/report-analizing.svg')"
            alt="Logo"
          />
          <span
            class="mt-2 custom-message text-secondary"
            style="font-size: 28px"
          >
            No unmatched transactions.
          </span>
        </div>
      </template>
    </div>
    <client-expense-request
      :expenseDepartment="expenseDepartment"
      v-if="expenseDepartment"
      @close="expenseDepartment = null"
    />
    <ModalCreateCustomerTicket
      v-if="showModalCreateCustomerTicket"
      :customer-ticket="customerTicketSelected"
      :selected-expense-transaction="selectedExpenseTransaction"
      :selected-expense="selectedExpense"
      :set-motive="103"
      @hidden="showModalCreateCustomerTicket = false"
      @saved="refresh()"
    />
    <ModalViewTicketCustomerDetails
      v-if="showModalViewTicketCustomerDetails"
      :customer-ticket="customerTicketSelected"
      @hidden="showModalViewTicketCustomerDetails = false"
    />
    <ManualSyncTransactions
      v-if="showModalSyncManual"
      :dataRow="selectedUnmatched"
      @close="showModalSyncManual = false"
      @refresh="refreshAll"
    />
  </b-modal>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import {
  fieldsImportUnMatched,
  fieldsSoftUnMatched,
} from "@/views/ceo/dashboard/modals/expenses-request/data/card-expenses-request.fields";
import ClientExpenseRequest from "@/views/ceo/dashboard/modals/expenses-request/ClientExpenseRequest.vue";
import ModalCreateCustomerTicket from "@/views/commons/components/customer-tickets/components/modal/ModalCreateCustomerTicket.vue";
import ModalViewTicketCustomerDetails from "@/views/commons/components/customer-tickets/components/modal/ModalViewTicketCustomerDetails.vue";
import DashboardService from "@/views/ceo/service-dashboard/dashboard.service";
import ManualSyncTransactions from "@/views/ceo/dashboard/modals/expenses/expenses-transactions-unmatched/ManualSyncTransactions.vue";

export default {
  name: "UnMatchedTransactionsModal",
  props: {
    card: {
      type: Object,
      required: true,
    },
    sendMonth: {
      type: Number,
      required: true,
    },
    sendYear: {
      type: Number,
      required: true,
    },
    creditCards: {
      type: Array,
      required: true,
    },
  },
  components: {
    ClientExpenseRequest,
    ModalCreateCustomerTicket,
    ModalViewTicketCustomerDetails,
    ManualSyncTransactions,
  },
  data() {
    return {
      showModalSyncManual: false,
      selectedUnmatched: null,
      selectedExpenseTransaction: null,
      selectedExpense: null,
      customerTicketSelected: null,
      showModalViewTicketCustomerDetails: false,
      showModalCreateCustomerTicket: false,
      fieldsImportUnMatched,
      fieldsSoftUnMatched,
      activeTab: 0,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      filter: {
        dataYear: null,
        dataMonth: null,
      },
      cpyMonth: null,
      tabsTransactions: [],
      paginate: {
        currentPage: 1,
        perPage: 50,
        totalRows: 0,
        startPage: 0,
        toPage: 0,
      },
      filterSearch: {
        type: "input",
        inputType: "text",
        placeholder: "search by account...",
        model: "",
      },
      dataPaginada: [],
      expenseDepartment: null,
      cardSelected: null,
      loaded: false,
      credit_card_expense_id: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    fields() {
      const tabItem = this.tabsTransactions.find(
        (item, index) => index === this.activeTab
      );
      if (!tabItem) return [];
      const fields = {
        soft: this.fieldsSoftUnMatched,
        import: this.fieldsImportUnMatched,
      };
      return fields[tabItem.key];
    },
    totales() {
      const tabItem = this.tabsTransactions.find(
        (item, index) => index === this.activeTab
      );
      if (!tabItem) return 0;
      return tabItem.total_amount;
    },
    disableNext() {
      const now = moment();
      const sameYear = now.year() === this.filter.dataYear;
      const sameMonth = now.month() + 1 === this.filter.dataMonth;
      return sameYear && sameMonth;
    },
    parametersApi() {
      return {
        month: this.filter.dataMonth,
        year: this.filter.dataYear,
        credit_card_expense_id: this.credit_card_expense_id,
      };
    },
    tabClass() {
      const tabItem = this.tabsTransactions.find(
        (item, index) => index === this.activeTab
      );
      return {
        "import--table": tabItem && tabItem.key === "import",
        "soft--table": tabItem && tabItem.key === "soft",
      };
    },
    currentTab() {
      const tabItem = this.tabsTransactions.find(
        (item, index) => index === this.activeTab
      );
      if (!tabItem) return null;
      return tabItem;
    },
  },
  watch: {
    async currentTab() {
      await this.showData();
    },
    "paginate.perPage": {
      handler() {
        this.$nextTick(this.showData);
      },
    },
    "paginate.currentPage": {
      handler() {
        this.$nextTick(this.showData);
      },
    },
    cardSelected: {
      async handler(value) {
        if (!this.loaded) return;
        this.credit_card_expense_id = value.id;
        await this.getUnMatchedTransactions();
      },
      deep: true,
    },
  },
  async created() {
    this.cardSelected = this.creditCards.find(
      (item) => item.id === this.card.credit_card_expense_id
    );
    this.credit_card_expense_id = this.card.credit_card_expense_id;
    await this.initMonthYear();
    await this.getUnMatchedTransactions();
    this.toggleModal("modalTransactionUnMatched");
    this.loaded = true;
  },
  methods: {
    ...mapActions({
      expensesRequest: "ExpensesRequest/refreshExpensesCounter",
    }),
    openManualSyncTransactions(item) {
      this.showModalSyncManual = true;
      this.selectedUnmatched = item;
      this.selectedUnmatched.pmonth = this.filter.dataMonth;
      this.selectedUnmatched.pyear = this.filter.dataYear;
    },
    async annulConfirmation(item) {
      await this.changeExpenseStatus(item);
    },
    async changeExpenseStatus(item) {
      const detail = `Service: <b>${item.department}</b>, Vendor: <b>${item.vendor}</b>, Concept: <b>${item.concept}</b>, Amount: <b>$${item.amount}</b>`;
      const confirm = await this.showConfirmSwal(
        `Are you sure you want to annul this confirmed expense?: ${detail}`
      );
      if (!confirm.isConfirmed) return false;

      const params = {
        id: item.id,
        status: "ANNULLED",
        user_id: this.currentUser.user_id,
        type: item.type,
        observation: "ANNULLED BY CEO (no match found)",
        method: "CREDIT_CARD",
      };

      try {
        this.addPreloader();
        const { data } = await DashboardService.changeExpenseStatus(params);
        if (data.success) {
          this.showToast(
            "success",
            "top-right",
            "Success",
            "CheckIcon",
            "Successfully process"
          );
          await this.refresh();
          this.expensesRequest(true);
        } else {
          this.showToast(
            "danger",
            "top-right",
            "Oop!",
            "AlertOctagonIcon",
            "Failed process"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async refreshAll() {
      await this.refresh();
      this.$emit("refreshAll");
    },
    ticketStatusName(item) {
      if (!item.ticket_code) return "Create Ticket";
      if (item.customer_status_id == 9) return "Completed";
      if (item.customer_status_id == 3) return "In Process";
      return "Not possible";
    },
    ticketStatusColor(item) {
      if (!item.ticket_code) return "text-secondary";
      if (item.customer_status_id == 9) return "text-success";
      if (item.customer_status_id == 3) return "text-warning";
      return "text-danger";
    },
    selectCustomerTicket(item) {
      if (item.ticket_code) {
        this.openModalViewTicketCustomerDetails(item);
      } else {
        this.openModalCreateCustomerTicket(item);
      }
    },
    async openModalViewTicketCustomerDetails(item) {
      const data = await DashboardService.getCustomerTicket({
        name_text: item.ticket_code,
        user_id: 1,
      });
      if (data.length > 0) {
        this.customerTicketSelected = data[0];
        this.showModalViewTicketCustomerDetails = true;
      } else {
        this.$bvToast.toast("Ticket not found", {
          title: "Error",
          variant: "danger",
        });
      }
    },
    async openModalCreateCustomerTicket(item) {
      let date = "";
      let formatedAmount = "";
      if (item.transaction_date) {
        const parsedDate = new Date(item.transaction_date);
        const mo = parsedDate.getMonth() + 1;
        const da = parsedDate.getDate();
        const an = parsedDate.getFullYear();
        date = `${mo}/${da}/${an}`;
      }
      if (item.amount) {
        formatedAmount = new Intl.NumberFormat("en-US", {
          currency: "USD",
          style: "currency",
        }).format(item.amount);
      }
      if (this.currentTab.key == "soft") {
        this.selectedExpense = { ...item };
        this.selectedExpenseTransaction = {
          id: null,
        };
        this.customerTicketSelected = {
          text: `VENDOR: ${item.vendor}\nCONCEPT: ${item.concept}\nAMOUNT: ${formatedAmount}\nDATE: ${date}`,
        };
      }
      if (this.currentTab.key == "import") {
        this.selectedExpenseTransaction = { ...item };
        this.selectedExpense = {
          id: null,
        };
        this.customerTicketSelected = {
          text: `CARD: XXXX-${item.cardnumber.slice(-4)}\nDESCRIPTION: ${
            item.description
          }\nAMOUNT: ${formatedAmount}\nDATE: ${date}`,
        };
      }
      this.showModalCreateCustomerTicket = true;
    },
    closeModal() {
      this.$emit("close");
    },
    async prev() {
      if (this.filter.dataMonth == null) this.filter.dataMonth = this.cpyMonth;
      if (this.filter.dataMonth > 1) {
        this.filter.dataMonth--;
      } else {
        this.filter.dataMonth = 12;
        this.filter.dataYear--;
      }
      await this.refresh();
    },
    async next() {
      if (this.filter.dataMonth == null) this.filter.dataMonth = this.cpyMonth;
      if (this.disableNext) return;
      if (this.filter.dataMonth < 12) {
        this.filter.dataMonth++;
      } else {
        this.filter.dataMonth = 1;
        this.filter.dataYear++;
      }
      await this.refresh();
    },
    async initMonthYear() {
      this.filter.dataMonth = this.sendMonth;
      this.filter.dataYear = this.sendYear;
    },
    async refresh() {
      this.getUnMatchedTransactions();
    },
    async getUnMatchedTransactions() {
      try {
        this.addPreloader();
        const data = await DashboardService.getUnMatchedTransactions(
          this.parametersApi
        );
        this.tabsTransactions = data;
        this.removePreloader();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async showData() {
      try {
        if (this.currentTab === null) return;
        let tabItem = this.tabsTransactions.find(
          (x) => x.key === this.currentTab.key
        );
        this.paginate.totalRows = tabItem.data.length;
        this.showRange(tabItem.data.length);
        let { currentPage, perPage } = this.paginate;
        const startIndex = (currentPage - 1) * perPage;
        const endIndex = startIndex + perPage;
        const pageData = tabItem.data.slice(startIndex, endIndex);
        this.paginate.currentPage = currentPage;
        this.tabsTransactions.forEach((element) => {
          if (element.key === this.currentTab.key) {
            element.dataPaginada = pageData;
          }
        });
      } catch (error) {}
    },
    showRange(totalRows) {
      let { currentPage, perPage } = this.paginate;
      const startIndex = (currentPage - 1) * perPage + 1;
      const endIndex = Math.min(currentPage * perPage, totalRows);
      this.paginate.toPage = endIndex;
      this.paginate.startPage = startIndex;
    },
    openModalClientExpense(item) {
      this.expenseDepartment = item;
    },
  },
  mounted() {
    // this.$nextTick(this.showData);
  },
};
</script>
<style>
.type--expense span.total--clients {
  text-decoration: underline;
  font-weight: 900;
  cursor: pointer;
}
</style>

<style lang="scss">
.detailed-unmatched-transactions .b-table-bottom-row {
  position: sticky;
  bottom: 0px;
  z-index: 1;
}
.detailed-unmatched-transactions .b-table-bottom-row th:nth-child(1) {
  border-radius: 0px 0px 0px 15px !important;
  margin-left: 5px !important;
  background: #0090e7 !important;
}
.detailed-unmatched-transactions.soft--table
  .b-table-bottom-row
  th:nth-child(n + 1):nth-child(-n + 3) {
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #0090e7 !important;
}
.detailed-unmatched-transactions.import--table
  .b-table-bottom-row
  th:nth-child(n + 1):nth-child(-n + 2) {
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #0090e7 !important;
}
.detailed-unmatched-transactions.soft--table
  .b-table-bottom-row
  th:nth-child(3) {
  border-radius: 0px 0px 15px 0px !important;
  margin-left: 10px !important;
  background: #0090e7 !important;
}
.detailed-unmatched-transactions.import--table
  .b-table-bottom-row
  th:nth-child(2) {
  border-radius: 0px 0px 15px 0px !important;
  margin-left: 10px !important;
  background: #0090e7 !important;
}
.detailed-unmatched-transactions .fill-bottom-row {
  background-color: #ffffff !important;
}

.dark-layout .detailed-unmatched-transactions {
  .fill-bottom-row {
    background-color: #17171a !important;
    border-top: none !important;
  }
}

#absolute-linker-settlement {
  width: 16rem;
  background: #fafafa;
  height: 3.714rem;
  top: 0;
  right: 0;
  background: #fafafa;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  &.dark {
    background: #242425;
  }
}
.totales-footer--unmatched-transactions {
  font-weight: bold;
}
.custom-class {
  position: relative;
  width: 100%;
  min-height: 40vh !important;
  .custom-img {
    max-width: 40%;
    max-height: 200px;
    width: 200px !important;
    height: 300px !important;
    opacity: 0.3;
  }
}
.detailed-unmatched-transactions {
  .total-client {
    font-size: 14px;
    padding: 0.8rem;
    span {
      border-bottom: 2px solid #0090e7;
      padding: 0px 5px;
      font-weight: bold !important;
    }
    :hover {
      cursor: pointer;
      span {
        box-shadow: rgba(255, 255, 255, 0.856) 0px 54px 55px,
          rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
          rgba(0, 0, 0, 0.17) 0px 12px 13px,
          rgba(241, 239, 239, 0.09) 0px -3px 5px;
      }
    }
  }
}
.detailed-unmatched-transactions.import--table {
  .bold-text {
    font-weight: bold;
  }
}
.filter-selected-card {
  .vs__dropdown-toggle {
    height: 60.17px;
  }
}
</style>
