<template>
  <b-modal
    ref="modalNewRecord"
    :title="`New ${!typeModalRecord ? 'Cash Record' : 'Record'}`"
    title-tag="h3"
    modal-class="modal-primary"
    @hidden="close"
    size="xmd"
    scrollable
    no-close-on-backdrop
    hide-footer
  >
    <b-row>
      <b-col cols="12" md="6">
        <b-form-group
          id="input-group-1"
          label="Client Name"
          label-for="input-1"
          description="enter client name if you want to search by client name"
        >
          <b-form-input
            id="input-1"
            v-model="clientName"
            placeholder="Enter client name"
            required
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-group
          id="input-group-2"
          label="Account Number"
          label-for="input-2"
          description="enter account number if you want to search by account number"
        >
          <b-form-input
            id="input-2"
            v-model="accountNumber"
            placeholder="Enter account number"
            required
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="2">
        <b-button variant="primary" class="mt-2" block @click="searchClient"
          >Search</b-button
        >
      </b-col>
    </b-row>
    <hr />
    <b-card
      class="card-details"
      :style="isDarkSkin ? 'background-color: #262626 !important' : ''"
    >
      <ValidationObserver ref="formOther">
        <b-card-title>
          New Record for
          <span class="text-primary">{{
            nameClientSelected || "No client found"
          }}</span>
        </b-card-title>
        <b-row v-if="typeModalRecord">
          <b-col md="6" class="mb-1">
            <validation-provider
              name="amount"
              rules="required"
              v-slot="{ errors }"
            >
              <b-input-group>
                <template #prepend>
                  <b-input-group-text> AMOUNT </b-input-group-text>
                </template>
                <money
                  v-model="amount"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    prefix: '$',
                    precision: 2,
                  }"
                  class="form-control"
                />
              </b-input-group>
              <small class="text-danger" v-if="errors.length > 0">
                {{ errors[0] }}
              </small>
            </validation-provider>
          </b-col>

          <b-col md="6" class="mb-1">
            <validation-provider
              name="program"
              rules="required"
              v-slot="{ errors }"
              v-if="!isClient"
            >
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >PROGRAM
                  </b-input-group-text>
                </template>
                <b-select
                  name="program"
                  :options="optionsPrograms"
                  value-field="value"
                  text-field="label"
                  v-model="programSelected"
                />
              </b-input-group>
              <small class="text-danger" v-if="errors.length > 0">
                {{ errors[0] }}
              </small>
            </validation-provider>
          </b-col>
          <b-col md="6" class="mb-1">
            <validation-provider
              name="type"
              rules="required"
              v-slot="{ errors }"
            >
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >TYPE
                  </b-input-group-text>
                </template>
                <b-select
                  name="type"
                  :options="optionsMethods"
                  value-field="value"
                  text-field="label"
                  v-model="methodSelected"
                />
              </b-input-group>
              <small class="text-danger" v-if="errors.length > 0">
                {{ errors[0] }}
              </small>
            </validation-provider>
          </b-col>
          <b-col md="6" class="mb-1">
            <validation-provider
              name="serial_number"
              rules="required"
              v-slot="{ errors }"
            >
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >SERIAL NUMBER
                  </b-input-group-text>
                </template>
                <b-form-input
                  name="serial_number"
                  v-model="serialNumber"
                  maxlength="15"
                  @input="serialNumber = serialNumber.toUpperCase()"
                />
              </b-input-group>
              <small class="text-danger" v-if="errors.length > 0">
                {{ errors[0] }}
              </small>
            </validation-provider></b-col
          >
        </b-row>
        <b-row v-else>
          <b-col md="6" class="mb-1">
            <validation-provider
              name="amount"
              rules="required"
              v-slot="{ errors }"
            >
              <b-input-group>
                <template #prepend>
                  <b-input-group-text> AMOUNT </b-input-group-text>
                </template>
                <money
                  v-model="amount"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    prefix: '$',
                    precision: 2,
                  }"
                  class="form-control"
                />
              </b-input-group>
              <small class="text-danger" v-if="errors.length > 0">
                {{ errors[0] }}
              </small>
            </validation-provider>
          </b-col>
          <b-col md="6" class="mb-1">
            <validation-provider
              name="program"
              rules="required"
              v-slot="{ errors }"
              v-if="!isClient"
            >
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >PROGRAM
                  </b-input-group-text>
                </template>
                <b-select
                  name="program"
                  :options="optionsPrograms"
                  value-field="value"
                  text-field="label"
                  v-model="programSelected"
                />
              </b-input-group>
              <small class="text-danger" v-if="errors.length > 0">
                {{ errors[0] }}
              </small>
            </validation-provider>
          </b-col>
        </b-row>
      </ValidationObserver>
      <b-button
        variant="success"
        class="float-right"
        @click="createRecord"
        :disabled="disabledBtn"
      >
        CREATE RECORD
      </b-button>
    </b-card>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import OtherPaymentsService from "@/views/commons/components/other-payments/service/other-payment.service";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import PaymentScheduleService from "@/views/commons/components/clients/components/paymentSchedule/service/payment-schedule.service.js";
export default {
  props: {
    typeModalRecord: Boolean,
  },
  data() {
    return {
      accountNumber: null,
      clientName: null,
      optionsPrograms: [
        { value: 1, label: "Business" },
        { value: 2, label: "Boost Credit" },
        { value: 3, label: "Credit Experts" },
        { value: 4, label: "Debt Solution" },
        { value: 5, label: "Tax Research" },
        { value: 6, label: "Court Info" },
        { value: 7, label: "Specialist" },
        { value: 8, label: "KeyBook" },
        { value: 9, label: "Paragon" },
      ],
      optionsMethods: [
        { label: "Check", value: 3 },
        { label: "Money Order", value: 4 },
        { label: "Cashier Check", value: 7 },
      ],
      methodSelected: 3,
      fileToUpload: [],
      serialNumber: "",
      date: "",
      amount: "",
      hour: "",
      fimage: "",
      typePaymentOther: 6,
      nameClientSelected: "",
      client_account_id: null,
      lead_id: null,
      isClient: false,
      module_id: null,
      program_id: null,
      programSelected: null,
      settlement_date: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    disabledBtn() {
      return this.client_account_id === null && this.lead_id === null;
    },
  },
  mounted() {
    this.toggleModal("modalNewRecord");
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async searchClient() {
      const { data } = await OtherPaymentsService.searchClient({
        accountNumber: this.accountNumber,
        clientName: this.clientName,
      });
      if (data.length > 0) {
        this.nameClientSelected = `${data[0].name} (${
          data[0].account || "CRM"
        })`;
        this.client_account_id = data[0].id;
        this.lead_id = data[0].lead_id;
        this.module_id = data[0].module_id;
        this.program_id = data[0].program_id;
        this.isClient = this.client_account_id !== null;
        await this.getLastPaymentDate();
      } else {
        this.showToast(
          "info",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          "No client found"
        );
        this.nameClientSelected = "No client found";
        this.client_account_id = null;
        this.lead_id = null;
        this.settlement_date = null;
        this.amount = 0;
      }
    },
    async createRecord() {
      let validate = await this.$refs["formOther"].validate();
      let datetime = null;
      if (validate) {
        if (this.date != "" && this.hour != "") {
          datetime = moment(
            `${this.date} ${this.hour}`,
            "YYYY-MM-DD HH:mm:ss"
          ).format("YYYY-MM-DD HH:mm:ss");
        }
        const form = {
          client_account_id: this.isClient ? this.client_account_id : null,
          lead_id: this.isClient ? null : this.lead_id,
          payment_method: !this.typeModalRecord ? 2 : this.methodSelected,
          payment_type: 6,
          payment_modality: 1,
          settlement_date:
            this.settlement_date == null ? datetime : this.settlement_date,
          serial_number: this.serialNumber,
          amount: this.amount,
          program_id: this.program_id,
          module_id: this.module_id,
          created_by: this.currentUser.user_id,
          image: this.fimage,
          isClient: this.isClient,
          correspondence: true,
          program_select: this.programSelected,
        };
        this.addPreloader();
        try {
          await OtherPaymentsService.saveOtherPayment(form);
          if (this.settlement_date !== null) {
            await this.registerAdvancePayment(form);
          }
          this.$emit("refresh");
          this.showSuccessSwal();
        } catch (error) {
          if (error.response.status === 400) {
            this.showWarningSwal("IMPORTANT!", error.response.data.message);
          } else {
            this.showErrorSwal();
          }
        } finally {
          this.removePreloader();
        }
      }
    },
    async getLastPaymentDate() {
      try {
        const { data } = await PaymentScheduleService.getLastPaymentDate({
          client_account_id: this.client_account_id,
        });
        this.settlement_date = null;
        this.amount = 0;
        if (data.length < 1) return;
        const dateOriginal = moment(data[0].due_date);
        const dateModified = dateOriginal.date(moment().date());
        this.settlement_date =
          dateModified.format("YYYY-MM-DD") + " " + moment().format("HH:mm:ss");
        this.amount =
          parseFloat(data[0].pending_amount) > 0
            ? parseFloat(data[0].pending_amount)
            : parseFloat(data[0].monthly_payment);
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async registerAdvancePayment(form) {
      try {
        this.addPreloader();
        const CurrentMonth = moment();
        const Month = moment(form.settlement_date);
        const isCurrentMonth = Month.format("MM-YY") === CurrentMonth.format("MM-YY");
        const isAdvancePayment = Month.isAfter(CurrentMonth, "month");
        form.isOtherPayment = 1;
        form.status = isCurrentMonth ? "CURRENT PAYMENT" : isAdvancePayment ? "ADVANCE PAYMENT" : "LATE PAYMENT";
        form.dateMonthlyPayment = this.settlement_date;
        const { data } = await ClientsOptionsServices.registerAdvancePayment(
          form
        );
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>

<style scoped>
.input-group-prepend {
  width: 30%;
}
.input-group-text {
  width: 100%;
}
</style>