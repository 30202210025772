<template>
  <div>
    <b-modal
      no-close-on-backdrop
      @hidden="$emit('close')"
      ref="modal-add-bank-account"
      v-model="showModal"
      size="lg"
      :title="title"
    >
      <div class="m-2">
        <ValidationObserver ref="observer">
          <div class="form-row">
            <!-- BANK ID -->
            <div class="form-group col-md-4">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="bank"
              >
                <b-form-group :class="errors[0]" label="Bank">
                  <v-select
                    v-model="form.bank_id"
                    :options="banks"
                    :reduce="(item) => item.bank_id"
                    label="bank_name"
                    :style="
                      errors[0]
                        ? 'border: 1px solid red; border-radius: 5px;'
                        : ''
                    "
                  >
                  </v-select>
                </b-form-group>
              </ValidationProvider>
            </div>
            <div class="form-group col-md-6">
              <b-form-group label="Status" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  id="btn-radios-2"
                  v-model="form.status"
                  :options="optionsStatus"
                  :aria-describedby="ariaDescribedby"
                  button-variant="outline-primary"
                  name="radio-btn-outline"
                  buttons
                ></b-form-radio-group>
              </b-form-group>
            </div>

            <!-- ACCOUNT NAME -->
            <div class="form-group col-md-6">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|max:60"
                name="accountName"
              >
                <b-form-group label="Account name">
                  <b-form-input
                    type="text"
                    class="form-control"
                    v-model="form.account_name"
                    :class="errors[0] ? 'is-invalid' : ''"
                    :disabled="paymentPending"
                    maxlength="60"
                  >
                  </b-form-input>
                </b-form-group>
              </ValidationProvider>
            </div>

            <!-- ACCOUNT NUMBER -->
            <div class="form-group col-md-6">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="accountNumber"
              >
                <b-form-group label="Account number">
                  <b-form-input
                    v-mask="'############'"
                    v-model="form.account_number"
                    :class="errors[0] ? 'is-invalid' : ''"
                    :disabled="paymentPending"
                  ></b-form-input>
                </b-form-group>
              </ValidationProvider>
            </div>

            <!-- ROUTING NUMBER -->
            <div class="form-group col-md-6">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="routingNumber"
              >
                <b-form-group label="Routing number">
                  <b-form-input
                    v-mask="'############'"
                    :class="errors[0] ? 'is-invalid' : ''"
                    v-model="form.routing_number"
                    :disabled="paymentPending"
                  >
                  </b-form-input>
                </b-form-group>
              </ValidationProvider>
            </div>

            <!-- MODULES -->
            <div class="form-group col-md-6">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="module"
              >
                <b-form-group
                  :class="errors[0]"
                  :label="type === 'CHECK' ? 'Modules' : 'Balance'"
                >
                  <template v-if="type === 'CHECK'">
                    <v-select
                      v-model="form.module_ids"
                      :options="modules"
                      :reduce="(item) => item.id"
                      label="name"
                      multiple
                      :clearable="false"
                      :style="
                        errors[0]
                          ? 'border: 1px solid red; border-radius: 5px;'
                          : ''
                      "
                    >
                    </v-select>
                  </template>
                  <template v-if="type === 'E-CHECK'">
                    <money
                      required
                      v-bind="money"
                      v-model="form.balance"
                      class="form-control"
                      :disabled="!!dataAccount"
                      :style="
                        errors[0]
                          ? 'border: 1px solid red; border-radius: 5px;'
                          : ''
                      "
                    ></money>
                  </template>
                </b-form-group>
              </ValidationProvider>
            </div>
          </div>
        </ValidationObserver>
      </div>
      <template #modal-footer>
        <div>
          <b-button variant="primary" @click="saveBankAccount()"
            ><feather-icon icon="SaveIcon" class="mr-1" />
            {{ textButton }}</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BankAccountsService from "@/views/debt-solution/views/bank-account/bank-accounts.service";
import vSelect from "vue-select";
import VueMask from "v-mask";
import { Money } from "v-money";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
// ADD VUE GOOGLE AUTOCOMPLETE FOR ADDRESS INPUT
import VueGoogleAutocomplete from "vue-google-autocomplete";

import CheckServices from "@/views/commons/components/check-expenses/services/checks.services.js";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    vSelect,
    VueMask,
    Money,
    VueGoogleAutocomplete,
  },
  directives: { money: Money },
  props: {
    dataAccount: {
      type: Object,
      default: null,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      banks: [],
      form: {
        bank_id: null,
        account_name: null,
        account_number: null,
        check_number: null,
        balance: 0,
        status: "active",
        bank_check_account_id: null,
        module_ids: [],
      },
      modules: [],

      validateMoney: false,
      statusAccounts: [
        { id: 1, label: "Active" },
        { id: 2, label: "Inactive" },
      ],
      typeAccounts: [
        { id: 1, label: "AMG" },
        { id: 2, label: "Client" },
      ],
      isDisabled: false,
      money: {
        decimal: ",",
        prefix: "$ ",
        thousands: ".",
        precision: 2,
        maxlength: 16,
      },
      paymentPending: false,
      optionsStatus: [
        { text: "Active", value: "active" },
        { text: "Inactive", value: "inactive" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    textButton() {
      return this.dataAccount != null ? "Update Account" : "Save Account";
    },
    title() {
      return this.dataAccount != null
        ? `UPDATE ACCOUNT: ${this.dataAccount.account_name}`
        : "ADD ACCOUNT";
    },
  },
  async created() {
    if (this.dataAccount != null) {
      this.form = { ...this.dataAccount };
      let modules = JSON.parse(this.dataAccount.module);
      this.form.module_ids = modules.map((item) => item.module_id);
    } else {
      this.form.module_ids = this.type === "E-CHECK" ? [1] : [];
    }

    await this.getBanks();
    await this.getModules();
    this.showModal = true;
  },

  methods: {
    async getBanks() {
      const { data } = await CheckServices.getBanks();
      this.banks = data;
    },
    async saveBankAccount() {
      const validate = await this.$refs.observer.validate();
      if (!validate) return;

      const params = {
        bankAccountId: this.form.bank_check_account_id,
        bankId: this.form.bank_id,
        accountName: this.form.account_name,
        accountNumber: this.form.account_number,
        routingNumber: this.form.routing_number,
        type: this.form.type,
        userId: this.currentUser.user_id,
        moduleIds: this.form.module_ids,
        tab: this.type,
        balance: this.form.balance,
        status: this.form.status,
      };

      try {
        const service =
          this.dataAccount != null ? "updateBankAccount" : "saveBankAccount";
        const { data } = await CheckServices[service](params);
        if (data.success) {
          this.showToast(
            "success",
            "top-right",
            "Success",
            "CheckIcon",
            "Successfully process"
          );
          this.$emit("refresh");
          this.$emit("close");
        } else {
          this.showToast(
            "danger",
            "top-right",
            "Error",
            "XIcon",
            "Error process"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getModules() {
      const { data } = await CheckServices.getModules();
      this.modules = data;
    },

    getAddressData(addressData, addressSecond) {
      this.dataBankAccount.accountAddress = addressSecond.formatted_address;

      const ctx = this;
      addressSecond.address_components.filter(function (val) {
        val.types.filter(function (sub) {
          if (sub == "administrative_area_level_1") {
            ctx.dataBankAccount.accountSlug = val.short_name;
          }
        });
      });
    },
    // Validation payments pending bank account (Edit)
    // Asynchronous function to get payments pending for a bank account
    async getPaymentsPending() {
      try {
        // Get data and status from BankAccountsService
        const { data, status } =
          await BankAccountsService.getPaymentsPendingBankAccount({
            // Pass in the bank_account_id
            bank_account_id: this.dataAccount.id,
          });
        // If status is 200, set paymentPending to true or false depending on data
        if (status == 200) {
          data ? (this.paymentPending = true) : (this.paymentPending = false);
        }
      } catch (error) {
        // Show error message
        this.showErrorSwal(error);
      }
    },
    // setAction() is a method that will either update an account bank or create a new one depending on the value of this.isUpdate
    setAction() {
      // If this.isUpdate is true, call the updateAccountBank() method
      if (this.isUpdate) {
        this.updateAccountBank();
      }
      // Otherwise, call the createAccount() method
      else {
        this.createAccount();
      }
    },
    // This function is used to create a new bank account
    async createAccount() {
      // Validate the form
      const result = await this.$refs.observer.validate();
      this.validateMoney = true;
      // If the form is valid, proceed with creating the account
      if (result) {
        try {
          // Create an object with all the necessary parameters
          const params = {
            bankName: this.dataBankAccount.bankName,
            accountName: this.dataBankAccount.accountName,
            routingNumber: this.dataBankAccount.routingNumber,
            accountNumber: this.dataBankAccount.accountNumber,
            typeCheckbook: this.dataBankAccount.typeCheckbook,
            balance: parseFloat(this.dataBankAccount.balance),
            statusAccount: this.dataBankAccount.statusAccount,
            type: this.dataBankAccount.type,
            user_id: this.currentUser.user_id,
            address: this.dataBankAccount.accountAddress,
            slug: this.dataBankAccount.accountSlug,
          };

          // Show a confirmation swal
          const resultState = await this.showConfirmSwal();

          // If the user confirms, proceed with creating the account
          if (resultState.isConfirmed) {
            this.addPreloader();
            const resul = await BankAccountsService.createBankAccounts(params);
            // If the account was created successfully, show a success swal and emit an event
            if (resul.status == 200) {
              this.showSuccessSwal(
                "Success!",
                "The bank account has been created successfully"
              );
              this.$emit("onCreateAccount");
            }
          }
        } catch (error) {
          // If there is an error, show an info or error swal depending on the type of error
          if (error.response?.data?.error) {
            this.showInfoSwal(error.response?.data?.error, "Info");
          } else {
            this.showErrorSwal(error);
          }
        } finally {
          this.removePreloader();
        }
      }
    },
    cancel() {
      this.$emit("closeModal");
    },
    // This function is used to show the bank account details
    showBankAccount() {
      this.dataBankAccount.bankName = this.dataAccount.bank_name;
      this.dataBankAccount.accountName = this.dataAccount.account_name;
      this.dataBankAccount.routingNumber = this.dataAccount.routing_number;
      this.dataBankAccount.accountNumber = this.dataAccount.account_number;
      this.dataBankAccount.accountAddress = this.dataAccount.address;
      this.dataBankAccount.balance = parseFloat(this.dataAccount.balance);
      this.dataBankAccount.statusAccount = this.dataAccount.status_code;
      this.dataBankAccount.type = this.dataAccount.type_code;
      this.dataBankAccount.id = this.dataAccount.id;
    },
    // This function updates the bank account information
    async updateAccountBank() {
      // Validate the form
      const result = await this.$refs.observer.validate();
      if (result) {
        try {
          // Create an object with the new bank account information
          const params = {
            bankName: this.dataBankAccount.bankName,
            accountName: this.dataBankAccount.accountName,
            routingNumber: this.dataBankAccount.routingNumber,
            accountNumber: this.dataBankAccount.accountNumber,
            balance: parseFloat(this.dataBankAccount.balance),
            statusAccount: this.dataBankAccount.statusAccount.id
              ? this.dataBankAccount.statusAccount.id
              : this.dataBankAccount.statusAccount,
            type: this.dataBankAccount.type.id
              ? this.dataBankAccount.type.id
              : this.dataBankAccount.type,
            user_id: this.currentUser.user_id,
            idBankAccount: this.dataBankAccount.id,
            address: this.dataBankAccount.accountAddress,
            slug: this.dataBankAccount.accountSlug,
          };

          // Show a confirmation swal
          const resultState = await this.showConfirmSwal();
          if (resultState.isConfirmed) {
            this.addPreloader();
            // Call the service to update the bank account
            const resul = await BankAccountsService.updateBankAccount(params);

            let message = resul.data;

            // Check the response from the service
            if (typeof message == "string") {
              this.showInfoSwal(message);
            } else {
              if (resul.status == 200) {
                this.showSuccessSwal(
                  "Success!",
                  "The bank account has been successfully updated"
                );
                this.$emit("onUpdateAccount");
              }
            }
          }
        } catch (error) {
          // Handle errors
          if (error.response?.data?.error) {
            this.showInfoSwal(error.response?.data?.error, "Info");
          } else {
            this.showErrorSwal(error);
          }
        } finally {
          this.removePreloader();
        }
      }
    },
  },
};
</script>
