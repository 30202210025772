<template>
  <div>
    <svg
      :width="width"
      :height="height"
      :viewBox="'0 0 ' + width + ' ' + height"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.875 12.081C15.88 13.061 17.37 13.061 17.375 12.081C17.289 10.703 16.247 9.576 14.881 9.381C15.032 8.092 13.231 8.092 13.381 9.38C10.134 9.904 10.138 14.256 13.381 14.78V17.3C12.843 17.16 12.447 16.702 12.387 16.149C12.383 15.168 10.891 15.168 10.887 16.149C10.973 17.526 12.015 18.654 13.381 18.849C13.231 20.137 15.031 20.137 14.881 18.849C18.128 18.324 18.125 13.972 14.881 13.449V10.932C15.419 11.072 15.814 11.529 15.875 12.081ZM13.381 13.232C12.108 12.898 12.108 11.265 13.381 10.932V13.232ZM15.875 16.149C15.814 16.701 15.419 17.159 14.881 17.299V15C15.419 15.14 15.814 15.597 15.875 16.149ZM6.654 12.845C4.985 12.873 4.985 15.358 6.654 15.385C8.323 15.357 8.323 12.873 6.654 12.845ZM21.577 12.845C19.908 12.873 19.908 15.357 21.577 15.385C23.246 15.357 23.246 12.873 21.577 12.845Z"
        :fill="color"
      />
      <path
        d="M27.983 8.357H27.958C27.865 6.254 26.135 4.594 24.03 4.589H4.2C2.022 4.592 0.257005 6.357 0.255005 8.535V19.7C0.263005 21.804 1.922 23.531 4.024 23.623C4.009 25.706 5.693 27.406 7.776 27.411H27.983C30.06 27.409 31.743 25.725 31.745 23.648V12.11C31.74 10.036 30.057 8.357 27.983 8.357ZM4.2 22.146C2.85 22.144 1.756 21.05 1.755 19.7V8.535C1.757 7.185 2.85 6.091 4.2 6.089H24.03C25.38 6.091 26.474 7.185 26.476 8.535V19.7C26.475 21.05 25.38 22.145 24.03 22.146H4.2ZM30.245 23.648C30.244 24.897 29.232 25.909 27.983 25.911H7.776C6.527 25.907 5.518 24.89 5.524 23.641H24.03C26.206 23.639 27.971 21.876 27.976 19.7V9.857C29.225 9.852 30.241 10.861 30.245 12.11V23.648Z"
        :fill="color"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      required: false,
      default: 33,
    },
    height: {
      type: Number,
      required: false,
      default: 33,
    },
    color: {
      type: String,
      required: false,
      default: "white",
    },
  },
};
</script>

