<template>
  <div>
    <b-modal
      ref="sales-detail-modal"
      size="xmd"
      @hidden="$emit('hidden')"
      hide-footer
    >
      <template #modal-title>
        <span class="text-uppercase font-weight-bolder"> {{ title }} </span>
      </template>
      <template #default>
        <filter-slot
          class="filter-slot-new-customization"
          :filter="filterSlot.filters"
          :total-rows="filterSlot.totalRows"
          :paginate="filterSlot.paginate"
          :start-page="filterSlot.startPage"
          :to-page="filterSlot.toPage"
          :filter-principal="filterSlot.filterPrincipal"
          :top-paginate="false"
          @reload="$refs['sale-detail-table'].refresh()"
        >
          <b-table
            id="sale-detail-table"
            ref="sale-detail-table"
            slot="table"
            class="position-relative table-new-customization"
            empty-text="No matching records found"
            sticky-header="60vh"
            primary-key="id"
            responsive="md"
            show-empty
            no-border-collapse
            no-provider-filtering
            :busy.sync="isBusy"
            :fields="fields"
            :items="myProvider"
            :per-page="filterSlot.paginate.perPage"
            :current-page="filterSlot.paginate.currentPage"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(nickname)="data">
              <router-link
                class="d-block text-primary"
                :class="textLink"
                :to="`/socialnetwork/leads/new/dashboard/${data.item.lead_id}`"
                target="_blank"
              >
                {{ data.item.nickname }}
              </router-link>
              <div v-if="data.item.fullname">
                {{ data.item.fullname }}
              </div>
              <div v-else>-</div>
            </template>
            <template #cell(program_id)="data">
              <div
                class="d-flex justify-content-center align-items-center position-relative h-100 w-100"
              >
                <div>
                  <div v-if="data.item.program_name" class="programs_image">
                    <b-img
                      :src="getProgramImageRoute(data.item.program_id)"
                      v-b-tooltip.right="data.item.program_name"
                      alt="img_services"
                    />
                  </div>
                  <b-avatar
                    v-else
                    size="40"
                    square
                    icon="file-earmark-image-fill"
                    class="rounded"
                    variant="light"
                  />
                </div>
              </div>
            </template>
            <template #cell(source_url)="data">
              <div
                class="d-flex justify-content-center flex-column align-items-center"
              >
                <b-avatar
                  :src="data.item.source_url"
                  size="40"
                  square
                  icon="file-earmark-image-fill"
                  class="rounded"
                  variant="light"
                />
                <span class="pt-1">
                  {{ data.item.source_name }}
                </span>
              </div>
            </template>
            <template #cell(fee_amount)="data">
              <span v-if="data.item.fee_amount">
                {{ data.item.fee_amount | formatCurrency }}
              </span>
              <span v-else>-</span>
            </template>
            <template #cell(initial_amount)="data">
              <span v-if="data.item.initial_amount">
                {{ data.item.initial_amount | formatCurrency }}
              </span>
              <span v-else>-</span>
            </template>
            <template #cell(created_at)="data">
              {{ data.item.created_at | myGlobalDay }}
            </template>
          </b-table>
        </filter-slot>
      </template>
    </b-modal>
  </div>
</template>
  <script>
import ComercialReportService from "@/views/commons/components/reports/tabs/comercial-report/service/comercial-report-v2.service.js";
export default {
  name: "LeadsDetailModal",
  filters: {
    formatCurrency(value) {
      if (typeof value === "string") {
        value = value.replace(/,/g, "");
      }

      value = parseFloat(value);
      if (!value || value === 0 || isNaN(value)) {
        return "$0.00";
      }
      return value.toLocaleString(
        "en-US",
        {
          style: "currency",
          currency: "USD",
        },
        { minimumFractionDigits: 2 },
        { maximumFractionDigits: 2 }
      );
    },
  },
  props: {
    type: {
      type: Number,
      required: true,
      default: null,
    },
    flyerCode: {
      type: String,
      required: true,
      default: "",
    },
    flyerId: {
      type: Number,
      required: true,
      default: null,
    },
    metaAdId: {
      type: String,
      required: false,
      default: "",
    },
    dateFrom: {
      type: String,
      required: true,
      default: null,
    },
    dateTo: {
      type: String,
      required: true,
      default: null,
    },
    sendYear: {
      type: Number,
      required: false,
      default: null,
    },
    sendMonth: {
      type: Number,
      required: false,
      default: null,
    },
    sendRange: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      isBusy: false,
      fields: [
        {
          key: "nickname",
          label: "Lead",
          sortable: true,
          visible: true,
        },
        {
          key: "program_id",
          label: "Program",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: false,
          visible: false,
        },
        {
          key: "source_url",
          label: "Source",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: false,
          visible: true,
        },
        {
          key: "fee_amount",
          label: "Fee",
          sortable: true,
          visible: true,
        },
        {
          key: "initial_amount",
          label: "IP",
          sortable: true,
          visible: true,
        },
        {
          key: "created_at",
          label: "Created at",
          sortable: true,
          visible: true,
        },
      ],
      filterSlot: {
        filters: [],
        paginate: {
          currentPage: 1,
          perPage: 50,
        },
        startPage: 0,
        toPage: 0,
        totalRows: 0,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Search...",
          model: "",
        },
      },
    };
  },
  computed: {
    title() {
      const tiltes = {
        1: "Appointments with sales",
        2: "Appointments without sales",
      };
      return tiltes[this.type];
    },
  },
  created() {},
  mounted() {
    this.toggleModal("sales-detail-modal");
  },
  methods: {
    async myProvider(ctx) {
      try {
        const orderBy = ctx.sortBy ? ctx.sortBy : "created_at";
        let order = ctx.sortDesc ? "desc" : "asc";
        order = ctx.sortBy ? order : "desc";

        const params = {
          search_text: this.filterSlot.filterPrincipal.model,
          date_from: this.dateFrom,
          date_to: this.dateTo,
          flyer_id: this.flyerId,
          meta_ad_id: this.metaAdId,
          type: this.type,
          npage: this.filterSlot.paginate.currentPage,
          perpage: this.filterSlot.paginate.perPage,
          orderby: orderBy,
          order: order,
          year: this.sendYear,
          month: this.sendMonth,
          range: this.sendRange,
        };

        const { data } = await ComercialReportService.getSalesByFlyer(params);

        this.filterSlot.totalRows = data.total;
        this.filterSlot.startPage = data.from ?? 0;
        this.filterSlot.toPage = data.to ?? 0;
        this.filterSlot.paginate.currentPage = data.current_page;
        this.filterSlot.paginate.perPage = data.per_page;

        return data.data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>
  <style lang="scss">
#sale-detail-table {
  .programs_image {
    background-color: #e9e9e9;
    padding: 0.2rem;
    border-radius: 10px;
    width: 3.2rem;
    height: 3.2rem;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .rounded {
    border-radius: 0.357rem !important;
  }
}
</style>