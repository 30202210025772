export default [
    {
        type: 'select',
        margin: true,
        showLabel: true,
        label: 'Program',
        placeholder: 'Select a Program',
        model: null,
        options: [],
        reduce: 'id',
        selectText: 'name',
        cols: 12,
    },
    {
        type: 'select',
        margin: true,
        showLabel: true,
        label: 'Status',
        placeholder: 'Select a status',
        model: null,
        options: [],
        reduce: 'id',
        selectText: 'status',
        cols: 12,
    },
]
