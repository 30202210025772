<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="7.68007"
      cy="9.62533"
      rx="1.13325"
      ry="1.07025"
      :fill="color"
    />
    <ellipse
      cx="14.3537"
      cy="9.62533"
      rx="1.13325"
      ry="1.07025"
      :fill="color"
    />
    <path
      d="M14.127 13.1636H7.78018C7.48826 13.1638 7.25172 13.4005 7.25191 13.6925C7.25436 15.7359 8.91038 17.392 10.9539 17.3944C12.9974 17.392 14.6534 15.7359 14.6558 13.6925C14.6556 13.4006 14.4189 13.1634 14.127 13.1636ZM11.4836 16.2831C10.0523 16.5757 8.65487 15.6526 8.36229 14.2213H13.5455C13.3333 15.2595 12.5218 16.0709 11.4836 16.2831ZM10.9539 1C5.11236 1 0.376831 5.73553 0.376831 11.577C0.376831 17.4186 5.11236 22.1541 10.9539 22.1541C16.7926 22.1472 21.5241 17.4157 21.5309 11.577C21.5309 5.73553 16.7954 1 10.9539 1ZM10.9539 21.0964C5.69647 21.0964 1.43454 16.8345 1.43454 11.577C1.43454 6.31964 5.69647 2.0577 10.9539 2.0577C16.2089 2.06345 20.4675 6.32203 20.4732 11.577C20.4732 16.8345 16.2113 21.0964 10.9539 21.0964Z"
      :fill="color"
      :stroke="color"
      stroke-width="0.284236"
    />
  </svg>
</template>
<script>
export default {
  name: "PositiveIcon",
  props: {
    size: {
      type: String,
      default: "1rem",
    },
    color: {
      type: String,
      default: "#000",
    },
  },
};
</script>
