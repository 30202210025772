export default [
  {
    key: "program_name",
    label: "Service",
    sortable: false,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "route_thumb",
    label: "Flyer",
    sortable: false,
    visible: false,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "ad_type",
    label: "Ad Type",
    sortable: false,
    visible: false,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "ad_name",
    label: "Ad Name",
    sortable: true,
    visible: false,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "effective_status",
    label: "Status",
    sortable: false,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "campaign_id",
    label: "Campaign",
    sortable: false,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "reach",
    label: "Reach",
    sortable: true,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "spend",
    label: "Investment",
    sortable: true,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "results",
    label: "Results",
    sortable: true,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "cost_per_result",
    label: "Cost per result",
    sortable: true,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "period",
    label: "Period",
    sortable: false,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
];
