export default [
  {
    key: "ad_type",
    type: "select",
    margin: true,
    showLabel: true,
    label: "Ad Type",
    model: null,
    options: [
      { value: null, label: "All" },
      { value: "Flyer", label: "Flyer" },
      { value: "Video", label: "Video" },
      { value: "Text", label: "Text" },
      { value: "Other", label: "Other" },
    ],
    reduce: "value",
    selectText: "label",
    cols: 12,
    visible: false,
  },
  {
    key: "program_id",
    type: "select",
    margin: true,
    showLabel: true,
    label: "Service",
    model: null,
    options: [
      { value: null, label: "All" },
      { value: 1, label: "Business" },
      { value: 2, label: "Boost Credit" },
      { value: 3, label: "Credit Experts" },
      { value: 4, label: "Debt Solution" },
      { value: 5, label: "Tax Reasearch" },
      { value: 6, label: "Court Info" },
      { value: 7, label: "Specialist" },
      { value: 8, label: "Key Book" },
      { value: 9, label: "Paragon" },
    ],
    reduce: "value",
    selectText: "label",
    cols: 12,
    visible: false,
  },
];
