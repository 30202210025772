<template>
  <b-modal
    ref="imported-transactions-modal"
    modal-class="custom-modal-amg"
    title-class="h3 text-white"
    title="IMPORTED TRANSACTIONS"
    size="xmd"
    no-close-on-backdrop
    @hidden="closeModal()"
    hide-footer
    header-class="p-0"
    centered
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <div>IMPORTED TRANSACTIONS</div>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="closeModal()"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>
    <div class="ml-1 my-1">
      <div
        class="absolute-linker d-flex justify-content-between align-items-center"
        :class="{ dark: isDarkSkin }"
      >
        <div>
          <v-select
            :options="creditCards"
            v-model="cardSelected"
            option-value="id"
            label="cardname"
            :clearable="false"
            placeholder="Select a card"
            style="min-width: 300px"
            @input="refresh()"
          >
            <template #selected-option="option">
              <div class="d-flex flex-column">
                <span>Bank: {{ option.bank }}</span>
                <span
                  >Card: {{ option.cardname }} | XXXX-{{
                    option.cardnumber.slice(-4)
                  }}</span
                >
              </div>
            </template>
            <template v-slot:option="option">
              <div class="d-flex flex-column">
                <span>Bank: {{ option.bank }}</span>
                <span
                  >Card: {{ option.cardname }} | XXXX-{{
                    option.cardnumber.slice(-4)
                  }}</span
                >
              </div>
            </template>
          </v-select>
        </div>
        <div class="d-flex align-items-center flex-column h-full px-1">
          <div>
            <feather-icon
              icon="ChevronLeftIcon"
              size="20"
              class="cursor-pointer"
              @click="prev()"
            />
            <span class="font-size:15px">
              {{ months[dataMonth - 1] }} {{ dataYear }}
            </span>
            <feather-icon
              icon="ChevronRightIcon"
              size="20"
              class="cursor-pointer"
              :class="{ 'disabled-next': disableNext }"
              @click="next()"
            />
          </div>
          <div class="mt-1">
            <b-button
              variant="outline-success"
              @click="openImportTransactions()"
            >
              <div class="d-flex align-items-center" style="gap: 10px">
                <feather-icon icon="UploadIcon" />
                <span>Import Transactions</span>
              </div>
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <b-table
      :items="getImportedTransactions"
      :fields="filterFields"
      ref="import-transaction-history"
      sticky-header="50vh"
      show-empty
      :busy.sync="isBusy"
      class="position-relative table-new-customization"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(cardinfo)="{ item }">
        <div class="row">
          <div class="col-4 p-0 justify-content-end d-flex">
            <type-card :type_card="item.type_card" />
          </div>
          <div
            class="col-8 p-0 d-flex justify-content-center align-items-center"
          >
            <strong>XXXX-{{ item.cardnumber.slice(-4) }}</strong>
          </div>
        </div>
      </template>
      <template #cell(date_ranges)="{ item }">
        <div>
          <strong>{{ item.date_ranges.split(" - ")[0] | myGlobal }}</strong>
          -
          <strong>{{ item.date_ranges.split(" - ")[1] | myGlobal }}</strong>
        </div>
      </template>
      <template #cell(total_transactions)="{ item }">
        <div>
          <b-badge
            @click="openDetail(item)"
            variant="primary"
            style="font-size: 15px"
            class="cursor-pointer"
            >{{ item.total_transactions }}</b-badge
          >
        </div>
      </template>
      <template #cell(total_amount)="{ item }">
        <div>
          <b-badge
            @click="openDetail(item)"
            variant="light-success"
            style="font-size: 15px"
            class="cursor-pointer"
            >{{ item.total_amount | formatMoney }}</b-badge
          >
        </div>
      </template>
      <template #cell(created_at)="{ item }">
        <div>
          {{ item.created_at | myGlobalWithHour }}
        </div>
      </template>
      <template #bottom-row>
        <b-th>
          <b-badge
            variant="primary"
            class="w-100 text-center"
            style="
              font-size: 14px;
              font-weight: 600;
              padding-bottom: 7px;
              padding-top: 7px;
            "
          >
            TOTAL
          </b-badge>
        </b-th>
        <b-th>
          <b-badge
            variant="success"
            class="w-100 text-center text-black"
            style="
              font-size: 14px;
              font-weight: 600;
              padding-bottom: 7px;
              padding-top: 7px;
            "
          >
            {{ totals.total_transactions }}
          </b-badge>
        </b-th>
        <b-th>
          <b-badge
            variant="success"
            class="w-100 text-center text-black"
            style="
              font-size: 14px;
              font-weight: 600;
              padding-bottom: 7px;
              padding-top: 7px;
            "
          >
            {{ totals.total_amount | formatMoney }}
          </b-badge>
        </b-th>
        <b-th> </b-th>
      </template>
    </b-table>
    <ImportedTransactionsDetailsModal
      v-if="showDetail"
      :send-year="dataYear"
      :send-month="dataMonth"
      :credit-cards="creditCards"
      :historyInfo="logInfo"
      :card-selected-prop="cardSelected"
      :import-history-items="items"
      @close="showDetail = false"
    />
    <ImportTransactions
      v-if="showModalImport"
      :send-year="dataYear"
      :send-month="dataMonth"
      @close="showModalImport = false"
      @refresh="refresh()"
    />
  </b-modal>
</template>
<script>
import DashboardService from "@/views/ceo/service-dashboard/dashboard.service.js";
import TypeCard from "@/views/commons/card/TypeCard.vue";
import ImportedTransactionsDetailsModal from "@/views/commons/components/credit-cards-expenses/modals/ImportedTransactionsDetailsModal";
import moment from "moment";
import ImportTransactions from "@/views/commons/components/credit-cards-expenses/modals/ImportTransactions.vue";

export default {
  props: {
    sendYear: {
      type: Number,
      required: true,
    },
    sendMonth: {
      type: Number,
      required: true,
    },
    creditCards: {
      type: Array,
      required: true,
    },
    dataRow: {
      type: Object,
      required: true,
    },
  },
  components: {
    TypeCard,
    ImportedTransactionsDetailsModal,
    ImportTransactions,
  },
  data() {
    return {
      isBusy: false,
      showModalImport: false,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      dataYear: null,
      dataMonth: null,
      cpyMonth: null,
      cpyYear: null,
      totals: {
        total_transactions: 0,
        total_amount: 0,
      },
      fields: [
        {
          key: "date_ranges",
          label: "Range",
          class: "text-center",
          visible: true,
        },
        {
          key: "total_transactions",
          label: "Total transactions",
          class: "text-center",
          visible: true,
        },
        {
          key: "total_amount",
          label: "Total amount",
          class: "text-center",
          visible: true,
        },
        {
          key: "created_at",
          label: "Imported at",
          class: "text-center",
          visible: true,
        },
      ],
      items: [],
      logInfo: null,
      showDetail: false,
      cardSelected: null,
    };
  },
  mounted() {
    this.cardSelected = this.creditCards.find(
      (item) => item.id === this.dataRow.credit_card_expense_id
    );
    this.initMonthYear();
    this.toggleModal("imported-transactions-modal");
  },
  async created() {},
  computed: {
    filterFields() {
      return this.fields.filter((item) => item.visible);
    },
    disableNext() {
      const now = moment();
      const sameYear = now.year() === this.dataYear;
      const sameMonth = now.month() + 1 === this.dataMonth;
      return sameYear && sameMonth;
    },
  },
  methods: {
    openImportTransactions() {
      this.showModalImport = true;
      this.validateModal = true;
    },
    refresh() {
      this.$refs["import-transaction-history"].refresh();
    },
    initMonthYear() {
      this.dataMonth = this.sendMonth;
      this.dataYear = this.sendYear;
    },
    async next() {
      if (this.dataMonth == null) this.dataMonth = this.cpyMonth;
      if (this.disableNext) return;
      if (this.dataMonth < 12) {
        this.dataMonth++;
      } else {
        this.dataMonth = 1;
        this.dataYear++;
      }
      await this.refresh();
    },
    async prev() {
      if (this.dataMonth == null) this.dataMonth = this.cpyMonth;
      if (this.dataMonth > 1) {
        this.dataMonth--;
      } else {
        this.dataMonth = 12;
        this.dataYear--;
      }
      await this.refresh();
    },
    async getImportedTransactions() {
      try {
        this.addPreloader();
        const params = {
          year: this.dataYear,
          month: this.dataMonth,
          credit_card_id: this.cardSelected.id,
        };
        const { data } = await DashboardService.getImportedTransactionsByCard(
          params
        );
        this.items = data.data;
        this.totals = data.totals;
        return this.items;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    closeModal() {
      this.$emit("close");
    },
    openDetail(item) {
      this.logInfo = item;
      this.showDetail = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.b-table-bottom-row th {
  background-color: #ffffff !important;
}
.dark-layout {
  .b-table-bottom-row th {
    background-color: #17171a !important;
  }
}
</style>
