<template>
  <b-modal
    ref="VendorConceptEditorModal"
    :title="title"
    size="sm"
    centered
    title-class="h4 text-white font-weight-bolder"
    header-class="class_modal_js"
    @ok="handleOk"
    @hidden="handleHidden"
    ok-title="Update"
  >
    <ValidationObserver ref="form">
      <b-row>
        <b-col>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <b-form-group label="Name">
              <b-form-input
                v-model="description"
                :state="errors.length > 0 ? false : null"
                @keyup.enter="handleOk"
              />
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service";
export default {
  name: "VendorConceptEditorModal",
  props: {
    vcTarget: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      description: null,
    };
  },
  computed: {
    title() {
      return this.vcTarget.type == "vendor"
        ? "UPDATE VENDOR"
        : "UPDATE CONCEPT";
    },
  },
  methods: {
    async handleOk(ev) {
      ev.preventDefault();
      let isValid = await this.$refs["form"].validate();
      if (!isValid) return;
      const confirm = await this.showConfirmSwal(
        `Are you sure you want to update this ${this.vcTarget.type}?: ${this.vcTarget.description}`
      );
      if (!confirm.isConfirmed) return;
      await this.updateVendorConcept();
    },
    handleHidden() {
      this.$emit("closing");
    },
    async updateVendorConcept() {
      try {
        this.addPreloader();
        await DashboardServive.updateVendorConcept({
          id: this.vcTarget.id,
          description: this.description,
          type: this.vcTarget.type,
        });
        this.showToast(
          "success",
          "top-right",
          "Success",
          "CheckIcon",
          "Successfully process"
        );
        this.$emit("refresh");
        this.$emit("closing");
        this.description = null;
        this.removePreloader();
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
  },
  mounted() {
    this.description = this.vcTarget.description;
    this.toggleModal("VendorConceptEditorModal");
  },
};
</script>
