<template>
  <div>
    <b-modal
      title="CARD PAYMENTS"
      v-model="showModal"
      hide-footer
      size="xmd"
      @hidden="close()"
      modal-class="custom-modal-amg"
    >
      <div class="ml-1 my-1">
        <div
          class="absolute-linker d-flex justify-content-end align-items-center"
          :class="{ dark: isDarkSkin }"
        >
          <div>
            <b-button
              variant="outline-primary"
              v-b-tooltip.hover.left
              :title="'Update unmatched transactions'"
              @click="updateUnmatchedTransactions()"
              size="sm"
              class="mr-1 blink-button"
            >
              SYNC PENDING
              <tabler-icon icon="RotateClockwiseIcon" size="20" />
            </b-button>
            <b-button @click="openDetailRequest()" variant="outline-danger"
              >REJECTED</b-button
            >
          </div>
          <div class="d-flex align-items-center h-full px-1">
            <feather-icon
              icon="ChevronLeftIcon"
              size="20"
              class="cursor-pointer"
              @click="prev()"
            />
            <span class="font-size:15px">
              {{ months[dataMonth - 1] }} {{ dataYear }}
            </span>
            <feather-icon
              icon="ChevronRightIcon"
              size="20"
              class="cursor-pointer"
              :class="{ 'disabled-next': disableNext }"
              @click="next()"
            />
          </div>
        </div>
      </div>

      <b-table
        :items="expenses"
        :fields="fields"
        responsive
        class="position-relative table-new-customization"
        sticky-header="70vh"
      >
        <template #head(total_unmatched)>
          <div class="d-flex justify-content-center">
            <span>Unmatched</span>
          </div>
        </template>
        <template #cell(bank)="{ item }">
          <strong class="text-warning">
            {{ item.bank }}
          </strong>
        </template>
        <template #cell(cardholdername)="{ item }">
          <div class="d-flex flex-column">
            <span class="m-0">{{ item.cardholdername }}</span>
            <strong>XXXX-{{ item.cardnumber.slice(-4) }}</strong>
          </div>
        </template>
        <template #cell(total_soft)="{ item }">
          <div
            v-if="item.total_soft > 0"
            class="cursor-pointer"
            style="text-decoration: underline; text-underline-offset: 3px"
            @click="openTotalsByModuleModal(item)"
          >
            <strong>{{ item.total_soft | formatMoney }}</strong>
          </div>
          <div v-else>
            {{ item.total_soft | formatMoney }}
          </div>
        </template>
        <template #cell(total_import)="{ item }">
          <div
            v-if="item.total_import > 0"
            class="cursor-pointer"
            style="text-decoration: underline; text-underline-offset: 3px"
            @click="openImportedTransactionsModal(item)"
          >
            {{ item.total_import | formatMoney }}
          </div>
          <div v-else>
            {{ item.total_import | formatMoney }}
          </div>
        </template>
        <template #cell(total_unmatched)="{ item }">
          <template v-if="item.total_import > 0">
            <div
              v-if="totalUnmatched(item) > 0"
              class="cursor-pointer text-danger"
              style="
                text-decoration: underline;
                text-underline-offset: 3px;
                font-weight: bold;
              "
              @click="openUnMatchedTransactionModal(item)"
            >
              {{ totalUnmatched(item) | formatMoney }}
            </div>
            <div v-else :style="{ cursor: 'not-allowed' }">
              {{ item.total_unmatched | formatMoney }}
            </div>
          </template>
          <template v-else
            ><strong
              class="text-warning"
              v-b-tooltip.hover
              title="Pending import"
              >UNDEFINED</strong
            >
          </template>
        </template>
        <template #cell(match)="{ item }">
          <strong
            v-if="item.total_matched > 0"
            class="cursor-pointer text-success"
            v-b-tooltip.hover.left
            :title="'Show matched transactions'"
            @click="openMatchTransactionModal(item)"
            style="
              text-decoration: underline;
              text-underline-offset: 3px;
              font-weight: bold;
            "
          >
            {{ item.total_matched | formatMoney }}
          </strong>
          <span v-else>{{ item.total_matched | formatMoney }}</span>
        </template>
        <template #cell(status)="{ item }">
          <feather-icon
            icon="CircleIcon"
            size="13"
            :fill="
              item.total_soft == item.total_import &&
              parseFloat(item.total_unmatched) +
                parseFloat(item.total_exp_dept_unmatched) ==
                0
                ? 'green'
                : 'red'
            "
            :style="
              item.total_soft == item.total_import &&
              parseFloat(item.total_unmatched) +
                parseFloat(item.total_exp_dept_unmatched) ==
                0
                ? 'color: green'
                : 'color: red'
            "
          />
        </template>

        <template #bottom-row>
          <b-th></b-th>
          <b-th>
            <b-badge
              variant="primary"
              class="w-100 text-center"
              style="
                font-size: 14px;
                font-weight: 600;
                padding-bottom: 7px;
                padding-top: 7px;
              "
            >
              TOTAL
            </b-badge>
          </b-th>
          <b-th>
            <b-badge
              variant="success"
              class="w-100 text-center text-black"
              style="
                font-size: 14px;
                font-weight: 600;
                padding-bottom: 7px;
                padding-top: 7px;
              "
            >
              {{ totalGeneralSoft | formatMoney }}
            </b-badge>
          </b-th>
          <b-th>
            <b-badge
              variant="success"
              class="w-100 text-center text-black"
              style="
                font-size: 14px;
                font-weight: 600;
                padding-bottom: 7px;
                padding-top: 7px;
              "
            >
              {{ totalGeneralImport | formatMoney }}
            </b-badge>
          </b-th>
          <b-th>
            <b-badge
              variant="success"
              class="w-100 text-center text-black"
              style="
                font-size: 14px;
                font-weight: 600;
                padding-bottom: 7px;
                padding-top: 7px;
              "
            >
              {{
                totalGeneralMatched | formatMoney
              }}
            </b-badge>
          </b-th>
          <b-th>
            <b-badge
              variant="success"
              class="w-100 text-center text-black"
              style="
                font-size: 14px;
                font-weight: 600;
                padding-bottom: 7px;
                padding-top: 7px;
              "
            >
              {{
                totalGeneralImport == 0
                  ? totalGeneralImport
                  : totalGeneralUnmatched | formatMoney
              }}
            </b-badge>
          </b-th>
          <b-th></b-th>
        </template>
      </b-table>
    </b-modal>
    <totals-by-module-modal
      v-if="showTotalsByModuleModal"
      :send-year="dataYear"
      :send-month="dataMonth"
      :card-id="itemSelected.credit_card_expense_id"
      @reload="reload"
      @close="showTotalsByModuleModal = false"
    />
    <ImportedTransactionsModal
      v-if="showImportedTransactions"
      :send-year="dataYear"
      :send-month="dataMonth"
      :credit-cards="creditCards"
      @close="showImportedTransactions = false"
      :dataRow="dataRow"
    />
    <un-matched-transactions-modal
      v-if="modalTransactionUnMatched.show"
      :card="modalTransactionUnMatched.item"
      @close="modalTransactionUnMatched.show = false"
      @refreshAll="updateUnmatchedTransactions()"
      :sendMonth="dataMonth"
      :sendYear="dataYear"
      :creditCards="creditCards"
    />
    <expenses-request-detail
      v-if="showExpensesRequestDetailModal"
      @refresh="getRequestExpenses()"
      @close="showExpensesRequestDetailModal = false"
      :isRejected="true"
    />
    <MatchTransactionModal
      v-if="showModalMatchTransaction.show"
      :creditCardId="showModalMatchTransaction.creditCardId"
      :sendMonth="showModalMatchTransaction.month"
      :sendYear="showModalMatchTransaction.year"
      :creditCards="creditCards"
      @close="
        [
          (showModalMatchTransaction.show = false),
          (showModalMatchTransaction.month = null),
          (showModalMatchTransaction.year = null),
          (showModalMatchTransaction.creditCardId = null),
          (showModalMatchTransaction.cardnumber = null),
        ]
      "
      :cardNumber="showModalMatchTransaction.cardnumber"
    />
  </div>
</template>
<script>
import DashboardService from "@/views/ceo/service-dashboard/dashboard.service";
import Fields from "@/views/ceo/dashboard/modals/expenses-request/data/expenses-by-card.fields.js";
import TotalsByModuleModal from "@/views/ceo/dashboard/modals/expenses/TotalsByModule.vue";
import ImportedTransactionsModal from "@/views/commons/components/credit-cards-expenses/modals/ImportedTransactionsModal.vue";
import moment from "moment";
import UnMatchedTransactionsModal from "@/views/ceo/dashboard/modals/expenses/expenses-transactions-unmatched/UnMatchedTransactionsModal.vue";
import ExpensesRequestDetail from "@/views/ceo/dashboard/modals/expenses-request/ExpensesRequestDetail.vue";
import MatchTransactionModal from "@/views/ceo/dashboard/modals/expenses/MatchTransactionModal.vue";

export default {
  name: "TotalsByCardModal",
  components: {
    TotalsByModuleModal,
    ImportedTransactionsModal,
    UnMatchedTransactionsModal,
    ExpensesRequestDetail,
    MatchTransactionModal,
  },
  data() {
    return {
      showImportedTransactions: false,
      dataRow: {},
      showModal: false,
      expenses: [],
      fields: Fields,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      dataYear: null,
      dataMonth: null,
      type: null,
      cpyMonth: null,
      cpyYear: null,
      useMonthFilter: false,
      itemSelected: null,
      showTotalsByModuleModal: false,
      creditCards: [],
      modalTransactionUnMatched: {
        show: false,
        item: null,
      },
      showExpensesRequestDetailModal: false,
      showModalMatchTransaction: {
        show: false,
        month: null,
        year: null,
        creditCardId: null,
        cardnumber: null,
      },
    };
  },
  props: {
    sendYear: {
      type: Number,
      required: true,
    },
    sendMonth: {
      type: Number,
      required: true,
    },
  },
  async created() {
    this.initMonthYear();
    await this.getTotalsByCreditCard();
    this.showModal = true;
  },
  computed: {
    titleMonthYear() {
      return `${this.months[this.dataMonth - 1]} ${this.dataYear}`;
    },
    disableNext() {
      const now = moment();
      const sameYear = now.year() === this.dataYear;
      const sameMonth = now.month() + 1 === this.dataMonth;
      return sameYear && sameMonth;
    },

    totalGeneralSoft() {
      return this.expenses.reduce(
        (acum, item) => acum + parseFloat(item.total_soft),
        0
      );
    },
    totalGeneralImport() {
      return this.expenses.reduce(
        (acum, item) => acum + parseFloat(item.total_import),
        0
      );
    },
    totalGeneralMatched() {
      return this.expenses.reduce(
        (acum, item) => acum + parseFloat(item.total_matched),
        0
      );
    },
    totalGeneralUnmatched() {
      return this.expenses.reduce(
        (acum, item) =>
          acum +
          parseFloat(item.total_unmatched) +
          parseFloat(item.total_exp_dept_unmatched),
        0
      );
    },
    generateYears() {
      const years = [];
      const currentYear = new Date().getFullYear();
      for (let i = currentYear; i >= 2023; i--) {
        years.push(i);
      }
      return years;
    },
  },
  methods: {
    totalUnmatched(item) {
      return (
        parseFloat(item.total_unmatched) +
        parseFloat(item.total_exp_dept_unmatched)
      );
    },
    openImportedTransactionsModal(data) {
      this.showImportedTransactions = true;
      this.dataRow = data;
    },
    async getTotalsByCreditCard() {
      try {
        this.addPreloader();
        const params = {
          month: this.dataMonth,
          year: this.dataYear,
        };
        const data = await DashboardService.getTotalsByCreditCard(params);
        this.expenses = data;
        this.creditCards = data.map((item) => {
          return {
            id: item.credit_card_expense_id,
            cardname: item.cardholdername,
            cardnumber: item.cardnumber,
            bank: item.bank,
          };
        });
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async updateUnmatchedTransactions() {
      try {
        this.addPreloader();
        const params = {
          year: this.dataYear,
          month: this.dataMonth,
        };
        const { data } = await DashboardService.updateUnmatchedTransactions(
          params
        );
        if (data.success) {
          this.showToast(
            "success",
            "top-right",
            "Congratulations",
            "CheckIcon",
            "Transactions updated successfully"
          );
          this.getTotalsByCreditCard();
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    openTotalsByModuleModal(item) {
      this.itemSelected = item;
      this.showTotalsByModuleModal = true;
    },
    async prev() {
      if (this.dataMonth == null) this.dataMonth = this.cpyMonth;
      if (this.dataMonth > 1) {
        this.dataMonth--;
      } else {
        this.dataMonth = 12;
        this.dataYear--;
      }
      await this.refresh();
    },
    async next() {
      if (this.dataMonth == null) this.dataMonth = this.cpyMonth;
      if (this.disableNext) return;
      if (this.dataMonth < 12) {
        this.dataMonth++;
      } else {
        this.dataMonth = 1;
        this.dataYear++;
      }
      await this.refresh();
    },
    initMonthYear() {
      this.dataMonth = this.sendMonth;
      this.dataYear = this.sendYear;
    },
    reload(reloadData) {
      this.dataMonth = reloadData.month;
      this.dataYear = reloadData.year;
      this.refresh();
    },
    async refresh() {
      this.getTotalsByCreditCard();
    },
    close() {
      this.$emit("close");
    },
    openUnMatchedTransactionModal(item) {
      this.modalTransactionUnMatched.show = true;
      this.modalTransactionUnMatched.item = { ...item };
    },
    openDetailRequest(item) {
      this.showExpensesRequestDetailModal = true;
    },
    async getRequestExpenses() {
      try {
        const { data } = await DashboardService.getRequestExpenses();
        this.expensesRequest = data;
      } catch (error) {
        console.log(error);
      }
    },
    openMatchTransactionModal(item) {
      this.showModalMatchTransaction.show = true;
      this.showModalMatchTransaction.creditCardId = item.credit_card_expense_id;
      this.showModalMatchTransaction.month = this.dataMonth;
      this.showModalMatchTransaction.year = this.dataYear;
      this.showModalMatchTransaction.cardnumber = item.cardnumber;
    },
  },
};
</script>
<style lang="scss" scoped>
.dark-select {
  background-color: #333;
  color: #fff;
}
.dark .absolute-linker {
  background-color: #444;
}
.disabled-next {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
