<template>
  <div>
    <b-modal
      v-model="onControl"
      header-bg-variant="transparent border-bottom border-bottom-2"
      size="caro"
      header-class="p-0"
      title="Sentiment Analysis of Calls"
      modal-class="modal-primary"
      hide-footer
      scrollable
      @hidden="close()"
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <span>Sentiment Analysis of Calls</span>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="$emit('close')"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>
      <div>
        <CallImpressionGrid />
      </div>
    </b-modal>
  </div>
</template>

<script> 
import CallImpressionGrid from "@/views/commons/call-impression/components/CallImpressionGrid.vue";

export default {
  components: { 
    CallImpressionGrid,
  },
  props: { 
  },
  data() {
    return { 
      onControl: false,
    };
  },

  computed: { 
  },
  watch: {},
  mounted() { 
  },
  async created() {
    this.onControl = true;
  },
  methods: {
    close() {
      this.onControl = false;
      this.$emit("close");
    },  
  },
};
</script>
<style lang="scss" scoped> 
.header-modal {
    position: relative;
    padding: 20px 0 20px 15px;
    background: rgba(22, 84, 204, 1);
    width: 100%;
    font-size: 22px;
    display: flex;

    span {
      font-size: 1.6rem;
      font-weight: bold;
      margin-left: 8px;
      color: #ffffff;
    }

    .container-icons {
      background-color: #ffffff;
      padding: 2.7px 6.37px;
      border-radius: 0.357rem;
      width: 31px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 15px;
      right: 20px;
      transition: all 0.23s ease 0.1s;

      &--dark {
        background-color: #17171a;
      }

      &:hover {
        transform: translate(-7px, 5px);
        cursor: pointer;
      }
    }
  }
</style> 