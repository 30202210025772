export default [
  {
    key: "full_name_client",
    label: "Client",
    thClass: "text-left",
    tdClass: "text-left",
    visible: true,
  },
  {
    key: "creditor_name",
    label: "Account",
    thClass: "text-left",
    tdClass: "text-left",
    visible: true,
  },
  {
    key: "negotiator_name",
    label: "Negotiator",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    key: "balance",
    label: "Balance",
    thClass: "text-right",
    tdClass: "text-right",
    visible: true,
  },
  {
    key: "offer_amount",
    label: "Offer Amount",
    thClass: "text-right",
    tdClass: "text-right",
    visible: true,
  },
  {
    key: "payment_date",
    label: "Payment Date",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    key: "created_at",
    label: "Offer Created",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    key: "send_to",
    label: "Send By",
    thClass: "text-center",
    tdClass: "text-center",
    visible: false,
  },
  {
    key: "result",
    label: "Offer Result",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    key: "process",
    label: "Process",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    key: "show",
    label: "Show",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
];
