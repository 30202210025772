<template>
  <b-modal
    ref="myModal"
    v-model="onControl"
    header-bg-variant="transparent border-bottom border-bottom-2"
    size="xmd"
    header-class="p-0"
    title="Client Payments Detail"
    modal-class="modal-primary"
    hide-footer
    scrollable
    @hidden="close"
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <span> Client Payments Details ({{ titleMonthYear }})</span>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="close"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>

    <filter-slot
      :filter="filter"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refTableIncomesDetail'].refresh()"
      class="filter-slot-new-customization"
    >
      <template #table>
        <b-table
          ref="refTableIncomesDetail"
          no-border-collapse
          class="position-relative table-new-customization"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="searchDetailIncomes"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #head(program_id)="data">
            <div class="text-left">
              {{ data.label }}
            </div>
          </template>
          <template #head(client_name)="data">
            <div class="text-left">
              {{ data.label }}
            </div>
          </template>
          <template #head(payment_method)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(amount)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(created_at)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>

          <template #cell(program_id)="data">
            <div class="charge__programs">
              <img
                :src="imgPrograms(data.item.program_id)"
                alt="img_services"
              />
            </div>
          </template>
          <template #cell(client_name)="data">
            <div class="align-middle text-left">
              <div v-if="data.item.client_name">
                <router-link
                  class
                  target="_blank"
                  :to="
                    routers(data.item.program_id, data.item.client_account_id)
                  "
                >
                  {{ data.item.client_name }} <br />
                  {{ data.item.account }}
                </router-link>
              </div>
              <div v-else>
                {{ "Unknown" }}
              </div>
            </div>
          </template>
          <template #cell(payment_method)="data">
            <div class="align-middle text-center">
              {{ data.item.payment_method }}
              <span v-if="data.item.transaction_type !== ''">
                ( {{ data.item.transaction_type }} )
              </span>
            </div>
          </template>
          <template #cell(amount)="data">
            <div class="align-middle text-center text-money text-no-wrap">
              {{ data.item.amount | currency }}
            </div>
          </template>
          <template #cell(created_at)="data">
            <div class="align-middle text-center">
              {{ data.item.created_at | myGlobalWithHour }}
            </div>
          </template>
          <template #bottom-row>
            <b-th colspan="2" class="fill-bottom-row" />
            <b-th>
              <b-badge
                variant="primary"
                class="w-100 text-center"
                style="font-size: 14px; font-weight: 600"
              >
                TOTAL
              </b-badge>
            </b-th>
            <b-th>
              <b-badge
                variant="primary"
                class="w-100 text-center"
                style="font-size: 14px; font-weight: 600"
              >
                {{ "$ " }}{{ totalAmount | currencyZero }}
              </b-badge>
            </b-th>
            <b-th class="fill-bottom-row" colspan="2" />
          </template>
        </b-table>
      </template>
    </filter-slot>
  </b-modal>
</template>

<script>
import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service";
import ProgramsFilter from "@/views/ceo/dashboard/data/programs-filter.js";
import { mapGetters } from "vuex";

export default {
  props: {
    sendYear: {
      type: Number,
      default: null,
    },
    sendMonth: {
      type: Number,
      default: null,
    },
    sendPrograms: {
      type: Array,
      required: false,
      default: [],
    },
  },
  data() {
    return {
      /* eslint-disable global-require */
      imgServices: require("@/assets/images/icons/dashboard-ceo/services.svg"),
      imgDebtSolution: require("@/assets/images/icons/dashboard-ceo/logo-debt-solution.svg"),
      imgBoostCredit: require("@/assets/images/icons/dashboard-ceo/logo-boost-credit.svg"),
      imgCreditExperts: require("@/assets/images/icons/dashboard-ceo/logo-credit-experts.svg"),
      imgBusiness: require("@/assets/images/icons/dashboard-ceo/logo-business.svg"),
      imgTaxResearch: require("@/assets/images/icons/dashboard-ceo/logo-tax-research.svg"),
      imgSpecialist: require("@/assets/images/icons/dashboard-ceo/logo-specialist.svg"),
      imgKeyBook: require("@/assets/images/icons/dashboard-ceo/logo-keybook.svg"),
      onControl: false,
      startPage: 0,
      toPage: 0,
      isBusy: false,
      dataYear: null,
      dataMonth: null,
      titleModal: "",
      idPrograms: null,
      initialProgram: "",
      sortBy: "client_name",
      sortDesc: true,
      filter: ProgramsFilter,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by client name...",
        model: "",
      },
      fields: [
        {
          key: "program_id",
          sortable: false,
          label: "Program",
        },
        {
          key: "client_name",
          sortable: false,
          label: "Client",
        },
        {
          key: "payment_method",
          sortable: false,
          label: "Payment",
        },
        {
          key: "amount",
          sortable: false,
          label: "Amount",
        },
        {
          key: "created_at",
          sortable: false,
          label: "Created at",
        },
      ],
      salesDetailItems: [],
      totalRows: 1,
      totalAmount: 0,
      paginate: {
        perPage: 50,
        currentPage: 1,
      },
      typeExpenses: "",
    };
  },

  computed: {
    ...mapGetters({
      titleMonthYear: "DashboardCeoStore/G_TITLE_MODAL",
    }),
  },

  async created() {
    this.onControl = true;
    this.dataMonth = this.sendMonth;
    this.dataYear = this.sendYear;
    this.filter[0].options = this.sendPrograms;
  },

  methods: {
    close() {
      this.$refs["myModal"].hide();
      this.$emit("close");
    },

    async searchDetailIncomes(ctx) {
      const { data } = await DashboardServive.getDetailsIncomes({
        year: this.dataYear,
        type: "clients_payments",
        program_id: this.filter[0].model,
        search_text: this.filterPrincipal.model,
        month: this.dataMonth,
        page: ctx.currentPage,
        perpage: ctx.perPage,
      });

      this.salesDetailItems = data.data;
      this.startPage = data.from;
      this.paginate.currentPage = data.current_page;
      this.paginate.perPage = data.per_page;
      this.totalRows = data.total;
      this.toPage = data.to;
      this.totalAmount = this.salesDetailItems[0].total_amount;
      return this.salesDetailItems || [];
    },

    routers(program, id) {
      let route = "";
      switch (program) {
        case 1:
          route = `/bussiness/clients/account/${id}`;
          break;
        case 2:
          route = `/boostcredit/clients/account/${id}`;
          break;
        case 3:
          route = `/creditexperts/clients/account/${id}`;
          break;
        case 4:
          route = `/debtsolution/clients/account/${id}`;
          break;
        case 5:
          route = `/taxresearch/clients/account/${id}`;
          break;
        case 7:
          route = `/specialists/clients/account/${id}`;
          break;
        case 8:
          route = `/bookeeping/clients/account/${id}`;
          break;
        case 9:
          route = `/ti/clients/account/${id}`;
          break;
      }
      return route;
    },
    imgPrograms(programId) {
      switch (programId) {
        case 1:
          return this.imgBusiness;
        case 2:
          return this.imgBoostCredit;
        case 3:
          return this.imgCreditExperts;
        case 4:
          return this.imgDebtSolution;
        case 5:
          return this.imgTaxResearch;
        case 7:
          return this.imgSpecialist;
        case 8:
          return this.imgKeyBook;
        default:
          return this.imgKeyBook;
      }
    },
  },
};
</script>
  <style lang="scss" scoped>
.text-money {
  font-weight: 500;

  &::before {
    content: "$";
  }
}

.header-modal {
  position: relative;
  padding: 20px 0 20px 15px;
  background: rgba(22, 84, 204, 1);
  width: 100%;
  font-size: 22px;
  display: flex;
  justify-content: space-betweeen;

  span {
    font-size: 1.6rem;
    font-weight: bold;
    margin-left: 8px;
    color: #ffffff;
  }

  .container-icons {
    background-color: #ffffff;
    padding: 2.7px 6.37px;
    border-radius: 0.357rem;
    width: 31px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    transition: all 0.23s ease 0.1s;

    &--dark {
      background-color: #17171a;
    }

    &:hover {
      transform: translate(-7px, 5px);
      cursor: pointer;
    }
  }
}

.charge__programs {
  width: 47px;
  height: 40px;
  background-color: #e9e9e9;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 35px;
    height: 35px;
  }
}

.b-table-bottom-row th:nth-child(2) {
  border-radius: 0px 0px 0px 15px !important;
  margin-left: 5px;
  background: #0090e7 !important;
}

.b-table-bottom-row th:nth-child(3) {
  border-radius: 0px 0px 15px 0px !important;
  margin-left: -10px;
  background: #0090e7 !important;
}
</style>
