import { amgApi } from "@/service/axios";

class PaymentSolutionsService {
  async getCreditCards(params) {
    try {
      return await amgApi.post("ceo/dashboard/expenses/settings/credit-cards/index", params);
    } catch (error) {
      throw error;
    }
  }

  async getModules() {
    try {
      return await amgApi.get("new-commons/all-modules/index");
    } catch (error) {
      throw error;
    }
  }

  async saveCreditCards(params) {
    try {
      return await amgApi.post("ceo/dashboard/expenses/settings/credit-cards/save", params);
    } catch (error) {
      throw error;
    }
  }

  async deleteCreditCard(params) {
    try {
      return await amgApi.post("ceo/dashboard/expenses/settings/credit-cards/delete", params);
    } catch (error) {
      throw error;
    }
  }

  async UpdateCreditCard(params) {
    try {
      return await amgApi.post("ceo/dashboard/expenses/settings/credit-cards/update", params);
    } catch (error) {
      throw error;
    }
  }
}

export default new PaymentSolutionsService();