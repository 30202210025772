<template>
  <div class="card-others">
    <template v-for="(item, index) in others">
      <div :key="index" class="w-100" @click="$emit('open', item)">
        <body-header-card
          :title="item.title"
          :amount="item.amount"
          :amount-color="item.amountColor"
          :icon-name="item.iconName"
          :bg-color-icon="item.bgColorIcon"
        />
      </div>
    </template>
  </div>
</template>
<script>
import BodyHeaderCard from "@/views/debt-solution/views/dashboard/components/BodyHeaderCard.vue";
import DashboardService from "@/views/debt-solution/views/dashboard/service/dashboard.service";

export default {
  components: {
    BodyHeaderCard,
  },
  props: {
    programId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      others: [
        {
          title: "OTHER FEES",
          amount: 0,
          iconName: "others-fee",
          bgColorIcon: "bg-success",
          type: 1,
        },
        {
          title: "VOID / REFUND",
          amount: 0,
          iconName: "others-void-refund",
          bgColorIcon: "bg-warning",
          amountColor: "text-danger",
          type: 3,
        },
        {
          title: "CHARGE BACK",
          amount: 0,
          iconName: "others-charge-back",
          bgColorIcon: "bg-danger",
          amountColor: "text-danger",
          type: 2,
        },
      ],
    };
  },
  created() {
    this.getOthers();
  },
  methods: {
    async getOthers() {
      try {
        this.addPreloader();
        const params = {
          program_id: this.programId,
        };
        const { data } = await DashboardService.getDebtSolutionOthers(params);
        this.others[0].amount = data.additional_charges;
        this.others[1].amount = this.formatNumber(data.void_refund) * -1;
        this.others[2].amount = this.formatNumber(data.charge_backs) * -1;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    formatNumber(number) {
      if (typeof number === "string") {
        number = number.replace(/,/g, "");
      }
      return parseFloat(number);
    },
  },
};
</script>
<style lang="scss">
.card-others {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
</style>
