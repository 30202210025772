<template>
  <div class="card-settlements">
    <div class="settlement-total">
      <div class="settlement-total-title"></div>
      <div class="cards-settlement-total">
        <template v-for="(item, index) in settlementsTotal">
          <div :key="index" class="w-100" @click="$emit('open', item)">
            <body-header-card
              :title="item.title"
              :amount="item.amount"
              :icon-name="item.iconName"
              :bg-color-icon="item.bgColorIcon"
            />
          </div>
        </template>
      </div>
    </div>
    <div class="total-cost">
      <div class="total-cost-title">TOTAL COST</div>
      <div class="cards-total-cost">
        <template v-for="(item, index) in settlementsCost">
          <div :key="index" class="w-100" @click="$emit('open', item)">
            <body-header-card
              :title="item.title"
              :amount="item.amount"
              :iconName="item.iconName"
              :bgColorIcon="item.bgColorIcon"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import BodyHeaderCard from "@/views/debt-solution/views/dashboard/components/BodyHeaderCard.vue";
import DashboardService from "@/views/debt-solution/views/dashboard/service/dashboard.service.js";
export default {
  components: {
    BodyHeaderCard,
  },
  data() {
    return {
      settlements: [
        {
          title: "TOTAL AMOUNT",
          amount: 0,
          iconName: "settlements-total",
          bgColorIcon: "bg-primary",
        },
        {
          title: "SETTLEMENT COST",
          amount: 0,
          iconName: "settlements-cost",
          bgColorIcon: "bg-success",
        },
        {
          title: "SETTLEMENT FEE",
          amount: 0,
          iconName: "settlements-fee",
          bgColorIcon: "bg-warning",
        },
      ],
    };
  },
  computed: {
    settlementsTotal() {
      return this.settlements.filter(
        (settlement) => settlement.iconName === "settlements-total"
      );
    },
    settlementsCost() {
      return this.settlements.filter(
        (settlement) => settlement.iconName !== "settlements-total"
      );
    },
  },
  created() {
    this.getSettlements();
  },
  methods: {
    async getSettlements() {
      try {
        this.addPreloader();
        const { data } = await DashboardService.getDebtSolutionSettlementAmounts();
        this.settlements[0].amount = data.paid;
        this.settlements[1].amount = data.total_cost;
        this.settlements[2].amount = data.fee;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
<style lang="scss">
.card-settlements {
  display: flex;
  gap: 1rem;

  .settlement-total {
    width: 33.33%;
    .settlement-total-title {
      margin-bottom: 2.7rem;
    }
    .cards-settlement-total {
      padding-top: 0.3rem;
      .body-header-card {
        padding: 1.2rem 1rem;
      }
    }
  }
  .total-cost {
    width: 66.67%;
    .total-cost-title {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #3a72ea;
      color: #ffffff;
      font-size: 1rem;
      font-weight: 600;
      padding: 0.5rem;
      border-radius: 0.3rem 0.3rem 0 0;
    }
    .cards-total-cost {
      display: flex;
      gap: 1rem;
      padding-top: 0.5rem;
    }
  }
}
</style>