<template>
  <div>
    <!-- credit card to department expenses -->
    <div class="d-flex justify-content-end mb-2" style="gap: 10px">
      <b-button variant="primary" @click="creditCardModal()">
        Add Credit Card
      </b-button>
      <b-button variant="outline-success" @click="openImportTransactions()">
        <div class="d-flex align-items-center" style="gap: 10px">
          <feather-icon icon="UploadIcon" />
          <span>Import Transactions</span>
        </div>
      </b-button>
      <b-button
        variant="info"
        class="p-1"
        v-b-tooltip.hover.top="'History of transaction imports'"
        @click="showHistoryImports = true"
      >
        <feather-icon size="18" icon="ListIcon" />
      </b-button>
    </div>

    <b-tabs
      active-tab-class="p-0 "
      pills
      nav-class="mb-0 mt-1"
      active-nav-item-class="bg-primary box-shadow-primary border-primary primary"
    >
      <b-tab
        v-for="(title, index) in ['Active', 'Deleted']"
        :key="index"
        :title="title"
        :active="index === 0"
        class="mb-1"
        @click="changeTab(index + 1)"
      >
        <div class="border-top-primary border-3 border-table-radius px-0 mb-1">
          <b-table
            :items="creditCards"
            :fields="visibleFields"
            sticky-header="50vh"
            class="position-relative table-new-customization"
              show-empty
          >
            <template #cell(cardholdername)="{ item }">
              <div class="text-warning">
                {{ item.cardholdername }}
              </div>
            </template>
            <template #cell(cardsecuritycode)="{ value }">
              <span>{{ value }}</span>
            </template>
            <template #cell(expiration_date)="{ item }">
              <p>{{ item.card_expi_month }}/{{ item.card_expi_year }}</p>
            </template>
            <template #cell(total)="{ item }">
              <b-badge
                class="clickable"
                @click="openDetail(item)"
                variant="primary"
                >{{ item.total }}</b-badge
              >
            </template>
            <template #cell(actions)="{ item }">
              <feather-icon
                icon="EditIcon"
                class="cursor-pointer text-warning"
                v-b-tooltip.hover.top="'Edit credit card'"
                @click="creditCardModal(item)"
              />
              <feather-icon
                icon="TrashIcon"
                class="cursor-pointer text-danger ml-1"
                v-b-tooltip.hover.top="'Delete credit card'"
                @click="deleteCreditCard(item)"
              />
            </template>
            <template #cell(module_name)="{ item }">
              <div class="row justify-content-center align-items-center">
                <template v-for="modules in JSON.parse(item.module_names)">
                  <div class="d-flex col-1 mt-1" :key="modules">
                    <img
                      v-b-tooltip.hover.top="modules.name"
                      :src="`/assets/${modules.icon}`"
                      alt="Logo"
                      height="30"
                      width="30"
                    />
                  </div>
                </template>
              </div>
            </template>
            <template #cell(created_at)="{ item }">
              {{ item.created_at | myGlobalDay }}
            </template>
            <template #cell(deleted_at)="{ item }">
              {{ item.deleted_at | myGlobal }}
            </template>
            <template v-slot:cell(type_card)="data">
              <type-card :type_card="data.item.type_card" />
            </template>
          </b-table>
        </div>
      </b-tab>
    </b-tabs>

    <credit-card-modal
      v-if="showModal"
      :dataCard="dataCard"
      @refresh="getCreditCards"
      @close="showModal = false"
    />
    <ImportTransactions
      v-if="showModalImport"
      @close="showModalImport = false"
      @refresh="getCreditCards"
    />
    <ImportTransactionHistory
      v-if="showHistoryImports"
      @close="closeModalHistory()"
      :dataRow="dataRow"
      :modalOrigin="validateModal"
    />
  </div>
</template>
<script>
import PaymentSolutionsServices from "@/views/commons/components/credit-cards-expenses/services/payment-solutions.services.js";
import Fields from "@/views/commons/components/credit-cards-expenses/data/credit-cards.fields.js";
import CreditCardModal from "@/views/commons/components/credit-cards-expenses/modals/CreditCardModal.vue";
import ImportTransactions from "@/views/commons/components/credit-cards-expenses/modals/ImportTransactions.vue";
import TypeCard from "@/views/commons/card/TypeCard.vue";
import ImportTransactionHistory from "@/views/commons/components/credit-cards-expenses/modals/ImportTransactionHistory.vue";

import { mapGetters } from "vuex";
export default {
  components: {
    CreditCardModal,
    TypeCard,
    ImportTransactions,
    ImportTransactionHistory,
  },
  data() {
    return {
      showHistoryImports: false,
      creditCards: [],
      fields: Fields,
      showModal: false,
      showModalImport: false,
      dataCard: null,
      tab: 1,
      countPending: 0,
      transactions: [],
      popoverContent: "",
      showDetail: false,
      dataRow: {},
      validateModal: false
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    visibleFields() {
      this.filterColumn("actions", this.tab == 1);
      this.filterColumn("deleted_at", this.tab == 2);
      return this.fields.filter((item) => item.visible);
    },
  },
  async created() {
    await this.getCreditCards();
  },
  methods: {
    async getCreditCards() {
      const params = {
        moduleId: this.currentUser.modul_id,
        tab: this.tab,
      };
      try {
        this.addPreloader();
        const { data } = await PaymentSolutionsServices.getCreditCards(params);
        this.creditCards = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async changeTab(tab) {
      this.tab = tab;
      await this.getCreditCards();
    },
    async deleteCreditCard(item) {
      const confirm = await this.showConfirmSwal(
        `Are you sure you want to delete the credit card: <strong> ${item.cardholdername}</strong>?`
      );
      if (!confirm.isConfirmed) return;

      const params = {
        id: item.id,
        user: this.currentUser.user_id,
      };
      try {
        this.addPreloader();
        const { data } = await PaymentSolutionsServices.deleteCreditCard(
          params
        );
        if (data.success) {
          this.showToast(
            "success",
            "top-right",
            "Success",
            "CheckIcon",
            "Credit Card deleted successfully"
          );
          this.getCreditCards();
        } else {
          this.showToast(
            "danger",
            "top-right",
            "Error",
            "XIcon",
            "Something went wrong!"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    creditCardModal(val = null) {
      this.dataCard = val;
      this.showModal = true;
    },
    openImportTransactions() {
      this.showModalImport = true;
      this.validateModal = true
    },
    filterColumn(key, bool) {
      let field = this.fields.find((field) => field.key == `${key}`);
      field.visible = bool;
    },
    openDetail(data) {
      this.showHistoryImports = true;
      this.dataRow = data;
      this.validateModal = false
    },
    closeModalHistory(){
      this.showHistoryImports = false;
      this.validateModal = true
    }
  },
};
</script>
