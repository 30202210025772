import { render, staticRenderFns } from "./SalesModal.vue?vue&type=template&id=2b9b0c55"
import script from "./SalesModal.vue?vue&type=script&lang=js"
export * from "./SalesModal.vue?vue&type=script&lang=js"
import style0 from "./SalesModal.vue?vue&type=style&index=0&id=2b9b0c55&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports