import { render, staticRenderFns } from "./DetailedSales.vue?vue&type=template&id=2412f523&scoped=true"
import script from "./DetailedSales.vue?vue&type=script&lang=js"
export * from "./DetailedSales.vue?vue&type=script&lang=js"
import style0 from "./DetailedSales.vue?vue&type=style&index=0&id=2412f523&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2412f523",
  null
  
)

export default component.exports