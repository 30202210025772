<template>
  <div>
    <b-list-group>
      <template v-if="!loading">
        <b-list-group-item
          v-for="notification in S_ALL_OFFER_NOTIFICATIONS"
          :key="notification.id"
          @click="
            currentUser.role_id == 1
              ? resumeOfferModal(notification)
              : settlementOfferModal(notification)
          "
        >
          <div class="d-flex w-100 justify-content-between">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                  :class="notification.status == 1 ? '' : 'cursor-pointer'"
                  size="30"
                  :variant="
                    notification.status == 1 ? 'light-primary' : 'primary'
                  "
                >
                  <feather-icon size="15" icon="DollarSignIcon" />
                </b-avatar>
              </b-media-aside>

              <b-media-body class="my-auto">
                <b-link
                  :class="[
                    'font-weight-bold mb-0 h5',
                    notification.status == 0
                      ? skin == 'dark'
                        ? 'text-light'
                        : 'text-dark'
                      : '',
                  ]"
                  class="d-flex"
                >
                  <p
                    v-html="notification.notification"
                    style="line-height: 1.8; font-size: 14px"
                  ></p>
                  <span
                    v-if="notification.status == 0"
                    class="float-right cursor-pointer unread-notification ml-1"
                    style="margin-top: 3px"
                  >
                    <!-- <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                    >
                      <circle
                        id="Ellipse_1"
                        data-name="Ellipse 1"
                        cx="5"
                        cy="5"
                        r="5"
                        fill="#00b5b8"
                      />
                    </svg> -->
                  </span>
                </b-link>
              </b-media-body>
            </b-media>
            <small>{{ notification.created_at | myDateGlobalWithHour }}</small>
          </div>
        </b-list-group-item>
      </template>
      <template v-else>
        <b-list-group-item
          :key="notification.id"
          v-for="notification in S_ALL_OFFER_NOTIFICATIONS.length"
        >
          <div class="d-flex w-100 justify-content-between align-items-center">
            <b-media no-body class="w-100">
              <b-media-aside class="mr-2" width="100%">
                <b-skeleton type="avatar" size="30px"></b-skeleton>
              </b-media-aside>
              <b-media-body class="my-auto">
                <b-skeleton style="margin-top: 9px"></b-skeleton>
              </b-media-body>
            </b-media>
            <small></small>
          </div>
        </b-list-group-item>
      </template>
    </b-list-group>
    <div class="mx-2 mb-2 mt-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"
            >Showing {{ startPage }} to {{ toPage }} of
            {{ totalData }} entries</span
          >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalData"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <OfferResumeModal
      :show="showResumeModal"
      :info="resumeModalInfo"
      @closing="closeResumeModal"
    ></OfferResumeModal>

    <SettlementOfferModal
      v-if="showSettlementOfferModal"
      :offerInfo="offerInfo"
      @closing="closeSettlementOfferModal"
    ></SettlementOfferModal>
  </div>
</template>

<script>
import SettlementOfferModal from "@/views/debt-solution/views/settlement-offer/SettlementOfferModal.vue";
import OfferResumeModal from "@/views/management/views/approval-process/settlement-approval/components/ResumedOfferModal.vue";
import PriorityOfferService from "@/views/debt-solution/views/priority-offers/services/priorityOffersService.js";

import { mapActions, mapGetters, mapState } from "vuex";
export default {
  components: {
    OfferResumeModal,
    SettlementOfferModal,
  },
  async mounted() {
    await this.getAllOfferNotifications();
  },
  data() {
    return {
      showSettlementOfferModal: false,
      offerInfo: {},
      showResumeModal: false,
      resumeModalInfo: {},

      notificationModal: false,
      accountsHoldModal: false,
      selectedNotification: {},

      currentPage: 1,
      perPage: 7,
      totalData: 0,
      startPage: 0,
      toPage: 0,
      loading: false,
      readNotification: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      skin: "appConfig/skin",
    }),
    ...mapState({
      S_ALL_OFFER_NOTIFICATIONS: (state) =>
        state.OfferNotificationStore.S_ALL_OFFER_NOTIFICATIONS,
    }),
  },
  methods: {
    closeSettlementOfferModal() {
      this.showSettlementOfferModal = false;
      // list notifications to change status of read
      this.A_GET_ALL_OFFER_NOTIFICATIONS({
        id: this.currentUser.role_id,
        page: this.currentPage,
        action: "offer",
      });
    },
    // show to supervisor
    async settlementOfferModal(notification) {
      const { data } = await PriorityOfferService.getOfferById(
        notification.link
      );
      this.offerInfo = {
        mode: "watch",
        client: data[0].client,
        creditorName: data[0].creditor,
        creditorAccount: data[0].creditor_account,
        creditorId: data[0].creditor_id,
        balance: data[0].balance,
        id: data[0].id,
        clientAccountId: data[0].client_account_id,
        creditor_id: data[0].ds_credit_id,
        last_comment: data[0].last_comment,
      };
      this.showSettlementOfferModal = true;
    },

    // show to ceo
    async resumeOfferModal(notification) {
      const { data } = await PriorityOfferService.getOfferById(
        notification.link
      );
      console.log(data);
      this.resumeModalInfo = { ...data[0] };
      this.showResumeModal = true;
    },

    closeResumeModal() {
      // list notifications to change status of read
      this.A_GET_ALL_OFFER_NOTIFICATIONS({
        id: this.currentUser.role_id,
        page: this.currentPage,
        action: "offer",
      });
      this.showResumeModal = false;
    },

    ...mapActions({
      A_GET_ALL_OFFER_NOTIFICATIONS:
        "OfferNotificationStore/A_GET_ALL_OFFER_NOTIFICATIONS",
    }),
    async getAllOfferNotifications(page = 1) {
      this.loading = true;
      try {
        const data = await this.A_GET_ALL_OFFER_NOTIFICATIONS({
          id: this.currentUser.role_id,
          page,
          action: "offer",
        });
        this.currentPage = data.current_page;
        this.perPage = data.per_page;
        this.totalData = data.total;
        this.startPage = data.from;
        this.toPage = data.to;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
  },
  watch: {
    currentPage(newVal, oldVal) {
      this.getAllOfferNotifications(newVal);
    },
  },
};
</script>

<style>
</style>
