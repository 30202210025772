<template>
  <div class="dashboard" :class="isDarkSkin ? 'dashboard--dark' : ''">
    <b-row>
      <b-col cols="12" xs="6" sm="6" md="6" lg="5" xl="5">
        <h1 class="dashboard__title">
          <amg-icon
            icon="AmgIcon"
            size="53"
            class="pointer"
            style="margin-right: 8px"
            @click="redirectToHome"
          />
          Welcome,<span class="dashboard__title--name">Roger Segura</span>
        </h1>
      </b-col>
      <b-col cols="12" xs="6" sm="6" md="6" lg="7" xl="7">
        <div class="d-flex w-100 h-100 align-items-center justify-content-end">
          <div
            class="text-secondary"
            style="padding-top: 3px; font-size: 12px; padding-right: 8px"
          >
            LAST UPDATE: {{ lastUpdate | myGlobalDay }}
          </div>
          <b-button
            variant="primary"
            class="btn-refresh mr-1"
            @click="refreshDashboard"
          >
            <feather-icon icon="RefreshCcwIcon" size="14" />
          </b-button>

          <v-select
            v-model="selectYear"
            :reduce="(user) => user.id"
            :options="years"
            :selectable="(option) => option.selectable === true"
            :clearable="false"
            label="name"
            style="width: 150px"
            class="mr-1"
          >
            <template #first>
              <b-form-select-option :value="null" disabled>
                Please select an option
              </b-form-select-option>
            </template>
          </v-select>

          <v-select
            v-model="selectMonth"
            :reduce="(user) => user.id"
            :options="months"
            :clearable="false"
            label="name"
            style="width: 190px"
          >
            <template #first>
              <b-form-select-option :value="null" disabled>
                Please select an option
              </b-form-select-option>
            </template>
          </v-select>
        </div>
      </b-col>
    </b-row>

    <div class="dashboard__container">
      <b-row>
        <b-col cols="12" xs="6" sm="6" md="3" lg="3" xl="3" class="d-flex">
          <card-expenses
            :key="dashboardRefresh"
            :select-year="selectYear"
            :select-month="selectMonth"
            :list-programs="listPrograms"
            :list-department="department"
            style="width: 100%"
          />
        </b-col>
        <b-col cols="12" xs="6" sm="6" md="3" lg="3" xl="3" class="d-flex">
          <card-incomes
            :key="dashboardRefresh"
            :select-year="selectYear"
            :select-month="selectMonth"
            :list-programs="listPrograms"
            style="width: 100%"
          />
        </b-col>
        <b-col cols="12" xs="6" sm="6" md="3" lg="3" xl="3" class="d-flex">
          <card-sales
            :key="dashboardRefresh"
            :select-year="selectYear"
            :select-month="selectMonth"
            :list-programs="listPrograms"
            style="width: 100%"
          />
        </b-col>
        <b-col cols="12" xs="6" sm="6" md="3" lg="3" xl="3" class="d-flex">
          <card-employee-turnover
            :key="dashboardRefresh"
            :select-year="selectYear"
            :select-month="selectMonth"
            style="width: 100%"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="9" lg="9" xl="9">
          <program-details
            :key="dashboardRefresh"
            :select-year="selectYear"
            :select-month="selectMonth"
            :list-programs="listPrograms"
          />
        </b-col>
        <b-col cols="12" xs="6" sm="6" md="3" lg="3" xl="3">
          <daily-settlement-dashboard />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
          <call-analysis
            :key="dashboardRefresh"
            :select-year="selectYear"
            :select-month="selectMonth"
            :list-programs="listPrograms"
            show-unnoted
            v-model="statusCh"
          />
        </b-col>
        <b-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
          <card-sentiment-analysis-of-messenger
            :key="dashboardRefresh"
            :select-year="selectYear"
            :select-month="selectMonth"
            :active-date="false"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" lg="6" class="mb-2">
          <card-ce-ds
            :key="dashboardRefresh"
            :select-year="selectYear"
            :select-month="selectMonth"
          />
        </b-col>
        <b-col cols="12" lg="6" class="mb-2">
          <card-debt-solution
            :key="dashboardRefresh"
            :select-year="selectYear"
            :select-month="selectMonth"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
          <sales-per-month
            :key="dashboardRefresh"
            :select-year="selectYear"
            :list-programs="listPrograms"
          />
        </b-col>
        <b-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
          <card-tasks :key="dashboardRefresh" />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
          <card-calls
            :key="dashboardRefresh"
            :selected-month="selectMonth"
            :selected-year="selectYear"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service";
import { mapActions } from "vuex";

import CardExpenses from "@/views/ceo/dashboard/CardExpenses.vue";
import CardIncomes from "@/views/ceo/dashboard/CardIncomes.vue";
import CardCeDs from "@/views/ceo/dashboard/CardCeDs.vue";
import CardMontlyPayments from "@/views/ceo/dashboard/CardCharge.vue";
import CardSales from "@/views/ceo/dashboard/CardSales.vue";
import CardEmployeeTurnover from "@/views/ceo/dashboard/CardEmployeeTurnover.vue";
import CardTasks from "@/views/ceo/dashboard/CardTasks.vue";
import CardCalls from "@/views/ceo/dashboard/CardCalls.vue";
import MarketingRoi from "@/views/ceo/dashboard/MarketingRoi.vue";
import SalesPerMonth from "@/views/ceo/dashboard/SalesPerMonth.vue";
import ClientsInHold from "@/views/ceo/dashboard/ClientsInHold.vue";
import ClientsNoPayment from "@/views/ceo/dashboard/ClientsNoPayment.vue";
import ContainerClientStatus from "@/views/ceo/dashboard/ContainerClientStatus.vue";
import DailySettlementDashboard from "@/views/commons/components/reports/tabs/daily-payment-report/DailySettlementDashboard.vue";
import ClientsAutomaticPayments from "@/views/ceo/dashboard/ClientsAutomaticPayments.vue";
import ProgramDetails from "@/views/ceo/dashboard/ProgramDetails.vue";
import CardDebtSolution from "@/views/ceo/dashboard/components/CardDebtSolution.vue";
import CardCallImpression from "@/views/ceo/dashboard/CardCallImpression.vue";
import CardSentimentAnalysisOfMessenger from "@/views/ceo/dashboard/CardSentimentAnalysisOfMessenger.vue";
import CallAnalysis from "@/views/ceo/dashboard/CallAnalysis.vue";

export default {
  components: {
    CardExpenses,
    CardIncomes,
    CardSales,
    CardCeDs,
    CardMontlyPayments,
    CardEmployeeTurnover,
    CardCalls,
    MarketingRoi,
    ContainerClientStatus,
    SalesPerMonth,
    CardTasks,
    ClientsInHold,
    ClientsNoPayment,
    DailySettlementDashboard,
    ClientsAutomaticPayments,
    ProgramDetails,
    CardDebtSolution,
    CallAnalysis,
    CardSentimentAnalysisOfMessenger,
    CardCallImpression,
  },

  data() {
    return {
      // valores
      selectMonth: null,
      selectYear: null,
      optionsMonth: [
        { id: 1, name: "January" },
        { id: 2, name: "February" },
        { id: 3, name: "March" },
        { id: 4, name: "April" },
        { id: 5, name: "May" },
        { id: 6, name: "June" },
        { id: 7, name: "July" },
        { id: 8, name: "August" },
        { id: 9, name: "September" },
        { id: 10, name: "October" },
        { id: 11, name: "November" },
        { id: 12, name: "December" },
      ],
      optionsYears: [],
      listPrograms: [],
      department: [],
      dashboardRefresh: 1,
      lastUpdate: null,
      menuHidden: this.$store.state.appConfig.layout.menu.hidden,
      today: new Date(),
      statusCh: {},
    };
  },

  computed: {
    months() {
      if (this.selectYear === this.today.getFullYear()) {
        return this.optionsMonth.filter(
          (month) => month.id <= this.today.getMonth() + 1
        );
      }
      return this.optionsMonth;
    },
    years() {
      // Reset selectable mode for all years
      this.optionsYears.forEach((year) => {
        year.selectable = true;
      });

      // Disable future years if the selected month is greater than the current month
      const currentMonth = this.today.getMonth() + 1;
      if (this.selectMonth > currentMonth) {
        const lastYearIndex = this.optionsYears.length - 1;
        this.optionsYears[lastYearIndex].selectable = false;
      }

      return this.optionsYears;
    },
  },

  watch: {
    selectMonth() {
      this.A_SET_MONTH_SELECTED(this.selectMonth);
    },

    selectYear() {
      this.A_SET_YEAR_SELECTED(this.selectYear);
    },
  },

  async created() {
    this.getLastUpdate();
    this.currentMonth();
    this.generateYears();

    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
    this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "sticky" });

    try {
      this.addPreloader();
      this.getPrograms();
      this.getDepartment();
    } catch (error) {
      console.log(error);
    } finally {
      this.removePreloader();
    }
  },

  destroyed() {
    this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", {
      type: "sticky",
    });
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", this.menuHidden);
    this.$store.commit("appConfig/UPDATE_LAYOUT_TYPE", "vertical");
  },
  methods: {
    currentMonth() {
      const today = new Date();
      const currentMonth = today.getMonth() + 1;
      this.selectMonth = currentMonth;
      this.A_SET_MONTH_SELECTED(this.selectMonth);
    },

    setCurrentYear() {
      const today = new Date();
      const currentYear = today.getFullYear();
      this.selectYear = currentYear;
      this.A_SET_YEAR_SELECTED(this.selectYear);
    },

    generateYears() {
      const today = new Date();
      const currentYear = today.getFullYear();
      const beginningYear = 2023;
      this.optionsYears = [];

      for (let i = beginningYear; i <= currentYear; i++) {
        this.optionsYears.push({ id: i, name: i, selectable: true });
      }

      this.selectYear = currentYear;
      this.A_SET_YEAR_SELECTED(this.selectYear);
    },

    decimalPart(number) {
      number = parseFloat(number);
      number = number.toFixed(2);
      const parts = number.split(".");

      if (number != 0) {
        const parts = number.split(".");
        if (parts.length > 1) {
          return parts[1];
        }
      } else {
        return "00";
      }
    },
    integerPart(number) {
      number = parseInt(number);
      return number;
    },

    async refreshDashboard() {
      try {
        this.addPreloader();
        const data = await DashboardServive.refreshDashboard({
          year: this.selectYear,
          month: this.selectMonth,
        });
        /*
        console.log("data DashboardServive.refreshDashboard ", data);
        */
        if (data.status === 200) {
          this.dashboardRefresh += 1;
          this.lastUpdate = data.data.last_update;
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async getLastUpdate() {
      try {
        const data = await DashboardServive.getLastUpdate();
        if (data.status === 200) {
          this.lastUpdate = data.data.last_update;
        }
        this.addPreloader();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async getPrograms() {
      try {
        const { data } = await DashboardServive.programs();
        const defaultOption = {
          id: null,
          name: "All programs",
          value: "All programs",
        };
        data.unshift(defaultOption);
        this.listPrograms = data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    redirectToHome() {
      window.open("/home", "_blank");
      //this.$router.push({ name: "amg-menu" });
    },

    async getDepartment() {
      const { data } = await DashboardServive.getDepartments();
      this.department = data.data;
    },
    ...mapActions({
      A_SET_MONTH_SELECTED: "DashboardCeoStore/A_SET_MONTH_SELECTED",
      A_SET_YEAR_SELECTED: "DashboardCeoStore/A_SET_YEAR_SELECTED",
    }),
  },
};
</script>
<style lang="scss" scoped>
$color-primary: #5f5f5f;
$color-secondary: #ffffff;
$bg-card-white: #ffffff;
$bg-card-dark: #111113;
$box-shadow-white: #00000026;

@mixin reset-pm {
  padding: 0;
  margin: 0;
}

.dashboard {
  .dashboard__card--dark {
    background-color: #1a1a1a !important;
    box-shadow: unset !important;
  }
  padding: 20px;
  //background-color: #f5f7fa;

  // &--dark {
  //   //background-color: #17171a;
  // }

  &__title {
    font-size: 2.3rem;
    @include reset-pm();
    color: $color-primary;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;

    &--name {
      font-weight: bold;
      margin-left: 9px;
    }
  }

  .btn-refresh {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      margin-left: 7px;
    }
  }

  &__container {
    margin-top: 20px;

    .dashboard__card {
      margin-top: 5px;
      //box-shadow: 0px 0px 15px 0px $box-shadow-white;
      border-radius: 15px;
      margin-bottom: 27px;
      padding: 18px;
      background-color: $bg-card-white;
      height: 95%;
      &--dark {
        background-color: $bg-card-dark;
        //box-shadow: 0px 0px 22.935px 0px rgba(255, 249, 249, 0.1);
      }
    }
  }
}
</style>
