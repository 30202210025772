import io from 'socket.io-client';

class WebSocketService {
  constructor() {
    this.socket = null;
  }

  initWebsocket() {
    const connectionOptions = {
      "force new connection": true,
      reconnectionAttempts: "Infinity",
      timeout: 10000,
      transports: ["websocket"],
    };
    this.socket = io(process.env.VUE_APP_NEST_BACK, connectionOptions);
    return this.socket;
  }

  getSocket() {
    return this.socket;
  }
}

const websocketService = new WebSocketService();
export default websocketService;
