<template>
  <div>
    <b-modal
      ref="modal-meta-details"
      size="sm"
      @hidden="$emit('hidden')"
      hide-footer
      scrollable
    >
      <template #modal-title>
        <span class="font-weight-bolder">
          {{ buildTitle }}
        </span>
      </template>
      <template #default>
        <b-table
          id="comercial-report-table-ceo"
          ref="comercial-report-table-ceo"
          slot="table"
          class="position-relative table-new-customization"
          empty-text="No matching records found"
          sticky-header="70vh"
          primary-key="id"
          responsive="md"
          show-empty
          no-border-collapse
          no-provider-filtering
          :busy.sync="isBusy"
          :fields="visibleFields"
          :items="myProvider"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(spend)="data">
            <span>{{ data.item.spend | formatCurrency }}</span>
          </template>
        </b-table>
      </template>
    </b-modal>
  </div>
</template>
<script>
import MetaService from "@/views/creative/views/meta/service/meta.service.js";
import moment from "moment";
export default {
  name: "MetaDetails",
  props: {
    adId: {
      type: String,
      required: true,
    },
    adName: {
      type: String,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
    maxDate: {
      type: String,
      required: true,
    },
    minDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      fields: [
        {
          key: "day",
          label: "Month/Day",
          sortable: false,
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "spend",
          label: "Investment",
          sortable: false,
          visible: false,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "results",
          label: "results",
          sortable: false,
          visible: false,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "reach",
          label: "reach",
          sortable: false,
          visible: false,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "cost_per_result",
          label: "CPR",
          sortable: false,
          visible: false,
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
    };
  },
  computed: {
    buildTitle() {
      return `${this.adName}: ${this.minDate} - ${this.maxDate}`;
    },
    visibleFields() {
      switch (this.type) {
        case 1:
          this.fields.find((field) => field.key === "spend").visible = true;
          break;
        case 2:
          this.fields.find((field) => field.key === "results").visible = true;
          break;
        case 3:
          this.fields.find((field) => field.key === "reach").visible = true;
          break;
        case 4:
          this.fields.find((field) => field.key === "cost_per_result").visible = true;
          break;
        default:
          break;
      }
      return this.fields.filter((field) => field.visible);
    },
  },
  created() {},
  mounted() {
    this.toggleModal("modal-meta-details");
  },
  methods: {
    async myProvider() {
      try {
        const params = {
          ad_id: this.adId,
          start_date: moment(this.minDate).format("YYYY-MM-DD"),
          end_date: moment(this.maxDate).format("YYYY-MM-DD"),
        };
        const { data } = await MetaService.getAdInsightsByDay(params);
        return data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    getFieldByKey(key) {
      return this.fields.find((field) => field.key === key);
    },
  },
};
</script>
<style lang="scss">
</style>