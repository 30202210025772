<template>
  <div class="b_card_content_nud">
    <b-card
      ref="bCarClientStatus"
      class="b_card_content"
      :class="isDarkSkin ? 'b_card_content--dark' : ''"
    >
      <b-card-text>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <div style="height: 250px" class="status-apex-container">
              <vue-apex-charts
                ref="chart_project_delays"
                type="donut"
                height="100%"
                width="100%"
                :options="options_project_delay"
                :series="options_project_delay.series"
                :class="
                  isDarkSkin ? 'apexchart_delays_dark' : 'apexchart_delays'
                "
              />
              <div
                class="content_loading_roi"
                :class="[
                  statusAreaCard ? 'd-none' : '',
                  isDarkSkin ? 'content_loading_roi--dark' : '',
                ]"
              >
                <b-spinner variant="primary" label="Text Centered" />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>

    <client-status-modal
      v-if="showModalClientStatus"
      :data-client="dataClient"
      :list-programs-modal="listPrograms"
      :hide-select="hideSelectProgram()"
      @close="showModalClientStatus = false"
      :index="index"
    />
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import ClientStatusModal from "@/views/ceo/dashboard/modals/ClientStatusModal.vue";

export default {
  components: {
    VueApexCharts,
    ClientStatusModal,
  },
  props: {
    dataStatus: {
      type: Object,
      required: true,
    },
    listPrograms: {
      type: Array,
      required: false,
      default: [],
    },
    selectYear: {
      type: Number,
      default: null,
      required: false,
    },
    selectMonth: {
      type: Number,
      default: null,
      required: false,
    },
    selectProgram: {
      type: Number,
      default: null,
      required: false,
    },
    index: {
      type: Number,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      icoMarketingCard: require("@/assets/images/icons/dashboard-ceo/ico_clients_card.png"),
      sizeScreen: 0,
      options_project_delay: {
        labels: [],
        series: [30, 40, 45, 50, 35, 45, 60],
        colors: [
          "#00DD31",
          "#FD8D00",
          "#843c54",
          "#5b3f5b",
          "#bd3030",
          "#ff0000",
        ],
        chart: {
          type: "donut",
          dropShadow: {
            enabled: true,
            color: "#111",
            top: -1,
            left: 3,
            blur: 3,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          pie: {
            offsetX: -5,
            offsetY: 10,
            customScale: 0.8,
            donut: {
              size: "65%",
              labels: {
                show: true,
              },
            },
          },
        },
        stroke: {
          show: true,
          curve: ["smooth", "straight", "stepline"],
          lineCap: "butt",
          width: 0,
          dashArray: 0,
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "solid",
          opacity: 1,
          pattern: {
            enabled: false,
            style: ["verticalLines"],
          },
        },
        legend: {
          formatter(val, opts) {
            return val;
          },
          position: "right",
          offsetY: 0,
          offsetX: -22,
          fontSize: "16px",
          height: 280,
          width: 180,
          itemMargin: {
            horizontal: -1,
            vertical: 7,
          },
          labels: {
            colors: "#5e5b5b",
          },
          markers: {
            width: 45,
            height: 30,
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: undefined,
            radius: 9,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
        },
        title: {
          text: "",
        },
      },

      showModalClientStatus: false,
      dataClient: {
        statusId: null,
        statusName: null,
        year: this.selectYear,
        month: this.selectMonth,
        idProgram: this.selectProgram,
      },
      statusAreaCard: false,
    };
  },
  watch: {
    isDarkSkin(value) {
      this.checkScreenSize();
      // this.getClientStatusByProgram();
    },
    selectProgram(value) {
      this.getClientStatusByProgram();
      this.dataClient.idProgram = this.selectProgram;
    },
    selectYear(value) {
      this.dataClient.year = this.selectYear;
      this.getClientStatusByProgram();
    },
    selectMonth(value) {
      this.dataClient.month = this.selectMonth;
      this.getClientStatusByProgram();
    },
  },
  mounted() {
    // responsive font
    setTimeout(() => {
      this.$nextTick().then(() => {
        window.addEventListener("resize", this.checkScreenSize);
        this.checkScreenSize();
      });

      this.getClientStatusByProgram();
      this.responsiveChartLabel();
      this.hideSelectProgram();
    }, 500);
  },
  methods: {
    checkScreenSize() {
      setTimeout(() => {
        const componentClientStatus = this.$refs.bCarClientStatus;
        this.sizeScreen =
          componentClientStatus && componentClientStatus.clientWidth > 0
            ? componentClientStatus.clientWidth
            : 0;
      }, 500);
      this.responsiveChartLabel();
    },
    titleCard() {
      let size = "18px";
      if (this.sizeScreen >= 407 && this.sizeScreen < 468) {
        size = "18px";
      } else if (this.sizeScreen >= 274 && this.sizeScreen < 407) {
        size = "13px";
      } else if (this.sizeScreen < 274) {
        size = "16px";
      }
      return `display:block;font-size:${size};font-weight:600;width:100%`;
    },
    responsiveChartLabel() {
      setTimeout(() => {
        const chartClient = this.$refs.chart_project_delays;
        const svg_item = chartClient.$el.getElementsByTagName("svg")[0];

        if (this.sizeScreen >= 345 && this.sizeScreen < 700) {
          svg_item.setAttribute("height", "550");
          svg_item
            .getElementsByTagName("foreignObject")[0]
            .setAttribute("height", 300);
        } else if (this.sizeScreen < 344) {
          svg_item.setAttribute("height", "550");
          svg_item
            .getElementsByTagName("foreignObject")[0]
            .setAttribute("height", 300);
        }
      }, 500);
    },
    async getClientStatusByProgram() {
      try {
        this.statusAreaCard = false;

        this.dataStatus.labels.forEach((element, index) => {
          this.dataStatus.labels[index] =
            `${element}` + "\n" + `(${this.dataStatus.seriesv2[index]})`;
        });
        // console.log(this.dataStatus);

        let totalSeries = 0;
        if (Array.isArray(this.dataStatus.series)) {
          this.dataStatus.series.forEach((element) => {
            totalSeries += element;
          });
        }
        this.statusAreaCard = true;

        const vm = this;
        this.options_project_delay = {
          labels: this.dataStatus.labels, // []
          series: this.dataStatus.series, // [30, 40, 45, 50, 35, 45, 60],
          chart: {
            events: {
              dataPointSelection(event, chartContext, config) {
                if (event) {
                } else {
                  vm.showModalClientStatus = true;
                  vm.dataClient.statusId =
                    vm.dataStatus.clientStatus[config.dataPointIndex].status_id;
                  vm.dataClient.statusName =
                    vm.dataStatus.clientStatus[config.dataPointIndex].status;

                  chartContext.pie.donutDataLabels.value.formatter =
                    function () {
                      return "Clients";
                    };
                  chartContext.pie.donutDataLabels.name.formatter =
                    function () {
                      return vm.dataStatus.series[config.dataPointIndex];
                    };
                }
              },
            },
          },
          tooltip: {
            custom({ series, seriesIndex, dataPointIndex, w }) {
              return `<div class="custom-marker arrow_box p-1">${this.dataStatus.labels[seriesIndex]} %</div>`;
            },
          },
          plotOptions: {
            pie: {
              customScale: 0.75,
              donut: {
                labels: {
                  show: true,
                  name: {
                    formatter(val) {
                      return totalSeries;
                    },
                    color: this.isDarkSkin ? "#B4B4B4" : "#353535",
                  },
                  total: {
                    color: this.isDarkSkin ? "#B4B4B4" : "#353535",
                    formatter(val) {
                      return "Clients";
                    },
                  },
                  value: {
                    color: this.isDarkSkin ? "#B4B4B4" : "#353535",
                    fontSize: "18px",
                  },
                },
              },
            },
          },
          responsive: [
            // breakpoint, pie_x, pie_y, pie_Scale, nameFontSize, valueFontSize, totalFontSize, nameY, valueY, totalY, legendY, legendX, legendHeight
            this.resizeChart(
              450,
              90,
              180,
              2.3,
              "1px",
              "7px",
              "10px",
              0,
              -5,
              0,
              140,
              30,
              155
            ),
            this.resizeChart(
              500,
              0,
              120,
              1.3,
              "1px",
              "12px",
              "18px",
              0,
              -3,
              0,
              0,
              -40,
              290
            ),
            this.resizeChart(
              600,
              0,
              90,
              1.1,
              "1px",
              "14px",
              "17px",
              0,
              1,
              0,
              0,
              -20,
              290
            ),
            this.resizeChart(
              800,
              -40,
              10,
              0.7,
              "1px",
              "25px",
              "35px",
              0,
              14,
              0,
              15,
              10,
              240
            ),
            this.resizeChart(
              900,
              -40,
              10,
              0.7,
              "1px",
              "25px",
              "35px",
              0,
              14,
              0,
              15,
              10,
              240
            ),
            this.resizeChart(
              992,
              -40,
              10,
              0.8,
              "1px",
              "22px",
              "40px",
              0,
              13,
              0,
              0,
              20,
              290
            ),
            this.resizeChart(
              1100,
              80,
              690,
              5.8,
              "1px",
              "3px",
              "4px",
              0,
              -13,
              0,
              140,
              20,
              155
            ),
            this.resizeChart(
              1200,
              90,
              150,
              2.1,
              "1px",
              "7px",
              "10px",
              0,
              -7,
              0,
              140,
              30,
              155
            ),
            this.resizeChart(
              1250,
              90,
              210,
              2.4,
              "1px",
              "5px",
              "8px",
              0,
              -10,
              0,
              140,
              20,
              155
            ),
            this.resizeChart(
              1275,
              90,
              150,
              2.1,
              "1px",
              "9px",
              "12px",
              0,
              -5,
              0,
              130,
              30,
              150
            ),
            this.resizeChart(
              1300,
              90,
              110,
              1.8,
              "1px",
              "9px",
              "12px",
              0,
              -5,
              0,
              130,
              30,
              150
            ),
            this.resizeChart(
              1400,
              -15,
              50,
              0.9,
              "1px",
              "15px",
              "15px",
              -5,
              5,
              0,
              0,
              0,
              290
            ),
            this.resizeChart(
              1500,
              -15,
              50,
              0.9,
              "1px",
              "15px",
              "15px",
              -5,
              5,
              0,
              0,
              0,
              290
            ),
            this.resizeChart(
              1600,
              -15,
              70,
              0.9,
              "1px",
              "17px",
              "25px",
              -3,
              3,
              0,
              0,
              -10,
              290
            ),
            this.resizeChart(
              1700,
              -15,
              60,
              0.9,
              "1px",
              "20px",
              "30px",
              -3,
              5,
              0,
              0,
              -10,
              290
            ),
            this.resizeChart(
              1800,
              -15,
              50,
              0.9,
              "1px",
              "18px",
              "29px",
              -1,
              9,
              0,
              0,
              -10,
              290
            ),
            this.resizeChart(
              1900,
              -15,
              35,
              0.7,
              "1px",
              "25px",
              "30px",
              -5,
              10,
              0,
              0,
              -20,
              290
            ),
            this.resizeChart(
              5900,
              -15,
              30,
              0.7,
              "1px",
              "25px",
              "30px",
              -5,
              10,
              0,
              0,
              0,
              290
            ),
          ],
        };
        this.$refs.chart_project_delays?.updateOptions(
          this.options_project_delay
        );
      } catch (error) {
        this.statusAreaCard = true;
        this.showErrorSwal(error);
      }
    },
    resizeChart(
      breakpoint_x,
      pie_x,
      pie_y,
      pie_customScale,
      nameFontSize,
      valueFontSize,
      totalFontSize,
      nameY,
      valueY,
      totalY,
      legendY,
      legendX,
      legendHeight
    ) {
      const resize = {
        breakpoint: breakpoint_x, // 1600,
        options: {
          plotOptions: {
            pie: {
              offsetX: pie_x, // 0,
              offsetY: pie_y, // 50,
              customScale: pie_customScale, // 1,
              donut: {
                size: "59%",
                labels: {
                  show: true,
                  name: {
                    show: true,
                    fontSize: nameFontSize, // '5px',
                    offsetY: nameY, // 0,
                  },
                  value: {
                    show: true,
                    fontSize: valueFontSize, // '13px',
                    offsetY: valueY, // -2,
                  },
                  total: {
                    showAlways: true,
                    show: true,
                    fontSize: totalFontSize, // '20px',
                    offsetY: totalY, // 0
                  },
                },
              },
            },
          },
          legend: {
            position: "right",
            offsetY: legendY,
            offsetX: legendX,
            height: legendHeight, // 290
            width: 180,
            labels: {
              colors: this.isDarkSkin ? "#fff" : "#373d3f",
            },
            fontSize: "12px",
          },
        },
      };
      return resize;
    },
    hideSelectProgram() {
      return this.selectProgram != null;
    },
  },
};
</script>
<style lang="scss" scoped>
.b_card_content {
  border-radius: 18px;
  min-height: 370px;
  padding-bottom: 0;

  .title_content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .b_avatar_color {
    background: rgba(167, 197, 255, 1) !important;
  }

  .select_border_dark {
    color: #1654cc;
    border-color: #1654cc !important;
  }
}
</style>
<style lang="scss">
.dashboard {
  .b_card_content {
    .card-body {
      padding: 1.5rem 1.5rem 0 1.5rem !important;
    }
  }
}

%apexcharts_legend_series {
  display: flex !important;
  align-items: center !important;
}

%apexcharts_legend {
  border-radius: 10px;
  padding: 15px 20px 10px 20px;
}

%apexcharts_legend_text {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.apexchart_delays {
  .apexcharts-legend-series {
    @extend %apexcharts_legend_series;
  }

  .apexcharts-legend-text {
    @extend %apexcharts_legend_text;
  }

  .apexcharts-legend {
    background: #fafafa !important;
    @extend %apexcharts_legend;
    overflow: hidden !important;
  }

  .apexcharts-canvas {
    cursor: pointer;
  }
}

.apexchart_delays_dark {
  .apexcharts-legend-series {
    @extend %apexcharts_legend_series;
  }

  .apexcharts-legend-text {
    @extend %apexcharts_legend_text;
    color: #b4b4b4 !important;
  }

  .apexcharts-legend {
    background: #17171a !important;
    @extend %apexcharts_legend;
  }
  .apexcharts-canvas {
    cursor: pointer;
  }
}

.content_loading_roi {
  position: absolute;
  top: 0px;
  background: #fff;
  height: 102%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &--dark {
    background: rgba(17, 17, 19, 1);
  }
}

.dark-layout {
  .b_card_content_nud {
    .card {
      background: #111113 !important;
    }
  }
}
.status-apex-container {
  .apexcharts-legend.apexcharts-align-center {
    overflow: auto !important;
  }
}
</style>
