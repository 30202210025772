var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"message-bubble-container",class:{
    me: _vm.message.me,
    other: !_vm.message.me,
  },attrs:{"id":_vm.message.type == 'Chat' ? _vm.message.id : _vm.message.id + '-date'}},[(_vm.visibleMessage(_vm.message))?_c('div',{staticClass:"message-container"},[_c('div',{staticClass:"message",class:{
        me: _vm.message.me,
        other: !_vm.message.me,
      }},[(_vm.message.me)?_c('b-avatar',{attrs:{"size":"30","variant":"light-secondary","src":_vm.isUserValid(_vm.message)
            ? _vm.getImageUser(_vm.message.user.id)
            : _vm.G_CURRENT_FB_PAGE.picture}}):_vm._e(),(!_vm.message.me)?_c('b-avatar',{attrs:{"size":"30","variant":"light-secondary","src":_vm.senderAvatar}}):_vm._e(),_c('div',{staticClass:"message-content",class:{
          me: _vm.message.me,
          other: !_vm.message.me,
        }},[(_vm.validReplyTo(_vm.message))?_c('MessageReplyTo',{attrs:{"message":_vm.message}}):_vm._e(),(_vm.message.message)?_c('Bubble',{class:{
            me: _vm.message.me,
            other: !_vm.message.me,
          }},[(_vm.message.type == 'Chat' || _vm.message.type == 'Unsupported')?_c('p',{staticClass:"m-0",class:{
              'message-error':
                _vm.message.messageStatus == 'Error' ||
                _vm.message.type == 'Unsupported',
            },domProps:{"innerHTML":_vm._s(_vm.processMessageChat(_vm.message.message))}}):_vm._e(),(_vm.isMessageCall(_vm.message))?_c('div',{staticClass:"message-call"},[_c('b-badge',{staticClass:"message-call-badge",attrs:{"variant":_vm.getMessageCall(_vm.message, _vm.message.me).variant}},[_c('feather-icon',{attrs:{"icon":_vm.getMessageCall(_vm.message, _vm.message.me).icon,"size":"20"}})],1),_c('div',{staticClass:"message-call-text"},[_c('strong',[_vm._v(_vm._s(_vm.getMessageCall(_vm.message, _vm.message.me).text))]),_c('span',[_vm._v(_vm._s(_vm._f("formatHourTime")(_vm.message.createdTime)))])])],1):_vm._e()]):_vm._e(),(_vm.message.attachments.length > 0)?_c('Attachments',{staticClass:"bubble-message",attrs:{"attachments":_vm.message.attachments,"me":_vm.message.me}}):_vm._e(),(_vm.message.isSticker)?_c('div',{staticClass:"bubble-message"},[_c('img',{staticClass:"message-sctiker",attrs:{"src":_vm.message.sticker,"alt":""}})]):_vm._e(),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:("Reply"),expression:"`Reply`",modifiers:{"hover":true,"top":true}}],staticClass:"icon-reply-message",class:{
            me: _vm.message.me,
            other: !_vm.message.me,
          }},[_c('feather-icon',{attrs:{"icon":"CornerUpLeftIcon","size":"16"}})],1)],1)],1),_c('div',{staticClass:"message-created-time",class:{
        me: _vm.message.me,
        other: !_vm.message.me,
      }},[(_vm.message.me && _vm.isUserValid(_vm.message))?_c('span',{staticClass:"message-user"},[_vm._v(" "+_vm._s(_vm.message.messageStatus == "Error" ? "Message not sent" : "Send by")+" "),_c('strong',[_vm._v(_vm._s(_vm.message.user.name))])]):_vm._e(),_c('div',{staticClass:"created-time-status"},[(_vm.isMessageSeen)?_c('UsersMessageRead',{attrs:{"users":_vm.message.usersSeen}}):_vm._e(),(!_vm.isMessageCall(_vm.message))?_c('span',{staticClass:"message-time"},[_vm._v(" "+_vm._s(_vm._f("formatHourTime")(_vm.message.createdTime))+" ")]):_vm._e(),(_vm.isMessageStatus)?_c('MessageStatus',{attrs:{"message":_vm.message,"sender-avatar":_vm.senderAvatar}}):_vm._e()],1)])]):_vm._e(),(_vm.message.type == 'Date')?_c('div',{staticClass:"message-date-container"},[_c('div',{staticClass:"message-date"},[_c('b-badge',{attrs:{"variant":"light-secondary"}},[_vm._v(" "+_vm._s(_vm.messageDate(_vm.message.message))+" ")])],1)]):_vm._e(),(_vm.message.type == 'Notice')?_c('div',{staticClass:"message-notice-container"},[_c('div',{staticClass:"message-notice"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.messageNotice(_vm.message.message))}})])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }