<template>
  <div>
    <b-modal
      v-model="showModal"
      hide-footer
      size="xmd"
      no-close-on-backdrop
      @hidden="close()"
      modal-class="custom-modal-amg"
    >
      <template #modal-title>
        <h2 class="text-white">
          {{
            method == "CREDIT_CARD"
              ? "TRANSACTIONS CONFIRMED IN THE SOFT"
              : method == "CHECK"
              ? "CHECKS"
              : "E-CHECKS"
          }}
        </h2>
      </template>
      <div class="ml-1 my-1">
        <div
          class="absolute-linker d-flex align-items-center"
          :class="{
            dark: isDarkSkin,
            'justify-content-end': method === 'CHECK' || method === 'E-CHECK',
            'justify-content-between': method === 'CREDIT_CARD',
          }"
        >
          <div v-if="method == 'CREDIT_CARD'">
            <v-select
              :options="creditCards"
              v-model="cardSelected"
              label="cardholdername"
              :reduce="(option) => option.credit_card_expense_id"
              placeholder="Select a card"
              style="min-width: 300px"
              :clearable="false"
              @input="getTotalsByModule()"
            >
              <template v-slot:option="option">
                <div class="d-flex flex-column">
                  <span>Bank: {{ option.bank_name }}</span>
                  <span
                    >Card: {{ option.cardholdername }} | XXXX-{{
                      option.cardnumber.slice(-4)
                    }}</span
                  >
                </div>
              </template>
              <template v-slot:selected-option="option">
                <div class="d-flex flex-column">
                  <span>Bank: {{ option.bank_name }}</span>
                  <span
                    >Card: {{ option.cardholdername }} | XXXX-{{
                      option.cardnumber.slice(-4)
                    }}</span
                  >
                </div>
              </template>
            </v-select>
          </div>
          <div class="row d-flex justify-content-end" style="gap: 5px">
            <div
              v-if="method == 'CHECK' || method == 'E-CHECK'"
              class="d-flex col-12 justify-content-end"
              style="gap: 5px"
            >
              <b-button
                v-if="method == 'CHECK'"
                @click="openDetailRequestInProcess()"
                variant="outline-info"
              >
                <b-badge v-if="countInProcess > 0" pill variant="danger">{{
                  countInProcess
                }}</b-badge>
                IN PROCESS
              </b-button>
              <b-button @click="openDetailRequest()" variant="outline-danger"
                > {{ method == "E-CHECK" ? "NOT COLLECTED" : "REJECTED" }}</b-button
              >
            </div>
            <div
              class="d-flex align-items-center h-full px-1 col-12 justify-content-end"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="20"
                class="cursor-pointer"
                @click="prev()"
              />
              <span class="font-size:15px">
                {{ months[dataMonth - 1] }} {{ dataYear }}
              </span>
              <feather-icon
                icon="ChevronRightIcon"
                size="20"
                class="cursor-pointer"
                :class="{ 'disabled-next': disableNext }"
                @click="next()"
              />
            </div>
          </div>
        </div>
      </div>

      <b-table
        :items="expenses"
        :fields="fields"
        responsive
        show-empty
        class="position-relative table-new-customization"
        sticky-header="70vh"
      >
        <template #cell(department)="{ item }">
          <img
            v-b-tooltip.hover.top="item.module_name"
            :src="`/assets/${item.icons}`"
            alt="Logo"
            height="30"
            width="30"
          />
        </template>
        <template #cell(transactions)="{ item }">
          <div @click="openDetail(item)">
            <b-badge
              variant="primary"
              style="font-size: 15px"
              class="cursor-pointer"
            >
              {{ item.transactions }}
            </b-badge>
          </div>
        </template>
        <template #cell(amount)="{ item }">
          <div>
            <!-- <span>{{ item.amount | formatMoney }}</span> -->
            <b-badge
              @click="openDetail(item)"
              variant="light-success"
              style="font-size: 15px"
              class="cursor-pointer"
              >{{ item.amount | formatMoney }}</b-badge
            >
          </div>
        </template>
        <template #bottom-row>
          <b-th>
            <b-badge
              variant="primary"
              class="w-100 text-center"
              style="
                font-size: 14px;
                font-weight: 600;
                padding-bottom: 7px;
                padding-top: 7px;
              "
            >
              TOTAL
            </b-badge>
          </b-th>
          <b-th>
            <b-badge
              variant="success"
              class="w-100 text-center text-black"
              style="
                font-size: 14px;
                font-weight: 600;
                padding-bottom: 7px;
                padding-top: 7px;
              "
            >
              {{ totalTransactions }}
            </b-badge>
          </b-th>
          <b-th>
            <b-badge
              variant="success"
              class="w-100 text-center text-black"
              style="
                font-size: 14px;
                font-weight: 600;
                padding-bottom: 7px;
                padding-top: 7px;
              "
            >
              {{ totalGeneralAmount | formatMoney }}
            </b-badge>
          </b-th>
        </template>
      </b-table>
    </b-modal>
    <expenses-detail
      v-if="showExpensesDetailModal"
      :sendYear="dataYear"
      :sendMonth="dataMonth"
      :module_id="itemSelected.module_id"
      :cardId="cardSelected"
      :method="method"
      @reload="reload"
      @close="showExpensesDetailModal = false"
    />
    <expenses-request-detail
      v-if="showExpensesRequestDetailModal"
      @close="closeExpensesRequestDetail"
      :isRejected="isRejected"
      :isInProcess="isInProcess"
    />
  </div>
</template>
<script>
import DashboardService from "@/views/ceo/service-dashboard/dashboard.service";
import Fields from "@/views/ceo/dashboard/modals/expenses-request/data/expenses-by-module.fields.js";
import ExpensesDetail from "@/views/ceo/dashboard/modals/expenses/ExpensesDetail.vue";
import ExpensesRequestDetail from "@/views/ceo/dashboard/modals/expenses-request/ExpensesRequestDetail.vue";
import moment from "moment";
import { modifyLabel, modifyVisibility } from "@/helpers/fields-table";
export default {
  name: "TotalsByModule",
  components: {
    ExpensesDetail,
    ExpensesRequestDetail,
  },
  data() {
    return {
      showModal: false,
      expenses: [],
      fields: Fields,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      dataYear: null,
      dataMonth: null,
      type: null,
      cpyMonth: null,
      cpyYear: null,
      useMonthFilter: false,
      itemSelected: null,
      showExpensesDetailModal: false,
      cardSelected: null,
      creditCards: [],
      showExpensesRequestDetailModal: false,
      isRejected: false,
      isInProcess: false,
      countInProcess: 0,
    };
  },
  props: {
    sendYear: {
      type: Number,
      required: true,
    },
    sendMonth: {
      type: Number,
      required: true,
    },
    cardId: {
      type: Number,
      required: false,
    },
    method: {
      type: String,
      required: false,
      default: "CREDIT_CARD",
    },
    // creditCards: {
    //   type: Array,
    //   required: true,
    // },
  },
  async created() {
    modifyLabel(
      this.fields,
      "transactions",
      this.method == "CREDIT_CARD" ? "TRANSACTIONS" : "COLLECTED"
    );
    this.initMonthYear();
    await this.getCardList();
    this.cardSelected = this.cardId;
    await this.getTotalsByModule();
    await this.getCountInProcess();
    this.showModal = true;
  },
  computed: {
    titleMonthYear() {
      return `${this.months[this.dataMonth - 1]} ${this.dataYear}`;
    },
    disableNext() {
      const now = moment();
      const sameYear = now.year() === this.dataYear;
      const sameMonth = now.month() + 1 === this.dataMonth;
      return sameYear && sameMonth;
    },

    totalTransactions() {
      return this.expenses.reduce((acum, item) => acum + item.transactions, 0);
    },
    totalGeneralAmount() {
      return this.expenses.reduce(
        (acum, item) => acum + parseFloat(item.amount),
        0
      );
    },
    generateYears() {
      const years = [];
      const currentYear = new Date().getFullYear();
      for (let i = currentYear; i >= 2023; i--) {
        years.push(i);
      }
      return years;
    },
  },
  methods: {
    async getTotalsByModule() {
      try {
        this.addPreloader();
        const params = {
          month: this.dataMonth,
          year: this.dataYear,
          cardId: this.cardSelected || null,
          method: this.method,
        };
        const data = await DashboardService.getTotalsByModule(params);
        this.expenses = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async getCardList() {
      try {
        const params = {
          month: this.dataMonth,
          year: this.dataYear,
        };
        const data = await DashboardService.getCardList(params);
        this.creditCards = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    openDetail(item) {
      this.itemSelected = item;
      this.showExpensesDetailModal = true;
    },
    async prev() {
      if (this.dataMonth == null) this.dataMonth = this.cpyMonth;
      if (this.dataMonth > 1) {
        this.dataMonth--;
      } else {
        this.dataMonth = 12;
        this.dataYear--;
      }
      await this.refresh();
    },
    async next() {
      if (this.dataMonth == null) this.dataMonth = this.cpyMonth;
      if (this.disableNext) return;
      if (this.dataMonth < 12) {
        this.dataMonth++;
      } else {
        this.dataMonth = 1;
        this.dataYear++;
      }
      await this.refresh();
    },
    initMonthYear() {
      this.dataMonth = this.sendMonth;
      this.dataYear = this.sendYear;
    },
    reload(reloadData) {
      this.dataMonth = reloadData.month;
      this.dataYear = reloadData.year;
      this.cardSelected = reloadData.cardId;
      this.refresh();
    },
    async refresh() {
      await this.getCardList();
      await this.getTotalsByModule();
    },
    close() {
      // let reloadData = {
      //   year: this.dataYear,
      //   month: this.dataMonth,
      // };
      // this.$emit("reload", reloadData);
      this.$emit("close");
    },
    openDetailRequest(item) {
      this.showExpensesRequestDetailModal = true;
      this.isRejected = true;
    },
    openDetailRequestInProcess() {
      this.showExpensesRequestDetailModal = true;
      this.isInProcess = true;
    },
    closeExpensesRequestDetail() {
      this.showExpensesRequestDetailModal = false;
      this.isRejected = false;
      this.isInProcess = false;
      this.getCountInProcess();
    },
    async getCountInProcess() {
      try {
        const data = await DashboardService.getCountInProcess();
        if (data.length > 0) {
          this.countInProcess = data.reduce((acc, curr) => {
            return acc + curr.count;
          }, 0);
        }
      } catch (error) {}
    },
  },
};
</script>
<style lang="scss" scoped>
.dark-select {
  background-color: #333;
  color: #fff;
}
.dark .absolute-linker {
  background-color: #444;
}
.disabled-next {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
