export default [
  {
    key: "program_id",
    label: "Service",
    tdClass: "text-center",
    thClass: "text-center",
    sortable: false,
    visible: true,
  },
  {
    key: "flyer_route_thumb",
    label: "Flyer",
    tdClass: "text-center",
    thClass: "text-center",
    section: "creative",
    sortable: false,
    visible: true,
  },
  {
    key: "flyer_code",
    label: "Code",
    tdClass: "text-center",
    thClass: "text-center",
    section: "creative",
    sortable: true,
    visible: true,
  },
  {
    key: "investment",
    label: "Inv",
    tdClass: "text-center",
    thClass: "text-center",
    section: "creative",
    sortable: true,
    visible: true,
  },
  {
    key: "results",
    label: "Replys",
    tdClass: "text-center",
    thClass: "text-center",
    section: "creative",
    sortable: true,
    visible: true,
  },
  {
    key: "sources",
    label: "Source",
    tdClass: "text-center",
    thClass: "text-center",
    section: "metamedia",
    sortable: true,
    visible: true,
  },
  {
    key: "count_leads",
    label: "Leads",
    tdClass: "text-center",
    thClass: "text-center",
    section: "metamedia",
    sortable: true,
    visible: true,
  },
  {
    key: "count_numbers",
    label: "Num Obt",
    tdClass: "text-center",
    thClass: "text-center",
    section: "metamedia",
    sortable: true,
    visible: true,
  },
  {
    key: "appointments",
    label: "Appointments",
    tdClass: "text-center",
    thClass: "text-center",
    section: "metamedia",
    sortable: true,
    visible: true,
  },
  {
    key: "sales",
    label: "Sales",
    tdClass: "text-center",
    thClass: "text-center",
    section: "crm",
    sortable: true,
    visible: true,
  },
  {
    key: "fee",
    label: "Fee",
    tdClass: "text-center",
    thClass: "text-center",
    section: "crm",
    sortable: true,
    visible: true,
  },
  {
    key: "ip",
    label: "IP",
    tdClass: "text-center",
    thClass: "text-center",
    section: "crm",
    sortable: true,
    visible: true,
  },
  {
    key: "roi",
    label: "ROI %",
    tdClass: "text-center",
    thClass: "text-center",
    sortable: true,
    visible: true,
  },
];
