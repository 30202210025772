<template>
  <b-container class="mt-1">
    <h4>More Information</h4>
    <validation-observer ref="moreInformationObserver">
      <b-row>
        <b-col sm="6">
          <validation-provider name="type">
            <b-form-group label="Type" label-for="type">
              <v-select
                id="type"
                label="value"
                :options="types"
                :reduce="(el) => el.id"
                v-model="userData.debt_types"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="true"
                :preserve-search="true"
                :disabled="onlyView"
                placeholder="Pick some"
                class="rounded"
                :preselect-first="true"
              ></v-select>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col sm="6">
          <validation-provider name="payment_types">
            <b-form-group label="Payment Type" label-for="payment_types">
              <v-select
                id="payment_types"
                label="value"
                :options="payment_types"
                :reduce="(el) => el.id"
                :disabled="onlyView"
                v-model="userData.payment_type"
                class="rounded"
              ></v-select>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col sm="6">
          <validation-provider name="send_type">
            <b-form-group label="Send Type" label-for="send_type">
              <v-select
                id="send_type"
                label="name"
                :options="send_types"
                :reduce="(el) => el.id"
                v-model="userData.send_types"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="true"
                :preserve-search="true"
                :disabled="onlyView"
                placeholder="Pick some"
                class="rounded"
                :preselect-first="true"
              ></v-select>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col sm="6">
          <validation-provider name="average_trade_percentage">
            <b-form-group
              label="Average Settlement Percentage"
              label-for="average_trade_percentage"
            >
              <b-form-input
                id="average_trade_percentage"
                v-model="userData.average_trade_percentage"
                class="rounded"
                :disabled="onlyView"
                v-mask="'##.##'"
              ></b-form-input>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col sm="6">
          <validation-provider name="interest_rate">
            <b-form-group label="Interest Rate" label-for="interest_rate">
              <b-form-input
                id="interest_rate"
                v-model="userData.interest_rate"
                class="rounded"
                :disabled="onlyView"
                v-mask="'##.##'"
              ></b-form-input>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
  </b-container>
</template>
<script>
import { VueAutosuggest } from "vue-autosuggest";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { ValidationObserver } from "vee-validate";
import useMoreInformationCreditorEdit from "@/views/debt-solution/views/creditors/components/modal/useMoreInformationCreditorEdit.js";
import { getCurrentInstance } from "@vue/composition-api";

export default {
  name: "MoreInformationCreditorEdit",
  components: { ValidationObserver, VueAutosuggest, VueGoogleAutocomplete },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    onlyView: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async validate(){
      return await this.$refs.moreInformationObserver.validate();
    }
  },
  setup(props) {
    const vm = getCurrentInstance().proxy;
    const { types, payment_types, send_types, G_STATUS_EEUU, G_COUNTRIES } =
      useMoreInformationCreditorEdit(vm);

    return {
      types,
      payment_types,
      send_types,
      G_STATUS_EEUU,
      G_COUNTRIES,
    };
  },
};
</script>

<style lang="scss">
.paddingB {
  padding-bottom: 4px !important;
}

.paddingT {
  padding-top: 4px !important;
}

.ppaddingTP {
  padding-top: 23px !important;
}

.paddingIconoX {
  padding-top: 8px !important;
  padding-left: 3px !important;
}

.widthInput {
  width: 96%;
}

.widthInputPhone {
  width: 80%;
}
</style>
    