<template>
  <div>
    <svg
      :width="width"
      :height="height"
      :viewBox="'0 0 ' + width + ' ' + height"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.619 1C7.60215 1 1 7.60215 1 15.619C1 23.6359 7.60215 30.2381 15.619 30.2381C23.6359 30.2381 30.2381 23.6359 30.2381 15.619C30.2381 7.60215 23.6359 1 15.619 1ZM15.619 27.4086C9.11121 27.4086 3.82949 22.1269 3.82949 15.619C3.82949 9.11121 9.11121 3.82949 15.619 3.82949C22.1269 3.82949 27.4086 9.11121 27.4086 15.619C27.4086 22.1269 22.1269 27.4086 15.619 27.4086Z"
        :fill="color"
        :stroke="stroke"
        stroke-width="0.6"
      />
      <path
        d="M18.6371 10.6203L11.8464 17.4111C10.1487 19.1088 9.58277 19.6747 10.5259 20.6178C10.8089 20.9008 11.1861 21.0894 11.5634 21.0894C11.9407 21.0894 12.3179 20.9008 12.6009 20.7121C12.6952 20.6178 20.6178 12.6952 20.6178 12.6952C21.1837 12.1293 21.1837 11.2805 20.6178 10.7146C20.0519 10.0544 19.203 10.0544 18.6371 10.6203Z"
        fill="white"
        stroke="#00DD31"
        stroke-width="0.6"
      />
      <path
        d="M12.7895 13.261C13.5709 13.261 14.2043 12.6276 14.2043 11.8463C14.2043 11.0649 13.5709 10.4315 12.7895 10.4315C12.0082 10.4315 11.3748 11.0649 11.3748 11.8463C11.3748 12.6276 12.0082 13.261 12.7895 13.261Z"
        :fill="color"
        :stroke="stroke"
        stroke-width="0.6"
      />
      <path
        d="M18.4486 20.8065C19.2299 20.8065 19.8633 20.1731 19.8633 19.3917C19.8633 18.6104 19.2299 17.977 18.4486 17.977C17.6672 17.977 17.0338 18.6104 17.0338 19.3917C17.0338 20.1731 17.6672 20.8065 18.4486 20.8065Z"
        :fill="color"
        :stroke="stroke"
        stroke-width="0.6"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      required: false,
      default: 31,
    },
    height: {
      type: Number,
      required: false,
      default: 31,
    },
    color: {
      type: String,
      required: false,
      default: "white",
    },
    stroke: {
      type: String,
      required: false,
      default: "#00DD31",
    },
  },
};
</script>

