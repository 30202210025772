<template>
  <div>
    <svg
      :width="width"
      :height="height"
      :viewBox="'0 0 ' + width + ' ' + height"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.792 8.35332H29.4941V6.05541C29.4941 4.71463 28.9615 3.42877 28.0134 2.48069C27.0654 1.53262 25.7795 1 24.4387 1H6.05541C4.71463 1 3.42877 1.53262 2.4807 2.48069C1.53262 3.42877 1 4.71463 1 6.05541V13.4087C1 14.7495 1.53262 16.0354 2.4807 16.9834C3.42877 17.9315 4.71463 18.4641 6.05541 18.4641H8.35332V20.7621C8.35332 22.1028 8.88594 23.3887 9.83402 24.3368C10.7821 25.2848 12.068 25.8175 13.4087 25.8175H31.792C33.1328 25.8175 34.4187 25.2848 35.3668 24.3368C36.3148 23.3887 36.8474 22.1028 36.8474 20.7621V13.4087C36.8474 12.068 36.3148 10.7821 35.3668 9.83402C34.4187 8.88594 33.1328 8.35332 31.792 8.35332ZM6.05541 15.7066C5.44596 15.7066 4.86148 15.4645 4.43054 15.0336C3.9996 14.6027 3.7575 14.0182 3.7575 13.4087V6.05541C3.7575 5.44596 3.9996 4.86148 4.43054 4.43054C4.86148 3.9996 5.44596 3.7575 6.05541 3.7575H24.4387C25.0482 3.7575 25.6326 3.9996 26.0636 4.43054C26.4945 4.86148 26.7366 5.44596 26.7366 6.05541V8.35332H13.4087C12.068 8.35332 10.7821 8.88594 9.83402 9.83402C8.88594 10.7821 8.35332 12.068 8.35332 13.4087V15.7066H6.05541ZM34.09 20.7621C34.09 21.3715 33.8479 21.956 33.4169 22.3869C32.986 22.8179 32.4015 23.06 31.792 23.06H13.4087C12.7993 23.06 12.2148 22.8179 11.7839 22.3869C11.3529 21.956 11.1108 21.3715 11.1108 20.7621V13.4087C11.1108 12.7993 11.3529 12.2148 11.7839 11.7839C12.2148 11.3529 12.7993 11.1108 13.4087 11.1108H31.792C32.4015 11.1108 32.986 11.3529 33.4169 11.7839C33.8479 12.2148 34.09 12.7993 34.09 13.4087V20.7621Z"
        :fill="color"
        :stroke="stroke"
      />
      <path
        d="M22.6004 12.03C21.6005 12.03 20.6231 12.3265 19.7917 12.882C18.9604 13.4375 18.3124 14.227 17.9298 15.1508C17.5471 16.0745 17.447 17.091 17.6421 18.0716C17.8371 19.0523 18.3186 19.9531 19.0256 20.6601C19.7327 21.3671 20.6334 21.8486 21.6141 22.0436C22.5947 22.2387 23.6112 22.1386 24.535 21.756C25.4587 21.3733 26.2483 20.7254 26.8038 19.894C27.3593 19.0627 27.6558 18.0852 27.6558 17.0854C27.6558 15.7446 27.1231 14.4587 26.1751 13.5107C25.227 12.5626 23.9411 12.03 22.6004 12.03ZM22.6004 19.3833C22.1459 19.3833 21.7016 19.2485 21.3237 18.996C20.9458 18.7435 20.6513 18.3846 20.4774 17.9648C20.3034 17.5449 20.2579 17.0828 20.3466 16.6371C20.4353 16.1913 20.6541 15.7819 20.9755 15.4605C21.2969 15.1391 21.7063 14.9203 22.1521 14.8316C22.5978 14.743 23.0598 14.7885 23.4797 14.9624C23.8996 15.1363 24.2585 15.4308 24.511 15.8087C24.7635 16.1866 24.8983 16.6309 24.8983 17.0854C24.8983 17.6948 24.6562 18.2793 24.2252 18.7102C23.7943 19.1412 23.2098 19.3833 22.6004 19.3833ZM13.4087 12.03C13.0462 12.0348 12.7002 12.1822 12.4456 12.4402C12.191 12.6983 12.0483 13.0462 12.0483 13.4087C12.0484 13.6822 12.1297 13.9495 12.282 14.1766C12.4344 14.4037 12.6508 14.5804 12.9038 14.6842C13.1568 14.788 13.435 14.8142 13.7029 14.7595C13.9708 14.7048 14.2165 14.5716 14.4085 14.377C14.6006 14.1823 14.7304 13.9349 14.7815 13.6663C14.8327 13.3976 14.8027 13.1198 14.6955 12.8682C14.5884 12.6166 14.4088 12.4026 14.1796 12.2533C13.9505 12.1041 13.6821 12.0263 13.4087 12.03ZM31.792 19.3833C31.4278 19.3881 31.0799 19.5348 30.8224 19.7924C30.5648 20.0499 30.418 20.3979 30.4133 20.762C30.4181 21.1294 30.5674 21.48 30.8289 21.7381C31.0904 21.9961 31.443 22.1408 31.8104 22.1408C32.1761 22.1408 32.5267 21.9955 32.7853 21.737C33.0439 21.4784 33.1891 21.1277 33.1891 20.762C33.1891 20.3964 33.0439 20.0457 32.7853 19.7871C32.5267 19.5286 32.1761 19.3833 31.8104 19.3833H31.792Z"
        :fill="color"
        :stroke="stroke"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      required: false,
      default: 38,
    },
    height: {
      type: Number,
      required: false,
      default: 27,
    },
    color: {
      type: String,
      required: false,
      default: "white",
    },
    stroke: {
      type: String,
      required: false,
      default: "#0090E7",
    },
  },
};
</script>

