export default [
  {
    key: "cardholdername",
    label: "Card",
    class: "text-center",
    visible: true
  },
  {
    key: "name",
    label: "Bank name",
    class: "text-center",
    visible: true
  },
  {
    key: "total",
    label: "Total",
    class: "text-center",
    visible: true
  }
]