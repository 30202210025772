export default [
  {
    key: 'full_name_client',
    label: 'Client',
    // thStyle: { width: '33%' },
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'creditor_name',
    label: 'Actual Creditor',
    // thStyle: { width: '33%' },
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'balance',
    label: 'Actual Balance',
    // thStyle: { width: '33%' },
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'offer_amount',
    label: 'Offer Amount',
    // thStyle: { width: '33%' },
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'settlement_fee',
    label: 'Settlement fee',
    // thStyle: { width: '33%' },
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'settlement_total_cost',
    label: 'Settlement total cost',
    // thStyle: { width: '33%' },
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'id_status',
    label: 'Status',
    // thStyle: { width: '33%' },
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'payment_date',
    label: 'Payment date',
    // thStyle: { width: '33%' },
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'aproved_at',
    label: 'Offer approved',
    // thStyle: { width: '33%' },
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'orden_aproved_at',
    label: 'Payment Order created',
    // thStyle: { width: '33%' },
    thClass: 'text-left',
    tdClass: 'text-left',
  },
];
