<template>
  <div
    class="settlements-pie settlements-pie-chart-general"
    style="max-width: 800px; margin: auto; canvas-antialiasing: true"
  >
    <e-charts
      v-if="!loadingPieChart"
      ref="pieChartTransactions"
      :options="getOption"
      autoresize
      @legendselectchanged="handleLegendSelected"
    />
    <b-row v-else>
      <b-col
        cols="12"
        class="d-flex"
        style="height: 400px"
        :class="changeForZoom ? 'flex-column' : ''"
      >
        <div
          class="d-flex flex-column justify-content-center"
          :class="!changeForZoom ? 'legend-style' : 'legend-style-zoom'"
        >
          <div
            :class="!changeForZoom ? 'legend-skeleton' : 'legend-skeleton-zoom'"
          >
            <div class="donut-animate"></div>
          </div>
        </div>
        <div
          class="d-flex"
          :class="!changeForZoom ? 'donut-style' : 'donut-style-zoom'"
        >
          <div class="donut-skeleton">
            <div class="donut-animate"></div>
            <div class="donut-hole"></div>
            <div class="donut-stripe"></div>
          </div>
        </div>
      </b-col>
    </b-row>
    <transaction-pie-chart-detail
      :pie-chart-name="pieChartName"
      :tab-counters="tabCounters"
      :merchant-id="merchantId"
      :merchant-name="merchantName"
      v-if="showTransactionPieChartDetail"
      @hidden="(showTransactionPieChartDetail = false), refreshSettlements()"
      @on-fix-transaction="getDailySettlementsPie"
    />
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import ECharts from "vue-echarts";
import TransactionPieChartDetail from "@/views/commons/components/reports/tabs/daily-payment-report/components/TransactionPieChartDetail.vue";
export default {
  props: {
    dateRange: {
      type: Object,
      default: () => {
        return {
          start: null,
          end: null,
        };
      },
    },
    merchantId: {
      type: Number,
      default: null,
    },
    merchantName: {
      type: String,
      default: null,
    },
  },

  components: {
    ECharts,
    TransactionPieChartDetail,
  },
  data() {
    return {
      window: window,
      itemsConfig: [
        {
          color: "#2453BA",
          label: "Matched",
          value: 0,
          total_amount: 0,
          child: [],
        },

        {
          color: "#ff3d60",
          label: "Unmatched",
          value: 0,
          total_amount: 0,
          child: [],
        },
      ],
      option: {},
      showTransactionPieChartDetail: false,
      pieChartName: {},
      tabCounters: [],
      loadingPieChart: false,
      segments: 12,
      changeForZoom: false,
    };
  },
  computed: {
    isDarkTheme() {
      return this.$store.state.appConfig.layout.skin === "dark";
    },
    ...mapState({
      windowWidth: (state) => state.app.windowWidth,
      S_GET_DAILY_SETTLEMENT_PIE: (state) =>
        state["ManagementReportStore"].S_GET_DAILY_SETTLEMENT_PIE,
    }),
    ...mapGetters({
      G_DAILY_SETTLEMENTS_DATE_RANGE:
        "ManagementReportStore/G_DAILY_SETTLEMENTS_DATE_RANGE",
      G_GET_DAILY_SETTLEMENT_PIE:
        "ManagementReportStore/G_GET_DAILY_SETTLEMENT_PIE",
    }),
    getOption() {
      return this.option;
    },
  },
  async mounted() {
    this.option = {
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      legend: {
        top: "center",
        left: "left",
        orient: "vertical",
        align: "auto",
        itemWidth: 30,
        itemHeight: 30,
        itemGap: 24,
        backgroundColor: this.isDarkTheme ? "#272729" : "#dfdfdf",
        borderRadius: 10,
        padding: 20,
        data: [],
        textStyle: {
          color: this.isDarkTheme ? "#FFFFFF" : "#17171A",
          rich: {
            a: {
              height: 20,
              fontWeight: "bold",
              fontSize: 15,
            },
            b: {
              height: 10,
              fontSize: 12,
            },
          },
        },
        width: "auto",
        height: "auto",
        formatter: (name) => {
          const item = this.G_GET_DAILY_SETTLEMENT_PIE.find(
            (item) => item.name == name
          );
          const amount = item.total_amount.toString().replace(".", ",");
          return [
            "{a|" + name + "}",
            "{b|( " + item.value + " transactions - $ " + amount + ")}",
          ].join("\n");
        },
      },
      series: [
        {
          name: "Total",
          type: "pie",
          radius: ["40%", "70%"],
          center: ["60%", "50%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          data: [],
          itemStyle: {
            color: (element) => {
              return this.G_GET_DAILY_SETTLEMENT_PIE[element.dataIndex].color;
            },
            borderWidth: 10,
            borderRadius: 30,
            borderColor: this.isDarkTheme ? "#17171A" : "#FFFFFF",
          },
          labelLine: {
            show: false,
          },
          right: "right",
          width: "75%",
        },
      ],
    };
    await this.getDailySettlementsPie();
  },
  methods: {
    ...mapActions({
      A_GET_DAILY_SETTLEMENT_PIE:
        "ManagementReportStore/A_GET_DAILY_SETTLEMENT_PIE",
      A_GET_DAILY_SETTLEMENT: "ManagementReportStore/A_GET_DAILY_SETTLEMENT",
    }),
    async refreshSettlements() {
      await this.A_GET_DAILY_SETTLEMENT({
        range: this.G_DAILY_SETTLEMENTS_DATE_RANGE,
      });
    },
    async getDailySettlementsPie() {
      try {
        this.loadingPieChart = true;
        await this.A_GET_DAILY_SETTLEMENT_PIE({
          from: this.isoFormatToDate(
            this.G_DAILY_SETTLEMENTS_DATE_RANGE.startDate
          ),
          to: this.isoFormatToDate(this.G_DAILY_SETTLEMENTS_DATE_RANGE.endDate),
          merchant_id: this.merchantId,
        });
        this.addPreloader();
        this.option.series[0].data = this.G_GET_DAILY_SETTLEMENT_PIE;
        this.option.legend.data = this.G_GET_DAILY_SETTLEMENT_PIE.map(
          (obj) => obj.name
        );
        this.S_GET_DAILY_SETTLEMENT_PIE[0].color = this.itemsConfig[0].color;
        this.S_GET_DAILY_SETTLEMENT_PIE[0].label = this.itemsConfig[0].label;
        this.S_GET_DAILY_SETTLEMENT_PIE[1].color = this.itemsConfig[1].color;
        this.S_GET_DAILY_SETTLEMENT_PIE[1].label = this.itemsConfig[1].label;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
        this.loadingPieChart = false;
      }
    },
    handleLegendSelected(items) {
      const item = this.G_GET_DAILY_SETTLEMENT_PIE.find(
        (item) => item.name == items.name
      );
      this.S_GET_DAILY_SETTLEMENT_PIE[0].color = this.itemsConfig[0].color;
      this.S_GET_DAILY_SETTLEMENT_PIE[0].label = this.itemsConfig[0].label;
      this.S_GET_DAILY_SETTLEMENT_PIE[1].color = this.itemsConfig[1].color;
      this.S_GET_DAILY_SETTLEMENT_PIE[1].label = this.itemsConfig[1].label;
      this.$refs.pieChartTransactions.chart.setOption({ animation: false });
      // Re-select what the user unselected
      this.$refs.pieChartTransactions.chart.dispatchAction({
        type: "legendSelect",
        name: items.name,
      });
      this.$refs.pieChartTransactions.chart.setOption({ animation: true });
      this.pieChartName = items.name;
      this.tabCounters = item.child;
      this.showTransactionPieChartDetail = true;
    },
    isoFormatToDate(isoDate) {
      return new Date(isoDate).toISOString().substring(0, 10);
    },
    onFixTransaction() {
      this.$emit("on-fix-transaction");
    },
  },
  watch: {
    isDarkSkin: {
      handler: function (val, oldVal) {
        this.option.series[0].itemStyle.borderColor = val
          ? "#17171A"
          : "#FFFFFF";

        this.option.legend.textStyle.color = val ? "#FFFFFF" : "#17171A";
        this.option.legend.backgroundColor = val ? "#272729" : "#dfdfdf";
      },
      deep: true,
    },

    windowWidth: {
      handler: function (val) {
        if (val <= 1450) {
          this.changeForZoom = true;
          this.option.legend.orient = "horizontal";
          this.option.legend.top = "top";
          this.option.legend.left = "center";
          this.option.series[0].center = ["35%", "60%"];
        } else {
          this.changeForZoom = false;
          this.option.legend.orient = "vertical";
          this.option.legend.top = "center";
          this.option.legend.left = "left";
          this.option.series[0].center = ["60%", "50%"];
        }
      },
    },
    G_GET_DAILY_SETTLEMENT_PIE: {
      handler: function (val) {
        this.option.series[0].data = this.G_GET_DAILY_SETTLEMENT_PIE;
        this.option.legend.data = this.G_GET_DAILY_SETTLEMENT_PIE.map(
          (obj) => obj.name
        );
        this.S_GET_DAILY_SETTLEMENT_PIE[0].color = this.itemsConfig[0].color;
        this.S_GET_DAILY_SETTLEMENT_PIE[0].label = this.itemsConfig[0].label;
        this.S_GET_DAILY_SETTLEMENT_PIE[1].color = this.itemsConfig[1].color;
        this.S_GET_DAILY_SETTLEMENT_PIE[1].label = this.itemsConfig[1].label;
      },
    },
  },
};
</script>

<style lang="scss">
$color-donuts: #e0e0e0;
$color-donuts-dark: #141417;
$color-layout: #fff;
$color-dark-layout: #17171a;
.settlements-pie-chart-general {
  .echarts {
    width: 100% !important;
  }
  .legend-style {
    width: 40%;
    height: 100%;
  }
  .donut-style {
    width: 60%;
    height: 100%;
  }
  .legend-style-zoom {
    width: 100%;
    height: 30%;
  }
  .donut-style-zoom {
    width: 100%;
    height: 70%;
  }
  .donut-skeleton {
    margin: auto;
    width: 270px;
    height: 270px;
    border-radius: 50%;
    position: relative;
    background-color: $color-donuts; /* Color de la rosquilla */
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .donut-hole {
    width: 180px; /* Ajusta el tamaño del agujero */
    height: 180px; /* Ajusta el tamaño del agujero */
    background-color: $color-layout; /* Haz el centro transparente */
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .donut-animate {
    width: 200px;
    height: 300px;
    right: 0;
    left: 0;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.4),
      transparent
    );
    animation: b-skeleton-animate-wave-ltr 1.75s linear infinite;
  }
  .donut-stripe {
    height: 300px;
    width: 10px;
    background: $color-layout;
    position: Absolute;
  }

  .legend-skeleton {
    width: 200px;
    height: 120px;
    border-radius: 10%;
    position: relative;
    background-color: $color-donuts;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .legend-skeleton-zoom {
    width: 400px;
    height: 70px;
    margin: auto;
    margin-top: 0;
    border-radius: 10px;
    position: relative;
    background-color: $color-donuts;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .b-skeleton-animate-wave {
    border-radius: 10px;
  }
}

.dark-layout {
  .settlements-pie-chart-general {
    .donut-skeleton {
      width: 270px;
      height: 270px;
      border-radius: 50%;
      position: relative;
      background-color: $color-donuts-dark;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .donut-hole {
      width: 180px;
      height: 180px;
      background-color: $color-dark-layout;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .donut-stripe {
      height: 300px;
      width: 10px;
      background: $color-dark-layout;
      position: Absolute;
    }

    .legend-skeleton {
      width: 200px;
      height: 120px;
      border-radius: 10%;
      position: relative;
      background-color: $color-donuts-dark;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .legend-skeleton-zoom {
      width: 400px;
      height: 70px;
      margin: auto;
      margin-top: 0;
      border-radius: 10px;
      position: relative;
      background-color: $color-donuts-dark;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>