<template>
  <div
    class="dashboard__card"
    :class="isDarkSkin ? 'dashboard__card--dark' : ''"
  >
    <div class="card-charge">
      <div class="card-charge__header">
        <div class="charge-logo">
          <img :src="imgServices" alt="img_services" />
        </div>
        <h2 class="title" :class="isDarkSkin ? 'title--dark' : ''">
          Monthly Payments
        </h2>
      </div>
      <div class="card-charge__body">
        <div
          v-for="(item, i) in dataMontlyPayments"
          :key="i"
          class="charge"
          @click="ModalMonthlyPayment(item)"
          :class="isDarkSkin ? 'charge__dark' : ''"
        >
          <div class="charge__programs">
            <img :src="imgPrograms(item.program_id)" alt="img_services" />
          </div>
          <div class="charge__details" style="padding-top: 10px">
            <h3
              class="charge__details--program d-flex justify-content-between"
              :class="isDarkSkin ? 'subtitle-dark' : ''"
            >
              <div>{{ item.program_name }} ({{ item.c_paid_payments }})</div>
              <div>
                <span class="value">{{ item.paid_payments }} </span>
                <span>({{ item.progress }}%)</span>
              </div>
            </h3>
            <!-- <b-progress :value="item.progress" /> -->
          </div>
        </div>
      </div>
      <detail-monthly-payment
        v-if="showModalMonthlyPayment"
        :send-year="dataYear"
        :send-month="dataMonth"
        :send-programs-id="idProgram"
        :send-programs="arrPrograms"
        @close="showModalMonthlyPayment = false"
      />
    </div>
  </div>
</template>
<script>
import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service";
import DetailMonthlyPayment from "@/views/ceo/dashboard/modals/monthly-payments/DetailMonthlyPayments.vue";

export default {
  components: {
    DetailMonthlyPayment,
  },
  props: {
    selectYear: {
      type: Number,
      default: null,
      required: false,
    },
    selectMonth: {
      type: Number,
      default: null,
      required: false,
    },
    listPrograms: {
      type: Array,
      required: false,
      default: [],
    },
  },
  data() {
    return {
      imgServices: require("@/assets/images/icons/dashboard-ceo/services.svg"),
      imgDebtSolution: require("@/assets/images/icons/dashboard-ceo/logo-debt-solution.svg"),
      imgBoostCredit: require("@/assets/images/icons/dashboard-ceo/logo-boost-credit.svg"),
      imgCreditExperts: require("@/assets/images/icons/dashboard-ceo/logo-credit-experts.svg"),
      imgBusiness: require("@/assets/images/icons/dashboard-ceo/logo-business.svg"),
      imgTaxResearch: require("@/assets/images/icons/dashboard-ceo/logo-tax-research.svg"),
      imgSpecialist: require("@/assets/images/icons/dashboard-ceo/logo-specialist.svg"),
      imgKeyBook: require("@/assets/images/icons/dashboard-ceo/logo-keybook.svg"),
      value: 75,
      showModalMonthlyPayment: false,
      objCharge: [
        {
          slug: "DS",
          program: "Debt Solution",
          value: 1258,
          percentage: 80,
        },
        {
          slug: "BC",
          program: "Boost Credit",
          value: 4523,
          percentage: 70,
        },
        {
          slug: "CE",
          program: "Credit Experts",
          value: 1125,
          percentage: 60,
        },
        {
          slug: "BU",
          program: "Business",
          value: 1252,
          percentage: 70,
        },
        {
          slug: "TR",
          program: "Tax Research",
          value: 1258,
          percentage: 80,
        },
        {
          slug: "SP",
          program: "Specialist",
          value: 1278,
          percentage: 80,
        },
      ],
      dataMontlyPayments: [],
      arrPrograms: [],
      idProgram: null,
    };
  },

  watch: {
    selectMonth(newVal) {
      this.dataMonth = newVal;
      this.getMontlyPayments();
    },
    selectYear(newVal) {
      this.dataYear = newVal;
      this.getMontlyPayments();
    },
  },
  async created() {
    this.dataMonth = this.selectMonth;
    this.dataYear = this.selectYear;
    await this.getMontlyPayments();
  },

  methods: {
    ModalMonthlyPayment(item) {
      this.arrPrograms = this.listPrograms;
      this.idProgram = item.program_id;
      this.showModalMonthlyPayment = true;
    },
    async getMontlyPayments() {
      try {
        const params = {
          month: this.dataMonth,
          year: this.dataYear,
        };
        const { data } = await DashboardServive.getMonthlyPayments(params);
        this.dataMontlyPayments = data;
      } catch (error) {
        console.log(error);
      }
    },
    imgPrograms(program_id) {
      switch (program_id) {
        case 1:
          return this.imgBusiness;
        case 2:
          return this.imgBoostCredit;
        case 3:
          return this.imgCreditExperts;
        case 4:
          return this.imgDebtSolution;
        case 5:
          return this.imgTaxResearch;
        case 7:
          return this.imgSpecialist;
        case 8:
          return this.imgKeyBook;
        default:
          return this.imgKeyBook;
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$color-primary: #5f5f5f;
$color-secondary: #ffffff;
$bg-card-white: #ffffff;
$bg-card-dark: #111113;
$box-shadow-white: #00000026;

.dashboard__card {
  box-shadow: 0px 0px 15px 0px $box-shadow-white;
  border-radius: 15px;
  margin-bottom: 27px;
  padding: 18px;
  margin-top: 0px !important;
  background-color: $bg-card-white;

  &--dark {
    background-color: $bg-card-dark;
    box-shadow: 0px 0px 22.935px 0px rgba(255, 249, 249, 0.1);
  }

  .card-charge {
    &__header {
      display: flex;
      padding-left: 8px;

      .charge-logo {
        margin-right: 5px;
        width: 43px;
        height: 43px;
        border-radius: 10px;
        background-color: #a7c5ff;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 32px;
          height: 32px;
        }
      }

      .title {
        display: flex;
        margin: 0px;
        justify-content: center;
        align-items: center;
        color: #3d3d3d;
        font-family: Montserrat;
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 500;
        line-height: 122.25%;
        margin-left: 5px;

        &--dark {
          color: $color-secondary;
        }
      }
    }

    &__body {
      .charge {
        display: flex;
        margin: 10px auto;
        padding: 6px 8px;
        border-radius: 12px;

        &:hover {
          background-color: #eeeeee;
          cursor: pointer;
        }

        &__programs {
          width: 47px;
          height: 40px;
          background-color: #e9e9e9;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 35px;
            height: 35px;
          }
        }

        &__details {
          width: 100%;
          margin: 0px 13px;

          &--program {
            margin: 0px;
            color: #5f5f5f;
            font-family: Montserrat;
            font-size: 1.1rem;
            font-style: normal;
            font-weight: 500;
            margin-bottom: 5px;

            .value {
              font-weight: 700;
              margin-left: 3px;
              margin-right: 3px;

              &::before {
                content: "$";
              }
            }
          }

          .subtitle-dark {
            color: #b4b4b4;
          }
        }
      }

      .charge__dark:hover {
        background-color: #1b1b1b;
      }
    }
  }
}
</style>
