<template>
  <div class="card-retainer-fee">
    <template v-for="(item, index) in retainerFee">
      <div :key="index" class="w-100" @click="$emit('open', item)">
        <body-header-card
          :title="item.title"
          :amount="item.amount"
          :icon-name="item.iconName"
          :bg-color-icon="item.bgColorIcon"
        />
      </div>
    </template>
  </div>
</template>
<script>
import BodyHeaderCard from "@/views/debt-solution/views/dashboard/components/BodyHeaderCard.vue";
import DashboardService from "@/views/debt-solution/views/dashboard/service/dashboard.service.js";
export default {
  components: {
    BodyHeaderCard,
  },
  props: {
    programId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      retainerFee: [
        {
          title: "TOTAL RETAINER FEE",
          amount: 0,
          iconName: "retainer-total-fee",
          bgColorIcon: "bg-primary",
          type: 1,
        },
        {
          title: "TOTAL PAYMENTS",
          amount: 0,
          iconName: "retainer-total-payments",
          bgColorIcon: "bg-success",
          type: 2,
        },
        {
          title: "TOTAL PENDING",
          amount: 0,
          iconName: "retainer-total-pending",
          bgColorIcon: "bg-warning",
          type: 3,
        },
      ],
    };
  },
  created() {
    this.getRetainerFee();
  },
  methods: {
    async getRetainerFee() {
      try {
        this.addPreloader();
        const params = {
          program_id: this.programId,
        };
        const data = await DashboardService.getDebtSolutionRetainerFee(params);
        this.retainerFee[0].amount = data.fee;
        this.retainerFee[1].amount = data.amount;
        this.retainerFee[2].amount = data.pending;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
<style lang="scss">
.card-retainer-fee {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
</style>