var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modal-preview-ad",attrs:{"size":"xsm","hide-footer":""},on:{"hidden":function($event){return _vm.$emit('hidden')}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('span',{staticClass:"text-uppercase font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.adName)+" ")])]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"modal-preview-ad"},[(
            (_vm.type === 'ad_preview' || _vm.type === 'preview_video_in_meta') &&
            _vm.iframeAd
          )?_c('div',{staticClass:"w-100 ad-preview-iframe",class:_vm.type,domProps:{"innerHTML":_vm._s(_vm.iframeAd)}}):_vm._e(),(
            (_vm.type === 'preview_image_in_meta' ||
              _vm.type === 'preview_image_in_soft') &&
            _vm.imageUrl
          )?_c('div',[_c('img',{staticClass:"w-100",attrs:{"src":_vm.imageUrl,"alt":"preview_image"}})]):_vm._e()])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }