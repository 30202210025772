export default [
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Program",
    model: null,
    options: [
      { value: 0, label: "CRM" },
      { value: 1, label: "Business" },
      { value: 2, label: "Boost Credit" },
      { value: 3, label: "Credit Experts" },
      { value: 4, label: "Debt Solution" },
      { value: 5, label: "Tax Research" },
      { value: 6, label: "Court Info" },
      { value: 7, label: "Specialist" },
      { value: 8, label: "KeyBook" },
      { value: 9, label: "Paragon" },
    ],
    reduce: "value",
    selectText: "label",
    cols: 12,
  },
]
