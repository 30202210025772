<template>
  <b-modal
    ref="ticketsModal"
    modal-class="modal-primary"
    size="xls"
    header-bg-variant="transparent border-bottom border-bottom-2"
    header-class="p-0"
    @hidden="closeModal()"
    hide-footer
    centered
    no-close-on-backdrop
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <div class="d-flex align-items-center container-title">
          <span> {{ getTitleModal }}</span>
        </div>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="closeModal()"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>
    <filter-slot
      :filter="[]"
      :top-paginate="false"
      :total-rows="FiltersForSlot.totalRows"
      :paginate="FiltersForSlot.paginate"
      :start-page="FiltersForSlot.startPage"
      :to-page="FiltersForSlot.toPage"
      :filter-principal="FiltersForSlot.filterPrincipal"
      @reload="$refs['ticketsTable'].refresh()"
      class="filter-slot-new-customization"
      :show-minimal="type != 1"
    >
      <template #buttons-filter-end v-if="type != 1">
        <div style="min-width: 280px" class="ml-1">
          <div class="absolute-linker" :class="{ dark: isDarkSkin }">
            <div
              class="d-flex justify-content-between align-items-center h-full px-1"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="20"
                class="cursor-pointer"
                @click="prev"
              />
              <span class="font-size:15px">
                {{ months[dataMonth - 1] }} {{ dataYear }}
              </span>
              <feather-icon
                icon="ChevronRightIcon"
                size="20"
                class="cursor-pointer"
                @click="next"
              />
            </div>
          </div>
        </div>
        <div class="total-cb" :class="type == 3 ? 'annuled' : ''">
          TOTAL: {{ FiltersForSlot.totalRows }}
        </div>
      </template>
      <b-table
        ref="ticketsTable"
        slot="table"
        class="position-relative table-new-customization"
        :items="myProvider"
        :fields="visibleFields"
        :busy.sync="isBusy"
        primary-key="id"
        responsive="md"
        sticky-header="50vh"
        show-empty
        no-provider-filtering
        :per-page="FiltersForSlot.paginate.perPage"
        :current-page="FiltersForSlot.paginate.currentPage"
      >
        <template #cell(module)="data">
          <img
            :id="`show-program-origin-${data.item.id}`"
            :src="`/assets/${data.item.icons}`"
            alt="Logo"
            height="35"
            width="35"
          />
          <b-tooltip
            :target="`show-program-origin-${data.item.id}`"
            triggers="hover"
            placement="right"
          >
            <div class="d-flex tooltip-origin-program-container">
              <div class="tooltip-origin-program-from">
                {{ "Module:" }}
              </div>
              <div class="tooltip-origin-program-name">
                {{ data.item.module }}
              </div>
            </div>
          </b-tooltip>
        </template>

        <template #cell(ticket_id)="{ item }">
          <span
            class="text-primary font-weight-bolder clickable"
            @click="viewTicket(item.ticket_id)"
          >
            {{ item.ticket_id }}
          </span>
          <br />
          <span
            v-if="type == 1"
            class="badget-wo-color clickable"
            style="margin-top: 5px"
            :style="{
              backgroundColor: `rgba(${hexToRgb(
                item.status_name == 'In Progress' && !item.accept_paragon
                  ? '#ff9f43'
                  : item.status_color
              )}, 0.3)`,
              color:
                item.status_name == 'In Progress' && !item.accept_paragon
                  ? '#ff9f43'
                  : item.status_color,
            }"
          >
            {{
              item.status_name == "In Progress" && !item.accept_paragon
                ? "In Paragon"
                : item.status_name
            }}
          </span>
        </template>

        <template #cell(title)="data">
          <span>{{
            data.item.title.length > 50
              ? data.item.title.substring(0, 50) + "..."
              : data.item.title
          }}</span>
        </template>

        <template #cell(requested_by)="data">
          <span>{{ data.item.user }}</span>
          <br />
          <span>{{ data.item.created_at | myGlobal }}</span>
        </template>

        <template #cell(due_date)="data">
          <template v-if="type == 1">
            <div
              v-if="!data.item.due_date"
              class="d-flex align-items-center justify-content-center"
            >
              <span class="text-on-review" style="font-size: 14px">
                {{
                  data.item.status_name === "In Progress" &&
                  !data.item.accept_paragon
                    ? "In Paragon"
                    : data.item.status_name
                }}</span
              >
            </div>
            <Timer
              v-else
              :created_at="data.item.created_at"
              :special="data.item.special"
              :due_date="data.item.due_date"
            />
          </template>
          <span v-if="type == 2">
            {{ data.item.completed_at | myGlobal }}
          </span>

          <span v-if="type == 3">
            {{ data.item.deleted_at | myGlobal }}
          </span>

          <span v-if="data.item.status_id == 4"> - </span>
        </template>
      </b-table>
    </filter-slot>

    <ModalDetails
      ref="modalDetails"
      @modalInformation="modalMoreInformation"
      :ticket_id="ticket_id"
      v-if="modalDetails"
      @close="modalDetails = false"
    />
  </b-modal>
</template>
<script>
import ModalDetails from "@/views/commons/components/helpdesk/components/modals/ModalDetailsKanban.vue";
import Timer from "@/views/commons/components/helpdesk/components/Timer.vue";
import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service.js";
import { mapGetters } from "vuex";
export default {
  components: {
    ModalDetails,
    Timer,
  },
  props: {
    type: {
      type: Number,
      default: 1,
      required: true,
    },
    month: {
      type: Number,
      required: true,
    },
    year: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      Fields: [
        {
          key: "module",
          label: "Module",
          tdClass: "text-center",
          thClass: "text-center",
          visible: true,
        },
        {
          key: "ticket_id",
          label: "TICKET",
          tdClass: "text-center",
          thClass: "text-center",
          visible: true,
        },
        {
          key: "title",
          label: "TITLE",
          thStyle: { width: "25%" },
          visible: true,
        },
        {
          key: "requested_by",
          label: "Requested By",
          tdClass: "text-center",
          thClass: "text-center",
          visible: true,
        },
        {
          key: "due_date",
          label: "Due Date",
          tdClass: "text-center",
          thClass: "text-center",
          visible: true,
        },
      ],
      FiltersForSlot: {
        totalRows: 0,
        paginate: {
          perPage: 10,
          currentPage: 1,
        },
        startPage: 1,
        toPage: 1,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Search by Ticket, Title, Description...",
          model: "",
        },
      },
      isBusy: false,
      modalDetails: false,
      ticket_id: null,
      dataYear: null,
      dataMonth: null,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },
  mounted() {
    this.toggleModal("ticketsModal");
  },
  created() {
    this.dataYear = this.year;
    this.dataMonth = this.month;
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async myProvider() {
      const data = await DashboardServive.getTicketsDetails({
        p_search: this.FiltersForSlot.filterPrincipal.model,
        p_per_page: this.FiltersForSlot.paginate.perPage,
        p_page: this.FiltersForSlot.paginate.currentPage,
        p_type: this.type,
        p_month: this.dataMonth,
        p_year: this.dataYear,
      });
      const items = data.data || [];
      this.FiltersForSlot.totalRows = data.total || 0;
      return items;
    },
    viewTicket(ticket) {
      this.ticket_id = ticket;
      this.modalDetails = true;
    },
    async next() {
      if (this.dataMonth < 12) {
        this.dataMonth++;
      } else {
        this.dataMonth = 1;
        this.dataYear++;
      }
      await this.refresh();
    },
    async prev() {
      if (this.dataMonth > 1) {
        this.dataMonth--;
      } else {
        this.dataMonth = 12;
        this.dataYear--;
      }
      await this.refresh();
    },
    async refresh() {
      this.$refs["ticketsTable"].refresh();
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    visibleFields() {
      const titles = {
        1: "Due Date",
        2: "Completed At",
        3: "Annuled At",
      };
      this.Fields[4].label = titles[this.type];
      if (this.type == 3) {
        this.Fields[3].visible = false;
      } else {
        this.Fields[3].visible = true;
      }
      return this.Fields.filter((field) => field.visible);
    },
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
    getTitleModal() {
      return this.type == 1
        ? "Tickets Pending"
        : this.type == 2
        ? "Tickets Completed"
        : "Tickets Annuled";
    },
  },
};
</script>

<style lang="scss" scoped>
.badget-wo-color {
  padding: 3px 6px;
  border-radius: 5px;
  font-weight: bold;
}

.header-modal {
  position: relative;
  padding: 20px 0 20px 15px;
  background: rgba(22, 84, 204, 1);
  width: 100%;
  font-size: 22px;
  display: flex;
  justify-content: space-betweeen;

  span {
    font-size: 1.6rem;
    font-weight: bold;
    margin-left: 8px;
    color: #ffffff;
  }

  .container-icons {
    background-color: #ffffff;
    padding: 2.7px 6.37px;
    border-radius: 0.357rem;
    width: 31px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    transition: all 0.23s ease 0.1s;

    &--dark {
      background-color: #17171a;
    }

    &:hover {
      transform: translate(-7px, 5px);
      cursor: pointer;
    }
  }
}

.tooltip-origin-program-from {
  padding-top: 3px;
  padding-left: 5px;
  font-size: 14px;
  font-weight: 900;
  margin-right: 5px;
}

.tooltip-origin-program-name {
  padding-top: 3px;
  font-size: 14px;
  font-weight: 700;
  margin-right: 5px;
}

.absolute-linker {
  width: 16rem;
  background: #fafafa;
  height: 3.714rem;
  top: 0;
  right: 0;
  background: #fafafa;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  &.dark {
    background: #242425;
  }
}
.total-cb {
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  font-size: 15px;
  font-weight: 600;
  background: rgba(0, 231, 104, 0.185);
  color: #007506;
  &.annuled {
    background: rgba(231, 0, 0, 0.233);
    color: #a70404;
  }
}
</style>