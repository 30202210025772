<template>
  <b-modal
    ref="myModal"
    v-model="onControl"
    header-bg-variant="transparent border-bottom border-bottom-2"
    size="xlg"
    header-class="p-0"
    title="Payroll Detail"
    modal-class="modal-primary modal-chargeback"
    hide-footer
    scrollable
    @hidden="close"
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <span>Charge Backs Details ({{ titleMonthYear }})</span>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="close"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>

    <filter-slot
      :filter="filter"
      :top-paginate="false"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="refresh"
      class="filter-slot-new-customization"
      show-minimal
    >
      <template #buttons-filter-end>
        <div style="min-width: 280px" class="ml-1">
          <div class="absolute-linker" :class="{ dark: isDarkSkin }">
            <div
              class="d-flex justify-content-between align-items-center h-full px-1"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="20"
                class="cursor-pointer"
                @click="prev"
              />
              <span class="font-size:15px">
                {{ months[dataMonth - 1] }} {{ dataYear }}
              </span>
              <feather-icon
                icon="ChevronRightIcon"
                size="20"
                class="cursor-pointer"
                @click="next"
              />
            </div>
          </div>
        </div>
        <div class="total-cb">TOTAL: {{ totalRows }}</div>
      </template>
      <template #table>
        <b-table
          ref="refTableExpensesDetail"
          no-border-collapse
          class="position-relative table-new-customization"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="searchDetailExpenses"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(program_id)="data">
            <div class="text-center">
              <img
                v-b-tooltip.hover.top="data.item.program"
                :src="getProgramImageRoute(data.item.program_id)"
                alt="img_services"
              />
            </div>
          </template>
          <template #cell(amount)="data">
            <div class="align-middle text-center text-money">
              {{ data.item.amount | currency }}
            </div>
          </template>
          <template #cell(penalty)="data">
            <div class="align-middle text-center text-money">
              {{ data.item.penalty | currency }}
            </div>
          </template>
          <template #cell(client)="data">
            <router-link
              class="font-weight-bolder"
              target="_blank"
              :to="
                routerDashboardRedirectioner(
                  data.item.program_id,
                  data.item.client_account_id
                )
              "
            >
              {{ data.item.client }} <br />
              {{ data.item.account }}
            </router-link>
          </template>
          <template #cell(transaction_id)="data">
            <div class="">
              {{ data.item.transaction_id }}
            </div>
          </template>
          <template #cell(status)="data">
            <div class="d-flex flex-column">
              <div>
                <b-badge
                  :variant="
                    data.item.status_id == 1
                      ? 'light-warning'
                      : data.item.status_id == 2
                      ? 'light-success'
                      : 'light-info'
                  "
                >
                  {{ data.item.status }}
                </b-badge>
              </div>
              <div v-if="data.item.status_id != 1">
                [
                <b-badge
                  class="font-italic"
                  style="font-size: 9px"
                  :variant="
                    getVariantStatus(
                      data.item.responsable
                        ? data.item.responsable_id
                        : data.item.dispute_id,
                      data.item.status_id
                    )
                  "
                >
                  {{
                    data.item.responsable
                      ? data.item.responsable
                      : data.item.dispute
                  }}
                </b-badge>
                ]
              </div>
              <div>
                <feather-icon
                  icon="ListIcon"
                  class="text-primary cursor-pointer"
                  size="18"
                  @click="showStatusTracking(data.item.charge_back_id)"
                />
              </div>
            </div>
          </template>
          <template #cell(dispute_response)="data">
            <div class="d-flex flex-column">
              <div v-if="data.item.status_id == 3 && data.item.dispute_id == 3">
                <b-badge
                  :variant="
                    data.item.dispute_response_id == 1
                      ? 'light-warning'
                      : data.item.dispute_response_id == 2
                      ? 'light-success'
                      : 'light-danger'
                  "
                >
                  {{ data.item.dispute_response }}
                </b-badge>
              </div>
              <div v-else>-</div>
            </div>
          </template>
          <template #cell(created_at)="data">
            <div class="align-middle text-center">
              <div class="text-center">
                <span>{{ data.item.created_at | myGlobalDay }}</span>
              </div>
              <div>
                <span>{{ data.item.created_by }}</span>
              </div>
            </div>
          </template>
          <template #cell(action)="row">
            <feather-icon
              @click="row.toggleDetails"
              :icon="row.detailsShowing ? 'EyeOffIcon' : 'EyeIcon'"
              class="cursor-pointer text-primary text-weight-bolder"
              size="18"
              v-b-tooltip.hover.top="
                `${row.detailsShowing ? 'Hide' : 'Show'} Details`
              "
            />

            <tabler-icon
              icon="TicketIcon"
              class="cursor-pointer text-weight-bolder"
              style="margin-left: 5px"
              :class="row.item.ticket_id ? 'text-success' : 'text-secondary'"
              size="18"
              v-b-tooltip.hover.top="
                `${row.item.ticket_id ? 'View' : 'Create'} Ticket`
              "
              @click="selectCustomerTicket(row.item)"
            />
          </template>
          <template #bottom-row>
            <b-th colspan="4" class="fill-bottom-row" />
            <b-th>
              <b-badge variant="primary" class="w-100 text-center">
                TOTAL
              </b-badge>
            </b-th>
            <b-th>
              <b-badge variant="primary" class="w-100 text-center">
                {{ "$ " }}{{ totalAmount | currencyZero }}
              </b-badge>
            </b-th>
            <b-th>
              <b-badge variant="primary" class="w-100 text-center">
                {{ "$ " }}{{ totalPenalty | currencyZero }}
              </b-badge>
            </b-th>
            <b-th class="fill-bottom-row" colspan="2" />
          </template>
          <template #row-details="row">
            <b-card>
              <b-row
                class="text-center text-primary pb-1"
                style="font-weight: 900"
              >
                <b-col cols="6">
                  <span>TRANSACTION</span>
                  <hr />
                </b-col>
                <b-col cols="6">
                  <span>MERCHANT</span>
                  <hr />
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="6"
                  class="px-3 d-flex flex-column"
                  style="border-right: 1px solid #0085ff"
                >
                  <b-row>
                    <b-col cols="12" class="d-flex justify-content-between">
                      <span>TRANSACTION ID :</span>
                      <span class="text-primary font-weight-bolder">{{
                        row.item.transaction_id
                      }}</span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" class="d-flex justify-content-between">
                      <span>TRANSACTION AMOUNT :</span>
                      <span class="text-primary font-weight-bolder"
                        >$ {{ row.item.amount | currencyZero }}</span
                      >
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" class="d-flex justify-content-between">
                      <span>TRANSACTION DATE :</span>
                      <span class="text-primary font-weight-bolder">{{
                        row.item.transaction_date | myGlobalWithHour
                      }}</span>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="6" class="px-3 d-flex flex-column">
                  <b-row>
                    <b-col cols="12" class="d-flex justify-content-between">
                      <span>MERCHANT NAME :</span>
                      <span class="text-primary font-weight-bolder">{{
                        row.item.merchant_name
                      }}</span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" class="d-flex justify-content-between">
                      <span>AUTH CODE :</span>
                      <span class="text-primary font-weight-bolder">{{
                        row.item.auth_code
                      }}</span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" class="d-flex justify-content-between">
                      <span>REASON CODE :</span>
                      <span class="text-primary font-weight-bolder"
                        >{{ row.item.reason_code }}
                      </span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <modal-create-customer-ticket
      v-if="showModalCreateCustomerTicket"
      :customer-ticket="customerTicketSelected"
      :customer-id="customerId"
      :set-motive="101"
      @hidden="showModalCreateCustomerTicket = false"
      @saved="refresh"
    />

    <modal-view-ticket-customer-details
      v-if="showModalViewTicketCustomerDetails"
      :customer-ticket="customerTicketSelected"
      @hidden="showModalViewTicketCustomerDetails = false"
    />

    <charge-back-status-tracking
      v-if="openModalStatusTracking"
      :id-charge-back="chargeBackId"
      @close="openModalStatusTracking = false"
    />
  </b-modal>
</template>

<script>
import DashboardService from "@/views/ceo/service-dashboard/dashboard.service";
import ProgramsFilter from "@/views/ceo/dashboard/data/programs-filter.js";
import ChargeBackStatusTracking from "@/views/ceo/dashboard/modals/expenses/ChargeBackStatusTracking.vue";
import ModalCreateCustomerTicket from "@/views/commons/components/customer-tickets/components/modal/ModalCreateCustomerTicket.vue";
import ModalViewTicketCustomerDetails from "@/views/commons/components/customer-tickets/components/modal/ModalViewTicketCustomerDetails.vue";

export default {
  components: {
    ChargeBackStatusTracking,
    ModalCreateCustomerTicket,
    ModalViewTicketCustomerDetails,
  },
  props: {
    sendYear: {
      type: Number,
      default: null,
    },
    sendMonth: {
      type: Number,
      default: null,
    },
    sendPrograms: {
      type: Array,
      required: false,
      default: [],
    },
  },
  data() {
    return {
      onControl: false,
      startPage: 0,
      toPage: 0,
      isBusy: false,
      dataYear: null,
      dataMonth: null,
      titleModal: "",
      idPrograms: null,
      initialProgram: "",
      sortBy: "client_name",
      sortDesc: true,
      filter: ProgramsFilter,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by client name...",
        model: "",
      },
      fields: [
        {
          key: "program_id",
          sortable: false,
          label: "Program",
        },
        {
          key: "client",
          sortable: false,
          label: "Client Name",
        },
        {
          key: "code_id",
          sortable: false,
          label: "Code ID",
          class: "text-center",
          thClass: "text-center",
        },
        {
          key: "transaction_id",
          sortable: false,
          label: "Transaction ID",
          class: "text-center",
          thClass: "text-center",
        },
        {
          key: "status",
          sortable: false,
          label: "Status",
          class: "text-center",
          thClass: "text-center",
        },
        {
          key: "amount",
          sortable: false,
          label: "Chargeback",
          class: "text-center",
          thClass: "text-center",
        },
        {
          key: "penalty",
          sortable: false,
          label: "Penalty",
          class: "text-center",
          thClass: "text-center",
        },
        {
          key: "created_at",
          sortable: false,
          label: "Created At",
          class: "text-center",
          thClass: "text-center",
        },
        {
          key: "action",
          sortable: false,
          label: "Actions",
          class: "text-center",
          thClass: "text-center",
        },
      ],
      chargeDetailItems: [],
      totalRows: 1,
      paginate: {
        perPage: 50,
        currentPage: 1,
      },
      chargeBackId: null,
      openModalStatusTracking: false,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      totalAmount: 0,
      totalPenalty: 0,
      showModalCreateCustomerTicket: false,
      showModalViewTicketCustomerDetails: false,
      customerTicketSelected: {},
      customerId: null,
    };
  },

  computed: {
    titleMonthYear() {
      return `${this.months[this.dataMonth - 1]} ${this.dataYear}`;
    },
  },

  async created() {
    this.onControl = true;
    this.idPrograms = this.sendProgramsId;
    this.dataMonth = this.sendMonth;
    this.dataYear = this.sendYear;
    this.initialProgram = this.sendProgramsInitial;
    this.filter[0].options = this.sendPrograms;
  },

  methods: {
    close() {
      this.$refs["myModal"].hide();
      this.$emit("close");
    },

    selectCustomerTicket(item) {
      if (item.ticket_code) {
        this.openModalViewTicketCustomerDetails(item);
      } else {
        this.openModalCreateCustomerTicket(item);
      }
    },

    async openModalViewTicketCustomerDetails(item) {
      const data = await DashboardService.getCustomerTicket({
        name_text: item.ticket_code,
        user_id: 1,
      });
      if (data.length > 0) {
        this.customerTicketSelected = data[0];
        this.showModalViewTicketCustomerDetails = true;
      } else {
        this.$bvToast.toast("Ticket not found", {
          title: "Error",
          variant: "danger",
        });
      }
    },

    async openModalCreateCustomerTicket(item) {
      this.customerId = item.client_account_id;
      let date = "";
      if (item.created_at) {
        const parsedDate = new Date(item.created_at);
        const mo = parsedDate.getMonth() + 1;
        const da = parsedDate.getDate();
        const an = parsedDate.getFullYear();
        date = `${mo}/${da}/${an}`;
      }
      this.customerTicketSelected = {
        id: item.ticket_id,
        charge_back_id: item.charge_back_id,
        client_name: item.client,
        account: item.account,
        is_client: true,
        text: `TRANSACTION ID: ${item.transaction_id}\nAMOUNT: ${item.amount}\nDATE ${date}`,
      };
      this.showModalCreateCustomerTicket = true;
    },

    async searchDetailExpenses(ctx) {
      const { data } = await DashboardService.getDetailsExpenses({
        year: this.dataYear,
        type_expense: "charge-back",
        program_id: this.filter[0].model,
        month: this.dataMonth,
        npage: ctx.currentPage,
        perpage: ctx.perPage,
        search: this.filterPrincipal.model,
      });

      if (data.data.length === 0) {
        this.chargeDetailItems = [];
        this.totalAmount = 0;
        this.totalPenalty = 0;
      } else {
        this.chargeDetailItems = data.data;
        this.totalAmount = this.chargeDetailItems[0].total_amount;
        this.totalPenalty = this.chargeDetailItems[0].total_penalty;
      }

      this.startPage = data.from;
      this.paginate.currentPage = data.current_page;
      this.paginate.perPage = data.per_page;
      this.totalRows = data.total;
      this.toPage = data.to;
      return this.chargeDetailItems || [];
    },
    routers(program, id) {
      let route = "";
      switch (program) {
        case 1:
          route = `/bussiness/clients/account/${id}`;
          break;
        case 2:
          route = `/boostcredit/clients/account/${id}`;
          break;
        case 3:
          route = `/creditexperts/clients/account/${id}`;
          break;
        case 4:
          route = `/debtsolution/clients/account/${id}`;
          break;
        case 5:
          route = `/taxresearch/clients/account/${id}`;
          break;
        case 7:
          route = `/specialists/clients/account/${id}`;
          break;
        case 8:
          route = `/bookeeping/clients/account/${id}`;
          break;
        case 9:
          route = `/ti/clients/account/${id}`;
          break;
      }
      return route;
    },
    showStatusTracking(id) {
      this.chargeBackId = id;
      this.openModalStatusTracking = true;
    },
    getVariantStatus(sub_status_id, status_id) {
      let variant = "";
      if (status_id == 2) {
        switch (sub_status_id) {
          case 1:
            variant = "light-orange";
            break;
          case 2:
            variant = "light-success";
            break;
          case 3:
            variant = "light-primary";
            break;
          case 4:
            variant = "light-info";
            break;
        }
      } else {
        switch (sub_status_id) {
          case 1:
            variant = "light-warning";
            break;
          case 2:
            variant = "light-primary";
            break;
          case 3:
            variant = "light-success";
            break;
        }
      }

      return variant;
    },
    async next() {
      if (this.dataMonth < 12) {
        this.dataMonth++;
      } else {
        this.dataMonth = 1;
        this.dataYear++;
      }
      await this.refresh();
    },
    async prev() {
      if (this.dataMonth > 1) {
        this.dataMonth--;
      } else {
        this.dataMonth = 12;
        this.dataYear--;
      }
      await this.refresh();
    },
    async refresh() {
      this.$refs["refTableExpensesDetail"].refresh();
    },
  },
};
</script>
<style lang="scss"   >
.modal-chargeback {
  .text-money {
    font-weight: 500;

    &::before {
      content: "$";
    }
  }

  .header-modal {
    position: relative;
    padding: 20px 0 20px 15px;
    background: rgba(22, 84, 204, 1);
    width: 100%;
    font-size: 22px;
    display: flex;

    span {
      font-size: 1.6rem;
      font-weight: bold;
      margin-left: 8px;
      color: #ffffff;
    }

    .container-icons {
      background-color: #ffffff;
      padding: 2.7px 6.37px;
      border-radius: 0.357rem;
      width: 31px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 15px;
      right: 20px;
      transition: all 0.23s ease 0.1s;

      &--dark {
        background-color: #17171a;
      }

      &:hover {
        transform: translate(-7px, 5px);
        cursor: pointer;
      }
    }
  }

  .b-table-bottom-row th:nth-child(3) {
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #0090e7 !important;
  }

  .b-table-bottom-row th:nth-child(2) {
    border-radius: 0px 0px 0px 15px !important;
    margin-left: 5px;
    background: #0090e7 !important;
  }

  .b-table-bottom-row th:nth-child(4) {
    border-radius: 0px 0px 15px 0px !important;
    margin-left: -10px;
    background: #0090e7 !important;
  }
  .fill-bottom-row {
    background-color: #ffffff !important;
  }

  .badge-light-orange {
    color: #ff7005 !important;
  }
  .badge-light-prototype {
    color: #61a804 !important;
  }
  .badge-light-black {
    color: #000 !important;
  }
  .badge-light-testing {
    color: #00d25b !important;
  }
  svg.status-icon.feather {
    margin-top: -3px !important;
  }

  .dark-layout .badge-light-prototype {
    color: #91ff00 !important;
  }
  .dark-layout .badge-light-black {
    color: #fff !important;
  }

  .absolute-linker {
    width: 16rem;
    background: #fafafa;
    height: 3.714rem;
    top: 0;
    right: 0;
    background: #fafafa;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    &.dark {
      background: #242425;
    }
  }
}

.dark-layout {
  .fill-bottom-row {
    background-color: #17171a !important;
    border-top: none !important;
  }
}
</style> 

<style lang="scss" scoped>
.total-cb {
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  font-size: 15px;
  font-weight: 600;
  background: rgba(231, 0, 0, 0.233);
  color: rgb(239, 119, 119);
}
</style>