<template>
  <b-modal
    v-model="showModal"
    :title="getTitle"
    @hidden="closeModal"
    hide-footer
    size="xlg"
    modal-class="custom-modal-amg"
    title-class="h3 text-white"
  >
    <b-card no-body>
      <template v-if="isRejected || isInProcess">
        <b-tabs
          v-model="activeSubTab"
          active-tab-class="p-0 "
          pills
          nav-class="mb-0 mt-1"
          active-nav-item-class="bg-info box-shadow-info border-info info"
        >
          <b-tab
            v-for="(item, index1) in ['AMG', 'CLIENT']"
            :key="index1"
            :active="index1 === 0"
            class="mb-1"
            @click="changeSubTab(index1 + 1, null)"
            lazy
          >
            <template #title>
              {{ item }}
              <span class="ml-2" v-if="totalesExpenses && tab == 1 && isInProcess">
                <feather-icon
                  icon
                  :badge="setCount(item)"
                  badge-classes="badge-danger"
              /></span>
            </template>
            <b-card
              no-body
              class="border-top-info border-3 border-table-radius px-1"
            >
              <card-expenses-request
                :typeExpense="typeExpense"
                :cardId="cardId"
                v-if="index1 === activeSubTab"
                :isRejected="isRejected"
                :isInProcess="isInProcess"
              />
            </b-card>
          </b-tab>
        </b-tabs>
      </template>
      <template v-else>
        <template v-if="cardTabs.length > 0">
          <b-tabs
            v-model="activeSubTab"
            active-tab-class="p-0 "
            pills
            nav-class="mb-0 mt-1"
            active-nav-item-class="bg-info box-shadow-info border-info info"
          >
            <template v-if="!loading">
              <b-tab
                v-for="(item, index1) in cardTabs"
                :key="index1"
                :active="index1 === 0"
                class="mb-1"
                @click="changeSubTab(index1 + 1, item.id)"
                lazy
              >
                <template #title>
                  <div class="d-flex align-items-center" style="gap: 5px">
                    <type-card
                      :type_card="item.type_card"
                      style="height: 28px"
                    />
                    <p class="m-0">{{ item.card }}</p>
                    <span class="ml-2" v-if="totalesExpenses && tab === 1">
                      <feather-icon
                        icon
                        :badge="totalesExpenses[item.id]"
                        badge-classes="badge-danger"
                      />
                    </span>
                  </div>
                </template>
                <b-card
                  no-body
                  class="border-top-info border-3 border-table-radius px-1"
                >
                  <card-expenses-request
                    :typeExpense="null"
                    :cardId="cardId"
                    v-if="index1 === activeSubTab"
                    :isRejected="isRejected"
                    :isInProcess="isInProcess"
                  />
                </b-card>
              </b-tab>
            </template>
            <template v-else>
              <div class="d-flex justify-content-center">
                <span>Loading...</span>
              </div>
            </template>
          </b-tabs>
        </template>
        <template v-else>
          <div class="d-flex justify-content-center align-items-center">
            <img
              src="@/assets/images/noData/no-data-score-card.svg"
              class="custom-img"
              alt="scope"
            />
            <span class="mt-3 text-uppercase custom-span"
              >No data available</span
            >
          </div>
        </template>
      </template>
    </b-card>
  </b-modal>
</template>
<script>
import CreditCards from "@/views/ceo/dashboard/modals/expenses-request/CreditCards.vue";
import Checks from "@/views/ceo/dashboard/modals/expenses-request/Checks.vue";
import CardExpensesRequest from "@/views/ceo/dashboard/modals/expenses-request/CardExpensesRequest.vue";
import { mapActions, mapGetters } from "vuex";
import DashboardService from "@/views/ceo/service-dashboard/dashboard.service";
import TypeCard from "@/views/commons/card/TypeCard.vue";
export default {
  name: "ExpensesRequestDetail",
  props: {
    isRejected: {
      type: Boolean,
      default: false,
      indexSelected: {
        type: Number,
        default: 0,
      },
    },
    isInProcess: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CardExpensesRequest,
    TypeCard,
    CreditCards,
    Checks,
  },
  async created() {
    await this.getCardTabs();
    await this.getCountInProcess();
  },
  data() {
    return {
      tab: 1,
      subtab: 1,
      showModal: true,
      activeSubTab: 0,
      cardTabs: [],
      cardId: null,
      loading: true,
      options: ["CREDIT CARDS", "CHECKS"],
      activeTab: 0,
      totalCount: [],
    };
  },
  computed: {
    ...mapGetters({
      totalesExpenses: "ExpensesRequest/G_TOTALES_EXPENSES",
      refreshCounter: "ExpensesRequest/G_REFRESH_EXPENSES_COUNTER",
      method: "ExpensesRequest/G_METHOD_EXPENSE",
    }),
    typeExpense() {
      switch (this.activeSubTab) {
        case 0:
          return "AMG";
        case 1:
          return "CLIENT";
        default:
          return "NOT FOUND";
      }
    },

    // totalCount() {
    //   const expenses = this.totalesExpenses || {};

    //   return this.isRejected
    //     ? 0
    //     : Object.values(expenses).reduce((acc, curr) => acc + (curr || 0), 0);
    // },
    getTitle() {
      if (this.method == "CHECK") {
        return `CHECKS | ${
          this.isRejected ? "REJECTED" : "IN PROCESS"
        }`;
      } else if (this.method == "E-CHECK") {
        return `E-CHECKS | ${
          this.isRejected ? "NOT COLLECTED" : "IN PENDING APPROVAL"
        }`;
      } else {
        return `CARDS | ${
          this.isRejected ? "REJECTED" : "PENDING APPROVAL"
        }`;
      }
    },
  },
  watch: {
    refreshCounter: {
      async handler(value) {
        await this.getTotales({ status: 2 });
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      getTotales: "ExpensesRequest/getTotales",
    }),
    changeSubTab(index, id) {
      this.subtab = index;
      this.cardId = id;
    },
    closeModal() {
      this.$emit("close");
    },
    async getCardTabs() {
      try {
        this.addPreloader();
        const data = await DashboardService.getCardTab();
        this.cardTabs = data || [];
        this.setInitialCardId();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.loading = false;
        this.removePreloader();
      }
    },
    setInitialCardId() {
      if (this.cardTabs.length > 0) {
        this.cardId = this.cardTabs[0].id;
      }
    },
    async getCountInProcess() {
      try {
        const data = await DashboardService.getCountInProcess();
        this.totalCount = data || [];
        if (data.length > 0) {
          this.countInProcess = data.reduce((acc, curr) => {
            return acc + curr.count;
          }, 0);
        }
      } catch (error) {}
    },
    setCount(type) {
      if (this.method == "CREDIT_CARD") {
        this.totalesExpenses[type];
      } else if (this.method == "CHECK") {
        let data = this.totalCount.find((item) => item.type == type);
        return data?.count;
      }
    },
  },
};
</script>
<style scoped>
.custom-img {
  width: 300px !important;
  height: 300px !important;
  opacity: 0.1;
}
.custom-span {
  position: absolute;
  font-size: 15px;
  font-weight: bolder;
  text-align: center;
  letter-spacing: 1px;
  padding: 0px 20%;
  z-index: 2;
  color: #979595;
}
</style>
