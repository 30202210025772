
<template>
  <div>
    <b-list-group>
      <template v-if="G_NOTIFICATION_COUNTER > 2">
        <div class="mb-2 d-flex justify-content-end">
          <b-button
            class="btn btn-sm read-all-notification-btn"
            :class="isDarkSkin ? 'text-white' : 'text-primary'"
            variant="outline-primary"
            @click="clickReadAllNotifications"
          >
            Mark all as read
            <feather-icon
              :badge="
                G_NOTIFICATION_COUNTER > 99 ? '+99' : G_NOTIFICATION_COUNTER
              "
              badge-classes="badge-important"
              class="text-body"
              icon="BellIcon"
              size="19"
            />
          </b-button>
        </div>
      </template>
      <template v-if="!loading">
        <b-list-group-item
          v-for="notification in S_ALL_USER_NOTIFICATIONS"
          :key="notification.id"
          @click="
            notification.status == 1
              ? null
              : clickReadNotification(notification)
          "
        >
          <div class="d-flex w-100 justify-content-between">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                  :class="notification.status == 1 ? '' : 'cursor-pointer'"
                  size="30"
                  :variant="
                    notification.status == 1
                      ? 'light-secondary'
                      : 'light-primary'
                  "
                >
                  <feather-icon
                    size="15"
                    :icon="
                      notification.type == 0
                        ? 'ClipboardIcon'
                        : notification.type == 1
                          ? 'PhoneIcon'
                          : notification.type == 2
                            ? 'DollarSignIcon'
                            : notification.type == 3
                              ? 'FileTextIcon'
                              : 'PhoneForwardedIcon'
                    "
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body v-if="notification.type=== 0 && notification.link === '-'">
                <!--Start: Modal Automatic Payments-->
                <div
                  :class="[
                    'font-weight-bold mb-0 h5 clickable',
                    notification.status == 0
                      ? skin == 'dark'
                        ? 'text-light'
                        : 'text-dark'
                      : '',
                  ]"
                  class="d-flex"
                  @click="openModalAutomaticPayment(notification)"
                >
                  <p v-html="notification.notification" />
                  <feather-icon
                    style="margin-left: 4px; margin-top: 3px"
                    icon="ExternalLinkIcon"
                  />
                </div>
                <!--end: Modal Automatic Payments-->
              </b-media-body>
              <b-media-body
                v-else
                class="my-auto"
                @click="
                  !(notification.type == 11 || notification.type == 0) &&
                    closeNotifications()
                "
              >
                <b-link
                  v-if="notification.type != 11 && notification.type != 0"
                  :class="[
                    'font-weight-bold mb-0 h5',
                    notification.status == 0
                      ? skin == 'dark'
                        ? 'text-light'
                        : 'text-dark'
                      : '',
                  ]"
                  class="d-flex"
                  :to="notificationRoute(notification)"
                >
                  <p v-html="notification.notification" />
                  <feather-icon
                    style="margin-left: 4px; margin-top: 3px"
                    icon="ExternalLinkIcon"
                  />
                </b-link>
                <div
                  v-else
                  :key="notification.id"
                  @click="
                    openOtherNotification(notification, notification.type)
                  "

                  :class="[
                    'font-weight-bold mb-0 h5 clickable',
                    notification.status == 0
                      ? skin == 'dark'
                        ? 'text-light'
                        : 'text-dark'
                      : '',
                  ]"
                  class="d-flex"
               
                >
                  <p v-html="notification.notification" />
                  <feather-icon
                    style="margin-left: 4px; margin-top: 3px"
                    icon="ExternalLinkIcon"
                  />
                </div>
              </b-media-body>
            </b-media>
            <small>{{ notification.created_at | myDateGlobal }}</small>
          </div>
        </b-list-group-item>
      </template>
      <template v-else>
        <b-list-group-item
          v-for="notification in S_ALL_USER_NOTIFICATIONS.length"
          :key="notification.id"
        >
          <div class="d-flex w-100 justify-content-between align-items-center">
            <b-media
              no-body
              class="w-100"
            >
              <b-media-aside
                class="mr-2"
                width="100%"
              >
                <b-skeleton
                  type="avatar"
                  size="30px"
                />
              </b-media-aside>
              <b-media-body class="my-auto">
                <b-skeleton style="margin-top: 9px" />
              </b-media-body>
            </b-media>
            <small />
          </div>
        </b-list-group-item>
      </template>
    </b-list-group>
    <div class="mx-2 mb-2 mt-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span
            class="text-muted"
          >Showing {{ startPage }} to {{ toPage }} of
            {{ totalData }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalData"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <b-modal
      v-model="accountsHoldModal"
      title-class="h3 text-white"
      size="lg"
      centered
      title="NEW CLIENTS STATUS HOLD"
      hide-footer
      modal-class="modal-primary"
    >
      <new-client-status-hold-modal
        :notification="selectedNotification"
        @closeNotifications="accountsHoldModal = false"
      />
    </b-modal>

    <!-- Automatic Payments -->
    <b-modal
      v-model="showModalAutomaticPayments"
      modal-class="modal-primary"
      title="Automatic Payments"
      title-tag="h3"
      size="xlg"
      hide-footer
      @hidden="showModalAutomaticPayments = false"
    >
      <automatic-payments :notification="selectedNotification" />
    </b-modal>

    <b-modal
      v-model="showModal"
      modal-class="modal-primary"
      title="Automatic Payments"
      title-tag="h3"
      size="xlg"
      hide-footer
      @hidden="showModal = false"
    >
      <AutomaticPayments :notification="selectedNotification" />
    </b-modal>
  </div>
</template>

<script>

import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import NewClientStatusHoldModal from "@/layouts/components/navbar/components/notifications/NewClientStatusHoldModal.vue";
import NotificationService from "@/service/notification/index.js";
import AutomaticPayments from "@/layouts/components/navbar/components/notifications/AutomaticPayments.vue";

export default {
  components: {
    NewClientStatusHoldModal,
    AutomaticPayments,

  },
  async mounted() {
    await this.getAllUserNotifications();

  },
  data() {
    return {
      currentPage: 1,
      perPage: 7,
      totalData: 0,
      startPage: 0,
      toPage: 0,
      loading: false,
      selectedNotification: {},
      accountsHoldModal: false,
      showModalAutomaticPayments: false,
      showModal: false,
    };
  },
  async mounted() {
    await this.getAllUserNotifications();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      skin: "appConfig/skin",
      G_NOTIFICATION_COUNTER: "NotificationStore/G_NOTIFICATION_COUNTER",

    }),
    ...mapState({
      S_ALL_USER_NOTIFICATIONS: state => state.NotificationStore.S_ALL_USER_NOTIFICATIONS,
    }),
    notificationRoute: () => item => {
      if (!item.module_id) {
        return item.link;
      }
      // validate target module
      switch (item.module_id) {
        case 26:
          if (item.type == 0) {
            return '/cedigital/sales/calendar';
          }
          return '/cedigital/sales/sales-made';

        default:
          return item.link;
      }
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    ...mapActions({
      A_GET_ALL_USER_NOTIFICATIONS:
        'NotificationStore/A_GET_ALL_USER_NOTIFICATIONS',
      A_UPDATE_NOTIFICATION: 'NotificationStore/A_UPDATE_NOTIFICATION',
    }),
    ...mapMutations({
      DECREASE_NOTIFICATION_COUNTER:
        "NotificationStore/DECREASE_NOTIFICATION_COUNTER",
      DECREASE_ALL_NOTIFICATION_COUNTER:
        "NotificationStore/DECREASE_ALL_NOTIFICATION_COUNTER",

    }),
    async getAllUserNotifications(page = 1) {
      this.loading = true;
      try {
        const data = await this.A_GET_ALL_USER_NOTIFICATIONS({
          id: this.currentUser.user_id,
          page,
        });
        this.currentPage = data.current_page;
        this.perPage = data.per_page;
        this.totalData = data.total;
        this.startPage = data.from;
        this.toPage = data.to;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async clickReadNotification(notification) {
      const { data } = await NotificationService.readNotificationById({
        id: notification.id,
      });
      notification.status = 1;
      if (this.G_NOTIFICATION_COUNTER > 0) {
        this.DECREASE_NOTIFICATION_COUNTER();
      }
    },
    closeNotifications() {
      this.$emit('closeNotifications');
    },
    openModalAccountHold(notification) {
      this.accountsHoldModal = true;
      this.selectedNotification = notification;
    },

    openModalAutomaticPayment(notification) {
      this.selectedNotification = notification;
      this.showModalAutomaticPayments = true;
    },

    async clickReadAllNotifications() {
      this.addPreloader();
      try {
        await NotificationService.readAllNotificationsByIdUser({
          id: this.currentUser.user_id,
        });
        this.DECREASE_ALL_NOTIFICATION_COUNTER();
        this.getAllUserNotifications();
        this.showToast(
          "success",
          "top-right",
          "Success",
          "CheckIcon",
          "All notifications marked as read"
        );
        this.$emit("readAllNotificationsByUser");
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    openOtherNotification(notification, type) {
      this.clickReadNotification(notification);
      if (type == 0 && this.moduleId) {
        this.openModalAutomaticPayments(notification);
      } else if (type === 11) {
        this.openModalAccountHold(notification);
      }
    },
    openModalAutomaticPayments(notification) {
      this.selectedNotification = notification;
      this.showModal = true;

    },
  },
  watch: {
    currentPage(newVal, oldVal) {
      this.getAllUserNotifications(newVal);
    },
  },
};
</script>

<style>
/* .list-group-item-selected {
  background-color: #82868b !important;
  border-color: white !important;
  color: white !important;
  opacity: 0.6 !important;
} */
/* .list-group-item-selected h5,
h4 {
  color: white !important;
} */

.read-all-notification-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
