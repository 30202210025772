<template>
  <div class="meta-comercial-report">
    <filter-slot
      class="filter-slot-new-customization"
      :card-class="'m-0 border-0 p-0'"
      :filter="visibleFilters"
      :total-rows="filterSlot.totalRows"
      :paginate="filterSlot.paginate"
      :start-page="filterSlot.startPage"
      :to-page="filterSlot.toPage"
      :filter-principal="filterSlot.filterPrincipal"
      :top-paginate="false"
      :double-paginate="bottomPaginate"
      :left-col-value="10"
      :right-col-value="2"
      @reload="$refs['comercial-report-table'].refresh()"
    >
      <template #buttons-filter-end>
        <div
          style="min-width: 280px"
          class="ml-1 customized-filter d-flex align-items-center w-100"
        >
          <div
            v-if="!reportSendRange"
            class="absolute-linker"
            :class="{ dark: isDarkSkin }"
          >
            <div
              class="d-flex justify-content-between align-items-center h-full px-1"
              style="font-size: 15px; font-weight: bold"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="20"
                class="cursor-pointer"
                @click="prev"
              />
              <span>
                {{ titleMonthYear }}
              </span>
              <feather-icon
                icon="ChevronRightIcon"
                size="20"
                class="cursor-pointer"
                @click="next"
              />
            </div>
          </div>
          <div
            v-else
            class="d-flex justify-content-between align-items-center h-full px-1"
          >
            <date-range-picker
              ref="picker"
              :opens="'left'"
              control-container-class="custom-datepciker"
              :locale-data="{ firstDay: 0, format: 'mm/dd/yyyy' }"
              :max-date="maxDate"
              :ranges="null"
              v-model="dateRange"
              @update="refresh()"
            >
              <template v-slot:input="picker">
                {{ picker.startDate | myGlobal }} -
                {{ picker.endDate | myGlobal }}
                <feather-icon
                  icon="CalendarIcon"
                  size="19"
                  class="text-success ml-2"
                />
              </template>
            </date-range-picker>
          </div>
          <b-form-radio-group
            id="btn-radios-1"
            class="mr-1"
            v-model="selectedType"
            :options="optionsType"
            button-variant="outline-primary"
            size="md"
            name="radio-btn-outline"
            buttons
            @change="handleTypeSearch()"
          ></b-form-radio-group>
        </div>
      </template>

      <b-table
        id="comercial-report-table"
        ref="comercial-report-table"
        slot="table"
        class="position-relative table-new-customization rc"
        empty-text="No matching records found"
        sticky-header="60vh"
        primary-key="id"
        responsive="md"
        show-empty
        no-border-collapse
        no-provider-filtering
        :busy.sync="isBusy"
        :fields="fields"
        :items="myProvider"
        :per-page="filterSlot.paginate.perPage"
        :current-page="filterSlot.paginate.currentPage"
      >
        <template #thead-top>
          <b-tr class="additional-head">
            <b-th></b-th>
            <b-th colspan="4">
              <div class="w-100 text-center">CREATIVE</div>
            </b-th>
            <b-th colspan="4">
              <div class="w-100 text-center">METAMEDIA</div>
            </b-th>
            <b-th colspan="3">
              <div class="w-100 text-center">CRM</div>
            </b-th>
            <b-th></b-th>
          </b-tr>
        </template>
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(program_id)="data">
          <div
            class="d-flex justify-content-center align-items-center position-relative h-100 w-100"
          >
            <b-badge
              v-if="!data.item.exists_in_soft"
              variant="light-danger"
              class="only-meta-badge"
            >
              Only Meta
            </b-badge>

            <div :class="{ 'pt-1': !data.item.exists_in_soft }">
              <div v-if="data.item.program_name" class="programs_image">
                <b-img
                  :src="getProgramImageRoute(data.item.program_id)"
                  v-b-tooltip.right="data.item.program_name"
                  alt="img_services"
                />
              </div>
              <b-avatar
                v-else
                size="40"
                square
                icon="file-earmark-image-fill"
                class="rounded"
                variant="light"
              />
            </div>
          </div>
        </template>
        <template #cell(state_slug)="data">
          <span v-if="data.item.state_slug">{{ data.item.state_slug }}</span>
          <span v-else>-</span>
        </template>
        <template #cell(flyer_route_thumb)="data">
          <div>
            <b-avatar
              :src="data.item.flyer_route_thumb"
              size="50"
              square
              icon="file-earmark-image-fill"
              class="rounded"
              variant="light"
            />
          </div>
        </template>
        <template #cell(flyer_code)="data">
          <b-badge
            variant="light-primary"
            v-b-tooltip.hover="
              data.item.flyer_code.length > 20 ? data.item.flyer_code : ''
            "
            class="cursor-pointer"
            @click="getAdPreview(data.item)"
          >
            {{ data.item.flyer_code | limitChars(20) }}
            <feather-icon icon="ArrowUpRightIcon" size="20" />
          </b-badge>
        </template>
        <template #cell(investment)="data">
          <span
            :class="{
              'text-success': data.item.investment > 0,
              'text-secondary': data.item.investment <= 0,
            }"
          >
            {{ data.item.investment | formatCurrency }}
          </span>
        </template>
        <template #cell(sources)="data">
          <div
            v-if="data.item.sources"
            class="d-flex justify-content-center align-items-center"
            style="gap: 1rem"
          >
            <div
              v-for="(item, index) in JSON.parse(data.item.sources)"
              :key="index"
              class="d-flex justify-content-center flex-column align-items-center"
            >
              <b-avatar
                :src="item.source_url"
                size="40"
                square
                icon="file-earmark-image-fill"
                class="rounded"
                variant="light"
              />
              <span :class="{ 'pt-1': item.source }">
                {{ item.source }}
              </span>
            </div>
          </div>
          <div v-else>
            <b-avatar
              size="40"
              square
              icon="file-earmark-image-fill"
              class="rounded"
              variant="light"
            />
          </div>
        </template>
        <template #cell(count_leads)="data">
          <b-badge
            pill
            :variant="getBadgeVariant(data.item.count_leads)"
            :class="{
              'cursor-pointer': data.item.count_leads > 0,
            }"
            @click="data.item.count_leads > 0 && openModalLeads(data.item)"
          >
            {{ data.item.count_leads }}
          </b-badge>
        </template>
        <template #cell(count_numbers)="data">
          <b-badge
            pill
            :variant="getBadgeVariant(data.item.count_numbers)"
            :class="{
              'cursor-pointer': data.item.count_numbers > 0,
            }"
            @click="data.item.count_numbers > 0 && openModalNumbers(data.item)"
          >
            {{ data.item.count_numbers }}
          </b-badge>
        </template>
        <template #cell(sales)="data">
          <b-badge
            pill
            :variant="getBadgeVariant(data.item.sales)"
            class="cursor-pointer"
            @click="openModalSales(data.item)"
          >
            {{ data.item.sales }}
          </b-badge>
        </template>
        <template #cell(fee)="data">
          <span>
            {{ data.item.fee | formatCurrency }}
          </span>
        </template>
        <template #cell(ip)="data">
          <span>
            {{ data.item.ip | formatCurrency }}
          </span>
        </template>
        <template #cell(roi)="data">
          <span
            :class="{
              'text-primary': data.item.roi > 0,
              'text-danger': data.item.roi <= 0,
            }"
          >
            {{ data.item.roi }}%
          </span>
        </template>
        <!-- footer -->
        <template #bottom-row>
          <b-th colspan="2" />
          <b-th>
            <div>
              <b-badge
                variant="primary"
                class="w-100 text-center bottom-row-text"
                style="margin-bottom: 5px"
              >
                SUBTOTAL
              </b-badge>
              <b-badge
                variant="primary"
                class="w-100 text-center bottom-row-text"
              >
                TOTAL
              </b-badge>
            </div>
          </b-th>
          <b-th>
            <div>
              <b-badge
                :variant="totals.investment > 0 ? 'success' : 'secondary'"
                class="w-100 text-center bottom-row-text"
                style="
                  padding-bottom: 7px;
                  padding-top: 7px;
                  margin-bottom: 5px;
                "
              >
                {{ subtotals.investment | formatCurrency }}
              </b-badge>
              <b-badge
                :variant="totals.investment > 0 ? 'success' : 'secondary'"
                class="w-100 text-center bottom-row-text"
                style="padding-bottom: 7px; padding-top: 7px"
              >
                {{ totals.investment | formatCurrency }}
              </b-badge>
            </div>
          </b-th>
          <b-th>
            <div>
              <b-badge
                variant="primary"
                class="w-100 text-center bottom-row-text"
                style="margin-bottom: 5px"
              >
                {{ subtotals.replys }}
              </b-badge>
              <b-badge
                variant="primary"
                class="w-100 text-center bottom-row-text"
              >
                {{ totals.replys }}
              </b-badge>
            </div>
          </b-th>
          <b-th />
          <b-th>
            <div>
              <b-badge
                variant="primary"
                class="w-100 text-center bottom-row-text"
                style="margin-bottom: 5px"
              >
                {{ subtotals.leads }}
              </b-badge>
              <b-badge
                variant="primary"
                class="w-100 text-center bottom-row-text"
              >
                {{ totals.leads }}
              </b-badge>
            </div>
          </b-th>
          <b-th>
            <div>
              <b-badge
                variant="primary"
                class="w-100 text-center bottom-row-text"
                style="margin-bottom: 5px"
              >
                {{ subtotals.numbers }}
              </b-badge>
              <b-badge
                variant="primary"
                class="w-100 text-center bottom-row-text"
              >
                {{ totals.numbers }}
              </b-badge>
            </div>
          </b-th>
          <b-th>
            <div>
              <b-badge
                variant="primary"
                class="w-100 text-center bottom-row-text"
                style="margin-bottom: 5px"
              >
                {{ subtotals.appointments }}
              </b-badge>
              <b-badge
                variant="primary"
                class="w-100 text-center bottom-row-text"
              >
                {{ totals.appointments }}
              </b-badge>
            </div>
          </b-th>
          <b-th>
            <div>
              <b-badge
                variant="primary"
                class="w-100 text-center bottom-row-text"
                style="margin-bottom: 5px"
              >
                {{ subtotals.sales }}
              </b-badge>
              <b-badge
                variant="primary"
                class="w-100 text-center bottom-row-text"
              >
                {{ totals.sales }}
              </b-badge>
            </div>
          </b-th>
          <b-th>
            <div>
              <b-badge
                variant="primary"
                class="w-100 text-center bottom-row-text"
                style="margin-bottom: 5px"
              >
                {{ subtotals.fee | formatCurrency }}
              </b-badge>
              <b-badge
                variant="primary"
                class="w-100 text-center bottom-row-text"
              >
                {{ totals.fee | formatCurrency }}
              </b-badge>
            </div>
          </b-th>
          <b-th>
            <div>
              <b-badge
                variant="primary"
                class="w-100 text-center bottom-row-text"
                style="margin-bottom: 5px"
              >
                {{ subtotals.ip | formatCurrency }}
              </b-badge>
              <b-badge
                variant="primary"
                class="w-100 text-center bottom-row-text"
              >
                {{ totals.ip | formatCurrency }}
              </b-badge>
            </div>
          </b-th>
          <b-th>
            <div>
              <b-badge
                variant="primary"
                class="w-100 text-center bottom-row-text"
                :class="{
                  'text-primary': subtotals.roi > 0,
                  'text-danger': subtotals.roi <= 0,
                }"
                style="margin-bottom: 5px"
              >
                {{ subtotals.roi }}%
              </b-badge>
              <b-badge
                variant="primary"
                class="w-100 text-center bottom-row-text"
                :class="{
                  'text-primary': totals.roi > 0,
                  'text-danger': totals.roi <= 0,
                }"
              >
                {{ totals.roi }}%
              </b-badge>
            </div>
          </b-th>
        </template>
      </b-table>
    </filter-slot>

    <leads-modal
      v-if="showLeadsModal"
      :flyer-code="flyerCode"
      :flyer-id="flyerId"
      :meta-ad-id="metaAdId"
      :date-from="dateFrom"
      :date-to="dateTo"
      :send-year="dataYear"
      :send-month="dataMonth"
      :send-range="reportSendRange"
      @hidden="showLeadsModal = false"
    />
    <numbers-modal
      v-if="showNumbersModal"
      :flyer-code="flyerCode"
      :flyer-id="flyerId"
      :meta-ad-id="metaAdId"
      :date-from="dateFrom"
      :date-to="dateTo"
      :send-year="dataYear"
      :send-month="dataMonth"
      :send-range="reportSendRange"
      @hidden="showNumbersModal = false"
    />
    <sales-modal
      v-if="showSalesModal"
      :flyer-code="flyerCode"
      :flyer-id="flyerId"
      :meta-ad-id="metaAdId"
      :date-from="dateFrom"
      :date-to="dateTo"
      :send-year="dataYear"
      :send-month="dataMonth"
      :send-range="reportSendRange"
      @hidden="showSalesModal = false"
    />
    <preview-ad
      v-if="showPreviewAd"
      :type="type"
      :ad-id="adId"
      :video-id="videoId"
      :ad-name="adName"
      :meta-user-id="metaUserId"
      @hidden="showPreviewAd = false"
    />
  </div>
</template>
<script>
import ComercialReportService from "@/views/commons/components/reports/tabs/comercial-report/service/comercial-report-v2.service.js";
import SNLeadsService from "@/views/social-network/services/leads";
import SocialNetworkService from "@/views/commons/components/bank-of-flyers/services/social-network.service.js";
import Fields from "@/views/commons/components/reports/tabs/comercial-report/data/fields-comercial-report-grid.data.js";
import Filters from "@/views/commons/components/reports/tabs/comercial-report/data/filters-comercial-report.grid.data.js";
import LeadsModal from "@/views/commons/components/reports/tabs/comercial-report/modals/leads/LeadsModal.vue";
import NumbersModal from "@/views/commons/components/reports/tabs/comercial-report/modals/leads/NumbersModal.vue";
import SalesModal from "@/views/commons/components/reports/tabs/comercial-report/modals/sales/SalesModal.vue";
import PreviewAd from "@/views/creative/views/meta/components/PreviewAd.vue";
import moment from "moment";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DateRangePicker from "vue2-daterange-picker";
export default {
  name: "ComercialReportGrid",
  components: {
    LeadsModal,
    NumbersModal,
    SalesModal,
    DateRangePicker,
    PreviewAd,
  },
  props: {
    sendYear: {
      type: Number,
      required: false,
      default: null,
    },
    sendMonth: {
      type: Number,
      required: false,
      default: null,
    },
    sendRange: {
      type: Boolean,
      required: false,
      default: true,
    },
    programId: {
      type: Number,
      required: false,
      default: null,
    },
    bottomPaginate: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  filters: {
    formatCurrency(value) {
      if (typeof value === "string") {
        value = value.replace(/,/g, "");
      }

      value = parseFloat(value);
      if (!value || value === 0 || isNaN(value)) {
        return "$0.00";
      }
      return value.toLocaleString(
        "en-US",
        {
          style: "currency",
          currency: "USD",
        },
        { minimumFractionDigits: 2 },
        { maximumFractionDigits: 2 }
      );
    },
  },
  data() {
    return {
      isBusy: false,
      fields: Fields,
      filterSlot: {
        filters: Filters,
        paginate: {
          currentPage: 1,
          perPage: 50,
        },
        startPage: 0,
        toPage: 0,
        totalRows: 0,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Flyer Code...",
          model: "",
        },
      },
      totals: {
        investment: 0,
        leads: 0,
        numbers: 0,
        appointments: 0,
        sales: 0,
        fee: 0,
        ip: 0,
        roi: 0,
        replys: 0,
      },
      subtotals: {
        investment: 0,
        leads: 0,
        numbers: 0,
        appointments: 0,
        sales: 0,
        fee: 0,
        ip: 0,
        roi: 0,
        replys: 0,
      },
      showLeadsModal: false,
      showNumbersModal: false,
      showSalesModal: false,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      dataYear: null,
      dataMonth: null,
      dateRange: {
        startDate: null,
        endDate: null,
      },
      maxDate: null,
      showPreviewAd: false,
      selectedType: 1,
      optionsType: [
        { text: "Month", value: 1 },
        { text: "Customized", value: 2 },
      ],
      reportSendRange: null,
    };
  },
  computed: {
    visibleFilters() {
      if (!this.reportSendRange) {
        this.getFilterByKey("program_id").visible = false;
      }
      return this.filterSlot.filters.filter((filter) => filter.visible);
    },
    titleMonthYear() {
      return this.reportSendRange
        ? this.titleSendRange
        : `${this.months[this.dataMonth - 1]} ${this.dataYear}`;
    },
    titleSendRange() {
      const startDate = moment(this.dateRange.startDate).format("MM/DD/YYYY");
      const endDate = moment(this.dateRange.endDate).format("MM/DD/YYYY");
      return `${startDate} - ${endDate}`;
    },
  },
  async created() {
    this.initMonthYear();
    this.initDateRange();
    this.reportSendRange = this.sendRange;
    await Promise.all([
      this.getAllPrograms(),
      this.getAllSources(),
      this.getStates(),
    ]);
  },
  mounted() {},
  methods: {
    async myProvider(ctx) {
      try {
        const orderBy = ctx.sortBy ? ctx.sortBy : "investment";
        let order = ctx.sortDesc ? "desc" : "asc";
        order = ctx.sortBy ? order : "desc";

        const params = {
          search_text: this.filterSlot.filterPrincipal.model,
          date_from: this.reportSendRange
            ? moment(this.dateRange.startDate).format("YYYY-MM-DD")
            : null,
          date_to: this.reportSendRange
            ? moment(this.dateRange.endDate).format("YYYY-MM-DD")
            : null,
          program_id: this.programId,
          source_id: this.getFilterByKey("source_id").model,
          state: this.getFilterByKey("state").model,
          investment_from: this.getFilterByKey("investment_from").model,
          investment_to: this.getFilterByKey("investment_to").model,
          ip_from: this.getFilterByKey("ip_from").model,
          ip_to: this.getFilterByKey("ip_to").model,
          fee_from: this.getFilterByKey("fee_from").model,
          fee_to: this.getFilterByKey("fee_to").model,
          npage: this.filterSlot.paginate.currentPage,
          perpage: this.filterSlot.paginate.perPage,
          orderby: orderBy,
          order: order,
          year: this.dataYear,
          month: this.dataMonth,
          range: this.reportSendRange,
        };

        const { data } = await ComercialReportService.getComercialReport(
          params
        );
        this.filterSlot.totalRows = data.total;
        this.filterSlot.startPage = data.from ?? 0;
        this.filterSlot.toPage = data.to ?? 0;
        this.filterSlot.paginate.currentPage = data.current_page;
        this.filterSlot.paginate.perPage = data.per_page;

        this.totals.investment = data.data?.[0]?.total_investment ?? 0;
        this.totals.leads = data.data?.[0]?.total_leads ?? 0;
        this.totals.numbers = data.data?.[0]?.total_numbers ?? 0;
        this.totals.appointments = data.data?.[0]?.total_appointments ?? 0;
        this.totals.sales = data.data?.[0]?.total_sales ?? 0;
        this.totals.fee = data.data?.[0]?.total_fee ?? 0;
        this.totals.ip = data.data?.[0]?.total_ip ?? 0;
        this.totals.roi = data.data?.[0]?.total_roi ?? 0;
        this.totals.replys = data.data?.[0]?.total_results ?? 0;

        this.subtotals.investment = data.data?.[0]?.subtotal_investment ?? 0;
        this.subtotals.leads = data.data?.[0]?.subtotal_leads ?? 0;
        this.subtotals.numbers = data.data?.[0]?.subtotal_numbers ?? 0;
        this.subtotals.appointments =
          data.data?.[0]?.subtotal_appointments ?? 0;
        this.subtotals.sales = data.data?.[0]?.subtotal_sales ?? 0;
        this.subtotals.fee = data.data?.[0]?.subtotal_fee ?? 0;
        this.subtotals.ip = data.data?.[0]?.subtotal_ip ?? 0;
        this.subtotals.roi = data.data?.[0]?.subtotal_roi ?? 0;
        this.subtotals.replys = data.data?.[0]?.subtotal_results ?? 0;

        return data.data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    getFilterByKey(key) {
      return this.filterSlot.filters.find((filter) => filter.key === key);
    },
    async getAllPrograms() {
      try {
        const data = await SNLeadsService.getFanPagePrograms();
        data.data.unshift({ value: "All", id: null });
        this.getFilterByKey("program_id").options = data.data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getAllSources() {
      try {
        const { data } = await ComercialReportService.getSources({});
        data.unshift({ name: "All", id: null });
        this.getFilterByKey("source_id").options = data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getStates() {
      try {
        const { data } = await SocialNetworkService.getStates();
        data.unshift({ state: "All", slug: null });
        this.getFilterByKey("state").options = data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    getBadgeVariant(value) {
      const badgeColors = {
        warning: "light-warning",
        success: "light-success",
        default: "light-secondary",
      };
      value = value > 0 ? "success" : value == 0 ? "warning" : "default";
      return badgeColors[value];
    },
    openModalLeads(item) {
      this.getItems(item);
      this.showLeadsModal = true;
    },
    openModalNumbers(item) {
      this.getItems(item);
      this.showNumbersModal = true;
    },
    openModalSales(item) {
      this.getItems(item);
      this.showSalesModal = true;
    },
    getItems(item) {
      this.clearItems();
      const dateFrom = this.reportSendRange
        ? moment(this.dateRange.startDate).format("YYYY-MM-DD")
        : null;
      const dateTo = this.reportSendRange
        ? moment(this.dateRange.endDate).format("YYYY-MM-DD")
        : null;
      this.flyerCode = item.flyer_code;
      this.flyerId = item.flyer_id;
      this.dateFrom = dateFrom;
      this.dateTo = dateTo;
      this.metaAdId = item.ad_id;
    },
    clearItems() {
      this.flyerCode = "";
      this.flyerId = null;
      this.dateFrom = null;
      this.dateTo = null;
      this.metaAdId = null;
    },
    async next() {
      if (this.dataMonth < 12) {
        this.dataMonth++;
      } else {
        this.dataMonth = 1;
        this.dataYear++;
      }
      await this.refresh();
    },
    async prev() {
      if (this.dataMonth > 1) {
        this.dataMonth--;
      } else {
        this.dataMonth = 12;
        this.dataYear--;
      }
      await this.refresh();
    },
    async refresh() {
      this.$emit("newTitleModal", this.titleMonthYear);
      await this.$refs["comercial-report-table"].refresh();
    },
    initDateRange() {
      const today = moment().format("MM/DD/YYYY");
      const yesterday = moment().subtract(1, "days").format("MM/DD/YYYY");
      this.dateRange.startDate = yesterday;
      this.dateRange.endDate = yesterday;
      this.maxDate = today;
    },
    initMonthYear() {
      this.dataMonth = this.sendMonth;
      this.dataYear = this.sendYear;
    },
    getAdPreview(item) {
      this.showPreviewAd = true;
      this.adId = item.ad_id;
      this.metaUserId = item.meta_user_id;
      this.adName = item.flyer_code;
      this.type = "ad_preview";
    },
    handleTypeSearch() {
      const type = {
        MONTH: 1,
        CUSTOMIZED: 2,
      };
      if (this.selectedType === type.MONTH) {
        this.reportSendRange = false;
        this.initMonthYear();
        this.refresh();
      }

      if (this.selectedType === type.CUSTOMIZED) {
        this.reportSendRange = true;
        this.initDateRange();
        this.refresh();
      }
    },
  },
  watch: {
    sendYear() {
      this.$refs["comercial-report-table"].refresh();
    },
    sendMonth() {
      this.$refs["comercial-report-table"].refresh();
    },
  },
};
</script>
<style lang="scss">
.meta-comercial-report {
  #comercial-report-table {
    .only-meta-badge {
      position: absolute;
      left: -27px;
      top: -15px;
    }
    .programs_image {
      background-color: #e9e9e9;
      padding: 0.2rem;
      border-radius: 10px;
      width: 3.2rem;
      height: 3.2rem;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .rounded {
      border-radius: 0.357rem !important;
    }

    .b-table-bottom-row {
      position: sticky;
      bottom: 0;
      z-index: 1;
      background: #1654cc !important;

      .badge-primary {
        background-color: #1654cc !important;
      }

      .bottom-row-text {
        font-weight: 600;
        font-size: 14px;
      }

      th:nth-child(1) {
        border-radius: 0px 0px 0px 15px !important;
        margin-left: 5px !important;
      }

      th:nth-child(n + 1):nth-child(-n + 12) {
        padding-top: 5px;
        padding-bottom: 5px;
      }

      th:nth-child(12) {
        border-radius: 0px 0px 15px 0px !important;
        margin-left: 10px !important;
      }
    }
    .fill-bottom-row {
      background-color: #ffffff !important;
    }
  }

  .table-new-customization.rc {
    table {
      border-spacing: 0px 4px !important;
      thead {
        tr {
          position: sticky !important;
          z-index: 999;
          top: 30px;
          th {
            border: 0.5px solid !important;
          }
        }

        tr th:first-child {
          border-radius: 0 !important;
        }

        tr th:last-child {
          border-radius: 0 !important;
        }
      }
      .additional-head {
        top: 0;
        th:first-child {
          border-radius: 15px 0 0 0 !important;
        }

        th:last-child {
          border-radius: 0 15px 0 0 !important;
        }
      }
    }
  }

  .customized-filter {
    .custom-datepciker {
      cursor: pointer;
      padding: 10px 10px;
      border: 1px solid #606060;
      border-radius: 8px;
      font-size: 0.9rem !important;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      width: 200px;
    }

    .custom-datepciker > svg {
      color: #606060 !important;
      margin: auto !important;
      margin-right: 0.5rem !important;
    }

    .daterangepicker .drp-calendar {
      max-width: 340px !important;
      width: 340px !important;
    }
  }
}

.dark-layout {
  .meta-comercial-report {
    #comercial-report-table {
      .fill-bottom-row {
        background-color: #17171a !important;
        border-top: none !important;
      }
    }

    .table-new-customization.rc {
      thead {
        tr {
          th {
            border: 0.5px solid #1b1b1e !important;
          }
        }
      }
    }

    .customized-filter {
      .custom-datepciker {
        border: 1px solid #3a72ea;
        color: #3a72ea;
      }
      .custom-datepciker > svg {
        color: #3a72ea !important;
      }
    }
  }
}
</style>

