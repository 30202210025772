<template>
  <div class="mt-1">
    <b-table :items="missingMonthlyPayments" :fields="visibleFields">
      <template v-slot:cell(pay_5)="{ item }">
        <div v-if="item.pay_5">
          <span
            class="font-weight-bolder text-primary cursor-pointer"
            @click="ModalClientAutomatic(item, 5, 1)"
          >
            {{ item.pay_5 }}
          </span>
          |
          <span
            class="font-weight-bolder text-warning cursor-pointer"
            @click="ModalClientAutomatic(item, 5, 0)"
          >
            {{ item.wo_5 }}
          </span>
        </div>
        <span v-else> - </span>
      </template>
      <template v-slot:cell(pay_10)="{ item }">
        <div v-if="item.pay_10">
          <span
            class="font-weight-bolder text-primary cursor-pointer"
            @click="ModalClientAutomatic(item, 10, 1)"
          >
            {{ item.pay_10 }}
          </span>
          |
          <span
            class="font-weight-bolder text-warning cursor-pointer"
            @click="ModalClientAutomatic(item, 10, 0)"
          >
            {{ item.wo_10 }}
          </span>
        </div>
        <span v-else> - </span>
      </template>
      <template v-slot:cell(pay_15)="{ item }">
        <div v-if="item.pay_15">
          <span
            class="font-weight-bolder text-primary cursor-pointer"
            @click="ModalClientAutomatic(item, 15, 1)"
          >
            {{ item.pay_15 }}
          </span>
          |
          <span
            class="font-weight-bolder text-warning cursor-pointer"
            @click="ModalClientAutomatic(item, 15, 0)"
          >
            {{ item.wo_15 }}
          </span>
        </div>
        <span v-else> - </span>
      </template>
      <template v-slot:cell(pay_20)="{ item }">
        <div v-if="item.pay_20">
          <span
            class="font-weight-bolder text-primary cursor-pointer"
            @click="ModalClientAutomatic(item, 20, 1)"
          >
            {{ item.pay_20 }}
          </span>
          |
          <span
            class="font-weight-bolder text-warning cursor-pointer"
            @click="ModalClientAutomatic(item, 20, 0)"
          >
            {{ item.wo_20 }}
          </span>
        </div>
        <span v-else> - </span>
      </template>
      <template v-slot:cell(pay_25)="{ item }">
        <div v-if="item.pay_25">
          <span
            class="font-weight-bolder text-primary cursor-pointer"
            @click="ModalClientAutomatic(item, 25, 1)"
          >
            {{ item.pay_25 }}
          </span>
          |
          <span
            class="font-weight-bolder text-warning cursor-pointer"
            @click="ModalClientAutomatic(item, 25, 0)"
          >
            {{ item.wo_25 }}
          </span>
        </div>
        <span v-else> - </span>
      </template>
      <template v-slot:cell(pay_30)="{ item }">
        <div v-if="item.pay_30">
          <span
            class="font-weight-bolder text-primary cursor-pointer"
            @click="ModalClientAutomatic(item, 30, 1)"
          >
            {{ item.pay_30 }}
          </span>
          |
          <span
            class="font-weight-bolder text-warning cursor-pointer"
            @click="ModalClientAutomatic(item, 30, 0)"
          >
            {{ item.wo_30 }}
          </span>
        </div>
        <span v-else> - </span>
      </template>
    </b-table>
    <clients-auto-modal
      v-if="showModalClientsAuto"
      :send-year="dataYear"
      :send-month="dataMonth"
      :send-programs-id="idProgram"
      :send-programs="arrPrograms"
      :day-payment="dayPayment"
      :is-paid="isPaid"
      @close="showModalClientsAuto = false"
    />
  </div>
</template>
<script>
import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service";
import ClientsAutoModal from "@/views/ceo/dashboard/modals/ClientsAutoModal.vue";

export default {
  components: {
    ClientsAutoModal,
  },
  props: {
    selectYear: {
      type: Number,
      default: null,
      required: false,
    },
    selectMonth: {
      type: Number,
      default: null,
      required: false,
    },
    listPrograms: {
      type: Array,
      required: false,
      default: [],
    },
  },
  data() {
    return {
      imgServices: require("@/assets/images/icons/dashboard-ceo/services.svg"),
      imgDebtSolution: require("@/assets/images/icons/dashboard-ceo/logo-debt-solution.svg"),
      imgBoostCredit: require("@/assets/images/icons/dashboard-ceo/logo-boost-credit.svg"),
      imgCreditExperts: require("@/assets/images/icons/dashboard-ceo/logo-credit-experts.svg"),
      imgBusiness: require("@/assets/images/icons/dashboard-ceo/logo-business.svg"),
      imgTaxResearch: require("@/assets/images/icons/dashboard-ceo/logo-tax-research.svg"),
      imgSpecialist: require("@/assets/images/icons/dashboard-ceo/logo-specialist.svg"),
      imgKeyBook: require("@/assets/images/icons/dashboard-ceo/logo-keybook.svg"),
      value: 75,
      showModalClientsInHold: false,
      missingMonthlyPayments: [],
      arrPrograms: [],
      idProgram: null,
      fields: [
        { label: "5", key: "pay_5", visible: true, class: "text-center" },
        { label: "10", key: "pay_10", visible: true, class: "text-center" },
        { label: "15", key: "pay_15", visible: true, class: "text-center" },
        { label: "20", key: "pay_20", visible: true, class: "text-center" },
        { label: "25", key: "pay_25", visible: true, class: "text-center" },
        { label: "30", key: "pay_30", visible: false, class: "text-center" },
      ],
      items: [
        {
          program_id: 1,
          pay_5: 0,
          pay_10: 0,
          pay_15: 0,
          pay_20: 0,
          pay_25: 0,
          pay_30: 0,
        },
        {
          program_id: 2,
          pay_5: 0,
          pay_10: 0,
          pay_15: 0,
          pay_20: 0,
          pay_25: 0,
          pay_30: 0,
        },
        {
          program_id: 3,
          pay_5: 0,
          pay_10: 0,
          pay_15: 0,
          pay_20: 0,
          pay_25: 0,
          pay_30: 0,
        },
        {
          program_id: 4,
          pay_5: 0,
          pay_10: 0,
          pay_15: 0,
          pay_20: 0,
          pay_25: 0,
          pay_30: 0,
        },
        {
          program_id: 5,
          pay_5: 0,
          pay_10: 0,
          pay_15: 0,
          pay_20: 0,
          pay_25: 0,
          pay_30: 0,
        },
        {
          program_id: 7,
          pay_5: 0,
          pay_10: 0,
          pay_15: 0,
          pay_20: 0,
          pay_25: 0,
          pay_30: 0,
        },
        {
          program_id: 8,
          pay_5: 0,
          pay_10: 0,
          pay_15: 0,
          pay_20: 0,
          pay_25: 0,
          pay_30: 0,
        },
      ],
      dayPayment: null,
      isPaid: false,
      showModalClientsAuto: false,
    };
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
  },
  watch: {
    selectMonth(newVal) {
      this.dataMonth = newVal;
      this.getAutomaticPayments();
      const days = new Date(this.dataYear, this.dataMonth, 0).getDate();
      if (days >= 30) {
        this.fields[6].visible = true;
      } else {
        this.fields[6].visible = false;
      }
    },
    selectYear(newVal) {
      this.dataYear = newVal;
      const days = new Date(this.dataYear, this.dataMonth, 0).getDate();
      if (days >= 30) {
        this.fields[6].visible = true;
      } else {
        this.fields[6].visible = false;
      }
      this.getAutomaticPayments();
    },
  },
  async created() {
    this.dataMonth = this.selectMonth;
    this.dataYear = this.selectYear;
    await this.getAutomaticPayments();
  },

  methods: {
    ModalClientAutomatic(item, day, isPaid) {
      this.arrPrograms = this.listPrograms;
      this.idProgram = item.program_id * 1;
      this.dayPayment = day;
      this.isPaid = isPaid;
      this.showModalClientsAuto = true;
    },
    async getAutomaticPayments() {
      try {
        const params = {
          month: this.dataMonth,
          year: this.dataYear,
        };
        let { data } = await DashboardServive.getClientAutomaticPayments(
          params
        );
        data = Object.entries(data).map(([key, value]) => ({
          program_id: key,
          ...value,
        }));
        this.missingMonthlyPayments = data;
      } catch (error) {
        console.error(error);
      }
    },
    imgPrograms(program_id) {
      switch (program_id * 1) {
        case 1:
          return this.imgBusiness;
        case 2:
          return this.imgBoostCredit;
        case 3:
          return this.imgCreditExperts;
        case 4:
          return this.imgDebtSolution;
        case 5:
          return this.imgTaxResearch;
        case 7:
          return this.imgSpecialist;
        case 8:
          return this.imgKeyBook;
        default:
          return this.imgKeyBook;
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$color-primary: #5f5f5f;
$color-secondary: #ffffff;
$bg-card-white: #ffffff;
$bg-card-dark: #111113;
$box-shadow-white: #00000026;

.dashboard__card {
  box-shadow: 0px 0px 15px 0px $box-shadow-white;
  border-radius: 15px;
  margin-bottom: 27px;
  padding: 18px;
  margin-top: 0px !important;
  background-color: $bg-card-white;
  &--dark {
    background-color: $bg-card-dark;
    box-shadow: 0px 0px 22.935px 0px rgba(255, 249, 249, 0.1);
  }

  .card-charge {
    &__header {
      display: flex;
      padding-left: 8px;

      .charge-logo {
        margin-right: 5px;
        width: 43px;
        height: 43px;
        border-radius: 10px;
        background-color: #a7c5ff;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 32px;
          height: 32px;
        }
      }

      .title {
        display: flex;
        margin: 0px;
        justify-content: center;
        align-items: center;
        color: #3d3d3d;
        font-family: Montserrat;
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 500;
        line-height: 122.25%;
        margin-left: 5px;

        &--dark {
          color: $color-secondary;
        }
      }
    }

    &__body {
      .charge {
        display: flex;
        margin: 10px auto;
        padding: 6px 8px;
        border-radius: 12px;

        &:hover {
          background-color: #eeeeee;
          cursor: pointer;
        }

        &__programs {
          width: 47px;
          height: 40px;
          background-color: #e9e9e9;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 35px;
            height: 35px;
          }
        }

        &__details {
          display: flex;
          justify-content: space-between;
          padding-top: 10px;
          width: 100%;
          margin: 0px 13px;

          &--program {
            margin: 0px;
            color: #5f5f5f;
            font-family: Montserrat;
            font-size: 1.1rem;
            font-style: normal;
            font-weight: 500;
            margin-bottom: 5px;

            .value {
              font-weight: 700;
              margin-left: 3px;
              margin-right: 3px;

              &::before {
                content: "$";
              }
            }
          }

          .subtitle-dark {
            color: #b4b4b4;
          }
        }
      }

      .charge__dark:hover {
        background-color: #1b1b1b;
      }
    }
  }
}

.loader {
  display: flex;
  margin: 100px auto;
  justify-content: center;
  align-items: center;
  .loader__spinner {
    width: 48px;
    height: 48px;
    display: inline-block;
    position: relative;
  }
  .loader__spinner::after,
  .loader__spinner::before {
    content: "";
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 2px solid #3800cb;
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader 2s linear infinite;
  }
  .loader__spinner::after {
    animation-delay: 1s;
  }

  @keyframes animloader {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
}
</style>
