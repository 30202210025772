<template>
  <div class="card-debsolution-dashboard-ce">
    <general-card key="general-card" title="Debt Solution" :show-offers="true">
      <template #offers>
        <b-button
          class="position-relative"
          variant="outline-primary"
          size="sm"
          @click="openModalCeDs('type_ds', dataOffers)"
        >
          Offers
          <b-badge variant="light-primary mr-1">{{ totalOffers }}</b-badge>
          <offer-menu-badge
            v-b-tooltip.hover.top="'Settlements'"
            class="menu-badge"
          />
        </b-button>
      </template>
      <template #body-header>
        <div class="mb-1">
          <b-badge variant="light-primary">Retainer FEE</b-badge>
          <card-retainer-fee
            :program-id="programId"
            @open="openDetailedFeeModal($event)"
          />
        </div>
        <hr />
        <div class="mb-1 overflow-auto">
          <b-badge variant="light-primary">To Negotiate</b-badge>
          <card-negotiate @open="openDetailedNegotiateModal($event)" />
        </div>
        <hr />
        <div class="mb-1">
          <b-badge variant="light-primary">Others</b-badge>
          <card-others
            :program-id="programId"
            @open="openDetailedOthersModal($event)"
          />
        </div>
        <hr />
        <div>
          <b-badge variant="light-primary">Settlements</b-badge>
          <card-settlements @open="openDetailedSettlementModal($event)" />
        </div>
      </template>
    </general-card>
    <detailed-negotiate
      v-if="showDetailedBalance"
      :default-status="negotiateCardStatus"
      @close="closeDetailedNegotiateModal()"
    />
    <detailed-settlement
      v-if="showDetailedSettlement"
      @close="closeDetailedSettlementModal()"
    />
    <detailed-others
      v-if="showDetailedOthers"
      :default-type="othersCardType"
      @close="closeDetailedOthersModal()"
    />
    <detailed-fee
      v-if="showDetailedFee"
      :default-type="feeCardType"
      @close="closeDetailedFeeModal()"
    />
    <ce-ds-modal
      v-if="statusModalCeDe"
      :data-client="dataClient"
      :type-modal-name="typeModalName"
      :card-chosen="cardChosen"
      @close="statusModalCeDe = false"
    />
  </div>
</template>
<script>
import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service.js";
import GeneralCard from "@/views/debt-solution/views/dashboard/components/GeneralCard.vue";
import CardRetainerFee from "@/views/debt-solution/views/dashboard/components/retainer-fee/CardRetainerFee.vue";
import CardNegotiate from "@/views/debt-solution/views/dashboard/components/negotiate/CardNegotiate.vue";
import CardSettlements from "@/views/debt-solution/views/dashboard/components/settlements/CardSettlements.vue";
import CardOthers from "@/views/debt-solution/views/dashboard/components/others/CardOthers.vue";
import DetailedNegotiate from "@/views/debt-solution/views/dashboard/components/negotiate/modals/DetailedNegotiate.vue";
import DetailedSettlement from "@/views/debt-solution/views/dashboard/components/settlements/modals/DetailedSettlement.vue";
import DetailedOthers from "@/views/debt-solution/views/dashboard/components/others/modals/DetailedOthers.vue";
import DetailedFee from "@/views/debt-solution/views/dashboard/components/retainer-fee/modals/DetailedFee.vue";
import OfferMenuBadge from "@/views/main/menu-badge/OfferMenuBadge.vue";
import CeDsModal from "@/views/ceo/dashboard/modals/CeDsModal.vue";
export default {
  name: "CardDebtSolution",
  components: {
    GeneralCard,
    CardRetainerFee,
    CardNegotiate,
    CardSettlements,
    CardOthers,
    DetailedNegotiate,
    DetailedSettlement,
    DetailedOthers,
    DetailedFee,
    OfferMenuBadge,
    CeDsModal,
  },
  props: {
    selectYear: {
      type: Number,
      required: true,
    },
    selectMonth: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showDetailedBalance: false,
      showDetailedSettlement: false,
      showDetailedOthers: false,
      showDetailedFee: false,
      negotiateCardStatus: null,
      othersCardType: null,
      dataOffers: {
        isMoney: false,
        name: "data_offers",
        sendBack: "offers",
        showName: "Offers",
        value: 0,
      },
      statusModalCeDe: false,
      typeModalName: "",
      dataClient: {
        statusId: null,
        statusName: null,
        year: this.selectYear,
        month: this.selectMonth,
        idProgram: 0,
      },
    };
  },
  computed: {
    programId() {
      return 4;
    },
    totalOffers() {
      return this.dataOffers.value;
    },
  },
  created() {
    this.getCeDsIndex();
  },
  mounted() {},
  methods: {
    async getCeDsIndex() {
      try {
        const params = {
          year: this.selectYear,
          month: this.selectMonth,
        };
        const data = await DashboardServive.getDsStatistics(params);
        this.dataOffers.value = data.find(
          (item) => item.name === "data_offers"
        ).value;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    openDetailedNegotiateModal(negotiateCard) {
      this.negotiateCardStatus = negotiateCard.status;
      this.showDetailedBalance = true;
    },
    closeDetailedNegotiateModal() {
      this.negotiateCardStatus = null;
      this.showDetailedBalance = false;
    },
    openDetailedSettlementModal() {
      this.showDetailedSettlement = true;
    },
    closeDetailedSettlementModal() {
      this.showDetailedSettlement = false;
    },
    openDetailedOthersModal(othersCard) {
      this.othersCardType = othersCard.type;
      this.showDetailedOthers = true;
    },
    closeDetailedOthersModal() {
      this.othersCardType = null;
      this.showDetailedOthers = false;
    },
    openDetailedFeeModal(feeCard) {
      this.feeCardType = feeCard.type;
      this.showDetailedFee = true;
    },
    closeDetailedFeeModal() {
      this.feeCardType = null;
      this.showDetailedFee = false;
    },
    openModalCeDs(typeModal, data) {
      this.typeModalName = typeModal;
      this.statusModalCeDe = true;
      this.cardChosen = data;
    },
  },
  watch: {
    selectYear(value) {
      this.getCeDsIndex();
      this.dataClient.year = this.selectYear;
    },
    selectMonth(value) {
      this.getCeDsIndex();
      this.dataClient.month = this.selectMonth;
    },
    moduleIdSelected(value) {
      this.getNcrData();
    },
  },
};
</script>
<style lang="scss">
.card-debsolution-dashboard-ce {
  .general-card-ds {
    border-radius: 1rem;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  }
  .menu-badge {
    position: absolute;
    right: -3%;
    top: -12%;
  }
}
</style>