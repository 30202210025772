<template>
  <div class="meta-grid-table">
    <b-nav card-header pills class="m-0">
      <b-nav-item
        v-for="(item, index) in tabs"
        :key="index"
        :link-classes="['px-3', bgTabsNavs]"
        :active="activeTab == item.id"
        @click="(activeTab = item.id), $refs['meta-ads-table'].refresh()"
      >
        {{ item.title }}
      </b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0 m-0"
    >
      <filter-slot
        class="filter-slot-new-customization"
        :filter="visibleFilters"
        :total-rows="filterSlot.totalRows"
        :paginate="filterSlot.paginate"
        :start-page="filterSlot.startPage"
        :to-page="filterSlot.toPage"
        :filter-principal="filterSlot.filterPrincipal"
        :top-paginate="false"
        :left-col-value="10"
        :right-col-value="2"
        @reload="$refs['meta-ads-table'].refresh()"
      >
        <template #buttons-filter-end>
          <div
            style="min-width: 350px"
            class="ml-1 customized-filter d-flex justify-content-start align-items-center w-100"
          >
            <div
              v-if="!metaSendRange"
              class="absolute-linker"
              :class="{ dark: isDarkSkin }"
            >
              <div
                class="d-flex justify-content-between align-items-center h-full px-1"
                style="font-size: 15px; font-weight: bold"
              >
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="20"
                  class="cursor-pointer"
                  @click="prev"
                />
                <span>
                  {{ titleMonthYear }}
                </span>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="20"
                  class="cursor-pointer"
                  @click="next"
                />
              </div>
            </div>
            <div v-else class="d-flex align-items-center h-full px-1">
              <date-range-picker
                ref="picker"
                :opens="'left'"
                control-container-class="custom-datepciker"
                :locale-data="{ firstDay: 0, format: 'mm/dd/yyyy' }"
                :max-date="maxDate"
                :ranges="null"
                v-model="dateRange"
                @update="refresh()"
              >
                <template v-slot:input="picker">
                  {{ picker.startDate | myGlobal }} -
                  {{ picker.endDate | myGlobal }}
                  <feather-icon
                    icon="CalendarIcon"
                    size="19"
                    class="text-success ml-2"
                  />
                </template>
              </date-range-picker>
            </div>
            <b-form-radio-group
              id="btn-radios-1"
              class="mr-1"
              v-model="selectedType"
              :options="optionsType"
              button-variant="outline-primary"
              size="md"
              name="radio-btn-outline"
              buttons
              @change="handleTypeSearch()"
            ></b-form-radio-group>

            <b-button
              variant="primary"
              class="d-flex justify-content-center align-items-center"
              size="sm"
              @click="syncWithMeta()"
            >
              <meta-icon-svg :size="24" />
              <span style="padding-left: 3px">Sync Today</span>
            </b-button>

            <b-button
              v-if="campaign_id"
              variant="primary"
              class="d-flex justify-content-center align-items-center ml-1"
              size="sm"
              @click="searchByCampaignId(null, null, 1)"
            >
              <tabler-icon icon="ArrowBackIcon" size="24" />
              <span style="padding-left: 3px">Back</span>
            </b-button>

            <b-button
              v-if="!campaign_id"
              variant="primary"
              class="d-flex justify-content-center align-items-center ml-1"
              size="sm"
              @click="handleGroupType()"
              v-b-tooltip.hover="groupType === 1 ? 'Change to Ads' : 'Change to Campaigns'"
            >
              <tabler-icon icon="ReplaceIcon" size="24" />
              <span style="padding-left: 3px">
                {{ groupType === 1 ? "Campaign" : "ADS" }}
              </span>
            </b-button>

            <feather-icon
              icon="MenuIcon"
              size="22"
              class="ml-1 cursor-pointer text-primary"
              v-b-tooltip.hover="'Tracking Meta Ad Migration Status'"
              @click="showTrackingMigrationStatusModal = true"
            />
          </div>
        </template>

        <b-table
          id="meta-ads-table"
          ref="meta-ads-table"
          slot="table"
          class="position-relative table-new-customization"
          empty-text="No matching records found"
          sticky-header="60vh"
          primary-key="id"
          responsive="md"
          show-empty
          no-border-collapse
          no-provider-filtering
          :busy.sync="isBusy"
          :fields="visibleFields"
          :items="myProvider"
          :per-page="filterSlot.paginate.perPage"
          :current-page="filterSlot.paginate.currentPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #head(ad_name)="data">
            <div class="text-center">
              {{ data.label }}
              <tabler-icon
                icon="ArrowsSortIcon"
                size="14"
                style="padding-left: 3px"
              />
            </div>
          </template>

          <template #head(reach)="data">
            <div class="text-center">
              {{ data.label }}
              <tabler-icon
                icon="ArrowsSortIcon"
                size="14"
                style="padding-left: 3px"
              />
            </div>
          </template>
          <template #head(spend)="data">
            <div class="text-center">
              {{ data.label }}
              <tabler-icon
                icon="ArrowsSortIcon"
                size="14"
                style="padding-left: 3px"
              />
            </div>
          </template>
          <template #head(results)="data">
            <div class="text-center">
              {{ data.label }}
              <tabler-icon
                icon="ArrowsSortIcon"
                size="14"
                style="padding-left: 3px"
              />
            </div>
          </template>
          <template #head(cost_per_result)="data">
            <div class="text-center">
              {{ data.label }}
              <tabler-icon
                icon="ArrowsSortIcon"
                size="14"
                style="padding-left: 3px"
              />
            </div>
          </template>

          <template #cell(program_name)="data">
            <div
              v-b-tooltip.hover="
                data.item.ad_name.length > 20 ? data.item.ad_name : ''
              "
              class="position-relative h-100 w-100"
            >
              <b-badge
                v-if="!data.item.exists_in_soft"
                variant="light-danger"
                class="only-meta-badge"
              >
                Only Meta
              </b-badge>

              <div
                class="d-flex justify-content-center align-items-center"
                :class="{
                  'pt-1': !data.item.exists_in_soft,
                }"
              >
                <div v-if="data.item.program_name" class="programs_image">
                  <img
                    :src="getProgramImageRoute(data.item.program_id)"
                    v-b-tooltip.right="data.item.program_name"
                    alt="img_services"
                  />
                </div>

                <b-avatar
                  v-else
                  size="40"
                  square
                  icon="file-earmark-image-fill"
                  class="rounded"
                  variant="light"
                />
              </div>
            </div>
          </template>

          <template #cell(route_thumb)="data">
            <div>
              <b-avatar
                :src="data.item.route_thumb"
                size="50"
                square
                icon="file-earmark-image-fill"
                class="rounded"
                variant="light"
              />
            </div>
          </template>
          <template #cell(ad_name)="data">
            <div
              v-b-tooltip.hover="
                data.item.ad_name.length > 20 ? data.item.ad_name : ''
              "
            >
              {{ data.item.ad_name | limitChars(20) }}
            </div>
          </template>
          <template #cell(ad_type)="data">
            <b-badge
              :variant="getVariantByAdType(data.item.ad_type)"
              class="cursor-pointer"
              @click="getAdPreview(data.item)"
            >
              {{ data.item.ad_type }}
              <feather-icon icon="ArrowUpRightIcon" size="20" />
            </b-badge>
          </template>

          <template #cell(effective_status)="data">
            <b-badge
              :variant="getVariantStatusAd(data.item).variant"
              class="w-100 text-center"
            >
              {{ getVariantStatusAd(data.item).status }}
            </b-badge>
          </template>
          <template #cell(campaign_id)="data">
            <div
              v-b-tooltip.hover="
                data.item.campaign_name.length > 30
                  ? data.item.campaign_name
                  : ''
              "
              :class="{
                'cursor-pointer text-primary text-decoration-underline':
                  groupType === 1,
              }"
              @click="
                groupType === 1 &&
                  searchByCampaignId(
                    data.item.campaign_id,
                    data.item.campaign_name,
                    2
                  )
              "
            >
              {{ data.item.campaign_name | limitChars(30) }}
            </div>
          </template>
          <template #cell(spend)="data">
            <div class="d-flex justify-content-center align-items-center">
              <span>{{ data.item.spend | formatCurrency }}</span>
              <feather-icon
                v-if="data.item.spend > 0 && groupType === 2"
                icon="InfoIcon"
                class="text-primary cursor-pointer"
                size="17"
                style="padding-left: 3px"
                v-b-tooltip.hover="'See investment per day'"
                @click="openModalMetaDetails(data.item, 1)"
              />
            </div>
          </template>
          <template #cell(results)="data">
            <div class="d-flex justify-content-center align-items-center">
              <span>{{ data.item.results }}</span>
              <feather-icon
                v-if="data.item.results > 0 && groupType === 2"
                icon="InfoIcon"
                class="text-primary cursor-pointer"
                size="17"
                style="padding-left: 3px"
                v-b-tooltip.hover="'See results per day'"
                @click="openModalMetaDetails(data.item, 2)"
              />
            </div>
          </template>
          <template #cell(reach)="data">
            <div class="d-flex justify-content-center align-items-center">
              <span>{{ data.item.reach }}</span>
              <feather-icon
                v-if="data.item.reach > 0 && groupType === 2"
                icon="InfoIcon"
                class="text-primary cursor-pointer"
                size="17"
                style="padding-left: 3px"
                v-b-tooltip.hover="'See reach per day'"
                @click="openModalMetaDetails(data.item, 3)"
              />
            </div>
          </template>
          <template #cell(cost_per_result)="data">
            <div class="d-flex justify-content-center align-items-center">
              <span>{{ data.item.cost_per_result | formatCurrency }}</span>
              <feather-icon
                v-if="data.item.cost_per_result > 0 && groupType === 2"
                icon="InfoIcon"
                class="text-primary cursor-pointer"
                size="17"
                style="padding-left: 3px"
                v-b-tooltip.hover="'See CPR per day'"
                @click="openModalMetaDetails(data.item, 4)"
              />
            </div>
          </template>
          <template #cell(period)="data">
            <span>{{ data.item.min_date }} - {{ data.item.max_date }}</span>
          </template>

          <template #bottom-row>
            <b-th :colspan="colspan" />
            <b-th>
              <b-badge
                variant="primary"
                class="w-100 text-center"
                style="font-size: 14px; font-weight: 600"
              >
                TOTAL
              </b-badge>
            </b-th>
            <b-th>
              <b-badge
                variant="primary"
                class="w-100 text-center"
                style="font-size: 14px; font-weight: 600"
              >
                {{ totalReach }}
              </b-badge>
            </b-th>
            <b-th>
              <b-badge
                variant="success"
                class="w-100 text-center"
                style="
                  font-size: 14px;
                  font-weight: 600;
                  padding-bottom: 7px;
                  padding-top: 7px;
                "
              >
                {{ totalSpend | formatCurrency }}
              </b-badge>
            </b-th>
            <b-th>
              <b-badge
                variant="primary"
                class="w-100 text-center"
                style="font-size: 14px; font-weight: 600"
              >
                {{ totalResults }}
              </b-badge>
            </b-th>
            <b-th>
              <b-badge
                v-if="activeTab === 1"
                variant="primary"
                class="w-100 text-center"
                style="font-size: 14px; font-weight: 600"
              >
                {{ totalCostPerResult | formatCurrency }}
              </b-badge>
            </b-th>
            <b-th colspan="2" />
          </template>
        </b-table>
      </filter-slot>
    </b-card>
    <preview-ad
      v-if="showPreviewAd"
      :type="type"
      :ad-id="adId"
      :video-id="videoId"
      :ad-name="adName"
      :meta-user-id="metaUserId"
      @hidden="showPreviewAd = false"
    />
    <meta-details-modal
      v-if="showMetaDetailsModal"
      :ad-id="adId"
      :ad-name="adName"
      :type="type"
      :max-date="maxDate"
      :min-date="minDate"
      @hidden="showMetaDetailsModal = false"
    />
    <tracking-migration-status-modal
      v-if="showTrackingMigrationStatusModal"
      @hidden="showTrackingMigrationStatusModal = false"
    />
  </div>
</template>
  <script>
import MetaService from "@/views/creative/views/meta/service/meta.service.js";
import Filters from "@/views/creative/views/meta/data/filters.data.js";
import Fields from "@/views/creative/views/meta/data/fields.data.js";
import PreviewAd from "@/views/creative/views/meta/components/PreviewAd.vue";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment";
import MetaIconSvg from "@/views/creative/views/meta/components/MetaIconSvg.vue";
import { mapGetters } from "vuex";
import MetaDetailsModal from "@/views/creative/views/meta/components/MetaDetails.vue";
import TrackingMigrationStatusModal from "@/views/creative/views/meta/components/TrackingMigrationStatusModal.vue";
export default {
  name: "MetaMain",
  components: {
    PreviewAd,
    DateRangePicker,
    MetaIconSvg,
    MetaDetailsModal,
    TrackingMigrationStatusModal,
  },
  props: {
    sendYear: {
      type: Number,
      required: false,
      default: null,
    },
    sendMonth: {
      type: Number,
      required: false,
      default: null,
    },
    sendRange: {
      type: Boolean,
      required: false,
      default: true,
    },
    programId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  filters: {
    formatCurrency(value) {
      if (typeof value === "string") {
        value = value.replace(/,/g, "");
      }

      value = parseFloat(value);
      if (!value || value === 0 || isNaN(value)) {
        return "$0.00";
      }
      return value.toLocaleString(
        "en-US",
        {
          style: "currency",
          currency: "USD",
        },
        { minimumFractionDigits: 2 },
        { maximumFractionDigits: 2 }
      );
    },
  },
  data() {
    return {
      isBusy: false,
      Fields,
      filterSlot: {
        Filters,
        paginate: {
          currentPage: 1,
          perPage: 50,
        },
        startPage: 0,
        toPage: 0,
        totalRows: 0,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Search...",
          model: "",
        },
      },
      totalReach: 0,
      totalSpend: 0,
      totalCostPerResult: 0,
      totalResults: 0,
      showPreviewAd: false,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      dataYear: null,
      dataMonth: null,
      dateRange: {
        startDate: null,
        endDate: null,
      },
      maxDate: null,
      showMetaDetailsModal: false,
      selectedType: 1,
      optionsType: [
        { text: "Month", value: 1 },
        { text: "Customized", value: 2 },
      ],
      metaSendRange: null,
      showTrackingMigrationStatusModal: false,
      campaign_id: null,
      campaign_title: null,
      tabs: [
        {
          id: 1,
          title: "MESSAGES",
        },
        {
          id: 2,
          title: "OTHERS",
        },
      ],
      activeTab: 1,
      groupType: 1, // 1 = campaign, 2 = ad
    };
  },
  computed: {
    visibleFilters() {
      if (!this.metaSendRange) {
        this.getFilterByKey("program_id").visible = false;
      }
      if (this.groupType === 2) {
        this.getFilterByKey("ad_type").visible = true;
      } else {
        this.getFilterByKey("ad_type").visible = false;
      }

      return this.filterSlot.Filters.filter((filter) => filter.visible);
    },
    titleMonthYear() {
      return this.metaSendRange
        ? this.titleSendRange
        : `${this.months[this.dataMonth - 1]} ${this.dataYear}`;
    },
    titleSendRange() {
      const startDate = moment(this.dateRange.startDate).format("MM/DD/YYYY");
      const endDate = moment(this.dateRange.endDate).format("MM/DD/YYYY");
      return `${startDate} - ${endDate}`;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    visibleFields() {
      if (this.groupType === 2) {
        this.getFieldsByKey("route_thumb").visible = true;
        this.getFieldsByKey("ad_type").visible = true;
        this.getFieldsByKey("ad_name").visible = true;
        if (this.campaign_id) {
          this.getFieldsByKey("campaign_id").visible = false;
        } else {
          this.getFieldsByKey("campaign_id").visible = true;
        }
      } else {
        this.getFieldsByKey("route_thumb").visible = false;
        this.getFieldsByKey("ad_type").visible = false;
        this.getFieldsByKey("ad_name").visible = false;
        this.getFieldsByKey("campaign_id").visible = true;
      }

      return this.Fields.filter((field) => field.visible);
    },
    colspan() {
      if (this.groupType === 2) {
        return this.campaign_id ? 4 : 5;
      }
      return 2;
    },
  },
  created() {
    this.bindSocketFacebookAds();
    this.initMonthYear();
    this.initDateRange();
    this.metaSendRange = this.sendRange;
  },
  mounted() {},
  methods: {
    async myProvider(ctx) {
      try {
        const orderBy = ctx.sortBy ? ctx.sortBy : "spend";
        const order = ctx.sortDesc ? "desc" : ctx.sortBy ? "asc" : "desc";

        const params = {
          search_text: this.filterSlot.filterPrincipal.model,
          ad_type: this.getFilterByKey("ad_type").model,
          date_from: this.metaSendRange
            ? moment(this.dateRange.startDate).format("YYYY-MM-DD")
            : null,
          date_to: this.metaSendRange
            ? moment(this.dateRange.endDate).format("YYYY-MM-DD")
            : null,
          program_id: this.programId,
          npage: this.filterSlot.paginate.currentPage,
          perpage: this.filterSlot.paginate.perPage,
          orderby: orderBy,
          order: order,
          year: this.dataYear,
          month: this.dataMonth,
          range: this.metaSendRange,
          campaign_id: this.campaign_id,
          tab: this.activeTab,
          group_type: this.groupType,
        };

        const { data } = await MetaService.geAdsInsights(params);
        this.filterSlot.totalRows = data.total;
        this.filterSlot.startPage = data.from ?? 0;
        this.filterSlot.toPage = data.to ?? 0;
        this.filterSlot.paginate.currentPage = data.current_page;
        this.filterSlot.paginate.perPage = data.per_page;
        this.totalReach = data.data?.[0]?.total_reach ?? 0;
        this.totalSpend = data.data?.[0]?.total_spend ?? 0;
        this.totalCostPerResult = data.data?.[0]?.total_cost_per_result ?? 0;
        this.totalResults = data.data?.[0]?.total_results ?? 0;
        return data.data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    searchByCampaignId(campaign_id, campaign_title, group_type) {
      this.campaign_id = campaign_id;
      this.campaign_title = campaign_title;
      this.filterSlot.filterPrincipal.model = "";
      this.groupType = group_type;
      let buildTitle =
        this.titleMonthYear +
        (this.campaign_title ? ` - ${this.campaign_title}` : "");
      this.$emit("newTitleModal", buildTitle);
      this.refresh();
    },
    getFilterByKey(key) {
      return this.filterSlot.Filters.find((filter) => filter.key === key);
    },
    getFieldsByKey(key) {
      return this.Fields.find((field) => field.key === key);
    },
    getVariantByAdType(adType) {
      const types = {
        Flyer: "light-primary",
        Video: "light-success",
        Text: "light-info",
        Other: "light-secondary",
      };
      return types[adType] ?? "light-secondary";
    },
    getVariantStatusAd(item) {
      const types = {
        ACTIVE: "light-success",
        PAUSED: "light-warning",
        DELETED: "light-danger",
        PENDING_REVIEW: "light-info",
        DISAPPROVED: "light-danger",
        PREAPPROVED: "light-info",
        PENDING_BILLING_INFO: "light-info",
        CAMPAIGN_PAUSED: "light-warning",
        ARCHIVED: "light-info",
        ADSET_PAUSED: "light-warning",
        IN_PROCESS: "light-primary",
        WITH_ISSUES: "light-warning",
      };
      const effective_status = this.campaign_id
        ? item.effective_status
        : item.ca_effective_status;
      return {
        variant: types[effective_status] ?? "light-secondary",
        status: effective_status.replace(/_/g, " "),
      };
    },
    getAdImagePreviewInSoft(item) {
      this.resetVariables();
      this.showPreviewAd = true;
      this.type = "preview_image_in_soft";
      this.adId = item.ad_id;
      this.adName = item.ad_name;
    },
    getAdPreview(item) {
      this.resetVariables();
      this.showPreviewAd = true;
      this.adId = item.ad_id;
      this.metaUserId = item.meta_user_id;
      this.adName = item.ad_name;
      this.type = "ad_preview";
    },
    handleAdType(item) {
      if (item.url_flyer_meta) {
        this.getAdImagePreviewInMeta(item);
      } else if (item.video_id) {
        this.getAdVideoPreview(item);
      }
    },
    getAdImagePreviewInMeta(item) {
      this.resetVariables();
      this.showPreviewAd = true;
      this.type = "preview_image_in_meta";
      this.adId = item.ad_id;
      this.metaUserId = item.meta_user_id;
      this.adName = item.ad_name;
    },
    getAdVideoPreview(item) {
      this.resetVariables();
      this.showPreviewAd = true;
      this.type = "preview_video_in_meta";
      this.videoId = item.video_id;
      this.adName = item.ad_name;
      this.metaUserId = item.meta_user_id;
    },
    resetVariables() {
      this.metaUserId = null;
      this.adName = null;
      this.type = null;
      this.adId = null;
      this.videoId = null;
    },
    async next() {
      if (this.dataMonth < 12) {
        this.dataMonth++;
      } else {
        this.dataMonth = 1;
        this.dataYear++;
      }
      await this.refresh();
    },
    async prev() {
      if (this.dataMonth > 1) {
        this.dataMonth--;
      } else {
        this.dataMonth = 12;
        this.dataYear--;
      }
      await this.refresh();
    },
    async refresh() {
      let buildTitle =
        this.titleMonthYear +
        (this.campaign_title ? ` - ${this.campaign_title}` : "");
      this.$emit("newTitleModal", buildTitle);
      await this.$refs["meta-ads-table"].refresh();
    },
    initDateRange() {
      const startOfMonth = moment().startOf("month").format("MM/DD/YYYY");
      const today = moment().format("MM/DD/YYYY");
      this.dateRange.startDate = startOfMonth;
      this.dateRange.endDate = today;
      this.maxDate = today;
    },
    initMonthYear() {
      this.dataMonth = this.sendMonth;
      this.dataYear = this.sendYear;
    },
    async syncWithMeta() {
      try {
        this.addPreloader();
        const today = moment().format("YYYY-MM-DD");
        const params = {
          date: today,
          user_id: this.currentUser.user_id,
        };
        const { data } = await MetaService.syncWithMeta(params);
        this.showSuccessSwal(data.message);
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    openModalMetaDetails(item, type) {
      this.adId = item.ad_id;
      this.type = type;
      this.minDate = item.min_date;
      this.maxDate = item.max_date;
      this.adName = item.ad_name;
      this.showMetaDetailsModal = true;
    },
    handleTypeSearch() {
      const type = {
        MONTH: 1,
        CUSTOMIZED: 2,
      };
      if (this.selectedType === type.MONTH) {
        this.metaSendRange = false;
        this.initMonthYear();
        this.refresh();
      }

      if (this.selectedType === type.CUSTOMIZED) {
        this.metaSendRange = true;
        this.initDateRange();
        this.refresh();
      }
    },
    async bindSocketFacebookAds() {
      window.socket.bind("facebook-ads-notification", async ({ content }) => {
        if (this.currentUser.user_id !== content.user_id) return;
        this.showTimedToast({
          variant: content.variant,
          icon: content.icon,
          timeout: content.timeout,
          title: content.title,
          text: content.text,
        });

        if (content.variant === "success" || content.variant === "error") {
          await this.refresh();
        }
      });
    },
    handleGroupType() {
      this.groupType = this.groupType === 1 ? 2 : 1;
      this.campaign_id = null;
      this.refresh();
    },
  },
  beforeDestroy() {
    try {
      window.socket.unbind("facebook-ads-notification");
    } catch (error) {
      console.log("facebook-ads-notification", error);
    }
  },
};
</script>
<style lang="scss">
.meta-grid-table {
  .filter-slot-new-customization {
    #inner-duo-filter-container {
      .input-group.mr-1 {
        width: 700px !important;
      }
    }
  }
  #meta-ads-table {
    .only-meta-badge {
      position: absolute;
      left: -27px;
      top: -15px;
    }
    .text-decoration-underline {
      text-decoration: underline !important;
    }
    .programs_image {
      background-color: #e9e9e9;
      padding: 0.2rem;
      border-radius: 10px;
      width: 3.2rem;
      height: 3.2rem;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .b-table-bottom-row {
      position: sticky;
      bottom: 0;
      z-index: 1;

      th:nth-child(1) {
        border-radius: 0px 0px 0px 15px !important;
        margin-left: 5px !important;
        background: #0090e7 !important;
      }

      th:nth-child(n + 2):nth-child(-n + 7) {
        font-size: 16px;
        padding-top: 10px;
        padding-bottom: 10px;
        background: #0090e7 !important;
      }

      th:nth-child(7) {
        border-radius: 0px 0px 15px 0px !important;
        margin-left: 10px !important;
        background: #0090e7 !important;
      }
    }
    .fill-bottom-row {
      background-color: #ffffff !important;
    }
    .rounded {
      border-radius: 0.357rem !important;
    }
  }
  .customized-filter {
    .custom-datepciker {
      cursor: pointer;
      padding: 10px 10px;
      border: 1px solid #606060;
      border-radius: 8px;
      font-size: 0.9rem !important;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      width: 200px;
    }

    .custom-datepciker > svg {
      color: #606060 !important;
      margin: auto !important;
      margin-right: 0.5rem !important;
    }

    .daterangepicker .drp-calendar {
      max-width: 340px !important;
      width: 340px !important;
    }
  }
}

.dark-layout {
  .meta-grid-table {
    .customized-filter {
      .custom-datepciker {
        border: 1px solid #3a72ea;
        color: #3a72ea;
      }
      .custom-datepciker > svg {
        color: #3a72ea !important;
      }
    }
    #meta-ads-table {
      .fill-bottom-row {
        background-color: #17171a !important;
        border-top: none !important;
      }
    }
  }
}
</style>