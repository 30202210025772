export default [
  {
    key: "lead_name",
    label: "Lead Name",
    sortable: false,
    thStyle: "background: #0090E7; color: white; border-radius: 15px 0 0 0;",
  },
  {
    key: "seller_name",
    label: "Seller",
    sortable: false,
    thStyle: "background: #0090E7; color: white;",
  },
  {
    key: "programs",
    label: "Programs",
    sortable: false,
    thClass: "text-center",
    tdClass: "text-center",
    thStyle: "background: #0090E7; color: white;",
  },
  {
    key: "call_answered",
    label: "Answered",
    sortable: false,
    thClass: "text-center",
    tdClass: "text-center",
    thStyle: "background: #0090E7; color: white;",
  },
  {
    key: "sale_made",
    label: "Sale Made",
    sortable: false,
    thClass: "text-center",
    tdClass: "text-center",
    thStyle: "background: #0090E7; color: white;",
  },
  {
    key: "total_reschedule",
    label: "Reschedule",
    sortable: false,
    thClass: "text-center",
    tdClass: "text-center",
    thStyle: "background: #0090E7; color: white;",
  },
  {
    key: "appointment_date",
    label: "Appointment Date",
    sortable: false,
    thClass: "text-center",
    tdClass: "text-center",
    thStyle: "background: #0090E7; color: white;",
  },
  {
    key: "creator_name",
    label: "Created By",
    sortable: false,
    thClass: "text-center",
    tdClass: "text-center",
    thStyle: "background: #0090E7; color: white; border-radius: 0 15px 0 0;",
  },
];
