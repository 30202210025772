<template>
  <div
    class="dashboard__card"
    :class="isDarkSkin ? 'dashboard__card--dark' : ''"
  >
    <div class="card-report">
      <div class="report-header d-flex align-items-center">
        <h2 class="report-header__title">Pending Approval</h2>
        <div class="report-header__icons other-payment-icon">
          <feather-icon icon="DollarSignIcon" size="25" />
        </div>
      </div>
      <div class="line-card" />
      <div class="report-body" style="margin-bottom: 15px">
        <div class="report d-flex align-items-center justify-content-between">
          <span style="text-wrap: nowrap" class="font-weight-bolder"
            >Settlements:</span
          >
          <div>
            <div
              @click="openModalCeDs('type_ds', dataOffers)"
              class="report__value cursor-pointer"
              style="margin-left: 10px"
              :class="isDarkSkin ? 'report__value--dark' : ''"
              v-if="G_OFFER_NOTIFICATION_COUNTER != 0"
            >
              <div class="report__value--new--op d-flex">
                <span>{{ G_OFFER_NOTIFICATION_COUNTER }}</span>
              </div>
            </div>
            <div
              v-else
              style="font-size: 14px; color: #b4b4b4; margin-left: 5px"
            >
              No offers
            </div>
          </div>
        </div>
        <div
          v-if="creditCardsData != 0"
          class="d-flex align-items-center justify-content-between font-weight-bolder"
        >
          <span style="text-wrap: nowrap">Cards:</span>
          <div v-if="loading.cards" style="margin-left: 10px">
            <b-spinner small variant="primary" />
          </div>
          <div
            v-else
            style="margin-left: 10px"
            :class="isDarkSkin ? 'report__value--dark' : ''"
          >
            <div
              @click="openDetailRequest(item, 0)"
              class="report__value--new--op cursor-pointer"
            >
              {{ creditCardsData }}
            </div>
          </div>
        </div>
        <div
          class="report d-flex align-items-center justify-content-between font-weight-bolder"
        >
          <span class="font-weight-bolder">Checks:</span>
          <div v-if="loading.checks" style="margin-left: 10px">
            <b-spinner small variant="primary" />
          </div>
          <div
            v-else
            class="report__content"
            :class="isDarkSkin ? 'report__value--dark' : ''"
          >
            <div
              v-if="checksData != 0"
              class="report__value--new--op cursor-pointer"
              @click="openPendingApprovalCheckModal(0)"
            >
              {{ checksData }}
              <span style="font-size: 11px !important">(NEW) </span>
            </div>
            <div
              v-if="checksToReview != 0"
              class="report__value--new--op cursor-pointer"
              @click="openPendingApprovalCheckModal(1)"
            >
              <span v-if="checksData != 0"> / </span>
              {{ checksToReview }}
              <span style="font-size: 11px !important">(UNDERVIEW)</span>
            </div>
            <div
              v-if="checksChanged != 0"
              class="report__value--new--op cursor-pointer"
              @click="openPendingApprovalCheckModal(2)"
            >
              <span v-if="checksData != 0 || checksToReview != 0"> / </span>
              {{ checksChanged }}
              <span style="font-size: 11px !important">(# CHANGED)</span>
            </div>
            <div
              v-if="
                checksData == 0 && checksToReview == 0 && checksChanged == 0
              "
            >
              Not actions
            </div>
          </div>
        </div>
        <div
          class="report d-flex align-items-center justify-content-between font-weight-bolder"
        >
          <span class="font-weight-bolder">E-Checks:</span>
          <div v-if="loading.checks" style="margin-left: 10px">
            <b-spinner small variant="primary" />
          </div>
          <div
            v-else
            class="report__content"
            :class="isDarkSkin ? 'report__value--dark' : ''"
          >
            <div v-if="echecksData != 0" class="report__value--new--op cursor-pointer" @click="showPendingApprovalECheck = true">
              {{ echecksData }}
              <span style="font-size: 11px !important">(UNDERVIEW) </span>
            </div>
            <div
              v-else
            >
              Not actions
            </div>
          </div>
        </div>
        <div
          class="report d-flex align-items-center justify-content-between font-weight-bolder"
        >
          <span class="font-weight-bolder">Others Payment:</span>

          <div v-if="loading.others" style="margin-left: 10px">
            <b-spinner small variant="primary" />
          </div>
          <div
            class="cursor-pointer report__content"
            :class="isDarkSkin ? 'report__value--dark' : ''"
            v-if="totalOthers != 0 && !loading.others"
          >
            <div
              class="report__value--new--op"
              v-if="others.zelle != 0"
              @click="openOtherPayment(1)"
            >
              <span>{{ others.zelle }}</span>
              <span style="font-size: 11px !important">(ZELLE)</span>
            </div>
            <div
              class="report__value--new--op"
              v-if="others.deposit != 0"
              @click="openOtherPayment(1)"
            >
              <span v-if="others.zelle != 0">/</span>
              <span style="margin-left: 5px"> {{ others.deposit }}</span>
              <span style="font-size: 11px !important">(DEPOSIT)</span>
            </div>
            <div
              class="report__value--new--op"
              v-if="others.cash != 0"
              @click="openOtherPayment(2)"
            >
              <span v-if="others.zelle != 0 || others.deposit != 0">/</span>
              <span style="margin-left: 5px"> {{ others.cash }}</span>
              <span style="font-size: 11px !important">(CASH)</span>
            </div>
            <div
              class="report__value--new--op"
              v-if="others.check != 0"
              @click="openOtherPayment(3)"
            >
              <span
                v-if="
                  others.zelle != 0 || others.deposit != 0 || others.cash != 0
                "
              >
                /
              </span>
              <span style="margin-left: 5px"> {{ others.check }}</span>
              <span style="font-size: 11px !important">(CHECK)</span>
            </div>
          </div>
          <div
            v-else-if="!loading.others"
            style="font-size: 14px; color: #b4b4b4; margin-left: 5px"
          >
            No payments yet
          </div>
        </div>
      </div>
      <hr />
      <div class="report-header">
        <h2 class="report-header__title">Employee</h2>
        <div class="report-header__icons icons_employee">
          <img :src="imgEmployee" alt="img_sales" />
        </div>
      </div>

      <div class="line-card-2" />

      <div class="report-body">
        <div class="report d-flex align-items-center justify-content-between">
          <span style="text-wrap: nowrap" class="font-weight-bolder"
            >Turnover:</span
          >
          <div v-if="loading.turnover" style="margin-left: 10px">
            <b-spinner small variant="primary" />
          </div>
          <div
            v-else
            class="cursor-pointer report__content font-weight-bolder"
            style="margin-left: 10px"
            :class="isDarkSkin ? 'report__value--dark' : ''"
          >
            <div
              class="report__value--new--op"
              @click="
                showModalDetailEmployee(
                  null,
                  null,
                  objEmployeeTurnover.total_new_employees,
                  0
                )
              "
            >
              <span>+{{ objEmployeeTurnover.total_new_employees }}</span>
            </div>
            <span style="font-size: 15px; margin: 0 5px">/</span>
            <div
              class="report__value--new--op text-danger"
              @click="
                showModalDetailEmployee(
                  null,
                  null,
                  objEmployeeTurnover.total_new_employees,
                  1
                )
              "
            >
              <span>-{{ objEmployeeTurnover.total_retired_employees }}</span>
            </div>
          </div>
        </div>
        <div class="report d-flex align-items-center justify-content-between">
          <span style="text-wrap: nowrap" class="font-weight-bolder"
            >Attendance:</span
          >
          <div v-if="loading.attendance" style="margin-left: 10px">
            <b-spinner small variant="primary" />
          </div>
          <div
            v-else
            class="cursor-pointer report__content font-weight-bolder"
            style="margin-left: 10px"
            :class="isDarkSkin ? 'report__value--dark' : ''"
            @click="showAttendanceModal = true"
          >
            <div class="report__value--new--op text-warning">
              <span>{{ totalAttendance.lates }}</span>
              <span style="font-size: 11px !important">(LATES)</span>
            </div>
            <span style="font-size: 15px; margin: 0 5px">/</span>
            <div class="report__value--new--op text-danger">
              <span>{{ totalAttendance.absences }}</span>
              <span style="font-size: 11px !important">(ABSENCES)</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <OtherPaymentModal
      v-if="showOtherPaymentModal"
      :counters="others"
      :type="typeModal"
      @close="showOtherPaymentModal = false"
      @reload="getCounterOthersPayments"
    />
    <detail-employee-turnover
      v-if="showDetailEmployeeTurnover"
      :send-year="dataYear"
      :send-month="dataMonth"
      :send-department-id="idDepartment"
      :send-department-name="nameDepartment"
      :tab-selected="tabIndex"
      @close="showDetailEmployeeTurnover = false"
    />

    <ce-ds-modal
      v-if="statusModalCeDe"
      :data-client="dataClient"
      :type-modal-name="typeModalName"
      :card-chosen="dataOffers"
      @close="statusModalCeDe = false"
      @reloadCeo="reloadValue"
    />
    <AttendanceReports
      v-if="showAttendanceModal"
      :send-year="dataYear"
      :send-month="dataMonth"
      @close="showAttendanceModal = false"
    />
    <expenses-request-detail
      v-if="showExpensesRequestDetailModal.show"
      @refresh="getRequestExpenses()"
      @close="showExpensesRequestDetailModal.show = false"
      :indexSelected="showExpensesRequestDetailModal.indexSelected"
    />
    <pending-approval-check
      v-if="showPendingApprovalCheckModal"
      :tab="tabIndex"
      @close="showPendingApprovalCheckModal = false"
    />
    <PendingApprovalECheck
      v-if="showPendingApprovalECheck"
      @close="showPendingApprovalECheck = false"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service";
import DetailEmployeeTurnover from "@/views/ceo/dashboard/modals/employee/DetailEmployeeTurnover.vue";
import OtherPaymentModal from "@/views/ceo/dashboard/modals/OtherPaymentModal.vue";
import CeDsModal from "@/views/ceo/dashboard/modals/CeDsModal.vue";
import AttendanceReports from "@/views/ceo/dashboard/modals/attendance/AttendanceReports.vue";
import ExpensesRequestDetail from "@/views/ceo/dashboard/modals/expenses-request/ExpensesRequestDetail.vue";
import PendingApprovalCheck from "@/views/ceo/dashboard/modals/expenses-request/PendingApprovalCheck.vue";
import PendingApprovalECheck from "@/views/ceo/dashboard/modals/expenses-request/PendingApprovalECheck.vue";

export default {
  components: {
    DetailEmployeeTurnover,
    OtherPaymentModal,
    CeDsModal,
    AttendanceReports,
    ExpensesRequestDetail,
    PendingApprovalCheck,
    PendingApprovalECheck,
  },
  props: {
    selectYear: {
      type: Number,
      default: null,
      required: false,
    },
    selectMonth: {
      type: Number,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      imgEmployee: require("@/assets/images/icons/dashboard-ceo/employee.svg"),
      dataYear: null,
      dataMonth: null,
      objEmployeeTurnover: {
        total_new_employees: 0,
        total_retired_employees: 0,
      },
      idDepartment: null,
      showDetailEmployeeTurnover: false,
      nameDepartment: "",
      employeeTurnoverData: [],
      newEmployees: 0,
      tabIndex: 0,
      others: {
        zelle: 0,
        check: 0,
        cash: 0,
        deposit: 0,
      },
      showOtherPaymentModal: false,
      statusModalCeDe: false,
      typeModalName: "",
      dataOffers: {
        isMoney: false,
        name: "data_offers",
        sendBack: "offers",
        showName: "Offers",
        value: 0,
      },
      dataClient: {
        statusId: null,
        statusName: null,
        year: this.selectYear,
        month: this.selectMonth,
        idProgram: 0,
        state: 0,
      },
      typeModal: 1,
      dataAttendance: {
        lates: [],
        absences: [],
        grouped: [],
      },
      showAttendanceModal: false,
      loading: {
        attendance: true,
        turnover: true,
        others: true,
        settlement: true,
        checks: true,
        cards: true,
      },
      expensesRequest: [],
      showExpensesRequestDetailModal: {
        show: false,
        indexSelected: 0,
      },
      creditCardsData: [],
      checksData: 0,
      checksToReview: 0,
      checksChanged: 0,
      echecksData: 0,
      toReview: false,
      showPendingApprovalCheckModal: false,
      showPendingApprovalECheck: false,
    };
  },
  watch: {
    selectMonth(newVal) {
      this.dataMonth = newVal;
      this.getEmployeeTurnover();
      this.getCounterAttendance();
    },
    selectYear(newVal) {
      this.dataYear = newVal;
      this.getEmployeeTurnover();
      this.getCounterAttendance();
    },
    G_REFRESH_EXPENSES_COUNTER(newVal) {
      if (newVal) {
        // this.getRequestExpenses();
        this.getCreditCardsCounter();
        this.getChecksCounter();
        this.refreshExpensesCounter(false);
      }
    },
  },
  computed: {
    totalOthers() {
      const { zelle, check, cash, deposit } = this.others;
      return zelle + check + cash + deposit;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_OFFER_NOTIFICATION_COUNTER:
        "OfferNotificationStore/G_OFFER_NOTIFICATION_COUNTER",
      G_REFRESH_EXPENSES_COUNTER: "ExpensesRequest/G_REFRESH_EXPENSES_COUNTER",
    }),
    totalAttendance() {
      let lates = this.dataAttendance.lates.reduce(
        (acc, item) => acc + item.totals,
        0
      );
      let absences = this.dataAttendance.absences.reduce(
        (acc, item) => acc + item.totals,
        0
      );

      return { lates, absences };
    },
  },

  async created() {
    this.dataMonth = this.selectMonth;
    this.dataYear = this.selectYear;
    await this.getCounterOthersPayments();
    await this.getCreditCardsCounter();
    await this.getChecksCounter();
    // await this.getRequestExpenses();
    await this.getEmployeeTurnover();
    await this.getCounterAttendance();
  },

  methods: {
    ...mapActions({
      refreshExpensesCounter: "ExpensesRequest/refreshExpensesCounter",
      A_GET_OFFER_NOTIFICATIONS:
        "OfferNotificationStore/A_GET_OFFER_NOTIFICATIONS",
      G_REFRESH_AFTER_APPROVE_EXPENSE:
        "ExpensesRequest/refreshAfterApproveExpense",
      A_METHOD_EXPENSE: "ExpensesRequest/A_METHOD_EXPENSE",
    }),
    openDetailRequest(item, index) {
      this.showExpensesRequestDetailModal.indexSelected = index;
      this.showExpensesRequestDetailModal.show = true;
      this.A_METHOD_EXPENSE("CREDIT_CARD");
    },
    // openDetailRequestToReview(item, index) {
    //   this.toReview = true;
    //   this.showExpensesRequestDetailModal.indexSelected = index;
    //   this.showExpensesRequestDetailModal.show = true;
    // },
    reloadValue() {
      this.A_GET_OFFER_NOTIFICATIONS({ role_id: this.currentUser.role_id });
    },
    openModalCeDs(typeModal, data) {
      this.typeModalName = typeModal;
      this.statusModalCeDe = true;
    },
    async getCounterOthersPayments() {
      try {
        this.loading.others = true;
        this.others = await DashboardServive.getCounterOthersPayments();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading.others = false;
      }
    },

    clearSpace(number) {
      if (number !== undefined && number !== null) {
        return number.toString().trim();
      }
      return "";
    },

    async getEmployeeTurnover() {
      try {
        this.loading.turnover = true;
        const params = {
          month: this.selectMonth,
          year: this.selectYear,
        };
        const data = await DashboardServive.getEmployeeTurnover(params);
        this.employeeTurnoverData = data.employee_turnover?.filter(
          (item) => item.new_employees != 0 || item.retired_employees != 0
        );
        this.objEmployeeTurnover = data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading.turnover = false;
      }
    },
    async getRequestExpenses() {
      try {
        const { data } = await DashboardServive.getRequestExpenses();
        this.expensesRequest = data;
      } catch (error) {
        console.log(error);
      }
    },

    async getCreditCardsCounter() {
      try {
        this.loading.cards = true;
        const data = await DashboardServive.getCreditCardsCounter();
        this.creditCardsData = data[0].counter;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading.cards = false;
      }
    },

    async getChecksCounter() {
      try {
        this.loading.checks = true;
        const data = await DashboardServive.getChecksCounter();
        this.checksData = data.new;
        this.checksToReview = data.to_review;
        this.checksChanged = data.changed;
        this.echecksData = data.new_e_check;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading.checks = false;
      }
    },

    showModalDetailEmployee(department_id, name, new_employees, tabIndex = 0) {
      this.idDepartment = department_id;
      this.nameDepartment = name;
      this.newEmployees = new_employees;
      this.showDetailEmployeeTurnover = true;
      this.tabIndex = tabIndex;
    },

    openOtherPayment(type) {
      this.typeModal = type;
      this.showOtherPaymentModal = true;
    },
    openPendingApprovalCheckModal(tab) {
      this.tabIndex = tab;
      this.showPendingApprovalCheckModal = true;
    },
    async getCounterAttendance() {
      try {
        this.loading.attendance = true;
        const { absences, lates, grouped } =
          await DashboardServive.getCounterAttendance({
            month: this.selectMonth,
            year: this.selectYear,
          });
        this.dataAttendance = { absences, lates, grouped };
      } catch (error) {
        console.log(error);
      } finally {
        this.loading.attendance = false;
      }
    },
  },
  openPendingApprovalCheckModal() {
    this.showPendingApprovalCheckModal = true;
    this.A_METHOD_EXPENSE("CREDIT_CARD");
  },
};
</script>
<style lang="scss" scoped>
$color-primary: #5f5f5f;
$color-secondary: #ffffff;
$bg-card-white: #ffffff;
$bg-card-dark: #111113;
$box-shadow-white: #00000026;

.dashboard__card {
  margin-top: 5px;
  box-shadow: 0px 0px 15px 0px $box-shadow-white;
  border-radius: 15px;
  margin-bottom: 27px;
  padding: 18px;
  background-color: $bg-card-white;
  height: auto !important;

  &--dark {
    background-color: $bg-card-dark;
    box-shadow: 0px 0px 22.935px 0px rgba(255, 249, 249, 0.1);
  }

  .card-report {
    position: relative;

    .report-header {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;

      &__title {
        color: #959595;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding-left: 14px;
      }

      &__icons {
        width: 47px;
        height: 47px;
      }
      .icons_employee {
        border-radius: 9.174px;
        background: #a7c5ff;
        width: 47px;
        height: 47px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .line-card {
      width: 4.5px;
      height: 120px;
      background-color: #1654cc;
      position: absolute;
      top: 14px;
      left: -3px;
      border-radius: 8px;
    }

    .line-card-2 {
      width: 4.5px;
      height: 80px;
      background-color: #1654cc;
      position: absolute;
      top: 180px;
      left: -3px;
      border-radius: 8px;
    }

    .report-body {
      padding-left: 14px;
      // padding-right: 8px;

      .report {
        width: 100%;
        &__content {
          margin-left: 10px;
          white-space: nowrap;
          display: flex;
          justify-content: space-between;
          flex-wrap: nowrap;
        }
        &__value {
          color: #606060;
          font-family: Montserrat;
          font-weight: 700;
          font-size: 1.6rem;
          display: flex;
          width: 100%;

          &--new {
            color: #15d763;
            margin-right: 20px;

            span {
              margin-left: 2px;
            }
          }
          &--new--op {
            color: #15d763;
            margin-right: 5px;
            font-size: 16px;

            span {
              margin-left: 2px;
              font-size: 16px;
            }
          }

          &--retired {
            color: #ff4a4a;
            span {
              margin-left: 2px;
            }
          }
        }
      }

      .detail-employee {
        width: 100%;
        height: 110px;
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;
        gap: 3px;
        align-content: flex-start;

        .employee {
          width: calc(33.33% - 3px);
          height: 25px;
          padding: 4px 8px;
          display: flex;
          justify-content: space-between;
          border-radius: 10px;
          background: rgba(191, 191, 191, 0.25);
          align-items: center;
          font-size: 0.93rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          font-family: Montserrat;
          margin-bottom: 5px;
          // margin-bottom:5px;

          &__program {
            color: #606060;

            &--dark {
              color: #b4b4b4;
            }
          }

          &__input {
            color: #15d763;

            &::before {
              content: "+";
            }
          }

          &__ouput {
            color: #ff4a4a;
            font-weight: 700;

            &::before {
              content: "-";
            }
          }
        }
      }
      .no-data-employee {
        width: 100%;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.other-payment-icon {
  background: #b1fccf;
  border-radius: 10px;
  color: #00bf08;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
