<template>
  <div
    :id="message.type == 'Chat' ? message.id : message.id + '-date'"
    class="message-bubble-container"
    :class="{
      me: message.me,
      other: !message.me,
    }"
  >
    <div v-if="visibleMessage(message)" class="message-container">
      <div
        class="message"
        :class="{
          me: message.me,
          other: !message.me,
        }"
      >
        <b-avatar
          v-if="message.me"
          size="30"
          variant="light-secondary"
          :src="
            isUserValid(message)
              ? getImageUser(message.user.id)
              : G_CURRENT_FB_PAGE.picture
          "
        />
        <b-avatar
          v-if="!message.me"
          size="30"
          variant="light-secondary"
          :src="senderAvatar"
        />

        <div
          class="message-content"
          :class="{
            me: message.me,
            other: !message.me,
          }"
        >
          <!-- show reply to message -->
          <MessageReplyTo v-if="validReplyTo(message)" :message="message" />

          <Bubble
            v-if="message.message"
            :class="{
              me: message.me,
              other: !message.me,
            }"
          >
            <p
              v-if="message.type == 'Chat' || message.type == 'Unsupported'"
              class="m-0"
              :class="{
                'message-error':
                  message.messageStatus == 'Error' ||
                  message.type == 'Unsupported',
              }"
              v-html="processMessageChat(message.message)"
            ></p>

            <div v-if="isMessageCall(message)" class="message-call">
              <b-badge
                :variant="getMessageCall(message, message.me).variant"
                class="message-call-badge"
              >
                <feather-icon
                  :icon="getMessageCall(message, message.me).icon"
                  size="20"
                />
              </b-badge>
              <div class="message-call-text">
                <strong>{{ getMessageCall(message, message.me).text }}</strong>
                <span>{{ message.createdTime | formatHourTime }}</span>
              </div>
            </div>
          </Bubble>

          <Attachments
            v-if="message.attachments.length > 0"
            :attachments="message.attachments"
            :me="message.me"
            class="bubble-message"
          />

          <div class="bubble-message" v-if="message.isSticker">
            <img class="message-sctiker" :src="message.sticker" alt="" />
          </div>

          <div
            class="icon-reply-message"
            :class="{
              me: message.me,
              other: !message.me,
            }"
            v-b-tooltip.hover.top="`Reply`"
          >
            <feather-icon icon="CornerUpLeftIcon" size="16" />
          </div>
        </div>
      </div>

      <div
        class="message-created-time"
        :class="{
          me: message.me,
          other: !message.me,
        }"
      >
        <span class="message-user" v-if="message.me && isUserValid(message)">
          {{
            message.messageStatus == "Error" ? "Message not sent" : "Send by"
          }}
          <strong>{{ message.user.name }}</strong>
        </span>

        <div class="created-time-status">
          <!-- This is the message read by the user -->
          <UsersMessageRead v-if="isMessageSeen" :users="message.usersSeen" />

          <span v-if="!isMessageCall(message)" class="message-time">
            {{ message.createdTime | formatHourTime }}
          </span>

          <!-- This message send from me -->
          <MessageStatus
            v-if="isMessageStatus"
            :message="message"
            :sender-avatar="senderAvatar"
          />
        </div>
      </div>
    </div>

    <div v-if="message.type == 'Date'" class="message-date-container">
      <div class="message-date">
        <b-badge variant="light-secondary">
          {{ messageDate(message.message) }}
        </b-badge>
      </div>
    </div>
    <div v-if="message.type == 'Notice'" class="message-notice-container">
      <div class="message-notice">
        <span v-html="messageNotice(message.message)"></span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Bubble from "@/views/commons/Messenger/components/messenger/messages/components/BubbleMessage.vue";
import Attachments from "@/views/commons/Messenger/components/messenger/messages/components/Attachments.vue";
import moment from "moment";
import UsersMessageRead from "@/views/commons/Messenger/components/messenger/messages/components/UsersMessageRead.vue";
import MessageStatus from "@/views/commons/Messenger/components/messenger/messages/components/MessageStatus.vue";
import MessageReplyTo from "@/views/commons/Messenger/components/messenger/messages/components/MessageReplyTo.vue";

export default {
  name: "MessageBubble",
  components: {
    Bubble,
    Attachments,
    UsersMessageRead,
    MessageStatus,
    MessageReplyTo,
  },
  filters: {
    formatHourTime(value) {
      if (!value) return "";
      return moment(value).tz("America/Los_Angeles").format("h:mm A");
    },
  },
  props: {
    message: {
      type: Object,
      required: false,
      default: () => {},
    },
    senderAvatar: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      G_CURRENT_FB_PAGE: "MessengerMetaStore/G_CURRENT_FB_PAGE",
      G_USERS_IMAGES: "SmsInboxStore/G_USERS_IMAGES",
    }),
    isMessageStatus() {
      return (
        (this.message.me && this.message.messageStatus) ||
        this.message.type == "Unsupported"
      );
    },
    isMessageSeen() {
      return this.message.usersSeen && this.message.usersSeen.length > 0;
    },
  },
  created() {},
  mounted() {},
  methods: {
    messageNotice(message) {
      const result = this.processMessageNotice(message);
      if (result.url) {
        return `${result.text} <a href="${result.url}" target="_blank">${result.enlace}</a>`;
      } else {
        return result.text;
      }
    },
    processMessageNotice(message) {
      const urlRegex =
        /\((https?:\/\/[^\s]+|fb-pma:\/\/[^\s]+|fb-messenger:\/\/[^\s]+)\)/;
      const urlMatch = message.match(urlRegex);
      let result = {
        text: message,
        enlace: null,
        url: null,
      };
      if (urlMatch) {
        const url = urlMatch[1];
        const text = message.replace(urlRegex, "");
        const textParts = text.split(".");
        const textBeforeLastDot = textParts.slice(0, -1).join(".") + ".";
        const textLastPart = textParts[textParts.length - 1].trim();

        result.text = textBeforeLastDot;
        result.enlace = textLastPart ?? "See more";
        result.url = url;
      }
      return result;
    },
    messageDate(date) {
      const tz = "America/Los_Angeles";
      const today = moment().tz(tz).startOf("day");
      const yesterday = moment().tz(tz).subtract(1, "days").startOf("day");
      const messageDate = moment(date).tz(tz).startOf("day");

      if (messageDate.isSame(today)) {
        return "Today";
      }
      if (messageDate.isSame(yesterday)) {
        return "Yesterday";
      }
      return messageDate.format("dddd, MMMM DD, YYYY");
    },
    visibleMessage(message) {
      const types = ["Chat", "MissedCall", "SuccessfulCall", "Unsupported"];
      return (
        types.includes(message.type) &&
        (message.message || message.attachments.length > 0 || message.isSticker)
      );
    },
    isMessageCall(message) {
      return message.type == "MissedCall" || message.type == "SuccessfulCall";
    },
    getMessageCall(message, isMe) {
      const types = {
        MissedCall: {
          text: "Llamada de audio perdida",
          icon: "PhoneMissedIcon",
          variant: "light-danger",
        },
        SuccessfulCall: {
          text: "Llamada de audio",
          icon: isMe ? "PhoneOutgoingIcon" : "PhoneIncomingIcon",
          variant: isMe ? "light-success" : "light-primary",
        },
      };
      return types[message.type];
    },
    isUserValid(message) {
      return message.user && message.user.id;
    },
    getImageUser(userId) {
      const user = this.G_USERS_IMAGES.find((user) => user.id == userId);
      if (user) {
        return user.image_thumb;
      }
      return null;
    },
    processMessageChat(text) {
      const urlRegex =
        /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
      return text.replace(urlRegex, (url) => {
        return `<a href="${url}" class="message-url" target="_blank" rel="noopener noreferrer">${url}</a>`;
      });
    },
    validReplyTo(message) {
      return message.replyTo && message.replyTo.id;
    },
  },
};
</script>
<style lang="scss">
.message-bubble-container {
  display: flex;
  align-items: end;
  margin: 0.5rem 1.5rem;
  /*&:hover {
    .icon-reply-message {
      display: block !important;
    }
  }*/
  .message-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-width: 45%;
    .message {
      display: flex;
      gap: 1rem;
      align-items: end;
      .message-content {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        position: relative;
        .bubble-message {
          border-radius: 1rem;
          overflow: hidden;
        }
      }
      .message-content.me {
        align-items: end;
      }
      .icon-reply-message {
        position: absolute;
        top: 40%;
        cursor: pointer;
        display: none;
      }
      .icon-reply-message.me {
        left: -2rem;
      }
      .icon-reply-message.other {
        right: -2rem;
      }
      .message-sctiker {
        max-width: 100px;
        max-height: 100px;
      }
      .message-url {
        color: unset;
        text-decoration: underline;
        font-weight: 700;
      }
    }
    .message.me {
      flex-direction: row-reverse;
    }
    .message-created-time {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: row;
      gap: 0.5rem;
      .message-user {
        font-size: 0.8rem;
      }
      .message-time {
        font-size: 0.88rem;
      }
      .created-time-status {
        display: flex;
        gap: 0.5rem;
      }
    }
    .message-created-time.me {
      margin-right: 3rem;
    }
    .message-created-time.other {
      margin-left: 3rem;
    }
    @media (max-width: 1140px) {
      .message-created-time {
        flex-direction: column !important;
        align-items: flex-end !important;
      }
    }
  }
  .message-date-container,
  .message-notice-container {
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    .message-date,
    .message-notice {
      padding: 0 1rem;
      z-index: 99;
    }
  }

  .message-notice-container {
    .message-notice {
      background-color: #ffffff;
      width: 65%;
      text-align: center;
    }
  }
  .message-error {
    opacity: 0.6;
  }
  .message-call {
    display: flex !important;
    align-items: center !important;
    gap: 0.5rem !important;
    .message-call-badge {
      padding: 0.7rem !important;
      border-radius: 50% !important;
      .feather {
        height: 1.6rem !important;
        width: 1.6rem !important;
      }
    }
    .message-call-text {
      display: flex !important;
      flex-direction: column !important;
      justify-content: center !important;
    }
  }

  .bg-light-primary {
    transition: background-color 0.3s !important;
  }
}
.message-bubble-container.me {
  justify-content: flex-end;
}
.message-bubble-container.other {
  justify-content: flex-start;
}

.dark-layout {
  .message-bubble-container {
    .message-date-container,
    .message-notice-container {
      .message-notice {
        background-color: #111113;
      }
    }
  }
}
</style>