<template>
  <b-modal
    ref="imported-transactions-details-modal"
    modal-class="custom-modal-amg"
    title-class="h3 text-white"
    title="IMPORTED TRANSACTIONS"
    size="xmd"
    no-close-on-backdrop
    @hidden="closeModal()"
    hide-footer
    header-class="p-0"
    centered
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <div class="d-flex align-items-center">IMPORTED TRANSACTIONS</div>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="closeModal()"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>
    <div class="ml-1 my-1">
      <div
        class="absolute-linker d-flex justify-content-between align-items-center"
        :class="{ dark: isDarkSkin }"
      >
        <div>
          <v-select
            :options="creditCards"
            v-model="cardSelected"
            :clearable="false"
            placeholder="Select a card"
            style="min-width: 300px"
            @input="getImportedTransactions()"
          >
            <template #selected-option="option">
              <div class="d-flex flex-column">
                <span>Bank: {{ option.bank }}</span>
                <span
                  >Card: {{ option.cardname }} | XXXX-{{
                    option.cardnumber.slice(-4)
                  }}</span
                >
              </div>
            </template>
            <template v-slot:option="option">
              <div class="d-flex flex-column">
                <span>Bank: {{ option.bank }}</span>
                <span
                  >Card: {{ option.cardname }} | XXXX-{{
                    option.cardnumber.slice(-4)
                  }}</span
                >
              </div>
            </template>
          </v-select>
        </div>
        <div class="d-flex align-items-center h-full px-1">
          <feather-icon
            icon="ChevronLeftIcon"
            size="20"
            class="cursor-pointer"
            @click="prev()"
          />
          <span class="font-size:15px">
            {{ months[dataMonth - 1] }} {{ dataYear }}
          </span>
          <feather-icon
            icon="ChevronRightIcon"
            size="20"
            class="cursor-pointer"
            :class="{ 'disabled-next': disableNext }"
            @click="next()"
          />
        </div>
      </div>
      <div
        class="absolute-linker d-flex justify-content-between align-items-center my-1"
        :class="{ dark: isDarkSkin }"
      >
        <div>
          <v-select
            :options="itemsHistory"
            v-model="rangeSelected"
            class="custom-select-uwu"
            :clearable="false"
            placeholder="Select a range"
            style="min-width: 300px"
            @input="refresh()"
          >
            <template #selected-option="option">
              <div>
                <strong>{{
                  option.date_ranges.split(" - ")[0] | myGlobal
                }}</strong>
                -
                <strong>{{
                  option.date_ranges.split(" - ")[1] | myGlobal
                }}</strong>
              </div>
            </template>
            <template v-slot:option="option">
              <div>
                <strong>{{
                  option.date_ranges.split(" - ")[0] | myGlobal
                }}</strong>
                -
                <strong>{{
                  option.date_ranges.split(" - ")[1] | myGlobal
                }}</strong>
              </div>
            </template>
          </v-select>
        </div>
        <div>
          <b-button variant="outline-success" @click="openImportTransactions()">
            <div class="d-flex align-items-center" style="gap: 10px">
              <feather-icon icon="UploadIcon" />
              <span>Import Transactions</span>
            </div>
          </b-button>
        </div>
      </div>
    </div>
    <filter-slot
      :filter="filter"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="refresh()"
    >
      <b-table
        slot="table"
        :items="getImportedTransactionsDetails"
        :fields="fields"
        :per-page="paginate.perPage"
        :current-page="paginate.currentPage"
        ref="import-transaction-history-details"
        sticky-header="50vh"
        show-empty
        :busy.sync="isBusy"
        :filter="filter"
        class="position-relative table-new-customization"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(transaction_date)="{ item }">
          <div>
            {{ item.transaction_date | myGlobal }}
          </div>
        </template>
        <template #cell(amount_credit)="{ item }">
          <div>
            <b-badge variant="light-info" style="font-size: 12px">{{
              item.amount_credit
            }}</b-badge>
          </div>
        </template>
        <template #cell(amount_debit)="{ item }">
          <div>
            <b-badge variant="light-success" style="font-size: 12px">{{
              item.amount_debit | formatMoney
            }}</b-badge>
          </div>
        </template>
        <template #bottom-row>
          <b-th>
            <b-badge
              variant="primary"
              class="w-100 text-center"
              style="
                font-size: 14px;
                font-weight: 600;
                padding-bottom: 7px;
                padding-top: 7px;
              "
            >
              SUBTOTAL<br />TOTAL
            </b-badge>
          </b-th>
          <b-th>
            <b-badge
              variant="success"
              class="w-100 text-center text-black"
              style="
                font-size: 14px;
                font-weight: 600;
                padding-bottom: 7px;
                padding-top: 7px;
              "
            >
              {{ subTotalAmount | formatMoney }}
              <br />
              {{ totalAmount | formatMoney }}
            </b-badge>
          </b-th>
          <b-th> </b-th>
        </template>
      </b-table>
    </filter-slot>
    <ImportTransactions
      v-if="showModalImport"
      @close="showModalImport = false"
      @refresh="refresh()"
    />
  </b-modal>
</template>
<script>
import DashboardService from "@/views/ceo/service-dashboard/dashboard.service.js";
import TypeCard from "@/views/commons/card/TypeCard.vue";
import moment from "moment";
import ImportTransactions from "@/views/commons/components/credit-cards-expenses/modals/ImportTransactions.vue";

export default {
  name: "ImportedTransactionsDetailsModal",
  props: {
    importHistoryItems: {
      type: Array,
      required: true,
    },
    sendYear: {
      type: Number,
      required: true,
    },
    sendMonth: {
      type: Number,
      required: true,
    },
    creditCards: {
      type: Array,
      required: true,
    },
    historyInfo: {
      required: true,
    },
    cardSelectedProp: {
      type: Object,
      required: true,
    },
  },
  components: {
    TypeCard,
    ImportTransactions,
  },
  data() {
    return {
      startPage: null,
      toPage: null,
      totalRows: 0,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Concept...",
        model: "",
      },
      filter: [],
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      isBusy: false,
      showModalImport: false,
      rangeSelected: null,
      itemsHistory: [],
      subTotalAmount: 0,
      totalAmount: 0,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      dataYear: null,
      dataMonth: null,
      cpyMonth: null,
      cpyYear: null,
      cardSelected: null,
      fields: [
        {
          key: "transaction_date",
          label: "Transaction date",
          class: "text-center",
          visible: true,
        },
        {
          key: "amount_debit",
          label: "Amount debit",
          class: "text-center",
          visible: true,
        },
        {
          key: "description",
          label: "Concept",
          class: "text-center",
          visible: true,
        },
      ],
      items: [],
    };
  },
  mounted() {
    this.itemsHistory = this.importHistoryItems;
    this.rangeSelected = this.itemsHistory.find(
      (item) => item.id == this.historyInfo.id
    );
    this.cardSelected = this.cardSelectedProp;
    this.initMonthYear();
    this.toggleModal("imported-transactions-details-modal");
  },
  computed: {
    disableNext() {
      const now = moment();
      const sameYear = now.year() === this.dataYear;
      const sameMonth = now.month() + 1 === this.dataMonth;
      return sameYear && sameMonth;
    },
  },
  methods: {
    openImportTransactions() {
      this.showModalImport = true;
    },
    refresh() {
      this.$refs["import-transaction-history-details"].refresh();
    },
    async getImportedTransactions() {
      const params = {
        year: this.dataYear,
        month: this.dataMonth,
        credit_card_id: this.cardSelected.id,
      };
      try {
        this.addPreloader();
        const { data } = await DashboardService.getImportedTransactionsByCard(
          params
        );
        this.itemsHistory = data.data;
        if (this.itemsHistory.length > 0) {
          this.rangeSelected = this.itemsHistory[0];
        } else {
          this.rangeSelected = null;
        }
        await this.refresh();
      } catch (error) {
        console.log(error);
        this.showErrorSwal(error);
        this.removePreloader();
      }
    },
    initMonthYear() {
      this.dataMonth = this.sendMonth;
      this.dataYear = this.sendYear;
    },
    async next() {
      if (this.dataMonth == null) this.dataMonth = this.cpyMonth;
      if (this.disableNext) return;
      if (this.dataMonth < 12) {
        this.dataMonth++;
      } else {
        this.dataMonth = 1;
        this.dataYear++;
      }
      await this.getImportedTransactions();
      await this.refresh();
    },
    async prev() {
      if (this.dataMonth == null) this.dataMonth = this.cpyMonth;
      if (this.dataMonth > 1) {
        this.dataMonth--;
      } else {
        this.dataMonth = 12;
        this.dataYear--;
      }
      await this.getImportedTransactions();
      await this.refresh();
    },
    async getImportedTransactionsDetails() {
      try {
        this.addPreloader();
        if (this.rangeSelected == null) {
          this.items = [];
          this.subTotalAmount = 0;
          this.totalAmount = 0;
          this.startPage = null;
          this.toPage = null;
          this.totalRows = 0;
          return this.items;
        }
        const params = {
          search: this.filterPrincipal.model,
          historyId: this.rangeSelected.id,
          perPage: this.paginate.perPage,
          currentPage: this.paginate.currentPage,
        };
        const { data } = await DashboardService.getImportedTransactionsDetails(
          params
        );
        this.startPage = data.paginator.from;
        this.toPage = data.paginator.to;
        this.totalRows = data.paginator.total;
        this.items = data.paginator.data;
        this.totalAmount = this.items.length ? this.items[0].totalAmount : 0;
        this.subTotalAmount = data.subTotalAmount;
        return this.items;
      } catch (error) {
        console.log(error);
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.b-table-bottom-row th {
  background-color: #ffffff !important;
}
.dark-layout {
  .b-table-bottom-row th {
    background-color: #17171a !important;
  }
}
</style>
