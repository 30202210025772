<template>
  <div
    ref="bCardCalls"
    class="dashboard__card"
    :class="isDarkSkin ? 'dashboard__card--dark' : ''"
  >
    <b-card-text class="mb-0 pb-0">
      <b-row>
        <b-col cols="6">
          <div class="d-flex mb-1">
            <b-avatar rounded="sm" size="4em" class="b_avatar_color">
              <div class="ico_content">
                <b-img :src="imgCalls" style="width: 85%; height: 85%" />
              </div>
            </b-avatar>

            <div class="ml-1 title_content">
              <span :style="titleCard()">Calls per hour</span>
            </div>
          </div>
        </b-col>

        <b-col cols="3">
          <div>
            <b-form-select
              v-model="selectCall4hour"
              style="margin-top: 4px; width: 100%"
              :class="isDarkSkin ? 'select_border_dark' : ''"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>
                  Choose type
                </b-form-select-option>
                <b-form-select-option
                  v-for="(item, index) in optionsCall4Hour"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </b-form-select-option>
              </template>
            </b-form-select>
          </div>
        </b-col>
        <b-col cols="3">
          <div>
            <b-form-select
              v-model="programSelected"
              style="margin-top: 4px; width: 100%"
              :class="isDarkSkin ? 'select_border_dark' : ''"
              @input="getModule()"
            >
              <template #first>
                <b-form-select-option
                  v-for="(item, index) in programs"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.value }}
                </b-form-select-option>
              </template>
            </b-form-select>
          </div>
        </b-col>

        <b-col cols="12" class="p-0">
          <bar-chart
            ref="barChartCallByHour"
            :module-id="moduleId == 16 ? currentModuleId : moduleId"
            :type="selectCall4hour"
            :day="dayCall4Hour"
            :lineId="currentLineId"
          />
        </b-col>
      </b-row>
    </b-card-text>
  </div>
</template>
<script>
import BarChart from "@/views/commons/components/ring-central/dashboard/components/BarChart.vue";
import DashboardServices from "@/views/ceo/service-dashboard/dashboard.service.js";
export default {
  components: {
    BarChart,
  },
  props: {
    selectedMonth: {
      type: Number,
      required: true,
    },
    selectedYear: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      imgCalls: require("@/assets/images/icons/dashboard-ceo/logo-call.svg"),
      selectCall4hour: 1,
      dayCall4Hour: {
        endDate: null,
        startDate: null,
      },
      currentLineId: 0,
      moduleId: 0,
      programs: [],
      programSelected: null,
      optionsCall4Hour: [
        { id: 1, name: "Outbound" },
        { id: 2, name: "Inbound" },
      ],
    };
  },
  watch: {
    selectedMonth: function () {
      this.generateDate();
    },
    selectedYear: function () {
      this.generateDate();
    },
    programSelected: function () {
      if (this.programSelected == null) {
        this.moduleId = 0;
      }
    },
    isDarkSkin() {
      this.checkScreenSize();
    },
  },
  async created() {
    await this.getPrograms();
    this.generateDate();
  },
  mounted() {
    // responsive font
    window.addEventListener("resize", this.checkScreenSize);
    this.checkScreenSize();
    //this.responsiveChartLabel();
  },
  methods: {
    generateDate() {
      let startDate = new Date(this.selectedYear, this.selectedMonth - 1, 1);
      let endDate = new Date(this.selectedYear, this.selectedMonth, 0);
      this.dayCall4Hour = {
        startDate: startDate,
        endDate: endDate,
      };
    },
    async getPrograms() {
      const { data } = await DashboardServices.programs();
      let defaultOption = {
        id: null,
        name: "All programs",
        value: "All programs",
      };
      data.unshift(defaultOption);
      this.programs = data.filter((item) => item.id != 9 && item.id != 6);
    },
    async getModule() {
      if (this.programSelected != null) {
        const params = {
          programId: this.programSelected,
        };
        const { data } = await DashboardServices.convertProgram(params);
        this.moduleId = data;
      } else {
        this.moduleID = 0;
      }
    },
    titleCard() {
      let size = "19px";
      if (this.sizeScreen >= 407 && this.sizeScreen < 468) {
        size = "14px";
      } else if (this.sizeScreen >= 274 && this.sizeScreen < 407) {
        size = "13px";
      } else if (this.sizeScreen < 274) {
        size = "16px";
      }
      return `display:block;font-size:${size};font-weight:600;width:100%`;
    },
    checkScreenSize() {
      setTimeout(() => {
        const componentClientStatus = this.$refs.bCardCalls;
        this.sizeScreen =
          componentClientStatus.clientWidth > 0 &&
          componentClientStatus.clientWidth
            ? componentClientStatus.clientWidth
            : 0;
      }, 500);
      //this.responsiveChartLabel();
    },
    responsiveChartLabel() {
      setTimeout(() => {
        const chartClient = this.$refs.chart_project_delays;
        const svg_item = chartClient.$el.getElementsByTagName("svg")[0];
        if (this.sizeScreen >= 345 && this.sizeScreen < 700) {
          svg_item.setAttribute("height", "550");
          svg_item
            .getElementsByTagName("foreignObject")[0]
            .setAttribute("height", "300");
        } else if (this.sizeScreen < 344) {
          svg_item.setAttribute("height", "550");
          svg_item
            .getElementsByTagName("foreignObject")[0]
            .setAttribute("height", "300");
        }
      }, 500);
    },
  },
};
</script>
<style lang="scss" scoped>
$color-primary: #5f5f5f;
$color-secondary: #ffffff;
$bg-card-white: #ffffff;
$bg-card-dark: #111113;
$box-shadow-white: #00000026;

.dashboard__card {
  margin-top: 5px;
  box-shadow: 0px 0px 15px 0px $box-shadow-white;
  border-radius: 15px;
  margin-bottom: 27px;
  padding: 18px;
  background-color: $bg-card-white;
  // background-color:RED;
  height: 100%;

  &--dark {
    background-color: $bg-card-dark;
    box-shadow: 0px 0px 22.935px 0px rgba(255, 249, 249, 0.1);
  }
}

.select_border_dark {
  color: #1654cc !important;
  border-color: #1654cc !important;
}
.title_content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.b_avatar_color {
  background: rgba(167, 197, 255, 1) !important;
}
</style>

<style>
.echarts {
  width: 100% !important;
  margin: auto;
}
</style>
