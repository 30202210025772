export default [
  {
    key: 'lead_full_name',
    label: 'Client',
    class: 'text-center',
    visible: true,
  },
  {
    key: 'account_name',
    label: 'Account',
    class: 'text-center',
    visible: true,
  },
  {
    key: 'bureau',
    label: 'Bureau',
    thStyle: { width: '20%' },
    class: 'text-center',
    visible: true,
  },
  {
    key: 'details',
    label: 'details',
    class: 'text-center',
    thStyle: { width: '30%' },
    visible: true,
  },
  {
    key: 'created_by',
    label: 'Disputed By',
    class: 'text-center',
    visible: true,
  },
];
