<template>
  <div
    class="messenger-message-read"
    ref="messageRead"
    @click.stop="toggleVisibility()"
  >
    <b-avatar
      v-for="(user, index) in usersToShow"
      :key="index"
      size="18"
      variant="light-secondary"
      :src="getImageUser(user.id)"
      icon="person-fill"
      class="cursor-pointer"
    />
    <b-avatar
      v-if="usersTotal > 0"
      variant="light-secondary"
      size="18"
      :text="`+${usersTotal}`"
      class="cursor-pointer"
    />
    <div class="users-details" v-if="showUsersDetails">
      <perfect-scrollbar>
        <div class="users">
          <div v-for="(user, index) in users" :key="index" class="user-item">
            <b-avatar
              size="40"
              variant="light-secondary"
              :src="getImageUser(user.id)"
              icon="person-fill"
            />
            <span class="user-name">
              {{ user.name }}
              <small class="ml-1">{{ formatDateTime(user.moment) }}</small>
            </span>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import PerfectScrollbar from "vue-perfect-scrollbar";
import moment from "moment";

export default {
  name: "UsersMessageRead",
  components: {
    PerfectScrollbar,
  },
  props: {
    users: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      showUsersDetails: false,
    };
  },
  computed: {
    ...mapGetters({
      G_USERS_IMAGES: "SmsInboxStore/G_USERS_IMAGES",
    }),
    usersToShow() {
      return this.users.slice(0, 2);
    },
    usersTotal() {
      return this.users.length - 2;
    },
  },
  created() {},
  mounted() {},
  methods: {
    getImageUser(userId) {
      const user = this.G_USERS_IMAGES.find((user) => user.id == userId);
      if (user) {
        return user.image_thumb;
      }
      return null;
    },
    toggleVisibility() {
      this.showUsersDetails = !this.showUsersDetails;
      if (this.showUsersDetails) {
        document.addEventListener("click", this.handleClickOutside);
      } else {
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    handleClickOutside(event) {
      const container = this.$refs.messageRead;
      if (container && !container.contains(event.target)) {
        this.hide();
      }
    },
    hide() {
      this.showUsersDetails = false;
      document.removeEventListener("click", this.handleClickOutside);
    },
    formatDateTime(date) {
      const TIME_ZONE = "America/Los_Angeles";
      const now = moment().tz(TIME_ZONE);
      const messageDate = moment(date).tz(TIME_ZONE);
      const diff = now.diff(messageDate, "minutes");
      if (diff < 60) {
        return `${diff}m ago`;
      } else if (diff < 1440) {
        const hours = Math.floor(diff / 60);
        const minutes = diff % 60;
        return `${hours}h ${minutes}m ago`;
      } else {
        return messageDate.format("DD/MM/YYYY hh:mm A");
      }
    },
  },
  beforeDestroy() {
    this.hide();
  },
};
</script>
<style lang="scss">
.messenger-message-read {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  cursor: pointer;
  .b-avatar-text {
    span {
      font-size: 10px;
    }
  }
  .users-details {
    position: absolute;
    height: 15rem;
    background-color: #fff;
    width: 20rem;
    padding: 1rem;
    box-shadow: 0px 3px 8px 0px #dbdbdb;
    z-index: 999;
    left: 0;
    bottom: 1.4rem;
    border-radius: 0.5rem;
    .ps-container {
      height: 100%;
      position: relative;
      .ps__thumb-y {
        background-color: var(--primary-color);
      }
    }
    .users {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      .user-item {
        display: flex;
        align-items: center;
        gap: 1rem;
        .user-name {
          font-family: "Montserrat", sans-serif;
          font-size: 1.04rem;
          font-weight: 500;
        }
      }
    }
  }
}
.dark-layout {
  .messenger-message-read {
    .users-details {
      background-color: #17171a;
      box-shadow: 0px 3px 8px 0px #0e0e0e;
    }
  }
}
</style>