<template>
  <b-container class="mt-1">
    <h4>Customer</h4>
    <!-- <b-row class="mt-2">
      <b-col cols="12">
        <validation-provider rules="max:255" v-slot="{ errors }">
          <b-form-group label="Customer Name">
            <div class="paddingT">
              <b-form-input
                v-model="userData.customer_name"
                autofocus
                maxlength="255"
                :state="errors[0] ? false : null"
              >
              </b-form-input>
            </div>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row> -->
    <validation-observer ref="customerCreditorObserver">
      <b-row class="mt-2 row-gap">
        <b-col class="mr-0 pr-0 col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <validation-provider rules="mobile" v-slot="{ errors }">
            <div class="d-flex justify-content-between">
              <label>Phone</label>
              <b-button
                variant="outline"
                class="p-0 paddingB"
                :disabled="isDisabledPhoneCustoner || onlyView"
                @click="addInput(4)"
              >
                <feather-icon
                  icon="PlusSquareIcon"
                  size="18"
                  class="text-primary"
                />
              </b-button>
            </div>
            <template>
              <b-form-input
                id="phone"
                v-model="userData.customerPhone[0].phone"
                name="phone"
                autofocus
                trim
                :disabled="onlyView"
                :state="errors[0] ? false : null"
                v-mask="'(###) ###-####'"
              />
            </template>
          </validation-provider>
        </b-col>

        <template v-if="userData.customerPhone.length > 1">
          <template v-for="(item, index) in userData.customerPhone">
            <b-col
              class="mr-0 pr-0 col-sm-12 col-md-6 col-lg-4 col-xl-3"
              :key="index"
              v-if="index > 0"
            >
              <validation-provider rules="mobile" v-slot="{ errors }">
                <div class="d-flex justify-content-end">
                  <b-button
                    variant="outline"
                    class="p-0 paddingB"
                    :disabled="onlyView"
                    @click="deleteInput(4, index)"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="18"
                      class="text-danger"
                    />
                  </b-button>
                </div>
                <template>
                  <b-form-input
                    v-model="item.phone"
                    autofocus
                    :disabled="onlyView"
                    :state="errors[0] ? false : null"
                    v-mask="'(###) ###-####'"
                  />
                </template>
              </validation-provider>
            </b-col>
          </template>
        </template>
      </b-row>

      <b-row class="mt-2 row-gap">
        <b-col class="mr-0 pr-0 col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <validation-provider rules="mobile" v-slot="{ errors }">
            <div class="d-flex justify-content-between">
              <label>Fax</label>
              <b-button
                variant="outline"
                class="p-0 paddingB"
                :disabled="isDisabledFaxCustomer || onlyView"
                @click="addInput(6)"
              >
                <feather-icon
                  icon="PlusSquareIcon"
                  size="18"
                  class="text-primary"
                />
              </b-button>
            </div>
            <b-form-input
              id="fax"
              v-model="userData.customerFax[0].fax"
              name="fax"
              autofocus
              trim
              :disabled="onlyView"
              :state="errors[0] ? false : null"
              v-mask="'(###) ###-####'"
            />
          </validation-provider>
        </b-col>
        <template v-if="userData.customerFax.length > 0">
          <template v-for="(item, index) in userData.customerFax">
            <b-col
              class="mr-0 pr-0 col-sm-12 col-md-6 col-lg-4 col-xl-3"
              :key="index"
              v-if="index > 0"
            >
              <validation-provider rules="mobile" v-slot="{ errors }">
                <div class="d-flex justify-content-end">
                  <b-button
                    variant="outline"
                    class="p-0 m-0"
                    :disabled="onlyView"
                    @click="deleteInput(6, index)"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="18"
                      class="text-danger"
                    />
                  </b-button>
                </div>
                <template>
                  <b-form-input
                    id="fax1"
                    v-model="userData.customerFax[index].fax"
                    name="fax1"
                    autofocus
                    trim
                    :disabled="onlyView"
                    :state="errors[0] ? false : null"
                    v-mask="'(###) ###-####'"
                  />
                </template>
              </validation-provider>
            </b-col>
          </template>
        </template>
      </b-row>

      <b-row class="mt-2 row-gap">
        <b-col class="mr-0 pr-0" cols="12" md="6">
          <validation-provider rules="email|max:255" v-slot="{ errors }">
            <div class="d-flex justify-content-between">
              <label>Email</label>
              <b-button
                variant="outline"
                class="p-0 paddingB"
                :disabled="isDisabledEmailCustomer || onlyView"
                @click="addInput(5)"
              >
                <feather-icon
                  icon="PlusSquareIcon"
                  size="18"
                  class="text-primary"
                />
              </b-button>
            </div>
            <b-form-input
              id="email"
              v-model="userData.customerEmail[0].email"
              type="email"
              name="email"
              autofocus
              :disabled="onlyView"
              :state="errors[0] ? false : null"
              trim
              maxlength="255"
            />
          </validation-provider>
        </b-col>

        <template v-if="userData.customerEmail.length > 0">
          <template v-for="(item, index) in userData.customerEmail">
            <b-col
              class="mr-0 pr-0"
              cols="12" md="6" 
              :key="index"
              v-if="index > 0"
            >
              <validation-provider rules="email|max:255" v-slot="{ errors }">
                <div class="d-flex justify-content-end">
                  <b-button
                    variant="outline"
                    class="p-0 paddingB"
                    :disabled="onlyView"
                    @click="deleteInput(5, index)"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="18"
                      class="text-danger"
                    />
                  </b-button>
                </div>
                <template>
                  <b-form-input
                    id="email1"
                    v-model="userData.customerEmail[index].email"
                    name="email1"
                    autofocus
                    trim
                    :disabled="onlyView"
                    type="email"
                    :state="errors[0] ? false : null"
                    maxlength="255"
                  />
                </template>
              </validation-provider>
            </b-col>
          </template>
        </template>
      </b-row>

      <b-row class="mt-2">
        <b-col cols="12" class="pr-0 mr-0">
          <b-form-group label="Website" label-for="website">
            <b-form-input
              id="website"
              v-model="userData.website"
              name="website"
              :disabled="onlyView"
              autofocus
              trim
            />
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
  </b-container>
</template>
  <script>
import { VueAutosuggest } from "vue-autosuggest";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { ValidationObserver } from "vee-validate";
import CreditorsService from "../../service/creditors.service";
import { required, email } from "@validations";
import { mapGetters } from "vuex";
export default {
  name: "CustomerCreditorEdit",
  props: {
    userData: {
      type: Object,
      required: true,
    },
    onlyView: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ValidationObserver,
    VueAutosuggest,
    VueGoogleAutocomplete,
  },
  data() {
    return {
      required,
      email,
      dataCreditor: [],
      filteredOptions: [],
      isDisabledPhone: false,
      isDisabledFax: false,
      isDisabledEmail: false,
      isDisabledPhoneCustoner: false,
      showPhone: false,
      showFax: false,
      showEmail: false,
      showPhoneCustoner: false,
      dataPhone: [],
      showEmailCustomer: false,
      showFaxCustomer: false,
      dataFax: [],
      dataEmail: [],
      options: [
        { text: "Yes", value: "1" },
        { text: "No", value: "2" },
      ],
      isDisabledFaxCustomer: false,
      isDisabledEmailCustomer: false,
    };
  },
  mounted() {},
  methods: {
    addInput(data) {
      switch (data) {
        case 1:
          this.showPhone = true;
          this.userData.recovery.push({ phone: "" });
          if (this.userData.recovery.length == 5) {
            this.isDisabledPhone = true;
          }
          break;
        case 2:
          this.showFax = true;
          this.userData.fax.push({ fax: "" });
          if (this.userData.fax.length == 5) {
            this.isDisabledFax = true;
          }
          break;
        case 3:
          this.showEmail = true;
          this.userData.email.push({ email: "" });
          if (this.userData.email.length == 5) {
            this.isDisabledEmail = true;
          }
          break;
        case 4:
          this.showPhoneCustoner = true;
          this.userData.customerPhone.push({ phone: "" });
          if (this.userData.customerPhone.length == 5) {
            this.isDisabledPhoneCustoner = true;
          }
          break;
        case 5:
          this.showEmailCustomer = true;
          this.userData.customerEmail.push({});
          if (this.userData.customerEmail.length == 5) {
            this.isDisabledEmailCustomer = true;
          }
          break;
        case 6:
          this.showFaxCustomer = true;
          this.userData.customerFax.push({});
          if (this.userData.customerFax.length == 5) {
            this.isDisabledFaxCustomer = true;
          }
          break;
        default:
          break;
      }
    },
    deleteInput(data, index) {
      switch (data) {
        case 1:
          this.isDisabledPhone = false;
          for (var i = 0; i < this.userData.recovery.length; i++) {
            if (i == index) {
              this.userData.recovery.splice(i, 1);
            }
          }
          break;
        case 2:
          this.isDisabledFax = false;
          for (var i = 0; i < this.userData.fax.length; i++) {
            if (i == index) {
              this.userData.fax.splice(i, 1);
            }
          }
          break;
        case 3:
          this.isDisabledEmail = false;
          for (var i = 0; i < this.userData.email.length; i++) {
            if (i == index) {
              this.userData.email.splice(i, 1);
            }
          }
          break;
        case 4:
          this.isDisabledPhoneCustoner = false;
          for (var i = 0; i < this.userData.customerPhone.length; i++) {
            if (i == index) {
              this.userData.customerPhone.splice(i, 1);
            }
          }
          break;
        case 5:
          this.isDisabledEmailCustomer = false;
          for (var i = 0; i < this.userData.customerEmail.length; i++) {
            if (i == index) {
              this.userData.customerEmail.splice(i, 1);
            }
          }
          break;

        case 6:
          this.isDisabledFaxCustomer = false;
          for (var i = 0; i < this.userData.customerFax.length; i++) {
            if (i == index) {
              this.userData.customerFax.splice(i, 1);
            }
          }
          break;

        default:
          break;
      }
    },
    getAddressData(data, placeResultData) {
      this.$refs.refMailingAddress.$el.value =
        placeResultData.formatted_address;
      this.userData.address = placeResultData.formatted_address;
    },
    getAddressData2(data, placeResultData, id) {
      // this.userData.address = placeResultData.formatted_address;
      this.$refs.address.$el.value = `${data.street_number || ""} ${
        data.route
      }`;
      this.userData.customerAddress = `${
        data.street_number ? data.street_number : ""
      } ${data.route}`;
      this.userData.customerCity = data.locality;
      this.userData.customerZipcode = data.postal_code;
      this.userData.customerState = this.G_STATUS_EEUU.find(
        (e) => e.slug === data.administrative_area_level_1
      ).id;
      this.userData.customerCountry = this.G_COUNTRIES.find(
        (e) => e.name.toLowerCase() == "estados unidos"
      ).id;
    },
    async validate(){
      return await this.$refs.customerCreditorObserver.validate();
    }
  },
  computed: {
    ...mapGetters({
      G_STATUS_EEUU: "DebtSolutionCreditors/G_STATUS_EEUU",
      G_COUNTRIES: "DebtSolutionCreditors/G_COUNTRIES",
    }),
  },
};
</script>

<style lang="scss" scoped>
.paddingB {
  padding-bottom: 4px !important;
}
.paddingT {
  padding-top: 4px !important;
}
.ppaddingTP {
  padding-top: 23px !important;
}
.paddingIconoX {
  padding-top: 8px !important;
  padding-left: 3px !important;
}
.widthInput {
  width: 96%;
}
.widthInputPhone {
  width: 80%;
}
.row-gap {
  row-gap: 10px !important;
}
.col {
  flex-basis: unset !important;
}
</style>
  