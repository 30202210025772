<template>
  <div>
    <b-modal
      ref="freezed-amount-details"
      modal-class="custom-modal-amg"
      title-class="h3 text-white"
      :title="title"
      size="xmd"
      header-bg-variant="transparent"
      header-class="p-0"
      @hidden="closeModal()"
      centered
      hide-footer
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <div class="d-flex align-items-center">
            <span>{{ title }}</span>
          </div>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="closeModal()"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>
      <b-row class="client-info">
        <b-col cols="6" class="p-0">
          <div
            class="mr-1"
            :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
          >
            <p>Bank Name | {{ bank_account.bank_name }}</p>
          </div>
        </b-col>
        <b-col cols="6" class="p-0">
          <div
            class="ml-1"
            :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
          >
            <p>Account Name | {{ bank_account.account_name }}</p>
          </div>
        </b-col>
      </b-row>
      <div>
        <b-table
          sticky-header="70vh"
          show-empty
          :items="items"
          :fields="fields"
          class="position-relative table-new-customization"
        >
          <template #cell(freeze)="{ item }">
            <span class="text-primary">{{ item.freeze | formatMoney }}</span>
          </template>
          <template #cell(approved_at)="{ item }">
            {{ item.approved_at | myGlobalDay }}
          </template>
          <template #cell(payment_date)="{ item }">
            <span class="d-block">{{ item.payment_user_name }}</span>
            <span class="d-block">{{ item.payment_date | myGlobalDay }}</span>
          </template>
          <template #cell(user_name)="{ item }">
            <span class="d-block">{{ item.user_name }}</span>
            <span class="d-block">{{ item.created_at | myGlobalDay }}</span>
          </template>
          <template #cell(creditor)="{ item }">
            <span class="d-block text-warning font-weight-bolder">{{
              item.creditor_name
            }}</span>
            <span class="d-block"
              >#{{ item.account_number || item.account_number2 }}</span
            >
          </template>
          <template #cell(lead_name)="{ item }">
            <div>
              <router-link
                class
                :to="
                  routerDashboardRedirectioner(
                    item.program_id,
                    item.client_account_id
                  )
                "
                target="_blank"
                >{{ item.lead_name }}
              </router-link>
              <status-account :account="{ ...item }" />
              <span>{{ item.account }}</span>
            </div>
          </template>
        </b-table>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CheckService from "@/views/commons/components/check-expenses/services/checks.services.js";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
export default {
  name: "FreezedAmountDetailsModal",
  props: {
    bank_account: {
      type: Object,
      required: true,
    },
  },
  components: {
    StatusAccount,
  },
  data() {
    return {
      items: [],
      fields: [
        {
          key: "lead_name",
          label: "Client",
          visible: true,
        },
        {
          key: "creditor",
          label: "Creditor",
          visible: true,
        },
        {
          key: "freeze",
          label: "Freezed Amount",
          visible: true,
          class: "text-center",
        },
        {
          key: "user_name",
          label: "Offer Creator",
          visible: true,
        },
        {
          key: "approved_at",
          label: "Approved At",
          visible: true,
        },
        {
          key: "payment_date",
          label: "Payment Date",
          visible: true,
        },
      ],
    };
  },
  computed: {
    title() {
      return `Freezed Amount Details`;
    },
    ...mapGetters({}),
  },
  watch: {},
  async mounted() {
    await this.myProvider();
    this.toggleModal("freezed-amount-details");
  },
  async created() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async myProvider() {
      try {
        const params = {
          bank_check_account_id: this.bank_account.bank_check_account_id,
        };
        this.items = await CheckService.getFreezeTracking(params);
        return this.items;
      } catch (e) {
        this.showToast("danger", "top-right", "Error", "XIcon", e);
        return [];
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";
</style>
