import { render, staticRenderFns } from "./FreezedAmountDetailsModal.vue?vue&type=template&id=6cdc8339&scoped=true"
import script from "./FreezedAmountDetailsModal.vue?vue&type=script&lang=js"
export * from "./FreezedAmountDetailsModal.vue?vue&type=script&lang=js"
import style0 from "./FreezedAmountDetailsModal.vue?vue&type=style&index=0&id=6cdc8339&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cdc8339",
  null
  
)

export default component.exports