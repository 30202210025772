
import { ref, computed } from "@vue/composition-api";
import CreditorsService from "@/views/debt-solution/views/creditors/service/creditors.service.js";


const useMoreInformationCreditorEdit = (ctx) => {
    const types = ref([]);
    const send_types = ref([]);
    const payment_types = [
        {
            id: 1,
            value: "E-CHECK (CHECKING ACCOUNT)",
        },
        {
            id: 2,
            value: "CHECK (OVERNIGHT)",
        },
        {
            id: 3,
            value: "CASHIER CHECK",
        },
        {
            id: 4,
            value: "MONEY ORDER",
        },
    ];

    const G_STATUS_EEUU = computed(
        () => ctx.$store.getters["DebtSolutionCreditors/G_STATUS_EEUU"]
    );

    const G_COUNTRIES = computed(
        () => ctx.$store.getters["DebtSolutionCreditors/G_COUNTRIES"]
    );

    const getSendTypes = async () => {
        try {
            const { data } = await CreditorsService.getSendTypes();
            send_types.value = data;
        } catch (error) {
            ctx.showErrorSwal(error);
        }
    };

    const allTypes = async () => {
        try {
            const route = "/sales-made/debt-solution/get-debts-types";
            const { data } = await amgApi.get(route);
            // id 6, 7 and  8 remove
            types.value = data.filter(
                (el) => el.id != 6 && el.id != 7 && el.id != 8
            );
        } catch (error) {
            ctx.showErrorSwal(error);
        }
    };

    allTypes();
    getSendTypes();

    return {
        types,
        send_types,
        payment_types,
        G_STATUS_EEUU,
        G_COUNTRIES,
    };
};

export default useMoreInformationCreditorEdit;