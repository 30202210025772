<template>
  <b-modal
    ref="modalName"
    modal-class="custom-modal-amg"
    title-class="h3 text-white"
    title="HISTORY OF TRANSACTION IMPORTS"
    size="xmd"
    @hidden="closeModal()"
    hide-footer
    header-class="p-0"
    centered
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <div>HISTORY OF TRANSACTION IMPORTS</div>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="closeModal()"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>
    <b-table
      :items="items"
      :fields="fields"
      sticky-header="50vh"
      show-empty
      class="position-relative table-new-customization"
    >
      <template #cell(cardinfo)="{ item }">
        <div class="row">
          <div class="col-4 p-0 justify-content-end d-flex">
            <type-card :type_card="item.type_card" />
          </div>
          <div
            class="col-8 p-0 d-flex justify-content-center align-items-center"
          >
            <strong>XXXX-{{ item.cardnumber.slice(-4) }}</strong>
          </div>
        </div>
      </template>
      <template #cell(date_ranges)="{ item }">
        <div>
          <span
            >from
            <strong>{{
              item.date_ranges.split(" - ")[0] | myGlobal
            }}</strong></span
          >
          <br />
          <span>
            to
            <strong>{{
              item.date_ranges.split(" - ")[1] | myGlobal
            }}</strong></span
          >
        </div>
      </template>
      <template #cell(total_transactions)="{ item }">
        <div>
          <b-badge
            @click="openDetail(item)"
            variant="primary"
            style="font-size: 15px"
            class="cursor-pointer"
            >{{ item.total_transactions }}</b-badge
          >
        </div>
      </template>
      <template #cell(total_amount)="{ item }">
        <div>
          <b-badge
            @click="openDetail(item)"
            variant="light-success"
            style="font-size: 15px"
            class="cursor-pointer"
            >{{ item.total_amount  | formatMoney }}</b-badge
          >
        </div>
      </template>
      <template #cell(created_at)="{ item }">
        <div>
          {{ item.created_at | myGlobalWithHour }}
        </div>
      </template>
    </b-table>
    <ImportHistoryDetail
      v-if="showDetail"
      :historyInfo="logInfo"
      @close="showDetail = false"
    />
  </b-modal>
</template>
<script>
import Fields from "@/views/commons/components/credit-cards-expenses/data/history.imports.fields.js";
import DashboardService from "@/views/ceo/service-dashboard/dashboard.service.js";
import TypeCard from "@/views/commons/card/TypeCard.vue";
import ImportHistoryDetail from "@/views/commons/components/credit-cards-expenses/modals/ImportHistoryDetail.vue";

export default {
  props: {
    dataRow: {
      type: Object,
      required: true,
    },
    modalOrigin: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TypeCard,
    ImportHistoryDetail,
  },
  data() {
    return {
      fields: Fields,
      items: [],
      logInfo: null,
      showDetail: false,
    };
  },
  mounted() {
    this.toggleModal("modalName");
  },
  async created() {
    await this.getHistory();
  },
  methods: {
    async getHistory() {
      const params = {
        year: null,
        month: null,
        credit_card_id: !this.modalOrigin ? this.dataRow.id : null,
      };
      try {
        this.addPreloader();
        const { data } = await DashboardService.getHistoryImports(params);
        this.items = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    closeModal() {
      this.$emit("close");
    },
    openDetail(item) {
      this.logInfo = item;
      this.showDetail = true;
    },
  },
};
</script>
