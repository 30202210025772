<template>
  <b-modal
    v-model="showViewAutomaticNote"
    @hidden="$emit('hidden')"
    size="xmd"
    title="NOTE GENERATED"
    modal-class="modal-primary"
    hide-footer
  >
    <div v-if="note != null" class="modal-note">
      <div class="d-flex justify-content-between header">
        <span class="font-weight-bold title">Summary </span>
        <div
          class="font-weight-bold sentiment m-05"
          :class="{
            'sentiment--positive': note.sentiment === 'POSITIVE',
            'sentiment--negative': note.sentiment === 'NEGATIVE',
            'sentiment--neutral': note.sentiment === 'NEUTRAL',
            'sentiment--mixed': note.sentiment === 'MIXED',
          }"
        >
          <span class="p-1"> {{ note.sentiment }} </span>
        </div>
      </div>

      <div v-html="note.content" class="mt-1" v-if="note.content != null"></div>
      <div v-html="note.text" class="mt-1" v-else></div>
    </div>
  </b-modal>
</template>


<script>
import CallNotesService from "@/views/commons/components/ring-central/notes/service/notes.service.js";

export default {
  components: {},
  props: {
    noteId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      showViewAutomaticNote: true,
      note: {},
    };
  },
  async mounted() {
    await this.getAutomaticNote();
  },
  methods: {
    async getAutomaticNote() {
      try {
        this.addPreloader();
        let params = {
          note_id: this.noteId,
        };
        const { data } = await CallNotesService.getAutomaticNote(params);
        this.note = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-note {
  .m-05 {
    margin: 0.5rem;
  }
  .title {
    font-size: 1.5rem;
    font-weight: 800;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
  }
  .header {
    border-bottom: 1px solid #dee2e6;
  }
  .sentiment {
    font-size: 1.2rem;
    border-radius: 5px;
    color: white;
    font-weight: 800 !important;
    font-family: "Roboto", sans-serif;

    &--positive {
      background-color: #28a745;
    }
    &--negative {
      background-color: #dc3545;
    }
    &--neutral {
      background-color: #007bff;
    }
    &--mixed {
      background-color: #6f42c1;
    }
  }
}
</style>