<template>
  <div>
    <!-- Tickets -->
    <div class="d-flex mb-1 align-items-center">
      <b-avatar rounded="sm" size="4em" class="b_avatar_color">
        <div class="ico_content">
          <b-img
            src="/assets/images/logos/paragon.png"
            style="width: 85%; height: 85%"
          />
        </div>
      </b-avatar>
      <div class="ml-1 title_content">
        <span :style="titleCard()"> Paragon Tickets </span>
      </div>
    </div>
    <div class="mt-3">
      <b-row class="m-0 p-0">
        <b-col cols="4" v-for="ticket in tickets" :key="ticket.id">
          <div class="ticket" @click="openTicketModal(ticket.id)">
            <b-card
              :class="isDarkSkin ? 'bg_dark_card' : 'bg_light_card'"
              style="height: 100px"
              body-class="text-center"
            >
              <b-card-text class="title-ticket-card">
                Total {{ ticket.name }}
              </b-card-text>
              <b-card-text class="counter-ticket-card text-warning">
                {{ ticket.value }}
              </b-card-text>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </div>
    <!-- End Tickets -->
    <TicketSectionModal
      :month="month"
      :year="year"
      :type="typeModal"
      v-if="showTicketModal"
      @close="showTicketModal = false"
    />
  </div>
</template>

<script>
import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service.js";
import TicketSectionModal from "@/views/ceo/dashboard/modals/TicketSectionModal.vue";
export default {
  components: {
    TicketSectionModal,
  },
  props: {
    month: {
      type: Number,
      required: true,
    },
    year: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      tickets: [
        { id: 1, name: "Pending", value: 0 },
        { id: 2, name: "Completed", value: 0 },
        { id: 3, name: "Annuled", value: 0 },
      ],
      typeModal: null,
      showTicketModal: false,
    };
  },
  mounted() {
    this.getTotalTickets();
  },
  methods: {
    openTicketModal(type) {
      this.typeModal = type;
      this.showTicketModal = true;
    },
    titleCard() {
      let size = "18px";
      if (this.sizeScreen >= 407 && this.sizeScreen < 468) {
        size = "16px";
      } else if (this.sizeScreen >= 274 && this.sizeScreen < 407) {
        size = "13px";
      } else if (this.sizeScreen < 274) {
        size = "16px";
      }
      return `display:block;font-size:${size};font-weight:600`;
    },
    async getTotalTickets() {
      this.tickets = await DashboardServive.getTotalsTicket({
        month: this.month,
        year: this.year,
      });
    },
  },
  watch: {
    month() {
      this.getTotalTickets();
    },
    year() {
      this.getTotalTickets();
    },
  },
};
</script>

<style lang="scss" scoped>
.title-ticket-card {
  display: block;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
}
.counter-ticket-card {
  display: block;
  font-size: 22px;
  font-weight: 600;
}
.bg_dark_card {
  cursor: pointer;
  background: #111113 !important;
  color: white;
  &:hover {
    background: #313131 !important;
  }
}
.bg_light_card {
  cursor: pointer;
  background: white !important;
  box-shadow: 0px 0px 15px 0px #00000026 !important;
  color: rgb(39, 39, 39);
  &:hover {
    background: #f5f5f5 !important;
  }
}
.b_avatar_color {
  background: rgba(167, 197, 255, 1) !important;
}
.ico_content {
  border: solid 1px #eee;
  width: 80%;
  height: 83%;
  border-radius: 50%;
  background: #fff;
  text-align: center;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>