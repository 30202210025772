export default [
  {
    key: "client",
    label: "Client",
    sortable: true,
    thClass: "th-class-title",
    tdClass: "text-left",
    visible: true,
  },
  {
    key: "authorize_amount",
    label: "Authorize",
    sortable: true,
    thClass: "text-right mr-1",
    tdClass: "text-right mr-1",
    visible: true,
  },
  {
    key: "soft_amount",
    label: "AMG",
    sortable: true,
    thClass: "text-right mr-1",
    tdClass: "text-right mr-1",
    visible: true,
  },
  {
    key: "merchant_name",
    label: "Merchant",
    sortable: true,
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    key: "card_number",
    label: "Card Number",
    sortable: true,
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    key: "transaction_id",
    label: "Transaction ID",
    sortable: true,
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    key: "transaction_type_name",
    label: "Type",
    sortable: true,
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    key: "settlement_date_authorize",
    label: "Settlement Date",
    sortable: true,
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    key: "authorize_status",
    label: "Authorize Status",
    sortable: true,
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    key: "soft_status",
    label: "AMG status",
    sortable: true,
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    key: "actions",
    label: "Actions",
    sortable: false,
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    key: "chat",
    label: "Ticket",
    sortable: false,
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
];
