export default [
    {
        key: "department",
        label: "Department",
        thClass: "text-center",
        tdClass: "text-center",
        visible: true
    },
    {
        key: "transactions",
        label: "Transactions",
        thClass: "text-center",
        tdClass: "text-center",
        visible: true
    },
    {
        key: "amount",
        label: "Amount",
        thClass: "text-center",
        tdClass: "text-center",
        visible: true
    }
]