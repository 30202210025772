<template>
  <div
    :class="'border-top-primary border-3 box-shadow-3 rounded-bottom border-table-radius pb-1'"
  >
    <filter-slot
      :filter="[]"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refTableExpensesDetail'].refresh()"
      class="filter-slot-new-customization"
      :top-paginate="false"
    >
      <template #table>
        <b-table
          ref="refTableExpensesDetail"
          class="blue-scrollbar position-relative table-new-customization"
          no-border-collapse
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="searchNewEmployee"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #head(full_name_employees)="data">
            <div class="text-left">
              {{ data.label }}
            </div>
          </template>
          <template #head(number)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(nationality)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(created_at)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>

          <template #cell(full_name_employees)="data">
            <div class="text-left" style="width: 250px !important">
              <feather-icon icon="UserIcon" class="text-warning" />
              {{ data.item.full_name_employees }}
            </div>
          </template>
          <template #cell(number)="data">
            <div class="align-middle text-center">
              {{ data.item.number }}
            </div>
          </template>
          <template #cell(department_name)="data">
            <div class="align-middle">
              <feather-icon icon="BookmarkIcon" class="text-info" />
              {{ data.item.department_name }}
            </div>
          </template>
          <template #cell(salary)="data">
            <div class="align-middle text-center text-nowrap">
              <span
                :class="data.item.salary === 'Not defined' ? 'text-danger' : ''"
              >
                {{ data.item.salary === "Not defined" ? "" : "S/. "
                }}{{ data.item.salary }}
              </span>
            </div>
          </template>
          <template #cell(nationality)="data">
            <div class="align-middle text-center">
              {{ data.item.nationality }}
            </div>
          </template>
          <template #cell(created_at)="data">
            <div class="align-middle text-center">
              {{ data.item.created_at | myGlobal }}
            </div>
          </template>
          <template #bottom-row>
            <b-th colspan="2" class="fill-bottom-row" />
            <b-th>
              <b-badge
                variant="primary"
                class="w-100 text-center"
                style="font-size: 14px; font-weight: 600; color: white"
              >
                TOTAL
              </b-badge>
            </b-th>
            <b-th>
              <b-badge
                variant="primary"
                class="w-100 text-center"
                style="font-size: 14px; font-weight: 600; color: white"
              >
                {{ "$ " }}{{ totalSalaries | currencyZero }}
              </b-badge>
            </b-th>
            <b-th class="fill-bottom-row" colspan="2" />
          </template>
        </b-table>
      </template>
    </filter-slot>
  </div>
</template>

<script>
import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service";

export default {
  props: {
    sendYear: {
      type: Number,
      default: null,
    },
    sendMonth: {
      type: Number,
      default: null,
    },
    sendDepartmentId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isBusy: false,
      dataYear: null,
      dataMonth: null,
      startPage: 0,
      toPage: 0,
      sortBy: "client_name",
      nameDepartemnt: "",
      sortDesc: true,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by employee name...",
        model: "",
      },
      fields: [
        {
          key: "full_name_employees",
          sortable: false,
          label: "Employee Name",
          show: true,
        },
        {
          key: "number",
          sortable: false,
          label: "Code",
          show: true,
        },
        {
          key: "department_name",
          sortable: false,
          label: "Department",
          show: false,
        },
        {
          key: "salary",
          sortable: false,
          label: "Salary",
          show: true,
          class: "text-center",
        },
        {
          key: "nationality",
          sortable: false,
          label: "Nationality",
          show: true,
        },
        {
          key: "created_at",
          sortable: false,
          label: "Entry Date",
          show: true,
        },
      ],
      totalRows: 1,
      totalSalaries: 0,
      paginate: {
        perPage: 50,
        currentPage: 1,
      },
      employeeDetailItems: [],
      idDepartment: null,
    };
  },
  async created() {
    this.dataMonth = this.sendMonth;
    this.dataYear = this.sendYear;
    this.idDepartment = this.sendDepartmentId;
    this.fields[2].show = this.sendDepartmentId == null;
    this.fields = this.fields.filter((item) => item.show);
  },

  methods: {
    close() {
      this.$emit("close");
    },

    async searchNewEmployee(ctx) {
      const data = await DashboardServive.getDetailsEmployeeTurnover({
        year: this.dataYear,
        month: this.dataMonth,
        department_id: this.idDepartment,
        new_or_retired: 0,
        search_text: this.filterPrincipal.model,
        nPage: ctx.currentPage,
        nPerPage: ctx.perPage,
      });

      this.employeeDetailItems = data.data;
      this.employeeDetailItems.forEach((item) => {
        if (
          item.full_name_employees != null &&
          item.full_name_employees != ""
        ) {
          item.full_name_employees = item.full_name_employees.toUpperCase();
        }
        if (item.nationality != null && item.nationality != "") {
          item.nationality = item.nationality.toUpperCase();
        }
      });
      this.startPage = data.from;
      this.paginate.currentPage = data.current_page;
      this.paginate.perPage = data.per_page;
      this.totalRows = data.total;
      this.toPage = data.to;

      this.totalSalaries = this.employeeDetailItems[0]?.total_salaries ?? 0;
      return this.employeeDetailItems || [];
    },
  },
};
</script>
<style lang="scss" scoped>
.b-table-bottom-row th:nth-child(2) {
  border-radius: 0px 0px 0px 15px !important;
  margin-left: 5px;
  background: #0090e7 !important;
}

.b-table-bottom-row th:nth-child(3) {
  border-radius: 0px 0px 15px 0px !important;
  margin-left: -10px;
  background: #0090e7 !important;
}
.fill-bottom-row {
  background-color: #ffffff !important;
}

.dark-layout {
  .fill-bottom-row {
    background-color: #17171a !important;
    border-top: none !important;
  }
}
</style>