import { amgApi } from "@/service/axios";
import TaskService from "@/service/task";

class SettlementApprovalService {
    async getPaymentFractions(body) {
        try {
            const data = await amgApi.post("/debt-solution/get-offer-fractions", body);
            return data;
        } catch (error) {
            console.log("Errors on  SettlementApprovalService:getPaymentFractions", error);
        }
    }
}
export default new SettlementApprovalService();