<template>
  <b-modal
    ref="modalReject"
    modal-class="modal-primary"
    title-class="h3 text-white"
    title="Reject Other Payment"
    size="sm"
    @hidden="closeModal()"
    centered
    no-close-on-backdrop
  >
    <template v-if="depositId">
      <div class="accordion" role="tablist">
        <b-card
          no-body
          v-for="(payment, index) in payments"
          :key="index"
          class="mb-1"
        >
          <b-button block variant="primary" v-b-toggle="'accordion-' + index">
            {{ payment.client_name || payment.lead_name }}
          </b-button>
          <b-collapse
            :id="'accordion-' + index"
            accordion="my-accordion"
            role="tabpanel"
          >
            <div class="p-1">
              <b-input-group style="margin-bottom: 5px">
                <b-input-group-prepend class="w-40">
                  <b-input-group-text class="w-100">
                    NUMBER
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input
                  name="type"
                  :value="payment.serial_number"
                  disabled
                />
              </b-input-group>
              <b-input-group>
                <b-input-group-prepend class="w-40">
                  <b-input-group-text class="w-100">
                    HOLDER
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input name="type" :value="payment.owner" disabled />
              </b-input-group>
            </div>
          </b-collapse>
        </b-card>
      </div>
    </template>
    <template v-else>
      <b-input-group style="margin-bottom: 5px">
        <b-input-group-prepend class="w-40">
          <b-input-group-text class="w-100"> NAME </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input
          name="type"
          :value="transactionData.client_name || transactionData.lead_name"
          disabled
        />
      </b-input-group>
      <b-input-group style="margin-bottom: 5px">
        <b-input-group-prepend class="w-40">
          <b-input-group-text class="w-100"> NUMBER </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input
          name="type"
          :value="transactionData.serial_number"
          disabled
        />
      </b-input-group>
      <b-input-group style="margin-bottom: 10px">
        <b-input-group-prepend class="w-40">
          <b-input-group-text class="w-100"> HOLDER </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input name="type" :value="transactionData.owner" disabled />
      </b-input-group>
    </template>
    <span for="textarea" class="font-weight-bolder">Reason</span>

    <b-form-textarea
      id="textarea"
      v-model="reason_reject"
      placeholder="Enter something..."
      :state="error_reject ? false : null"
    ></b-form-textarea>

    <template #modal-footer>
      <b-button @click="closeModal" variant="secondary">Cancel</b-button>
      <b-button @click="reject" variant="danger">Reject</b-button>
    </template>
  </b-modal>
</template>
<script>
import OtherPaymentsService from "@/views/commons/components/other-payments/service/other-payment.service";
export default {
  props: {
    currentTab: Number,
    statusTab: Number,
    depositId: Number,
    moduleId: Number,
    transactionData: Object,
  },
  data() {
    return {
      reason_reject: "",
      payments: [],
      error_reject: false,
    };
  },
  mounted() {
    this.toggleModal("modalReject");
  },
  created() {
    console.log(this.depositId);
    if (this.depositId) {
      this.getDetails();
    }
  },
  methods: {
    async getDetails() {
      try {
        const params = {
          orderBy: 1,
          order: "desc",
          method: this.currentTab,
          status: this.statusTab,
          module_id: this.moduleId,
          deposit_id: this.depositId,
        };
        const { data } = await OtherPaymentsService.getOtherPayment(params);
        this.payments = data.data;
        return this.payments || [];
      } catch (error) {
        console.log(error);
      }
    },
    closeModal() {
      this.$emit("close");
    },
    async reject() {
      this.error_reject = false;
      if (this.reason_reject === "") {
        this.error_reject = true;
        return;
      }
      const { isConfirmed } = await this.showConfirmSwal(
        "Are you sure you want to reject this payment?",
        "This action cannot be undone.",
        "warning"
      );
      if (!isConfirmed) return;
      this.$emit("reject", this.reason_reject);
    },
  },
};
</script>

<style scoped>
.w-40 {
  width: 40%;
}
</style>