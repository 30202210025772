<template>
  <b-modal
    v-model="ownControl"
    size="lg"
    modal-class="custom-modal-amg"
    no-close-on-backdrop
    @hidden="closeModal"
    centered
  >
    <template #modal-title>
      <div class="d-flex align-items-center" style="gap: 10px">
        <feather-icon icon="UploadIcon" class="text-white" size="22" />
        <h1 class="text-white font-weight-bold">Import transactions</h1>
      </div>
    </template>
    <div class="container my-2">
      <ValidationObserver ref="formImport">
        <div class="row">
          <div class="col-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-group label="Bank:">
                <v-select
                  v-model="selectedType"
                  :reduce="(el) => el.id"
                  :options="optionsType"
                  :clearable="false"
                  :style="
                    errors[0]
                      ? 'border: 1px solid red; border-radius: 5px;'
                      : ''
                  "
                  label="name"
                />
              </b-form-group>
            </ValidationProvider>
          </div>
          <div class="col-6" v-if="notCapitalOneBank">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-group class="m-0" label="Credit Card">
                <v-select
                  v-bind="cardsExpenseScope.attr"
                  v-model="selectedCard"
                  :class="{ 'invalid-select': errors.length > 0 }"
                ></v-select>
              </b-form-group>
            </ValidationProvider>
          </div>
          <div class="col-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-group class="m-0" label="File">
                <b-form-file
                  v-model="file1"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  accept=".xlsx, .xls, .csv"
                  :class="{ 'border-danger rounded': errors.length > 0 }"
                ></b-form-file>
              </b-form-group>
            </ValidationProvider>
          </div>
          <div class="col-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-group label="Range: ">
                <date-range-picker
                  ref="picker"
                  control-container-class="vue-daterange-picker-input d-block"
                  appendToBody
                  :locale-data="{ firstDay: 1, format: 'mm/dd/yyyy' }"
                  v-model="dayTransactionHour"
                  :date-format="dateFormat"
                  :ranges="false"
                  class="d-block"
                  :class="{ 'border-danger rounded': errors.length > 0 }"
                  @start-selection="changeSelection()"
                >
                  <template v-slot:input="picker">
                    <div class="container">
                      <div class="row justify-content-between">
                        <div>
                          <span>
                            {{ picker.startDate | myGlobal }} -
                            {{ picker.endDate | myGlobal }}</span
                          >
                        </div>
                        <div>
                          <feather-icon
                            icon="CalendarIcon"
                            size="19"
                            class="text-success"
                          />
                        </div>
                      </div>
                    </div>
                  </template>
                  <template
                    v-if="sendMonth == null && sendYear == null"
                    v-slot:footer
                  >
                    <div class="d-flex justify-content-end p-1">
                      <b-button
                        size="sm"
                        variant="primary"
                        @click="clickCancel()"
                      >
                        Clear
                      </b-button>
                      <b-button
                        class="ml-1"
                        size="sm"
                        variant="success"
                        @click="$refs.picker.clickedApply()"
                      >
                        Save
                      </b-button>
                    </div>
                  </template>
                </date-range-picker>
              </b-form-group>
            </ValidationProvider>
          </div>
        </div>
      </ValidationObserver>
    </div>
    <template #modal-footer>
      <b-button variant="success" @click="importTransactions()"
        >IMPORT</b-button
      >
    </template>
  </b-modal>
</template>

<script>
import DepartmentExpenses from "@/views/commons/expenses/services/expenses-department.service";
import DashboardService from "@/views/ceo/service-dashboard/dashboard.service";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  components: {
    DateRangePicker,
  },
  props: {
    sendYear: {
      type: Number,
      default: null,
    },
    sendMonth: {
      type: Number,
      default: null,
    },
  },
  async created() {
    this.ownControl = true;
    if (this.sendYear != null && this.sendMonth != null) {
      this.year = this.sendYear;
      this.month = this.sendMonth;
    }
    await this.loadCardsExpense();
    await this.generateRanges();
    await this.getBanks();
  },
  data() {
    return {
      ownControl: false,
      dataCardsExpense: [],
      selectedCard: null,
      file1: [],
      ranges: null,
      dayTransactionHour: {
        startDate: null,
        endDate: null,
      },
      optionsType: [],
      selectedType: 1,
      minDate: null,
      maxDate: null,
    };
  },
  methods: {
    async loadCardsExpense() {
      try {
        const data = await DepartmentExpenses.getAllCardsExpensesByModule({
          module_id: null,
          program_id: null,
          card_bank_id: this.selectedType,
        });
        this.dataCardsExpense = data;
      } catch (ex) {
        this.showErrorSwal(ex);
      }
    },
    closeModal() {
      this.$emit("close");
    },
    async importTransactions() {
      try {
        let isValid = await this.$refs["formImport"].validate();
        if (!isValid) {
          return;
        }
        const formattedStartDate = this.formatDate(
          this.dayTransactionHour.startDate
        );
        const formattedEndDate = this.formatDate(
          this.dayTransactionHour.endDate
        );
        const card = this.dataCardsExpense.find(
          (item) => item.id == this.selectedCard
        );
        const bank = this.optionsType.find(
          (item) => item.id == this.selectedType
        );
        const confirm = await this.showConfirmSwal(
          `Are you sure you want to import this file in the range <strong> ${formattedStartDate}</strong> to <strong> ${formattedEndDate} </strong> ${
            card ? ", credit card " + card.value : ""
          } and <strong>${bank.name}</strong>  bank?`
        );
        if (confirm.isConfirmed) {
          this.addPreloader();

          let formData = new FormData();
          formData.append("file", this.file1);
          formData.append("credit_card_id", this.selectedCard);
          formData.append("startDate", formattedStartDate);
          formData.append("endDate", formattedEndDate);
          formData.append("card_bank_id", this.selectedType);
          const data = await DashboardService.importTransactions(formData);

          this.showSuccessSwal(
            "Import Summary",
            null,
            `A total of ${data.total} records were found in your file.<br>
            ${data.totalFiltered} records were filtered.<br>
            ${data.totalDuplicated} duplicate records were found.<br>
            ${data.totalSaved} records were successfully saved.`
          );

          this.$emit("refresh");
          this.closeModal();
        }
      } catch (error) {
        const { response } = error;
        if (response && response.status === 409) {
          this.showToast(
            "warning",
            "top-right",
            "Warning",
            "AlertTriangleIcon",
            response.data
          );
        } else {
          this.showErrorSwal(error);
        }
      } finally {
        this.removePreloader();
      }
    },
    changeSelection() {
      if (this.sendMonth == null && this.sendYear == null) {
        this.month = this.$refs.picker.start.getMonth() + 1;
        this.year = this.$refs.picker.start.getFullYear();
      }
    },
    clickCancel() {
      this.month = null;
      this.year = null;
      this.$refs.picker.clickCancel();
    },
    generateRanges() {
      if (this.sendMonth != null && this.sendYear != null) {
        const monthProp = this.sendMonth - 1;
        const firstDay = new Date(this.sendYear, monthProp, 1);
        const nextMonth = monthProp + 1;

        let year, month;
        if (nextMonth > 12) {
          year = this.sendYear + 1;
          month = 1;
        } else {
          year = this.sendYear;
          month = nextMonth;
        }

        const endDay = new Date(year, month, 0);
        let today = new Date();
        today.setHours(0, 0, 0, 0);

        if (
          firstDay.getMonth() == today.getMonth() &&
          firstDay.getFullYear() == today.getFullYear()
        ) {
          this.dayTransactionHour.endDate = today;
        } else {
          this.dayTransactionHour.endDate = endDay;
        }
        this.dayTransactionHour.startDate = firstDay;
      } 
      // else {
        // const now = new Date();
        // this.dayTransactionHour.endDate = now;
        // const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
        // this.dayTransactionHour.startDate = firstDay;
      // }
    },
    dateFormat(classes, date) {
      let month, year;

      if (this.month != null && this.year != null) {
        month = this.month;
        year = this.year;

        const firstDateOfMonth = new Date(year, month - 1, 1);
        const lastDateOfMonth = new Date(year, month, 1);

        const now = new Date();
        const lastDateAllowed =
          year === now.getFullYear() && month - 1 === now.getMonth()
            ? now
            : lastDateOfMonth;

        if (date < firstDateOfMonth || date >= lastDateAllowed) {
          classes.disabled = true;
        } else {
          classes.disabled = false;
        }
      } else {
        let today = new Date();
        if (date >= today) {
          classes.disabled = true;
        } else {
          classes.disabled = false;
        }
      }

      return classes;
    },
    formatDate(date) {
      const d = new Date(date);
      const year = d.getFullYear();
      const month = (d.getMonth() + 1).toString().padStart(2, "0");
      const day = d.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    async getBanks() {
      try {
        this.addPreloader();
        const data = await DashboardService.getBankCards();
        this.optionsType = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
  computed: {
    cardsExpenseScope() {
      return {
        attr: {
          options: this.dataCardsExpense,
          label: "value",
          reduce: (item) => item.id,
        },
      };
    },
    notCapitalOneBank() {
      return this.selectedType != null && this.selectedType != 1;
    },
  },
  watch: {
    selectedType() {
      this.selectedCard = null;
      this.loadCardsExpense();
    },
  },
};
</script>

<style scoped>
.border-danger {
  color: #fc424a !important;
}
</style>