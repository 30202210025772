<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="8.30312"
      cy="9.62533"
      rx="1.13325"
      ry="1.07025"
      :fill="color"
    />
    <ellipse
      cx="14.9767"
      cy="9.62533"
      rx="1.13325"
      ry="1.07025"
      :fill="color"
    />
    <path
      d="M15.7055 15.2464L15.7057 15.2465C15.7215 15.2622 15.7377 15.2783 15.7538 15.2947C16.0154 15.5568 16.0152 15.9813 15.7533 16.2432L15.7533 16.2432C15.6275 16.3688 15.457 16.4395 15.2791 16.4396C15.2791 16.4396 15.279 16.4396 15.2789 16.4396V16.2975C15.1387 16.2977 15.0041 16.2419 14.9051 16.1425L15.7055 15.2464ZM15.7055 15.2464C13.3986 12.9663 9.68016 12.988 7.40009 15.2949L7.39967 15.2953C7.14401 15.5561 7.14403 15.9736 7.39966 16.2344C7.65899 16.4991 8.08377 16.5033 8.3484 16.244L8.34841 16.2441L8.35004 16.2424C8.36515 16.2271 8.38033 16.2119 8.39564 16.1968L15.7055 15.2464ZM11.577 22.2962H11.5772C17.4942 22.2893 22.2893 17.4942 22.2962 11.5772V11.577C22.2962 5.65704 17.4971 0.857882 11.577 0.857882C5.65704 0.857882 0.857882 5.65704 0.857882 11.577C0.857882 17.4971 5.65704 22.2962 11.577 22.2962ZM11.577 20.9543C6.39813 20.9543 2.19982 16.756 2.19982 11.577C2.19982 6.39818 6.39804 2.19991 11.5769 2.19982C16.7535 2.20548 20.9486 6.40055 20.9543 11.5772C20.9542 16.756 16.7559 20.9543 11.577 20.9543Z"
      :fill="color"
      :stroke="color"
      stroke-width="0.284236"
    />
  </svg>
</template>
<script>
export default {
  name: "PositiveIcon",
  props: {
    size: {
      type: String,
      default: "1rem",
    },
    color: {
      type: String,
      default: "#000",
    },
  },
};
</script>
