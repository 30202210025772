import BU from "@/assets/images/icons/dashboard-ceo/logo-business.svg";
import DS from "@/assets/images/icons/dashboard-ceo/logo-debt-solution.svg";
import BC from "@/assets/images/icons/dashboard-ceo/logo-boost-credit.svg";
import CE from "@/assets/images/icons/dashboard-ceo/logo-credit-experts.svg";
import TR from "@/assets/images/icons/dashboard-ceo/logo-tax-research.svg";
import SP from "@/assets/images/icons/dashboard-ceo/logo-specialist.svg";
import KB from "@/assets/images/icons/dashboard-ceo/logo-keybook.svg";
import PG from "@/assets/images/icons/dashboard-ceo/paragon.png";

export const imgImports = {
    BU,
    DS,
    BC,
    CE,
    TR,
    SP,
    KB,
    PG
}

export const imgImportsComplete = {
    "Business": BU,
    "Debt Solution": DS,
    "Boost Credit": BC,
    "Credit Experts": CE,
    "Tax Research": TR,
    "Specialist": SP,
    "Keybook": KB,
    "Paragon": PG
}