export default [
    {
        key: 'card_bank_name',
        label: 'BANK',
        class: 'text-center',
        visible: true
    },
    {
        key: 'cardinfo',
        label: 'Card',
        class: 'text-center',
        visible: true,
        tdStyle: { width: '50%' },
    },
    {
        key: 'date_ranges',
        label: 'Range',
        class: 'text-center',
        visible: true
    },
    {
        key: 'total_transactions',
        label: 'Total transactions',
        class: 'text-center',
        visible: true
    },
    {
        key: 'total_amount',
        label: 'Total amount',
        class: 'text-center',
        visible: true
    },
    {
        key: 'created_at',
        label: 'Imported at',
        class: 'text-center',
        visible: true
    },
    // {
    //     key: 'actions',
    //     label: 'See details',
    //     class: 'text-center',
    //     visible: true
    // }
]