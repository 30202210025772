<template>
  <div style="height: 80%">
    <e-charts ref="line" :options="option" autoresize />
  </div>
</template>
  
<script>
import DashboardCallsService from "@/views/commons/components/ring-central/dashboard/dashboard.service.js";
import ECharts from "vue-echarts";

export default {
  components: {
    ECharts,
  },
  props: {
    moduleId: {
      type: Number,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
    day: {
      type: Object,
      required: true,
    },
    lineId: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  computed: {
    isDarkTheme() {
      return this.$store.state.appConfig.layout.skin === "dark";
    },
  },
  data() {
    return {
      loading: null,
      option: {},
      totalAnswer: 0,
      totalNotAnswer: 0,
    };
  },

  methods: {
    async getCalls4Hour() {
      try {
        this.totalAnswer = 0;
        this.totalNotAnswer = 0;
        this.loading = true;
        const params = {
          module_id: this.moduleId,
          type: this.type,
          from: this.day.startDate,
          to: this.day.endDate,
          line_id: this.lineId,
        };
        const { data } = await DashboardCallsService.getCalls4Hour(params);
        this.valuesTotal = data;
        this.option.xAxis.data = [
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
        ];
        const answered = data
          .map((item) => {
            if (item.cur_group == "Answered") {
              return item.total == 0 ? 0 : item.total;
            }
            return null;
          })
          .filter((valor) => valor !== null);
        this.option.series[0].data = answered;

        answered.forEach((element) => {
          this.totalAnswer += element;
        });

        const notAnswered = data
          .map((item) => {
            if (item.cur_group == "Not Answered") {
              return item.total == 0 ? 0 : item.total;
            }
            return null;
          })
          .filter((valor) => valor !== null);
        this.option.series[1].data = notAnswered;

        notAnswered.forEach((element) => {
          this.totalNotAnswer += element;
        });
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.loading = false;
      }
    },
  },

  async created() {
    this.option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        bottom: "bottom",
        padding: [30, 0, 0, 0],
        data: ["Answered", "Not Answered"],
        color: ["#0090E7", "#F04242"],
        itemHeight: 30,
        itemWidth: 30,

        textStyle: {
          color: this.isDarkTheme ? "#FFFFFF" : "#17171A",
          margin: 10,
          rich: {
            a: {
              height: 20,
              fontWeight: "bold",
              fontSize: 15,
            },
            b: {
              height: 10,
              fontSize: 12,
            },
          },
        },

        formatter: (name) => {
          const value =
            name == "Not Answered" ? this.totalNotAnswer : this.totalAnswer;
          return ["{a|" + name + "}", "{b|( " + value + " Calls)}"].join("\n");
        },
      },

      xAxis: {
        type: "category",
        axisTick: { show: false },
        data: [],
        axisLabel: {
          color: this.isDarkTheme ? "#FFFFFF" : "#616161",
          margin: 10,
          fontSize: 14,
          fontWeight: "500",
        },
      },

      yAxis: {
        type: "value",
        minInterval: 1,
        axisLabel: {
          margin: 10,
          fontSize: 15,
          fontWeight: "500",
          color: this.isDarkTheme ? "#FFFFFF" : "#616161",
        },

        nameTextStyle: {
          color: this.isDarkTheme ? "#FFFFFF" : "#616161",
        },
        axisLine: {
          show: true,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: this.isDarkTheme ? "#FFFFFF" : "#616161",
            type: "dashed",
            width: 1.3,
            interval: 10,
          },
        },
      },

      series: [
        {
          name: "Answered",
          type: "bar",
          barGap: 0,
          color: "#3F7AFA",
          label: {
            show: true,
            position: "top",
            distance: 12,
            align: "center",
            verticalAlign: "middle",
            rotate: 1,
            formatter: "{c}",
            fontSize: 12,
            rich: {
              name: {},
            },
          },
          itemStyle: {
            normal: {
              barBorderRadius: [10, 10, 0, 0],
            },
          },
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        {
          name: "Not Answered",
          type: "bar",
          color: "#A4E5FF",
          label: {
            show: true,
            position: "top",
            distance: 12,
            align: "center",
            verticalAlign: "middle",
            rotate: 1,
            formatter: "{c}",
            fontSize: 12,
            rich: {
              name: {},
            },
          },
          itemStyle: {
            normal: {
              barBorderRadius: [10, 10, 0, 0],
            },
          },
          emphasis: {
            focus: "series",
          },
          data: [],
        },
      ],
    };
    await this.getCalls4Hour();
  },

  watch: {
    day() {
      this.getCalls4Hour();
    },
    type() {
      this.getCalls4Hour();
    },

    moduleId() {
      this.getCalls4Hour();
    },

    lineId() {
      this.getCalls4Hour();
    },

    isDarkSkin: {
      handler: function (val, oldVal) {
        this.option.legend.textStyle.color = val ? "#FFFFFF" : "#17171A";
        this.option.xAxis.axisLabel.color = val ? "#FFFFFF" : "#616161";
        this.option.yAxis.axisLabel.color = val ? "#FFFFFF" : "#616161";
        this.option.yAxis.splitLine.lineStyle.color = val
          ? "#FFFFFF"
          : "#616161";
      },
      deep: true,
    },
  },
};
</script>
  
<style>
.echarts {
  width: 90% !important;
  margin: auto;
}
</style>
  