<template lang="">
  <div
    class="dashboard__card"
    :class="isDarkSkin ? 'dashboard__card--dark' : ''"
  >
    <div
      v-if="loading"
      class="loading"
    >
      <b-spinner
        variant="primary"
        label="Spinning"
      />
    </div>
    <div class="card-report">
      <div class="report-header">
        <h2 class="report-header__title">
          Commercial
        </h2>
        <div class="report-header__icons">
          <img
            :src="imgSales"
            alt="img_sales"
          >
        </div>
      </div>

      <div class="line-card"></div>

      <div class="report-body" v-if="!loading">
        <hr />
        <div class="detail-comercial">
          <div 
            class="detail-content"
            :class="isDarkSkin ? 'detail-content--dark' : ''"
          >
            <div class="detail-content__text">
              <div class="list-sales">.</div>
              <span> Meta Investment </span>
            </div>
            <div class="detail-comercial-content">
              <div class="detail-comercial-content__value d-flex pointer" @click="showDetailInversionCreative = true;">
                <span>{{ metaInvestment | formatMoney }}</span>
              </div>
            </div>
          </div>
          <div 
            class="detail-content"
            :class="isDarkSkin ? 'detail-content--dark' : ''"
          >
            <div class="detail-content__text">
              <div class="list-sales">.</div>
              <span> New Leads </span>
            </div>
            <div class="detail-comercial-content">
              <div class="detail-comercial-content__value d-flex pointer" @click="detailsLead">
                <span>{{ objLeads.leads_number }}</span>
              </div>
            </div>
          </div>
          <div 
            class="detail-content"
            :class="isDarkSkin ? 'detail-content--dark' : ''"
          >
            <div class="detail-content__text">
              <div class="list-sales">.</div>
              <span> Appointments </span>
            </div>
            <div class="detail-comercial-content">
              <div class="detail-comercial-content__value d-flex pointer" @click="showModalAppointmentsDetails = true">
                <span>{{ objAppointments.total_appointments_month }}</span>
              </div>
            </div>
          </div>
          <div 
            class="detail-content"
            :class="isDarkSkin ? 'detail-content--dark' : ''"
          >
            <div class="detail-content__text">
              <div class="list-sales">.</div>
              <span> Sales </span>
            </div>
            <div class="detail-comercial-content">
              <div class="detail-comercial-content__value d-flex pointer" @click="detailSalesPrograms(null, null)" >
                <span>{{ objSales.total_sales }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="detail-sales">
          <!-- {{arrSales  }} -->
          <div
            v-for="(item, i) in objSales.sales_by_program"
            v-if="item.sales !== 0"
            :key="i"
            class="sales pointer"
            @click="detailSalesPrograms(item.program_id, item.initial)" 
          >
          <div class="sales__program">
              <img :src="images[item.initial]" :alt="item.initial" style="width: 30px">
            </div>
            <div class="sales__value">
              {{ item.sales }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <sales-programs
      v-if="showSalesPrograms"
      :send-year="selectYear"
      :send-month="selectMonth"
      :send-programs-id="programsId"
      :send-programs-initial="programInitial"
      @close="showSalesPrograms = false"
    />
    <detail-inversion
      v-if="showDetailInversionCreative"
      :send-year="selectYear"
      :send-month="selectMonth"
      :send-programs="arrPrograms"
      @close="showDetailInversionCreative = false"
    />

    <modal-leads-details
      v-if="showModalLeadsDetails"
      :object-data="objLeadsDetail"
      :type="1"
      :type-date="2"
      :type-answer="null"
      :type-potential="null"
      :type-number="null"
      :type-appointment="null"
      :type-module="null"
      :type-show="1"
      ceoDashboard
      @hidden="showModalLeadsDetails = false"
    />
    <modal-appointments-details
      v-if="showModalAppointmentsDetails"
      :date="`${dataYear}-${dataMonth}-5`"
      :is-today="false"
      @hidden="showModalAppointmentsDetails = false"
    />

  </div>
</template>
<script>
import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service";
import CrmDashboardService from "@/views/crm/views/dashboard/service/dashboard.service";
import SalesPrograms from "@/views/ceo/dashboard/modals/sales/SalesPrograms.vue";
import DetailInversion from "@/views/ceo/dashboard/modals/expenses/DetailInversion.vue";
import ModalLeadsDetails from "@/views/crm/views/dashboard/components/leads/modal/modalLeadsGrid.vue";
import ModalAppointmentsDetails from "@/views/crm/views/dashboard/components/appointments/ModalAppointmentsGrid.vue";
import { imgImports } from "@/views/ceo/dashboard/helpers/imgImports.js";

export default {
  components: {
    SalesPrograms,
    DetailInversion,
    ModalLeadsDetails,
    ModalAppointmentsDetails,
  },
  props: {
    selectYear: {
      type: Number,
      default: null,
      required: false,
    },
    selectMonth: {
      type: Number,
      default: null,
      required: false,
    },
    listPrograms: {
      type: Array,
      required: false,
      default: () => [],
    },
    listDepartment: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      imgSales: require("@/assets/images/icons/dashboard-ceo/sales.svg"),
      objSales: {
        total_sales: 0,
        sales_by_program: [],
      },
      objLeads: {
        leads_number: 0,
      },
      objAppointments: {
        total_appointments_month: 0,
      },
      objLeadsDetail: {
        originModule: null,
        date: "",
      },
      metaInvestment: 0,
      showSalesPrograms: false,
      showDetailPayroll: false,
      showDetailInversionCreative: false,
      showDetailChargeBack: false,
      showDetailVoidRefund: false,
      showModalLeadsDetails: false,
      showModalAppointmentsDetails: false,
      dataYear: null,
      dataMonth: null,
      programsId: null,
      programInitial: null,
      images: imgImports,
      arrPrograms: [],
      arrDepartment: [],
      loading: true,
    };
  },
  watch: {
    selectMonth(newVal) {
      this.dataMonth = newVal;
      this.getSales();
    },
    selectYear(newVal) {
      this.dataYear = newVal;
      this.getSales();
    },
  },
  async created() {
    this.dataMonth = this.selectMonth;
    this.dataYear = this.selectYear;

    await this.getSales();
  },
  methods: {
    async getSales() {
      try {
        this.loading = true;
        const params = {
          month: this.selectMonth,
          year: this.selectYear,
        };

        const parameters = {
          day: null,
          module_origin: null,
          month: this.selectMonth,
          year: this.selectYear,
          type: 1,
          type_date: 2,
          type_module_selected: 1,
        };
        const data = await DashboardServive.getSales(params);
        const expenses = await DashboardServive.getExpenses(params);
        const leads = await CrmDashboardService.getLeadsDashboardData(
          parameters
        );
        const appointment =
          await CrmDashboardService.getAppointmentsDashboardData({
            date: `${this.dataYear}-${this.dataMonth}-05`,
            program_id: null,
            seller_id: null,
            slug: "card-appointments-crm",
          });
        const dc_expenses = expenses.data.dc_expenses.filter(
          (item) => item.name == "Inversion Creative"
        );
        this.metaInvestment = dc_expenses[0].value;
        this.objSales = data;
        this.objLeads = leads.data[0];
        this.objAppointments = appointment.data[0];
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    detailsLead() {
      this.objLeadsDetail = {
        ...this.objLeadsDetail,
        date: `${this.dataYear}-${this.dataMonth}-5`,
      };
      this.showModalLeadsDetails = true;
    },
    detailSalesPrograms(programsId, initial) {
      this.programsId = programsId;
      this.programInitial = initial;
      this.showSalesPrograms = true;
    },
    programImg(initial) {
      return imgImports(initial);
    },
    showModalDetailExpenses() {
      this.arrPrograms = this.listPrograms;
      this.arrDepartment = this.listDepartment;
      this.showDetailInversionCreative = true;
    },
  },
};
</script>
<style lang="scss" scoped>
$color-primary: #5f5f5f;
$color-secondary: #ffffff;
$bg-card-white: #ffffff;
$bg-card-dark: #111113;
$box-shadow-white: #00000026;

.dashboard__card {
  margin-top: 5px;
  box-shadow: 0px 0px 15px 0px $box-shadow-white;
  border-radius: 15px;
  margin-bottom: 27px;
  padding: 18px;
  background-color: $bg-card-white;
  height: auto !important;

  &--dark {
    background-color: $bg-card-dark;
    box-shadow: 0px 0px 22.935px 0px rgba(255, 249, 249, 0.1);
  }

  .card-report {
    position: relative;

    .report-header {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: end;

      &__title {
        color: #959595;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding-left: 14px;
      }

      &__icons {
        width: 47px;
        height: 47px;
      }
    }

    .line-card {
      width: 4.5px;
      height: 58px;
      background-color: #1654cc;
      position: absolute;
      top: 14px;
      left: -3px;
      border-radius: 8px;
    }

    .report-body {
      padding-left: 14px;
      padding-top: 33px;

      // padding-right: 8px;

      .report {
        width: 100%;
        display: flex;
        align-items: center;

        &__value {
          color: #606060;
          font-family: Montserrat;
          font-weight: 700;
          font-size: 1.6rem;

          &--decimal {
            font-size: 1.3rem;
          }

          &--dark {
            color: $color-secondary;
          }
        }
        .value-currency::before {
          content: "$";
        }
      }

      .detail-comercial {
        // background-color:red;
        display: flex;
        gap: 6px;
        flex-direction: column;
        justify-content: space-around;

        .detail-content {
          display: flex;
          justify-content: space-between;
          color: #606060;
          position: relative;
          margin-bottom: 3px;

          &--dark {
            color: #b4b4b4;
          }

          &__text {
            font-family: Montserrat;
            font-size: 14.678px;
            font-style: normal;
            line-height: normal;
            display: flex;
            justify-content: center;
          }
          .list-sales {
            margin-right: 7px;
            font-size: 15px;
            font-weight: bold;
            position: absolute;
            top: -4px;
            left: -9px;
          }
        }
      }
      .detail-comercial-content__value {
        font-family: Montserrat;
        font-size: 14.678px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .detail-sales {
        width: 100%;
        display: flex;
        margin-top: 20px;
        flex-wrap: wrap;
        gap: 5px;
        align-content: flex-start;

        .sales {
          height: 25px;
          padding: 4px 8px;
          display: flex;
          justify-content: space-between;
          border-radius: 10px;
          align-items: center;
          font-size: 0.93rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          font-family: Montserrat;
          margin-bottom: 5px;
          gap: 6px;

          &__program {
            color: #454545;
          }

          &__value {
            color: #1654cc;
            font-weight: 700;
            font-size: 15px;
          }

          &__icons {
            border-radius: 4px;
            background-color: #1654cc;
            padding: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}
</style>
