<template>
  <b-modal
    ref="transaction-pie-chart-detail"
    size="xl"
    hide-footer
    scrollable
    no-close-on-backdrop
    no-close-on-esc
    @hidden="$emit('hidden')"
  >
    <template #modal-title>
      <span class="font-weight-bolder">
        {{ pieChartName }} transactions
        {{
          G_CURRENT_DAILY_SETTLEMENT_MERCHANT.merchantName
            ? "of " + G_CURRENT_DAILY_SETTLEMENT_MERCHANT.merchantName
            : ""
        }}
      </span>
    </template>
    <template #default>
      <b-nav
        v-if="pieChartName != 'Matched'"
        card-header
        pills
        class="mx-1 border-tab"
      >
        <b-nav-item
          v-for="(item, index) in tabs"
          :key="index"
          :link-classes="['px-3', bgTabsNavs]"
          :active="item.value === activeTab"
          class="position-relative"
          @click="
            (activeTab = item.value),
              $refs['daily-settlements-transactions-table'].refresh()
          "
        >
          {{ item.name }}
          <b-badge
            v-if="tabCounters.find((tab) => tab.name == item.value).value != 0"
            pill
            variant="danger"
            class="position-absolute"
            style="top: -5px; right: -5px"
          >
            {{ tabCounters.find((tab) => tab.name == item.value).value }}
          </b-badge>
        </b-nav-item>
      </b-nav>
      <filter-slot
        :top-paginate="false"
        paginate-left
        class="filter-slot-new-customization"
        :filter="[]"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="$refs['daily-settlements-transactions-table'].refresh()"
      >
        <template #buttons>
          <b-button
            v-if="
              activeTab == 'inconsistent' &&
              pieChartName != 'Matched' &&
              items.length > 0
            "
            variant="success"
            class="ml-1"
            @click="fixAllTransaction()"
          >
            Balance All Amount
          </b-button>
        </template>
        <b-table
          slot="table"
          ref="daily-settlements-transactions-table"
          class="position-relative table-new-customization"
          sticky-header="60vh"
          no-provider-filtering
          :busy.sync="isBusy"
          :sort-desc.sync="sortDesc"
          :items="getDailySettlementTransactions"
          :fields="visibleFields"
          :per-page="paginate.perPage"
          :current-page="paginate.currentPage"
          :filter="filter"
          show-empty
          :tbody-tr-class="rowClass"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(transaction_id)="data">
            <div class="d-flex flex-column">
              <span v-if="data.item.transaction_id != null">
                {{ data.item.transaction_id }}
              </span>
              <span v-else> - </span>
            </div>
          </template>

          <template #cell(client)="data">
            <div class="d-flex flex-column">
              <!-- client -->
              <router-link
                v-if="data.item.is_client == 1"
                target="_blank"
                :to="routers(data.item.client_account_id, data.item.program_id)"
              >
                {{ data.item.client }}
                <br />
                <span class="mt-05" v-if="data.item.client_account">
                  {{ data.item.client_account }}
                </span>
              </router-link>
              <!-- lead -->
              <router-link
                v-else-if="
                  data.item.is_client == 0 && data.item.client_account != null
                "
                :to="`/crm/leads/${data.item.client_account}`"
                target="_blank"
              >
                {{
                  data.item.client
                    ? data.item.client
                    : data.item.transaction_client
                    ? data.item.transaction_client
                    : "UNKNOWN"
                }}
                <br />
                <span class="mt-05" v-if="data.item.client_account">
                  ID: {{ data.item.client_account }}
                </span>
              </router-link>
              <span v-else :class="isDarkSkin ? 'text-info' : 'text-info'">
                {{
                  data.item.client
                    ? data.item.client
                    : data.item.transaction_client
                    ? data.item.transaction_client
                    : "UNKNOWN"
                }}
              </span>
              <span v-if="data.item.account && data.item.icon_status">
                <status-account :account="data.item" :text="true" />
              </span>
            </div>
          </template>

          <template #cell(authorize_amount)="data">
            <span
              v-if="data.item.authorize_amount == '-'"
              class="text-uppercase"
            >
              <b-badge variant="light-info"> PENDING TO SETTLE </b-badge>
            </span>
            <span v-else class="text-primary font-weight-bolder">
              $ {{ data.item.authorize_amount | currencyZero }}
              <feather-icon
                v-if="
                  Number(data.item.authorize_amount) >
                  Number(data.item.soft_amount)
                "
                icon="ArrowUpIcon"
                class="text-success"
              />
              <feather-icon
                v-if="
                  Number(data.item.authorize_amount) <
                  Number(data.item.soft_amount)
                "
                icon="ArrowDownIcon"
                class="text-danger"
              />
            </span>
          </template>

          <template #cell(settlement_date_authorize)="data">
            <span>
              {{ data.item.user == "-" ? "UNKNOWN" : data.item.user }}
            </span>
            <br />
            <span v-if="data.item.settlement_date_authorize == '-'">
              <b-badge variant="light-info"> PENDING TO SETTLE </b-badge>
            </span>
            <span v-else>
              {{ data.item.settlement_date_authorize | myGlobalDay }}
            </span>
          </template>

          <template #cell(soft_amount)="data">
            <span
              v-if="data.item.soft_amount != '-'"
              :class="getClassForTransaction(data.item)"
            >
              <span v-if="isRefund(data.item)">-</span>
              <span> $ {{ data.item.soft_amount | currencyZero }}</span>

              <span
                v-if="isVoid(data.item)"
                v-b-tooltip.hover.bottom="'Voided amount'"
                class="ml-1 text-secondary"
              >
                ($ {{ data.item.void_amount | currencyZero }})</span
              >
            </span>
            <span v-else> - </span>
            <feather-icon
              v-if="
                Number(data.item.soft_amount) >
                  Number(data.item.authorize_amount) && !isVoid(data.item)
              "
              icon="ArrowUpIcon"
              class="text-success"
            />
            <feather-icon
              v-if="
                Number(data.item.soft_amount) <
                Number(data.item.authorize_amount)
              "
              icon="ArrowDownIcon"
              class="text-danger"
            />
          </template>

          <template #cell(authorize_status)="data">
            <b-badge
              :variant="getStatus(data.item.authorize_status)"
              class="text-uppercase"
            >
              {{
                data.item.authorize_status == "-"
                  ? "PENDING TO SETTLE"
                  : data.item.authorize_status
              }}</b-badge
            >
          </template>

          <template #cell(soft_status)="data">
            <b-badge
              v-if="data.item.soft_status != null"
              :variant="getStatus(data.item.soft_status)"
              class="text-uppercase"
            >
              {{ data.item.soft_status }}</b-badge
            >
            <span v-else> - </span>

            <feather-icon
              v-if="isRefund(data.item) || isPartialRefund(data.item)"
              size="16"
              :icon="
                data.item._showDetails ? 'ChevronUpIcon' : 'ChevronDownIcon'
              "
              class="text-primary ml-1"
              @click="data.toggleDetails"
            />
          </template>

          <template #row-details="row">
            <b-card v-if="row.item.refund_transactions != '[]'" class="p-0 m-0">
              <b-row>
                <b-col
                  v-if="
                    JSON.parse(row.item.refund_transactions)[0]
                      .transaction_id != null
                  "
                  cols="4"
                  ><b>Ref. Transaction:</b>
                  {{
                    JSON.parse(row.item.refund_transactions)[0].transaction_id
                  }}</b-col
                >
                <b-col cols="4"
                  ><b>Date:</b>
                  {{
                    JSON.parse(row.item.refund_transactions)[0].date
                      | myGlobalDay
                  }}</b-col
                >
                <b-col cols="4"
                  ><b>Amount:</b> $
                  {{
                    JSON.parse(row.item.refund_transactions)[0].amount
                      | currencyZero
                  }}</b-col
                >
                <b-col cols="4" class="mt-1"
                  ><b>Auth. Code:</b>
                  {{
                    JSON.parse(row.item.refund_transactions)[0].auth_code
                  }}</b-col
                >
                <b-col cols="4" class="mt-1"
                  ><b>Card Number:</b>
                  {{
                    JSON.parse(row.item.refund_transactions)[0].card_number
                  }}</b-col
                >
                <b-col cols="4" class="mt-1"
                  ><b>Client:</b>
                  {{
                    JSON.parse(row.item.refund_transactions)[0].fullname
                  }}</b-col
                >
              </b-row>
            </b-card>

            <b-card
              v-if="row.item.partial_refund_transactions != null"
              class="p-0 m-0"
            >
              <b-row
                v-for="(item, index) in JSON.parse(
                  row.item.partial_refund_transactions
                )"
                :key="index"
                class="mb-1"
              >
                <b-col :key="index" cols="4"
                  ><b>Ref. Transaction:</b> {{ item.transaction_id }}</b-col
                >
                <b-col v-if="item.date != null" cols="4"
                  ><b>Date:</b> {{ item.date | myGlobalDay }}</b-col
                >

                <b-col v-if="item.amount != null" cols="4"
                  ><b>Amount:</b> $ {{ item.amount | currencyZero }}</b-col
                >

                <b-col v-if="item.date == null" cols="4">
                  <b-badge variant="light-warning">
                    Refund/Pending Settlement</b-badge
                  >
                </b-col>

                <b-col v-if="item.auth_code" cols="4" class="mt-1"
                  ><b>Auth. Code:</b> {{ item.auth_code }}</b-col
                >
                <b-col v-if="item.card_number" cols="4" class="mt-1"
                  ><b>Card Number:</b> {{ item.card_number }}</b-col
                >
                <b-col v-if="item.fullname" cols="4" class="mt-1"
                  ><b>Client:</b> {{ item.fullname }}</b-col
                >
              </b-row>
            </b-card>
          </template>

          <template #cell(actions)="data">
            <div class="d-flex justify-content-center">
              <b-button
                v-if="data.item.authorize_status_id != 4"
                variant="outline-primary"
                size="sm"
                @click="
                  fixTransaction(
                    data.item.id,
                    data.item.authorize_amount,
                    data.item.transaction_id
                  )
                "
              >
                {{ getButtonTitle(data.item) }}
              </b-button>

              <b-button
                v-if="data.item.authorize_status_id == 4"
                variant="primary"
                size="sm"
                @click="ApproveOrDeclineHeldTransaction(data.item)"
              >
                Approve Transaction
              </b-button>
            </div>
          </template>
          <template #cell(chat)="row">
            <tabler-icon
              v-if="
                row.item.client_account_id != null ||
                row.item.client_account != null
              "
              icon="TicketIcon"
              class="cursor-pointer text-weight-bolder"
              style="margin-left: 5px"
              :class="row.item.ticket_code ? 'text-success' : 'text-secondary'"
              size="18"
              v-b-tooltip.hover.top="
                row.item.ticket_code ? 'Show Ticket' : 'Create Ticket'
              "
              @click="selectCustomerTicket(row.item)"
            />
          </template>

          <template #cell(card_number)="data">
            {{ data.item.card_number }}
          </template>

          <template v-if="items" #bottom-row>
            <template v-if="items.length > 0">
              <template
                v-for="(field, index) in fields.filter(
                  (fieldT) => fieldT.visible
                )"
              >
                <b-th
                  v-if="index == 0"
                  :key="field.key"
                  class="fill-bottom-row"
                >
                  <b-badge variant="primary" class="w-100 text-center">
                    TOTAL
                  </b-badge>
                </b-th>
                <b-th
                  v-else-if="index < fields.length - 11"
                  :key="'eif' + field.key"
                  class="fill-bottom-row"
                />
                <th
                  v-else
                  class="fill-bottom-row"
                  :key="'else' + field.key"
                  :colspan="
                    index == fields.length - 4 || index == fields.length - 3
                      ? 1
                      : 0
                  "
                >
                  <b-badge variant="primary" class="w-100 text-right">
                    <span
                      v-if="field.key == 'authorize_amount'"
                      class="footer-amount"
                    >
                      $ {{ authorizeAmount | currencyZero }}
                    </span>
                    <span
                      v-if="field.key == 'soft_amount'"
                      class="footer-amount"
                    >
                      <!-- $ {{ softAmount | currencyZero }} -->
                      ${{ total_soft_amount | currencyZero }}
                    </span>
                  </b-badge>
                </th>
              </template>
            </template>

            <!-- <tr v-if="items.length > 0" class="sticky-data-total text-left">
              <template
                v-for="(field, index) in fields.filter(
                  (fieldT) => fieldT.visible
                )"
              >
                <td v-if="index < fields.length - 11" :key="field.key" />
                <td
                  v-else
                  :key="'else' + field.key"
                  :colspan="
                    index == fields.length - 4 || index == fields.length - 3
                      ? 1
                      : 0
                  "
                >
                  <span v-if="field.key == 'client'" class="footer-title"
                    >Total</span
                  >
                  <span
                    v-if="field.key == 'authorize_amount'"
                    class="footer-amount"
                  >
                    $ {{ total_authorize_amount | currencyZero }}
                  </span>
                  <span v-if="field.key == 'soft_amount'" class="footer-amount">
                    $ {{ total_soft_amount | currencyZero }}
                  </span>
                </td>
              </template>
            </tr> -->
          </template>
        </b-table>
      </filter-slot>
      <modal-add-transactions-authorize
        v-if="showModalAddTransactionsAuthorize"
        :authorize-transaction-id="authorizeTransactiondId"
        @hidden="showModalAddTransactionsAuthorize = false"
        @refresh="reloadSettlements"
      />
      <modal-create-customer-ticket
        v-if="showModalCreateCustomerTicket"
        :customer-ticket="customerTicketSelected"
        :customer-id="customerId"
        :set-motive="100"
        @hidden="showModalCreateCustomerTicket = false"
        @saved="reloadSettlements"
      />

      <modal-view-ticket-customer-details
        v-if="showModalViewTicketCustomerDetails"
        :customer-ticket="customerTicketSelected"
        @hidden="showModalViewTicketCustomerDetails = false"
      />
    </template>
  </b-modal>
</template>
<script>
import DashboardService from "@/views/ceo/service-dashboard/dashboard.service";
import PaymentsService from "@/views/crm/views/payments/service/payments.service.js";
import Fields from "@/views/commons/components/reports/tabs/daily-payment-report/data/daily.settlement.transactions.fields.js";
import Filters from "@/views/commons/components/reports/tabs/daily-payment-report/data/daily.settlement.transactions.filters.js";
import { mapGetters, mapActions } from "vuex";
import ClientService from "@/views/crm/views/clients/service/clients.service.js";
import ModalAddTransactionsAuthorize from "@/views/commons/components/reports/tabs/daily-payment-report/components/ModalAddTransactionsAuthorize.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import ClientDashboardService from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services";
import ModalCreateCustomerTicket from "@/views/commons/components/customer-tickets/components/modal/ModalCreateCustomerTicket.vue";
import ModalViewTicketCustomerDetails from "@/views/commons/components/customer-tickets/components/modal/ModalViewTicketCustomerDetails.vue";

export default {
  components: {
    ModalAddTransactionsAuthorize,
    StatusAccount,
    ModalCreateCustomerTicket,
    ModalViewTicketCustomerDetails,
  },
  props: {
    pieChartName: {
      type: String,
      default: "Matched",
    },
    tabCounters: {
      type: Array,
      default: () => [],
    },
  },
  created() {},
  data() {
    return {
      currentTransactionClient: null,
      currentTransactionId: null,
      total_soft_amount: 0,
      total_authorize_amount: 0,
      isBusy: false,
      fields: Fields,
      filter: Filters,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by Client or Transaction ID...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      totalRows: 0,
      startPage: null,
      toPage: null,
      items: [],
      tabs: [
        {
          name: "Only Authorize",
          value: "only_authorize",
        },
        {
          name: "Only Soft",
          value: "only_soft",
        },
        {
          name: "Inconsistent",
          value: "inconsistent",
        },
      ],
      sortDesc: true,
      activeTab: "only_authorize",
      showModalAddTransactionsAuthorize: false,
      showTransactionBinacle: false,
      authorizeTransactiondId: null,
      isAll: false,
      showModalCreateCustomerTicket: false,
      showModalViewTicketCustomerDetails: false,
      customerTicketSelected: {},
      customerId: null,
    };
  },
  mounted() {
    this.isAll = false;
    this.toggleModal("transaction-pie-chart-detail");
    this.getAllPrograms();
    this.getStatusAllTransactions();
    this.fields.find((field) => field.key == "authorize_amount").visible = true;
    this.fields.find((field) => field.key == "authorize_status").visible = true;
    if (this.pieChartName == "Matched") {
      this.fields.find((field) => field.key == "actions").visible = false;
      this.fields.find(
        (field) => field.key == "authorize_status"
      ).visible = false;
      this.fields.find(
        (field) => field.key == "authorize_amount"
      ).visible = false;
      this.fields.find((field) => field.key == "soft_amount").visible = true;
      this.fields.find((field) => field.key == "soft_status").visible = true;
      this.fields.find((field) => field.key == "soft_status").label = "Status";
      this.fields.find((field) => field.key == "soft_amount").label = "Amount";
      this.fields.find(
        (field) => field.key == "settlement_date_authorize"
      ).label = "Date / Hour";
      this.fields.find(
        (field) => field.key == "transaction_type_name"
      ).visible = true;
    } else {
      this.fields.find((field) => field.key == "actions").visible = true;
      this.fields.find((field) => field.key == "soft_amount").visible = false;
      this.fields.find((field) => field.key == "card_number").visible = false;
      this.fields.find(
        (field) => field.key == "transaction_type_name"
      ).visible = false;

      this.fields.find((field) => field.key == "soft_status").visible = false;
      this.fields.find((field) => field.key == "authorize_status").label =
        "Status";
      this.fields.find((field) => field.key == "authorize_amount").label =
        "Amount";
      this.fields.find(
        (field) => field.key == "settlement_date_authorize"
      ).label = "Date / Hour";
      this.fields.find(
        (field) => field.key == "authorize_status"
      ).visible = true;
      this.fields.find(
        (field) => field.key == "authorize_amount"
      ).visible = true;
      this.fields.find((field) => field.key == "soft_status").label =
        "Soft Status";
      this.fields.find((field) => field.key == "soft_amount").label =
        "Soft Amount";
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_DAILY_SETTLEMENTS_DATE_RANGE:
        "ManagementReportStore/G_DAILY_SETTLEMENTS_DATE_RANGE",
      G_CURRENT_DAILY_SETTLEMENT_MERCHANT:
        "ManagementReportStore/G_CURRENT_DAILY_SETTLEMENT_MERCHANT",
    }),
    softAmount() {
      return this.items.reduce((sum, item) => {
        // Check if ref_authorize_transaction_id is not null
        if (
          item.ref_authorize_transaction_id !== null &&
          this.activeTab == "only_authorize"
        ) {
          return sum - parseFloat(item.soft_amount || 0);
        }
        return sum + parseFloat(item.soft_amount || 0);
      }, 0);
    },
    authorizeAmount() {
      return this.items.reduce((sum, item) => {
        // return sum + parseFloat(item.authorize_amount || 0);
        if (
          item.authorize_status_id == 21 &&
          this.activeTab == "only_authorize"
        ) {
          return sum - parseFloat(item.authorize_amount || 0);
        }
        return sum + parseFloat(item.authorize_amount || 0);
      }, 0);
    },
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    isManagementDailyReportTab() {
      return this.$route.name === "management-reports-daily-settlement";
    },
  },
  methods: {
    getButtonTitle(transaction) {
      if (this.activeTab == "inconsistent") {
        return "Balance Amount";
      }
      if (this.activeTab == "only_authorize") {
        return "Register Transaction";
      }
      if (
        this.activeTab == "only_soft" &&
        [1, 5, 8].includes(transaction.soft_status_id)
      ) {
        return "Auto Register Transaction";
      }
    },
    routers(id, program) {
      program = parseInt(program);
      let route = "";
      switch (program) {
        case 1:
          route = `/bussiness/clients/account/${id}`;
          break;
        case 2:
          route = `/boostcredit/clients/account/${id}`;
          break;
        case 3:
          route = `/creditexperts/clients/account/${id}`;
          break;
        case 4:
          route = `/debtsolution/clients/account/${id}`;
          break;
        case 5:
          route = `/taxresearch/clients/account/${id}`;
          break;
        case 7:
          route = `/specialists/clients/account/${id}`;
          break;
        case 8:
          route = `/bookeeping/clients/account/${id}`;
          break;
        case 9:
          route = `/ti/clients/account/${id}`;
          break;
      }
      return route;
    },

    isVoid(item) {
      return item.authorize_status === "Voided";
    },
    isRefund(item) {
      return item.authorize_status === "Refund Settled Successfully";
    },
    isPartialRefund(item) {
      return item.is_partial_refund == 1;
    },
    getClassForTransaction(item) {
      if (item.authorize_status === "Refund Settled Successfully") {
        return "text-danger";
      }
      if (item.authorize_status === "Voided") {
        return "text-primary ";
      }
      return "text-primary";
    },
    hasGridDetail(authorize_status) {
      return (
        authorize_status == "Refund Settled Successfully" ||
        authorize_status == "Voided"
      );
    },
    async ApproveOrDeclineHeldTransaction(transaction) {
      try {
        this.addPreloader();
        const params = {
          transaction_id: transaction.transaction_id,
          action: "approve",
          user_id: this.currentUser.user_id,
          merchant_id: transaction.merchant_id,
        };
        const { data } =
          await ClientDashboardService.ApproveOrDeclineHeldTransaction(params);
        if (data.authorizeStatus == 8) {
          this.$refs["daily-settlements-transactions-table"].refresh();
        }
        this.showGenericToast({
          position: "top-right",
          variant: data.variant,
          icon: data.icon,
          title: data.message,
          text: data.text,
        });
      } catch (error) {
        this.showErrorSwal(error.response.data.message);
      } finally {
        this.removePreloader();
      }
    },
    ...mapActions({
      A_GET_DAILY_SETTLEMENT_PIE:
        "ManagementReportStore/A_GET_DAILY_SETTLEMENT_PIE",
    }),
    showModalAddModalAuthorize(authorizeTransactionId) {
      this.authorizeTransactiondId = authorizeTransactionId;
      this.showModalAddTransactionsAuthorize = true;
    },
    getStatus(status) {
      if (
        status === "Settled Successfully" ||
        status === "Approved" ||
        status === "Returned Item" ||
        status === "Refund Settled Successfully"
      ) {
        return "light-success";
      }
      if (
        status === "Error" ||
        status === "Declined" ||
        status === "General Error" ||
        status === "Comm Error" ||
        status === "Could Not Void" ||
        status === "Failed Review" ||
        status === "Voided" ||
        status === "Communication Error" ||
        status == "Settlement Error"
      ) {
        return "light-danger";
      }
      if (
        status === "Pending" ||
        status === "Held for Review" ||
        status === "Expired" ||
        status === "FDS - Pending Review" ||
        status === "FDS - Authorized/Pending Review"
      ) {
        return "light-warning";
      }
      if (
        status === "Captured/Pending Settlement" ||
        status === "ChargeBack" ||
        status === "Authorized/Pending Capture" ||
        status === "Refund/Pending Settlement'" ||
        status === "Approved Review" ||
        status === "Under Review"
      ) {
        return "light-info";
      }
      return "light-info";
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.deficit) return "text-black";
    },
    isoFormatToDate(isoDate) {
      return new Date(isoDate).toISOString().substring(0, 10);
    },
    async getDailySettlementTransactions(ctx) {
      try {
        let sortBy = 7;

        if (ctx.sortBy === "id") {
          sortBy = 1;
        }
        if (ctx.sortBy === "authorize_amount") {
          sortBy = 3;
        }

        if (ctx.sortBy === "soft_amount") {
          sortBy = 4;
        }

        if (ctx.sortBy === "authorize_status") {
          sortBy = 5;
        }

        if (ctx.sortBy === "soft_status") {
          sortBy = 6;
        }

        if (ctx.sortBy === "settlement_date_authorize") {
          sortBy = 7;
        }
        if (ctx.sortBy === "card_number") {
          sortBy = 20;
        }
        if (ctx.sortBy === "merchant_name") {
          sortBy = 18;
        }
        if (ctx.sortBy === "transaction_type_name") {
          sortBy = 17;
        }
        const params = {
          search: this.filterPrincipal.model ? this.filterPrincipal.model : "",
          per_page: this.paginate.perPage,
          page: this.paginate.currentPage,
          order: ctx.sortDesc ? "desc" : "asc",
          order_by: sortBy,
          from: this.isoFormatToDate(
            this.G_DAILY_SETTLEMENTS_DATE_RANGE.startDate
          ),
          to: this.isoFormatToDate(this.G_DAILY_SETTLEMENTS_DATE_RANGE.endDate),
          type: this.pieChartName,
          tab: this.activeTab,
          merchant_id: this.G_CURRENT_DAILY_SETTLEMENT_MERCHANT.merchantId,
          user_id: this.currentUser.user_id,
        };
        const { data } = await PaymentsService.getDailySettlementsPieDetail(
          params
        );
        this.items = data.data;

        if (data.data.length > 0) {
          this.total_soft_amount = this.items[0].total_soft_amount;
          this.total_authorize_amount = this.items[0].total_authorize_amount;
        } else {
          this.total_soft_amount = 0;
          this.total_authorize_amount = 0;
        }

        this.startPage = data.from;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.last_page;
        this.totalData = data.total;
        this.totalRows = data.total;
        this.toPage = data.to;
        return this.items || [];
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    fixTransaction(transactionId, amount, authorizeTransactionId) {
      if (this.activeTab == "inconsistent") {
        this.fixInconsistentTransaction(transactionId, amount);
      } else if (this.activeTab == "only_soft") {
        this.fixOnlySoftTransaction(transactionId);
      } else if (this.activeTab == "only_authorize") {
        this.showModalAddModalAuthorize(authorizeTransactionId);
      }
    },
    fixAllTransaction() {
      if (this.activeTab == "inconsistent") {
        this.fixAllInconsistentTransaction();
      } else if (this.activeTab == "only_soft") {
        this.fixAllOnlySoftTransaction();
      }
    },
    async fixInconsistentTransaction(transactionId, amount) {
      try {
        const confirm = await this.showConfirmSwal();
        if (confirm.isConfirmed) {
          const params = {
            id: transactionId,
            amount,
          };
          await PaymentsService.fixInconsistentAmount(params);
          this.showToast(
            "success",
            "top-right",
            "Congratulations",
            "CheckIcon",
            "You've successfully fixed the transaction"
          );
          this.reloadSettlements();
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async fixAllInconsistentTransaction() {
      try {
        const confirm = await this.showConfirmSwal();
        if (confirm.isConfirmed) {
          const params = {
            from: this.isoFormatToDate(
              this.G_DAILY_SETTLEMENTS_DATE_RANGE.startDate
            ),
            to: this.isoFormatToDate(
              this.G_DAILY_SETTLEMENTS_DATE_RANGE.endDate
            ),
          };
          await PaymentsService.fixAllInconsistentAmount(params);
          this.showToast(
            "success",
            "top-right",
            "Congratulations",
            "CheckIcon",
            "You have successfully fixed the transactions"
          );
          this.reloadSettlements();
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async fixOnlySoftTransaction(transactionId) {
      try {
        const confirm = await this.showConfirmSwal();
        if (confirm.isConfirmed) {
          this.addPreloader();
          const params = {
            id: transactionId,
          };
          const { data } = await PaymentsService.fixOnlySoftTransaction(params);
          this.showToast(
            "success",
            "top-right",
            "Congratulations",
            "CheckIcon",
            "You've successfully fixed the transaction"
          );
          this.reloadSettlements();
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async fixAllOnlySoftTransaction() {
      try {
        this.isAll = true;
        const confirm = await this.showConfirmSwal();
        if (confirm.isConfirmed) {
          const params = {
            from: this.isoFormatToDate(
              this.G_DAILY_SETTLEMENTS_DATE_RANGE.startDate
            ),
            to: this.isoFormatToDate(
              this.G_DAILY_SETTLEMENTS_DATE_RANGE.endDate
            ),
          };
          await PaymentsService.fixAllOnlySoftTransaction(params);
          this.showToast(
            "success",
            "top-right",
            "Congratulations",
            "CheckIcon",
            "You've successfully fixed all transactions"
          );
          this.reloadSettlements();
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getAllPrograms() {
      try {
        const data = await ClientService.getAllPrograms();
        this.filter[0].options = data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getStatusAllTransactions() {
      try {
        const { data } = await PaymentsService.getStatusAllTransactions();
        this.filter[1].options = data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async reloadSettlements() {
      this.$refs["daily-settlements-transactions-table"].refresh();
      if (this.isAll) {
        this.tabCounters.find((tab) => tab.name == this.activeTab).value = 0;
      } else {
        this.tabCounters.find((tab) => tab.name == this.activeTab).value -= 1;
      }
      await this.A_GET_DAILY_SETTLEMENT_PIE({
        from: this.isoFormatToDate(
          this.G_DAILY_SETTLEMENTS_DATE_RANGE.startDate
        ),
        to: this.isoFormatToDate(this.G_DAILY_SETTLEMENTS_DATE_RANGE.endDate),
        merchant_id: this.G_CURRENT_DAILY_SETTLEMENT_MERCHANT.merchantId,
      });
    },
    selectCustomerTicket(item) {
      if (item.ticket_code) {
        this.openModalViewTicketCustomerDetails(item);
      } else {
        this.openModalCreateCustomerTicket(item);
      }
    },

    updateCodeTicket(ticket) {
      this.reloadSettlements;
    },

    async openModalViewTicketCustomerDetails(item) {
      const data = await DashboardService.getCustomerTicket({
        name_text: item.ticket_code,
        user_id: 1,
      });
      if (data.length > 0) {
        this.customerTicketSelected = data[0];
        this.showModalViewTicketCustomerDetails = true;
      } else {
        this.$bvToast.toast("Ticket not found", {
          title: "Error",
          variant: "danger",
        });
      }
    },

    async openModalCreateCustomerTicket(item) {
      this.customerId = item.client_account_id || item.client_account;
      let date = "";
      if (item.settlement_date_authorize) {
        const parsedDate = new Date(item.settlement_date_authorize);
        const mo = parsedDate.getMonth() + 1; // Se agrega 1 porque los meses van de 0 a 11 en JavaScript
        const da = parsedDate.getDate();
        const an = parsedDate.getFullYear();
        date = `${mo}/${da}/${an}`;
      }
      this.customerTicketSelected = {
        id: item.ticket_id,
        transaction_id: item.transaction_id,
        client_name: item.client,
        account: item.client_account,
        is_client: item.is_client,
        text: `TRANSACTION ID: ${item.transaction_id}\nAMOUNT: ${item.authorize_amount}\nDATE ${date}`,
      };
      this.showModalCreateCustomerTicket = true;
    },
  },
  watch: {
    G_DAILY_SETTLEMENTS_DATE_RANGE: {
      handler(val) {
        if (!this.$refs["daily-settlements-transactions-table"]) return;
        this.$refs["daily-settlements-transactions-table"].refresh();
      },
    },
    G_CURRENT_DAILY_SETTLEMENT_MERCHANT: {
      handler(val) {
        if (!this.$refs["daily-settlements-transactions-table"]) return;
        this.$refs["daily-settlements-transactions-table"].refresh();
      },
    },
    activeTab(newVal) {
      if (newVal == "only_soft") {
        this.fields.find(
          (field) => field.key == "authorize_amount"
        ).visible = false;
        this.fields.find(
          (field) => field.key == "authorize_status"
        ).visible = false;
        this.fields.find((field) => field.key == "soft_amount").visible = true;
        this.fields.find((field) => field.key == "soft_status").visible = true;
        this.fields.find((field) => field.key == "card_number").visible = true;

        this.fields.find(
          (field) => field.key == "transaction_type_name"
        ).visible = true;
        this.fields.find((field) => field.key == "soft_status").label =
          "Status";
        this.fields.find((field) => field.key == "soft_amount").label =
          "Amount";
        this.fields.find((field) => field.key == "chat").visible = true;
      } else if (newVal == "only_authorize") {
        this.fields.find((field) => field.key == "soft_amount").visible = false;
        this.fields.find((field) => field.key == "soft_status").visible = false;
        this.fields.find((field) => field.key == "card_number").visible = false;
        this.fields.find(
          (field) => field.key == "transaction_type_name"
        ).visible = false;
        this.fields.find(
          (field) => field.key == "authorize_amount"
        ).visible = true;
        this.fields.find(
          (field) => field.key == "authorize_status"
        ).visible = true;
        this.fields.find((field) => field.key == "authorize_status").label =
          "Status";
        this.fields.find((field) => field.key == "authorize_amount").label =
          "Amount";
        this.fields.find((field) => field.key == "chat").visible = true;
      } else {
        this.fields.find(
          (field) => field.key == "transaction_type_name"
        ).visible = false;
        this.fields.find(
          (field) => field.key == "authorize_amount"
        ).visible = true;
        this.fields.find(
          (field) => field.key == "authorize_status"
        ).visible = true;
        this.fields.find((field) => field.key == "soft_amount").visible = true;
        this.fields.find((field) => field.key == "soft_status").visible = true;
        this.fields.find((field) => field.key == "card_number").visible = true;

        this.fields.find((field) => field.key == "soft_status").label =
          "Soft Status";
        this.fields.find((field) => field.key == "soft_amount").label =
          "Soft Amount";
        this.fields.find((field) => field.key == "authorize_status").label =
          "Authorize Status";
        this.fields.find((field) => field.key == "authorize_amount").label =
          "Authorize Amount";
        this.fields.find((field) => field.key == "chat").visible = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.b-table-bottom-row th:nth-child(1) {
  border-radius: 0px 0px 0px 15px !important;
  margin-left: 5px;
  font-size: 20px;
  font-weight: bold;
  background: #0090e7 !important;
}
//last child
.b-table-bottom-row th:last-child {
  border-radius: 0px 0px 15px 0px !important;
  margin-left: -10px;
  font-size: 20px;
  font-weight: bold;
  background: #0090e7 !important;
}
.fill-bottom-row {
  font-size: 20px;
  background-color: #0090e7 !important;
}
</style>