<template>
  <div>
    <b-modal
      id="modal-detailed-lead"
      ref="modal-leads-grid"
      header-bg-variant="transparent border-bottom border-bottom-2"
      size="caro"
      header-class="p-0"
      title="Sales Detail"
      modal-class="modal-primary"
      scrollable
      @hidden="$emit('hidden')"
      hide-footer
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <span>
            {{ type == 1 ? "New Leads" : "Recovery" }} - {{ checkToday }}</span
          >
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="$emit('hidden')"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>
      <template #default>
        <filter-slot
          id="crm-detailed-lead-filter"
          :top-paginate="false"
          :filter="filterSlot.filters"
          :totalRows="filterSlot.totalRows"
          :paginate="filterSlot.paginate"
          :startPage="filterSlot.startPage"
          :toPage="filterSlot.toPage"
          :filterPrincipal="filterSlot.filterPrincipal"
          @reload="$refs['leads-grid'].refresh()"
        >
          <b-table
            ref="leads-grid"
            slot="table"
            class="blue-scrollbar px-1"
            primary-key="id"
            empty-text="No matching records found"
            responsive="md"
            sticky-header="55vh"
            show-empty
            no-provider-filtering
            :busy.sync="isBusy"
            :fields="visibleFields"
            :items="myProvider"
            :per-page="filterSlot.paginate.perPage"
            :current-page="filterSlot.paginate.currentPage"
            :class="
              isDarkSkin
                ? 'table-dark-detailed-lead'
                : 'table-light-detailed-lead'
            "
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(lead_name)="data">
              <div class="d-flex flex-column">
                <router-link
                  :class="textLink"
                  :to="`/crm/leads/${data.item.lead_id}`"
                  target="_blank"
                >
                  <span class="text-primary">{{ data.item.lead_name }}</span>
                </router-link>
                <router-link
                  :class="textLink"
                  :to="`/crm/leads/${data.item.lead_id}`"
                  target="_blank"
                >
                  <span
                    class="text-warning"
                    :style="
                      data.item.lead_name
                        ? 'font-size:13px'
                        : 'font-size:14.5px'
                    "
                    >{{ data.item.nickname }}</span
                  >
                </router-link>
              </div>
            </template>
            <template #cell(origin_module)="data">
              <div class="d-flex justify-content-center">
                <img
                  :src="`/assets/${data.item.icons}`"
                  alt="Logo"
                  height="35"
                  width="35"
                  style="background: #fafafa; border-radius: 5px; padding: 3px"
                />
              </div>
              <div class="d-flex justify-content-center">
                <span> {{ data.item.origin_module }}</span>
              </div>
            </template>
            <template #cell(mobile)="data">
              <div class="d-flex flex-column">
                <span v-if="data.item.mobile">{{ data.item.mobile }}</span>
                <span v-else>-</span>
              </div>
            </template>
            <template #cell(potential)="data">
              <div class="">
                <b-badge
                  v-if="data.item.is_mm"
                  :variant="
                    data.item.potential == 'YES'
                      ? 'light-success'
                      : 'light-danger'
                  "
                >
                  {{ data.item.potential }}
                </b-badge>
                <span v-else>-</span>
              </div>
            </template>
            <template #cell(dialogue)="data">
              <div class="">
                <b-badge
                  v-if="data.item.is_mm"
                  :variant="
                    data.item.dialogue == 'YES'
                      ? 'light-success'
                      : 'light-danger'
                  "
                >
                  {{ data.item.dialogue }}
                </b-badge>
                <span v-else>-</span>
              </div>
            </template>
            <template #cell(appointment)="data">
              <div class="">
                <b-badge
                  :variant="
                    data.item.appointment == 1
                      ? 'light-success'
                      : 'light-danger'
                  "
                >
                  {{ data.item.appointment == 1 ? "YES" : "NO" }}
                </b-badge>
              </div>
            </template>
            <template #cell(suggest)="data">
              <div
                v-if="data.item.suggest"
                class="d-flex justify-content-center align-items-center flex-wrap modal-appointments-grid"
                style="gap: 4px"
              >
                <div
                  v-for="(item, index) in data.item.suggest"
                  :key="index"
                  class="programs_image"
                >
                  <img
                    :src="getProgramImageRoute(item.program_id)"
                    v-b-tooltip.right="item.program_name"
                    alt="img_services"
                  />
                </div>
              </div>
              <div
                v-else
                class="d-flex justify-content-center align-items-center"
              >
                -
              </div>
            </template>
            <template #cell(last_call_at)="data">
              <div class="align-middle text-center">
                <span>
                  {{ data.item.last_call_at | myGlobalWithHour }}
                </span>
                <feather-icon
                  v-if="data.item.last_call_at"
                  v-b-tooltip.hover-right="'Who made the call?'"
                  size="18"
                  icon="AlertCircleIcon"
                  class="ml-1 cursor-pointer text-info"
                  @click="openWhoMadeTheCallModal(data.item.lead_id)"
                />
              </div>
            </template>
            <template #cell(created_at)="data">
              <div class="d-flex flex-column">
                <span>{{ data.item.created_at | myGlobalDay }}</span>
                <span>
                  <!-- <b-avatar
                    :src="data.item.user_photo"
                    size="1.5rem"
                    class="mr-1"
                  /> -->
                  {{ data.item.created_by }}
                </span>
              </div>
            </template>
            <template #cell(count_replies)="data">
              <div>
                <b-badge
                  :id="'name-contacts-' + data.item.lead_id"
                  pill
                  variant="light-info"
                  class="mr-auto ml-auto"
                >
                  {{ data.item.count_replies }}
                </b-badge>
                <b-tooltip
                  triggers="hover"
                  :target="'name-contacts-' + data.item.lead_id"
                  placement="right"
                >
                  <div class="d-flex flex-column">
                    <div
                      v-for="(contact, index) in data.item.contacts"
                      :key="index"
                    >
                      {{ contact.name }} -
                      {{ contact.created_at | myGlobalWithHour }}
                    </div>
                  </div>
                </b-tooltip>
              </div>
            </template>
          </b-table>
        </filter-slot>
      </template>
    </b-modal>
    <detailed-list-calls
      v-if="showWhoMadeTheCallModal"
      :lead-id="currentLeadId"
      @close="
        showWhoMadeTheCallModal = false;
        currentLeadId = null;
      "
    />
  </div>
</template>
<script>
import DashboardService from "@/views/crm/views/dashboard/service/dashboard.service";
import Fields from "@/views/crm/views/dashboard/components/leads/fields/fields-leads-grid.data.js";
import DetailedListCalls from "@/views/crm/views/dashboard/modals/DetailedListCalls.vue";
import moment from "moment";
export default {
  name: "ModalLeadsDetails",
  components: {
    DetailedListCalls,
  },
  props: {
    objectData: {
      type: Object,
      default: null,
    },
    type: {
      type: Number,
      default: 1,
    },
    typeDate: {
      type: Number,
      default: 1,
    },
    typeAnswer: {
      type: Number,
      required: false,
    },
    typePotential: {
      type: Number,
      required: false,
    },
    typeNumber: {
      type: Number,
      required: false,
    },
    typeAppointment: {
      type: Number,
      required: false,
    },
    typeModule: {
      type: Number,
      required: false,
    },
    typeShow: {
      type: Number,
      required: false,
    },
    ceoDashboard: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isBusy: false,
      fields: Fields,
      filterSlot: {
        filters: [],
        paginate: {
          currentPage: 1,
          perPage: 50,
        },
        startPage: 0,
        toPage: 0,
        totalRows: 0,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Name...",
          model: "",
        },
      },
      currentLeadId: null,
      showWhoMadeTheCallModal: false,
    };
  },
  computed: {
    visibleFields() {
      if (this.typeShow == 1 && this.type == 2) {
        this.fields.find((item) => item.key == "count_replies").visible = true;
      } else {
        this.fields.find((item) => item.key == "count_replies").visible = false;
      }
      return this.fields.filter((item) => item.visible);
    },
    checkToday() {
      if (this.ceoDashboard) {
        return moment(this.objectData.date).format("MMMM, YYYY");
      } else {
        return moment(this.objectData.date).format("MMMM DD, YYYY");
      }
    },
  },
  created() {},
  mounted() {
    this.toggleModal("modal-leads-grid");
  },
  methods: {
    async myProvider() {
      try {
        const inputDate = new Date(this.objectData.date);
        const utcDate = new Date(
          inputDate.getTime() + inputDate.getTimezoneOffset() * 60000
        );

        const params = {
          type: this.type,
          module_origin: this.objectData.originModule,
          year: utcDate.getFullYear(),
          month: utcDate.getMonth() + 1,
          day: utcDate.getDate(),
          type_date: this.typeDate,
          page: this.filterSlot.paginate.currentPage,
          per_page: this.filterSlot.paginate.perPage,
          search_text: this.filterSlot.filterPrincipal.model,
          type_module: this.type == 1 ? this.typeModule : 1,
          type_show: this.typeShow,
          answer: this.typeAnswer,
          potential: this.typePotential,
          number_phone: this.typeNumber,
          appointment: this.typeAppointment,
        };
        const { data } = await DashboardService.getLeadsDashboardDataDetails(
          params
        );
        this.filterSlot.totalRows = data.total;
        this.filterSlot.startPage = data.from ?? 0;
        this.filterSlot.toPage = data.to ?? 0;
        this.filterSlot.paginate.currentPage = data.current_page;
        this.filterSlot.paginate.perPage = data.per_page;
        return data.data;
      } catch (error) {
        this.showErrorSwal();
        console.log(error);
      }
    },
    addSearchIconInFilter() {
      const iconElement = document.createElement("i");
      iconElement.setAttribute("data-v-04a348xasdg48", "");
      iconElement.classList.add("search-icon");
      const div = document.createElement("div");
      div.setAttribute("data-v-04a348xasdg48", "");
      div.appendChild(iconElement);
      setTimeout(() => {
        const inputFilterParent = document.querySelector(
          "#inner-duo-filter-container"
        );
        const { firstChild } = inputFilterParent;
        inputFilterParent.insertBefore(iconElement, firstChild);
      }, 10);
    },
    openWhoMadeTheCallModal(leadId) {
      this.currentLeadId = leadId;
      this.showWhoMadeTheCallModal = true;
    },
  },
};
</script>
<style lang="scss">
#crm-detailed-lead-filter #container-filters-selector {
  margin-top: 20px !important;
  margin-bottom: 15px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  border-radius: 10px !important;
  background-color: #fafafa !important;
}

.dark-layout #crm-detailed-lead-filter #container-filters-selector {
  margin-top: 20px !important;
  margin-bottom: 15px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  border-radius: 10px !important;
  background-color: #1b1b1e !important;
}

#crm-detailed-lead-filter #filter-slot-seach-input {
  border-radius: 10px !important;
  padding-top: 25px !important;
  padding-bottom: 25px !important;
  padding-left: 45px !important;
}
#crm-detailed-lead-filter #filter-slot-search-icon {
  display: none;
}

#crm-detailed-lead-filter #inner-duo-filter-container {
  width: 60% !important;
}

#crm-detailed-lead-filter #inner-duo-filter-container button {
  border-radius: 10px !important;
}

#crm-detailed-lead-filter #inner-duo-filter-container button div {
  padding: 3px !important;
}

#crm-detailed-lead-filter #inner-duo-filter-container button div svg {
  width: 20px !important;
  height: 20px !important;
}

#crm-detailed-lead-filter .page-item button {
  border-radius: 5px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}
#crm-detailed-lead-filter .page-item span {
  border-radius: 5px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}
#crm-detailed-lead-filter #filters-selector {
  display: flex;
  flex-direction: row-reverse !important;
  justify-content: space-between;
}
#crm-detailed-lead-filter #filters-selector #filters-selector__pages {
  justify-content: flex-end !important;
}
#modal-detailed-lead table {
  border-collapse: separate !important;
  border-spacing: 0px 8px !important;
}

.table-dark-detailed-lead tr td {
  border: none !important;
  background: #1b1b1e !important;
}

.table-dark-detailed-lead tr td:first-child {
  border-radius: 10px 0 0 10px !important;
}

.table-dark-detailed-lead tr td:last-child {
  border-radius: 0 10px 10px 0 !important;
  background: #1b1b1e !important;
}

.table-dark-detailed-lead tr td {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.table-light-detailed-lead tr td {
  border: none !important;
  background: #fafafa !important;
}

.table-light-detailed-lead tr td:first-child {
  border-radius: 10px 0 0 10px !important;
}

.table-light-detailed-lead tr td:last-child {
  border-radius: 0 10px 10px 0 !important;
  background: #fafafa !important;
}

.table-light-detailed-lead tr td {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.table-dark-detailed-lead th {
  border-top: none !important;
}
.table-light-detailed-lead th {
  border-top: none !important;
}

.search-icon {
  position: absolute;
  width: 22px;
  height: 22px;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="50px" stroke="%23ffffff" height="50px"><path d="M 21 3 C 11.621094 3 4 10.621094 4 20 C 4 29.378906 11.621094 37 21 37 C 24.710938 37 28.140625 35.804688 30.9375 33.78125 L 44.09375 46.90625 L 46.90625 44.09375 L 33.90625 31.0625 C 36.460938 28.085938 38 24.222656 38 20 C 38 10.621094 30.378906 3 21 3 Z M 21 5 C 29.296875 5 36 11.703125 36 20 C 36 28.296875 29.296875 35 21 35 C 12.703125 35 6 28.296875 6 20 C 6 11.703125 12.703125 5 21 5 Z"/></svg>')
    no-repeat;
  background-size: contain;
  left: 30px;
  z-index: 2;
}

.header-modal {
  position: relative;
  padding: 20px 0 20px 15px;
  background: rgba(22, 84, 204, 1);
  width: 100%;
  font-size: 22px;
  display: flex;
  justify-content: space-betweeen;

  span {
    font-size: 1.6rem;
    font-weight: bold;
    margin-left: 8px;
    color: #ffffff;
  }

  .container-icons {
    background-color: #ffffff;
    padding: 2.7px 6.37px;
    border-radius: 0.357rem;
    width: 31px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    transition: all 0.23s ease 0.1s;

    &--dark {
      background-color: #17171a;
    }

    &:hover {
      transform: translate(-7px, 5px);
      cursor: pointer;
    }
  }

  .programs_image {
    background-color: #e9e9e9;
    padding: 0.2rem;
    border-radius: 10px;
    width: 2.5rem;
    height: 2.5rem;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
