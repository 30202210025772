<template>
  <div id="detailed-negotiate-component">
    <b-modal
      v-model="onControl"
      header-bg-variant="transparent border-bottom border-bottom-2"
      size="xmd"
      header-class="p-0"
      modal-class="modal-primary"
      hide-footer
      scrollable
      @hidden="close()"
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <span>{{ `Negotiate - ${negotiateStatusName}` }}</span>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="$emit('close')"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>

        </div>
      </template>
      <b-nav
        card-header
        pills
        class="m-0"
      >
        <b-nav-item
          exact-active-class="active router-link-active"
          :link-classes="[
            'sub-tab-nav',
            'px-3',
            [1, 8, 9, 10].includes(statusNav) ? 'router-link-active' : '',
            bgTabsNavs,
          ]"
          :active="[1, 8, 9, 10].includes(statusNav)"
          @click="changeNav(IS_DEBT_SOLUTION_DEPLOYED_INTO_NEW_PAYMENT_METHOD ? 8 : 1)"
        >
          Active
          <span
            v-if="false"
            class="ml-2"
          ><feather-icon
            icon
            badge="''"
            badge-classes="badge-danger"
          /></span>
        </b-nav-item>
        <b-nav-item
          exact-active-class="active router-link-active"
          :link-classes="[
            'sub-tab-nav',
            'px-3',
            [5, 11, 12, 13].includes(statusNav) ? 'router-link-active' : '',
            bgTabsNavs,
          ]"
          :active="[5, 11, 12, 13].includes(statusNav)"
          @click="changeNav(IS_DEBT_SOLUTION_DEPLOYED_INTO_NEW_PAYMENT_METHOD ? 1 : 5)"
        >
          Loyal
          <span
            v-if="false"
            class="ml-2"
          ><feather-icon
            icon
            badge="''"
            badge-classes="badge-danger"
          /></span>
        </b-nav-item>
        <b-nav-item
          exact-active-class="active router-link-active"
          :link-classes="[
            'sub-tab-nav',
            'px-3',
            [2, 4, 6].includes(statusNav) ? 'router-link-active' : '',
            bgTabsNavs,
          ]"
          :active="[2, 4, 6].includes(statusNav)"
          @click="changeNav(2)"
        >
          {{ IS_DEBT_SOLUTION_DEPLOYED_INTO_NEW_PAYMENT_METHOD ? 'Outstanding' : 'Hold' }}
          <span
            v-if="false"
            class="ml-2"
          ><feather-icon
            icon
            badge="''"
            badge-classes="badge-danger"
          /></span>
        </b-nav-item>
      </b-nav>
      <b-card
        no-body
        class="border-top-info border-3 border-table-radius px-0 w-100"
      >
        <filter-slot
          :filter="filters"
          class="filter-slot-new-customization"
          :filter-principal="filterPrincipal"
          :total-rows="totalRows"
          :paginate="paginate"
          :start-page="startPage"
          :to-page="toPage"
          :top-paginate="false"
          @reload="$refs['refDetailedNegotiate'].refresh()"
        >
          <template #table>
            <b-table
              id="detailed-negotiate-table"
              ref="refDetailedNegotiate"
              no-border-collapse
              class="position-relative table-new-customization"
              :fields="fields"
              show-empty
              no-provider-filtering
              sticky-header="50vh"
              primary-key="id"
              responsive="sm"
              :items="myProvider"
              :current-page="paginate.currentPage"
              :per-page="paginate.perPage"
              :busy.sync="isBusy"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2 ">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </template>
              <template #empty>
                <div class="text-center text-primary my-2">
                  <strong>No existing data.</strong>
                </div>
              </template>
              <template #head(balance)="data">
                <div class="text-center">
                  {{ data.label }}
                </div>
              </template>
              <template #head(created_at)="data">
                <div class="text-center">
                  {{ data.label }}
                </div>
              </template>
              <template #head(advisor_name)="data">
                <div class="text-center">
                  {{ data.label }}
                </div>
              </template>
              <template #cell(client_name)="data">
                <div>
                  <router-link
                    class
                    :to="{
                      name: 'debt-solution-dashboard',
                      params: {
                        idClient: data.item.client_account_id,
                      },
                    }"
                    target="_blank"
                  >{{ data.item.client_name }}
                  </router-link>
                  <status-account :account="data.item" />
                  <span>{{ data.item.account_id }}</span>
                </div>
              </template>
              <template #cell(balance)="data">
                <div class="text-center">
                  $ {{ data.item.balance | currencyZero }}
                </div>
              </template>
              <template #cell(advisor_name)="data">
                <div class="text-center">
                  {{ data.item.advisor_name }}
                </div>
              </template>
              <template #cell(created_at)="data">
                <div class="align-middle text-center">
                  {{ data.item.created_at | myGlobalWithHour }}
                </div>
              </template>
              <template #bottom-row>
                <b-th>
                  <b-badge
                    variant="primary"
                    class="w-100 text-left"
                    style="font-size: 14px; font-weight: 600"
                  >
                    TOTAL
                  </b-badge>
                </b-th>
                <b-th>
                  <b-badge
                    variant="primary"
                    class="w-100 text-center"
                    style="font-size: 14px; font-weight: 600"
                  >
                    {{ "$ " }}{{ totalBalance | currency }}
                  </b-badge>
                </b-th>
                <b-th class="fill-bottom-row" colspan="2" />
              </template>
            </b-table>
          </template>
        </filter-slot>
      </b-card>
    </b-modal>
  </div>
</template>

<script>

import DashboardService from '@/views/debt-solution/views/dashboard/service/dashboard.service';
import StatusAccount from '@/views/commons/components/clients/stylescomponents/StatusAccount.vue';
import Filters from '@/views/debt-solution/views/dashboard/components/negotiate/data/filters.data';

import { mapGetters } from 'vuex';

export default {
  components: {
    StatusAccount,
  },
  props: {
    defaultStatus: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      debtSolutionProgram: 4,
      totalBalance: 0,
      filters: Filters,
      onControl: false,
      startPage: 0,
      toPage: 0,
      isBusy: false,
      fields: [
        {
          key: 'client_name',
          sortable: false,
          label: 'Client Name',
          visible: true,
        },
        {
          key: 'balance',
          sortable: false,
          label: 'Balance',
          visible: true,
        },
        {
          key: 'advisor_name',
          sortable: false,
          label: 'Advisor',
          visible: true,
        },
        {
          key: 'created_at',
          sortable: false,
          label: 'Enroll Date',
          visible: true,
        },
      ],
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      totalRows: 1,
      paginate: {
        perPage: 100,
        currentPage: 1,
      },
      statusNav: 2,
    };
  },
  computed: {
    IS_DEBT_SOLUTION_DEPLOYED_INTO_NEW_PAYMENT_METHOD() {
      return this.G_NEW_PAYMENT_METHODS_DEPLOYED_PROGRAMS.includes(this.debtSolutionProgram);
    },
    ...mapGetters({
      G_NEW_PAYMENT_METHODS_DEPLOYED_PROGRAMS: 'global-store/G_NEW_PAYMENT_METHODS_DEPLOYED_PROGRAMS',
    }),
    status() {
      return [
        {
          id: 8,
          total: 0,
          subStatus: [
            { id: 8, name: 'Current', total: 0 },
            { id: 9, name: '1 Month Late', total: 0 },
            { id: 10, name: '2 Month Late', total: 0 },
          ],
        },
        {
          id: 11,
          total: 0,
          subStatus: [
            { id: 11, name: 'In Progress', total: 0 },
            { id: 12, name: 'Potential', total: 0 },
            { id: 13, name: 'Stand By', total: 0 },
          ],
        },
        {
          id: 2,
          total: 0,
          subStatus: [
            { id: 2, name: 'Hold', total: 0 },
            { id: 4, name: 'Canceled', total: 0 },
            { id: 6, name: 'Closed', total: 0 },
          ],
        },
      ];
    },
    showStatus() {
      let statusParent = 2;

      if ([8, 9, 10].includes(this.statusNav)) {
        statusParent = 8;
      } else if ([11, 12, 13].includes(this.statusNav)) {
        statusParent = 11;
      }
      const status = this.status.find(status => status.id === statusParent);
      return status?.subStatus;
    },
    negotiateStatusName() {
      switch (this.defaultStatus) {
        case 1:
          return 'Active';
        case 2:
          return 'Hold';
        case 5:
          return 'Loyal';
        default:
          return '';
      }
    },
  },
  async created() {
    if (this.defaultStatus == null) {
      this.statusNav = this.IS_DEBT_SOLUTION_DEPLOYED_INTO_NEW_PAYMENT_METHOD ? 8 : 1;
    } else {
      this.statusNav = this.defaultStatus;
    }
    this.onControl = true;
  },
  methods: {
    close() {
      this.onControl = false;
      this.$emit('close');
    },
    async myProvider() {
      const params = {
        from_date: this.filters[0].model,
        to_date: this.filters[1].model,
        page: this.paginate.currentPage,
        per_page: this.paginate.perPage,
        status: this.statusNav,
        search_text: this.filterPrincipal.model,
      };

      const { data } = await DashboardService.getDetailedDebtSolutionBalance(params);
      const items = data.data;
      this.startPage = data.from;
      this.paginate.currentPage = data.current_page;
      this.paginate.perPage = data.per_page;
      this.totalRows = data.total;
      this.toPage = data.to;
      this.totalBalance = data.data[0]?.total_balance;
      return items || [];
    },
    async changeNav(value) {
      this.statusNav = value;
      this.$refs.refDetailedNegotiate.refresh();
    },
  },
};
</script>
    <style lang="scss" scoped>
    .text-money {
        font-weight: 500;

        &::before {
            content: "$";
        }
    }

    .header-modal {
        position: relative;
        padding: 20px 0 20px 15px;
        background: rgba(22, 84, 204, 1);
        width: 100%;
        font-size: 22px;
        display: flex;
        justify-content: space-betweeen;

        span {
            font-size: 1.6rem;
            font-weight: bold;
            margin-left: 8px;
            color: #FFFFFF;
        }

        .container-icons {
            background-color: #FFFFFF;
            padding: 2.7px 6.37px;
            border-radius: 0.357rem;
            width: 31px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 15px;
            right: 20px;
            transition: all 0.23s ease 0.1s;

            &--dark {
                background-color: #17171a;
            }

            &:hover {
                transform: translate(-7px, 5px);
                cursor: pointer;
            }

        }
    }

    .charge__programs {
    width: 47px;
    height: 40px;
    background-color: #E9E9E9;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 35px;
        height: 35px;
    }
    }
    </style>
<style lang="scss">
  #detailed-negotiate-table .b-table-bottom-row {
        position: sticky;
        bottom: 0px;
        z-index: 1;
  }
  #detailed-negotiate-table .b-table-bottom-row th:nth-child(1) {
    border-radius: 0px 0px 0px 15px !important;
    margin-left: 5px;
    background: #0090e7 !important;
  }
  #detailed-negotiate-table .b-table-bottom-row th:nth-child(2) {
    border-radius: 0px 0px 15px 0px !important;
    margin-left: -10px;
    background: #0090e7 !important;
  }
  #detailed-negotiate-table .fill-bottom-row {
    background-color: #ffffff !important;
  }
  .dark-layout #detailed-negotiate-table {
    .fill-bottom-row {
      background-color: #17171a !important;
      border-top: none !important;
    }
  }
</style>
