export default [
  {
    key: "module",
    label: "module",
    visible: true,
  },
  {
    key: "client",
    label: "clients",
    visible: true,
  },
  // {
  //   key: "payable",
  //   label: "Vendor",
  //   visible: true,
  // },
  // {
  //   key: "description",
  //   label: "concept",
  //   visible: true,
  // },
  {
    key: "details",
    label: "Transaction details",
    visible: true,
    tdClass: "text-left col-4",
  },
  {
    key: "cardholdername",
    label: "cardholdername",
    visible: false,
  },

  {
    key: "created_at",
    label: "created_at",
    visible: false,
  },
  {
    key: "name_status",
    label: "status",
    visible: false,
  },
  {
    key: "type",
    label: "type",
    visible: false,
  },
  {
    key: "method",
    label: "Card",
    visible: true,
    class: "text-center",
  },
  {
    key: "address",
    label: "address",
    visible: false,
  },
  {
    key: "recurring_payment",
    label: "recurring",
    visible: true,
    class: "text-center",
  },
  {
    key: "status_check",
    label: "Status",
    visible: false,
  },
  {
    key: "date",
    label: "date expense",
    visible: true,
  },
  {
    key: "amount",
    label: "amount",
    visible: true,
  },
  {
    key: "check_number",
    label: "Check Number",
    visible: false,
    tdClass: "text-center",
    thClass: "text-center",
  },
  {
    key: "created_by",
    label: "registered by",
    visible: true,
  },
  {
    key: "deleted_by",
    label: "rejected at",
    visible: false,
  },
  {
    key: "collected_at",
    label: "Collected at",
    visible: false,
  },
  {
    key: "approved_by",
    label: "confirmed at",
    visible: true,
  },
  {
    key: "tracking",
    label: "Tracking",
    visible: true,
    class: "text-center",
  },
];
