<template>
  <div>
    <b-modal
      ref="myModal"
      v-model="ownControl"
      modal-class="modal-primary"
      modal
      size="xlg"
      scrollable
      :hide-footer="true"
      header-class="p-0"
      header-bg-variant="transparent border-bottom border-bottom-2"
      @hidden="$emit('close')"
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <div class="ml-1">
            Client Status ( {{ dataClient.statusName }} ) ({{ G_TITLE_MODAL }})
          </div>

          <b-button
            size="sm"
            variant="light"
            class="btn_close px-0"
            style="width: 35px"
            @click="close()"
          >
            <feather-icon icon="XIcon" size="18" class="pointer text-black" />
          </b-button>
        </div>
      </template>
      <div>
        <b-row>
          <b-col cols="12" class="text-right">
            <filter-slot
              ref="filterslotModal"
              :filter="[]"
              :no-visible-principal-filter="true"
              :filter-principal="filterPrincipal"
              :total-rows="totalRows"
              :paginate="paginate"
              :start-page="startPage"
              :to-page="toPage"
              :list-programs="listProgramsModal"
              :hide-select="hideSelect"
              @reload="$refs.clientStatusListTable.refresh()"
              @changeProgram="changeProgram"
            >
              <template #table>
                <b-table
                  ref="clientStatusListTable"
                  primary-key="id"
                  :items="myProvider"
                  :fields="fields"
                  table-class="text-nowrap"
                  responsive="sm"
                  show-empty
                  sticky-header="50vh"
                  :busy.sync="isBusy"
                  :per-page="paginate.perPage"
                  :current-page="paginate.currentPage"
                >
                  <template #table-busy>
                    <div class="text-center text-primary my-2">
                      <b-spinner class="align-middle mr-1" />
                      <strong>Loading ...</strong>
                    </div>
                  </template>

                  <template #cell(full_name_lead)="data">
                    <div>
                      <router-link
                        class
                        target="_blank"
                        :to="
                          routers(
                            data.item.program_id,
                            data.item.client_acount_id
                          )
                        "
                      >
                        {{ data.item.full_name_lead }} <br />
                        {{ data.item.account }}
                      </router-link>
                    </div>
                  </template>

                  <template #cell(created_at)="data">
                    <div>
                      {{ data.item.created_at | myGlobalDay }}
                    </div>
                  </template>

                  <template #cell(observation)="data">
                    <div
                      class="text-justify"
                      style="width: 100%; white-space: normal"
                      v-html="data.item.observation"
                    />
                  </template>
                </b-table>
              </template>
            </filter-slot>
          </b-col>
        </b-row>
      </div>
      <template #modal-footer>
        <b-row class="d-flex justify-content-end">
          <b-button variant="outline-success">
            SAVE
            <feather-icon icon="SaveIcon" />
          </b-button>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service.js";
import FilterSlot from "../slot/FilterSlot.vue";

export default {
  name: "ModalClientStatus",
  components: {
    FilterSlot,
  },
  props: {
    dataClient: {
      type: Object,
      required: false,
      default: null,
    },
    hideSelect: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      ownControl: true,
      initialProgram: "",
      listProgramsModal: [],

      // table
      isBusy: false,
      fields: [
        {
          key: "full_name_lead",
          label: "Client Name",
          thStyle: { width: "33%" },
          thClass: "text-left",
          tdClass: "text-left",
        },
        {
          key: "created_at",
          label: "LAST UPDATE",
          thStyle: { width: "33%" },
          thClass: "text-left",
          tdClass: "text-left",
        },
        {
          key: "observation",
          label: "observation",
          thStyle: { width: "350px" },
          thClass: "text-left",
          tdClass: "text-left",
        },
      ],

      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by description...",
        // model: '',
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      startPage: null,
      totalRows: 0,
      toPage: null,

      listPrograms: [],
      selectProgram: null,
    };
  },
  async created() {
    this.getProgramsStatus();
  },
  computed: {
    ...mapGetters({
      G_TITLE_MODAL: "DashboardCeoStore/G_TITLE_MODAL",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  mounted() {},
  methods: {
    close(){
      this.$refs['myModal'].hide();
      this.$emit("close");
    },
    async myProvider(ctx) {
      try {
        this.isBusy = true;
        const params = {
          year: this.dataClient.year,
          month: this.dataClient.month,
          idProgram:
            this.dataClient.idProgram == 0 || !this.dataClient.idProgram
              ? this.selectProgram
              : this.dataClient.idProgram,
          statusid: this.dataClient.statusId,
          search_text: "",
          nPage: this.paginate.currentPage,
          nPerPage: this.paginate.perPage,
          index: this.index,
        };
        const data = await DashboardServive.clientsStatusFilter(params);
        // console.log(data);
        this.isBusy = false;

        this.paginate.currentPage = data.current_page;
        this.paginate.perpage = data.per_page;
        this.totalRows = data.total;
        this.toPage = data.to;

        return data.data || [];
      } catch (error) {
        this.isBusy = false;
        this.showErrorSwal(error);
      }
    },

    async getProgramsStatus() {
      const data = await DashboardServive.getProgramsByNewStatus({
        new_status: this.index,
        year: this.dataClient.year,
        month: this.dataClient.month,
      });
      this.listProgramsModal = data;
    },

    routers(program, id) {
      let route = "";
      switch (program) {
        case 1:
          route = `/bussiness/clients/account/${id}`;
          break;
        case 2:
          route = `/boostcredit/clients/account/${id}`;
          break;
        case 3:
          route = `/creditexperts/clients/account/${id}`;
          break;
        case 4:
          route = `/debtsolution/clients/account/${id}`;
          break;
        case 5:
          route = `/taxresearch/clients/account/${id}`;
          break;
        case 7:
          route = `/specialists/clients/account/${id}`;
          break;
        case 8:
          route = `/bookeeping/clients/account/${id}`;
          break;
        case 9:
          route = `/ti/clients/account/${id}`;
          break;
      }
      return route;
    },
    changeProgram(selectProgram) {
      this.selectProgram = selectProgram;
      this.$refs.clientStatusListTable.refresh();
    },
  },
};
</script>
<style lang="scss" scoped>
.header-modal {
  width: 100%;
  background: rgba(22, 84, 204, 1);
  //padding: 20px 0 20px 15px;
  height: 80px;
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  //border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
}

.btn_close {
  position: absolute;
  top: 21px;
  right: 9px;
  transition: all 0.23s ease 0.1s;

  &:hover {
    transform: translate(-7px, 5px);
    cursor: pointer;
  }
}

.content_select {
  display: flex !important;
  flex-wrap: nowrap;
  justify-content: flex-end;

  .label_select {
    height: 50px;
    display: flex !important;
    align-items: center;
  }
}
</style>
