export default [
  {
    key: "pages",
    type: "select",
    margin: true,
    showLabel: true,
    label: "Service",
    placeholder: "Select Service",
    model: null,
    options: [],
    reduce: "id",
    selectText: "name",
    cols: 12,
  },
];
