<template>
  <validation-observer ref="recoveryCreditorObserver">
    <b-container class="mt-1">
      <h4>Recovery</h4>
      <b-row class="mt-1">
        <b-col cols="12">
          <validation-provider rules="max:255" v-slot="{ errors }">
            <b-form-group label="Name" label-for="recovery">
              <b-form-input
                id="recovery"
                name="recovery"
                v-model="userData.rName"
                autofocus
                :disabled="onlyView"
                maxlength="255"
                :state="errors[0] ? false : null"
                trim
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col cols="4">
          <validation-provider v-slot="{ errors }" :rules="userData.recovery_phone.length > 1 ? 'mobile|required':'mobile'">
            <div class="d-flex justify-content-between">
              <label for="recoveryPhone">Phone</label>
              <b-button
                variant="outline"
                :disabled="onlyView"
                v-b-tooltip.hover-right="'Add Recovery Phone'"
                class="p-0 m-0"
                @click="addInput(1)"
              >
                <feather-icon
                  icon="PlusSquareIcon"
                  size="18"
                  class="text-primary"
                />
              </b-button>
            </div>

            <b-form-input
              id="recoveryPhone"
              v-model="userData.recovery_phone[0].phone"
              autofocus
              trim
              :disabled="onlyView"
              :state="errors[0] ? false : null"
              class="widthInput"
              v-mask="'(###) ###-####'"
            />
          </validation-provider>
        </b-col>

        <b-col cols="4">
          <validation-provider v-slot="{ errors }"  :rules="userData.recovery_agent.length >1 ? 'required|min:2|max:255':'min:2|max:255'">
            <div class="d-flex justify-content-between">
              <label for="recoveryAgent">Agent</label>
              <b-button
                variant="outline"
                v-b-tooltip.hover-right="'Add Recovery Agent'"
                class="p-0 m-0"
                :disabled="onlyView"
                @click="addInput(5)"
              >
                <feather-icon
                  icon="PlusSquareIcon"
                  size="18"
                  class="text-primary"
                />
              </b-button>
            </div>
            <b-form-input
              id="recoveryAgent"
              v-model="userData.recovery_agent[0].agent"
              autofocus
              trim
              :disabled="onlyView"
              class="widthInput"
              maxlength="255"
              :state="errors[0] ? false : null"
            />
          </validation-provider>
        </b-col>

        <b-col cols="4">
          <validation-provider v-slot="{ errors }" rules="min:2">
            <div class="d-flex justify-content-between">
              <label for="recoveryExtension">Extension</label>
            </div>
            <b-form-input
              id="recoveryExtension"
              v-model="userData.recovery_agent[0].extension"
              autofocus
              trim
              :disabled="onlyView"
              class="widthInput"
              v-mask="'/ ######'"
              :state="errors[0] ? false : null"
            />
          </validation-provider>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="4" v-if="userData.recovery_phone.length > 1">
          <b-row v-for="(item, index) in userData.recovery_phone" :key="index">
            <b-col v-if="index > 0" class="mt-1">
              <div class="d-flex justify-content-between">
                <label for="recoveryPhone">Phone</label>
                <b-button
                  variant="outline"
                  v-b-tooltip.hover-right="'Delete Recovery Phone'"
                  class="p-0 m-0"
                  :disabled="onlyView"
                  @click="deleteInput(1, index)"
                >
                  <feather-icon
                    icon="XCircleIcon"
                    size="18"
                    class="text-danger"
                  />
                </b-button>
              </div>
              <validation-provider
                v-slot="{ errors }"
                rules="mobile|required"
                tag="div"
              >
                <b-form-input
                  id="recoveryPhone"
                  v-model="userData.recovery_phone[index].phone"
                  autofocus
                  trim
                  :disabled="onlyView"
                  :state="errors[0] ? false : null"
                  class="widthInput"
                  v-mask="'(###) ###-####'"
                />
              </validation-provider>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="4" v-else></b-col>

        <b-col cols="8" v-if="userData.recovery_agent.length > 1">
          <b-row v-for="(item, index) in userData.recovery_agent" :key="index">
            <b-col cols="6" v-if="index > 0" class="mt-1">
              <div class="d-flex justify-content-between">
                <label for="recoveryAgent">Agent</label>
                <b-button
                  variant="outline"
                  v-b-tooltip.hover-right="'Delete Recovery Agent'"
                  class="p-0 m-0"
                  :disabled="onlyView"
                  @click="deleteInput(5, index)"
                >
                  <feather-icon
                    icon="XCircleIcon"
                    size="18"
                    class="text-danger"
                  />
                </b-button>
              </div>
              <validation-provider
                v-slot="{ errors }"
                rules="min:2|max:255|required"
                tag="div"
              >
                <b-form-input
                  id="recoveryAgent"
                  v-model="userData.recovery_agent[index].agent"
                  autofocus
                  trim
                  :disabled="onlyView"
                  class="widthInput"
                  maxlength="255"
                  :state="errors[0] ? false : null"
                />
              </validation-provider>
            </b-col>

            <b-col cols="6" v-if="index > 0" class="mt-1">
              <div class="d-flex justify-content-between">
                <label for="recoveryExtension">Extension</label>
              </div>
              <validation-provider
                v-slot="{ errors }"
                rules="min:2"
                tag="div"
              >
                <b-form-input
                  id="recoveryExtension"
                  v-model="userData.recovery_agent[index].extension"
                  autofocus
                  trim
                  :disabled="onlyView"
                  class="widthInput"
                  v-mask="'/ ######'"
                  :state="errors[0] ? false : null"
                />
              </validation-provider>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col cols="4">
          <validation-provider rules="mobile" v-slot="{ errors }">
            <div class="d-flex justify-content-between">
              <label>Fax</label>
              <b-button
                variant="outline"
                class="p-0 paddingB"
                :disabled="isDisabledFax || onlyView"
                @click="addInput(2)"
              >
                <feather-icon
                  icon="PlusSquareIcon"
                  size="18"
                  class="mr-50 text-primary"
                />
              </b-button>
            </div>
            <template v-if="userData.recoveryFax.length > 0">
              <b-form-input
                id="recoveryFax"
                v-model="userData.recoveryFax[0].fax"
                name="recoveryFax"
                class="widthInput"
                autofocus
                :disabled="onlyView"
                v-mask="'(###) ###-####'"
                :state="errors[0] ? false : null"
              />
            </template>
          </validation-provider>

          <div v-if="userData.recoveryFax.length > 1">
            <validation-provider rules="mobile" v-slot="{ errors }">
              <b-row v-for="(item, index) in userData.recoveryFax" :key="index">
                <b-col class="mt-1 ml-1 p-0" v-if="index > 0">
                  <div class="d-flex justify-content-between">
                    <b-form-input
                      id="fax"
                      v-model="item.fax"
                      name="fax"
                      autofocus
                      trim
                      :disabled="onlyView"
                      v-mask="'(###) ###-####'"
                      :state="errors[0] ? false : null"
                    />
                    <div class="paddingIconoX">
                      <b-button
                        variant="outline"
                        class="p-0 m-0"
                        :disabled="onlyView"
                        @click="deleteInput(2, index)"
                      >
                        <feather-icon
                          icon="XCircleIcon"
                          size="18"
                          class="text-danger"
                        />
                      </b-button>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </validation-provider>
          </div>
        </b-col>

        <b-col cols="8">
          <validation-provider rules="email|max:255" v-slot="{ errors }">
            <div class="d-flex justify-content-between">
              <label>Email</label>

              <b-button
                variant="outline"
                class="p-0 paddingB"
                :disabled="isDisabledEmail || onlyView"
                @click="addInput(3)"
              >
                <feather-icon
                  icon="PlusSquareIcon"
                  size="18"
                  class="mr-50 text-primary"
                />
              </b-button>
            </div>
            <template v-if="userData.recoveryEmail.length > 0">
              <div>
                <b-form-input
                  id="email"
                  v-model="userData.recoveryEmail[0].email"
                  name="email"
                  autofocus
                  trim
                  :disabled="onlyView"
                  :state="errors[0] ? false : null"
                  class="widthInput"
                />
              </div>
            </template>
          </validation-provider>

          <div v-if="userData.recoveryEmail.length > 1">
            <validation-provider rules="email|max:255" v-slot="{ errors }">
              <b-row v-for="(item, index) in userData.recoveryEmail" :key="index">
                <b-col class="mt-1 ml-1 p-0" v-if="index > 0">
                  <div class="d-flex justify-content-between">
                    <b-form-input
                      v-model="item.email"
                      name="email"
                      autofocus
                      trim
                      :disabled="onlyView"
                      :state="errors[0] ? false : null"
                    />
                    <div class="paddingIconoX">
                      <b-button
                        variant="outline"
                        class="p-0 m-0"
                        :disabled="onlyView"
                        @click="deleteInput(3, index)"
                      >
                        <feather-icon
                          icon="XCircleIcon"
                          size="18"
                          class="text-danger"
                        />
                      </b-button>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </validation-provider>
          </div>
        </b-col>

        <b-col cols="6" class="mt-1">
          <b-form-group label="% Min of settlement" label-for="settlement">
            <b-form-input
              id="settlement"
              type="number"
              v-model="userData.settlement"
              name="settlement"
              autofocus
              :disabled="onlyView"
              v-mask="'##.##'"
              trim
            />
          </b-form-group>
        </b-col>
        <b-col cols="2" class="mt-1">
          <b-form-group label="Settle with A.M.G" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              class="paddingT d-flex"
              :options="options"
              :disabled="onlyView"
              v-model="userData.settle"
              :aria-describedby="ariaDescribedby"
            >
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </validation-observer>
</template>
  <script>
import { VueAutosuggest } from "vue-autosuggest";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { ValidationObserver } from "vee-validate";
import CreditorsService from "../../service/creditors.service";
import { required, email } from "@validations";
import { mapGetters } from "vuex";
export default {
  props: {
    userData: {
      type: Object,
      required: true,
    },
    onlyView: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ValidationObserver,
    VueAutosuggest,
    VueGoogleAutocomplete,
  },
  data() {
    return {
      required,
      email,
      dataCreditor: [],
      filteredOptions: [],
      isDisabledFax: false,
      isDisabledEmail: false,
      isDisabledPhoneCustomer: false,
      showFax: false,
      showEmail: false,
      showPhoneCustomer: false,
      dataPhone: [],
      dataFax: [],
      dataEmail: [],
      options: [
        { text: "Yes", value: "1" },
        { text: "No", value: "2" },
      ],
    };
  },
  mounted() {},
  methods: {
    addInput(data) {
      switch (data) {
        case 1:
          this.userData.recovery_phone.push({ id: 0, phone: "" });
          break;
        case 2:
          this.showFax = true;
          this.userData.recoveryFax.push({ fax: "" });
          if (this.userData.recoveryFax.length == 5) {
            this.isDisabledFax = true;
          }
          break;
        case 3:
          this.showEmail = true;
          this.userData.recoveryEmail.push({ email: "" });
          if (this.userData.recoveryEmail.length == 5) {
            this.isDisabledEmail = true;
          }
          break;
        case 4:
          this.showPhoneCustomer = true;
          this.userData.phone.push({ phone: "" });
          if (this.userData.phone.length == 5) {
            this.isDisabledPhoneCustomer = true;
          }
          break;
        case 5:
          this.userData.recovery_agent.push({
            id: 0,
            agent: "",
            extension: "",
          });
          break;
        default:
          break;
      }
    },
    deleteInput(data, index) {
      switch (data) {
        case 1:
          for (var i = 0; i < this.userData.recovery_phone.length; i++) {
            if (i == index) {
              if (this.userData.recovery_phone[i].id > 0) {
                this.userData.recovery_phone_delete.push({
                  id: this.userData.recovery_phone[i].id,
                });
              }
              this.userData.recovery_phone.splice(i, 1);
            }
          }
          break;
        case 2:
          this.isDisabledFax = false;
          for (var i = 0; i < this.userData.recoveryFax.length; i++) {
            if (i == index) {
              this.userData.recoveryFax.splice(i, 1);
            }
          }
          break;
        case 3:
          this.isDisabledEmail = false;
          for (var i = 0; i < this.userData.recoveryEmail.length; i++) {
            if (i == index) {
              this.userData.recoveryEmail.splice(i, 1);
            }
          }
          break;
        case 4:
          this.isDisabledPhoneCustomer = false;
          for (var i = 0; i < this.userData.phone.length; i++) {
            if (i == index) {
              this.userData.phone.splice(i, 1);
            }
          }
          break;
        case 5:
          for (var i = 0; i < this.userData.recovery_agent.length; i++) {
            if (i == index) {
              if (this.userData.recovery_agent[i].id > 0) {
                this.userData.recovery_agent_delete.push({
                  id: this.userData.recovery_agent[i].id,
                });
              }
              this.userData.recovery_agent.splice(i, 1);
            }
          }
          break;
        default:
          break;
      }
    },
    async validate(){
      return await this.$refs.recoveryCreditorObserver.validate();
    }
  },
  computed: {
    ...mapGetters({
      G_STATUS_EEUU: "DebtSolutionCreditors/G_STATUS_EEUU",
      G_COUNTRIES: "DebtSolutionCreditors/G_COUNTRIES",
    }),
  },
};
</script>
  <style lang="scss">
.paddingB {
  padding-bottom: 4px !important;
}
.paddingT {
  padding-top: 4px !important;
}
.ppaddingTP {
  padding-top: 23px !important;
}
.paddingIconoX {
  padding-top: 8px !important;
  padding-left: 3px !important;
}
.widthInput {
  width: 96%;
}
.widthInputPhone {
  width: 80%;
}
</style>
  