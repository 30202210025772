var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"title":"PENDING E-CHECKS","hide-footer":"","size":"xlg"},on:{"hidden":_vm.closeModal},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('b-table',{staticClass:"position-relative table-new-customization",attrs:{"items":_vm.items,"fields":_vm.fields,"responsive":"","show-empty":"","sticky-header":"70vh"},scopedSlots:_vm._u([{key:"cell(check_details)",fn:function(ref){
var item = ref.item;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"10"}},[_c('strong',[_vm._v("Pay to: ")]),_vm._v(_vm._s(item.vendor))]),(_vm.isCeo && item.status_check == 'PENDING')?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right.v-warning",value:('Edit vendor'),expression:"'Edit vendor'",modifiers:{"hover":true,"right":true,"v-warning":true}}],staticClass:"text-warning clickable",attrs:{"icon":"EditIcon"},on:{"click":function($event){return _vm.openVendorConceptEditorModal(item, 'vendor')}}}):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"cols":"10"}},[_c('strong',[_vm._v("Memo: ")]),_vm._v(_vm._s(item.concept))]),(_vm.isCeo && item.status_check == 'PENDING')?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right.v-warning",value:('Edit concept'),expression:"'Edit concept'",modifiers:{"hover":true,"right":true,"v-warning":true}}],staticClass:"text-warning clickable",attrs:{"icon":"EditIcon"},on:{"click":function($event){return _vm.openVendorConceptEditorModal(item, 'concept')}}}):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"cols":"10"}},[_c('strong',[_vm._v("Date: ")]),_vm._v(" "+_vm._s(_vm._f("myGlobal")(item.payment_date)))])],1),(item.url_file)?_c('b-row',[_c('b-col',{attrs:{"cols":"10"}},[_c('strong',[_vm._v("File: ")]),_c('a',{attrs:{"href":item.url_file,"target":"_blank","title":item.file}},[_c('amg-icon',{staticClass:"cursor-pointer text-danger",attrs:{"icon":"FilePdfIcon","size":"18"}})],1)]),_c('b-col',{staticClass:"m-0 p-0"})],1):_vm._e()]}},{key:"cell(amount)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatMoney")(item.amount))+" ")]}},{key:"cell(type)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{staticClass:"px-1",class:item.type == 'CLIENT' ? 'clickable' : '',attrs:{"variant":item.type == 'CLIENT' ? 'primary' : 'info'},on:{"click":function($event){item.type == 'CLIENT' && _vm.openClientExpenses(item)}}},[_vm._v(" "+_vm._s(item.type)+" "+_vm._s(item.total_clients > 0 ? ("(" + (item.total_clients) + ")") : null)+" ")])]}},{key:"cell(status_check)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.colorText(item)},[_vm._v(_vm._s(_vm.statusCheck(item)))])]}},{key:"cell(module)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(item.module_name),expression:"item.module_name",modifiers:{"hover":true,"left":true}}],attrs:{"src":("/assets/" + (item.icons)),"alt":"Logo","height":"30","width":"30"}})])]}},{key:"cell(check_number)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.check_number || "-"))])]}},{key:"cell(requested_by)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(item.requested_by_id == 0 ? "System" : item.requested_by))]),_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(_vm._f("myGlobalDay")(item.requested_at))+" ")])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [(item.status_check == 'PENDING COLLECTION')?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Collected'),expression:"'Collected'",modifiers:{"hover":true,"top":true}}],staticClass:"text-success clickable",attrs:{"icon":"DollarSignIcon","size":"22"},on:{"click":function($event){return _vm.confirmCheck(item)}}}):_vm._e(),(item.status_check == 'PENDING COLLECTION')?_c('tabler-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Not collected'),expression:"'Not collected'",modifiers:{"hover":true,"top":true}}],staticClass:"text-danger clickable",attrs:{"icon":"CircleXIcon","size":"22"},on:{"click":function($event){return _vm.openObservationsModal(item, 'not collected')}}}):_vm._e()]}},{key:"cell(tracking)",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:('Tracking'),expression:"'Tracking'",modifiers:{"hover":true,"right":true}}],staticClass:"text-info cursor-pointer",attrs:{"size":"16","icon":"ListIcon"},on:{"click":function($event){return _vm.openChecksTrackingModal(item)}}})],1)]}}])})],1),(_vm.showObservationsModal)?_c('ObservationsModal',{attrs:{"expenseData":_vm.selectedItem},on:{"close":function($event){_vm.showObservationsModal = false},"save":_vm.annulCheck}}):_vm._e(),(_vm.showChecksTrackingModal)?_c('ChecksTrackingModal',{attrs:{"dataRow":_vm.selectedCheck},on:{"close":function($event){_vm.showChecksTrackingModal = false}}}):_vm._e(),(_vm.showConfirmCheck)?_c('ConfirmEcheck',{attrs:{"checkData":_vm.selectedCheck},on:{"close":function($event){_vm.showConfirmCheck = false},"reload":_vm.refresh}}):_vm._e(),(_vm.clientExpenseModal.show)?_c('client-expense-request',{attrs:{"expenseDepartment":_vm.clientExpenseModal.expenseDepartment},on:{"close":function($event){_vm.clientExpenseModal.show = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }