export default [
  {
    key: 'lead_full_name',
    label: 'Client',
    // thStyle: { width: '33%' },
    thClass: 'text-left',
    tdClass: 'text-left',
    visible: true,
  },
  {
    key: 'account',
    label: 'Account',
    // thStyle: { width: '33%' },
    thClass: 'text-left',
    tdClass: 'text-left',
    visible: false,
  },
  {
    key: 'status',
    label: 'Status',
    class: 'text-center',
    visible: false,
  },
  {
    key: 'modulo',
    label: 'Module',
    // thStyle: { width: '33%' },
    thClass: 'text-left',
    tdClass: 'text-left',
    visible: true,
  },
  {
    key: 'created_by',
    label: 'Requested By',
    // thStyle: { width: '33%' },
    thClass: 'text-left',
    tdClass: 'text-left',
    visible: true,
  },
  {
    key: 'completed_by',
    label: 'Completed by',
    // thStyle: { width: '33%' },
    thClass: 'text-left',
    tdClass: 'text-left',
    visible: true,
  },
];
