var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modalNewRecord",attrs:{"title":("New " + (!_vm.typeModalRecord ? 'Cash Record' : 'Record')),"title-tag":"h3","modal-class":"modal-primary","size":"xmd","scrollable":"","no-close-on-backdrop":"","hide-footer":""},on:{"hidden":_vm.close}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"id":"input-group-1","label":"Client Name","label-for":"input-1","description":"enter client name if you want to search by client name"}},[_c('b-form-input',{attrs:{"id":"input-1","placeholder":"Enter client name","required":""},model:{value:(_vm.clientName),callback:function ($$v) {_vm.clientName=$$v},expression:"clientName"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"id":"input-group-2","label":"Account Number","label-for":"input-2","description":"enter account number if you want to search by account number"}},[_c('b-form-input',{attrs:{"id":"input-2","placeholder":"Enter account number","required":""},model:{value:(_vm.accountNumber),callback:function ($$v) {_vm.accountNumber=$$v},expression:"accountNumber"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('b-button',{staticClass:"mt-2",attrs:{"variant":"primary","block":""},on:{"click":_vm.searchClient}},[_vm._v("Search")])],1)],1),_c('hr'),_c('b-card',{staticClass:"card-details",style:(_vm.isDarkSkin ? 'background-color: #262626 !important' : '')},[_c('ValidationObserver',{ref:"formOther"},[_c('b-card-title',[_vm._v(" New Record for "),_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.nameClientSelected || "No client found"))])]),(_vm.typeModalRecord)?_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',[_vm._v(" AMOUNT ")])]},proxy:true}],null,true)},[_c('money',_vm._b({staticClass:"form-control",model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}},'money',{
                  decimal: '.',
                  thousands: ',',
                  prefix: '$',
                  precision: 2,
                },false))],1),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,false,2183945148)})],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[(!_vm.isClient)?_c('validation-provider',{attrs:{"name":"program","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',{staticClass:"title_lable"},[_vm._v("PROGRAM ")])]},proxy:true}],null,true)},[_c('b-select',{attrs:{"name":"program","options":_vm.optionsPrograms,"value-field":"value","text-field":"label"},model:{value:(_vm.programSelected),callback:function ($$v) {_vm.programSelected=$$v},expression:"programSelected"}})],1),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,false,3980154199)}):_vm._e()],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',{staticClass:"title_lable"},[_vm._v("TYPE ")])]},proxy:true}],null,true)},[_c('b-select',{attrs:{"name":"type","options":_vm.optionsMethods,"value-field":"value","text-field":"label"},model:{value:(_vm.methodSelected),callback:function ($$v) {_vm.methodSelected=$$v},expression:"methodSelected"}})],1),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,false,2254184311)})],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"serial_number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',{staticClass:"title_lable"},[_vm._v("SERIAL NUMBER ")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"name":"serial_number","maxlength":"15"},on:{"input":function($event){_vm.serialNumber = _vm.serialNumber.toUpperCase()}},model:{value:(_vm.serialNumber),callback:function ($$v) {_vm.serialNumber=$$v},expression:"serialNumber"}})],1),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,false,1909304908)})],1)],1):_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',[_vm._v(" AMOUNT ")])]},proxy:true}],null,true)},[_c('money',_vm._b({staticClass:"form-control",model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}},'money',{
                  decimal: '.',
                  thousands: ',',
                  prefix: '$',
                  precision: 2,
                },false))],1),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[(!_vm.isClient)?_c('validation-provider',{attrs:{"name":"program","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',{staticClass:"title_lable"},[_vm._v("PROGRAM ")])]},proxy:true}],null,true)},[_c('b-select',{attrs:{"name":"program","options":_vm.optionsPrograms,"value-field":"value","text-field":"label"},model:{value:(_vm.programSelected),callback:function ($$v) {_vm.programSelected=$$v},expression:"programSelected"}})],1),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,false,3980154199)}):_vm._e()],1)],1)],1),_c('b-button',{staticClass:"float-right",attrs:{"variant":"success","disabled":_vm.disabledBtn},on:{"click":_vm.createRecord}},[_vm._v(" CREATE RECORD ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }