<template>
  <div class="message-status">
    <b-avatar
      v-if="isRead && !isUnsupported"
      :size="handleMessage.size"
      :variant="handleMessage.variant"
      :src="senderAvatar"
      v-b-tooltip.hover
      :title="handleMessage.title"
      class="cursor-info"
      :icon="handleMessage.icon"
    />
    <icon-delivered
      v-else-if="isDelivered && !isUnsupported"
      :size="handleMessage.size"
      v-b-tooltip.hover
      :title="handleMessage.title"
    />
    <tabler-icon
      v-else
      :icon="handleMessage.icon"
      :size="`${handleMessage.size}`"
      class="cursor-info"
      :class="handleMessage.variant"
      v-b-tooltip.hover
      :title="handleMessage.title"
    />
  </div>
</template>
<script>
import IconDelivered from "@/views/commons/Messenger/components/messenger/messages/components/icons/IconDelivered.vue";
export default {
  name: "MessageStatus",
  components: {
    IconDelivered,
  },
  props: {
    message: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    senderAvatar: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    isUnsupported() {
      return this.message.type === "Unsupported";
    },
    isRead() {
      return this.message.messageStatus === "Read";
    },
    isDelivered() {
      return this.message.messageStatus === "Delivered";
    },
    handleMessage() {
      const messageStatus = this.message.messageStatus;
      const types = {
        Read: {
          icon: "person-fill",
          size: 14,
          variant: "light-secondary",
          title: messageStatus,
          status: "Read",
        },
        Error: {
          icon: "AlertCircleIcon",
          size: 14,
          variant: "text-danger",
          title: this.message.messageError,
          status: "Error",
        },
        Sent: {
          icon: "CircleCheckIcon",
          size: 14,
          variant: "",
          title: messageStatus,
          status: "Sent",
        },
        Delivered: {
          icon: "CircleCheckFilledIcon",
          size: 14,
          variant: "",
          title: messageStatus,
          status: "Delivered",
        },
        Unsupported: {
          icon: "InfoCircleIcon",
          size: 14,
          variant: "text-info",
          title:
            "This message is not compatible with our platform and can only be viewed in the Meta Business app.",
          status: messageStatus,
        },
      };
      if (this.isUnsupported) {
        return types.Unsupported;
      }
      return types[messageStatus];
    },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss">
.message-status {
  position: relative;
  top: -0.2rem;
}
</style>