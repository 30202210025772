<template>
  <div
    class="dashboard__card"
    :class="isDarkSkin ? 'dashboard__card--dark' : ''"
  >
    <div class="card-charge">
      <div class="card-charge__header">
        <div class="charge-logo">
          <img
            :src="imgServices"
            alt="img_services"
          >
        </div>
        <h2
          class="title"
          :class="isDarkSkin ? 'title--dark' : ''"
        >
          Clients Without Payment
        </h2>
      </div>
      <div class="card-charge__body">
        <div
          v-for="(item, i) in missingMonthlyPayments"
          :key="i"
          class="charge"
          :class="isDarkSkin ? 'charge__dark' : ''"
          @click="item.total_no_payments != 0 ? ModalClientsNoPayment(item) : null"
        >
          <div class="charge__programs">
            <img
              :src="imgPrograms(item.program_id)"
              alt="img_services"
            >
          </div>
          <div class="charge__details">
            <div
              class="charge__details--program"
              :class="isDarkSkin ? 'subtitle-dark' : ''"
            >{{ item.program_name }}
            </div>
            <div
              class="value"
              style="font-size: 14px; font-weight: 600; color: rgb(191, 51, 51)"
            >{{ item.total_no_payments == 0 ? '$ ' : '$ -' }}{{ item.total_no_payments }}
            </div>
          </div>
        </div>

      </div>

      <detail-clients-no-payment
        v-if="showModalClientsNoPayment"
        :send-year="dataYear"
        :send-month="dataMonth"
        :send-programs-id="idProgram"
        :send-programs="arrPrograms"
        @close="showModalClientsNoPayment = false"
      />
    </div>
  </div>
</template>
<script>
import DashboardServive from '@/views/ceo/service-dashboard/dashboard.service';
import DetailClientsNoPayment from '@/views/ceo/dashboard/modals/clients-no-payment/DetailClientsNoPayment.vue';

export default {
  components: {
    DetailClientsNoPayment,
  },
  props: {
    selectYear: {
      type: Number,
      default: null,
      required: false,
    },
    selectMonth: {
      type: Number,
      default: null,
      required: false,
    },
    listPrograms: {
      type: Array,
      required: false,
      default: [],
    },
  },
  data() {
    return {
      imgServices: require('@/assets/images/icons/dashboard-ceo/services.svg'),
      imgDebtSolution: require('@/assets/images/icons/dashboard-ceo/logo-debt-solution.svg'),
      imgBoostCredit: require('@/assets/images/icons/dashboard-ceo/logo-boost-credit.svg'),
      imgCreditExperts: require('@/assets/images/icons/dashboard-ceo/logo-credit-experts.svg'),
      imgBusiness: require('@/assets/images/icons/dashboard-ceo/logo-business.svg'),
      imgTaxResearch: require('@/assets/images/icons/dashboard-ceo/logo-tax-research.svg'),
      imgSpecialist: require('@/assets/images/icons/dashboard-ceo/logo-specialist.svg'),
      imgKeyBook: require('@/assets/images/icons/dashboard-ceo/logo-keybook.svg'),
      value: 75,
      showModalClientsNoPayment: false,
      missingMonthlyPayments: [],
      arrPrograms: [],
      idProgram: null,
    };
  },

  watch: {
    selectMonth(newVal) {
      this.dataMonth = newVal;
      this.getMissingMonthlypayments();
    },
    selectYear(newVal) {
      this.dataYear = newVal;
      this.getMissingMonthlypayments();
    },

  },
  async created() {
    this.dataMonth = this.selectMonth;
    this.dataYear = this.selectYear;
    await this.getMissingMonthlypayments();
  },

  methods: {
    ModalClientsNoPayment(item) {
      this.arrPrograms = this.listPrograms;
      this.idProgram = item.program_id;
      this.showModalClientsNoPayment = true;
    },
    async getMissingMonthlypayments() {
      try {
        const params = {
          month: this.dataMonth,
          year: this.dataYear,
        };
        const { data } = await DashboardServive.getMissingMonthlypayments(params);
        this.missingMonthlyPayments = data;
      } catch (error) {
        console.log(error);
      }
    },
    imgPrograms(program_id) {
      switch (program_id) {
        case 1:
          return this.imgBusiness;
        case 2:
          return this.imgBoostCredit;
        case 3:
          return this.imgCreditExperts;
        case 4:
          return this.imgDebtSolution;
        case 5:
          return this.imgTaxResearch;
        case 7:
          return this.imgSpecialist;
        case 8:
          return this.imgKeyBook;
        default:
          return this.imgKeyBook;
          break;
      }
    },

  },

};
</script>
  <style lang="scss" scoped>
  $color-primary: #5F5F5F;
  $color-secondary: #FFFFFF;
  $bg-card-white: #FFFFFF;
  $bg-card-dark: #111113;
  $box-shadow-white: #00000026;

  .dashboard__card {
    box-shadow: 0px 0px 15px 0px $box-shadow-white;
    border-radius: 15px;
    margin-bottom: 27px;
    padding: 18px;
    margin-top: 0px !important;
    background-color: $bg-card-white;

    &--dark {
      background-color: $bg-card-dark;
      box-shadow: 0px 0px 22.935px 0px rgba(255, 249, 249, 0.1);

    }

    .card-charge {

      &__header {
        display: flex;
        padding-left: 8px;

        .charge-logo {
          margin-right: 5px;
          width: 43px;
          height: 43px;
          border-radius: 10px;
          background-color: #A7C5FF;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 32px;
            height: 32px;
          }

        }

        .title {
          display: flex;
          margin: 0px;
          justify-content: center;
          align-items: center;
          color: #3D3D3D;
          font-family: Montserrat;
          font-size: 1.3rem;
          font-style: normal;
          font-weight: 500;
          line-height: 122.25%;
          margin-left: 5px;

          &--dark {
            color: $color-secondary;
          }

        }

      }

      &__body {
        .charge {
          display: flex;
          margin: 10px auto;
          padding: 6px 8px;
          border-radius: 12px;

          &:hover {
            background-color: #eeeeee;
            cursor: pointer;
          }

          &__programs {
            width: 47px;
            height: 40px;
            background-color: #E9E9E9;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 35px;
              height: 35px;
            }

          }

          &__details {
            display: flex;
            justify-content: space-between;
            padding-top: 10px;
            width: 100%;
            margin: 0px 13px;

            &--program {
              margin: 0px;
              color: #5F5F5F;
              font-family: Montserrat;
              font-size: 1.1rem;
              font-style: normal;
              font-weight: 500;
              margin-bottom: 5px;

              .value {
                font-weight: 700;
                margin-left: 3px;
                margin-right: 3px;

                &::before {
                  content: "$"
                }
              }

            }

            .subtitle-dark {
              color: #B4B4B4;
            }

          }

        }

        .charge__dark:hover {
            background-color: #1b1b1b;

          }
      }

    }

  }
  </style>
