<template>
  <div>
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="refreshTable"
      class="filter-slot-new-customization"
      :top-paginate="false"
      paginate-left
      :input-width="60"
    >
      <template #buttons>
        <b-button
          v-if="isCorrespondence && isTabMoneyOrder"
          variant="warning"
          class="ml-1"
          :disabled="selected.length === 0"
          @click="openLinkDepositModal"
        >
          ASSIGN DEPOSIT
        </b-button>
        <b-button
          v-if="isCorrespondence"
          variant="success"
          class="ml-1"
          @click="openModalRecord()"
        >
          {{ `CREATE ${isTabMoneyOrder ? "RECORD" : "CASH RECORD"}` }}
        </b-button>
      </template>
      <template #table>
        <b-table
          class="blue-scrollbar position-relative table-new-customization"
          slot="table"
          ref="refOtherPayments"
          no-provider-filtering
          :items="myProvider"
          :fields="visibleFields"
          primary-key="id"
          responsive="sm"
          table-class="text-nowrap"
          show-empty
          small
          :sticky-header="statusModal ? '50vh' : '70vh'"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #head(selected) v-if="isTabMoneyOrder">
            <b-form-checkbox v-model="selectAll" @input="selectedAll" />
          </template>

          <template v-slot:cell(selected)="row">
            <b-form-group v-if="isTabMoneyOrder">
              <b-form-checkbox
                v-model="row.item.selected"
                @input="selectedRow(row.item)"
                :disabled="row.item.deposit_id !== null"
              />
            </b-form-group>
          </template>

          <template #cell(client_name)="{ item }">
            <router-link
              class="text-primary font-weight-bolder"
              v-if="item.client_name"
              target="_blank"
              :to="{
                name: pathDashboard,
                params: { idClient: `${item.client_account_id}` },
              }"
            >
              {{ item.client_name }}
              <div>{{ item.account }}</div>
            </router-link>
            <router-link
              v-else
              class="text-warning font-weight-bolder"
              target="_blank"
              :to="{
                name: 'management-lead-show',
                params: { id: item.lead_id },
              }"
            >
              {{ item.lead_name }} <br />
              ID: {{ item.lead_id }}
            </router-link>
          </template>

          <template #cell(client_type)="{ item }">
            <b-badge
              :variant="item.lead_name ? 'light-warning' : 'light-success'"
              >{{ item.lead_name ? "CRM" : "CLIENT" }}
            </b-badge>
          </template>

          <template #cell(transaction_id)="{ item }">
            <div><b>METHOD:</b> {{ item.method }}</div>
            <div>
              <b>CONCEPT:</b>
              {{
                item.modality === "Monthly" ? "Monthly Payment" : item.modality
              }}
            </div>
            <div><b>TRANSACTION ID:</b> {{ item.voucher_id }}</div>
          </template>

          <template #cell(serial_number)="{ item }">
            <div><b>NUMBER:</b> {{ item.serial_number }}</div>
            <div><b>DATE:</b> {{ item.serial_date | myGlobalDay }}</div>
            <div><b>HOLDER:</b> {{ item.owner ? item.owner : "-" }}</div>
          </template>

          <template #cell(amount)="{ item }">
            <span class="text-success">$ {{ item.amount | currency }}</span>
          </template>

          <template #cell(created)="{ item }">
            <span>{{ item.created_by }}</span>
            <br />
            <span>{{ item.created_at | myGlobalDay }}</span>
          </template>

          <template #cell(received)="{ item }">
            <feather-icon
              icon="CheckCircleIcon"
              class="text-success"
              v-if="item.status_confirmation == 3"
            />
            <feather-icon
              icon="XCircleIcon"
              class="text-danger"
              v-else-if="item.status_confirmation == 4"
            />
          </template>

          <template #cell(deposit_number)="data">
            {{ data.item.deposit_number }}
          </template>

          <template #cell(amount_deposit)="data">
            <span class="text-primary"
              >$ {{ data.item.amount_deposit | currency }}</span
            >
          </template>

          <template #cell(date_deposit)="data">
            {{ data.item.date_deposit | myGlobal }}
          </template>

          <template #cell(status_confirmation)="{ item }">
            <div class="d-flex align-items-center flex-column">
              <b-badge
                :variant="validateStatus(item.status_confirmation).variant"
              >
                {{ validateStatus(item.status_confirmation).text }}
              </b-badge>
              <b-form-textarea
                v-if="item.reject_motive"
                v-model="item.reject_motive"
                plaintext
                disabled
                class="text-area-reject"
                :style="
                  isDarkSkin
                    ? 'color: #d8d8d8 !important; background-color: rgb(203 202 202 / 21%) !important'
                    : 'color: #6e6b7b !important;background-color: rgb(203 202 202 / 21%) !important'
                "
              />
            </div>
          </template>

          <template #cell(evidence)="{ item }">
            <feather-icon
              v-if="item.evidence_path || item.deposit_path"
              icon="FileIcon"
              class="text-primary clickable"
              size="18"
              @click="showEvidence(item)"
            />
          </template>
          <template #cell(tracking)="{ item }">
            <feather-icon
              class="mr-1 pointer text-primary"
              icon="ListIcon"
              size="18"
              v-b-tooltip
              title="Tracking"
              @click="showTracking(item)"
            />
          </template>

          <template #cell(actions)="{ item }">
            <div class="d-flex justify-content-center">
              <!-- Button for generate voucher -->
              <tabler-icon
                icon="FileCheckIcon"
                class="clickable"
                :class="item.voucher_id ? 'text-primary' : 'text-success'"
                size="22"
                v-b-tooltip.hover.top="'Generate Voucher'"
                @click="generateVoucher(item)"
                v-if="isTabCash && isCorrespondence"
              />

              <!-- Button for send management -->
              <tabler-icon
                icon="SendIcon"
                class="text-success clickable ml-1"
                size="22"
                v-b-tooltip.hover.top="'Send Management'"
                @click="sendManagement(item)"
                v-if="
                  item.voucher_id &&
                  isTabCash &&
                  statusTab == 1 &&
                  !isManagement
                "
              />

              <!-- Button for approve or reject -->
              <tabler-icon
                icon="ThumbUpIcon"
                class="text-success clickable"
                size="22"
                v-b-tooltip.hover.top="'Approve'"
                @click="approveOrReject(item, 1)"
                v-if="statusTab == 1 && isManagement"
              />
              <tabler-icon
                icon="ThumbDownIcon"
                class="text-danger clickable ml-1"
                size="22"
                v-b-tooltip.hover.top="'Reject'"
                @click="openModalReject(item)"
                v-if="statusTab == 1 && isManagement"
              />
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <modal-new-record
      v-if="showModalRecord"
      @close="showModalRecord = false"
      :typeModalRecord="typeModalRecord"
      @refresh="
        showModalRecord = false;
        refreshTable();
      "
    />

    <deposit-link-modal
      v-if="linkDepositModalController"
      :payments="pays"
      @close="closeLinkDepositModal"
      @refresh="refreshTable"
    />

    <modal-voucher
      v-if="showModalVoucher"
      @hide="showModalVoucher = false"
      @refreshTable="refreshTable"
      :dataClient="dataClient"
    ></modal-voucher>
    <tracking-other-payment
      v-if="showModalTracking"
      :dataClient="dataClient"
      @close="showModalTracking = false"
    />
    <cool-light-box
      :items="setImages"
      :index="indexImage"
      @close="indexImage = null"
    />
    <modal-reject
      v-if="showModalReject"
      @close="showModalReject = false"
      @reject="saveRejectMotive"
      :transaction-data="transactionData"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import fields from "@/views/commons/components/other-payments/data/fields";
import filters from "@/views/commons/components/other-payments/data/filters.js";
import OtherPaymentsService from "@/views/commons/components/other-payments/service/other-payment.service";
import DepositLinkModal from "@/views/commons/components/other-payments/view/modal/DepositLinkModal.vue";
import ModalVoucher from "@/views/commons/components/other-payments/view/modal/ModalVoucher.vue";
import TrackingOtherPayment from "@/views/commons/components/other-payments/view/modal/TrackingOtherPayment.vue";
import ModalNewRecord from "@/views/commons/components/other-payments/view/modal/ModalNewRecord.vue";
import ModalReject from "@/views/commons/components/other-payments/view/modal/ModalReject.vue";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
export default {
  components: {
    DepositLinkModal,
    ModalVoucher,
    CoolLightBox,
    TrackingOtherPayment,
    ModalNewRecord,
    ModalReject,
  },
  data() {
    return {
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      startPage: null,
      endPage: null,
      nextPage: null,
      toPage: null,
      totalRows: 0,
      fields,
      filters,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Account...",
        model: "",
      },
      isBusy: false,
      sortBy: "created_at",
      sortDesc: true,
      payments: [],
      selected: [],
      pays: [],
      selectAll: false,
      linkDepositModalController: false,
      stateModalConfirmPayment: false,
      confirmPaymentData: null,
      typeConfirm: null,
      showModalVoucher: false,
      showModalTracking: false,
      showModalRecord: false,
      dataClient: {},
      setImages: [],
      indexImage: null,
      typeModalRecord: null,
      showModalReject: false,
      rejectMotive: "",
      transactionData: {},
    };
  },
  props: {
    isPendingModal: {
      type: Boolean,
      default: false,
    },
    statusModal: {
      type: Number,
      default: null,
    },
    statusTabModal: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0]?.meta.module;
    },
    isTabDepositZelle() {
      return (
        this.$route.matched[2]?.meta.status == 1 || this.statusTabModal == 1
      );
    },
    isTabCash() {
      return (
        this.$route.matched[2]?.meta.status == 2 || this.statusTabModal == 2
      );
    },
    isTabMoneyOrder() {
      return (
        this.$route.matched[2]?.meta.status == 3 || this.statusTabModal == 3
      );
    },
    isPendingSubTab() {
      let status =
        this.$route.matched[3]?.meta.status == 1 || this.statusModal == 1;
      return status == 1 || this.isPendingModal;
    },
    isCompletedSubTab() {
      return this.$route.matched[3]?.meta.status == 2 || this.statusModal == 2;
    },
    isCorrespondence() {
      return this.$route.matched[0]?.meta.module == 9;
    },
    isManagement() {
      return this.$route.matched[0]?.meta.module == 16 || this.statusTabModal;
    },
    ifOtherModule() {
      return !this.isManagement && !this.isCorrespondence;
    },
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    currentTab() {
      return this.$route.matched[2]?.meta.status || this.statusTabModal;
    },
    statusTab() {
      return this.$route.matched[3]?.meta.status || this.statusModal;
    },
    pathDashboard() {
      let module_modal = this.statusModal ? 16 : this.moduleId;
      const pathName = {
        3: "bussiness-dashboard-clients",
        4: "administration-dashboard",
        5: "debt-solution-dashboard",
        6: "credit-experts-dashboard",
        7: "boost-credit-dashboard",
        8: "tax-research-client-dashboard",
        10: "claim-department-dashboard",
        11: "specialists-client-dashboard",
        14: "bookeeping-dashboard",
        18: "quality-client-dashboard",
        16: "management-client-dashboard",
      };
      return pathName[module_modal];
    },
  },
  created() {
    this.start();
  },
  methods: {
    ...mapActions({
      A_COUNTER_OP: "other-payment-store/A_COUNTER_OP",
    }),
    start() {
      this.filterColumn(
        "selected",
        this.isCorrespondence && this.isTabMoneyOrder && this.isPendingSubTab
      );
      this.filterColumn("evidence", !this.isTabCash);
      this.filterColumn(
        "received",
        this.isTabCash && !this.isPendingSubTab && !this.isManagement
      );

      this.filterColumn(
        "actions",
        (this.isTabDepositZelle || this.isTabMoneyOrder || this.isTabCash) &&
          this.statusTab == 1 &&
          (this.isManagement || this.isCorrespondence)
      );

      this.filterColumn(
        "deposit_number",
        this.isCorrespondence && this.isTabMoneyOrder && this.statusTab == 2
      );
      this.filterColumn(
        "amount_deposit",
        this.isCorrespondence && this.isTabMoneyOrder && this.statusTab == 2
      );
      this.filterColumn(
        "date_deposit",
        this.isCorrespondence && this.isTabMoneyOrder && this.statusTab == 2
      );
      this.filterColumn(
        "status_confirmation",
        (this.isManagement && this.statusTab == 2) ||
          (!this.isManagement && !this.isCorrespondence)
      );
      this.filterColumn(
        "serial_number",
        this.isTabDepositZelle ||
          (this.isCorrespondence && this.isTabMoneyOrder)
      );
    },

    filterColumn(key, tab) {
      let fields = this.fields.find((field) => field.key == `${key}`);
      fields.visible = tab;
    },

    async myProvider(ctx) {
      try {
        let module_modal = this.statusTabModal
          ? this.statusTabModal == 2
            ? 9
            : 16
          : this.moduleId;
        const params = {
          orderBy: 1,
          order: ctx.sortDesc ? "desc" : "asc",
          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
          method: this.currentTab,
          status: this.statusTab,
          module_id: module_modal,
          search: this.filterPrincipal.model,
          from: this.filters[0].model,
          to: this.filters[1].model,
          program_id: this.filters[2].model,
        };
        const { data } = await OtherPaymentsService.getOtherPayment(params);
        const {
          data: otherPayment,
          total,
          from,
          current_page,
          per_page,
          to,
        } = data;
        this.payments = otherPayment;
        this.startPage = from;
        this.paginate.currentPage = current_page;
        this.paginate.perPage = per_page;
        this.totalRows = total;
        this.toPage = to;
        return otherPayment;
      } catch (error) {
        console.log(error);
      }
    },

    selectedRow(data) {
      const index = this.selected.findIndex((select) => select.id === data.id);
      if (data.selected === true && index === -1) this.selected.push(data);
      else if (data.selected === false && index !== -1)
        this.selected.splice(index, 1);
    },

    selectedAll() {
      if (this.selectAll) {
        this.payments = this.payments.filter((item) => item.deposit_id == null);
        this.payments.forEach((item) => (item.selected = true));
      } else {
        this.payments.forEach((item) => (item.selected = false));
      }
    },

    openLinkDepositModal() {
      this.pays = this.selected;

      this.linkDepositModalController = true;
    },

    closeLinkDepositModal() {
      this.linkDepositModalController = false;
    },

    closeModalConfirmPayment() {
      this.stateModalConfirmPayment = false;
    },

    refreshTable() {
      this.$refs["refOtherPayments"].refresh();
    },

    validateStatus(status) {
      const statusMap = {
        1: { variant: "light-primary", text: "PENDING CORRESPONDENCE" },
        2: { variant: "light-warning", text: "PENDING MANAGEMENT" },
        3: { variant: "light-success", text: "APPROVED" },
        4: { variant: "light-danger", text: "REJECTED" },
      };
      return statusMap[status];
    },

    async sendManagement(item) {
      const { isConfirmed } = await this.showConfirmSwal(
        "Are you sure you want to send this payment?",
        "Management department will be able to start rejecting or approving it"
      );
      if (!isConfirmed) return;
      this.addPreloader();
      try {
        await OtherPaymentsService.changeStatusOtherPayment({
          id: item.id,
          module_id: this.moduleId,
          status: 2,
          user_id: this.currentUser.user_id,
        });
        await this.A_COUNTER_OP({ module_id: this.moduleId });
        this.refreshTable();
        this.showSuccessSwal();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    openModalReject(item) {
      this.transactionData = item;
      this.showModalReject = true;
    },
    saveRejectMotive(reason) {
      this.rejectMotive = reason;
      console.log(this.rejectMotive, this.transactionData);
      this.approveOrReject(this.transactionData, 2);
      this.showModalReject = false;
    },
    async approveOrReject(item, status) {
      let isConfirmed = true;
      if (status === 1) {
        let confirm = await this.showConfirmSwal(
          `Are you sure you want to approve this payment?`,
          "The payment will be sent to the client"
        );
        isConfirmed = confirm.isConfirmed;
      }

      if (!isConfirmed) return;
      this.addPreloader();
      try {
        await OtherPaymentsService.changeStatusOtherPayment({
          id: item.id,
          module_id: this.moduleId,
          status: status === 1 ? 3 : 4,
          user_id: this.currentUser.user_id,
          method_id: item.method_id,
          sent_by: item.created_by_id,
          reject_motive: this.rejectMotive,
        });
        await this.A_COUNTER_OP({ module_id: this.moduleId });
        this.$emit("reload");
        this.refreshTable();
        this.showSuccessSwal();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async generateVoucher(item) {
      if (item.voucher_id) return this.openModalVoucher(item);
      const { isConfirmed } = await this.showConfirmSwal(
        "Are you sure you want to generate the voucher?",
        "The voucher will be generated and sent to the client"
      );
      if (!isConfirmed) return;
      this.addPreloader();
      try {
        const { data } = await OtherPaymentsService.generateVoucher({
          id: item.id,
          module_id: item.module_id,
        });
        item.voucher_id = data.voucher_id;
        this.openModalVoucher(item);
        this.showToast(
          "success",
          "top-right",
          "Congratulations",
          "CheckIcon",
          `You've successfully generated the voucher`
        );
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    openModalVoucher(item) {
      this.dataClient = item;
      this.showModalVoucher = true;
    },

    showEvidence(item) {
      const path = item.evidence_path || item.deposit_path;
      console.log(path);
      this.setImages = [];
      this.setImages.push(path);
      this.indexImage = 0;
    },

    showTracking(item) {
      this.dataClient = item;
      this.showModalTracking = true;
    },

    openModalRecord() {
      this.typeModalRecord = this.isTabMoneyOrder;
      this.showModalRecord = true;
    },
  },
};
</script>

<style lang="scss" scoped>
b {
  font-family: sans-serif;
  font-size: 13px;
}

.text-area-reject {
  border-radius: 5px;
  margin-top: 5px;
  padding: 5px 10px;
  max-width: 250px;
  resize: none;
  height: 60px;
}
</style>