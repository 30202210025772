<template>
  <b-modal
    ref="modal-daily-authorize"
    :size="activeTab != 'pending' ? 'xl' : 'sm'"
    @hidden="$emit('hidden')"
    hide-footer
    :title="`Daily Authorize Report - ${activeTab.toUpperCase()}`"
    title-class="h3 text-white font-weight-bolder"
    scrollable
  >
    <filter-slot
      :top-paginate="false"
      class="filter-slot-new-customization"
      :filter="[]"
      :filter-principal="{}"
      :total-rows="rows"
      :paginate="paginate"
      :start-page="from"
      :to-page="to"
      :no-visible-principal-filter="true"
      :show-container-filters-selector="activeTab != 'pending'"
      @onChangeCurrentPage="getDailyAuthorizeReport()"
      @reload="getDailyAuthorizeReport()"
    >
      <template #buttons-filter>
        <b-form-datepicker
          v-model="fromDate"
          class="w-20 mr-1"
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          locale="en-US"
          placeholder="From"
        ></b-form-datepicker>
        <b-form-datepicker
          class="w-20 mr-1"
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          placeholder="To"
          locale="en-US"
          v-model="toDate"
        ></b-form-datepicker>

        <b-button
          @click="getDailyAuthorizeReport"
          variant="primary"
          class="mr-1"
          style="border-radius: 5px !important"
        >
          Apply
        </b-button>
        <b-button
          @click="clear"
          variant="outline-primary"
          style="border-radius: 5px !important"
        >
          Erase Date
        </b-button>
      </template>

      <template #table>
        <b-table-simple
          responsive
          class="position-relative table-new-customization"
          sticky-header="60vh"
        >
          <b-thead>
            <b-tr>
              <b-th
                v-for="(field, index) in fields"
                :key="index"
                class="custom-th text-center"
                :style="
                  activeTab == 'pending'
                    ? 'border-radius: 15px 15px 0 0 !important'
                    : ''
                "
              >
                <span class="field">
                  {{ field }}
                </span>
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <!-- isLoading -->
            <template v-if="isLoading">
              <b-tr>
                <b-td
                  :colspan="fields.length ? fields.length + 1 : 5"
                  class="text-center"
                >
                  <b-spinner class="align-middle mr-1" />
                  Loading...
                </b-td>
              </b-tr>
            </template>
            <template v-else-if="report.length > 0">
              <b-tr v-for="(item, index) in report" :key="index">
                <b-td class="text-center">
                  <span
                    class="date clickable text-primary font-weight-bolder"
                    @click="
                      $emit('newDate', item.date);
                      $emit('hidden');
                    "
                    >{{ item.date | myGlobal }}</span
                  >
                </b-td>
                <template v-if="activeTab != 'pending'">
                  <b-td v-for="(merchant, idx) in item.detail" :key="idx">
                    <div
                      class="row d-flex align-items-center"
                      style="font-size: 15px; margin-bottom: 5px"
                    >
                      <b-col cols="5" class="d-flex">
                        <div style="width: 20px">
                          <feather-icon
                            icon="CheckIcon"
                            v-if="merchant.checked"
                            class="text-confirmed font-weight-bolder"
                          />
                        </div>
                        Authorize:
                      </b-col>
                      <b-col class="text-right p-0">
                        <span
                          v-if="merchant.merchant != null"
                          class="font-weight-bolder"
                          >$ {{ merchant.authorize_amount | currencyZero }}
                        </span>
                        <span v-else class="font-weight-bolder"
                          >$ {{ merchant.total_authorize | currencyZero }}
                        </span>
                      </b-col>
                    </div>
                    <div
                      class="row d-flex align-items-center"
                      style="font-size: 15px"
                    >
                      <b-col cols="5" class="d-flex">
                        <div style="width: 20px">
                          <feather-icon
                            icon="CheckIcon"
                            v-if="merchant.checked"
                            class="text-confirmed font-weight-bolder"
                          />
                        </div>
                        Deposit:
                      </b-col>
                      <b-col class="text-right p-0">
                        <span
                          v-if="merchant.merchant != null"
                          class="font-weight-bolder"
                          >$ {{ merchant.real_amount | currencyZero }}</span
                        >
                        <span v-else class="font-weight-bolder"
                          >$ {{ merchant.total_amount | currencyZero }}</span
                        >
                      </b-col>
                    </div>

                    <div
                      class="row d-flex align-items-center font-weight-bolder"
                      style="
                        font-size: 13px;
                        margin-bottom: 5px;
                        min-height: 10px;
                      "
                    >
                      <b-col class="d-flex" v-if="merchant.income_date">
                        <div style="width: 20px"></div>
                        {{ merchant.income_date | myGlobal }}
                      </b-col>
                      <b-col class="text-right p-0"> </b-col>
                    </div>
                    <div
                      class="row d-flex align-items-center"
                      style="font-size: 15px"
                      v-if="activeTab == 'confirmed'"
                    >
                      <b-col cols="5" class="d-flex">
                        <div style="width: 20px"></div>
                        Difference:
                      </b-col>
                      <b-col class="text-right p-0">
                        <span
                          class="font-weight-bolder"
                          :class="
                            merchant.difference > 0
                              ? 'text-danger'
                              : 'text-primary'
                          "
                        >
                          {{ ` $ ${merchant.difference <= 0 ? "" : "-"}` }}
                          {{ merchant.difference | currencyZero }}
                          <span
                            style="font-size: 13px"
                            v-if="merchant.difference > 0"
                          >
                            ({{ merchant.percentage.toFixed(2) }} %)
                          </span>
                        </span>
                      </b-col>
                    </div>
                    <div
                      v-if="
                        activeTab == 'verified' &&
                        !merchant.income_date &&
                        Number(merchant.authorize_amount) != 0 &&
                        merchant.merchant_id
                      "
                      class="d-flex justify-content-center"
                    >
                      <b-badge
                        variant="light-primary"
                        @click="openRegisterIncomeModal(merchant, item)"
                        class="font-weight-bolder clickable"
                        style="font-size: 13px"
                      >
                        Deposit
                      </b-badge>
                    </div>
                    <div
                      v-else-if="
                        activeTab == 'verified' &&
                        !merchant.income_date &&
                        merchant.merchant_id
                      "
                      class="d-flex justify-content-center"
                    >
                      <b-badge
                        variant="light-secondary"
                        class="font-weight-bolder"
                        style="font-size: 13px"
                      >
                        Income Registered
                      </b-badge>
                    </div>
                    <div
                      v-if="allChecked"
                      class="row d-flex align-items-center font-weight-bolder"
                      style="font-size: 13px; min-height: 10px"
                    >
                      <b-col
                        class="d-flex"
                        v-if="merchant.updated_at"
                        v-b-tooltip.hover.top="
                          `${merchant.updated_at ? 'Created at: ' : ''}`
                        "
                      >
                        <div style="width: 20px"></div>

                        {{ merchant.updated_at | myGlobal }}
                      </b-col>
                      <b-col class="text-right p-0"> </b-col>
                    </div>
                  </b-td>
                </template>
              </b-tr>
            </template>
            <b-tr v-else>
              <b-td
                :colspan="fields.length ? fields.length + 1 : 5"
                class="text-center"
                >No data available</b-td
              >
            </b-tr>
          </b-tbody>
          <b-tfoot
            v-if="activeTab != 'pending'"
            style="position: sticky; bottom: 0; z-index: 1"
            :style="isDarkSkin ? 'background: #1b1a1e !important' : ''"
          >
            <b-tr v-if="!isLoading">
              <b-td
                :style="
                  isDarkSkin
                    ? 'background: rgba(58, 114, 234, 0.2) !important'
                    : 'background: rgba(234, 249, 255, 1) !important;'
                "
                style="font-weight: bold"
                v-for="(item, index) in vertical_counters"
                :key="index"
                class="text-center"
              >
                <template v-if="index != 0">
                  <div
                    class="row d-flex align-items-center"
                    style="font-size: 15px"
                  >
                    <b-col class="d-flex">
                      <div style="width: 20px"></div>
                      Authorize:
                    </b-col>
                    <b-col class="text-right p-0">
                      <span class="font-weight-bolder"
                        >$
                        {{ item.total_authorize_amount | currencyZero }}</span
                      >
                    </b-col>
                  </div>
                  <div
                    class="row d-flex align-items-center"
                    style="font-size: 15px"
                  >
                    <b-col class="d-flex">
                      <div style="width: 20px"></div>
                      Deposit:
                    </b-col>
                    <b-col class="text-right p-0">
                      <span class="font-weight-bolder"
                        >$ {{ item.total_vertical_amount | currencyZero }}</span
                      >
                    </b-col>
                  </div>
                  <div
                    class="row d-flex align-items-center"
                    style="font-size: 15px"
                    v-if="activeTab == 'confirmed'"
                  >
                    <b-col class="d-flex">
                      <div style="width: 20px"></div>
                      Difference:
                    </b-col>
                    <b-col class="text-right p-0">
                      <span
                        class="font-weight-bolder"
                        :class="
                          item.total_difference > 0
                            ? 'text-danger'
                            : 'text-primary'
                        "
                      >
                        {{ ` $ ${item.total_difference <= 0 ? "" : "-"}` }}
                        {{ item.total_difference | currencyZero }}
                        <span
                          style="font-size: 13px"
                          v-if="item.total_difference > 0"
                        >
                          ({{ item.total_percentage.toFixed(2) }} %)
                        </span>
                      </span>
                    </b-col>
                  </div>
                </template>
                <template v-else
                  ><span style="font-weight: bold; font-size: 15px">
                    Total</span
                  >
                </template>
              </b-td>
            </b-tr>
          </b-tfoot>
        </b-table-simple>
      </template>
    </filter-slot>
    <daily-settlement-detail-by-merchant
      v-if="showMerchantDetail"
      @hidden="
        (showMerchantDetail = false), resetCurrentDailySettlementMerchant()
      "
    />
    <DailyAuthorizeFormModal
      v-if="showAuthorizeForm"
      @hidden="showAuthorizeForm = false"
      :merchant="selectedMerchant"
      @register="registerCheckedMerchant($event)"
      @close="showAuthorizeForm = false"
    />
  </b-modal>
</template>
<script>
import moment from "moment";
import PaymentsService from "@/views/crm/views/payments/service/payments.service.js";
import { VueAutosuggest } from "vue-autosuggest";
import { mapGetters, mapActions } from "vuex";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import DailyAuthorizeFormModal from "@/views/commons/components/reports/tabs/daily-payment-report/components/DailyAuthorizeFormModal.vue";

export default {
  name: "DailyAuthorizeModal",
  components: {
    VueAutosuggest,
    StatusAccount,
    DailyAuthorizeFormModal,
    DailySettlementDetailByMerchant: () =>
      import(
        "@/views/commons/components/reports/tabs/daily-payment-report/components/DailySettlemenDetailByMerchant.vue"
      ),
  },
  props: {
    typeModal: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showMerchantDetail: false,
      isLoading: false,
      paginate: {
        perPage: 50,
        currentPage: 1,
      },
      startPage: 1,
      rows: 0,
      from: 1,
      to: 1,
      activeTab: "pending",
      report: [],
      vertical_counters: [],
      fields: [
        //DATE	AMG LLC(EMS DATA)	AMG BUSINESS INC(EMS DATA)	AMIG INC(CHASE)	AMG BUSINESS LLC(WELLS FARGO)	GENERAL
        "DATE",
        "AMG LLC(EMS DATA)",
        "AMG BUSINESS INC(EMS DATA)",
        "AMIG INC(CHASE)",
        "AMG BUSINESS LLC(WELLS FARGO)",
        "GENERAL",
      ],
      tabs: [
        { name: "PENDING", value: "pending" },
        { name: "VERIFIED", value: "verified" },
        { name: "CONFIRMED", value: "confirmed" },
      ],
      fromDate: null,
      toDate: null,
      showAuthorizeForm: false,
      selectedMerchant: null,
    };
  },
  computed: {
    allChecked() {
      return (
        this.report.every((item) => item.checked) &&
        this.activeTab === "confirmed"
      );
    },
  },
  mounted() {
    this.activeTab = this.typeModal;
    if (this.activeTab == "pending") {
      this.fields = ["DATE"];
    }
    this.toggleModal("modal-daily-authorize");
    this.getDailyAuthorizeReport();
  },
  watch: {
    month(value) {
      this.fillDays(value);
    },
  },
  methods: {
    clear() {
      this.fromDate = null;
      this.toDate = null;
      this.getDailyAuthorizeReport();
    },
    async resetCurrentDailySettlementMerchant() {
      await this.A_CURRENT_DAILY_SETTLEMENT_MERCHANT({
        merchantId: null,
        merchantName: null,
      });
    },
    ...mapActions({
      A_CURRENT_DAILY_SETTLEMENT_MERCHANT:
        "ManagementReportStore/A_CURRENT_DAILY_SETTLEMENT_MERCHANT",
      A_DAILY_SETTLEMENTS_DATE_RANGE:
        "ManagementReportStore/A_DAILY_SETTLEMENTS_DATE_RANGE",
      A_GET_DAILY_AUTHORIZE_TITLE_COUNTER:
        "ManagementReportStore/A_GET_DAILY_AUTHORIZE_TITLE_COUNTER",
    }),
    async getDailyAuthorizeReport() {
      try {
        //await 10 seconds
        this.isLoading = true;
        this.report = [];
        const { data } = await PaymentsService.getDailyAuthorizeReport({
          state: this.activeTab,
          currentPage: this.paginate.currentPage,
          perPage: this.paginate.perPage,
          from: this.fromDate,
          to: this.toDate,
        });
        this.fields = data.fields;
        this.report = data.report.data;
        this.vertical_counters = data.vertical_counters;
        this.paginate.perPage = data.report.per_page;
        this.rows = data.report.total;
        this.from = data.report.from;
        this.to = data.report.to;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.isLoading = false;
      }
    },
    async handleClick(date, item) {
      const yesterday = moment(date).subtract(1, "days").format("MM/DD/YYYY");
      const range = {
        startDate: yesterday,
        endDate: yesterday,
      };
      this.A_DAILY_SETTLEMENTS_DATE_RANGE(range);
      this.showMerchantDetail = true;
      await this.A_CURRENT_DAILY_SETTLEMENT_MERCHANT({
        merchantId: item.merchant_id,
        merchantName: item.merchant,
      });
    },
    openRegisterIncomeModal(merchant, report) {
      merchant.date = report.date;
      merchant.show_date = report.show_date;
      merchant.authorize = merchant.authorize_amount;
      this.selectedMerchant = merchant;
      this.showAuthorizeForm = true;
    },
    async registerCheckedMerchant(item) {
      try {
        this.addPreloader();
        let params = {
          date: item.date,
          merchant_id: item.merchant_id,
          real_amount: item.real_amount,
          income_date: item.income_date,
        };
        await PaymentsService.toogleDailyAuthorize(params);
        this.showAuthorizeForm = false;
        this.getDailyAuthorizeReport();
        this.A_GET_DAILY_AUTHORIZE_TITLE_COUNTER();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
<style lang="scss" >
.mx-05 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.footer-table td {
  background: rgba(249, 0, 0, 1) !important;
}
.text-confirmed {
  color: rgba(0, 221, 49, 1);
}

.dark-layout {
  .footer-table {
    background: #141414 !important;
  }
}
.w-20 {
  width: 20% !important;
}
</style>
