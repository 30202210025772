<template>
  <div>
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="refreshTable"
      class="filter-slot-new-customization"
      :top-paginate="false"
      paginate-left
      :input-width="60"
    >
      <template #table>
        <b-table
          class="blue-scrollbar position-relative table-new-customization"
          slot="table"
          ref="refCheckPayments"
          no-provider-filtering
          :items="myProvider"
          :fields="fields"
          primary-key="id"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(deposit_number)="{ item }">
            <span class="text-primary">
              {{ item.deposit_number.toUpperCase() }}
            </span>
          </template>

          <template #cell(amount)="{ item }">
            <span class="text-success">$ {{ item.amount | currency }}</span>
          </template>

          <template #cell(created)="{ item }">
            <span>{{ item.created_by }}</span>
            <br />
            <span>{{ item.created_at | myGlobalDay }}</span>
          </template>

          <template #cell(amount_deposit)="data">
            <span class="text-primary"
              >$ {{ data.item.amount_deposit | currency }}</span
            >
          </template>

          <template #cell(settlement_date)="data">
            {{ data.item.settlement_date | myGlobalDay }}
          </template>

          <template #cell(status_confirmation)="{ item }">
            <div class="d-flex align-items-center flex-column">
              <b-badge
                :variant="validateStatus(item.status_confirmation).variant"
              >
                {{ validateStatus(item.status_confirmation).text }}
              </b-badge>
              <b-form-textarea
                v-if="item.reject_motive"
                v-model="item.reject_motive"
                plaintext
                disabled
                class="text-area-reject"
                :style="
                  isDarkSkin
                    ? 'color: #d8d8d8 !important; background-color: rgb(203 202 202 / 21%) !important'
                    : 'color: #6e6b7b !important;background-color: rgb(203 202 202 / 21%) !important'
                "
              />
            </div>
          </template>

          <template #cell(evidence)="{ item }">
            <feather-icon
              v-if="item.evidence_path || item.deposit_path"
              icon="FileIcon"
              class="text-primary clickable"
              size="18"
              @click="showEvidence(item)"
            />
          </template>

          <template #cell(actions)="data">
            <!-- Button for approve or reject -->
            <div class="d-flex justify-content-center">
              <tabler-icon
                icon="EyeIcon"
                class="text-primary clickable"
                size="22"
                v-b-tooltip.hover.top="'Show Payment Details'"
                @click="showInfoDeposit(data.index)"
              />

              <!-- Button for approve or reject -->
              <tabler-icon
                icon="ThumbUpIcon"
                class="text-success clickable ml-1"
                size="22"
                v-b-tooltip.hover.top="'Approve'"
                @click="approveOrReject(data.item, 1)"
                v-if="statusTab == 1"
              />
              <tabler-icon
                icon="ThumbDownIcon"
                class="text-danger clickable ml-1"
                size="22"
                v-b-tooltip.hover.top="'Reject'"
                @click="openModalReject(data.item, data.index)"
                v-if="statusTab == 1"
              />
            </div>
          </template>

          <!-- Row details slot -->
          <template #row-details>
            <DepositDetail
              :currentTab="currentTab"
              :statusTab="statusTab"
              :depositId="depositId"
              :moduleId="moduleId"
              :statusModal="statusModal"
            />
          </template>
        </b-table>
      </template>
    </filter-slot>
    <cool-light-box
      :items="setImages"
      :index="indexImage"
      @close="indexImage = null"
    />

    <modal-reject
      v-if="showModalReject"
      @close="showModalReject = false"
      @reject="saveRejectMotive"
      :currentTab="currentTab"
      :statusTab="statusTab"
      :depositId="depositId"
      :moduleId="moduleId"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import fieldsCheck from "@/views/commons/components/other-payments/data/fieldsCheck";
import filters from "@/views/commons/components/other-payments/data/filters.js";
import OtherPaymentsService from "@/views/commons/components/other-payments/service/other-payment.service";
import DepositDetail from "@/views/commons/components/other-payments/view/component/DepositDetail.vue";
import ModalReject from "@/views/commons/components/other-payments/view/modal/ModalReject.vue";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
export default {
  components: {
    CoolLightBox,
    DepositDetail,
    ModalReject,
  },
  data() {
    return {
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      startPage: null,
      endPage: null,
      nextPage: null,
      toPage: null,
      totalRows: 0,
      fields: fieldsCheck,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Account...",
        model: "",
      },
      filters: filters,
      isBusy: false,
      sortBy: "created_at",
      sortDesc: true,
      dataClient: {},
      setImages: [],
      indexImage: null,
      deposits: [],
      depositId: null,
      showModalReject: false,
      rejectMotive: "",
    };
  },
  props: {
    isPendingModal: {
      type: Boolean,
      default: false,
    },
    statusModal: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    currentTab() {
      return this.$route.matched[2]?.meta.status || this.statusTabModal;
    },
    statusTab() {
      return this.$route.matched[3]?.meta.status || this.statusModal;
    },
  },
  methods: {
    ...mapActions({
      A_COUNTER_OP: "other-payment-store/A_COUNTER_OP",
    }),

    async myProvider(ctx) {
      try {
        let module_modal = this.statusModal ? 16 : this.moduleId;
        const params = {
          orderBy: 1,
          order: ctx.sortDesc ? "desc" : "asc",
          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
          method: this.currentTab,
          status: this.statusTab,
          module_id: module_modal,
          search: this.filterPrincipal.model,
          from: this.filters[0].model,
          to: this.filters[1].model,
        };
        const { data } = await OtherPaymentsService.getOtherChecksPayment(
          params
        );
        const {
          data: otherPayment,
          total,
          from,
          current_page,
          per_page,
          to,
        } = data;
        this.deposits = otherPayment;
        this.startPage = from;
        this.paginate.currentPage = current_page;
        this.paginate.perPage = per_page;
        this.totalRows = total;
        this.toPage = to;
        return this.deposits || [];
      } catch (error) {
        console.log(error);
      }
    },

    refreshTable() {
      this.$refs["refCheckPayments"].refresh();
    },

    validateStatus(status) {
      const statusMap = {
        1: { variant: "light-primary", text: "PENDING CORRESPONDENCE" },
        2: { variant: "light-warning", text: "PENDING MANAGEMENT" },
        3: { variant: "light-success", text: "APPROVED" },
        4: { variant: "light-danger", text: "REJECTED" },
      };
      return statusMap[status];
    },

    openModalReject(item, idx) {
      this.depositId = this.deposits[idx].id;
      this.transactionData = item;
      this.showModalReject = true;
    },
    saveRejectMotive(reason) {
      this.rejectMotive = reason;
      this.approveOrReject(this.transactionData, 2);
      this.showModalReject = false;
    },

    async approveOrReject(item, status) {
      let isConfirmed = true;
      if (status === 1) {
        let confirm = await this.showConfirmSwal(
          `Are you sure you want to approve this payment?`,
          "The payment will be sent to the client"
        );
        isConfirmed = confirm.isConfirmed;
      }

      if (!isConfirmed) return;
      this.addPreloader();
      try {
        await OtherPaymentsService.changeStatusCheckPayment({
          id: item.id,
          status: status === 1 ? 3 : 4,
          user_id: this.currentUser.user_id,
          reject_motive: this.rejectMotive,
        });
        await this.A_COUNTER_OP({ module_id: this.moduleId });
        this.$emit("reload");
        this.refreshTable();
        this.showSuccessSwal();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    showEvidence(item) {
      const path = item.evidence_path || item.deposit_path;
      this.setImages = [];
      this.setImages.push(path);
      this.indexImage = 0;
    },

    showInfoDeposit(idx) {
      this.depositId = this.deposits[idx].id;
      this.deposits.forEach((element, i) => {
        if (i != idx) {
          element._showDetails = false;
          element.detailsShowing = false;
        }
      });
      this.showDetails = !this.showDetails;
      this.deposits[idx]._showDetails = !this.deposits[idx]._showDetails;
      this.deposits[idx].detailsShowing = !this.deposits[idx].detailsShowing;
    },
  },
};
</script>
<style>
.button_circle {
  padding: 0;
  margin: 5px 2.5px;
  height: 30px;
  width: 30px;
  border-radius: 20px;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
}
.text-area-reject {
  border-radius: 5px;
  margin-top: 5px;
  padding: 5px 10px;
  max-width: 250px;
  resize: none;
  height: 60px;
}
</style>
