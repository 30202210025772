export default [
  {
    key: "check_number",
    label: "Check",
    visible: true,
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "details",
    label: "Transaction details",
    visible: true,
    thClass: "text-left",
    tdClass: "text-left col-4",
  },
  {
    key: "module",
    label: "Department",
    visible: true,
    thClass: "text-center",
  },
  {
    key: "type",
    label: "type",
    visible: false,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "status",
    label: "status",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "type_pending",
    label: "type",
    visible: false,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "client",
    label: "Clients",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  // {
  //     key: "vendor",
  //     label: "Vendor",
  //     visible: true
  // },
  // {
  //     key: "concept",
  //     label: "Concept",
  //     visible: true
  // },
  {
    key: "amount",
    label: "Amount",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "cardholdername",
    label: "Card",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "module",
    label: "Department",
    visible: true,
    thClass: "text-center",
  },
  {
    key: "expense_date",
    label: "Expense Date",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "created_at",
    label: "Requested by",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "updated_at",
    label: "Confirmed At",
    visible: false,
    thClass: "text-center",
    tdClass: "text-center",
  },

  {
    key: "actions",
    label: "Actions",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "tracking",
    label: "Tracking",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
];

export const fieldsSoftUnMatched = [
  {
    key: "department",
    label: "Department",
    visible: true,
    thClass: "text-center col-1",
    tdClass: "text-center",
  },
  {
    key: "vendor",
    label: "Vendor",
    visible: true,
  },
  {
    key: "concept",
    label: "Concept",
    visible: true,
  },
  {
    key: "amount",
    label: "Amount",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "transaction_date",
    label: "Date",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "type",
    label: "type",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "register_by",
    label: "Registered By",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "confirmed_date",
    label: "Confirmed",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "ticket",
    label: "Actions",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
];

export const fieldsImportUnMatched = [
  {
    key: "transaction_date",
    label: "Date",
    visible: true,
    thClass: "text-center col-2",
    tdClass: "text-center",
  },
  {
    key: "amount",
    label: "Amount",
    visible: true,
    thClass: "text-center col-2",
    tdClass: "text-center",
  },
  {
    key: "description",
    label: "Description",
    visible: true,
    thClass: "text-left",
  },
  {
    key: "ticket",
    label: "Ticket",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
];
