export default [
    {
        key: "module",
        label: "Department",
        visible: true,
        thClass: "text-center",
    },
    {
        key: "client",
        label: "Clients",
        visible: true,
        thClass: "text-center",
        tdClass: "text-center",
    },

    {
        key: "vendor",
        label: "Pay To",
        visible: true
    },
    {
        key: "concept",
        label: "Memo",
        visible: true
    },
    {
        key: "amount",
        label: "Amount",
        visible: true,
        thClass: "text-center",
        tdClass: "text-center",
    },
    // {
    //     key: "account_number",
    //     label: "Account Number",
    //     visible: true
    // },
    {
        key: "check_number",
        label: "Check Number",
        visible: true,
        tdClass: "text-center",
    },
    {
        key: "status_check",
        label: "Status Check",
        visible: true
    },
    {
        key: "module",
        label: "Department",
        visible: true,
        thClass: "text-center"
    },
    {
        key: "expense_date",
        label: "Payment Date",
        visible: true,
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "created_at",
        label: "Requested by",
        visible: true,
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "collected_at",
        label: "Collected At",
        visible: false,
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "updated_at",
        label: "Confirmed At",
        visible: false,
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "actions",
        label: "Actions",
        visible: true,
        thClass: "text-center",
        tdClass: "text-center",
    }
]
