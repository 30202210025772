var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modalName",attrs:{"modal-class":"modal-primary","title-class":"h3 text-white","title":"SEARCH SYNC","size":"xmd","hide-footer":"","centered":"","no-close-on-backdrop":""},on:{"hidden":function($event){return _vm.closeModal()}}},[_c('b-row',{staticClass:"client-info"},[_c('b-col',{staticClass:"p-0",attrs:{"cols":"4"}},[_c('span',{staticClass:"mb-1"},[_vm._v("Vendor")]),_c('div',{class:_vm.isDarkSkin ? 'border-custom' : 'border-custom-light'},[_c('p',[_vm._v(_vm._s(_vm.dataRow.vendor))])])]),_c('b-col',{staticClass:"p-0",attrs:{"cols":"4"}},[_c('span',{staticClass:"ml-1 mb-1"},[_vm._v("Concept")]),_c('div',{staticClass:"ml-1",class:_vm.isDarkSkin ? 'border-custom' : 'border-custom-light'},[_c('p',[_vm._v(_vm._s(_vm.dataRow.concept))])])]),_c('b-col',{staticClass:"p-0",attrs:{"cols":"2"}},[_c('span',{staticClass:"ml-1"},[_vm._v("Amount")]),_c('div',{staticClass:"ml-1 mb-1",class:_vm.isDarkSkin ? 'border-custom' : 'border-custom-light'},[_c('p',[_vm._v(_vm._s(_vm._f("formatMoney")(_vm.dataRow.amount)))])])]),_c('b-col',{staticClass:"p-0",attrs:{"cols":"2"}},[_c('span',{staticClass:"ml-1 mb-1"},[_vm._v("Date")]),_c('div',{staticClass:"ml-1",class:_vm.isDarkSkin ? 'border-custom' : 'border-custom-light'},[_c('p',[_vm._v(_vm._s(_vm._f("myGlobal")(_vm.dataRow.transaction_date)))])])])],1),_c('b-table',{ref:"importTransactionsTable",staticClass:"position-relative table-new-customization",attrs:{"id":"importTransactionsTable","items":_vm.myProvider,"fields":_vm.fields,"responsive":"md","sticky-header":"70vh","show-empty":""},scopedSlots:_vm._u([{key:"cell(pick)",fn:function(ref){
var item = ref.item;
return [_c('b-form-group',[_c('b-form-checkbox',{on:{"input":function($event){return _vm.onSelectedRow(item)}},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})],1)]}},{key:"cell(transaction_date)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("myGlobal")(item.transaction_date))+" ")])]}},{key:"cell(amount)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{staticClass:"cursor-pointer",staticStyle:{"font-size":"15px"},attrs:{"variant":"light-success"}},[_vm._v(_vm._s(_vm._f("formatMoney")(item.amount)))])]}},{key:"cell(select)",fn:function(ref){
var item = ref.item;
return [_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Select'),expression:"'Select'",modifiers:{"hover":true,"top":true}}],staticClass:"cursor-pointer text-warning ml-1",attrs:{"icon":"CheckIcon","size":"18"},on:{"click":function($event){return _vm.selectSync(item)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }