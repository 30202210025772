import { amgApi } from "@/service/axios";

class CallAnalysisService {
    async getCallAnalysis(payload) {
        try {
            const data = await amgApi.post("/ring-central/call-recording/call-analysis/index", payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on getCallAnalysis:", error);
            throw error;
        }
    }
}

export default new CallAnalysisService();
