<template>
  <div>
    <b-modal
      ref="modal-event-reschedule-tracking"
      size="xmd"
      @hidden="$emit('hidden')"
      hide-footer
    >
      <template #modal-title>
        <span class="text-uppercase font-weight-bolder">
          Reschedule Tracking: {{ leadName }}
        </span>
      </template>
      <template #default>
        <modal-event-tracking :event="event" />
      </template>
    </b-modal>
  </div>
</template>
<script>
import ModalEventTracking from "@/views/crm/views/Lead/lead-event/ModalEventTracking.vue";
export default {
  name: "ModalAppointmentsGrid",
  components: {
    ModalEventTracking,
  },
  props: {
    event: {
      type: Object,
      default: () => {
        return {};
      },
    },
    leadName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {
    this.toggleModal("modal-event-reschedule-tracking");
  },
  methods: {},
};
</script>
<style lang="scss">
</style>