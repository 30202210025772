var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"transaction-pie-chart-detail",attrs:{"size":"xl","hide-footer":"","scrollable":"","no-close-on-backdrop":"","no-close-on-esc":""},on:{"hidden":function($event){return _vm.$emit('hidden')}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.pieChartName)+" transactions "+_vm._s(_vm.G_CURRENT_DAILY_SETTLEMENT_MERCHANT.merchantName ? "of " + _vm.G_CURRENT_DAILY_SETTLEMENT_MERCHANT.merchantName : "")+" ")])]},proxy:true},{key:"default",fn:function(){return [(_vm.pieChartName != 'Matched')?_c('b-nav',{staticClass:"mx-1 border-tab",attrs:{"card-header":"","pills":""}},_vm._l((_vm.tabs),function(item,index){return _c('b-nav-item',{key:index,staticClass:"position-relative",attrs:{"link-classes":['px-3', _vm.bgTabsNavs],"active":item.value === _vm.activeTab},on:{"click":function($event){(_vm.activeTab = item.value),
            _vm.$refs['daily-settlements-transactions-table'].refresh()}}},[_vm._v(" "+_vm._s(item.name)+" "),(_vm.tabCounters.find(function (tab) { return tab.name == item.value; }).value != 0)?_c('b-badge',{staticClass:"position-absolute",staticStyle:{"top":"-5px","right":"-5px"},attrs:{"pill":"","variant":"danger"}},[_vm._v(" "+_vm._s(_vm.tabCounters.find(function (tab) { return tab.name == item.value; }).value)+" ")]):_vm._e()],1)}),1):_vm._e(),_c('filter-slot',{staticClass:"filter-slot-new-customization",attrs:{"top-paginate":false,"paginate-left":"","filter":[],"filter-principal":_vm.filterPrincipal,"total-rows":_vm.totalRows,"paginate":_vm.paginate,"start-page":_vm.startPage,"to-page":_vm.toPage},on:{"reload":function($event){return _vm.$refs['daily-settlements-transactions-table'].refresh()}},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [(
            _vm.activeTab == 'inconsistent' &&
            _vm.pieChartName != 'Matched' &&
            _vm.items.length > 0
          )?_c('b-button',{staticClass:"ml-1",attrs:{"variant":"success"},on:{"click":function($event){return _vm.fixAllTransaction()}}},[_vm._v(" Balance All Amount ")]):_vm._e()]},proxy:true}])},[_c('b-table',{ref:"daily-settlements-transactions-table",staticClass:"position-relative table-new-customization",attrs:{"slot":"table","sticky-header":"60vh","no-provider-filtering":"","busy":_vm.isBusy,"sort-desc":_vm.sortDesc,"items":_vm.getDailySettlementTransactions,"fields":_vm.visibleFields,"per-page":_vm.paginate.perPage,"current-page":_vm.paginate.currentPage,"filter":_vm.filter,"show-empty":"","tbody-tr-class":_vm.rowClass},on:{"update:busy":function($event){_vm.isBusy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},slot:"table",scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(transaction_id)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-column"},[(data.item.transaction_id != null)?_c('span',[_vm._v(" "+_vm._s(data.item.transaction_id)+" ")]):_c('span',[_vm._v(" - ")])])]}},{key:"cell(client)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-column"},[(data.item.is_client == 1)?_c('router-link',{attrs:{"target":"_blank","to":_vm.routers(data.item.client_account_id, data.item.program_id)}},[_vm._v(" "+_vm._s(data.item.client)+" "),_c('br'),(data.item.client_account)?_c('span',{staticClass:"mt-05"},[_vm._v(" "+_vm._s(data.item.client_account)+" ")]):_vm._e()]):(
                data.item.is_client == 0 && data.item.client_account != null
              )?_c('router-link',{attrs:{"to":("/crm/leads/" + (data.item.client_account)),"target":"_blank"}},[_vm._v(" "+_vm._s(data.item.client ? data.item.client : data.item.transaction_client ? data.item.transaction_client : "UNKNOWN")+" "),_c('br'),(data.item.client_account)?_c('span',{staticClass:"mt-05"},[_vm._v(" ID: "+_vm._s(data.item.client_account)+" ")]):_vm._e()]):_c('span',{class:_vm.isDarkSkin ? 'text-info' : 'text-info'},[_vm._v(" "+_vm._s(data.item.client ? data.item.client : data.item.transaction_client ? data.item.transaction_client : "UNKNOWN")+" ")]),(data.item.account && data.item.icon_status)?_c('span',[_c('status-account',{attrs:{"account":data.item,"text":true}})],1):_vm._e()],1)]}},{key:"cell(authorize_amount)",fn:function(data){return [(data.item.authorize_amount == '-')?_c('span',{staticClass:"text-uppercase"},[_c('b-badge',{attrs:{"variant":"light-info"}},[_vm._v(" PENDING TO SETTLE ")])],1):_c('span',{staticClass:"text-primary font-weight-bolder"},[_vm._v(" $ "+_vm._s(_vm._f("currencyZero")(data.item.authorize_amount))+" "),(
                Number(data.item.authorize_amount) >
                Number(data.item.soft_amount)
              )?_c('feather-icon',{staticClass:"text-success",attrs:{"icon":"ArrowUpIcon"}}):_vm._e(),(
                Number(data.item.authorize_amount) <
                Number(data.item.soft_amount)
              )?_c('feather-icon',{staticClass:"text-danger",attrs:{"icon":"ArrowDownIcon"}}):_vm._e()],1)]}},{key:"cell(settlement_date_authorize)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(data.item.user == "-" ? "UNKNOWN" : data.item.user)+" ")]),_c('br'),(data.item.settlement_date_authorize == '-')?_c('span',[_c('b-badge',{attrs:{"variant":"light-info"}},[_vm._v(" PENDING TO SETTLE ")])],1):_c('span',[_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(data.item.settlement_date_authorize))+" ")])]}},{key:"cell(soft_amount)",fn:function(data){return [(data.item.soft_amount != '-')?_c('span',{class:_vm.getClassForTransaction(data.item)},[(_vm.isRefund(data.item))?_c('span',[_vm._v("-")]):_vm._e(),_c('span',[_vm._v(" $ "+_vm._s(_vm._f("currencyZero")(data.item.soft_amount)))]),(_vm.isVoid(data.item))?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Voided amount'),expression:"'Voided amount'",modifiers:{"hover":true,"bottom":true}}],staticClass:"ml-1 text-secondary"},[_vm._v(" ($ "+_vm._s(_vm._f("currencyZero")(data.item.void_amount))+")")]):_vm._e()]):_c('span',[_vm._v(" - ")]),(
              Number(data.item.soft_amount) >
                Number(data.item.authorize_amount) && !_vm.isVoid(data.item)
            )?_c('feather-icon',{staticClass:"text-success",attrs:{"icon":"ArrowUpIcon"}}):_vm._e(),(
              Number(data.item.soft_amount) <
              Number(data.item.authorize_amount)
            )?_c('feather-icon',{staticClass:"text-danger",attrs:{"icon":"ArrowDownIcon"}}):_vm._e()]}},{key:"cell(authorize_status)",fn:function(data){return [_c('b-badge',{staticClass:"text-uppercase",attrs:{"variant":_vm.getStatus(data.item.authorize_status)}},[_vm._v(" "+_vm._s(data.item.authorize_status == "-" ? "PENDING TO SETTLE" : data.item.authorize_status))])]}},{key:"cell(soft_status)",fn:function(data){return [(data.item.soft_status != null)?_c('b-badge',{staticClass:"text-uppercase",attrs:{"variant":_vm.getStatus(data.item.soft_status)}},[_vm._v(" "+_vm._s(data.item.soft_status))]):_c('span',[_vm._v(" - ")]),(_vm.isRefund(data.item) || _vm.isPartialRefund(data.item))?_c('feather-icon',{staticClass:"text-primary ml-1",attrs:{"size":"16","icon":data.item._showDetails ? 'ChevronUpIcon' : 'ChevronDownIcon'},on:{"click":data.toggleDetails}}):_vm._e()]}},{key:"row-details",fn:function(row){return [(row.item.refund_transactions != '[]')?_c('b-card',{staticClass:"p-0 m-0"},[_c('b-row',[(
                  JSON.parse(row.item.refund_transactions)[0]
                    .transaction_id != null
                )?_c('b-col',{attrs:{"cols":"4"}},[_c('b',[_vm._v("Ref. Transaction:")]),_vm._v(" "+_vm._s(JSON.parse(row.item.refund_transactions)[0].transaction_id))]):_vm._e(),_c('b-col',{attrs:{"cols":"4"}},[_c('b',[_vm._v("Date:")]),_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(JSON.parse(row.item.refund_transactions)[0].date)))]),_c('b-col',{attrs:{"cols":"4"}},[_c('b',[_vm._v("Amount:")]),_vm._v(" $ "+_vm._s(_vm._f("currencyZero")(JSON.parse(row.item.refund_transactions)[0].amount)))]),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"4"}},[_c('b',[_vm._v("Auth. Code:")]),_vm._v(" "+_vm._s(JSON.parse(row.item.refund_transactions)[0].auth_code))]),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"4"}},[_c('b',[_vm._v("Card Number:")]),_vm._v(" "+_vm._s(JSON.parse(row.item.refund_transactions)[0].card_number))]),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"4"}},[_c('b',[_vm._v("Client:")]),_vm._v(" "+_vm._s(JSON.parse(row.item.refund_transactions)[0].fullname))])],1)],1):_vm._e(),(row.item.partial_refund_transactions != null)?_c('b-card',{staticClass:"p-0 m-0"},_vm._l((JSON.parse(
                row.item.partial_refund_transactions
              )),function(item,index){return _c('b-row',{key:index,staticClass:"mb-1"},[_c('b-col',{key:index,attrs:{"cols":"4"}},[_c('b',[_vm._v("Ref. Transaction:")]),_vm._v(" "+_vm._s(item.transaction_id))]),(item.date != null)?_c('b-col',{attrs:{"cols":"4"}},[_c('b',[_vm._v("Date:")]),_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(item.date)))]):_vm._e(),(item.amount != null)?_c('b-col',{attrs:{"cols":"4"}},[_c('b',[_vm._v("Amount:")]),_vm._v(" $ "+_vm._s(_vm._f("currencyZero")(item.amount)))]):_vm._e(),(item.date == null)?_c('b-col',{attrs:{"cols":"4"}},[_c('b-badge',{attrs:{"variant":"light-warning"}},[_vm._v(" Refund/Pending Settlement")])],1):_vm._e(),(item.auth_code)?_c('b-col',{staticClass:"mt-1",attrs:{"cols":"4"}},[_c('b',[_vm._v("Auth. Code:")]),_vm._v(" "+_vm._s(item.auth_code))]):_vm._e(),(item.card_number)?_c('b-col',{staticClass:"mt-1",attrs:{"cols":"4"}},[_c('b',[_vm._v("Card Number:")]),_vm._v(" "+_vm._s(item.card_number))]):_vm._e(),(item.fullname)?_c('b-col',{staticClass:"mt-1",attrs:{"cols":"4"}},[_c('b',[_vm._v("Client:")]),_vm._v(" "+_vm._s(item.fullname))]):_vm._e()],1)}),1):_vm._e()]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[(data.item.authorize_status_id != 4)?_c('b-button',{attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.fixTransaction(
                  data.item.id,
                  data.item.authorize_amount,
                  data.item.transaction_id
                )}}},[_vm._v(" "+_vm._s(_vm.getButtonTitle(data.item))+" ")]):_vm._e(),(data.item.authorize_status_id == 4)?_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.ApproveOrDeclineHeldTransaction(data.item)}}},[_vm._v(" Approve Transaction ")]):_vm._e()],1)]}},{key:"cell(chat)",fn:function(row){return [(
              row.item.client_account_id != null ||
              row.item.client_account != null
            )?_c('tabler-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
              row.item.ticket_code ? 'Show Ticket' : 'Create Ticket'
            ),expression:"\n              row.item.ticket_code ? 'Show Ticket' : 'Create Ticket'\n            ",modifiers:{"hover":true,"top":true}}],staticClass:"cursor-pointer text-weight-bolder",class:row.item.ticket_code ? 'text-success' : 'text-secondary',staticStyle:{"margin-left":"5px"},attrs:{"icon":"TicketIcon","size":"18"},on:{"click":function($event){return _vm.selectCustomerTicket(row.item)}}}):_vm._e()]}},{key:"cell(card_number)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.card_number)+" ")]}},(_vm.items)?{key:"bottom-row",fn:function(){return [(_vm.items.length > 0)?[_vm._l((_vm.fields.filter(
                function (fieldT) { return fieldT.visible; }
              )),function(field,index){return [(index == 0)?_c('b-th',{key:field.key,staticClass:"fill-bottom-row"},[_c('b-badge',{staticClass:"w-100 text-center",attrs:{"variant":"primary"}},[_vm._v(" TOTAL ")])],1):(index < _vm.fields.length - 11)?_c('b-th',{key:'eif' + field.key,staticClass:"fill-bottom-row"}):_c('th',{key:'else' + field.key,staticClass:"fill-bottom-row",attrs:{"colspan":index == _vm.fields.length - 4 || index == _vm.fields.length - 3
                    ? 1
                    : 0}},[_c('b-badge',{staticClass:"w-100 text-right",attrs:{"variant":"primary"}},[(field.key == 'authorize_amount')?_c('span',{staticClass:"footer-amount"},[_vm._v(" $ "+_vm._s(_vm._f("currencyZero")(_vm.authorizeAmount))+" ")]):_vm._e(),(field.key == 'soft_amount')?_c('span',{staticClass:"footer-amount"},[_vm._v(" $"+_vm._s(_vm._f("currencyZero")(_vm.total_soft_amount))+" ")]):_vm._e()])],1)]})]:_vm._e()]},proxy:true}:null],null,true)})],1),(_vm.showModalAddTransactionsAuthorize)?_c('modal-add-transactions-authorize',{attrs:{"authorize-transaction-id":_vm.authorizeTransactiondId},on:{"hidden":function($event){_vm.showModalAddTransactionsAuthorize = false},"refresh":_vm.reloadSettlements}}):_vm._e(),(_vm.showModalCreateCustomerTicket)?_c('modal-create-customer-ticket',{attrs:{"customer-ticket":_vm.customerTicketSelected,"customer-id":_vm.customerId,"set-motive":100},on:{"hidden":function($event){_vm.showModalCreateCustomerTicket = false},"saved":_vm.reloadSettlements}}):_vm._e(),(_vm.showModalViewTicketCustomerDetails)?_c('modal-view-ticket-customer-details',{attrs:{"customer-ticket":_vm.customerTicketSelected},on:{"hidden":function($event){_vm.showModalViewTicketCustomerDetails = false}}}):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }