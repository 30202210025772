<template>
  <div>
    <svg
      :width="width"
      :height="height"
      :viewBox="'0 0 ' + width + ' ' + height"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0462 14.6923H12.0901C11.2882 14.6923 10.6231 15.3574 10.6231 16.1594C10.6231 16.9613 11.2882 17.6264 12.0901 17.6264H14.0462C15.924 17.6264 17.4693 16.0811 17.4693 14.2033C17.4693 12.3255 15.924 10.7803 14.0462 10.7803C13.7723 10.7803 13.5572 10.5651 13.5572 10.2912C13.5572 10.0174 13.7723 9.80223 14.0462 9.80223H16.0022C16.8042 9.80223 17.4693 9.13717 17.4693 8.3352C17.4693 7.53322 16.8042 6.86816 16.0022 6.86816H14.0462C12.1684 6.86816 10.6231 8.41344 10.6231 10.2912C10.6231 12.169 12.1684 13.7143 14.0462 13.7143C14.32 13.7143 14.5352 13.9295 14.5352 14.2033C14.5352 14.4772 14.32 14.6923 14.0462 14.6923Z"
        :fill="color"
        :stroke="stroke"
        stroke-width="1.09606"
      />
      <path
        d="M22.8484 20.5604H21.5965C23.8655 18.5066 25.2934 15.5334 25.2934 12.2473C25.2934 6.04659 20.2468 1 14.0462 1C7.84553 1 2.79894 6.04659 2.79894 12.2473C2.79894 15.8268 4.5007 19.0347 7.1218 21.0886C5.73301 21.8319 4.75498 23.2793 4.75498 24.9615C4.75498 25.4897 4.87235 25.9787 5.02883 26.4481C2.70114 26.5655 0.842896 28.4824 0.842896 30.8297C0.842896 33.1769 2.8185 35.2308 5.24399 35.2308H18.9363C21.3618 35.2308 23.3374 33.2552 23.3374 30.8297C23.3374 30.3015 23.22 29.8125 23.0636 29.3431C25.3912 29.2257 27.2495 27.3088 27.2495 24.9615C27.2495 22.6143 25.2739 20.5604 22.8484 20.5604ZM5.733 12.2473C5.733 7.67011 9.46905 3.93407 14.0462 3.93407C18.6233 3.93407 22.3594 7.67011 22.3594 12.2473C22.3594 16.8244 18.6233 20.5604 14.0462 20.5604C9.46905 20.5604 5.733 16.8244 5.733 12.2473ZM18.9363 32.2967H5.24399C4.44202 32.2967 3.77696 31.6316 3.77696 30.8297C3.77696 30.0277 4.44202 29.3626 5.24399 29.3626H18.9363C19.7383 29.3626 20.4033 30.0277 20.4033 30.8297C20.4033 31.6316 19.7383 32.2967 18.9363 32.2967ZM22.8484 26.4286H9.15608C8.3541 26.4286 7.68905 25.7635 7.68905 24.9615C7.68905 24.1596 8.3541 23.4945 9.15608 23.4945H22.8484C23.6504 23.4945 24.3154 24.1596 24.3154 24.9615C24.3154 25.7635 23.6504 26.4286 22.8484 26.4286Z"
        :fill="color"
        :stroke="stroke"
        stroke-width="1.09606"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      required: false,
      default: 28,
    },
    height: {
      type: Number,
      required: false,
      default: 36,
    },
    color: {
      type: String,
      required: false,
      default: "white",
    },
    stroke: {
      type: String,
      required: false,
      default: "#00DD31",
    },
  },
};
</script>

