export default [
    {
      key: "check_details",
      label: "Check Details",
      class: "text-left",
      visible: true,
    },
    {
      key: "module",
      label: "Department",
      visible: true,
      thClass: "text-center",
    },
    {
      key: "type",
      label: "Type",
      visible: true,
      thClass: "text-center",
      tdClass: "text-center",
    },
    {
      key: "status_check",
      label: "Status Check",
      visible: true,
    },
    {
      key: "amount",
      label: "Amount",
      visible: true,
      thClass: "text-center",
      tdClass: "text-center",
    },
    {
      key: "module",
      label: "Department",
      visible: true,
      thClass: "text-center",
    },
    {
      key: "requested_by",
      label: "Requested by",
      visible: true,
      thClass: "text-center",
      tdClass: "text-center",
    },
    {
      key: "actions",
      label: "Actions",
      visible: true,
      thClass: "text-center",
      tdClass: "text-center",
    },
    {
      key: "tracking",
      label: "Tracking",
      visible: true,
      thClass: "text-center",
      tdClass: "text-center",
    },
  ];
  