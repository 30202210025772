<template>
  <b-card
    class="b_card_content dashboard__card"
    :class="isDarkSkin ? 'dashboard__card--dark' : ''"
  >
    <b-row>
      <b-col cols="7">
        <div class="d-flex mb-1 align-items-center">
          <b-avatar rounded="sm" size="4em" class="b_avatar_color">
            <div class="ico_content">
              <b-img :src="icoMarketingCard" style="width: 85%; height: 85%" />
            </div>
          </b-avatar>

          <div class="ml-1">
            <h3 class="font-weight-bolder mb-0">Clients Status</h3>
          </div>
        </div>
      </b-col>
      <b-col
        cols="5"
        class="d-flex justify-content-end"
        style="font-size: 20px; font-weight: 650"
      >
        <b-form-select
          v-model="selectProgram"
          style="margin-top: 4px; width: 100%"
          :class="isDarkSkin ? 'select_border_dark' : ''"
        >
          <template #first>
            <b-form-select-option
              v-for="(item, index) in listPrograms"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </b-form-select-option>
          </template>
        </b-form-select>
      </b-col>
    </b-row>
    <hr />
    <b-col cols="12">
      <b-row v-if="!loading">
        <b-col :cols="charts.old && charts.new ? '6' : '12'" v-if="charts.old">
          <h4 class="font-weight-bolder text-center">Old Status</h4>
          <client-status
            :data-status="charts.old"
            :list-programs="listPrograms"
            :select-year="selectYear"
            :select-month="selectMonth"
            :select-program="selectProgram"
            :index="0"
          />
        </b-col>
        <b-col :cols="charts.old && charts.new ? '6' : '12'" v-if="charts.new">
          <h4 class="font-weight-bolder text-center">New Status</h4>
          <client-status
            :data-status="charts.new"
            :list-programs="listPrograms"
            :select-year="selectYear"
            :select-month="selectMonth"
            :select-program="selectProgram"
            :index="1"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import ClientStatus from "@/views/ceo/dashboard/ClientStatus.vue";
import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service.js";
export default {
  components: {
    ClientStatus,
  },

  props: {
    value: {
      type: Object,
    },
    selectYear: {
      type: Number,
      default: null,
      required: false,
    },
    selectMonth: {
      type: Number,
      default: null,
      required: false,
    },
    listPrograms: {
      type: Array,
      required: false,
      default: [],
    },
  },

  data() {
    return {
      icoMarketingCard: require("@/assets/images/icons/dashboard-ceo/ico_clients_card.png"),
      charts: [],
      selectProgram: null,
      loading: true,
    };
  },

  watch: {
    isDarkSkin(value) {
      this.getClientStatusByProgram();
    },
    selectProgram(value) {
      this.getClientStatusByProgram();
      //   this.dataClient.idProgram = this.selectProgram;
    },
    selectYear(value) {
      //   this.dataClient.year = this.selectYear;
      this.getClientStatusByProgram();
    },
    selectMonth(value) {
      //   this.dataClient.month = this.selectMonth;
      this.getClientStatusByProgram();
    },
  },

  async created() {
    await this.getClientStatusByProgram();
  },

  methods: {
    async getClientStatusByProgram() {
      try {
        // this.statusAreaCard = false;
        this.loading = true;
        let params = {};
        if (this.selectProgram == null) {
          params = {
            year: this.selectYear,
            month: this.selectMonth,
          };
        } else {
          params = {
            year: this.selectYear,
            month: this.selectMonth,
            program_id: this.selectProgram,
          };
        }

        const data = await DashboardServive.clientsStatusIndex(params);
        this.charts = data;
        this.$emit("input", this.charts);
      } catch (e) {
        this.showErrorSwal(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.b_card_content {
  border-radius: 18px;
  min-height: 370px;

  &--dark {
    background: rgba(17, 17, 19, 1);
    box-shadow: 0px 0px 15px 0px #ffffff26 !important;
  }

  .title_content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .b_avatar_color {
    background: rgba(167, 197, 255, 1) !important;
  }

  .select_border_dark {
    color: #1654cc;
    border-color: #1654cc !important;
  }
}
</style>
