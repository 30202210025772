<template>
  <div
    class="dashboard__card"
    :class="isDarkSkin ? 'dashboard__card--dark' : ''"
  >
    <div class="card-report">
      <div class="report-header">
        <h2
          class="report-header__title"
          :class="isDarkSkin ? 'report-header__title--dark' : ''"
        >
          Expenses
        </h2>
        <div class="report-header__icons">
          <img :src="imgExpenses" alt="img_users" />
        </div>
      </div>
      <div class="line-card" />
      <div class="report-body">
        <div class="report">
          <div
            class="report__value value-currency"
            :class="isDarkSkin ? 'report__value--dark' : ''"
          >
            {{ integerPart(objExpenses.total_expenses) }}
            <span
              v-if="objExpenses.total_expenses != 0"
              class="report__value--decimal"
              >.{{ decimalPart(objExpenses.total_expenses) }}</span
            >
          </div>
        </div>
        <hr />
        <div class="detail">
          <div
            v-for="(item, i) in objExpenses.dc_expenses"
            :key="i"
            class="detail-content"
            :class="isDarkSkin ? 'detail-content--dark' : ''"
          >
            <div class="detail-content__text">
              <div class="list-expenses">.</div>
              <span :id="`expenses-${item.name}`">
                {{ titleExpense(item.name) }}
              </span>
              <feather-icon
                v-if="item.name === 'Payroll'"
                icon="AlertCircleIcon"
                size="18"
                class="text-secondary ml-1 pointer"
                v-b-tooltip.hover.top="
                  'This amount only takes into account employees salaries'
                "
              />
              <feather-icon
                v-if="item.name === 'Department Expenses'"
                icon="SettingsIcon"
                class="cursor-pointer ml-1 text-secondary"
                v-b-tooltip.hover
                :title="
                  countPending > 0 ? messagePending : 'Credit card settings'
                "
                @click="settingsModal(item, 0)"
                size="20"
              />
              <feather-icon
                v-if="item.name === 'Department Expenses'"
                icon="PlusIcon"
                class="cursor-pointer text-primary"
                style="margin-right: 5px"
                v-b-tooltip.hover.top="'REGISTER EXPENSE'"
                size="20"
                @click="openModalAddOrEditExpense('CREDIT_CARD')"
              />
              <feather-icon
                v-if="item.name === 'Check Payments'"
                icon="SettingsIcon"
                class="cursor-pointer ml-1 text-secondary"
                v-b-tooltip.hover
                @click="settingsModal(item, 1)"
                size="20"
              />
              <feather-icon
                v-if="item.name === 'Check Payments'"
                icon="PlusIcon"
                class="cursor-pointer text-primary"
                style="margin-right: 5px"
                v-b-tooltip.hover.top="'Add check'"
                size="20"
                @click="openModalAddOrEditExpense('CHECK')"
              />
              <feather-icon
                v-if="item.name === 'E-CHECK'"
                icon="SettingsIcon"
                class="cursor-pointer ml-1 text-secondary"
                v-b-tooltip.hover
                @click="settingsModal(item, 2)"
                size="20"
              />
            </div>
            <div class="detail-content">
              <feather-icon
                v-if="
                  item.name === 'Charge backs' &&
                  item.penalty &&
                  item.penalty !== 0
                "
                v-b-tooltip.top="'$' + item.penalty.toFixed(2)"
                class="mr-1 text-danger cursor-pointer"
                size="15"
                icon="AlertCircleIcon"
              />
              <div
                class="detail-content__value d-flex"
                style="margin-left: 10px"
              >
                <div v-if="item.name == 'Department Expenses'">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span
                      @click.stop="openShowTotalsByCard"
                      class="cursor-pointer"
                      >{{ item.value | formatMoney }}
                    </span>
                    <feather-icon
                      icon="CircleIcon"
                      size="8"
                      style="margin-left: 3px"
                      :fill="
                        resume.total_soft == resume.total_import &&
                        resume.total_unmatched == 0
                          ? 'green'
                          : 'red'
                      "
                      :style="
                        resume.total_soft == resume.total_import &&
                        resume.total_unmatched == 0
                          ? 'color: green'
                          : 'color: red'
                      "
                    />
                  </div>
                </div>
                <div
                  v-else-if="
                    item.name == 'Check Payments' || item.name == 'E-CHECK'
                  "
                >
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span
                      @click.stop="openTotalsByModuleModal(item.name)"
                      class="cursor-pointer"
                      style="margin-right: 10px"
                      >{{ item.value | formatMoney }}
                    </span>
                  </div>
                </div>
                <div v-else>
                  <span
                    @click="showModalDetailExpenses(item.name)"
                    class="cursor-pointer"
                    style="margin-right: 10px"
                    >{{ item.value | formatMoney }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <detail-payroll
      v-if="showDetailPayroll"
      :send-year="dataYear"
      :send-month="dataMonth"
      :send-department="arrDepartment"
      @close="showDetailPayroll = false"
    />
    <detail-inversion
      v-if="showDetailInversionCreative"
      :send-year="dataYear"
      :send-month="dataMonth"
      :send-programs="arrPrograms"
      @close="showDetailInversionCreative = false"
    />
    <detail-charge
      v-if="showDetailChargeBack"
      :send-year="dataYear"
      :send-month="dataMonth"
      :send-programs="arrPrograms"
      @close="showDetailChargeBack = false"
    />

    <detail-void-refund
      v-if="showDetailVoidRefund"
      :send-year="dataYear"
      :send-month="dataMonth"
      :send-programs="arrPrograms"
      @close="showDetailVoidRefund = false"
    />

    <settings-modal
      v-if="showSettingsModal"
      :indexSelected="indexSelected"
      @close="closeCreditCardSettings"
    />

    <expenses-by-department
      v-if="showDepartmentExpenses"
      :send-year="dataYear"
      :send-month="dataMonth"
      @close="showDepartmentExpenses = false"
    />

    <imported-transactions-modal
      v-if="showImportedTransactions"
      :send-year="dataYear"
      :send-month="dataMonth"
      :show-only="showOnly"
      @close="showImportedTransactions = false"
    />

    <ImportTransactions
      v-if="showModalImport"
      @close="showModalImport = false"
      @refresh="existTransaction"
    />

    <TotalsByCardModal
      v-if="showTotalsByCard"
      :send-year="dataYear"
      :send-month="dataMonth"
      @close="showTotalsByCard = false"
    />

    <totals-by-module-modal
      v-if="showTotalsByModuleModal"
      :send-year="dataYear"
      :send-month="dataMonth"
      :method="selectedMethod"
      @close="showTotalsByModuleModal = false"
    />

    <add-or-edit-expense-modal
      v-if="showAddOrEditExpenseModal.show"
      :tab="showAddOrEditExpenseModal.tab"
      :hasCheck="showAddOrEditExpenseModal.tab === 'CHECK'"
      :hasCreditCard="showAddOrEditExpenseModal.tab === 'CREDIT_CARD'"
      @close="
        [
          (showAddOrEditExpenseModal.show = false),
          (showAddOrEditExpenseModal.tab = null),
        ]
      "
    />
  </div>
</template>
<script>
import DashboardService from "@/views/ceo/service-dashboard/dashboard.service";
import DetailPayroll from "@/views/ceo/dashboard/modals/expenses/DetailPayroll.vue";
import DetailInversion from "@/views/ceo/dashboard/modals/expenses/DetailInversion.vue";
import DetailCharge from "@/views/ceo/dashboard/modals/expenses/DetailCharge.vue";
import DetailVoidRefund from "@/views/ceo/dashboard/modals/expenses/DetailVoidRefund.vue";
import ExpensesByDepartment from "@/views/ceo/dashboard/modals/expenses/ExpensesByDepartment.vue";
import SettingsModal from "@/views/ceo/dashboard/modals/expenses/SettingsModal.vue";
import ImportedTransactionsModal from "@/views/ceo/dashboard/modals/expenses/ImportedTransactionsModal.vue";
import ImportTransactions from "@/views/commons/components/credit-cards-expenses/modals/ImportTransactions.vue";
import TotalsByCardModal from "@/views/ceo/dashboard/modals/expenses/TotalsByCardModal.vue";
import TotalsByModuleModal from "@/views/ceo/dashboard/modals/expenses/TotalsByModule.vue";
import AddOrEditExpenseModal from "@/views/commons/expenses/components/Modal/AddOrEditExpense.vue";
import { mapActions } from "vuex";
export default {
  components: {
    DetailPayroll,
    DetailInversion,
    DetailCharge,
    DetailVoidRefund,
    SettingsModal,
    ExpensesByDepartment,
    ImportedTransactionsModal,
    ImportTransactions,
    TotalsByCardModal,
    TotalsByModuleModal,
    AddOrEditExpenseModal,
  },
  props: {
    selectYear: {
      type: Number,
      default: null,
      required: false,
    },
    selectMonth: {
      type: Number,
      default: null,
      required: false,
    },
    listPrograms: {
      type: Array,
      required: false,
      default: [],
    },
    listDepartment: {
      type: Array,
      required: false,
      default: [],
    },
  },
  data() {
    return {
      imgExpenses: require("@/assets/images/icons/dashboard-ceo/expenses.svg"),
      objExpenses: {},
      showSalesPrograms: false,
      showDetailPayroll: false,
      showDetailInversionCreative: false,
      showDetailChargeBack: false,
      showDetailVoidRefund: false,
      dataYear: null,
      dataMonth: null,
      arrPrograms: [],
      arrDepartment: [],
      showSettingsModal: false,
      showDepartmentExpenses: false,
      showImportedTransactions: false,
      importedTransactionsData: {
        matched_transactions: 0,
        unmatched_transactions: 0,
        total_transactions: 0,
      },
      countPending: 0,
      messagePending: "",
      showModalImport: false,
      showOnly: null,
      showTotalsByCard: false,
      resume: [],
      showTotalsByModuleModal: false,
      showAddOrEditExpenseModal: {
        show: false,
        tab: null,
      },
      selectedMethod: null,
    };
  },
  watch: {
    selectMonth(newVal) {
      this.dataMonth = newVal;
      this.refreshData();
    },
    selectYear(newVal) {
      this.dataYear = newVal;
      this.refreshData();
    },
  },

  async created() {
    this.dataMonth = this.selectMonth;
    this.dataYear = this.selectYear;

    await this.getExpenses();
    await this.getImportedTransactionsData();
    this.initializeChannelEvent();
    await this.existTransaction();
    await this.getResumeTotals();
  },

  methods: {
    ...mapActions({
      A_METHOD_EXPENSE: "ExpensesRequest/A_METHOD_EXPENSE",
    }),
    async updateUnmatchedTransactions() {
      try {
        this.addPreloader();
        const params = {
          year: this.dataYear,
          month: this.dataMonth,
        };
        const { data } = await DashboardService.updateUnmatchedTransactions(
          params
        );
        if (data.success) {
          this.showToast(
            "success",
            "top-right",
            "Congratulations",
            "CheckIcon",
            "Transactions updated successfully"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    showImportedTransactionsModal(type = null) {
      this.showOnly = type == "diff" ? "unmatched" : null;
      this.showImportedTransactions = true;
    },
    settingsModal(item, index) {
      this.indexSelected = index;
      this.showSettingsModal = true;
    },
    async getExpenses() {
      try {
        const params = {
          month: this.dataMonth,
          year: this.dataYear,
        };
        const { data } = await DashboardService.getExpenses(params);

        const dc_expenses = data.dc_expenses.filter(
          (item) => item.name != "Inversion Creative"
        );

        const total_expenses = dc_expenses.reduce(
          (acc, item) => acc + item.value,
          0
        );
        this.objExpenses = {
          dc_expenses,
          total_expenses,
        };
      } catch (error) {
        console.log(error);
      }
    },
    async getResumeTotals() {
      try {
        const params = {
          month: this.dataMonth,
          year: this.dataYear,
        };
        const data = await DashboardService.getResumeTotals(params);
        this.resume = data;
      } catch (error) {
        console.log(error);
      }
    },
    async getImportedTransactionsData() {
      try {
        const params = {
          month: this.dataMonth,
          year: this.dataYear,
        };
        const { data } = await DashboardService.getImportedTransactionsData(
          params
        );
        this.importedTransactionsData.matched_transactions =
          data[0].matched_transactions;
        this.importedTransactionsData.unmatched_transactions =
          data[0].unmatched_transactions;
        this.importedTransactionsData.total_transactions =
          data[0].total_transactions;
      } catch (error) {
        console.log(error);
      }
    },

    showModalDetailExpenses(name) {
      this.arrPrograms = this.listPrograms;
      this.arrDepartment = this.listDepartment;
      switch (name) {
        case "Payroll":
          this.showDetailPayroll = true;
          break;
        case "Inversion Creative":
          this.showDetailInversionCreative = true;
          break;
        case "Charge backs":
          this.showDetailChargeBack = true;
          break;
        case "Void/Refund":
          this.showDetailVoidRefund = true;
          break;
        case "Department Expenses":
          this.showDepartmentExpenses = true;
          break;
        default:
          break;
      }
    },

    decimalPart(number) {
      number = parseFloat(number);
      number = number.toFixed(2);
      const parts = number.split(".");

      if (number != 0) {
        const parts = number.split(".");
        if (parts.length > 1) {
          return parts[1];
        }
      } else {
        return "00";
      }
    },
    integerPart(number) {
      number = parseInt(number);
      return number.toLocaleString();
    },
    async initializeChannelEvent() {
      try {
        window.socket.bind("refresh-expenses-dashboard-ceo-event", async () => {
          console.log('expenses');
          this.refreshData();
        });
      } catch (error) {
        console.log(error);
      }
    },
    refreshData() {
      this.getExpenses();
      this.getImportedTransactionsData();
      this.getResumeTotals();
    },
    async existTransaction() {
      const data = await DashboardService.existTransaction();
      this.transactions = data;
      this.updatePopoverContent();
      this.countPending = data.filter(
        (item) => item.total_transactions === 0
      ).length;
    },
    updatePopoverContent() {
      const pendingMonths = this.getPendingMonths();
      if (pendingMonths.length > 0) {
        this.messagePending = `Pending transactions to be imported are from: ${pendingMonths.join(
          ", "
        )}.`;
      } else {
        this.messagePending = "No pending transactions.";
      }
    },
    getPendingMonths() {
      const pendingMonths = this.transactions
        .filter((transaction) => transaction.total_transactions === 0)
        .map((transaction) => {
          const date = new Date(transaction.year, transaction.month - 1);
          return date.toLocaleString("default", { month: "long" });
        });

      return Array.from(new Set(pendingMonths));
    },
    openImportTransactions() {
      this.showModalImport = true;
    },
    closeCreditCardSettings() {
      this.showSettingsModal = false;
      this.existTransaction();
    },
    openTotalsByModuleModal(itemName) {
      this.selectedMethod = itemName == "Check Payments" ? "CHECK" : "E-CHECK";
      this.showTotalsByModuleModal = true;
      this.A_METHOD_EXPENSE(this.selectedMethod);
    },
    openShowTotalsByCard() {
      this.showTotalsByCard = true;
      this.A_METHOD_EXPENSE("CREDIT_CARD");
    },
    titleExpense(name) {
      let title;
      switch (name) {
        case "Department Expenses":
          title = "Cards";
          break;
        case "Check Payments":
          title = "Checks";
          break;
        case "E-CHECK":
          title = "E-Checks";
          break;
        default:
          title = name;
          break;
      }
      return title;
    },
    openModalAddOrEditExpense(tab) {
      this.showAddOrEditExpenseModal.show = true;
      this.showAddOrEditExpenseModal.tab = tab;
    },
  },

  computed: {},
  beforeDestroy() {
    try {
      window.socket.unbind("refresh-expenses-dashboard-ceo-event");
    } catch (error) {
      console.log("refresh-expenses-dashboard-ceo-event", error);
    }
  },
};
</script>
<style scoped>
.hover-effect-dark:hover {
  background-color: #333;
  color: #f0f0f0;
  transition: background-color 0.3s, color 0.3s;
}
.hover-effect-light:hover {
  background-color: #dfeaff;
  color: #333;
  transition: background-color 0.3s, color 0.3s;
}
</style>
<style lang="scss" scoped>
$color-primary: #5f5f5f;
$color-secondary: #ffffff;
$bg-card-white: #ffffff;
$bg-card-dark: #111113;
$box-shadow-white: #00000026;

.dashboard__card {
  margin-top: 5px;
  box-shadow: 0px 0px 15px 0px $box-shadow-white;
  border-radius: 15px;
  margin-bottom: 27px;
  padding: 18px;
  background-color: $bg-card-white;
  height: auto !important;
  &--dark {
    background-color: $bg-card-dark;
    box-shadow: 0px 0px 22.935px 0px rgba(255, 249, 249, 0.1);
  }

  .card-report {
    position: relative;

    .report-header {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__title {
        color: #959595;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding-left: 14px;
      }

      &__icons {
        width: 47px;
        height: 47px;
      }

      .icons_employee {
        border-radius: 9.174px;
        background: #a7c5ff;
        width: 47px;
        height: 47px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .line-card {
      width: 4.5px;
      height: 58px;
      background-color: #1654cc;
      position: absolute;
      top: 14px;
      left: -3px;
      border-radius: 8px;
    }

    .report-body {
      padding-left: 14px;
      // padding-right: 8px;

      .report {
        width: 100%;
        display: flex;
        align-items: center;

        &__value {
          color: #606060;
          font-family: Montserrat;
          font-weight: 700;
          font-size: 1.6rem;

          &--decimal {
            font-size: 1.3rem;
          }

          &--dark {
            color: $color-secondary;
          }
        }

        .metrica-success {
          color: #45f06b;
        }

        .value-currency::before {
          content: "$";
        }
      }

      .detail {
        // background-color:red;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .detail-content {
          display: flex;
          justify-content: space-between;
          color: #606060;
          position: relative;
          margin-bottom: 3px;

          &--dark {
            color: #b4b4b4;
          }

          &__text {
            font-family: Montserrat;
            font-size: 14.678px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            display: flex;
            justify-content: center;

            .list-expenses {
              margin-right: 7px;
              font-size: 15px;
              font-weight: bold;
              position: absolute;
              top: -4px;
              left: -9px;
            }
          }

          &__value {
            font-family: Montserrat;
            font-size: 14.678px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
      }

      .detail-sales {
        width: 100%;
        height: 110px;
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;
        gap: 3px;
        align-content: flex-start;

        .sales {
          width: calc(33.33% - 3px);
          height: 25px;
          padding: 4px 8px;
          display: flex;
          justify-content: space-between;
          border-radius: 10px;
          background: #dfeaff;
          align-items: center;
          font-size: 0.93rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          font-family: Montserrat;
          margin-bottom: 5px;

          &__program {
            color: #454545;
          }

          &__value {
            color: #1654cc;
            font-weight: 700;
          }

          &__icons {
            border-radius: 4px;
            background-color: #1654cc;
            padding: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .detail-employee {
        width: 100%;
        height: 110px;
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;
        gap: 3px;
        align-content: flex-start;

        .employee {
          width: calc(33.33% - 3px);
          height: 25px;
          padding: 4px 8px;
          display: flex;
          justify-content: space-between;
          border-radius: 10px;
          background: rgba(191, 191, 191, 0.25);
          align-items: center;
          font-size: 0.93rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          font-family: Montserrat;
          margin-bottom: 5px;
          // margin-bottom:5px;

          &__program {
            color: #606060;

            &--dark {
              color: #b4b4b4;
            }
          }

          &__input {
            color: #15d763;

            &::before {
              content: "+";
            }
          }

          &__ouput {
            color: #ff4a4a;
            font-weight: 700;

            &::before {
              content: "-";
            }
          }
        }
      }
    }
  }
}
</style>
