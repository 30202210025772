export default [
  {
    key: "bank_name",
    label: "Bank Name",
    visible: true,
  },
  {
    key: "account_name",
    label: "Account Name",
    visible: true,
  },
  {
    key: "account_number",
    label: "Account Number",
    visible: true,
  },
  {
    key: "routing_number",
    label: "Routing Number",
    visible: true,
  },
  {
    key: "balance",
    label: "Balance",
    visible: true,
    tdClass: "col-2",
  },
  {
    key: "freeze",
    label: "Freezed Amount",
    visible: false,
    class: "text-center",
  },
  {
    key: "total_paid",
    label: "Total Paid",
    visible: true,
    class: "text-center",
  },
  {
    key: "departments",
    label: "Departments",
    visible: true,
  },
  {
    key: "created_at",
    label: "Created At",
    visible: true,
  },
  {
    key: "deleted_at",
    label: "Deleted At",
    visible: true,
  },
  {
    key: "status",
    label: "Status",
    visible: false,
  },
  {
    key: "actions",
    label: "Actions",
    visible: true,
  },
];
