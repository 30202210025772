<template>
  <b-modal
    ref="assignDepartments"
    modal-class="modal-primary"
    title-class="h3 text-white"
    title="Assing Departments"
    size="lg"
    @hidden="closeModal()"
    centered
    no-close-on-backdrop
    ok-title="Assign Departments"
    cancel-title="Cancel"
    ok-variant="outline-primary"
    @ok="save"
  >
    <b-row>
      <template v-for="(column, index) in modules">
        <b-col :key="index" :cols="col" sm="12" :md="col">
          <template v-for="(item, index2) in column">
            <div :key="index2" class="pt-1 pb-1 d-flex justify-content-between">
              <b-form-checkbox v-model="item.checked" :disabled="saving">
                <strong>{{ item.name }}</strong>
              </b-form-checkbox>
            </div>
          </template>
        </b-col>
      </template>
    </b-row>
  </b-modal>
</template>
<script>
import CheckServices from "@/views/commons/components/check-expenses/services/checks.services.js";

export default {
  name: "AssingDepartments",
  props: {},
  data() {
    return {
      modules: [],
      col: 4,
      permissionModules: [],
      loadingAll: false,
      assignDepartments: false,
      saving: false,
      oldModules: [],
    };
  },
  watch: {},
  computed: {
    currentModules() {
      return this.getModulesId();
    },
  },
  mounted() {},
  async created() {
    await this.getPermissionModules();
    await this.getModules();
    this.toggleModal("assignDepartments");
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async save(ev) {
      try {
        ev.preventDefault();
        this.addPreloader();
        const modulesId = this.currentModules;
        this.saving = true;
        await CheckServices.savePermissionECheckModules({
          modulesId,
        });
        this.saving = false;
        this.closeModal();
        this.removePreloader();
        this.showAlertSuccess("Departments have been successfully assigned.");
      } catch (ex) {
        console.log(ex);
        this.removePreloader();
      }
    },
    showAlertSuccess(message) {
      this.showToast("success", "top-right", "Success!", "CheckIcon", message);
    },
    async getPermissionModules() {
      try {
        this.addPreloader();
        const { data } = await CheckServices.listPermissionECheckModules();
        this.permissionModules = data;
        this.removePreloader();
      } catch (ex) {
        this.removePreloader();
      }
    },
    async getModules() {
      try {
        this.addPreloader();
        const { data } = await CheckServices.getModules();
        data.forEach((item) => {
          item.checked = this.permissionModules.includes(item.id);
        });
        const result = this.dividirArray(data, this.col);
        this.modules = result;
        this.oldModules = this.getModulesId();
        this.removePreloader();
      } catch (ex) {
        this.removePreloader();
      }
    },
    getModulesId() {
      return this.modules
        .flatMap((item) => item)
        .filter((item) => item.checked)
        .flatMap((item) => item.id)
        .map((item) => ({ module_id: item }));
    },
    dividirArray(arrayData, size) {
      const result = [];
      let width = 12;
      let percentaje = (size / width) * 100;
      let final = Math.floor(100 / percentaje);
      let unsize = Math.ceil(arrayData.length / final);
      for (let i = 0; i < arrayData.length; i += unsize) {
        result.push(arrayData.slice(i, i + unsize));
      }
      return result;
    },
    arraysAreEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) return false;
      // Ordenar ambos arrays
      const sortedArr1 = arr1.slice().sort();
      const sortedArr2 = arr2.slice().sort();
      for (let i = sortedArr1.length; i--; ) {
        if (sortedArr1[i] !== sortedArr2[i]) return false;
      }
      return true;
    },
  },
};
</script>