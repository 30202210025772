<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="8.30324"
      cy="9.51607"
      rx="1.13325"
      ry="1.05669"
      :fill="color"
    />
    <ellipse
      cx="14.9769"
      cy="9.51607"
      rx="1.13325"
      ry="1.05669"
      :fill="color"
    />
    <path
      d="M11.577 22.0282H11.5772C17.4925 22.0214 22.2893 17.288 22.2962 11.4432V11.4431C22.2962 5.59534 17.4953 0.857882 11.577 0.857882C5.65875 0.857882 0.857882 5.59534 0.857882 11.4431C0.857882 17.2908 5.65875 22.0282 11.577 22.0282ZM11.577 20.6997C6.39642 20.6997 2.19982 16.5537 2.19982 11.4431C2.19982 6.33249 6.39633 2.18651 11.5769 2.18642C16.7552 2.19201 20.9486 6.33483 20.9543 11.4432C20.9542 16.5538 16.7576 20.6997 11.577 20.6997ZM15.279 14.4339H7.87508C7.50625 14.4339 7.20411 14.7296 7.20411 15.0981C7.20411 15.4667 7.50625 15.7624 7.87508 15.7624H15.279C15.6478 15.7624 15.95 15.4667 15.95 15.0981C15.95 14.7296 15.6478 14.4339 15.279 14.4339Z"
      :fill="color"
      :stroke="color"
      stroke-width="0.284236"
    />
  </svg>
</template>
<script>
export default {
  name: "PositiveIcon",
  props: {
    size: {
      type: String,
      default: "1rem",
    },
    color: {
      type: String,
      default: "#000",
    },
  },
};
</script>
