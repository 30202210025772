<template>
  <b-modal
    v-model="modalCenter"
    centered
    size="xlg"
    no-close-on-backdrop
    :title="title"
    title-tag="h3"
    :hide-footer="onlyView"
    modal-class="modal-primary" 
    @hidden="hideModal"
  >
    <!-- <validation-observer ref="refFormObserver"> -->
      <!-- Form -->
      <b-form>
        <b-container fluid>
          <b-row no-gutters>
            <b-col  class="border-right col-sm-2 col-md-2 col-lg-3 col-xl-3 h-auto">
              <div class="stepper pr-2 py-1">
                <div
                  v-for="(step, index) in steps"
                  :key="index"
                  @click="currentStep = index"
                  class="p-1 cursor-pointer"
                  :class="['step', { active: index === currentStep }]"
                >
                  <div class="step-icon">
                    <tabler-icon :icon="step.icon" size="25" />
                  </div>
                  <div class="step-content no-wrap">
                    <div class="step-label">{{ step.label }}</div>
                  </div>
                  <div
                    class="clearfix w-100"
                    v-if="index == 0 && section_required.state"
                  >
                    <feather-icon
                      size="30"
                      class="alertInfo text-danger float-right"
                      icon="InfoIcon"
                    />
                  </div>
                </div>
              </div>
            </b-col>
            <b-col  class="col-sm-10 col-md-10 col-lg-9 col-xl-9">
              <basic-information-creditors
                v-show="currentStep === 0"
                ref="refBasicInformationCreditors"
                :user-data="userData"
                :onlyView="onlyView"
                @section_required="sectionRequired"
              />
              <recovery-creditor-edit
                v-show="currentStep === 1"
                :onlyView="onlyView"
                ref="refRecoveryCreditorEdit"
                :user-data="userData"
              />
              <customer-creditor-edit
                v-show="currentStep === 2"
                :onlyView="onlyView"
                ref="refCustomerCreditorEdit"
                :user-data="userData"
              />
              <more-information-edit
                v-show="currentStep === 3"
                :onlyView="onlyView"
                ref="refMoreInformationEdit"
                :user-data="userData"
              />
              <schedule-contact-creditor-edit
                v-show="currentStep === 4"
                :onlyView="onlyView"
                ref="refScheduleContactCreditorEdit"
                :user-data="userData"
                @update:schedule="setSchedule"
              />
            </b-col>
          </b-row>
        </b-container>
      </b-form>
    <!-- </validation-observer> -->
    <template #modal-footer>
      <b-button @click="hideModal">Cancel</b-button>
      <button-save @click="onSubmit" />
    </template>
  </b-modal>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import BasicInformationCreditors from "./BasicInformationCreditorsEdit.vue";
import RecoveryCreditorEdit from "@/views/debt-solution/views/creditors/components/modal/RecoveryCreditorsEdit.vue";
import CustomerCreditorEdit from "@/views/debt-solution/views/creditors/components/modal/CustomerCreditorEdit.vue";
import MoreInformationEdit from "@/views/debt-solution/views/creditors/components/modal/MoreInformationCreditorEdit2.vue";
import ScheduleContactCreditorEdit from "@/views/debt-solution/views/creditors/components/modal/ContactScheduleCreditorEdit.vue";

import CreditorsService from "../../service/creditors.service";
import { ValidationObserver } from "vee-validate";
import ButtonSave from "@/views/commons/utilities/ButtonSave.vue";
import ButtonCancel from "@/views/commons/utilities/ButtonCancel.vue";
export default {
  name: "ModalEditCreditor",
  components: {
    ButtonSave,
    ButtonCancel,
    BasicInformationCreditors,
    CreditorsService,
    ValidationObserver,
    RecoveryCreditorEdit,
    CustomerCreditorEdit,
    MoreInformationEdit,
    ScheduleContactCreditorEdit,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    onlyView: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_UPDATE_TABLE_CREDITORS:
        "DebtSolutionCreditors/G_UPDATE_TABLE_CREDITORS",
    }),
    title(){
      const action = this.onlyView ? '' : 'EDIT'
      return `${action} CREDITOR: ${this.userData.creditorName.toUpperCase()}`
    }
  },
  data() {
    return {
      modalCenter: true,
      idcred: "",
      steps: [
        {
          label: "Basic Information",
          icon: "UserIcon",
        },
        {
          label: "Recovery Information",
          icon: "LockIcon",
        },
        {
          label: "Customer Information",
          icon: "UsersIcon",
        },
        {
          label: "More Information",
          icon: "InfoCircleIcon",
        },
        {
          label: "Schedule Contact",
          icon: "CalendarIcon",
        },
      ],
      currentStep: 0,
      section_required: {
        state: false,
      },
    };
  },
  methods: {
    nextStep() {
      if (this.currentStep < this.steps.length - 1) {
        this.currentStep++;
      }
    },
    prevStep() {
      if (this.currentStep > 0) {
        this.currentStep--;
      }
    },
    ...mapActions({
      A_UPDATE_TABLE_CREDITORS:
        "DebtSolutionCreditors/A_UPDATE_TABLE_CREDITORS",
    }),
    // SET THE SCHEDULE WITH A EVENT
    setSchedule(val) {
      this.userData.schedule = val;
    },
    async validateInputs(){
      const validBasicInfo = await this.$refs.refBasicInformationCreditors.validate();
      if(!validBasicInfo) {
        this.currentStep = 0
        return false;
      };
      const validRecovery = await this.$refs.refRecoveryCreditorEdit.validate();
      if(!validRecovery) {
        this.currentStep = 1;
        return false;
      }
      const validCustomer = await this.$refs.refCustomerCreditorEdit.validate();
      if(!validCustomer) { 
        this.currentStep = 2;
        return false;
      }
      const validMoreInfo = await this.$refs.refMoreInformationEdit.validate();
      if(!validMoreInfo) { 
        this.currentStep = 3;
        return false;
      }
      const validSchedule = await this.$refs.refScheduleContactCreditorEdit.validate();
      if(!validSchedule) {
        this.currentStep = 4;
        return false;
      }
      return true;
    },
    showMessage(){
      this.showToast(
        "warning",
        "top-right",
        "Oops!",
        "XIcon",
        "Please fill all the fields"
      );
    },
    async onSubmit() {
      // if (await this.$refs.refFormObserver.validate()) {
        const validate = await this.validateInputs()
        if(!validate) {
          this.showMessage();
          return;
        };

        const confirm = await this.showConfirmSwal();
        if (!confirm.isConfirmed) {
          return;
        }
        this.addPreloader();
        try {
          await CreditorsService.SAVE_CREDITORS({
            id_creditor_parent: this.userData.parent_id,
            idcredential: this.userData.id,
            namecreditor: this.userData.creditorName,
            recovery_name: this.userData.rName,
            recovery_faxs: this.userData.recoveryFax,
            recovery_emails: this.userData.recoveryEmail,
            customer_phones: this.userData.customerPhone,
            settlement: this.userData.settlement,
            settle: this.userData.settle,
            website: this.userData.website,
            user: this.currentUser.user_id,
            offerContactNumber: this.userData.offer_contact_number,
            creditorMailingAddress: this.userData.creditorMailingAddress,
            creditorAddress: this.userData.creditorAddress,
            creditorCity: this.userData.creditorCity,
            creditorState: this.userData.creditorState,
            creditorZipCode: this.userData.creditorZipCode,
            creditorCountry: this.userData.creditorCountry,
            customer_name: this.userData.customer_name,
            customer_faxs: this.userData.customerFax,
            customer_emails: this.userData.customerEmail,
            average_trade_percentage: this.userData.average_trade_percentage,
            debt_types: this.userData.debt_types,
            timezone_id: this.userData.timezone_id,
            payment_type: this.userData.payment_type,
            agency: this.userData.agency,
            creditor_website: this.userData.creditor_website,
            interest_rate: this.userData.interest_rate,
            schedule: this.userData.schedule,
            send_types: this.userData.send_types,
            agent: this.userData.agent,
            offer_contact_number: this.userData.offer_contact_number,

            city_address: this.userData.city_address,
            zip_code_address: this.userData.zip_code_address,
            country_address: this.userData.country_address,
            state_address: this.userData.state_address,

            recovery_phone: this.userData.recovery_phone,
            recovery_agent: this.userData.recovery_agent,
            recovery_phone_delete: this.userData.recovery_phone_delete,
            recovery_agent_delete: this.userData.recovery_agent_delete,
          });
          this.$emit(
            "close",
            this.userData.creditorState,
            this.userData.creditorMailingAddress
          );
          this.modalCenter = false;
          this.A_UPDATE_TABLE_CREDITORS(true);
          setTimeout(() => {
            this.A_UPDATE_TABLE_CREDITORS(false);
          }, 2000);
          this.removePreloader();
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "The creditor has been update."
          );
        } catch (error) {
          this.showErrorSwal(error);
        }
      // }
    },
    hideModal() {
      this.modalCenter = false;
      this.$emit("hideModal", false);
    },
    sectionRequired(val) {
      this.section_required.state = val[0];
    },
  },
};
</script>
<style scoped>
.stepper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.step {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.step.active {
  color: #007bff;
  border-color: #007bff;
  border-radius: 15px;
  border: 1px solid #007bff;
}

.step-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.step-label {
  font-weight: bold;
  margin-bottom: 5px;
  
}

.step-description {
  color: #6c757d;
  font-size: 14px;
}

.stepper-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.border-right {
  border-right: 1px solid #dee2e6;
  height: 100%;
}

@keyframes alertInfo {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}
.alertInfo {
  animation: alertInfo 1s infinite;
}
</style>