<template>
  <b-container class="mt-1">
    <h3>Contact Schedule</h3>
    <b-container>
      <validation-observer ref="contactScheduleObserver">
        <b-row class="mt-2">
          <!-- <b-col>
            <b-form-group label="Day">
              <v-select
                v-model="contactScheduleObject.date"
                :options="daysOfTheWeek"
                :reduce="(days) => days.id"
                multiple
                :closeOnSelect="false"
              />
            </b-form-group>
          </b-col> -->

          <b-col cols="5">
            <validation-provider
              name="agency"
              rules="max:255"
              v-slot="{ errors }"
            >
              <b-form-group
                label="Agency"
                label-for="agency"
                :state="errors[0] ? false : null"
              >
                <b-form-input
                  id="agency"
                  v-model="contactScheduleObject.agency"
                  class="rounded"
                  :disabled="onlyView"
                  :state="errors[0] ? false : null"
                  :class="errors[0] ? 'rounded border border-danger' : ''"
                  maxlength="255"
                ></b-form-input>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="5">
            <validation-provider rules="integer" v-slot="{ errors }">
              <b-form-group label="Timezone" label-for="timezone">
                <v-select
                  :style="errors[0] ? 'border: 1px solid red !important;' : ''"
                  :class="errors[0] ? 'rounded border border-danger' : ''"
                  id="type"
                  label="name"
                  :options="timezones"
                  :disabled="onlyView"
                  :reduce="(el) => el.id"
                  v-model="contactScheduleObject.timezone_id"
                  class="rounded"
                ></v-select>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="5" class="mt-1">
            <b-form-group label="From">
              <kendo-timepicker
                :format="'HH:mm'"
                v-model="contactScheduleObject.time_from"
                :interval="15"
                :disabled="onlyView"
                class="w-100 rounded bg-transparent"
                placeholder="Time from"
                style="height: 2.73rem"
              />
            </b-form-group>
          </b-col>

          <b-col cols="5" class="mt-1">
            <b-form-group label="To">
              <kendo-timepicker
                :format="'HH:mm'"
                v-model="contactScheduleObject.time_to"
                :interval="15"
                :disabled="onlyView"
                class="w-100 rounded bg-transparent"
                placeholder="Time from"
                style="height: 2.73rem"
                :min="minTime"
              />
            </b-form-group>
          </b-col>

          <!-- <b-col class="d-flex align-items-center">
            <b-form-group label="Always available">
              <b-form-group>
                <b-form-checkbox
                  ref="asd"
                  v-model="available"
                  value="1"
                  :unchecked-value="0"
                  switch
                >
                </b-form-checkbox>
              </b-form-group>
            </b-form-group>
          </b-col> -->

          <b-col class="d-flex align-items-center text-right mt-2">
            <div>
              <b-button
                variant="primary"
                class="rounded"
                :disabled="onlyView"
                @click="validateBeforeAddToSchedule"
              >
                <feather-icon icon="PlusIcon" size="14" />
                Add
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col cols="12">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-center">Agency</th>
                  <th class="text-center">From</th>
                  <th class="text-center">To</th>
                  <th class="text-center">Timezone</th>
                  <th class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody v-if="schedule.length > 0">
                <template v-for="(item, index) in schedule">
                  <tr :key="index">
                    <td class="text-center">{{ item.agency }}</td>

                    <td class="text-center">{{ textToTime(item.time_from) }}</td>
                    <td class="text-center">{{ textToTime(item.time_to) }}</td>

                    <td class="text-center">
                      {{ getTimezoneNameById(item.timezone_id) }}
                    </td>
                    <td class="text-center">
                      <b-button
                        variant="danger"
                        class="rounded"
                        :disabled="onlyView"
                        @click="removeSchedule(index)"
                      >
                        <feather-icon icon="TrashIcon" size="14" />
                      </b-button>
                    </td>
                  </tr>
                </template>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="5" class="text-center">No schedules added</td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </validation-observer>
    </b-container>
  </b-container>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { mapGetters } from "vuex";
import moment from "moment";
import CreditorsService from "@/views/debt-solution/views/creditors/service/creditors.service.js";

export default {
  name: "ContactScheduleCreditorEdit",
  props: {
    userData: {
      type: Object,
      required: true,
    },
    onlyView: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },

  computed: {
    ...mapGetters({
      G_STATUS_EEUU: "DebtSolutionCreditors/G_STATUS_EEUU",
      G_COUNTRIES: "DebtSolutionCreditors/G_COUNTRIES",
    }),
    minTime() {
      let dTime = this.contactScheduleObject.time_from || "00:00";
      let contactDtime = "01/01/1970 " + dTime;
      var date = new Date(contactDtime);
      return date;
    },
  },
  data() {
    return {
      moment: moment,
      contactScheduleObject: {
        agency: null,
        timezone_id: null,
        time_from: null,
        time_to: null,
      },
      schedule: [],
      timezones: [],
    };
  },
  created() {
    this.schedule = this.userData.schedule;
    this.getTimezones();
  },

  methods: {
    async getTimezones() {
      try {
        const { data } = await CreditorsService.getTimezones();
        this.timezones = data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    clearFields() {
      this.contactScheduleObject.agency = null;
      this.contactScheduleObject.timezone_id = null;
      this.contactScheduleObject.time_from = null;
      this.contactScheduleObject.time_to = null;
    },

    // 00:00:00.000000	 to   23:59:59.00 am/pm
    textToTime(text) {
      let time = text.split(":");
      let hours = Number(time[0]);
      let minutes = Number(time[1]);
      let timeValue;

      if (hours > 0 && hours <= 12) {
        timeValue = "" + hours;
      } else if (hours > 12) {
        timeValue = "" + (hours - 12);
      } else if (hours == 0) {
        timeValue = "12";
      }

      timeValue += minutes < 10 ? ":0" + minutes : ":" + minutes; // get minutes
      timeValue += hours >= 12 ? " P.M." : " A.M."; // get AM/PM
      return timeValue;
    },

    getTimezoneNameById(id) {
      const timezone = this.timezones.find((el) => el.id === id);
      if (!timezone) return "";
      return timezone.name;
    },

    validateBeforeAddToSchedule() {
      if (this.contactScheduleObject.agency == null) {
        this.showToast(
          "danger",
          "top-right",
          "Oops!",
          "XIcon",
          "Please fill the agency field"
        );
        return true;
      }
      if (this.contactScheduleObject.timezone_id == null) {
        this.showToast(
          "danger",
          "top-right",
          "Oops!",
          "XIcon",
          "Please select a timezone"
        );
        return true;
      }
      if (this.contactScheduleObject.time_from == null) {
        this.showToast(
          "danger",
          "top-right",
          "Oops!",
          "XIcon",
          "Please select a time from"
        );
        return true;
      }
      if (this.contactScheduleObject.time_to == null) {
        this.showToast(
          "danger",
          "top-right",
          "Oops!",
          "XIcon",
          "Please select a time to"
        );
        return true;
      }

      if (
        this.contactScheduleObject.time_from >=
        this.contactScheduleObject.time_to
      ) {
        this.showToast(
          "danger",
          "top-right",
          "Oops!",
          "XIcon",
          "Time from must be less than time to"
        );
        return true;
      }
      this.addToSchedule();
    },
    removeSchedule(index) {
      this.schedule.splice(index, 1);
      this.userData.schedule = this.schedule;
    },
    addToSchedule() {
      const obj = { ...this.contactScheduleObject };
      this.schedule.push(obj);
      this.clearFields();
    },
    async validate(){
      return await this.$refs.contactScheduleObserver.validate();
    }
  },
};
</script>
  <style lang="scss">
.paddingB {
  padding-bottom: 4px !important;
}
.paddingT {
  padding-top: 4px !important;
}
.ppaddingTP {
  padding-top: 23px !important;
}
.paddingIconoX {
  padding-top: 8px !important;
  padding-left: 3px !important;
}
.widthInput {
  width: 96%;
}
.widthInputPhone {
  width: 80%;
}
</style>
  