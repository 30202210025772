<template>
  <b-modal
    ref="modal-transactions-authorize"
    size="xmd"
    @hidden="$emit('hidden')"
    no-close-on-backdrop
  >
    <!-- TITLE MODAL -->
    <template #modal-title>
      <span class="font-weight-bolder"> ADD TRANSACTIONS AUTHORIZE </span>
    </template>
    <!-- BODY MODAL -->
    <template #default>
      <b-alert class="p-1" show variant="info">
        <feather-icon icon="InfoIcon" size="20" class="mr-1" />
        Complete the information to correctly record the transaction
      </b-alert>
      <validation-observer ref="refFormTransactionsAuthorize">
        <!-- Client or lead information -->
        <b-badge class="text-uppercase" variant="light-primary">
          {{ isClient ? "Client" : "Lead" }} Information
        </b-badge>
        <div class="border rounded p-1 mb-2">
          <b-overlay
            :show="finishSearch.loading"
            rounded="sm"
            :variant="isDarkSkin ? 'dark' : 'white'"
          >
            <b-row>
              <b-col cols="2">
                <b-form-group
                  label="Select an option"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    id="radio-group-1"
                    v-model="type"
                    :options="type_options"
                    :aria-describedby="ariaDescribedby"
                    name="radio-options"
                    @change="resetFields"
                    :disabled="finishSearch.found"
                  ></b-form-radio-group>
                </b-form-group>
              </b-col>
              <b-col cols="5">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Program"
                >
                  <b-form-group label="Program">
                    <v-select
                      v-model="program"
                      :options="allPrograms"
                      :reduce="(option) => option.id"
                      label="name"
                      placeholder="Select a Program"
                      :class="errors[0] ? 'border-danger rounded' : ''"
                      @input="resetAutosuggest"
                      :disabled="finishSearch.found"
                    />
                    <small v-if="errors[0]" class="text-danger">{{
                      errors[0]
                    }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="5" v-if="program !== null && (isClient || isLead)">
                <validation-provider
                  v-slot="{ errors }"
                  rules="customRequired"
                  v-if="!finishSearch.found"
                >
                  <b-form-group :label="isClient ? 'Client' : 'Lead'">
                    <vue-autosuggest
                      ref="autocomplete"
                      :suggestions="filteredOptions"
                      :get-suggestion-value="getSuggestionValue"
                      :input-props="{
                        id: 'autosuggest__input',
                        class: ['form-control', errors[0]],
                      }"
                      @input="onInputChange"
                      @selected="selectHandler"
                      v-model="lead_name"
                    >
                      <template slot="before-input">
                        <div
                          class="lds-dual-ring"
                          v-show="autosuggestLoading"
                        ></div>
                      </template>

                      <template slot-scope="{ suggestion }">
                        <span
                          class="d-flex justify-content-between align-items-center my-suggestion-item cursor-pointe"
                        >
                          {{ suggestion.item.lead_name }}
                          <span
                            class="text-secondary"
                            v-if="
                              suggestion.item.nickname != null &&
                              suggestion.item.nickname != ''
                            "
                          >
                            ({{ suggestion.item.nickname }})
                          </span>

                          <status-account
                            v-if="isClient"
                            :account="{
                              icon_status: suggestion.item.icon_status,
                              color_status: suggestion.item.color_status,
                              parent_name: suggestion.item.parent_name,
                              name_status: suggestion.item.name_status,
                              program_id: suggestion.item.program_id,
                              status: suggestion.item.status,
                            }"
                            :text="false"
                          ></status-account>
                        </span>
                      </template>
                    </vue-autosuggest>
                    <small v-if="errors[0]" class="text-danger">{{
                      errors[0]
                    }}</small>
                  </b-form-group>
                </validation-provider>
                <b-form-group :label="isClient ? 'Client' : 'Lead'" v-else>
                  <b-form-input
                    v-model="lead_name"
                    :disabled="true"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-overlay>
        </div>

        <!-- Transaction information -->
        <b-badge class="text-uppercase" variant="light-primary">
          Transaction Information
        </b-badge>
        <div class="border rounded p-1 mb-2">
          <b-row>
            <b-col cols="4">
              <b-form-group label="Authorize Transaction ID">
                <b-form-input
                  v-model="authorizeTransaction.authorize_transaction_id"
                  :disabled="true"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Authorize Full Name">
                <b-form-input
                  v-model="authorizeTransaction.fullname"
                  :disabled="true"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Authorize Amount">
                <b-form-input
                  :value="'$ ' + authorizeTransaction.amount"
                  :disabled="true"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Authorize Submit Date">
                <b-form-input
                  :value="
                    authorizeTransaction.submit_date | moment('MM/DD/YYYY')
                  "
                  :disabled="true"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Authorize Merchant">
                <b-form-input
                  v-model="authorizeTransaction.description"
                  :disabled="true"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-group label="Modality">
                  <v-select
                    v-model="modality"
                    :options="modalities"
                    :reduce="(option) => option.id"
                    label="value"
                    placeholder="Select a Modality"
                    :class="errors[0] ? 'border-danger rounded' : ''"
                    @input="changeModality()"
                  />
                  <small v-if="errors[0]" class="text-danger">{{
                    errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="4" v-if="(isPayment || isInitialPayment) && isLead">
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-group label="Transaction Types">
                  <v-select
                    v-model="transactionType"
                    :options="transactionTypes"
                    :reduce="(option) => option.id"
                    label="value"
                    placeholder="Select a transaction type"
                    :class="errors[0] ? 'border-danger rounded' : ''"
                    @input="changeTransactionTypes()"
                  />
                  <small v-if="errors[0]" class="text-danger">{{
                    errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="8"
              v-if="
                isInitialPayment &&
                isLead &&
                lead_or_client_id != null &&
                transactionType == 3
              "
            >
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-group label="Sales">
                  <v-select
                    v-model="sale_id"
                    :options="sales"
                    :reduce="(option) => option.id"
                    label="value"
                    placeholder="Select a sale"
                    :class="errors[0] ? 'border-danger rounded' : ''"
                  />
                  <small v-if="errors[0]" class="text-danger">{{
                    errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="4" v-if="isPayment && isLead && transactionType == 6">
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-group label="Other">
                  <b-form-input
                    v-model="specifyOther"
                    :state="errors[0] ? false : null"
                    placeholder="Specific"
                  />
                  <small v-if="errors[0]" class="text-danger">{{
                    errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="4" v-if="isCharge && isClient">
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-group label="Type of Charges">
                  <v-select
                    v-model="chargeId"
                    :options="typeCharges"
                    :reduce="(option) => option.id"
                    label="description"
                    placeholder="Select a Charge"
                    :class="errors[0] ? 'border-danger rounded' : ''"
                  />
                  <small v-if="errors[0]" class="text-danger">{{
                    errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="4" v-if="chargeId == 5 && isCharge && isClient">
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-group label="Others">
                  <b-form-input
                    v-model="specifyTypeCharge"
                    :state="errors[0] ? false : null"
                    placeholder="Specify Type of Charge"
                  />
                  <small v-if="errors[0]" class="text-danger">{{
                    errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </div>
      </validation-observer>
    </template>
    <template #modal-footer>
      <b-button variant="primary" @click="processAuthorizeTransactionManual()">
        Save
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import ClientService from "@/views/crm/views/clients/service/clients.service.js";
import PaymentsService from "@/views/crm/views/payments/service/payments.service.js";
import { VueAutosuggest } from "vue-autosuggest";
import { mapGetters } from "vuex";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";

export default {
  components: {
    VueAutosuggest,
    StatusAccount,
  },
  props: {
    authorizeTransactionId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      allPrograms: [],
      modalities: [],
      type_options: [
        { value: 1, text: "Client" },
        { value: 2, text: "Lead" },
      ],
      authorizeTransaction: [],
      filteredOptions: [],
      typeCharges: [],
      transactionTypes: [],
      sales: [],
      program: null,
      modality: null,
      type: 1, //1 client - 2 lead
      sale_id: null,
      lead_or_client_id: null,
      autosuggestLoading: false,
      search: null,
      lead_name: "",
      chargeId: null,
      specifyTypeCharge: null,
      transactionType: null,
      specifyOther: null,
      transactionIsFromClient: null,
      finishSearch: {
        found: false,
        loading: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isClient() {
      return this.type == 1;
    },
    isCharge() {
      return this.modality == 4;
    },
    isLead() {
      return this.type == 2;
    },
    isPayment() {
      return this.modality == 3;
    },
    isInitialPayment() {
      return this.modality == 2;
    },
  },
  mounted() {
    this.toggleModal("modal-transactions-authorize");
    this.getAllPrograms();
    this.getTransactionModalities();
    this.getAuthorizeTransactions();
  },
  watch: {
    lead_name() {
      this.listSales();
    },
  },
  methods: {
    hasOnlyNumbers(str) {
      return /^\d+$/.test(str);
    },
    async getAllPrograms() {
      try {
        this.addPreloader();
        const data = await ClientService.getAllPrograms();
        this.allPrograms = data;
        if (this.isClient) return;
        this.allPrograms.push({ id: 0, name: "C.R.M" }); // is lead
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async getTransactionModalities() {
      try {
        this.addPreloader();
        const { data } = await PaymentsService.getTransactionModalities({
          type: this.type,
        });
        this.modalities = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async setAutomaticOptions() {
      this.search = this.authorizeTransaction.client_full_name;
      this.type = this.transactionIsFromClient == true ? 1 : 2;
      if (this.type == 1) {
        this.program = this.authorizeTransaction.program_id;
        await this.onInputChange(this.authorizeTransaction.client_account);
      } else {
        await this.getAllPrograms();
        await this.getTransactionModalities();
        await this.listTypeCharges();
        this.program = 0;
        this.onInputChange(this.authorizeTransaction.lead_full_name);
      }
      setTimeout(() => {
        if (
          this.filteredOptions.length > 0 &&
          this.filteredOptions[0].data.length > 0
        ) {
          this.lead_or_client_id = this.filteredOptions[0].data[0].id;
          this.lead_name = this.filteredOptions[0].data[0].lead_name;
          this.finishSearch.found = true;
        }
        this.finishSearch.loading = false;
      }, 1000);
    },
    async getAuthorizeTransactions() {
      try {
        this.addPreloader();
        const { data } = await PaymentsService.getAuthorizeTransactions({
          authorize_transaction_id: this.authorizeTransactionId,
        });
        this.authorizeTransaction = data[0];

        if (this.authorizeTransaction.client_account != null) {
          this.transactionIsFromClient = !this.hasOnlyNumbers(
            this.authorizeTransaction.client_account
          );
          await this.setAutomaticOptions();
        } else {
          this.finishSearch.loading = false;
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.lead_name.trim() === ""
        ? suggestion.item.nickname
        : suggestion.item.lead_name;
    },
    async onInputChange(text) {
      this.lead_or_client_id = null;
      this.autosuggestLoading = true;
      if (text === "" || text === undefined) {
        this.filteredOptions = [];
        this.autosuggestLoading = false;
        return;
      }
      this.search = text;
      await this.getClientsOrLeadsForProgram();
    },
    selectHandler(value) {
      if (value == null) {
        return;
      }
      this.lead_or_client_id = value.item.id;
    },
    resetFields() {
      this.modality = null;
      this.program = null;
      this.allPrograms = [];
      this.getTransactionModalities();
      this.resetAutosuggest();
      this.getAllPrograms();
    },
    resetAutosuggest() {
      this.lead_or_client_id = null;
      this.lead_name = null;
      this.filteredOptions = [];
      this.autosuggestLoading = false;
      this.search = null;
      this.listTypeCharges();
    },
    async getClientsOrLeadsForProgram() {
      try {
        const { data } = await PaymentsService.getClientsOrLeadsForProgram({
          program_id: this.program,
          type: this.type,
          search: this.search,
        });
        this.filteredOptions = [{ data: [...data] }];
        this.autosuggestLoading = false;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async processAuthorizeTransactionManual() {
      try {
        const validate =
          await this.$refs.refFormTransactionsAuthorize.validate();
        if (!validate) return;
        if (!this.lead_or_client_id) {
          let msg = this.isLead ? "Lead" : "Client";
          this.showToast(
            "warning",
            "top-right",
            "warning",
            "AlertTriangleIcon",
            `${msg} does not exist in selected program`
          );
          return;
        }
        const confirm = await this.showConfirmSwal();
        if (confirm.isConfirmed) {
          this.addPreloader();
          const params = {
            authorize_transaction_id: this.authorizeTransactionId,
            lead_id: this.isLead ? this.lead_or_client_id : null,
            client_account_id: this.isClient ? this.lead_or_client_id : null,
            program_id: this.program,
            modality_id: this.modality,
            user_id: this.currentUser.user_id,
            charge_id: this.isClient ? this.chargeId : null,
            specify_type_charge: this.isClient ? this.specifyTypeCharge : null,
            type_transaction_id: this.isLead ? this.transactionType : null,
            obs: this.transactionType == 6 ? this.specifyOther : null,
            sale_id: this.sale_id,
          };
          const { data } =
            await PaymentsService.processAuthorizeTransactionManual(params);
          this.showSuccessSwal(data.message);
          this.$emit("refresh");
          this.$emit("hidden");
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    changeModality() {
      this.listTypeCharges();
      this.listTransactionTypes();
    },
    changeTransactionTypes() {
      this.listSales();
    },
    listTypeCharges() {
      this.typeCharges = [];
      this.chargeId = null;
      this.specifyTypeCharge = null;
      if (this.modality != 4 || this.program == null) return;
      this.getTypeCharges();
    },
    listSales() {
      if (
        this.lead_or_client_id != null &&
        this.authorizeTransaction.submit_date != null &&
        this.modality == 2 &&
        this.transactionType == 3
      ) {
        this.sales = [];
        this.sale_id = null;
        this.getSales();
      }
    },
    listTransactionTypes() {
      this.transactionTypes = [];
      this.transactionType = null;
      this.specifyOther = null;
      if (this.modality != 3 && this.modality != 2) return;
      this.getTransactionTypes();
    },
    async getTypeCharges() {
      try {
        this.addPreloader();
        const params = {
          program_id: this.program,
        };
        const { data } = await PaymentsService.getTypeCharges(params);
        this.typeCharges = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async getSales() {
      try {
        this.addPreloader();
        const params = {
          date: this.authorizeTransaction.submit_date,
          lead_id: this.lead_or_client_id,
        };
        const { data } = await PaymentsService.getSales(params);
        this.sales = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async getTransactionTypes() {
      try {
        this.addPreloader();
        const params = {
          modality: this.modality,
        };
        const { data } = await PaymentsService.getTransactionTypes(params);
        this.transactionTypes = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>