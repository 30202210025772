<template>
  <div
    class="dashboard__card"
    :class="isDarkSkin ? 'dashboard__card--dark' : ''"
  >
    <div class="card-report">
      <div class="report-header">
        <div class="report-header__title">
          <div class="report-header__title--icons">
            <img :src="imgActivity" alt="img_sales" />
          </div>
          <h2 class="report-header__title--text">Activities</h2>
        </div>
        <div class="d-flex align-items-center">
          <button
            class="btn-activity"
            :class="{ active: showContent === 'Meeting' }"
            @click="showMeetings('Meeting')"
          >
            <div
              v-if="
                G_MEETINGS_PENDING_CUSTOMER_TICKETS.counter_pending_meetings !=
                0
              "
              class="container-badge"
            >
              <b-badge variant="light-danger" pill class="badge-total">
                <span>{{
                  G_MEETINGS_PENDING_CUSTOMER_TICKETS.counter_pending_meetings >
                  99
                    ? "+99"
                    : G_MEETINGS_PENDING_CUSTOMER_TICKETS.counter_pending_meetings
                }}</span>
              </b-badge>
            </div>
            Meetings
          </button>
          <button
            class="btn-activity"
            :class="{ active: showContent === 'Task' }"
            @click="showTasks('Task')"
          >
            <div
              v-if="G_TASKS_PENDING_CUSTOMER_TICKETS != 0"
              class="container-badge"
            >
              <b-badge variant="light-danger" pill class="badge-total">
                <span>{{
                  G_TASKS_PENDING_CUSTOMER_TICKETS > 99
                    ? "+99"
                    : G_TASKS_PENDING_CUSTOMER_TICKETS
                }}</span>
              </b-badge>
            </div>
            Tasks
          </button>
        </div>
      </div>
      <div class="report-body h-100">
        <div
          v-if="showContent == 'Meeting'"
          class="card-meetings"
          :class="isDarkSkin ? 'card-meetings--dark' : ''"
        >
          <div class="head-meeting w-100">
            <div
              class="head-meeting__total pointer"
              :class="isDarkSkin ? 'head-meeting__total--dark' : ''"
              @click="modalMeeting"
            >
              <feather-icon
                v-b-tooltip.hover.v-primary
                v-b-tooltip.hover.top
                title="See All Meetings"
                icon="CalendarIcon"
                class="text-primary cursor-pointer"
              />
            </div>
          </div>
          <b-tabs
            v-model="tabIndexMeeting"
            lazy
            active-tab-class="p-0 "
            pills
            nav-class="mb-0"
            active-nav-item-class="bg-info box-shadow-info border-primary primary"
          >
            <b-tab
              :title="'Today (' + allMeetings.total_today + ')'"
              @click="typeActivityMeeting = 'today'"
            >
              <card-meeting
                :type-data="'meetings'"
                :data-list="meetingsToday"
                @getMeeting="getMeeting()"
              />
            </b-tab>
            <b-tab
              :title="'Upcoming (' + allMeetings.total_upcoming + ')'"
              @click="typeActivityMeeting = 'upcoming'"
            >
              <card-meeting
                :type-data="'meetings'"
                :data-list="meetingsUpcoming"
                @getMeeting="getMeeting()"
              />
            </b-tab>
            <b-tab
              :title="'Overdue (' + allMeetings.total_overview + ')'"
              @click="typeActivityMeeting = 'overview'"
            >
              <card-meeting
                :type-data="'meetings'"
                :data-list="meetingsOverview"
                @getMeeting="getMeeting()"
              />
            </b-tab>
          </b-tabs>

          <!-- <div v-if="showMeetings"> -->

          <!-- </div> -->
        </div>

        <div
          v-if="showContent == 'Task'"
          class="card-tasks"
          :class="isDarkSkin ? 'card-tasks--dark' : ''"
        >
          <div class="head-task w-100">
            <div
              v-b-tooltip.hover.v-primary
              v-b-tooltip.hover.top
              title="See All Tasks"
              class="head-task__total pointer"
              :class="isDarkSkin ? 'head-task__total--dark' : ''"
              @click="modalTasks"
            >
              <feather-icon
                icon="CalendarIcon"
                class="text-primary cursor-pointer"
              />
            </div>
          </div>

          <b-tabs
            v-model="tabIndexTask"
            lazy
            active-tab-class="p-0 "
            pills
            nav-class="mb-0"
            active-nav-item-class="bg-info box-shadow-info border-primary primary"
          >
            <b-tab
              :title="'Today (' + allTasks.total_today + ')'"
              @click="typeActivityTask = 'today'"
            >
              <card-meeting
                :type-data="'tasks'"
                :data-list="tasksToday"
                @getTask="getTasks()"
              />
            </b-tab>
            <b-tab
              :title="'Upcoming (' + allTasks.total_upcoming + ')'"
              @click="typeActivityTask = 'upcoming'"
            >
              <card-meeting
                :type-data="'tasks'"
                :data-list="tasksUpcoming"
                @getTask="getTasks()"
              />
            </b-tab>
            <b-tab
              :title="'Overdue (' + allTasks.total_overview + ')'"
              @click="typeActivityTask = 'overview'"
            >
              <card-meeting
                :type-data="'tasks'"
                :data-list="tasksOverview"
                @getTask="getTasks()"
              />
            </b-tab>
          </b-tabs>

          <!-- <div
            class="container-task"
            :class="isDarkSkin ? 'container-task--dark' : ''"
            v-if="listTasks.length > 0"
          >
            <div
              v-for="(item, i) in listTasks"
              :key="i"
              class="task"
              :class="isDarkSkin ? 'task--dark' : ''"
            >
              <div
                class="container_icons"
                :class="isDarkSkin ? 'container_icons--dark' : ''"
              >
                <img v-if="!isDarkSkin" :src="imgTask" alt="img_sales" />
                <img v-else :src="imgTaskDark" alt="img_sales_dark" />
              </div>

              <div
                class="container_fecha"
                :class="isDarkSkin ? 'container_fecha--dark' : ''"
              >
                <div class="name-task">
                  {{ item.title }}
                </div>
                <div
                  class="date"
                  :class="isDarkSkin ? 'meetings__hour--dark' : ''"
                >
                  <div v-if="item.hour_task != null">
                    {{ item.max_date | myDateText }} |
                    {{ item.hour_task | myTime }}
                  </div>
                  <div v-else>
                    {{ item.max_date | myDateText }}
                  </div>
                  <div>
                    <feather-icon
                      v-b-tooltip.hover.v-success
                      v-b-tooltip.hover.bottom
                      title="COMPLETE"
                      icon="CheckCircleIcon"
                      size="18"
                      class="text-success hover-me cursor-pointer"
                      style="margin-right: 8px"
                      @click="updateTask(item, 2)"
                    />
                    <feather-icon
                      v-b-tooltip.hover.v-primary
                      v-b-tooltip.hover.bottom
                      title="EDIT"
                      icon="Edit2Icon"
                      size="18"
                      class="text-primary hover-me cursor-pointer"
                      style="margin-right: 8px"
                      @click="updateTask(item, 1)"
                    />
                    <feather-icon
                      v-b-tooltip.hover.v-danger
                      v-b-tooltip.hover.bottom
                      title="DELETE"
                      icon="XCircleIcon"
                      size="18"
                      class="text-danger hover-me cursor-pointer"
                      style="margin-right: 8px"
                      @click="cancelTask(item.id)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <!-- <div v-else class="task-done">
            <div>
              <img
                :src="imgTaskDone"
                alt="img_sales"
                width="180px"
                height="180px"
              />
            </div>
            <div class="text-task" :class="isDarkSkin ? 'text-task--dark' : ''">
              All Tasks Done!
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <modal-list-meetings
      v-if="showModalMeetings"
      @closeModal="showModalMeetings = false"
    />
    <modal-calendar-task
      v-if="showModalTask"
      @closeModal="showModalTask = false"
    />
  </div>
</template>
<script>
import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service";
import ModalListMeetings from "@/views/commons/components/customer-tickets/components/modal/meetings/ListMeetings.vue";
import ModalCalendarTask from "@/views/commons/components/customer-tickets/components/modal/tasks/ModalCalendarTask.vue";
import CustomerTicketsServices from "@/views/commons/components/customer-tickets/services/customer-tickets.service";
import ModalTaskComponent from "@/views/commons/components/customer-tickets/components/modal/tasks/ModalTask.vue";
import ModalAttended from "@/views/commons/components/customer-tickets/components/modal/meetings/ModalAttended.vue";
import CardMeeting from "@/views/commons/components/customer-tickets/components/modal/CardMeeting.vue";
import { mapGetters, mapActions } from "vuex";
import store from "@/store";

export default {
  components: {
    ModalListMeetings,
    ModalCalendarTask,
    ModalTaskComponent,
    ModalAttended,
    CardMeeting,
  },

  data() {
    return {
      imgActivity: require("@/assets/images/icons/dashboard-ceo/activity.svg"),
      imgTask: require("@/assets/images/icons/dashboard-ceo/task.svg"),
      imgTaskDark: require("@/assets/images/icons/dashboard-ceo/task-dark.svg"),
      imgTaskDone: require("@/assets/images/icons/dashboard-ceo/task-done.svg"),
      imgEventDone: require("@/assets/images/icons/dashboard-ceo/event-done.svg"),
      dataYear: null,
      dataMonth: null,
      listTasks: [],
      listMeetings: [],
      totalTasks: 0,
      totalMeeting: 0,
      showModalMeetings: false,
      showModalTask: false,
      showModalTask2: false,
      showContent: "Meeting",
      showModalAttended: false,
      statusMeeting: null,
      typeActivityMeeting: null,
      typeActivityTask: null,
      allMeetings: {
        total_today: 0,
        total_overview: 0,
        total_upcoming: 0,
      },
      allTasks: {
        tasks_today: 0,
        tasks_overview: 0,
        tasks_upcoming: 0,
      },
      tabIndexMeeting: 0,
      tabIndexTask: 0,
      meetingsToday: [],
      meetingsUpcoming: [],
      meetingsOverview: [],
      tasksToday: [],
      tasksOverview: [],
      tasksUpcoming: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_TASKS_PENDING_CUSTOMER_TICKETS:
        "NotificationStore/G_TASKS_PENDING_CUSTOMER_TICKETS",
      G_COUNT_PENDING_CUSTOMER_TICKETS:
        "NotificationStore/G_COUNT_PENDING_CUSTOMER_TICKETS",
      G_MEETINGS_PENDING_CUSTOMER_TICKETS:
        "NotificationStore/G_MEETINGS_PENDING_CUSTOMER_TICKETS",
    }),
  },

  watch: {
    G_MEETINGS_PENDING_CUSTOMER_TICKETS() {
      this.getMeeting();
    },
    G_TASKS_PENDING_CUSTOMER_TICKETS() {
      this.getTasks();
    },
    typeActivityMeeting() {
      switch (this.typeActivityMeeting) {
        case "today":
          this.listMeetings = this.meetingsToday;
          break;
        case "overview":
          this.listMeetings = this.meetingsOverview;
          break;
        case "upcoming":
          this.listMeetings = this.meetingsUpcoming;
          break;
      }
    },
    typeActivityTask() {
      switch (this.typeActivityTask) {
        case "today":
          this.listTasks = this.allTasks.tasks_today;
          break;
        case "overview":
          this.listTasks = this.allTasks.tasks_overview;
          break;
        case "upcoming":
          this.listTasks = this.allTasks.tasks_upcoming;
          break;
      }
    },
    allTasks() {
      this.typeActivityTask;
    },
    allMeetings() {
      this.typeActivityMeeting;
    },
  },
  async mounted() {
    await store.dispatch("NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS", {
      user_id: this.currentUser.user_id,
      client_account_id: null,
    });
    await store.dispatch(
      "NotificationStore/A_MEETINGS_PENDING_CUSTOMER_TICKETS"
    );
    await store.dispatch("NotificationStore/A_TASKS_PENDING_CUSTOMER_TICKETS");
  },
  async created() {
    await this.getTasks();
    await this.getMeeting();
  },
  methods: {
    ...mapActions({
      A_TASKS_PENDING_CUSTOMER_TICKETS:
        "NotificationStore/A_TASKS_PENDING_CUSTOMER_TICKETS",
    }),
    modalMeeting() {
      this.showModalMeetings = true;
    },
    modalTasks() {
      this.showModalTask = true;
    },
    showMeetings(eyelash) {
      this.showContent = eyelash;
    },
    showTasks(eyelash) {
      this.showContent = eyelash;
    },
    AttendMeeting({
      ticket_customer_id,
      name_client,
      code_ticket,
      meeting_start,
      meeting_end,
      meeting_date,
      created_by,
      module_id_client,
      phone,
      account,
    }) {
      this.statusMeeting = 4;
      this.paramsticketAttended = {
        id: ticket_customer_id,
        name_client,
        code: code_ticket,
        meeting: {
          date: meeting_date,
          hour_start: meeting_start,
          hour_end: meeting_end,
          created_by,
        },
        module_id: module_id_client,
        number_phone: phone,
        account,
      };
      this.showModalAttended = true;
      this.closeBodyMeeting(); // in case before action was reprogramm meeting
    },
    notAnswerMeeting({
      ticket_customer_id,
      name_client,
      code_ticket,
      meeting_start,
      meeting_end,
      meeting_date,
      created_by,
      module_id_client,
      phone,
      account,
    }) {
      this.statusMeeting = 5;
      this.paramsticketAttended = {
        id: ticket_customer_id,
        name_client,
        code: code_ticket,
        meeting: {
          date: meeting_date,
          hour_start: meeting_start,
          hour_end: meeting_end,
          created_by,
        },
        module_id: module_id_client,
        number_phone: phone,
        account,
      };
      this.showModalAttended = true;
      this.closeBodyMeeting(); // in case before action was reprogramm meeting
    },
    closeBodyMeeting() {
      this.showMeetingsTicket = false;
      this.paramsTicketReprogram = {};
      this.hoursReprogram = null;
    },
    async successAttended() {
      this.showModalAttended = false;
      this.showMeetingsTicket = false;
      this.meetingAnswered = true;
      await this.getMeeting();
    },
    async getTasks() {
      try {
        const { data } = await DashboardServive.getTasks();
        this.allTasks = data;
        this.tasksToday = data.tasks_today;
        this.tasksOverview = data.tasks_overview;
        this.tasksUpcoming = data.tasks_upcoming;
        this.indexTask();
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    async getMeeting() {
      try {
        const { data } = await DashboardServive.getMeeting();
        this.allMeetings = data;
        this.meetingsToday = data.meetings_today;
        this.meetingsOverview = data.meetings_overview;
        this.meetingsUpcoming = data.meetings_upcoming;
        this.indexMeeting();
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    indexMeeting() {
      if (this.allMeetings.total_today > 0) {
        this.typeActivityMeeting = "today";
        this.tabIndexMeeting = 0;
      } else if (this.allMeetings.total_upcoming > 0) {
        this.typeActivityMeeting = "upcoming";
        this.tabIndexMeeting = 1;
      } else {
        this.typeActivityMeeting = "overview";
        this.tabIndexMeeting = 2;
      }
    },
    indexTask() {
      if (this.allTasks.total_today > 0) {
        this.typeActivityTask = "today";
        this.tabIndexTask = 0;
      } else if (this.allTasks.total_upcoming > 0) {
        this.typeActivityTask = "upcoming";
        this.tabIndexTask = 1;
      } else {
        this.typeActivityTask = "overview";
        this.tabIndexTask = 2;
      }
    },
    async updateTask({ id, code }, status) {
      // change status to completed
      if (status === 2) {
        try {
          const { isConfirmed } = await this.showConfirmSwal();
          if (!isConfirmed) return;

          this.addPreloader();
          const params = {
            taskId: id,
            maxDate: null,
            title: null,
            description: null,
            createdBy: this.currentUser.user_id,
            status,
            allDay: null,
          };

          const { status: statusResult } =
            await CustomerTicketsServices.updateTicketCustomerTask(params);
          this.removePreloader();

          if (statusResult === 200) {
            this.showSuccessSwal();
            this.showContent = null;
            await this.getTasks();
            // this.showContent = 'meeting';
            await this.A_TASKS_PENDING_CUSTOMER_TICKETS({
              user_id: this.currentUser.user_id,
            });
          }
        } catch (error) {
          this.removePreloader();
          this.showErrorSwal();
          console.log("Error update task:", error);
        }
      } else {
        // edit information of task
        this.showModalTask2 = true;
        this.paramsModalTask = {
          id,
          code,
        };
        this.taskId = id;
      }
    },
    async cancelTask(taskId) {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;

        this.addPreloader();
        const params = {
          taskId,
          deletedBy: this.currentUser.user_id,
        };
        const { status } =
          await CustomerTicketsServices.deletedTicketCustomerTask(params);
        this.removePreloader();
        if (status === 200) {
          this.showSuccessSwal();
          await this.getTasks();
          await this.A_TASKS_PENDING_CUSTOMER_TICKETS({
            user_id: this.currentUser.user_id,
          });
        }
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
        console.log("error in cancelTask:", error);
      }
    },
    async successTask() {
      this.showModalTask2 = false;
      this.paramsModalTask = {};
      await this.getTasks();
      await this.A_TASKS_PENDING_CUSTOMER_TICKETS({
        user_id: this.currentUser.user_id,
      });
    },
    closeModalTask() {
      this.showModalTask = false;
      this.paramsModalTask = {};
    },
  },
};
</script>
<style lang="scss" scoped>
$color-primary: #5f5f5f;
$color-secondary: #ffffff;
$bg-card-white: #ffffff;
$bg-card-dark: #111113;
$box-shadow-white: #00000026;

.dashboard__card {
  margin-top: 5px;
  box-shadow: 0px 0px 15px 0px $box-shadow-white;
  border-radius: 15px;
  margin-bottom: 27px;
  padding: 18px;
  background-color: $bg-card-white;
  height: 100%;

  &--dark {
    background-color: $bg-card-dark;
    box-shadow: 0px 0px 22.935px 0px rgba(255, 249, 249, 0.1);
  }

  .card-report {
    position: relative;

    .report-header {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;

      &__title {
        display: flex;

        &--icons {
          width: 46.28px;
          height: 46.28px;
          background-color: #a7c5ff;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;

          img {
            width: 26.935px;
            height: 27px;
          }
        }

        &--text {
          color: #959595;
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          padding-left: 14px;
          display: flex;
          align-items: center;
          margin-bottom: 0px;
        }
      }

      .btn-activity {
        margin-left: 15px;
        padding: 4px 8px;
        background-color: transparent;
        border: none;
        font-size: 0.9rem;
        color: #a3a3a7;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        display: flex;
        position: relative;

        .container-badge {
          position: absolute;
          top: -3px;
          right: 0;

          .badge-total {
            width: 16px;
            height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #ff3d60;

            span {
              color: #ffffff;
            }
          }
        }
      }
      .active {
        border-bottom: 3px solid #2360d3;
        color: #2360d3;
      }
    }

    .report-body {
      margin-top: 10px;

      .card-tasks {
        margin-top: 3px;

        .head-task {
          display: flex;
          justify-content: end;
          margin-bottom: 10px;

          &__total {
            background-color: #f7f7f7;
            color: #898989;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            padding: 6px 10px;
            border-radius: 8px;

            span {
              margin-left: 12px;
            }
            &:hover {
              background-color: rgb(231, 227, 237);
            }

            &--dark {
              background-color: #1a1a1a;

              &:hover {
                background-color: #0f0c11;
              }
            }
          }
        }

        &--dark {
          background-color: $bg-card-dark;
        }

        .container-task {
          height: 320px;
          background-color: #edf0f7;
          border-radius: 10px;
          padding: 10px;
          overflow-y: auto;

          &--dark {
            background-color: #0d0d0c;
          }
        }
        .task-done {
          height: 326px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;

          .text-task {
            color: #343232;
            font-family: Montserrat;
            font-size: 18px;
            font-weight: bold;
            margin-top: 12px;

            &--dark {
              color: #d4cece;
            }
          }
        }

        .task {
          padding: 10px;
          margin-bottom: 10px;
          background: rgb(255, 255, 255);
          border-left: 10px solid #4e68f1;
          border-radius: 12px;
          display: flex;

          &--dark {
            background-color: #090b11;
            border-left: 10px solid #3f7afa;
          }

          .container_icons {
            background-color: #edf0ff;
            width: 45px;
            height: 45px;
            padding: 5px 8px;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;

            &--dark {
              background-color: #1654cc;
            }
          }

          .container_fecha {
            height: 50px;
            display: flex;
            flex-direction: column;
            margin-left: 20px;
            width: 100%;

            .name-task {
              color: #9095ad;
              font-family: Montserrat;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
            }
            .date {
              margin-top: 3px;
              color: #404040;
              font-family: Montserrat;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              display: flex;
              justify-content: space-between;
              width: 100%;
            }

            &--dark {
              .name-task {
                color: #595b61;
              }

              .date {
                color: #a8a8a8;
              }
            }
          }

          &__name {
            span {
              margin-left: 5px;
            }
          }

          &__date {
            color: rgb(143, 0, 171);
            &--max-date {
              margin-right: 7px;
            }

            &--hour {
              margin-left: 33px;
            }
            &--dark {
              color: rgb(212, 109, 233);
            }
          }
        }
      }

      .card-meetings {
        margin-top: 3px;

        .head-meeting {
          display: flex;
          justify-content: end;
          margin-bottom: 10px;

          &__total {
            background-color: #f7f7f7;
            color: #898989;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            padding: 6px 10px;
            border-radius: 8px;

            span {
              margin-left: 12px;
            }

            &:hover {
              background-color: rgb(231, 227, 237);
            }

            &--dark {
              background-color: #1a1a1a;

              &:hover {
                background-color: #0f0c11;
              }
            }
          }
        }

        &--dark {
          background-color: $bg-card-dark;
        }

        .container-meeting {
          height: 320px;
          background-color: #edf0f7;
          border-radius: 10px;
          padding: 10px;
          overflow-y: auto;

          &--dark {
            background-color: #0d0d0c;
          }

          .meetings {
            padding: 10px;
            margin-bottom: 10px;
            background: rgb(255, 255, 255);
            border-left: 10px solid #4e68f1;
            border-radius: 12px;
            display: flex;

            &--dark {
              background-color: #090b11;
              border-left: 10px solid #3f7afa;
            }

            .container_icons {
              background-color: #edf0ff;
              width: 45px;
              height: 45px;
              padding: 5px 8px;
              border-radius: 10px;
              display: flex;
              justify-content: center;
              align-items: center;

              &--dark {
                background-color: #1654cc;
              }
            }
            .container_fecha {
              height: 50px;
              display: flex;
              flex-direction: column;
              margin-left: 20px;

              .date {
                color: #9095ad;
                font-family: Montserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
              }
              .hour {
                margin-top: 3px;
                color: #404040;
                font-family: Montserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
              }

              &--dark {
                .date {
                  color: #595b61;
                }

                .hour {
                  color: #a8a8a8;
                }
              }
            }
          }
        }

        .meeting-done {
          height: 326px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;

          .text-meeting {
            color: #343232;
            font-family: Montserrat;
            font-size: 18px;
            font-weight: bold;
            margin-top: 12px;

            &--dark {
              color: #d4cece;
            }
          }
        }
      }
    }
  }
}
</style>
