<template>
  <div>
    <b-modal
      ref="myModal"
      v-model="ownControl"
      modal-class="modal-primary"
      modal
      size="xlg"
      scrollable
      :hide-footer="true"
      header-class="p-0"
      header-bg-variant="transparent border-bottom border-bottom-2"
      @hidden="$emit('close')"
    >
      <template v-slot:modal-header>
        <div class="header-modal">

          <div
            class="ml-1"
            style="text-transform: uppercase;"
          >
            {{ modalName }} ( {{ cardChosen.showName }} ) ({{ G_TITLE_MODAL }})
          </div>

          <b-button
            size="sm"
            :variant=" isDarkSkin ? 'dark' : 'light' "
            class="btn_close px-0"
            style="width:35px;"
            @click="close()"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer "
              :class=" isDarkSkin ? 'text-white' : 'text-black'"
            />
          </b-button>

        </div>
      </template>
      <div>
        <b-row>
          <b-col
            cols="12"
          >
            <filter-slot
              ref="filterslotModal"
              :filter="[]"
              :no-visible-principal-filter="false"
              :filter-principal="filterPrincipal"
              :total-rows="totalRows"
              :paginate="paginate"
              :start-page="startPage"
              :to-page="toPage"
              @reload="$refs.ceDsListTable.refresh()"
            >
              <template #buttons>
                <v-select
                  v-if="cardChosen.sendBack == 'removed_accounts'"
                  v-model="accountType"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="typesOfAccount"
                  :reduce="(val) => val.id"
                  label="value"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                  style="width: 200px;"
                  @input="$refs.ceDsListTable.refresh()"
                />
                <v-select
                  v-if="cardChosen.sendBack == 'regular_ncr'"
                  v-model="paymentOption"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="typesOfPaymentOptionsRegular"
                  :reduce="(val) => val.id"
                  label="value"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                  style="width: 200px;"
                  @input="$refs.ceDsListTable.refresh()"
                />
                <v-select
                  v-if="cardChosen.sendBack == 'digital_ncr'"
                  v-model="paymentOption"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="typesOfPaymentOptionsDigital"
                  :reduce="(val) => val.id"
                  label="value"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                  style="width: 200px;"
                  @input="$refs.ceDsListTable.refresh()"
                />
              </template>
              <template #table>
                <b-table
                  ref="ceDsListTable"
                  primary-key="id_detail"
                  :items="myProvider"
                  :fields="visibleFields"
                  table-class="text-nowrap"
                  responsive="sm"
                  show-empty
                  sticky-header="50vh"
                  :busy.sync="isBusy"
                  :per-page="paginate.perPage"
                  :current-page="paginate.currentPage"
                >
                  <template #table-busy>
                    <div class="text-center text-primary my-2">
                      <b-spinner class="align-middle mr-1" />
                      <strong>Loading ...</strong>
                    </div>
                  </template>
                  <template #cell(lead_full_name)="data">
                    <div>
                      <div
                        v-if="data.item.client_account_id"
                      >
                        <router-link
                          class
                          target="_blank"
                          :to="routers(data.item.program_id, data.item.client_account_id)"
                        >
                          <span>
                            {{ data.item.lead_full_name }} <br>
                          </span>
                          <span>
                            {{ data.item.account }}
                          </span>
                        </router-link>

                      </div>
                      <div v-else>
                        <span>
                          {{ data.item.lead_full_name }}
                        </span>
                        <br>
                        <span>
                          {{ data.item.account }}
                        </span>
                      </div>
                    </div>
                  </template>

                  <template #cell(created_by)="data">
                    <span>{{ data.item.created_by }}</span>
                    <br>
                    <span>{{ data.item.created_at | myGlobalDay }}</span>
                  </template>

                  <template #cell(details)="data">
                    <span>Account type: {{ data.item.account_type }}</span>
                    <br>
                    <span>Balance: {{ data.item.balance }}</span>
                    <br>
                    <span>Monthly payment: {{ data.item.m_payment }}</span>
                    <br>
                    <span>Account type: {{ data.item.account_type }}</span>
                    <br>
                    <span>Credit limit: {{ data.item.c_limit }}</span>
                    <br>
                    <div class="wrap-text">
                      <span>Payment status: {{ data.item.p_status }}</span>
                    </div>
                    <br>
                    <span>Status: {{ data.item.status_name }}</span>
                  </template>

                  <template #cell(account_name)="data">
                    <div class="wrap-text">
                      <span>{{ data.item.account_name }}</span>
                      <br>
                      <span>{{ data.item.account_number }}</span>
                    </div>
                  </template>

                  <template #cell(bureau)="data">
                    <div v-if="data.item.bureau_id === 1" style="font-weight: bold; width: 300px; text-align: center">
                                        <span>Trans</span><span style="color: #4d917a">Union</span>
                    </div>
                    <div v-if="data.item.bureau_id === 2" style="color: #0566b7;font-weight: bold;width: 300px;text-align: center;">Experian</div>
                    <div v-if="data.item.bureau_id === 3" style="color: #822a3a;font-weight: bold;width: 300px;text-align: center;">EQUIFAX</div>
                  </template>

                  <template #cell(status)="data">
                    <div class="text-center">
                      <span>{{ data.item.status }}</span>
                    </div>
                  </template>

                  <template #cell(completed_by)="data">
                    <span>{{ data.item.completed_by }}</span>
                    <br>
                    <span>{{ data.item.completed_at | myGlobalDay }}</span>
                  </template>

                </b-table>
              </template>
            </filter-slot>

          </b-col>
        </b-row>
      </div>
      <template #modal-footer>
        <b-row class="d-flex justify-content-end">
          <b-button variant="outline-success">
            SAVE
            <feather-icon icon="SaveIcon" />
          </b-button>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DashboardServive from '@/views/ceo/service-dashboard/dashboard.service.js';
import fieldsCeGeneral from '@/views/ceo/dashboard/data/fields-ce-data.js';
import fieldsCeAccounts from '@/views/ceo/dashboard/data/fields-ce-accounts.data.js';

export default {
  name: 'ModalClientStatus',
  components: {
    // FilterSlot
  },
  props: {
    dataClient: {
      type: Object,
      required: false,
      default: null,
    },
    typeModalName: {
      type: String,
      default: null,
      required: false,
    },
    cardChosen: {
      type: Object,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      ownControl: true,
      pdfIco: require('@/assets/images/icons/pdf.png'),
      fields: [],
      isBusy: false,

      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search by client name...',
        model: '',
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      startPage: 0,
      totalRows: 0,
      toPage: 0,
      searchInput: '',
      typesOfAccount: [
        { value: 'Deleted', id: 4 },
        { value: 'Updated', id: 3 },
      ],
      accountType: 4,
      typesOfPaymentOptionsRegular: [
        { value: 'Paid', id: 'regular_ncr_paid' },
        { value: 'All', id: null },
      ],
      typesOfPaymentOptionsDigital: [
        { value: 'Paid', id: 'digital_ncr_paid' },
        { value: 'All', id: null },
      ],
      paymentOption: null,

    };
  },
  async created() {
    if (this.modalType === 'direct_letters' || this.modalType === 'regular_letters') {
      this.fields = fieldsCeGeneral;
      this.fields[2].visible = true;
      this.fields[3].visible = false;
      this.fields[5].visible = false;
    } else if (this.modalType === 'disputed_accounts' || this.modalType === 'removed_accounts') {
      this.fields = fieldsCeAccounts;
    } else {
      this.fields = fieldsCeGeneral;
      this.fields[2].visible = false;
      this.fields[3].visible = true;
      this.fields[5].visible = true;
    }
  },
  computed: {
    ...mapGetters({
      G_TITLE_MODAL: 'DashboardCeoStore/G_TITLE_MODAL',
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    modalName() {
      return this.typeModalName == 'type_ds' ? 'DS STATISTICS' : 'CE STATISTICS';
    },
    modalType() {
      return this.cardChosen.sendBack;
    },
    visibleFields() {
      return this.fields.filter(field => field.visible);
    },
  },
  mounted() {

  },
  methods: {
    close(){
      this.$refs['myModal'].hide();
      this.$emit('close');
    },
    routers(program, id) {
      let route = '';
      switch (program) {
        case 1:
          route = `/bussiness/clients/account/${id}`;
          break;
        case 2:
          route = `/boostcredit/clients/account/${id}`;
          break;
        case 3:
          route = `/creditexperts/clients/account/${id}`;
          break;
        case 4:
          route = `/debtsolution/clients/account/${id}`;
          break;
        case 5:
          route = `/taxresearch/clients/account/${id}`;
          break;
        case 7:
          route = `/specialists/clients/account/${id}`;
          break;
        case 8:
          route = `/bookeeping/clients/account/${id}`;
          break;
        case 9:
          route = `/ti/clients/account/${id}`;
          break;
      }
      return route;
    },
    async myProvider() {
      const params = {
        year: this.dataClient.year,
        month: this.dataClient.month,
        card: this.paymentOption ? this.paymentOption : this.cardChosen.sendBack,
        search_text: this.filterPrincipal.model,
        nPage: this.paginate.currentPage,
        nPerPage: this.paginate.perPage,
        p_Stat: this.accountType,
      };
      const { data } = await DashboardServive.getCeStatisticsModal(params);
      this.startPage = data.from;
      this.paginate.currentPage = data.current_page;
      this.paginate.perPage = data.per_page;
      this.totalRows = data.total;
      this.toPage = data.to;
      return data.data || [];
    },
  },
};
</script>
  <style lang="scss" scoped>
  .header-modal{
      width: 100%;
      background:rgba(22, 84, 204, 1);
      height: 80px;
      color:#fff;
      font-size:18px;
      font-weight:bold;
      //border-radius: 10px 10px 0 0;
      //padding: 20px 0 20px 15px;
      display: flex;
      align-items: center;
  }

  .btn_close{
    position:absolute;
    top:21px;
    right:9px;
    transition: all 0.23s ease 0.1s;

    &:hover{
      transform: translate(-7px, 5px);
      cursor:pointer;
    }
  }

  .content_select{
    display: flex !important;
    flex-wrap: nowrap;
    justify-content: flex-end;

    .label_select{
      height:50px;
      display: flex !important;
      align-items: center;
    }

  }

  .wrap-text{
    white-space: pre-wrap; display: inline-block;
  }
  </style>
