export default [
  {
    key: "sender",
    label: "Nick Name",
    sortable: false,
    visible: true,
    thClass: "text-start",
    tdClass: "text-start",
  },
  // {
  //   key: "page",
  //   label: "Service",
  //   sortable: false,
  //   visible: true,
  // },
  {
    key: "sentiment",
    label: "Result",
    sortable: false,
    visible: true,
  },
  {
    key: "score",
    label: "Sentiment Score",
    sortable: false,
    visible: true,
    tdClass: "text-center",
    thClass: "text-center",
  },
  // {
  //   key: "sentimentDate",
  //   label: "Date",
  //   sortable: false,
  //   visible: true,
  // },
  {
    key: "messageCount",
    label: "Total Messages",
    sortable: false,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  // {
  //   key: "unreadCount",
  //   label: "Unread Messages",
  //   sortable: false,
  //   visible: true,
  //   thClass: "text-center",
  //   tdClass: "text-center",
  // },
  {
    key: "lastMessageDate",
    label: "Last Message Date",
    sortable: false,
    visible: true,
  },
  {
    key: "summary",
    // label: "Summary",
    label: "Actions",
    sortable: false,
    visible: true,
    thClass: "text-center",
  },
];
