<template>
  <div id="detailed-settlement-component">
    <b-modal
      v-model="onControl"
      header-bg-variant="transparent border-bottom border-bottom-2"
      size="xlg"
      header-class="p-0"
      modal-class="modal-primary"
      hide-footer
      scrollable
      @hidden="close()"
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <span>{{ 'Settlements' }}</span>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="$emit('close')"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>

        </div>
      </template>
      <b-card
        no-body
      >
        <filter-slot
          :filter="filters"
          class="filter-slot-new-customization"
          :filter-principal="filterPrincipal"
          :total-rows="totalRows"
          :paginate="paginate"
          :start-page="startPage"
          :to-page="toPage"
          :top-paginate="false"
          @reload="$refs['refDetailedSettlement'].refresh()"
        >
          <template #table>
            <b-table
              id="detailed-settlement-table"
              ref="refDetailedSettlement"
              no-border-collapse
              class="position-relative table-new-customization"
              :fields="fields"
              show-empty
              no-provider-filtering
              sticky-header="50vh"
              primary-key="id"
              responsive="sm"
              :items="myProvider"
              :current-page="paginate.currentPage"
              :per-page="paginate.perPage"
              :busy.sync="isBusy"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2 ">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </template>
              <template #empty>
                <div class="text-center text-primary my-2">
                  <strong>No existing data.</strong>
                </div>
              </template>
              <template #head(total_profit)="data">
                <div class="text-center">
                  {{ data.label }}
                </div>
              </template>
              <template #head(creditor_name)="data">
                <div class="text-center">
                  {{ data.label }}
                </div>
              </template>
              <template #head(settlemet_cost)="data">
                <div class="text-center">
                  {{ data.label }}
                </div>
              </template>
              <template #head(offer_amount)="data">
                <div class="text-center">
                  {{ data.label }}
                </div>
              </template>
              <template #head(created_at)="data">
                <div class="text-center">
                  {{ data.label }}
                </div>
              </template>
              <template #head(completed_date)="data">
                <div class="text-center">
                  {{ data.label }}
                </div>
              </template>
              <template #head(advisor_name)="data">
                <div class="text-center">
                  {{ data.label }}
                </div>
              </template>
              <template #cell(client_name)="data">
                <div>
                  <router-link
                    class
                    :to="{
                      name: 'debt-solution-dashboard',
                      params: {
                        idClient: data.item.client_account_id,
                      },
                    }"
                    target="_blank"
                  >{{ data.item.client_name }}
                  </router-link>
                  <status-account :account="data.item" />
                  <span>{{ data.item.account_id }}</span>
                </div>
              </template>
              <template #cell(creditor_name)="data">
                <div class="text-center">
                  <span>
                    <strong>{{ data.item.creditor_name }}</strong>
                    <br>
                    <span>#{{ data.item.creditor_account }}</span>
                  </span>
                </div>
              </template>
              <template #cell(total_profit)="data">
                <div class="text-center">
                  $ {{ data.item.total_profit | currencyZero }}
                </div>
              </template>
              <template #cell(settlemet_cost)="data">
                <div class="text-center">
                  $ {{ data.item.settlemet_cost | currencyZero }}
                </div>
              </template>
              <template #cell(offer_amount)="data">
                <div class="text-center">
                  $ {{ data.item.offer_amount | currencyZero }}
                </div>
              </template>
              <template #cell(completed_date)="data">
                <div class="text-center">
                  {{ data.item.completed_date | myGlobal }}
                </div>
              </template>
              <template #cell(advisor_name)="data">
                <div class="text-center">
                  {{ data.item.advisor_name }}
                </div>
              </template>
              <template #cell(created_at)="data">
                <div class="align-middle text-center">
                  {{ data.item.created_at | myGlobalWithHour }}
                </div>
              </template>
              <template #bottom-row>
                <b-th>
                  <b-badge
                    variant="primary"
                    class="w-100 text-left"
                    style="font-size: 14px; font-weight: 600"
                  >
                    TOTAL
                  </b-badge>
                </b-th>
                <b-th
                  class="fill-bottom-row"
                  colspan="1"
                />
                <b-th>
                  <b-badge
                    variant="primary"
                    class="w-100 text-center"
                    style="font-size: 14px; font-weight: 600"
                  >
                    {{ "$ " }}{{ totalOffer | currency }}
                  </b-badge>
                </b-th>
                <b-th>
                  <b-badge
                    variant="primary"
                    class="w-100 text-center"
                    style="font-size: 14px; font-weight: 600"
                  >
                    {{ "$ " }}{{ totalCost | currency }}
                  </b-badge>
                </b-th>
                <b-th>
                  <b-badge
                    variant="primary"
                    class="w-100 text-center"
                    style="font-size: 14px; font-weight: 600"
                  >
                    {{ "$ " }}{{ totalProfit | currency }}
                  </b-badge>
                </b-th>
                <b-th
                  class="fill-bottom-row"
                  colspan="3"
                />
              </template>
            </b-table>
          </template>
        </filter-slot>
      </b-card>
    </b-modal>
  </div>
</template>

<script>

import DashboardService from '@/views/debt-solution/views/dashboard/service/dashboard.service';
import StatusAccount from '@/views/commons/components/clients/stylescomponents/StatusAccount.vue';
import Filters from '@/views/debt-solution/views/dashboard/components/settlements/data/filters.data';

export default {
  components: {
    StatusAccount,
  },
  data() {
    return {
      debtSolutionProgram: 4,
      filters: Filters,
      totalProfit: 0,
      totalCost: 0,
      totalOffer: 0,
      onControl: false,
      startPage: 0,
      toPage: 0,
      isBusy: false,
      fields: [
        {
          key: 'client_name',
          sortable: false,
          label: 'Client Name',
          visible: true,
        },
        {
          key: 'creditor_name',
          sortable: false,
          label: 'Actual Creditor',
          visible: true,
        },
        {
          key: 'offer_amount',
          sortable: false,
          label: 'Total Amount',
          visible: true,
        },
        {
          key: 'settlemet_cost',
          sortable: false,
          label: 'Settlement Cost',
          visible: true,
        },
        {
          key: 'total_profit',
          sortable: false,
          label: 'Settlement Fee',
          visible: true,
        },
        {
          key: 'completed_date',
          sortable: false,
          label: 'Completed Date',
          visible: true,
        },
        {
          key: 'advisor_name',
          sortable: false,
          label: 'Advisor',
          visible: true,
        },
        {
          key: 'created_at',
          sortable: false,
          label: 'Enroll Date',
          visible: true,
        },
      ],
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      totalRows: 1,
      paginate: {
        perPage: 100,
        currentPage: 1,
      },
      statusNav: 2,
    };
  },
  computed: {},
  watch: {},
  async created() {
    this.onControl = true;
  },
  methods: {
    close() {
      this.onControl = false;
      this.$emit('close');
    },
    async myProvider() {
      const params = {
        page: this.paginate.currentPage,
        per_page: this.paginate.perPage,
        search_text: this.filterPrincipal.model,
        from_date: this.filters[0].model,
        to_date: this.filters[1].model,
      };

      const { data } = await DashboardService.getDebtSolutionDetailedSettlement(params);
      const items = data.data;
      this.startPage = data.from;
      this.paginate.currentPage = data.current_page;
      this.paginate.perPage = data.per_page;
      this.totalRows = data.total;
      this.toPage = data.to;
      this.totalProfit = data.data[0]?.settlement_total_profit;
      this.totalCost = data.data[0]?.settlement_total_cost;
      this.totalOffer = data.data[0]?.settlement_total_fee;
      return items || [];
    },
  },
};
</script>
<style lang="scss" scoped>
.text-money {
    font-weight: 500;

    &::before {
        content: "$";
    }
}

.header-modal {
    position: relative;
    padding: 20px 0 20px 15px;
    background: rgba(22, 84, 204, 1);
    width: 100%;
    font-size: 22px;
    display: flex;
    justify-content: space-betweeen;

    span {
        font-size: 1.6rem;
        font-weight: bold;
        margin-left: 8px;
        color: #FFFFFF;
    }

    .container-icons {
        background-color: #FFFFFF;
        padding: 2.7px 6.37px;
        border-radius: 0.357rem;
        width: 31px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 15px;
        right: 20px;
        transition: all 0.23s ease 0.1s;

        &--dark {
            background-color: #17171a;
        }

        &:hover {
            transform: translate(-7px, 5px);
            cursor: pointer;
        }

    }
}

.charge__programs {
width: 47px;
height: 40px;
background-color: #E9E9E9;
border-radius: 10px;
display: flex;
justify-content: center;
align-items: center;

  img {
      width: 35px;
      height: 35px;
  }
}
</style>
<style lang="scss">
  #detailed-settlement-table .b-table-bottom-row {
    position: sticky;
    bottom: 0px;
    z-index: 1;
  }
  #detailed-settlement-table .b-table-bottom-row th:nth-child(1) {
    border-radius: 0px 0px 0px 15px !important;
    margin-left: 5px !important;
    background: #0090e7 !important;
  }
  #detailed-settlement-table .b-table-bottom-row th:nth-child(2) {
    font-size: 16px !important;
    padding-top: 10px !important;
    border-radius: 0px 0px 0px 0px !important;
    padding-bottom: 10px !important;
    background: #0090e7 !important;
  }
  #detailed-settlement-table .b-table-bottom-row th:nth-child(3) {
    font-size: 16px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    border-radius: 0px 0px 0px 0px !important;
    background: #0090e7 !important;
  }
  #detailed-settlement-table .b-table-bottom-row th:nth-child(4) {
    font-size: 16px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    border-radius: 0px 0px 0px 0px !important;
    background: #0090e7 !important;
  }
  #detailed-settlement-table .b-table-bottom-row th:nth-child(5) {
    border-radius: 0px 0px 15px 0px !important;
    margin-left: 10px !important;
    background: #0090e7 !important;
  }
  #detailed-settlement-table .fill-bottom-row {
    background-color: #ffffff !important;
  }
  .dark-layout #detailed-settlement-table {
    .fill-bottom-row {
      background-color: #17171a !important;
      border-top: none !important;
    }
  }
  </style>
