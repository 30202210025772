export default [
  {
    key: "deposit_number",
    label: "Deposit ID",
    sortable: false,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "evidence",
    label: "Evidence",
    sortable: false,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "amount",
    label: "Deposit Amount",
    sortable: false,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "settlement_date",
    label: "Deposit Date",
    sortable: false,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "status_confirmation",
    label: "Status",
    sortable: false,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "created",
    label: "Created By",
    sortable: false,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "actions",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
]
