export default [
    {
      type: 'select',
      margin: true,
      showLabel: true,
      label: 'Department',
      model: null,
      options: [],
      reduce: 'id',
      selectText: 'name',
      cols: 12,
    },
  ]
  