<template>
  <b-modal
    title="Held For Review Transactions"
    ref="held-for-review-transactions-modal"
    @hidden="$emit('hidden')"
    size="caro"
    responsive
    hide-footer
  >
    <b-nav card-header pills class="mx-1 border-tab mt-1">
      <template v-for="(item, index) in tabs">
        <b-nav-item
          class="position-relative"
          :key="index"
          :link-classes="['px-3', bgTabsNavs]"
          :active="item.value === activeTab"
          @click="
            activeTab = item.value;
            getHeldForReviewTransactions(false);
          "
        >
          {{ item.name }}
          <b-badge
            v-if="
              (index == 0 && soft_count > 0) ||
              (index == 1 && authorizeCounter > 0)
            "
            pill
            variant="danger"
            class="position-absolute"
            style="top: -5px; right: -5px"
          >
            {{ index == 0 ? soft_count : authorizeCounter }}
          </b-badge>
        </b-nav-item>
      </template>
    </b-nav>
    <b-alert variant="warning" show class="mx-1" v-if="activeTab == 1">
      <div class="alert-body">
        <span>
          <feather-icon icon="InfoIcon" size="18" class="mr-1" />
          Here you can approve "Held For Review" transactions. You can register
          it in the AMG Software the next day from "Unmatched - Only Authorize"
          tab
        </span>
      </div>
    </b-alert>
    <b-table
      :busy="isBusy"
      class="px-1 held-transactions"
      striped
      :items="transactions"
      :fields="filterFields"
      table-class="text-nowrap"
      responsive="sm"
      show-empty
      no-provider-filtering
      sticky-header="65vh"
      style="overflow-x: hidden !important"
      small
      ref="ref-table-held-for-review-transactions"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Loading transactions...</strong>
        </div>
      </template>
      <template #cell(lastName)="data">
        <span class="text-primary" v-if="data.item.lastName != null">
          {{ data.item.firstName }} {{ data.item.lastName }}</span
        ><span v-else>-</span>
      </template>
      <template #cell(settleAmount)="data">
        <span class="text-primary"
          >$ {{ data.item.settleAmount | currencyZero }}</span
        >
      </template>
      <template #cell(soft_amount)="data">
        <span class="text-primary"
          >$ {{ data.item.soft_amount | currencyZero }}</span
        >
      </template>
      <template #cell(modality)="data">
        <b-badge variant="light-success" v-if="data.item.modality != null">{{
          data.item.modality
        }}</b-badge>
        <span v-else>-</span>
      </template>
      <template #cell(type)="data">
        <b-badge v-if="data.item.type != null" variant="light-info">{{
          data.item.type
        }}</b-badge>
        <span v-else>-</span>
      </template>
      <template #cell(method)="data">
        <b-badge variant="light-primary" v-if="data.item.method != null">{{
          data.item.method
        }}</b-badge>
        <span v-else>-</span>
      </template>
      <template #cell(submitTimeUTC)="data">
        {{ data.item.created_by ? data.item.created_by : "" }} <br />
        {{ data.item.submitTimeUTC | myGlobalDay }}
      </template>
      <template #cell(actions)="data">
        <div class="d-flex flex-column" v-if="activeTab == 0">
          <b-button
            size="sm"
            variant="outline-success"
            class="cursor-pointer my-1"
            @click="updateExpiredTransaction(data.item, 'approve')"
          >
            <span class="text-success"> Update Status</span>
          </b-button>
          <b-button
            size="sm"
            variant="outline-success"
            class="cursor-pointer mb-1"
            @click="ApproveOrDeclineHeldTransaction(data.item, 'approve')"
          >
            <span class="text-success"> Approve</span>
          </b-button>
          <b-button
            size="sm"
            variant="outline-danger"
            class="cursor-pointer mb-1"
            @click="ApproveOrDeclineHeldTransaction(data.item, 'decline')"
            >Decline</b-button
          >
        </div>
        <div class="d-flex flex-column" v-else>
          <b-button
            size="sm"
            variant="outline-success"
            class="cursor-pointer my-1"
            @click="approveAuthorizeTransaction(data.item, 'approve')"
          >
            <span> Approve</span>
          </b-button>
        </div>
      </template>
      <template v-if="transactions" #custom-foot>
        <tr v-if="transactions.length > 0" class="sticky-data-total text-left">
          <td v-if="activeTab == 0"></td>
          <td v-if="activeTab == 0"></td>
          <td v-if="activeTab == 0"></td>
          <td></td>
          <td></td>
          <td>
            <span class="footer-title">TOTAL</span>
          </td>
          <td :colspan="1">
            <span class="footer-amount"> $ {{ total | currencyZero }} </span>
          </td>
          <td></td>
          <td></td>
        </tr>
      </template>
    </b-table>
    <ModalAddTransactionsAuthorize
      v-if="showModalAddTransactionsAuthorize"
      :authorizeTransactionId="authorizeTransactiondId"
      @hidden="showModalAddTransactionsAuthorize = false"
    />
  </b-modal>
</template>
<script>
import PaymentsService from "@/views/crm/views/payments/service/payments.service.js";
import ClientDashboardService from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services";
import ModalAddTransactionsAuthorize from "@/views/commons/components/reports/tabs/daily-payment-report/components/ModalAddTransactionsAuthorize.vue";
export default {
  components: {
    ModalAddTransactionsAuthorize,
  },
  data() {
    return {
      isBusy: false,
      transactions: [],
      fields: [
        {
          key: "lastName",
          label: "Client",
          visible: true,
          tdClass: "text-left",
          thClass: "text-left bg-primary footer-title",
        },
        {
          key: "merchant",
          label: "Merchant",
          visible: true,
          tdClass: "text-left",
          thClass: "text-left bg-primary footer-title",
        },
        {
          key: "modality",
          label: "Modality",
          visible: true,
          tdClass: "text-left",
          thClass: "text-left bg-primary footer-title",
        },
        {
          key: "type",
          label: "Type",
          visible: true,
          tdClass: "text-left",
          thClass: "text-left bg-primary footer-title",
        },
        {
          key: "method",
          label: "Method",
          visible: true,
          tdClass: "text-left",
          thClass: "text-left bg-primary footer-title",
        },
        {
          key: "transId",
          label: "Transaction ID",
          visible: true,
          tdClass: "text-left",
          thClass: "text-left bg-primary footer-title",
        },
        {
          key: "settleAmount",
          label: "Authorize Amount",
          visible: true,
          tdClass: "text-left",
          thClass: "text-left bg-primary footer-title",
        },
        {
          key: "soft_amount",
          label: "Soft Amount",
          visible: true,
          tdClass: "text-left",
          thClass: "text-left bg-primary footer-title",
        },
        {
          key: "submitTimeUTC",
          label: "Date / Hour",
          visible: true,
          tdClass: "text-left",
          thClass: "text-left bg-primary footer-title",
        },
        {
          key: "actions",
          label: "Actions",
          visible: true,
          tdClass: "text-center",
          thClass: "text-center bg-primary footer-title",
        },
      ],
      tabs: [
        {
          name: "AMG / AUTHORIZE",
          value: 0,
        },
        {
          name: "ONLY AUTHORIZE",
          value: 1,
        },
      ],
      activeTab: 0,
      showModalAddTransactionsAuthorize: false,
      authorizeTransactiondId: null,
      authorizeCounter: 0,
      soft_count: 0,
    };
  },
  computed: {
    filterFields() {
      let exclude = [];
      if (this.activeTab === 0) {
        exclude = [6];
      } else {
        exclude = [2, 3, 4, 7];
      }
      return this.fields.filter((field, index) => {
        return !exclude.includes(index);
      });
    },
    total() {
      if (this.activeTab === 1) {
        return this.transactions.reduce((sum, transaction) => {
          return sum + parseFloat(transaction.settleAmount || 0);
        }, 0);
      } else {
        return this.transactions.reduce((sum, transaction) => {
          return sum + parseFloat(transaction.soft_amount || 0);
        }, 0);
      }
    },
  },
  mounted() {
    this.toggleModal("held-for-review-transactions-modal");
    this.getHeldForReviewTransactions(false);
  },
  methods: {
    async getHeldForReviewTransactions(emmitEvent) {
      try {
        this.transactions = [];
        this.addPreloader();
        this.isBusy = true;
        const { data } = await PaymentsService.getHeldForReviewTransactions({
          tab: this.activeTab,
        });
        if (emmitEvent) {
          this.$emit("on-success-review");
        }
        this.isBusy = false;

        if (data.authorize_count >= 99) {
          this.authorizeCounter = 99;
        } else {
          this.authorizeCounter = data.authorize_count;
        }

        if (data.soft_count >= 99) {
          this.soft_count = 99;
        } else {
          this.soft_count = data.soft_count;
        }

        if (this.activeTab === 0) {
          this.transactions = data.soft;
        } else {
          this.transactions = data.authorize;
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    openModalAddTransactionsAuthorize(transactionId) {
      this.authorizeTransactiondId = transactionId;
      this.showModalAddTransactionsAuthorize = true;
    },
    async ApproveOrDeclineHeldTransaction(transaction, action) {
      try {
        const { isConfirmed } = await this.showConfirmSwal();

        if (!isConfirmed) return;

        this.addPreloader();

        const params = {
          transaction_id: transaction.transId,
          action: action,
          merchant_id: transaction.merchant_id,
        };

        const { data } =
          await ClientDashboardService.ApproveOrDeclineHeldTransaction(params);

        this.showGenericToast({
          position: "top-right",
          variant: data.variant,
          icon: data.icon,
          title: data.message,
          text: data.text,
        });

        await this.getHeldForReviewTransactions(true);

        if (this.activeTab === 1 && action === "approve") {
          this.openModalAddTransactionsAuthorize(data.transactionDetail);
        }
      } catch (error) {
        this.showErrorSwal(error.response.data.message, "Error");
      } finally {
        this.removePreloader();
      }
    },
    async approveAuthorizeTransaction(transaction, action) {
      try {
        const { isConfirmed } = await this.showConfirmSwal();

        if (!isConfirmed) return;

        this.addPreloader();

        const params = {
          transaction_id: transaction.transId,
          action: action,
          merchant_id: transaction.merchant_id,
        };

        const { data } =
          await ClientDashboardService.ApproveOrDeclineHeldTransaction(params);

        if (data.authorizeStatus == 8) {
          this.showGenericToast({
            position: "top-right",
            variant: data.variant,
            icon: data.icon,
            title: data.message,
            text: data.text,
          });
          this.getHeldForReviewTransactions(true);
        } else {
          this.showErrorSwal(
            "There was an error inserting the transaction",
            "Error"
          );
        }
      } catch (error) {
        this.showErrorSwal(error.response.data.message, "Error");
      } finally {
        this.removePreloader();
      }
    },
    async updateExpiredTransaction(transaction) {
      try {
        this.addPreloader();
        const { data } = await PaymentsService.updateExpiredTransaction({
          transaction_id: transaction.transId,
          merchant_id: transaction.merchant_id,
        });
        this.showGenericToast({
          position: "top-right",
          variant: "success",
          icon: "check-circle",
          title: "Success",
          text: data.message,
        });
        this.getHeldForReviewTransactions(false);
      } catch (error) {
        this.showErrorSwal(error.response.data.message, "Error");
      }
      this.removePreloader();
    },
  },
};
</script>
<style lang="scss" >
.held-transactions {
  .sticky-data-total {
    position: sticky;
    bottom: 0;
    z-index: 1000;
    background-color: #0090e7 !important;
    color: #ffffff !important;
  }
  .footer-title {
    color: #ffffff !important;
    font-weight: 700 !important;
    font-family: "Montserrat", sans-serif !important;
  }
  .footer-amount {
    color: #ffffff !important;
    font-weight: 700 !important;
    font-family: "Montserrat", sans-serif !important;
  }
}
</style>