<template>
  <div class="message-attachments">
    <div v-for="(attachment, index) in attachments" :key="index">
      <!-- image -->
      <b-img
        v-if="attachment.type === 'Image'"
        class="message-attachment-image"
        :class="{ many: attachments.length > 1 }"
        :src="attachment.preview_url"
        @click="showImage(attachments, index)"
      />

      <audio
        v-if="attachment.type === 'Audio'"
        :src="attachment.url"
        controls
      ></audio>

      <!--file-->
      <Bubble
        v-if="['File'].includes(attachment.type)"
        class="message-attachment-file"
        :class="{
          me: me,
          other: !me,
        }"
      >
        <feather-icon icon="ArrowDownCircleIcon" />
        <a
          :href="attachment.url"
          target="_blank"
          :class="{
            me: me,
            other: !me,
          }"
        >
          {{ attachment.name }}</a
        >
      </Bubble>

      <!-- video -->
      <div v-if="attachment.type == 'Video'">
        <video
          :src="attachment.url"
          class="message-attachment-video"
          controls
        ></video>
      </div>
    </div>

    <ImagePreview
      v-if="showImageViewer"
      :images="imageData"
      @hidden="showImageViewer = false"
    />
  </div>
</template>
<script>
import Bubble from "@/views/commons/Messenger/components/messenger/messages/components/BubbleMessage.vue";
import ImagePreview from "@/commons/ImagePreview.vue";

export default {
  name: "Attachments",
  components: {
    Bubble,
    ImagePreview,
  },
  props: {
    attachments: {
      type: Array,
      required: false,
      default: () => [],
    },
    me: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showImageViewer: false,
      imageData: [],
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    showImage(data, imageIndex) {
      this.imageData = [];
      const images = [...data];
      if (data.length > 1) {
        this.imageData.push(images[imageIndex].url);
        images.splice(imageIndex, 1);
      }
      for (let index = 0; index < images.length; index++) {
        const element = images[index];
        this.imageData.push(element.url);
      }
      this.showImageViewer = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.message-attachments {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  .message-attachment-image {
    cursor: pointer;
    width: 100%;
    border-radius: 1rem;
    object-fit: cover;
  }

  .message-attachment-file {
    display: flex;
    cursor: pointer;
    align-items: center;
    gap: 0.5rem;
  }
  .message-attachment-video {
    width: 100%;
    border-radius: 1rem;
  }

  @media (min-width: 950px) {
    .message-attachment-image {
      max-width: 350px;
      max-height: 350px;
    }

    .message-attachment-image.many {
      width: 100px;
      height: 100px;
    }

    .message-attachment-video {
      max-width: 350px;
      max-height: 350px;
    }
  }
  .me {
    color: #fff;
  }
  .other {
    color: #000;
  }
}

.dark-layout {
  .message-attachments {
    .other {
      color: #fff;
    }
  }
}
</style>