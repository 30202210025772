export default [
  {
    key: "from",
    label: "From",
    thClass: "text-left",
    tdClass: "text-left",
    sortable: false,
    visible: true,
  },
  {
    key: "to",
    label: "To",
    thClass: "text-left",
    tdClass: "text-left",
    sortable: false,
    visible: true,
  },
  {
    key: "status",
    label: "RC Status",
    thClass: "text-left",
    tdClass: "text-left",
    sortable: false,
    visible: true,
  },
  {
    key: "start_time",
    label: "Start Time",
    thClass: "text-center",
    tdClass: "text-center",
    sortable: false,
    visible: true,
    // thStyle: { width: "250px" },
  },
  {
    key: "duration",
    label: "Duration",
    thClass: "text-center",
    tdClass: "text-center",
    sortable: false,
    visible: true,
  },
  {
    key: "record_url",
    label: "Audio",
    thClass: "text-center",
    tdClass: "text-center",
    sortable: false,
    visible: true,
  },
];
