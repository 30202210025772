<template>
  <div>
    <b-modal
      ref="modal-preview-ad"
      size="xsm"
      @hidden="$emit('hidden')"
      hide-footer
    >
      <template #modal-title>
        <span class="text-uppercase font-weight-bolder"> {{ adName }} </span>
      </template>
      <template #default>
        <div class="modal-preview-ad">
          <div
            v-if="
              (type === 'ad_preview' || type === 'preview_video_in_meta') &&
              iframeAd
            "
            v-html="iframeAd"
            class="w-100 ad-preview-iframe"
            :class="type"
          ></div>

          <div
            v-if="
              (type === 'preview_image_in_meta' ||
                type === 'preview_image_in_soft') &&
              imageUrl
            "
          >
            <img :src="imageUrl" alt="preview_image" class="w-100" />
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import MetaService from "@/views/creative/views/meta/service/meta.service.js";
export default {
  name: "ModalAppointmentsGrid",
  props: {
    type: {
      type: String,
      required: true,
    },
    adId: {
      type: String,
      required: false,
      default: null,
    },
    videoId: {
      type: String,
      required: false,
      default: null,
    },
    adName: {
      type: String,
      required: true,
    },
    metaUserId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      iframeAd: null,
      imageUrl: null,
    };
  },
  computed: {},
  created() {
    this.validateFunction();
  },
  mounted() {
    this.toggleModal("modal-preview-ad");
  },
  methods: {
    validateFunction() {
      const functions = {
        ad_preview: this.getAdPreviewInMeta.bind(this),
        preview_video_in_meta: this.getAdVideoPreview.bind(this),
        preview_image_in_soft: this.getAdImagePreviewInSoft.bind(this),
        preview_image_in_meta: this.getAdImagePreviewInMeta.bind(this),
      };
      functions[this.type] && functions[this.type]();
    },
    async getAdPreviewInMeta() {
      try {
        this.addPreloader();
        const params = {
          meta_user_id: this.metaUserId,
          type: "ad_preview",
        };
        const { data } = await MetaService.getAdPreview(params, this.adId);
        this.iframeAd = data.body ?? null;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async getAdImagePreviewInSoft() {
      try {
        this.addPreloader();
        const { data } = await MetaService.getAdsByAdId(this.adId);
        this.imageUrl = data.route;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async getAdVideoPreview() {
      try {
        this.addPreloader();
        const params = {
          meta_user_id: this.metaUserId,
          type: "ad_video_preview",
        };
        const { data } = await MetaService.getAdPreview(params, this.videoId);
        this.iframeAd = data ?? null;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async getAdImagePreviewInMeta() {
      try {
        this.addPreloader();
        const params = {
          meta_user_id: this.metaUserId,
          type: "ad_image_preview",
        };
        const { data } = await MetaService.getAdPreview(params, this.adId);
        this.imageUrl = data ?? null;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
<style lang="scss">
.modal-preview-ad {
  .ad-preview-iframe {
    iframe {
      width: 100% !important;
    }
  }
}
</style>