<template>
  <div>
    <b-modal
      v-model="onControl"
      header-bg-variant="transparent border-bottom border-bottom-2"
      size="xmd"
      header-class="p-0"
      title="Payroll Detail"
      modal-class="modal-primary"
      hide-footer
      scrollable
      @hidden="close"
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <div class="d-flex align-items-center container-title">
            <img :src="imgPrograms(idProgram)" alt="img_services" />
            <span> {{ getTitleModal() }}</span>
          </div>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="close()"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>
      <filter-slot
        class="filter-slot-new-customization"
        :filter="isRecovered ? [] : filter"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        :top-paginate="false"
        @reload="refreshTableExpenses"
      >
        <template #buttons-filter-end>
          <div class="d-flex align-items-center pl-1" v-if="isPaid === 0">
            <b-badge variant="light-success" class="p-1">
              <span style="padding: 10px 5px">
                RECOVERED : {{ totalRecovered }}
              </span>
            </b-badge>
          </div>
        </template>
        <template #table>
          <b-table
            ref="refTableExpensesDetail"
            no-border-collapse
            class="position-relative table-new-customization"
            :fields="visibleFields"
            show-empty
            no-provider-filtering
            sticky-header="50vh"
            primary-key="id"
            responsive="sm"
            :items="searchDetailClientsNoPayment"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
            :busy.sync="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #head(client_name)="data">
              <div class="text-left">
                {{ data.label }}
              </div>
            </template>
            <template #head(calls_count)="data">
              <div class="text-center">
                {{ data.label }}
              </div>
            </template>
            <template #head(account)="data">
              <div class="text-center">
                {{ data.label }}
              </div>
            </template>
            <template #head(totalPayment)="data">
              <div class="text-center">
                {{ data.label }}
              </div>
            </template>
            <template #head(start_date)="data">
              <div class="text-center">
                {{ data.label }}
              </div>
            </template>
            <template #head(rb_status)="data">
              <span class="pl-2">
                {{ data.label }}
              </span>
            </template>
            <template #head(settlement_date)="data">
              <div class="text-center">
                {{ data.label }}
              </div>
            </template>
            <template #cell(client_name)="data">
              <div class="align-middle text-left">
                <div v-if="data.item.client_name">
                  <router-link
                    class="font-weight-bolder"
                    target="_blank"
                    :to="routers(data.item.id, idProgram)"
                  >
                    {{ data.item.client_name }}
                    <br />
                    {{ data.item.account }}
                  </router-link>
                </div>
                <div v-else>
                  {{ "Unknown" }}
                </div>
              </div>
            </template>
            <template #cell(calls_count)="data">
              <div class="text-center">
                <b-badge
                  pill
                  :variant="data.item.calls_count === 0 ? 'danger' : 'primary'"
                  class="cursor-pointer"
                  @click="
                    openCallsDetail(
                      data.item.id,
                      data.item.settlement_date,
                      data.item.calls_count,
                      data.item.client_name,
                      data.item.module_id
                    )
                  "
                >
                  <span style="padding: 10px 5px">
                    {{ data.item.calls_count }}
                  </span>
                </b-badge>
              </div>
            </template>
            <template #cell(amount)="data">
              <div v-if="data.item.last_transaction_id && !isPaid">
                <div class="text-success">
                  $ {{ data.item.last_amount | currency }}
                </div>
                <div style="margin-right: -10px">
                  <b-badge variant="light-success"> RECOVERED </b-badge>
                </div>
              </div>
              <div v-else :class="isPaid ? 'text-success' : 'text-danger'">
                {{ data.item.amount && !isPaid ? "$ -" : "$" }}
                {{ data.item.amount | currencyZero }}
              </div>
            </template>
            <template #cell(account)="data">
              <div class="align-middle text-center">
                {{ data.item.account }}
              </div>
            </template>
            <template #cell(start_date)="data">
              <div class="align-middle text-center">
                {{ data.item.start_date | myGlobal }}
              </div>
            </template>
            <template #cell(rb_status)="data">
              <div class="text-center">
                <b-badge
                  pill
                  :variant="data.item.rb_status === 0 ? 'danger' : 'success'"
                >
                  <span style="padding: 10px 5px">
                    {{ data.item.rb_status === 0 ? "INACTIVE" : "ACTIVE" }}
                  </span>
                </b-badge>
              </div>
            </template>
            <template #cell(settlement_date)="data">
              <div class="align-middle text-center">
                {{ data.item.settlement_date | myGlobal }}
              </div>
            </template>
            <template #cell(action)="row">
              <tabler-icon
                icon="TicketIcon"
                class="cursor-pointer text-weight-bolder"
                style="margin-left: 5px"
                :class="row.item.ticket_id ? 'text-success' : 'text-secondary'"
                size="18"
                v-b-tooltip.hover.top="
                  row.item.ticket_code ? 'Show Ticket' : 'Create Ticket'
                "
                @click="selectCustomerTicket(row.item)"
              />
            </template>
            <template #bottom-row>
              <b-th
                class="fill-bottom-row"
                :colspan="isPaid === 0 ? 3 : 2"
              ></b-th>
              <b-th
                class="fill-bottom-row"
                colspan="2"
                style="padding: 0 !important"
              >
                <b-row class="m-0 p-0 fill-bottom-row">
                  <b-col
                    :cols="6"
                    class="widthRecovery text-center p-0 text-black text-center"
                  >
                    <b-badge
                      :variant="
                        isPaid ? 'primary' : hasRecovered ? 'success' : 'danger'
                      "
                      style="font-size: 16px"
                      class="w-100 text-center auto-clients-total-left"
                    >
                      TOTAL
                    </b-badge>
                  </b-col>
                  <b-col
                    cols="6"
                    class="widthRecovery text-center p-0 text-black text-center"
                  >
                    <b-badge
                      :variant="
                        isPaid ? 'primary' : hasRecovered ? 'success' : 'danger'
                      "
                      style="font-size: 16px"
                      class="w-100 right-center auto-clients-total-right"
                    >
                      {{ "$ " }}
                      {{
                        totalMissingPayments == 0 || isPaid || hasRecovered
                          ? ""
                          : "- "
                      }}
                      {{ totalMissingPayments | currencyZero }}
                    </b-badge>
                  </b-col>
                </b-row>
              </b-th>
              <b-th class="fill-bottom-row" colspan="3" />
            </template>
          </b-table>
        </template>
      </filter-slot>
    </b-modal>
    <calls-detailed
      v-if="showUnnotedCallsModal"
      :client-account-id="currentClientAccountId"
      :module-id="currentModuleId"
      :from-settlement-date="currentSettlementDate"
      :client-name="currentClientName"
      @close="closeCallsModalDetail()"
    />
    <modal-create-customer-ticket
      v-if="showModalCreateCustomerTicket"
      :customer-ticket="customerTicketSelected"
      :customer-id="customerId"
      :set-motive="102"
      @hidden="showModalCreateCustomerTicket = false"
      @saved="refreshTableExpenses"
    />

    <modal-view-ticket-customer-details
      v-if="showModalViewTicketCustomerDetails"
      :customer-ticket="customerTicketSelected"
      @hidden="showModalViewTicketCustomerDetails = false"
    />
  </div>
</template>

<script>
import DashboardService from "@/views/ceo/service-dashboard/dashboard.service";
import CallsDetailed from "@/views/ceo/dashboard/modals/clients-auto-modal/CallsDetailed.vue";
import ModalCreateCustomerTicket from "@/views/commons/components/customer-tickets/components/modal/ModalCreateCustomerTicket.vue";
import ModalViewTicketCustomerDetails from "@/views/commons/components/customer-tickets/components/modal/ModalViewTicketCustomerDetails.vue";
import Filter from "@/views/ceo/dashboard/data/clients-aouto-modal-filter.js";

export default {
  components: {
    CallsDetailed,
    ModalCreateCustomerTicket,
    ModalViewTicketCustomerDetails,
  },
  props: {
    sendYear: {
      type: Number,
      default: null,
    },
    sendMonth: {
      type: Number,
      default: null,
    },
    sendPrograms: {
      type: Array,
      required: false,
      default: [],
    },
    sendProgramsId: {
      type: Number,
      default: null,
    },
    dayPayment: {
      type: Number,
      default: 5,
    },
    isPaid: {
      type: Number,
      default: 0,
    },
    isRecovered: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deployedPaymentsPrograms: [],
      currentClientAccountId: null,
      currentModuleId: null,
      currentSettlementDate: null,
      currentClientName: null,
      onControl: false,
      startPage: 0,
      toPage: 0,
      isBusy: false,
      titleModal: "",
      idPrograms: null,
      initialProgram: "",
      sortBy: "client_name",
      sortDesc: true,
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by client name...",
        model: "",
      },
      fields: [
        {
          key: "client_name",
          sortable: false,
          label: "Client Name",
          visible: true,
        },
        {
          key: "transaction_id",
          sortable: false,
          label: "Transaction ID",
          visible: true,
        },
        {
          key: "calls_count",
          sortable: false,
          label: "Contact Info",
          visible: true,
        },
        {
          key: "start_date",
          sortable: false,
          label: "Enroll Date",
          visible: true,
        },
        {
          key: "amount",
          thClass: "text-right",
          tdClass: "text-right",
          sortable: false,
          label: "Amount",
          visible: true,
        },
        {
          key: "rb_status",
          sortable: false,
          label: "RB",
          visible: true,
        },
        {
          key: "settlement_date",
          sortable: false,
          label: "Pay date",
          visible: true,
        },
        {
          key: "action",
          sortable: false,
          label: "Actions",
          visible: true,
        },
      ],
      automaticPaids: [],
      totalRows: 1,
      totalMissingPayments: 0,
      paginate: {
        perPage: 50,
        currentPage: 1,
      },
      idProgram: null,
      imgDebtSolution: require("@/assets/images/icons/dashboard-ceo/logo-debt-solution.svg"),
      imgBoostCredit: require("@/assets/images/icons/dashboard-ceo/logo-boost-credit.svg"),
      imgCreditExperts: require("@/assets/images/icons/dashboard-ceo/logo-credit-experts.svg"),
      imgBusiness: require("@/assets/images/icons/dashboard-ceo/logo-business.svg"),
      imgTaxResearch: require("@/assets/images/icons/dashboard-ceo/logo-tax-research.svg"),
      imgSpecialist: require("@/assets/images/icons/dashboard-ceo/logo-specialist.svg"),
      imgKeyBook: require("@/assets/images/icons/dashboard-ceo/logo-keybook.svg"),
      showUnnotedCallsModal: false,
      totalRecovered: 0,
      filter: this.isPaid !== 0 ? [] : Filter,
      showModalCreateCustomerTicket: false,
      showModalViewTicketCustomerDetails: false,
      customerTicketSelected: {},
      customerId: null,
      filtering: false,
    };
  },

  async created() {
    this.fields = this.setFieldVisibility(this.fields, "action", this.isCeo);
    if (this.isPaid !== 0) {
      this.fields = this.setFieldVisibility(this.fields, "calls_count", false);
      this.fields = this.setFieldVisibility(this.fields, "action", false);
      this.fields = this.setFieldVisibility(this.fields, "rb_status", false);
    }
    await this.getDeployedNewPaymentsPrograms();
    this.idProgram = this.sendProgramsId;
    this.onControl = true;
    this.year = this.sendYear;
    this.month = this.sendMonth;
  },

  methods: {
    refreshTableExpenses() {
      this.$refs.refTableExpensesDetail.refresh();
    },
    close() {
      this.$emit("close");
    },
    getTitleModal() {
      return `Clients Automatic Payment - ${this.namePrograms(
        this.idProgram
      )} (${this.titleMonthYear})`;
    },
    routers(id, program) {
      let route = "";
      switch (program) {
        case 1:
          route = `/bussiness/clients/account/${id}`;
          break;
        case 2:
          route = `/boostcredit/clients/account/${id}`;
          break;
        case 3:
          route = `/creditexperts/clients/account/${id}`;
          break;
        case 4:
          route = `/debtsolution/clients/account/${id}`;
          break;
        case 5:
          route = `/taxresearch/clients/account/${id}`;
          break;
        case 7:
          route = `/specialists/clients/account/${id}`;
          break;
        case 8:
          route = `/bookeeping/clients/account/${id}`;
          break;
        case 9:
          route = `/ti/clients/account/${id}`;
          break;
      }
      return route;
    },

    namePrograms(id) {
      switch (id) {
        case 1:
          return "Business";
        case 2:
          return "Boost Credit";
        case 3:
          return "Credit Experts";
        case 4:
          return "Debt Solution";
        case 5:
          return "Tax Research";
        case 7:
          return "Specialist";
        case 8:
          return "KeyBook";
        default:
          return "No found";
          break;
      }
    },
    imgPrograms(program_id) {
      switch (program_id) {
        case 1:
          return this.imgBusiness;
        case 2:
          return this.imgBoostCredit;
        case 3:
          return this.imgCreditExperts;
        case 4:
          return this.imgDebtSolution;
        case 5:
          return this.imgTaxResearch;
        case 7:
          return this.imgSpecialist;
        case 8:
          return this.imgKeyBook;
        default:
          return this.imgKeyBook;
          break;
      }
    },
    async searchDetailClientsNoPayment(ctx) {
      const { data } = await DashboardService.getClientAutomaticPaymentsDetail({
        year: this.year,
        month: this.month,
        program_id: this.idProgram,
        day_payment: this.dayPayment,
        is_paid: this.isPaid,
        search_text: this.filterPrincipal.model,
        page: ctx.currentPage,
        perpage: ctx.perPage,
        is_recovered: this.isRecovered ? 1 : this.filter?.[0]?.model,
      });

      this.automaticPaids = data.data;
      this.startPage = data.from;
      this.paginate.currentPage = data.current_page;
      this.paginate.perPage = data.per_page;
      this.totalRows = data.total;
      this.toPage = data.to;
      this.totalMissingPayments =
        this.automaticPaids[0]?.total_missing_payments ?? 0.0;
      if (this.hasRecovered) {
        this.totalMissingPayments = this.automaticPaids[0]?.sum_total_recovered;
      }
      this.totalRecovered = this.automaticPaids[0]?.total_recovered;
      return this.automaticPaids || [];
    },
    async getDeployedNewPaymentsPrograms() {
      try {
        const data = await DashboardService.getDeployedNewPaymentsPrograms();
        this.deployedPaymentsPrograms = data;
      } catch (error) {
        console.error(error);
      }
    },
    async openCallsDetail(
      clientAccountId,
      settlementDate,
      callsCount,
      clientName,
      currentModuleId
    ) {
      if (callsCount < 1) return;
      this.currentClientAccountId = clientAccountId;
      this.currentModuleId = currentModuleId;
      this.currentSettlementDate = settlementDate;
      this.currentClientName = clientName;
      this.showUnnotedCallsModal = true;
    },
    closeCallsModalDetail() {
      this.currentClientAccountId = null;
      this.currentModuleId = null;
      this.currentSettlementDate = null;
      this.currentClientName = null;
      this.showUnnotedCallsModal = false;
    },
    selectCustomerTicket(item) {
      if (item.ticket_code) {
        this.openModalViewTicketCustomerDetails(item);
      } else {
        this.openModalCreateCustomerTicket(item);
      }
    },

    async openModalViewTicketCustomerDetails(item) {
      const data = await DashboardService.getCustomerTicket({
        name_text: item.ticket_code,
        user_id: 1,
      });
      if (data.length > 0) {
        this.customerTicketSelected = data[0];
        this.showModalViewTicketCustomerDetails = true;
      } else {
        this.$bvToast.toast("Ticket not found", {
          title: "Error",
          variant: "danger",
        });
      }
    },

    async openModalCreateCustomerTicket(item) {
      this.customerId = item.id;
      let date = "";
      if (item.settlement_date) {
        const parsedDate = new Date(item.settlement_date);
        const mo = parsedDate.getMonth() + 1; // Se agrega 1 porque los meses van de 0 a 11 en JavaScript
        const da = parsedDate.getDate();
        const an = parsedDate.getFullYear();
        date = `${mo}/${da}/${an}`;
      }
      this.customerTicketSelected = {
        id: item.ticket_id,
        transaction_id: item.transaction_id,
        client_name: item.client_name,
        account: item.account,
        is_client: true,
        text: `TRANSACTION ID: ${item.transaction_id}\nAMOUNT: ${item.amount}\nDATE ${date}`,
      };
      this.showModalCreateCustomerTicket = true;
    },
  },
  computed: {
    hasRecovered() {
      return this.isRecovered || this.filter?.[0]?.model === 1;
    },
    availableNewDeployedPaymentsPrograms() {
      const programs = this.deployedPaymentsPrograms.map(
        (program) => program.id
      );
      return programs || [];
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    nameMonths() {
      const names = {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December",
      };
      return names[this.month];
    },
    titleMonthYear() {
      return `${this.nameMonths} ${this.year}`;
    },
    visibleFields() {
      return this.fields.filter((f) => f.visible);
    },
  },
};
</script>
<style>
.b-table-top-row {
  position: sticky;
  top: 40px;
  z-index: 1;
}

.b-table-bottom-row {
  position: sticky;
  bottom: 0px;
  z-index: 1;
}

.auto-clients-total-left {
  font-size: 16px;
  border-radius: 0px 0px 0px 15px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 5px;
}

.auto-clients-total-right {
  font-size: 16px;
  border-radius: 0px 0px 15px 0px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: -10px;
}

.fill-bottom-row {
  background-color: #ffffff !important;
}

.dark-layout .fill-bottom-row {
  background-color: #17171a !important;
  border-top: none !important;
}
</style>

<style lang="scss" scoped>
.text-money {
  font-weight: 500;

  &::before {
    content: "$";
  }
}

.header-modal {
  position: relative;
  padding: 20px 0 20px 15px;
  background: rgba(22, 84, 204, 1);
  width: 100%;
  font-size: 22px;
  display: flex;
  justify-content: space-betweeen;

  span {
    font-size: 1.6rem;
    font-weight: bold;
    margin-left: 8px;
    color: #ffffff;
  }

  .container-icons {
    background-color: #ffffff;
    padding: 2.7px 6.37px;
    border-radius: 0.357rem;
    width: 31px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    transition: all 0.23s ease 0.1s;

    &--dark {
      background-color: #17171a;
    }

    &:hover {
      transform: translate(-7px, 5px);
      cursor: pointer;
    }
  }
}
</style>
