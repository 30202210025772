export default [
    {
        key: 'transaction_date',
        label: 'Transaction date',
        class: 'text-center',
        visible: true
    },
    // {
    //     key: 'amount_credit',
    //     label: 'Amount credit',
    //     class: 'text-center',
    //     visible: true
    // },
    {
        key: 'amount_debit',
        label: 'Amount debit',
        class: 'text-center',
        visible: true
    },
    {
        key: 'description',
        label: 'Concept',
        class: 'text-center',
        visible: true
    },
    // {
    //     key: 'category',
    //     label: 'Category',
    //     class: 'text-center',
    //     visible: true
    // }
]