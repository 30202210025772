<template>
  <div>
    <div
      class="d-flex justify-content-end mb-2"
      style="gap: 10px"
      v-if="type === 'E-CHECK'"
    >
      <b-button
        variant="outline-primary"
        @click="showModalAssingDepartments = true"
      >
        Assing Departments
      </b-button>
      <b-button variant="primary" @click="showCheckAccountsModal = true">
        Add Bank Account
      </b-button>
    </div>

    <b-tabs
      active-tab-class="p-0 "
      pills
      nav-class="mb-0 mt-1"
      active-nav-item-class="bg-primary box-shadow-primary border-primary primary"
    >
      <b-tab
        v-for="(title, index) in ['Active', 'Deleted']"
        :key="index"
        :title="title"
        :active="index === 0"
        class="mb-1"
        @click="changeTab(index + 1)"
      >
        <div class="border-top-primary border-3 border-table-radius px-0 mb-1">
          <b-table
            :items="checks"
            :fields="visibleFields"
            sticky-header="50vh"
            class="position-relative table-new-customization"
            show-empty
          >
            <template v-slot:cell(bank_name)="{ item }">
              <div class="text-warning">
                {{ item.bank_name }}
              </div>
            </template>
            <template v-slot:cell(account_name)="{ item }">
              {{ item.account_name }}
            </template>
            <template v-slot:cell(account_number)="{ item }">
              {{ item.account_number }}
            </template>
            <template
              v-slot:cell(departments)="{ item }"
              v-if="type === 'CHECK'"
            >
              <div class="d-flex">
                <template v-for="(val, index) in JSON.parse(item.module)">
                  <div
                    class="d-flex col-1"
                    :key="index"
                    :class="type === 'E-CHECK' ? '' : 'mt-1'"
                  >
                    <img
                      v-b-tooltip.hover.top="val.module_name"
                      :src="`/assets/${val.icon}`"
                      alt="Logo"
                      height="30"
                      width="30"
                      style="margin: 0 5px"
                    />
                  </div>
                </template>
              </div>
            </template>
            <template #cell(balance)="{ item }">
              <e-check-balance :item="item" @updateBalance="getCheckAccounts" />
            </template>
            <template #cell(freeze)="{ item }">
              <span
                class="text-primary clickable"
                @click="openFreezeDetailsModal(item)"
                >{{ item.freeze | formatMoney }}</span
              >
            </template>
            <template #cell(total_paid)="{ item }">
              <span
                class="text-primary clickable"
                @click="openTotalPaidDetailsModal(item)"
                >{{ item.total_paid | formatMoney }}</span
              >
            </template>
            <template #cell(tracking_balance)="{ item }">
              <feather-icon
                @click="TrackingECheckBalance = item.tracking_balance"
                icon="AlignJustifyIcon"
                size="28"
              />
            </template>
            <template v-slot:cell(deleted_at)="{ item }">
              {{ item.deleted_at | myGlobal }}
            </template>
            <template v-slot:cell(created_at)="{ item }">
              {{ item.created_at | myGlobal }}
            </template>
            <template #cell(status)="{ item }">
              <b-form-checkbox
                switch
                unchecked-value="inactive"
                value="active"
                v-model="item.status"
                @change="changeStatus(item)"
              >
              </b-form-checkbox>
            </template>
            <template v-slot:cell(actions)="{ item }">
              <feather-icon
                icon="EditIcon"
                class="cursor-pointer text-warning"
                v-b-tooltip.hover.top="toltipActions.edit"
                @click="checkAccountsModal(item)"
              />
              <feather-icon
                icon="TrashIcon"
                class="cursor-pointer text-danger ml-1"
                v-b-tooltip.hover.top="toltipActions.delete"
                @click="deleteCheckAccount(item)"
              />
            </template>
          </b-table>
        </div>
      </b-tab>
    </b-tabs>
    <CheckAccountsModal
      v-if="showCheckAccountsModal"
      :dataAccount="itemSelected"
      @refresh="getCheckAccounts()"
      @close="closeModalCheckAccounts()"
      :type="type"
    />
    <tracking-e-check-balance
      v-if="TrackingECheckBalance.length > 0"
      :trackingBalance="TrackingECheckBalance"
      @close="TrackingECheckBalance = []"
    />
    <FreezedAmountDetailsModal
      v-if="showFreezeDetailsModal"
      :bank_account="itemSelected"
      @close="showFreezeDetailsModal = false"
    />
    <AssignDepartments
      v-if="showModalAssingDepartments"
      @close="showModalAssingDepartments = false"
    />
    <TotalPaidDetailsModal
      v-if="showTotalPaidDetailsModal"
      :bank_account="itemSelected"
      @close="[(showTotalPaidDetailsModal = false), (itemSelected = null)]"
    />
  </div>
</template>
<script>
import CheckServices from "@/views/commons/components/check-expenses/services/checks.services.js";
import CheckAccountsModal from "@/views/commons/components/check-expenses/modals/CheckAccountsModal.vue";
import Fields from "@/views/commons/components/check-expenses/data/check-accounts.fields.js";
import { mapGetters } from "vuex";
import { modifyVisibility } from "@/helpers/fields-table";
import Feather from "@/views/ui/feather/Feather.vue";
import ECheckBalance from "./components/ECheckBalance.vue";
import TrackingECheckBalance from "@/views/commons/components/check-expenses/modals/TrackingECheckBalance.vue";
import FreezedAmountDetailsModal from "@/views/commons/components/check-expenses/components/FreezedAmountDetailsModal.vue";
import AssignDepartments from "@/views/commons/components/check-expenses/modals/AssignDepartments.vue";
import TotalPaidDetailsModal from "@/views/commons/components/check-expenses/modals/TotalPaidDetailsModal.vue";

export default {
  components: {
    CheckAccountsModal,
    Feather,
    ECheckBalance,
    TrackingECheckBalance,
    FreezedAmountDetailsModal,
    AssignDepartments,
    TotalPaidDetailsModal,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showFreezeDetailsModal: false,
      checks: [],
      showCheckAccountsModal: false,
      itemSelected: null,
      fields: Fields,
      tab: 1,
      TrackingECheckBalance: [],
      modules: [],
      module_ids: [],
      showModalAssingDepartments: false,
      showTotalPaidDetailsModal: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    visibleFields() {
      modifyVisibility(this.fields, "actions", this.tab == 1);
      modifyVisibility(this.fields, "deleted_at", this.tab == 2);
      modifyVisibility(this.fields, "created_at", this.tab == 1);
      modifyVisibility(this.fields, "balance", this.type == "E-CHECK");
      modifyVisibility(this.fields, "tracking_balance", this.type == "E-CHECK");
      modifyVisibility(
        this.fields,
        "departments",
        this.type == "CHECK" || this.type == "CREDIT_CARD"
      );
      modifyVisibility(this.fields, "freeze", this.type == "E-CHECK");
      modifyVisibility(this.fields, "status", this.type == "E-CHECK");
      modifyVisibility(this.fields, "total_paid", this.type == "E-CHECK");
      return this.fields.filter((item) => item.visible);
    },
    toltipActions() {
      let toltipEdit;
      let toltipDelete;
      switch (this.type) {
        case "E-CHECK":
          toltipEdit = "Edit bank e-check account";
          toltipDelete = "Delete bank e-check account";
          break;
        case "CHECK":
          toltipEdit = "Edit bank check account";
          toltipDelete = "Delete bank check account";
          break;
        default:
          toltipEdit = "Edit credit card";
          toltipDelete = "Delete credit card";
          break;
      }
      return {
        edit: toltipEdit,
        delete: toltipDelete,
      };
    },
  },
  watch: {
    type: {
      async handler(value) {
        await this.getCheckAccounts();
      },
      deep: true,
      immediate: true,
    },
  },
  async created() {},
  methods: {
    async getCheckAccounts() {
      try {
        this.addPreloader();
        const { data } = await CheckServices.getCheckAccounts({
          moduleId: this.currentUser.modul_id || "",
          tab: this.tab,
          type: this.type,
        });
        this.checks = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    checkAccountsModal(item = null) {
      this.itemSelected = item;
      this.showCheckAccountsModal = true;
    },
    openFreezeDetailsModal(item) {
      this.itemSelected = item;
      this.showFreezeDetailsModal = true;
    },
    async deleteCheckAccount(item) {
      const confirm = await this.showConfirmSwal(
        `Are you sure you want to delete the check account: <strong> ${item.account_name}</strong>?`
      );
      if (!confirm.isConfirmed) return;
      const params = {
        id: item.bank_check_account_id,
        userId: this.currentUser.user_id,
      };
      try {
        const { data } = await CheckServices.deleteCheckAccount(params);
        if (data.success) {
          this.showToast(
            "success",
            "top-right",
            "Success",
            "CheckIcon",
            "Successfully process"
          );
          this.getCheckAccounts();
        } else {
          this.showToast(
            "danger",
            "top-right",
            "Error",
            "XIcon",
            "Error process"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    closeModalCheckAccounts() {
      this.showCheckAccountsModal = false;
      this.itemSelected = null;
    },
    async changeTab(tab) {
      this.tab = tab;
      await this.getCheckAccounts();
    },
    async changeStatus(item) {
      try {
        this.addPreloader();
        const params = {
          id: item.bank_check_account_id,
          status: item.status,
        };
        await CheckServices.changeStatusBankAccount(params);
        this.showToast(
          "success",
          "top-right",
          "Success",
          "CheckIcon",
          `Status has been successfully changed to ${item.status.toUpperCase()}`
        );
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        console.log({ error });
      }
    },
    openTotalPaidDetailsModal(item) {
      this.itemSelected = item;
      this.showTotalPaidDetailsModal = true;
    },
  },
};
</script>
