<template>
  <b-modal
    ref="myModal"
    v-model="onControl"
    size="xmd"
    title="Sales Detail - Business"
    hide-footer
    header-class="p-0"
    header-bg-variant="transparent border-bottom border-bottom-2"
    scrollable
    @hidden="close"
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <div class="d-flex align-items-center container-title">
          <img
            v-if="initialProgram"
            :src="imgPrograms(initialProgram)"
            alt="img_services"
          />
          <span>{{ titleModal }}</span>
        </div>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="close"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>

    <filter-slot
      :filter="[]"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refTableSalesDetail'].refresh()"
    >
      <template #table>
        <b-table
          ref="refTableSalesDetail"
          no-border-collapse
          class="position-relative"
          :fields="visibleFields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="searchDetailSales"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #head(client_name)="data">
            <div class="text-left">
              {{ data.label }}
            </div>
          </template>
          <template #head(value)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(fee_amount)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(initial_payment)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(seller_name)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(done_date)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(created_at)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>

          <template #cell(client_name)="data">
            <div style="width: 250px !important">
              <router-link
                class
                target="_blank"
                :to="routers(data.item.program_id, data.item.client_account_id)"
              >
                {{ data.item.client_name ? data.item.client_name : "Unknown" }}
                <br />
                {{ data.item.account }}
              </router-link>
            </div>
          </template>
          <template #cell(value)="data">
            <div class="align-middle text-center">
              <img
                :src="imgProgramsComplete(data.item.value)"
                :alt="`img_${data.item.value}`"
                style="width: 40px"
              />
            </div>
          </template>
          <template #cell(fee_amount)="data">
            <div class="align-middle text-center text-money">
              {{ data.item.fee_amount | currency }}
            </div>
          </template>
          <template #cell(initial_payment)="data">
            <div class="align-middle text-center text-money">
              {{ data.item.initial_payment | currency }}
            </div>
          </template>
          <template #cell(seller_name)="data">
            <div class="align-middle text-center">
              {{ data.item.seller_name }}
            </div>
          </template>
          <template #cell(done_date)="data">
            <div class="align-middle text-center">
              {{ data.item.done_date | myGlobalWithHour }}
            </div>
          </template>
          <template #cell(created_at)="data">
            <div class="align-middle text-center">
              {{ data.item.created_at | myGlobalWithHour }}
            </div>
          </template>
          <template #bottom-row>
            <b-th colspan="1"></b-th>
            <b-th class="py-0" colspan="3">
              <b-row class="m-0 p-0">
                <b-col
                  cols="4"
                  class="text-center p-0 bg-gradient-primary text-black"
                >
                  <b-badge
                    variant="primary"
                    style="font-size: 16px"
                    class="w-100 text-center"
                  >
                    TOTAL
                  </b-badge>
                </b-col>
                <b-col
                  cols="4"
                  class="text-center p-0 bg-gradient-primary text-black"
                >
                  <b-badge
                    variant="primary"
                    style="font-size: 16px"
                    class="w-100 text-center"
                  >
                    {{ "$ " }}{{ totalFee | currency }}
                  </b-badge>
                </b-col>
                <b-col
                  cols="4"
                  class="text-center p-0 bg-gradient-primary text-black"
                >
                  <b-badge
                    variant="primary"
                    style="font-size: 16px"
                    class="w-100 text-center"
                  >
                    {{ "$ " }}{{ totalInitialPayments | currency }}
                  </b-badge>
                </b-col>
              </b-row>
            </b-th>
            <b-th colspan="2"></b-th>
          </template>
        </b-table>
      </template>
    </filter-slot>
  </b-modal>
</template>

<script>
import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service";
import { mapGetters } from "vuex";

export default {
  props: {
    sendYear: {
      type: Number,
      default: null,
    },
    sendMonth: {
      type: Number,
      default: null,
    },
    sendProgramsId: {
      type: Number,
      default: null,
    },
    sendProgramsInitial: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      onControl: false,
      startPage: 0,
      toPage: 0,
      isBusy: false,
      dataYear: null,
      dataMonth: null,
      titleModal: "",
      idPrograms: null,
      initialProgram: "",
      sortBy: "client_name",
      sortDesc: true,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by client name...",
        model: "",
      },
      fields: [
        {
          key: "client_name",
          sortable: false,
          label: "Client",
          visible: true,
        },
        {
          key: "value",
          sortable: false,
          label: "Program",
          visible: true,
        },
        {
          key: "seller_name",
          sortable: false,
          label: "Seller",
          visible: true,
        },
        {
          key: "fee_amount",
          sortable: false,
          label: "Fee Amount",
          visible: true,
        },
        {
          key: "initial_payment",
          sortable: false,
          label: "Initial Payment",
          visible: true,
        },
        {
          key: "done_date",
          sortable: false,
          label: "Done date",
          visible: true,
        },
        {
          key: "created_at",
          sortable: false,
          label: "Creation date",
          visible: true,
        },
      ],
      salesDetailItems: [],
      totalRows: 1,
      totalFee: 0,
      totalInitialPayments: 0,
      paginate: {
        perPage: 50,
        currentPage: 1,
      },
      imgDebtSolution: require("@/assets/images/icons/dashboard-ceo/logo-debt-solution.svg"),
      imgBoostCredit: require("@/assets/images/icons/dashboard-ceo/logo-boost-credit.svg"),
      imgCreditExperts: require("@/assets/images/icons/dashboard-ceo/logo-credit-experts.svg"),
      imgBusiness: require("@/assets/images/icons/dashboard-ceo/logo-business.svg"),
      imgTaxResearch: require("@/assets/images/icons/dashboard-ceo/logo-tax-research.svg"),
      imgSpecialist: require("@/assets/images/icons/dashboard-ceo/logo-specialist.svg"),
      imgKeybook: require("@/assets/images/icons/dashboard-ceo/logo-keybook.svg"),
    };
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    ...mapGetters({
      titleMonthYear: "DashboardCeoStore/G_TITLE_MODAL",
    }),
  },

  async created() {
    this.onControl = true;
    this.idPrograms = this.sendProgramsId;
    this.dataMonth = this.sendMonth;
    this.dataYear = this.sendYear;
    this.initialProgram = this.sendProgramsInitial;
    this.fields[1].visible = this.idPrograms == null ? true : false;

    this.titleModal = `Sales Details - ${
      this.namePrograms(this.initialProgram) || "All Services"
    } (${this.titleMonthYear})`;
  },

  methods: {
    close() {
      this.$refs["myModal"].hide();
      this.$emit("close");
    },

    namePrograms(name) {
      switch (name) {
        case "DS":
          return "Debt Solution";
        case "BC":
          return "Boost Credit";
        case "CE":
          return "Credit Experts";
        case "BU":
          return "Business";
        case "TR":
          return "Tax Research";
        case "SP":
          return "Specialist";
        case "KB":
          return "KeyBook";
        default:
          break;
      }
    },
    imgProgramsComplete(program) {
      const programs = {
        "Debt Solution": this.imgDebtSolution,
        "Boost Credit": this.imgBoostCredit,
        "Credit Experts": this.imgCreditExperts,
        Business: this.imgBusiness,
        "Tax Research": this.imgTaxResearch,
        Specialist: this.imgSpecialist,
        KeyBook: this.imgKeybook,
      };

      return programs[program];
    },

    imgPrograms(slug) {
      switch (slug) {
        case "DS":
          return this.imgDebtSolution;
        case "BC":
          return this.imgBoostCredit;
        case "CE":
          return this.imgCreditExperts;
        case "BU":
          return this.imgBusiness;
        case "TR":
          return this.imgTaxResearch;
        case "SP":
          return this.imgSpecialist;
        case "KB":
          return this.imgKeybook;
        default:
          break;
      }
    },
    routers(program, id) {
      let route = "";
      switch (program) {
        case 1:
          route = `/bussiness/clients/account/${id}`;
          break;
        case 2:
          route = `/boostcredit/clients/account/${id}`;
          break;
        case 3:
          route = `/creditexperts/clients/account/${id}`;
          break;
        case 4:
          route = `/debtsolution/clients/account/${id}`;
          break;
        case 5:
          route = `/taxresearch/clients/account/${id}`;
          break;
        case 7:
          route = `/specialists/clients/account/${id}`;
          break;
        case 8:
          route = `/bookeeping/clients/account/${id}`;
          break;
        case 9:
          route = `/ti/clients/account/${id}`;
          break;
      }
      return route;
    },
    async searchDetailSales(ctx) {
      const response = await DashboardServive.detailSales({
        idProgram: this.idPrograms,
        year: this.dataYear,
        search_text: this.filterPrincipal.model,
        month: this.dataMonth,
        nPage: ctx.currentPage,
        nPerPage: ctx.perPage,
      });

      this.salesDetailItems = response.data;
      this.totalInitialPayments =
        this.salesDetailItems[0]?.total_initial_payments ?? 0;
      this.totalFee = this.salesDetailItems[0]?.total_fee ?? 0;
      this.startPage = response.from;
      this.paginate.currentPage = response.current_page;
      this.paginate.perPage = response.per_page;
      this.totalRows = response.total;
      this.toPage = response.to;
      return this.salesDetailItems || [];
    },
  },
};
</script>
  <style lang="scss" scoped>
.header-modal {
  position: relative;
  padding: 20px 0 20px 15px;
  background: rgba(22, 84, 204, 1);
  width: 100%;
  font-size: 22px;
  display: flex;
  justify-content: space-betweeen;

  .container-title {
    width: 100%;
    height: 100%;

    span {
      font-size: 1.3rem;
      font-weight: bold;
      margin-left: 8px;
      color: #ffffff;
    }
  }

  .container-icons {
    background-color: #ffffff;
    padding: 2.7px 6.37px;
    border-radius: 0.357rem;
    width: 31px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    transition: all 0.23s ease 0.1s;

    &--dark {
      background-color: #17171a;
    }

    &:hover {
      transform: translate(-7px, 5px);
      cursor: pointer;
    }
  }
}

.text-money {
  font-weight: 500;

  &::before {
    content: "$";
  }
}
</style>
