var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"dashboard__card p-0",class:_vm.isDarkSkin ? 'dashboard__card--dark' : ''},[_c('b-row',[_c('b-col',{attrs:{"cols":"7"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-avatar',{staticClass:"b_avatar_color",attrs:{"rounded":"sm","size":"4em"}},[_c('div',{staticClass:"ico_content"},[_c('svg',{attrs:{"width":"22","height":"22","viewBox":"0 0 22 22","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M19.2954 14.1684C18.0702 14.1684 16.87 13.9758 15.7325 13.5979C15.1879 13.4063 14.5167 13.581 14.1821 13.9284L11.9271 15.6432C9.3396 14.2526 7.6836 12.5863 6.32137 9.99999L7.97842 7.78316C8.39556 7.36316 8.54506 6.74842 8.36628 6.17263C7.98783 5.02 7.79546 3.81053 7.79546 2.57895C7.79546 1.70842 7.09187 1 6.22728 1H2.56818C1.70359 1 1 1.70842 1 2.57895C1 12.7358 9.20787 21 19.2954 21C20.16 21 20.8636 20.2916 20.8636 19.4211V15.7474C20.8636 14.8768 20.16 14.1684 19.2954 14.1684Z","stroke":"#3F7AFA","stroke-width":"2.2"}})])])]),_c('div',{staticClass:"ml-1"},[_c('h3',{staticClass:"font-weight-bolder mb-0"},[_vm._v("Calls Analysis")])])],1)]),_c('b-col',{attrs:{"cols":"5"}},[_vm._t("calendar")],2)],1),_c('div',{staticClass:"mt-1 call-analysis-grid w-100 mt-2 diego-scrollbar"},[_c('div',{staticClass:"thead"},[_c('div',{staticClass:"tr"},_vm._l((_vm.visibleFields),function(column,index){return _c('div',{key:index,class:[
            { 'mr-1 ': column.key === 'program_name' },

            {
              'border-right': column.key === 'negative' && !_vm.showUnnoted,
            },
            'th',
            column.class ]},[_c('div',{staticClass:"d-flex justify-content-center w-100 align-items-center"},[(column.key === 'positive')?_c('PositiveIcon',{staticClass:"mr-1",attrs:{"size":"2.3rem","color":"white"}}):_vm._e(),(column.key === 'neutral')?_c('NeutralIcon',{staticClass:"mr-1",attrs:{"size":"2.3rem","color":"white"}}):_vm._e(),(column.key === 'mixed')?_c('MixedIcon',{staticClass:"mr-1",attrs:{"size":"2.3rem","color":"white"}}):_vm._e(),(column.key === 'negative')?_c('NegativeIcon',{staticClass:"mr-1",attrs:{"size":"2.3rem","color":"white"}}):_vm._e(),_c('div',{staticClass:"label",class:column.key === 'program_name' || column.key === 'unnoted'
                  ? 'mt-05'
                  : ''},[_vm._v(" "+_vm._s(column.label)+" ")])],1)])}),0)]),_c('div',{staticClass:"tbody"},[(_vm.showCustomPreloader == false)?_vm._l((_vm.callAnalysis),function(row,rowIndex){return _c('div',{key:rowIndex,staticClass:"tr my-1"},_vm._l((_vm.visibleFields),function(column,colIndex){return _c('div',{key:colIndex,staticClass:"td d-flex align-items-center",class:[
              {
                'mr-1 justify-content-start border-services':
                  column.key === 'program_name',
              } ],style:(column.style)},[(column.key === 'program_name')?[_c('img',{staticClass:"icon",attrs:{"src":("/assets/" + (row.icons))}}),_c('span',{staticClass:"ml-1",staticStyle:{"text-overflow":"ellipsis","overflow":"hidden","white-space":"nowrap","max-width":"100px"}},[_vm._v(_vm._s(row[column.key]))])]:[_c('div',{staticClass:"cell-content cursor-pointer d-flex",class:column.textClass,on:{"click":function($event){column.key === 'negative'
                    ? _vm.openCallImpressionModal(row.module_id, 3)
                    : column.key === 'positive'
                    ? _vm.openCallImpressionModal(row.module_id, 2)
                    : column.key === 'neutral'
                    ? _vm.openCallImpressionModal(row.module_id, 1)
                    : column.key === 'mixed'
                    ? _vm.openCallImpressionModal(row.module_id, 4)
                    : column.key === 'unnoted'
                    ? _vm.openUnnotedCallsModal(row.module_id)
                    : ''}}},[_c('div',{staticClass:"underline-text"},[_c('span',{staticClass:"text-td quantity"},[_vm._v(_vm._s(row[column.key]))]),_c('span',{staticClass:"text-td ml-05 percentage"},[_vm._v("("+_vm._s(row[((column.key) + "_percentage")])+"%)")])])])]],2)}),0)}):_vm._l(([1, 2, 3, 4, 5]),function(row,rowIndex){return _c('div',{key:rowIndex,staticClass:"tr my-1"},_vm._l((_vm.fields),function(column,colIndex){return _c('div',{key:colIndex,staticClass:"td d-flex align-items-center"},[_c('b-skeleton',{staticClass:"w-100"})],1)}),0)})],2)]),(_vm.showUnnotedCallsModal)?_c('UnnotedCallsModal',{attrs:{"module-id":_vm.moduleSelected,"month":_vm.selectMonth,"year":_vm.selectYear},on:{"hidden":function($event){_vm.showUnnotedCallsModal = false},"updateMainGrid":function($event){return _vm.getCallAnalysis()}}}):_vm._e(),(_vm.showCallImpressionModal)?_c('CallImpressionModal',{attrs:{"module-id":_vm.moduleSelected,"sentimentSelected":_vm.sentimentSelected,"selectMonth":_vm.selectMonth,"selectYear":_vm.selectYear},on:{"hidden":function($event){_vm.showCallImpressionModal = false},"updateMainGrid":function($event){return _vm.getCallAnalysis()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }