<template>
  <div>
    <b-modal
      id="modal-details-ncr"
      ref="modal-ncr-details"
      size="xmd"
      @hidden="$emit('hidden')"
      hide-footer
    >
      <template #modal-title>
        <span class="text-uppercase font-weight-bolder">
          NCR / {{ moduleName }} / {{ getNameTypeNcr }} -
          {{ getNameTypeNcrDetails }}
        </span>
      </template>
      <template #default>
        <b-nav card-header pills class="m-0" v-if="!moduleId">
          <b-nav-item
            exact-active-class="active router-link-active"
            :link-classes="[
              'tab-nav',
              'px-3',
              'h-full',
              selectModuleId == null ? 'router-link-active' : '',
              bgTabsNavs,
            ]"
            style="width: 75px"
            @click="selectModule(null)"
            :active="selectModuleId == null"
          >
            ALL 

            <feather-icon 
              icon
              class="cursor-pointer"
              size="21"
              :badge="
                counterAll
              "
              badgeClasses="bg-danger badge-position"


            />
          </b-nav-item>
          <b-nav-item
            v-for="module in moduleOptions"
            :key="module.id"
            exact-active-class="active router-link-active"
            :link-classes="[
              'tab-nav',
              'px-3',
              'h-full',
              selectModuleId == module.id ? 'router-link-active' : '',
              bgTabsNavs,
            ]"
            style="width: 75px"
            @click="selectModule(module.id)"
            :active="selectModuleId == module.id"
          >
            <img
              v-b-tooltip.hover.top="module.name"
              :src="`/assets/${module.icon}`"
              alt="Logo"
              height="30"
              width="30"
            /> 
            <feather-icon 
              icon
              class="cursor-pointer"
              size="21"
              :badge="
                getCountForModule(module.id)
              "
              badgeClasses="bg-danger badge-position"


            />
          </b-nav-item>
        </b-nav>
        <filter-slot
          id="ncr-details-filter"
          :top-paginate="false"
          :filter="filterSlot.filters"
          :totalRows="filterSlot.totalRows"
          :paginate="filterSlot.paginate"
          :startPage="filterSlot.startPage"
          :toPage="filterSlot.toPage"
          :filterPrincipal="filterSlot.filterPrincipal"
          class="filter-slot-new-customization"
          @onChangeCurrentPage="$refs.ncrDetailsTable.refresh()"
          @reload="$refs.ncrDetailsTable.refresh()"
        >
          <template #table>
            <b-table
              ref="ncrDetailsTable"
              slot="table"
              class="position-relative table-new-customization"
              :items="myProvider"
              :fields="visibleFields"
              responsive="sm"
              show-empty
              sticky-header="55vh"
              :busy.sync="isBusy"
              :per-page="filterSlot.paginate.perPage"
              :current-page="filterSlot.paginate.currentPage"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </template>

              <template #cell(initial)="data">
                <div class="d-flex justify-content-center">
                  <!--  -->
                  <img
                    :id="`show-program-origin-${data.item.ncr_request_id}`"
                    :src="`/assets/${data.item.icons}`"
                    alt="Logo"
                    height="35"
                    width="35"
                  />

                  <b-tooltip
                    v-if="data.item.program_id"
                    :target="`show-program-origin-${data.item.ncr_request_id}`"
                    triggers="hover"
                    placement="right"
                  >
                    <div class="d-flex tooltip-origin-program-container">
                      <div class="tooltip-origin-program-from">
                        {{ "Program :" }}
                      </div>
                      <div class="tooltip-charge__programs text-center">
                        <img
                          :src="getProgramImageRoute(data.item.program_id)"
                          alt="img_services"
                        />
                      </div>
                    </div>
                  </b-tooltip>
                  <b-tooltip
                    v-else
                    :target="`show-program-origin-${data.item.ncr_request_id}`"
                    triggers="hover"
                    placement="right"
                  >
                    <div class="d-flex tooltip-origin-program-container">
                      <div class="tooltip-origin-program-from">
                        {{ "Module : " }}
                      </div>
                      <div class="tooltip-origin-program-name">
                        {{ data.item.module_name }}
                      </div>
                    </div>
                  </b-tooltip>
                </div>
              </template>

              <template #cell(client_name)="data">
                <div v-if="data.item.client_name" class="d-flex flex-column">
                  <router-link
                    class="font-weight-bolder"
                    target="_blank"
                    :to="
                      routerDashboardRedirectioner(
                        data.item.program_id,
                        data.item.client_account_id
                      )
                    "
                  >
                    {{ data.item.client_name }} <br />
                    {{ data.item.account }}
                  </router-link>
                </div>
                <div v-else>
                  <router-link
                    class="text-warning font-weight-bolder"
                    target="_blank"
                    :to="{
                      name: dashboardLeadRouteName(data.item.module_id),
                      params: { id: data.item.lead_id },
                    }"
                  >
                    {{ data.item.lead_name }} <br />
                    ID: {{ data.item.lead_id }}
                  </router-link>
                </div>
              </template>

              <template #cell(status)="data">
                <div>
                  <b-badge
                    :variant="
                      data.item.status_id == 3
                        ? 'light-success'
                        : 'light-warning'
                    "
                  >
                    <b>{{ data.item.status }}</b>
                  </b-badge>
                </div>
              </template>

              <template #cell(amount)="data">
                <div>
                  <b>{{ data.item.amount | myMoney }}</b>
                </div>
              </template>

              <template #cell(paid)="data">
                <div
                  v-if="data.item.amount != '0.00'"
                  :style="
                    data.item.paid == 1 ? 'color:#8FD14F' : 'color:#F24726'
                  "
                >
                  <b>{{ data.item.paid == 1 ? "YES" : "NO" }}</b>
                </div>
                <div v-else>-</div>
              </template>

              <template #cell(created_by)="data">
                <div class="d-flex flex-column">
                  <span>{{ data.item.created_by }}</span>
                  <span>{{ data.item.created_at | myGlobalDay }}</span>
                </div>
              </template>

              <template #cell(completed_by)="data">
                <div class="d-flex flex-column">
                  <span v-if="data.item.completed_by">{{
                    data.item.completed_by
                  }}</span>
                  <span v-else>-</span>
                  <span>{{ data.item.completed_at | myGlobalDay }}</span>
                </div>
              </template>
            </b-table>
          </template>
        </filter-slot>
      </template>
    </b-modal>
  </div>
</template>
<script>
import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service.js";
export default {
  props: {
    typeNcrDetails: {
      type: Number,
      required: true,
      default: 1,
    },
    typeNcrId: {
      type: Number,
      required: true,
      default: 1,
    },
    year: {
      type: Number,
      required: true,
    },
    month: {
      type: Number,
      required: true,
    },
    moduleId: {
      type: Number,
      required: false,
    },
    moduleName: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isBusy: false,
      fields: [
        {
          key: "initial",
          label: "MODULE",
          class: "text-center",
          thStyle:
            "background: #0090E7; color: white; border-radius: 15px 0 0 0;",
          visible: true,
        },
        {
          key: "client_name",
          label: "CLIENT",
          class: " ",
          thStyle: "background: #0090E7; color: white;",
          visible: true,
        },
        {
          key: "status",
          label: "STATUS",
          class: "text-center",
          thStyle: "background: #0090E7; color: white;",
          visible: true,
        },
        {
          key: "amount",
          label: "AMOUNT",
          class: "text-center",
          thStyle: "background: #0090E7; color: white;",
          visible: true,
        },
        {
          key: "paid",
          label: "PAID",
          class: "text-center",
          thStyle: "background: #0090E7; color: white;",
          visible: true,
        },
        {
          key: "created_by",
          label: "REQUEST BY",
          class: "text-center",
          thStyle: "background: #0090E7; color: white;",
          visible: true,
        },
        {
          key: "completed_by",
          label: "COMPLETED BY",
          class: "text-center",
          thStyle:
            "background: #0090E7; color: white; border-radius: 0 15px 0 0;",
          visible: true,
        },
      ],
      filterSlot: {
        filters: [],
        paginate: {
          currentPage: 1,
          perPage: 10,
        },
        startPage: 0,
        toPage: 0,
        totalRows: 0,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Name...",
          model: "",
        },
      },
      items: [],
      isBusy: false,
      moduleOptions: [
        { id: 2, name: "CRM", icon: "/images/modules-icons/crm.png" },
        { id: 3, name: "Business", icon: "/images/modules-icons/business.png" },
        {
          id: 5,
          name: "Debt Solution",
          icon: "/images/modules-icons/debtsolution.png",
        },
        {
          id: 21,
          name: "Credit Experts",
          icon: "/images/modules-icons/creditexperts.png",
        },
        {
          id: 7,
          name: "Boost Credit",
          icon: "/images/modules-icons/bootscredit.png",
        },
        {
          id: 8,
          name: "Tax Research",
          icon: "/images/modules-icons/taxresearch.png",
        },
        {
          id: 10,
          name: "Court Info",
          icon: "/images/modules-icons/generalsuport.png",
        },
        {
          id: 28,
          name: "Specialist",
          icon: "/images/modules-icons/specialists.png",
        },
        {
          id: 15,
          name: "Metamedia",
          icon: "/images/modules-icons/socialnetwork.png",
        },
        { id: 18, name: "Quality", icon: "/images/modules-icons/quality.png" },
      ],
      selectModuleId: null,
      counterAll: 0,
      counters: [],
    };
  },
  computed: {
    visibleFields() {
      return this.fields.filter((item) => item.visible);
    },
    getNameTypeNcr() {
      return this.typeNcrId == 1 ? "AUTOMATIC" : "MANUAL";
    },
    getNameTypeNcrDetails() {
      switch (this.typeNcrDetails) {
        case 1:
          return "REQUEST";
        case 2:
          return "COMPLETED";
        case 3:
          return "PAID";
        case 4:
          return "UNPAID";
        case 5:
          return "ZERO PAY";
      }
    },
  },
  created() {},
  async mounted() {
    this.toggleModal("modal-ncr-details");
  },
  methods: {
    selectModule(moduleId) {
      this.selectModuleId = moduleId;
      this.filterSlot.filterPrincipal.model = "";
      this.filterSlot.paginate.currentPage = 1;
      this.$refs.ncrDetailsTable.refresh();
    },
    async myProvider() {
      this.isBusy = true;
      try {
        const params = {
          year: this.year,
          month: this.month,
          module_id: this.moduleId ?? this.selectModuleId,
          type_ncr: this.typeNcrId,
          search_text: this.filterSlot.filterPrincipal.model,
          type_ncr_details: this.typeNcrDetails,
          page: this.filterSlot.paginate.currentPage,
          perPage: this.filterSlot.paginate.perPage,
        };
        const { data } = await DashboardServive.getNcrDetails(params);
        if(data.data.length != 0) {
          this.counters = data.data[0].counters;  
          this.counterAll = data.data[0].total_all;
        }
        
        this.filterSlot.totalRows = data.total;
        this.filterSlot.startPage = data.from;
        this.filterSlot.toPage = data.to;
        this.isBusy = false;
        return data.data || [];
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    dashboardLeadRouteName(module_id) {
      const routers = {
        2: "lead-show",
        3: "bussiness-lead-show",
        4: "administration-lead-show",
        5: "debtsolution-lead-show",
        6: "creditexperts-lead-show",
        7: "boostcredit-lead-show",
        8: "tax-research-lead-show",
        10: "claimdepartment-lead-show",
        11: "specialists-leads-show",
        12: "ti-lead-show",
        14: "bookeeping-lead-show",
        20: "connection-lead-show",
        22: "customerservice-lead-show",
        23: "financial-leads-lead-show",
        25: "specialist-dg-lead-show",
        26: "sales-lead-show",
      };
      return routers[module_id] || null;
    },
    getCountForModule( module_id ) {
      const quantity =  this.counters.find(item => item.module_id == module_id);
      if( quantity ) {
        return quantity.count
      }else{
        return 0
      }
      
    }
  },
};
</script>
<style lang="scss" scoped>
.tooltip-charge__programs {
  width: 27;
  height: 20px;
  background-color: #e9e9e9;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;

  img {
    width: 25px;
    height: 25px;
  }
}

.tooltip-origin-program-from {
  padding-top: 3px;
  padding-left: 5px;
  font-size: 14px;
  font-weight: 900;
  margin-right: 5px;
}

.tooltip-origin-program-name {
  padding-top: 3px;
  font-size: 14px;
  font-weight: 700;
  margin-right: 5px;
}
</style>
<style lang="scss">
.header-modal {
  position: relative;
  padding: 20px 0 20px 15px;
  background: rgba(22, 84, 204, 1);
  width: 100%;
  font-size: 22px;
  display: flex;
  justify-content: space-betweeen;

  span {
    font-size: 1.6rem;
    font-weight: bold;
    margin-left: 8px;
    color: #ffffff;
  }

  .container-icons {
    background-color: #ffffff;
    padding: 2.7px 6.37px;
    border-radius: 0.357rem;
    width: 31px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    transition: all 0.23s ease 0.1s;

    &--dark {
      background-color: #17171a;
    }

    &:hover {
      transform: translate(-7px, 5px);
      cursor: pointer;
    }
  }

  .programs_image {
    background-color: #e9e9e9;
    padding: 0.2rem;
    border-radius: 10px;
    width: 2.5rem;
    height: 2.5rem;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }


}

  .badge-position{
      left: 10px !important; 
      top: -30px !important;
  }
</style>