import { th } from "date-fns/locale";

export default [
    {
        key: "bank",
        label: "Bank",
        visible: true
    },
    {
        key: "cardholdername",
        label: "Card",
        visible: true
    },
    {
        key: "total_soft",
        label: "Confirmed",
        thClass: "text-center",
        tdClass: "text-center",
        visible: true
    },
    {
        key: "total_import",
        label: "Import",
        thClass: "text-center",
        tdClass: "text-center",
        visible: true
    },
    {
        key:"match",
        label: "Match",
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "total_unmatched",
        label: "Unmatched",
        thClass: "text-center",
        tdClass: "text-center",
        visible: true
    },
    {
        key: "status",
        label: "Status",
        thClass: "text-center",
        tdClass: "text-center",
        visible: true
    }
]
