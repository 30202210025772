<template>
  <div
    class="text-primary"
    :class="{
      'd-flex justify-content-center align-items-center h-100': centerVertical,
      'text-center  py-2': centerHorizontal,
    }"
  >
    <b-spinner class="align-middle" />
  </div>
</template>
<script>
export default {
  name: "Spinner",
  props: {
    centerHorizontal: {
      type: Boolean,
      default: false,
    },
    centerVertical: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
