export default [
    {
      type: 'select',
      margin: true,
      showLabel: true,
      label: 'AMOUNT RECOVERED',
      model: null,
      options: [{id : null, value : "ALL"}, {id : 1, value : "RECOVERED"}, {id : 2, value : "NO RECOVERED"}],
      reduce: 'id',
      selectText: 'value',
      cols: 12,
    },
  ]
  